import * as Stacktrace from "stacktrace-js";
// import GoogleAnalytics from "react-ga";
import store from "../redux";
import { detect } from "detect-browser";
import * as Sentry from "@sentry/browser";

const info = require("../version.json");

export const getUiVersion = () => {
  return info.version;
};
export const logErrorToGA = (err, fatal = false) => {
  const browser = detect();
  const browserName = browser ? browser.name : "";
  const browserVersion = browser ? browser.version : "";
  const browserOs = browser ? browser.os : "";
  const token = store.getState().Auth.token;
  let userId: number | null = null;
  if (token && token.user !== undefined) {
    userId = token.user;
    if (process.env.NODE_ENV === "production") {
      Sentry.addBreadcrumb({
        data: {
          userId,
        },
      });
    }
  }

  if (process.env.NODE_ENV === "production") {
    Sentry.captureException(err);
  }
  Stacktrace.fromError(err)
    .then((stackframes) => {
      const description = `${stackframes.join("\n")}\n\n${JSON.stringify({
        version: info.version,
        sha: info.sha,
        userId,
        browserName,
        browserVersion,
        browserOs,
      })}`;

      // GoogleAnalytics.exception({
      //   description: description.replace(/@/g, ""),
      //   fatal: false,
      // });
    })
    .catch((err) => {
      const description = `${err.stack}\n\n${JSON.stringify({
        version: info.version,
        sha: info.sha,
        userId,
        browserName,
        browserVersion,
        browserOs,
      })}`;
      // GoogleAnalytics.exception({
      //   description: description.replace(/@/g, ""),
      //   fatal: false,
      // });
    });
};

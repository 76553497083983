import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logUserIn } from "../../../auth";
import { updateProfile } from "../../../docuclipper/api";
import { ReduxState } from "../../../redux";
import { createAlert } from "../../../redux/reducers/Alerts";
import { closeModal } from "../../../redux/reducers/ModalManager";
import AlertBanner from "../../Docuclipper/AlertBanner/AlertBanner";
import { SignupFields } from "../Signup/SignupB2C";
import { setShowModal } from "../../../redux/reducers/TrialLimitation";
import { useHistory } from "react-router-dom";

type Props = {};

// redux

export const TrialLimitModal: React.SFC<Props> = () => {
  const history = useHistory();

  const { showModal } = useSelector(
    (state: ReduxState) => state.TrialLimitation
  );
  const dispatch = useDispatch();
  const toggle = () => dispatch(setShowModal(!showModal));

  const { activeModal } = useSelector(
    (state: ReduxState) => state.ModalManager
  );
  const { job } = useSelector((state: ReduxState) => state.JobData);

  let items = "items";
  if (job?.type === "Invoice") {
    items = "invoices/receipts";
  } else if (job?.isBankMode) {
    items = "transactions";
  }

  return (
    <Modal onHide={toggle} show={showModal}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="text-center">
        <div className="mt-2 mb-2">
          <AlertBanner />
        </div>
        <div className="h4 font-weight-bold text-center m-4">
          {`During the trial, you can only download 10 ${items}`}.
        </div>
        {job?.isBankMode && (
          <div className="h5 text-muted text-center m-4">
            During the trial, DocuClipper extracts and reconciles all
            transactions (so you can make sure it works well) but only allows 10
            to be downloaded.
          </div>
        )}
        {job?.type === "Invoice" && (
          <div className="h5 text-muted text-center m-4">
            During the trial, DocuClipper extracts all data (so you can make
            sure it works well) but only allows 10 invoices/receipts to be
            downloaded.
          </div>
        )}
        <Button
          variant="primary"
          className=""
          onClick={() => {
            dispatch(setShowModal(false));
            history.push("/account/upgrade");
          }}
        >
          Upgrade
        </Button>
      </Modal.Body>
    </Modal>
  );
};

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert, Button, Col, Form, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  CustomFormatItem,
  JobType,
} from "../../../docuclipper/DocuclipperTypes";
import { ReduxState } from "../../../redux";
import {
  addCustomFormatItemRedux,
  createCustomFormatRedux,
  deleteSelectedCustomFormat,
  fetchCustomFormats,
  setSelectedCustomFormat,
  updateCustomFormatName,
} from "../../../redux/reducers/CustomFormat";
import { setSelectedExportType } from "../../../redux/reducers/DownloadOptions";
import EditableText from "../../Docuclipper/EditableText";
import PageHeader from "../../Docuclipper/PageHeader/PageHeader";
import CustomFormatItemComp from "./CustomFormatItem";

export const getCustomFormatHeading = (cfi: CustomFormatItem) => {
  if (cfi.heading?.text) {
    return cfi.heading.text;
  }
  if (!cfi.source) {
    return "";
  }
  if (cfi.source === "checkNumber") {
    return "Check Number";
  } else if (cfi.source === "refNumber") {
    return "Reference Number";
  } else if (cfi.source === "categoryKeyword") {
    return "Matched Keyword";
  } else if (cfi.source === "name") {
    return "Description";
  }
  return cfi.source.slice(0, 1).toUpperCase() + cfi.source.slice(1);
};

export const getValue = (cfi: CustomFormatItem, jobType: JobType) => {
  if (!cfi.source) {
    return "";
  }

  if (jobType === "Invoice") {
    switch (cfi.source) {
      case "id":
        return "123";
      case "date":
        return "2022-01-10";
      case "item":
        return "Item 1";
      case "tax":
      case "total":
      case "quantity":
      case "unitPrice":
      case "price":
        return 123;
      case "service":
        return "Service 1";
      case "category":
        return "Category 1";
      case "customer":
        return "Customer 1";
      case "vendor":
        return "Vendor 1";
      default:
        return "";
    }
  }

  switch (cfi.source) {
    case "date":
    case "date2":
      return "2022-01-10";
    case "amount":
    case "debit":
    case "credit":
    case "balance":
      return 123.45;
    case "name":
    case "description":
      return "Transaction 1";
    case "checkNumber":
      return "1000";
    case "refNumber":
      return "1000";
    case "account":
      return "Account 1";
    case "autoIncrement":
      return "1"; // This will be incremented for each row in the actual implementation
    case "transactionType":
      return `${cfi.creditLabel || "Credit"}`;
    case "hardcodedValue":
      return cfi.hardcodedValue || "Sample Value";
    default:
      return "";
  }
};

type Props = {
  jobType: JobType;
};

export const CustomFormat: React.FC<Props> = ({
  jobType,
}: {
  jobType: JobType;
}) => {
  const { customFormats, selected } = useSelector(
    (state: ReduxState) => state.CustomFormat
  );
  React.useEffect(() => {
    dispatch(
      fetchCustomFormats({
        init: true,
        type: jobType === "Invoice" ? "invoice" : "bankStatement",
      })
    );
  }, [jobType]);

  const dispatch = useDispatch();

  const customFormatsFiltered = customFormats.filter(
    (cf) => cf.name !== "basic" && cf.name !== "all"
  );

  return (
    <PageHeader title="" description="">
      <div>
        {/* <Row className="justify-content-start text-left"> */}
        <Row className="mx-2">
          <Col>
            <a
              href="https://youtu.be/6Nk0LruwWmI"
              target="_blank"
              rel="noreferrer noopener"
            >
              <FontAwesomeIcon icon="play" />
              <span className="ml-1">Watch Tutorial</span>
            </a>
          </Col>
        </Row>
        <Row className="justify-content-end text-right">
          <Button
            className="mx-2"
            variant="link"
            onClick={() =>
              dispatch(
                createCustomFormatRedux({
                  type: jobType === "Invoice" ? "invoice" : "bankStatement",
                })
              )
            }
          >
            <FontAwesomeIcon icon="plus" />
            <span className="ml-2">Add New Configuration</span>
          </Button>
        </Row>

        <Row className="align-items-center mx-2">
          {customFormatsFiltered.length > 0 && (
            <Col md="4" className="mt-2">
              <Form.Group
                controlId="exampleForm.ControlSelect1"
                className="mt-3"
              >
                <Form.Control
                  value={selected?.name}
                  as="select"
                  onChange={(e) => {
                    dispatch(setSelectedCustomFormat(e.target.value));
                    dispatch(
                      setSelectedExportType(`custom-${e.target.value}` as any)
                    );
                  }}
                >
                  {customFormatsFiltered.map((cf, i) => (
                    <option key={i} value={cf.name}>
                      {cf.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Col>
          )}

          {customFormatsFiltered.length === 0 && (
            <Alert variant="info">
              You don't have any configurations. Click on "Add new
              configuration" to create one
            </Alert>
          )}
          {selected && (
            <Col>
              <Row>
                <div style={{ fontSize: "2em" }}>
                  <EditableText
                    key={selected.name}
                    initialValue={selected.name}
                    onConfirm={(newValue) =>
                      dispatch(updateCustomFormatName(newValue))
                    }
                  />
                </div>
                <Button
                  className="mt-1"
                  variant="link"
                  onClick={() => dispatch(deleteSelectedCustomFormat())}
                >
                  <FontAwesomeIcon icon="trash" />
                  <span className="ml-2">Delete Configuration</span>
                </Button>
              </Row>
            </Col>
          )}
        </Row>

        {selected && (
          <>
            <div className="m-4">
              <div className="h4">Preview</div>
              <Table striped bordered hover className="m-0">
                <thead>
                  <tr>
                    {(selected.data.columnConfigs || []).map((cfi, idx) => (
                      <td key={idx}>
                        <strong>{getCustomFormatHeading(cfi)}</strong>
                      </td>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    {(selected.data.columnConfigs || []).map((cfi, idx) => (
                      <td key={idx}>{getValue(cfi, jobType)}</td>
                    ))}
                  </tr>
                </tbody>
              </Table>
            </div>
            <Row className="text-left justify-content-start mx-2">
              <Button
                className="m-2"
                variant="link"
                onClick={() => {
                  dispatch(addCustomFormatItemRedux());
                }}
              >
                <FontAwesomeIcon icon="plus" />
                <span className="ml-2">Add Column</span>
              </Button>
            </Row>
            <div className="m-4">
              <Row className="text-nowrap overflow-auto">
                {(selected.data.columnConfigs || []).map((cfi, idx) => (
                  <Col md="4" key={idx}>
                    <CustomFormatItemComp cfi={cfi} jobType={jobType} />
                  </Col>
                ))}
              </Row>
            </div>
          </>
        )}
      </div>
    </PageHeader>
  );
};

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import React, { useCallback, useMemo, useRef } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { customerSupportGetProspectCampaigns } from "src/docuclipper/api";

type Props = {
  rowData;
  columnDefs;
  height;
  width;
  hasExport;
  reactiveCustomComponents?: any;
  bulkOpsClassName?: any;
  onSetApi?: any;
  cypressId?: string;

  renderBulkOps: (data: any[], setSelectedRowsIds?: any) => any;
};
export const AgBulkTable: React.FC<Props & AgGridReactProps> = ({
  rowData,
  columnDefs,
  height,
  width,
  hasExport,
  renderBulkOps,
  bulkOpsClassName,
  onSetApi,
  cypressId,
  ...rest
}) => {
  const ref = useRef();
  const onBtnExport = useCallback(() => {
    if (ref && ref.current) {
      (ref.current as any).api.exportDataAsCsv();
    }
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      headerCheckboxSelectionFilteredOnly: true,
      filter: true,
    };
  }, []);
  const gridApiRef = useRef(null);

  const [selectedRowIds, setSelectedRowIds] = React.useState<any[]>([]);
  const [allRowsSelected, setAllRowsSelected] = React.useState(false);

  const onSelectionChanged = useCallback(
    (e) => {
      if (gridApiRef && gridApiRef.current) {
        const selectedNodes = (gridApiRef.current as any).getSelectedNodes();
        const selectedIds = selectedNodes
          .filter((node) => node.displayed)
          .map((node) => node.id);

        setSelectedRowIds(selectedIds || []);

        // Check if all displayed rows are selected
        const displayedRowCount = gridApiRef.current.getDisplayedRowCount();
        const selectedDisplayedCount = selectedNodes.filter(
          (node) => node.displayed
        ).length;
        setAllRowsSelected(
          displayedRowCount > 0 && displayedRowCount === selectedDisplayedCount
        );
      }
    },
    [gridApiRef]
  );

  const onFilterChanged = () => {
    const selectedData = getSelectedData();
    setSelectedRowIds(selectedData?.map((data) => data.id) || []);
  };

  const getSelectedData = () => {
    if (!gridApiRef || !gridApiRef.current) {
      return;
    }
    const selectedNodes = (gridApiRef.current as any).getSelectedNodes();

    const selectedData = selectedNodes
      .filter((node) => node.displayed)
      .map((node) => node.data);
    return selectedData as any[];
  };

  const newCols = [
    {
      width: 50,
      cellClass: "ag-grid-cell-selection",
      checkboxSelection: true,
      headerComponent:
        rest.rowModelType === "infinite" ? "headerCheckbox" : undefined,
      headerCheckboxSelection: rest.rowModelType === "infinite" ? false : true,
    },
    ...columnDefs,
  ];

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    if (onSetApi) {
      onSetApi(params.api);
    }

    params.api.addEventListener("filterChanged", () => {
      onFilterChanged();
      setAllRowsSelected(false);
    });
  };

  return (
    <>
      {hasExport && (
        <Row>
          <Col>
            <Button variant="link" onClick={onBtnExport}>
              <FontAwesomeIcon icon="download" />
              <span className="ml-1">Export as CSV</span>
            </Button>
          </Col>
        </Row>
      )}

      <Row className={`${bulkOpsClassName} align-items-center mt-2`}>
        <Col>
          <div>{renderBulkOps(getSelectedData() || [], setSelectedRowIds)}</div>
        </Col>
      </Row>

      <Row>
        <div
          className="ag-theme-alpine"
          style={{ height, width }}
          data-cy={cypressId}
        >
          <AgGridReact
            defaultColDef={defaultColDef}
            colResizeDefault="shift"
            singleClickEdit
            ref={ref as any}
            rowData={rowData}
            columnDefs={newCols}
            onSelectionChanged={onSelectionChanged}
            rowSelection="multiple"
            onGridReady={onGridReady}
            suppressRowClickSelection
            suppressHorizontalScroll={false}
            {...rest}
          ></AgGridReact>
        </div>
      </Row>
    </>
  );
};
AgBulkTable.defaultProps = {
  hasExport: false,
  bulkOpsClassName: "",
};
export default AgBulkTable;

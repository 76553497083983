import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Media from "react-media";
import { breakpoints } from "../../../utils/utils";

type Props = {
  text: string;
  icon: IconProp;
};
const CollapsibleBtnText: React.SFC<Props> = ({ icon, text }) => (
  <>
    <FontAwesomeIcon icon={icon} />
    <Media query={{ minWidth: breakpoints.sm }}>
      {(matches) => (matches ? <span className="ml-1">{text}</span> : null)}
    </Media>
  </>
);

export default CollapsibleBtnText;

import React from "react";
import DownloadAsAdminButton from "../../Admin/DownloadAsAdminButton";
import { useIsAdmin } from "src/utils/auth";
import { useSelector } from "react-redux";
import { ReduxState } from "src/redux";
import { Col, Row } from "react-bootstrap";

export const AdminDocumentDownloader: React.SFC<{}> = ({}) => {
  const { isAdmin, isSuperLogin, isSupportRep } = useIsAdmin();

  const { data } = useSelector((root: ReduxState) => root.JobDocument);

  return (
    (isAdmin || isSuperLogin || isSupportRep) && (
      <>
        <div className="table-responsive text-start">
          <h1>[Admin] Download docs</h1>

          {data.map((doc, i) => (
            <Row key={i}>
              <Col className="mx-4">
                <span>{doc.originalname}</span>
                {<DownloadAsAdminButton documentId={doc.documentId as any} />}
              </Col>
            </Row>
          ))}
        </div>
      </>
    )
  );
};

import { PayloadAction } from "@reduxjs/toolkit";
import { Job } from "../../../../docuclipper/DocuclipperTypes";
import { TemplateFieldState } from "../../TemplateFieldTypes";

// export const _setDocument = (state, action: PayloadAction<Document>) => {
//   state.document.document = {
//     ...action.payload,
//     numPages: parseInt(action.payload.numPages.toString(), 10),
//   };
//   state.document.loading = false;
// };

export const _setJob = (
  state: TemplateFieldState,
  action: PayloadAction<Job>
) => {
  state.job.job = action.payload;
};
export const _clearJob = (
  state: TemplateFieldState,
  action: PayloadAction<void>
) => {
  state.job.job = null;
};

// export const _fileLoad = (state, action: PayloadAction<void>) => {
//   state.document.loading = true;
// };

// export const _fileSetError = (state, action: PayloadAction<string>) => {
//   state.document.loading = false;
//   state.document.error = action.payload;
// };

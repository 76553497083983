import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "..";

import { createAlert } from "./Alerts";
import { categorizeAction } from "./Categories";
import { getJobMatchingTransactions } from "../../docuclipper/api";
import { TransactionLine } from "../../docuclipper/DocuclipperTypes";

const initialState: {
  showModal: boolean;
  showBanner: boolean;
} = {
  showModal: false,
  showBanner: false,
};

const slice = createSlice({
  name: "TrialLimitation",
  initialState,
  reducers: {
    setShowModal(state, action: PayloadAction<boolean>) {
      state.showModal = action.payload;
    },
    setShowBanner(state, action: PayloadAction<boolean>) {
      state.showBanner = action.payload;
    },
  },
});

export const { setShowBanner, setShowModal } = slice.actions;

export default slice.reducer;

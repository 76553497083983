import store from "./redux";
// import ReactGA from "react-ga";
import { setToken } from "./redux/reducers/Auth";
import jwtDecode from "jwt-decode";

export const logUserIn = (token: string) => {
  store.dispatch(setToken(token));

  // ReactGA.set(getFields());
};

export const getFields = () => {
  const jwt = getJwt();
  if (jwt === null) {
    return {};
  }
  const data: any = jwtDecode(jwt);
  // planName and billingcycle is pretty useless until we refresh
  // the JWT when the subscription status changes
  const fields: any = {
    dimension1: data.user || "-1",
    dimension3: data.planName || "",
    dimension4: data.billingCycle || "",
  };
  if (data.seat && data.seat.contractId) {
    fields.dimension2 = data.seat.contractId;
  }
  return fields;
};

export const getJwt = () => {
  let jwt = null;
  const state = store.getState();
  if (state && state.Auth && state.Auth.tokenStr) {
    jwt = state.Auth.tokenStr;
  }
  return jwt;
};

export default {
  getJwt,
  getFields,
  logUserIn,
};

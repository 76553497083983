import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getJwt } from "../../../auth";
import {
  DocuclipperToken,
  Integration,
} from "../../../docuclipper/DocuclipperTypes";
import excelIcon from "../../../icons/excel.png";
import myobIcon from "../../../icons/myob.jpeg";
import quickbooksIcon from "../../../icons/qb-logo-horizontal-preferred.svg";

import sageIcon from "../../../icons/sage.png";
import xeroIcon from "../../../icons/xero.png";
import { goTo } from "../../../utils/utils";
import CollapsibleBtnText from "../../Docuclipper/CollapsibleBtnText";
import TrackedButton from "../../Docuclipper/TrackedButton";
import IntegrationActions from "./IntegrationsActions";

/* tslint:disable:no-var-requires */
const qs = require("qs");

const INTEGRATION_QUICKBOOKS_URL = `${process.env.NODE_ENV === "production" ? "" : "http://localhost:8080"}/api/v1/protected/auth/intuit`;

export const INTEGRATIONS = {
  googleProfile: {
    scope: "https://www.googleapis.com/auth/userinfo.profile",
    url: `/api/v1/protected/auth/gdocs?integration=googleSpreadsheets`,
  },
  googleDocuments: {
    scope: "https://www.googleapis.com/auth/documents",
    url: `/api/v1/protected/auth/gdocs?integration=googleDocuments`,
  },
  googleDrive: {
    scope: "https://www.googleapis.com/auth/drive",
    url: `/api/v1/protected/auth/gdocs?integration=googleDrive`,
  },
  googleSpreadsheets: {
    scope: "https://www.googleapis.com/auth/spreadsheets",
    url: `/api/v1/protected/auth/gdocs?integration=googleSpreadsheets`,
  },
  quickbooks: {
    scope: "intuit.quickbooks.accounting",
    url: `${INTEGRATION_QUICKBOOKS_URL}?integration=quickbooks`,
  },
  gmail: {
    scope: "https://www.googleapis.com/auth/gmail.readonly",
    url: `/api/v1/protected/auth/gdocs?integration=gmail`,
  },
};

export const goToGdocs = (maybeNext: string) =>
  goTo(`${INTEGRATIONS.googleSpreadsheets.url}&token=${getJwt()}${maybeNext}`);
export const goToGmail = (maybeNext: string) =>
  goTo(`${INTEGRATIONS.gmail.url}&token=${getJwt()}${maybeNext}`);

type Props = {
  integrations: Integration[];
  testFn: (...args: any[]) => any;
  deleteFn: (...args: any[]) => any;
};

type ReduxProps = {
  token: DocuclipperToken;
};

export const goToQuickbooksFn = (next: string) =>
  goTo(`${INTEGRATIONS.quickbooks.url}&token=${getJwt()}${next}`);

export const goToQuickbooks = () => {
  const next = `&next=${encodeURIComponent(`/integrations`)}`;
  goToQuickbooksFn(next);
};

export const IntegrationsTable: React.SFC<
  Props & ReduxProps & RouteComponentProps
> = ({ integrations, location, history, testFn, deleteFn, token }) => {
  // const [integrations,setIntegrations] = React.useState<Integration[]>([]);
  let query;
  if (location) {
    query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
  }

  const googleSpreadsheetsIntegration = integrations.filter(
    (i) => i.scope === INTEGRATIONS.googleSpreadsheets.scope
  );
  const quickbooksIntegrations = integrations.filter(
    (i) => i.scope === INTEGRATIONS.quickbooks.scope
  );
  const gmailIntegrations = integrations.filter(
    (i) => i.scope === INTEGRATIONS.gmail.scope
  );

  const maybeNext = query.next ? `&next=${query.next}` : "";

  const sheetsActions = (
    <>
      <IntegrationActions
        integrations={googleSpreadsheetsIntegration}
        integrationsInfo={INTEGRATIONS}
        type="googleSpreadsheets"
        testFn={testFn}
        deleteFn={deleteFn}
      />
    </>
  );
  const quickbooksActions = (
    <>
      <IntegrationActions
        integrations={quickbooksIntegrations}
        integrationsInfo={INTEGRATIONS}
        type="quickbooks"
        testFn={testFn}
        deleteFn={deleteFn}
      />
    </>
  );

  let gmailActions: any = null;
  if (
    token &&
    token.permissions &&
    token.permissions.includes("MANAGE_EMAIL_INTEGRATION")
  ) {
    gmailActions = (
      <div className="card">
        <div className="card-header">
          <strong>Gmail</strong>
          <TrackedButton
            variant="primary"
            action="connectToGmail"
            onClick={() => goToGmail(maybeNext)}
            className="float-right btn--add-gmail"
          >
            <CollapsibleBtnText icon="plus" text="Connect Gmail Account" />
          </TrackedButton>
        </div>
        <div className="card-body">
          <IntegrationActions
            integrations={gmailIntegrations}
            integrationsInfo={INTEGRATIONS}
            type="gmail"
            testFn={testFn}
            deleteFn={deleteFn}
          />
        </div>
      </div>
    );
  }
  return (
    <>
      {/* quickbooks */}

      <div className="card">
        <div className="card-header">
          <img
            style={{ height: "48px" }}
            src={quickbooksIcon}
            alt="QuickBooks logo"
          />
          <strong className="ml-2">
            QuickBooks Online (Invoices & Receipts)
          </strong>
          <Button
            // variant="primary"
            // action="connectToQuickbooks"
            className="float-right btn--add-quickbooks"
            onClick={() => history.push("/quickbooksaddnewcompany")}
          >
            <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
            <span className="ml-1">Add QuickBooks Company</span>
          </Button>
        </div>
        <div className="card-body">{quickbooksActions}</div>
      </div>
      {/* gdocs */}
      <div className="card">
        <div className="card-header">
          <img style={{ height: "24px" }} src={excelIcon} alt="Excel logo" />
          <strong className="ml-2">Google Docs Spreadsheets</strong>
          <TrackedButton
            action="addGoogleDocsSpreadsheetAccount"
            className="float-right btn--add-gdocs"
            onClick={() => goToGdocs(maybeNext)}
            variant="primary"
          >
            <CollapsibleBtnText icon="plus" text="Connect Google Account" />
          </TrackedButton>
        </div>
        <div className="card-body">{sheetsActions}</div>
      </div>

      {gmailActions}
      {[
        {
          icon: excelIcon,
          name: "Excel (CSV & XLSX)",
          help: <>No configuration necessary.</>,
        },
        {
          icon: quickbooksIcon,
          name: "QuickBooks Online",
          help: (
            <Row>
              <Col>
                <div>No configuration necessary.</div>
              </Col>
              <Col>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.docuclipper.com/docs/ui/user-guide/extracting-data/import-data-quickbooks-online/"
                >
                  <FontAwesomeIcon icon="question-circle" />
                  <span className="ml-1">
                    How to import Web Connect files into QuickBooks Online
                  </span>
                  <FontAwesomeIcon className="ml-2" icon="external-link-alt" />
                </a>
              </Col>
            </Row>
          ),
        },
        {
          icon: quickbooksIcon,
          name: "QuickBooks Desktop",
          help: (
            <Row>
              <Col>
                <div>No configuration necessary.</div>
              </Col>
              <Col>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.docuclipper.com/docs/ui/user-guide/extracting-data/import-data-quickbooks-desktop/"
                >
                  <FontAwesomeIcon icon="question-circle" />
                  <span className="ml-1">
                    How to import Web Connect files into QuickBooks Desktop
                  </span>
                  <FontAwesomeIcon className="ml-2" icon="external-link-alt" />
                </a>
                <br />
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.docuclipper.com/docs/ui/user-guide/extracting-data/import-data-quickbooks-desktop-iif/"
                >
                  <FontAwesomeIcon icon="question-circle" />
                  <span className="ml-1">
                    How to import IIF files into QuickBooks Desktop
                  </span>
                  <FontAwesomeIcon className="ml-2" icon="external-link-alt" />
                </a>
              </Col>
            </Row>
          ),
        },
        {
          icon: xeroIcon,
          name: "Xero",
          help: (
            <Row>
              <Col>
                <div>No configuration necessary.</div>
              </Col>
              <Col>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.docuclipper.com/docs/ui/user-guide/extracting-data/import-data-xero/"
                >
                  <FontAwesomeIcon icon="question-circle" />
                  <span className="ml-1">
                    How to import CSV files into Xero
                  </span>
                  <FontAwesomeIcon className="ml-2" icon="external-link-alt" />
                </a>
              </Col>
            </Row>
          ),
        },
        {
          icon: sageIcon,
          name: "Sage",
          help: (
            <Row>
              <Col>
                <div>No configuration necessary.</div>
              </Col>
              <Col>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.docuclipper.com/docs/ui/user-guide/extracting-data/import-data-sage/"
                >
                  <FontAwesomeIcon icon="question-circle" />
                  <span className="ml-1">
                    How to import CSV files into Sage
                  </span>
                  <FontAwesomeIcon className="ml-2" icon="external-link-alt" />
                </a>
              </Col>
            </Row>
          ),
        },
        {
          icon: myobIcon,
          name: "MYOB",
          help: (
            <Row>
              <Col>
                <div>No configuration necessary.</div>
              </Col>
            </Row>
          ),
        },
      ].map(({ icon, name, help }, i) => (
        <div className="card" key={i}>
          <div className="card-header">
            <img
              style={{
                height: name.toLowerCase().includes("quickbooks")
                  ? "40px"
                  : "24px",
              }}
              src={icon}
              alt="QuickBooks logo"
            />
            <strong className="ml-2">{name}</strong>
          </div>
          <div className="card-body">{help}</div>
        </div>
      ))}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    token: state.Auth.token,
  };
};

export default connect(mapStateToProps, {})(withRouter(IntegrationsTable));

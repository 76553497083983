import { Field, Formik } from "formik";
import React from "react";
import { Alert, Form, FormGroup, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { ReduxState } from "../../../../../redux";
import {
  formikCheckInput,
  formikInput,
  formikSelectInput,
} from "../../../../../utils/utils";
import PopoverHelp from "../../../../Docuclipper/PopoverHelp";
import { LoadingTrackedButton } from "../../../../Docuclipper/TrackedButton";
import { QboSearchField } from "./QboSearch/QboSearch";
import { downloadSpreadsheetHelper } from "./utils";
import { groupBy } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const QboOptions: React.FC<{ format }> = ({ format }) => {
  const [loading, setLoading] = React.useState(false);
  const [separateFilesForAccounts, setSeparateFilesForAccounts] =
    React.useState(true);
  const { selectedExportType } = useSelector(
    (state: ReduxState) => state.DownloadOptions
  );
  useSelector((state: ReduxState) => state.TransactionManager);

  const { job } = useSelector((state: ReduxState) => state.JobData);

  const { downloadOptions } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );
  const { selectedDocumentIds, selectedFieldNames } = downloadOptions;
  // const allAccounts = Object.keys(groupBy(tls.tls || [], "account"));
  const { allAccounts } = useSelector((state: ReduxState) => state.Reconciler);

  const onExport = ({
    accountId,
    bankId,
    accountType,
    fromAccount,
    bid,
    bankName,
    currency,
  }) => {
    return downloadSpreadsheetHelper(
      job,
      "FieldsToExcel1",
      format,
      {
        accountId,
        bankId,
        accountType,
        fromAccount,
        getTotals: false,
        bid,
        bankName,
        currency,
        separateFilesForAccounts,
      },
      selectedDocumentIds,
      selectedFieldNames,
      [],
      [],
      "date",
      "asc"
    );
  };
  const onSubmit =
    () =>
    ({ accountId, bankId, accountType, fromAccount, bid, currency }) => {
      const { bid: bid2, name } = bid;
      setLoading(true);
      onExport({
        accountId,
        bankId,
        fromAccount,
        accountType,
        bid: bid2,
        bankName: name,
        currency,
      })
        .then(() => {
          setLoading(false);

          // resetForm();
        })
        .catch(() => {
          setLoading(false);
          //
        });
    };

  const bidDef =
    job && job.isBankMode && ["qbo"].includes(format)
      ? Yup.string().required(
          `Bank is required. Select "Chase Web Download" if your bank is not listed.`
        )
      : Yup.string();

  const format2str = () => {
    switch (format) {
      case "qbo":
        return "QBO Web Connect";
      case "ofx":
        return "OFX";
      case "qfx":
        return "QFX";
      default:
        return "";
    }
  };
  return (
    <Formik
      initialValues={{
        bid: "",
        accountId: "123456789",
        bankId: "123456789",
        accountType: "checking",
        fromAccount: "",
        currency: "USD",
      }}
      validationSchema={Yup.object().shape({
        bid: bidDef,
        accountId: Yup.string()
          .min(4, "Account number must be at least 4 characters")
          .max(22, "Account number must be at most 22 characters")
          .required("Account number is required"),
        bankId: Yup.string().required("Routing number is required"),
        accountType: Yup.string().required(""),
        currency: Yup.string().required(""),
      })}
      onSubmit={onSubmit()}
    >
      {({ submitForm, values }) => (
        <>
          <FormGroup>
            {format === "qbo" && job && job.isBankMode && (
              <div className="mb-2">
                <div>
                  <Form.Label>
                    <strong>Bank</strong>
                  </Form.Label>
                </div>

                <Field inline={true} name="bid" component={QboSearchField} />
              </div>
            )}
            <div className="">
              <Form.Label>
                <strong>Account type</strong>
              </Form.Label>
            </div>

            <Field
              inline={true}
              name="accountType"
              type="radio"
              value="checking"
              checked={values.accountType === "checking"}
              component={formikCheckInput}
              label="Bank"
            />

            <Field
              inline={true}
              name="accountType"
              type="radio"
              value="creditCard"
              checked={values.accountType === "creditCard"}
              component={formikCheckInput}
              label="Credit Card"
            />
          </FormGroup>
          {true && (
            <>
              {values.accountType === "checking" && (
                <FormGroup>
                  <Form.Label>
                    <strong>Routing number</strong>
                  </Form.Label>
                  <PopoverHelp header="" placement="right" id="bankIdHelp">
                    Optional for QuickBooks Online. Recommended for QuickBooks
                    Desktop.
                  </PopoverHelp>
                  <Field
                    name="bankId"
                    type="text"
                    component={formikInput}
                  ></Field>
                </FormGroup>
              )}
              <FormGroup>
                <Form.Label>
                  <strong>Account number</strong>
                </Form.Label>
                <PopoverHelp header="" placement="right" id="accountIdHelp">
                  Optional for QuickBooks Online. Recommended for QuickBooks
                  Desktop.
                </PopoverHelp>
                <Field
                  name="accountId"
                  type="text"
                  component={formikInput}
                ></Field>
              </FormGroup>
              <FormGroup>
                <Form.Label>
                  <strong>Currency</strong>
                </Form.Label>
                <Field
                  name="currency"
                  type="select"
                  component={formikSelectInput}
                >
                  <option value="USD">USD - United States Dollar</option>
                  <option value="CAD">CAD - Canadian Dollar</option>
                  <option value="EUR">EUR - Euro</option>
                  <option value="GBP">GBP - British Pound Sterling</option>
                  <option value="AUD">AUD - Australian Dollar</option>
                  <option value="ZAR">ZAR - Rand</option>
                </Field>
              </FormGroup>
            </>
          )}
          {["qbo", "qfx", "ofx"].includes(selectedExportType) &&
            allAccounts.length > 1 && (
              <>
                {separateFilesForAccounts && (
                  <Alert variant="danger" className="mt-2">
                    <>
                      <span className="mr-2">
                        {`DocuClipper has detected multiple accounts and will download
                    a zip file containing separate files for each account`}
                      </span>
                      <a
                        href="https://www.docuclipper.com/docs/docuclipper-exports-data-in-zip-file/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FontAwesomeIcon icon="question-circle" />
                        <span className="ml-1">See instructions</span>
                        <FontAwesomeIcon
                          className="ml-2"
                          icon="external-link-alt"
                        />
                      </a>
                    </>
                  </Alert>
                )}
                <Form.Group className="mx-1 my-3" controlId="oneFilePerAccount">
                  <Form.Check
                    checked={separateFilesForAccounts}
                    onChange={(e) =>
                      setSeparateFilesForAccounts(e.target.checked)
                    }
                    type="checkbox"
                    label="Download separate files for each account"
                  />
                </Form.Group>
              </>
            )}
          <Row className="justify-content-end mx-2">
            <LoadingTrackedButton
              isLoading={loading}
              className="btn-save-qbo justify-content-center m-1"
              // disabled={anyFileUploading}
              variant="primary"
              onClick={() => {
                submitForm();
              }}
            >
              {`Download ${format2str(format)}`}
            </LoadingTrackedButton>
          </Row>

          {/* <FormGroup>
            <Form.Label>
              <strong>Statement Year</strong>
            </Form.Label>
            <Field name="year" type="text" component={formikInput}></Field>
          </FormGroup> */}
        </>
      )}
    </Formik>
  );
};
export default QboOptions;

import { Job } from "../../docuclipper/DocuclipperTypes";
import { createJob } from "../../docuclipper/api";
import { ReduxState } from "..";

const initialState: {
  job: Job | null;
} = {
  job: null,
};

export const CREATE_JOB = "create-job";
export const DELETE_JOB = "delete-job";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_JOB:
      return {
        ...state,
        job: action.payload.job,
      };
    case DELETE_JOB:
      return {
        ...state,
        job: null,
      };

    default:
      return state;
  }
}

export type createJobActionFn = ({
  name,
  documentId,
}: {
  name: string;
  documentId: number;
}) => any;

export const createJobAction =
  ({ name, documentId }: { name: string; documentId: number }) =>
  async (dispatch, getState) => {
    const state: ReduxState = getState();

    try {
      const job = await createJob({
        jobName: name,
        documents: [documentId],
        templateId: null,
        tags: state.JobTags.selectedTags,
      });
      dispatch({
        type: CREATE_JOB,
        payload: {
          job,
        },
      });
    } catch (err) {
      //
    }
  };

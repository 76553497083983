import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { ReduxState } from "../redux";
export function usePayingInfo() {
  const { user } = useSelector((state: ReduxState) => state.User);
  const hasBoughtPages =
    user && parseInt(user.purchasedCredits.toString(), 10) > 0;
  const { subscription } = useSelector(
    (state: ReduxState) => state.Subscription
  );

  const hasActiveSubscription =
    subscription && subscription.status === "active";

  return { user, hasBoughtPages, hasActiveSubscription };
}

import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import uuidv4 from "uuid/v4";
import { Alert as DocuclipperAlert } from "../../../docuclipper/DocuclipperTypes";
import { removeAlert, RemoveAlertFn } from "../../../redux/reducers/Alerts";
import { updateTours } from "../../../redux/reducers/Tours";
import { UpgradeAlertBanner } from "../../../utils/AlertRenders";

type Props = {};

type ReduxProps = {
  alert: DocuclipperAlert;
  removeAlert: RemoveAlertFn;
};

export const AlertBanner: React.SFC<Props & ReduxProps> = ({
  alert,
  removeAlert,
}) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(updateTours());
  }, [dispatch, alert]);

  if (!alert) {
    return null;
  }
  let color;
  let icon: IconProp = "exclamation-circle";
  switch (alert.type) {
    case "success":
      color = "success";

      break;
    case "error":
      color = "danger";
      break;
    case "info":
      color = "primary";
      break;
    default:
      color = "primary";
      break;
  }

  return (
    <Alert
      dismissible={true}
      key={uuidv4()}
      style={{ marginBottom: 0 }}
      variant={color}
      onClose={() => removeAlert()}
    >
      <FontAwesomeIcon icon={icon} className={`text-${color}`} />

      {alert.messageWithUpdate && (
        <UpgradeAlertBanner message={alert.messageWithUpdate} />
      )}
      {!alert.messageWithUpdate && (
        <span className="ml-2">{alert.message}</span>
      )}
    </Alert>
  );
};

const mapStateToProps = (state) => ({
  alert: state.Alerts.alert,
});

const mapDispatchToProps = {
  removeAlert,
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertBanner);

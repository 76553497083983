import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "..";
import { getIntegrations } from "../../docuclipper/api";
import { Integration } from "../../docuclipper/DocuclipperTypes";

const initialState: {
  integrations: Integration[];
  loading: boolean;
  error: string | null;
} = {
  integrations: [],
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "Integration",
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
      state.error = null;
    },
    setData(state, action: PayloadAction<Integration[]>) {
      state.loading = false;
      state.integrations = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { load, setError, setData } = slice.actions;

export const fetchIntegrations = () => async (dispatch, getState) => {
  const state: ReduxState = getState();
  if (state.Integration.loading) {
    return;
  }
  dispatch(load());
  try {
    const job = state.JobData.job;
    if (job) {
      const integrations = await getIntegrations();
      dispatch(setData(integrations));
    }
  } catch (err) {
    dispatch(setError((err as any).message || "Error getting integrations"));
  }
};

export default slice.reducer;

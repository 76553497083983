import * as React from "react";
import { Row } from "react-bootstrap";
import TrackedButton from "../../Docuclipper/TrackedButton";

type Props = {
  goToFacebook: () => any;
  goToMicrosoft: () => any;
  goToGoogle: () => any;
};
type State = {
  hasAgreed: boolean;
  [x: number]: any;
};
export default class ContinueWithSocial extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hasAgreed: true,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }
  public render() {
    return (
      <>
        <div className="h6 modal-title text-center">
          <h4 className="mt-2">
            <span>Or continue with one of these services:</span>
          </h4>
        </div>

        <div style={{ margin: "20px" }} />
        <Row className="justify-content-center">
          <TrackedButton
            action="continueWithFacebookButton"
            className="google-button  btn--continue-with-facebook"
            disabled={!this.state.hasAgreed}
            // href="/api/v1/auth/google"
            onClick={this.props.goToFacebook}
          >
            <span className="google-button__icon">
              <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 72 72"
              >
                <path
                  fill="#4267b2"
                  d="M68,0H4A4,4,0,0,0,0,4V68a4,4,0,0,0,4,4H38.46V44.16H29.11V33.26h9.35v-8c0-9.3,5.68-14.37,14-14.37a77.46,77.46,0,0,1,8.38.43V21H55.1c-4.51,0-5.39,2.15-5.39,5.3v6.94H60.5l-1.4,10.9H49.71V72H68a4,4,0,0,0,4-4V4A4,4,0,0,0,68,0Z"
                />
                <path
                  id="f"
                  fill="#fff"
                  d="M49.71,72V44.16H59.1l1.4-10.9H49.71V26.32c0-3.15.88-5.3,5.39-5.3h5.72V11.3a77.46,77.46,0,0,0-8.38-.43c-8.3,0-14,5.07-14,14.37v8H29.11v10.9h9.35V72Z"
                />
              </svg>
            </span>
            <span className="facebook-button__text">
              Continue with Facebook
            </span>
          </TrackedButton>
        </Row>
        <Row className="justify-content-center">
          <TrackedButton
            action="continueWithFacebookButton"
            className="google-button  btn--continue-with-facebook"
            disabled={!this.state.hasAgreed}
            // href="/api/v1/auth/google"
            onClick={this.props.goToMicrosoft}
          >
            <span className="google-button__icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23">
                <path fill="#f3f3f3" d="M0 0h23v23H0z" />
                <path fill="#f35325" d="M1 1h10v10H1z" />
                <path fill="#81bc06" d="M12 1h10v10H12z" />
                <path fill="#05a6f0" d="M1 12h10v10H1z" />
                <path fill="#ffba08" d="M12 12h10v10H12z" />
              </svg>
            </span>
            <span className="facebook-button__text">
              Continue with Microsoft
            </span>
          </TrackedButton>
        </Row>
        <Row className="justify-content-center" style={{ marginTop: "15px" }}>
          <TrackedButton
            action="continueWithGoogleButton"
            className="google-button btn--continue-with-google"
            disabled={!this.state.hasAgreed}
            // href="/api/v1/auth/google"
            onClick={this.props.goToGoogle}
          >
            <span className="google-button__icon">
              <svg viewBox="0 0 366 372" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M125.9 10.2c40.2-13.9 85.3-13.6 125.3 1.1 22.2 8.2 42.5 21 59.9 37.1-5.8 6.3-12.1 12.2-18.1 18.3l-34.2 34.2c-11.3-10.8-25.1-19-40.1-23.6-17.6-5.3-36.6-6.1-54.6-2.2-21 4.5-40.5 15.5-55.6 30.9-12.2 12.3-21.4 27.5-27 43.9-20.3-15.8-40.6-31.5-61-47.3 21.5-43 60.1-76.9 105.4-92.4z"
                  id="Shape"
                  fill="#EA4335"
                />
                <path
                  d="M20.6 102.4c20.3 15.8 40.6 31.5 61 47.3-8 23.3-8 49.2 0 72.4-20.3 15.8-40.6 31.6-60.9 47.3C1.9 232.7-3.8 189.6 4.4 149.2c3.3-16.2 8.7-32 16.2-46.8z"
                  id="Shape"
                  fill="#FBBC05"
                />
                <path
                  d="M361.7 151.1c5.8 32.7 4.5 66.8-4.7 98.8-8.5 29.3-24.6 56.5-47.1 77.2l-59.1-45.9c19.5-13.1 33.3-34.3 37.2-57.5H186.6c.1-24.2.1-48.4.1-72.6h175z"
                  id="Shape"
                  fill="#4285F4"
                />
                <path
                  d="M81.4 222.2c7.8 22.9 22.8 43.2 42.6 57.1 12.4 8.7 26.6 14.9 41.4 17.9 14.6 3 29.7 2.6 44.4.1 14.6-2.6 28.7-7.9 41-16.2l59.1 45.9c-21.3 19.7-48 33.1-76.2 39.6-31.2 7.1-64.2 7.3-95.2-1-24.6-6.5-47.7-18.2-67.6-34.1-20.9-16.6-38.3-38-50.4-62 20.3-15.7 40.6-31.5 60.9-47.3z"
                  fill="#34A853"
                />
              </svg>
            </span>
            <span className="google-button__text">Continue with Google</span>
          </TrackedButton>
        </Row>
      </>
    );
  }

  private handleInputChange(event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;
    this.setState({
      [name]: value,
    });
  }
}

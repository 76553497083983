import {
  faCheck,
  faQuestionCircle,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Badge } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../redux";
import PopoverHelp from "../../Docuclipper/PopoverHelp";

type Props = {
  type: "job" | "document" | "field";
  documentId?: string;
};

export function ReconciledYes({ type, disableOverlay }) {
  let reconciledHelp = "";
  switch (type) {
    case "document":
      reconciledHelp = `The document has been reconciled, ensuring all transactions are accurate.`;
      break;
    case "account":
      reconciledHelp = `The account has been reconciled, ensuring all transactions are accurate.`;
      break;
    case "job":
      reconciledHelp = `The documents in this job have been reconciled, ensuring all transactions are accurate.`;
      break;
    case "field":
      reconciledHelp = `The field has been reconciled, ensuring all transactions are accurate.`;
      break;
    case "period":
      reconciledHelp = `The period has been reconciled, ensuring all transactions are accurate.`;
      break;
  }

  return (
    <span>
      <Badge variant="info" className="p-1 pr-2">
        <FontAwesomeIcon color="white" icon={faCheck} />
        <span className="ml-2 text-white">Reconciled</span>
      </Badge>
      {!disableOverlay && (
        <PopoverHelp header="" placement="right" id="reconciledHelp">
          {reconciledHelp}
        </PopoverHelp>
      )}
    </span>
  );
}

ReconciledYes.defaultProps = {
  disableOverlay: false,
};

export function ReconciledNo({ type, disableOverlay }) {
  let notReconciledHelp = "";
  switch (type) {
    case "document":
      notReconciledHelp = `The document couldn't be reconciled. There might be missing or inaccurate transactions.`;
      break;
    case "account":
      notReconciledHelp = `The account couldn't be reconciled. There might be missing or inaccurate transactions.`;
      break;
    case "job":
      notReconciledHelp = `One or more documents in this job couldn't be reconciled. There might be missing or inaccurate transactions.`;
      break;
    case "field":
      notReconciledHelp = `The field couldn't be reconciled. There might be missing or inaccurate transactions.`;
      break;
    case "period":
      notReconciledHelp = `The period couldn't be reconciled. There might be missing or inaccurate transactions.`;
      break;
  }

  return (
    <span>
      <Badge variant="danger" className="p-1 pr-2">
        <FontAwesomeIcon color="white" icon={faTimes} />
        <span className="ml-2 text-white">Not Reconciled</span>
      </Badge>
      {!disableOverlay && (
        <PopoverHelp header="" placement="bottom" id="reconciledHelp">
          {notReconciledHelp}
        </PopoverHelp>
      )}
    </span>
  );
}

export function ReconciledUnknown({ type, disableOverlay }) {
  let notReconciledHelp = "";
  switch (type) {
    case "document":
      notReconciledHelp = `Unknown reconciliation status. There might be missing or inaccurate transactions.`;
      break;
    case "job":
      notReconciledHelp = `Unknown reconciliation status. There might be missing or inaccurate transactions.`;
      break;
    case "field":
      notReconciledHelp = `Unknown reconciliation status. There might be missing or inaccurate transactions.`;
      break;
    case "period":
      notReconciledHelp = `Unknown reconciliation status. There might be missing or inaccurate transactions.`;
      break;
  }

  return (
    <span>
      <Badge variant="secondary" className="p-1 pr-2">
        <FontAwesomeIcon color="white" icon={faQuestionCircle} />
        <span className="ml-2 text-white">Unknown</span>
      </Badge>
      {!disableOverlay && (
        <PopoverHelp header="" placement="right" id="reconciledHelp">
          {notReconciledHelp}
        </PopoverHelp>
      )}
    </span>
  );
}

ReconciledNo.defaultProps = {
  disableOverlay: false,
};

export const ReconciledBadge: React.SFC<Props> = ({ type, documentId }) => {
  const { data: allJobDocuments } = useSelector(
    (state: ReduxState) => state.JobDocument
  );

  const jobDocuments = allJobDocuments.filter(
    (jd) => !documentId || jd.documentId === documentId
  );

  if (jobDocuments.length === 0) {
    return null;
  }

  let reconciled = false;
  for (const doc of jobDocuments) {
    if (doc.isReconciled) {
      reconciled = true;
    } else {
      reconciled = false;
      break;
    }
  }

  let shouldReconcile = false;
  for (const doc of jobDocuments) {
    if (doc.shouldReconcile) {
      shouldReconcile = true;
      break;
    }
  }

  if (!shouldReconcile) {
    return null;
  }
  let reconciledHelp = "";
  let notReconciledHelp = "";
  switch (type) {
    case "document":
      reconciledHelp = `The document has been reconciled, ensuring all transactions are accurate.`;
      notReconciledHelp = `The document couldn't be reconciled. There might be missing or inaccurate transactions.`;
      break;
    case "job":
      reconciledHelp = `The documents in this job have been reconciled, ensuring all transactions are accurate.`;
      notReconciledHelp = `One or more documents in this job couldn't be reconciled. There might be missing or inaccurate transactions.`;
      break;
    case "field":
      reconciledHelp = `The field has been reconciled, ensuring all transactions are accurate.`;
      notReconciledHelp = `The field couldn't be reconciled. There might be missing or inaccurate transactions.`;
      break;
  }
  return (
    <>
      {reconciled && <ReconciledYes type={type} />}
      {shouldReconcile && !reconciled && <ReconciledNo type={type} />}
    </>
  );
};

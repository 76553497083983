import moment from "moment";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getJob } from "../../../docuclipper/api";
import { logErrorToGA } from "../../../docuclipper/Logger";
import { ReduxState } from "../../../redux";
import { setJobData } from "../../../redux/reducers/JobData";
import { openModal } from "../../../redux/reducers/ModalManager";
import { fetchUser } from "../../../redux/reducers/User";
import { createAlert } from "../../../redux/reducers/Alerts";

// {
//   type: 'update-current-job',
//     payload: {
//       progress: 60,
//       job: {}
//     }
// }

type Props = {};

const POLLING_INTERVAL_SECS = 30;
const POLLING_INTERVAL = POLLING_INTERVAL_SECS * 1000;

export const JobPoller: React.SFC<Props> = () => {
  const dispatch = useDispatch();
  const interval = React.useRef<null | number | NodeJS.Timeout>(null);

  const { lastUpdate } = useSelector((state: ReduxState) => state.JobData);
  const jobStatus = useSelector((state: ReduxState) =>
    state.JobData.job ? state.JobData.job.status : null
  );
  const jobId = useSelector((state: ReduxState) =>
    state.JobData.job ? state.JobData.job.id : null
  );

  const lastUpdateRef = React.useRef<null | Date>(null);

  React.useEffect(() => {
    lastUpdateRef.current = lastUpdate;
  }, [lastUpdate]);

  const cleanup = () => {
    if (interval.current !== null) {
      //console.log("cleaning interval", interval.current);
      clearInterval(interval.current as any);
      interval.current = null;
    }
  };

  const setup = () => {
    if (!["InProgress", "Finishing"].includes(jobStatus || "")) {
      return;
    }
    if (interval.current !== null) {
      return;
    }
    interval.current = setInterval(() => {
      if (
        lastUpdateRef.current !== null &&
        lastUpdateRef.current >
          moment().subtract(POLLING_INTERVAL_SECS, "seconds").toDate()
      ) {
        return;
      }
      if (jobId !== null && jobId !== undefined) {
        getJob(jobId)
          .then((jobRes) => {
            if (jobRes.status === "OutOfCredits") {
              // dispatch(openModal("outOfPages", { outOfCredits: true }));
              dispatch(
                createAlert({
                  timeout: 0,
                  type: "error",
                  messageWithUpdate: `You've run out of pages`,
                  message: "",
                })
              );
            }
            if (
              ["Succeeded", "Failed", "Canceled", "OutOfCredits"].includes(
                jobRes.status
              )
            ) {
              dispatch(fetchUser());
            }
            dispatch(
              setJobData({
                job: {
                  status: jobRes.status,
                  output: jobRes.output,
                  isGeneric: jobRes.isGeneric,
                  isBankMode: jobRes.isBankMode,
                  bankModeIfReconciled: jobRes.bankModeIfReconciled,
                  isPartial: jobRes.isPartial,
                  name: jobRes.name,
                  type: jobRes.type,
                },
                progress: jobRes.progress || null,
              })
            );
          })
          .catch((err) => logErrorToGA(err));
      }
    }, POLLING_INTERVAL);
    //console.log("set interval", interval.current);
  };

  React.useEffect(() => {
    if (
      jobId !== null &&
      !["InProgress", "Finishing"].includes(jobStatus || "")
    ) {
      cleanup();
    }
    if (
      jobId !== null &&
      ["InProgress", "Finishing"].includes(jobStatus || "") &&
      interval.current === null
    ) {
      setup();
    }
  }, [jobId, jobStatus]);

  React.useEffect(() => {
    setup();

    return () => {
      cleanup();
    };
  }, []);

  //console.log("JobPoller re-render");
  return null;
};
export default JobPoller;

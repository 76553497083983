import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Job } from "../../docuclipper/DocuclipperTypes";

const initialState: {
  open: boolean;
} = {
  open: false,
};

const slice = createSlice({
  name: "HelpDrawer",
  initialState,
  reducers: {
    setHelpDrawerOpen(state, action: PayloadAction<boolean>) {
      state.open = action.payload;
    },
  },
});

export const { setHelpDrawerOpen } = slice.actions;

export default slice.reducer;

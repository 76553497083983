import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User } from "../../docuclipper/DocuclipperTypes";
import { getUser } from "../../docuclipper/api";
import { ReduxState } from "..";

const initialState: {
  user: User | null;
  loading: boolean;
  error: Error | null;
} = {
  user: null,
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "Plans",
  initialState,
  reducers: {
    loadUser(state) {
      state.loading = true;
      state.error = null;
    },
    setUser(state, action: PayloadAction<User>) {
      state.loading = false;
      state.user = action.payload;
    },
    setError(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { loadUser, setError, setUser } = slice.actions;

export const fetchUser = () => async (dispatch, getState) => {
  const state: ReduxState = getState();
  if (state.User.loading) {
    return;
  }

  dispatch(loadUser());
  try {
    const user = await getUser();
    dispatch(setUser(user));
    // if (window && (window as any).profitwell) {
    //   (window as any).profitwell("start", { user_email: user.email });
    // }
  } catch (err) {
    dispatch(setError(err));
  }
};

export default slice.reducer;

import React from "react";
import { goTo } from "../../utils/utils";
type Props = {
  path: string;
};
const ExternalRedirect: React.SFC<Props> = ({ path }) => {
  React.useEffect(() => {
    if (window.location.pathname !== path) {
      goTo(path);
    }
  }, [path]);

  return null;
};

ExternalRedirect.defaultProps = {
  path: "/docuclipper/",
};

export default ExternalRedirect;

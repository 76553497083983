import React, { useEffect } from "react";
import { Button, Form, FormGroup, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateColumnConfigServer } from "../../../docuclipper/api";
import { ReduxState } from "../../../redux";
import {
  fetchColumnConfigs,
  setSelectedField,
  toggleModal,
  updateColumnConfig,
} from "../../../redux/reducers/ColumnConfig";
import { CustomFormat } from "../../Pages/CustomFormat/CustomFormat";
import { uniq, flatten } from "lodash";

type Props = { i: number };
export const ColumnConfigComp: React.FC<Props> = ({ i }) => {
  const dispatch = useDispatch();

  const { columnConfigs, selectedField } = useSelector(
    (state: ReduxState) => state.ColumnConfig
  );
  if (!selectedField) {
    return null;
  }
  return (
    <>
      <FormGroup>
        <Form.Check
          onChange={(e) =>
            dispatch(
              updateColumnConfig({ i, cc: { included: e.target.checked } })
            )
          }
          type="checkbox"
          checked={columnConfigs[selectedField][i].included}
          label="Included"
        />
      </FormGroup>
      <FormGroup>
        <Form.Control
          placeholder="heading"
          value={columnConfigs[selectedField][i].heading || ""}
          onChange={(e) =>
            dispatch(updateColumnConfig({ i, cc: { heading: e.target.value } }))
          }
        />
      </FormGroup>
    </>
  );
};

export const ColumnConfigs = () => {
  const { totalsByDocumentId } = useSelector(
    (state: ReduxState) => state.Reconciler
  );

  const { selectedField, columnConfigs } = useSelector(
    (state: ReduxState) => state.ColumnConfig
  );

  const { job } = useSelector((state: ReduxState) => state.JobData);
  React.useEffect(() => {
    if (job && job.template) {
      dispatch(fetchColumnConfigs());
    }
  }, [job]);

  const allFields = Object.keys(totalsByDocumentId || {}).map((documentId) =>
    Object.keys(totalsByDocumentId[documentId] || {})
  );
  const uniqueFields = uniq(flatten(allFields));

  const dispatch = useDispatch();
  if (uniqueFields.length > 0 && !selectedField) {
    dispatch(setSelectedField(uniqueFields[0]));
  }

  const selectedColumnConfig = selectedField
    ? columnConfigs[selectedField]
    : null;
  let cols: any = null;
  if (selectedColumnConfig) {
    cols = selectedColumnConfig.map((cc, i) => <ColumnConfigComp i={i} />);
  }
  return (
    <>
      <Form.Control
        as="select"
        onChange={(e) => dispatch(setSelectedField(e.target.value))}
      >
        {uniqueFields.map((option) => (
          <option selected={selectedField === option} value={option}>
            {option}
          </option>
        ))}
      </Form.Control>
      {cols}
    </>
  );
};

export const ColumnConfigModal: React.FC = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: ReduxState) => state.ColumnConfig.isModalOpen);
  const { columnConfigs, selectedField } = useSelector(
    (state: ReduxState) => state.ColumnConfig
  );
  const { job } = useSelector((state: ReduxState) => state.JobData);

  const toggle = () => {
    dispatch(toggleModal(!isOpen));
  };

  useEffect(() => {
    if (isOpen) {
      if (job && job.template && selectedField) {
        updateColumnConfigServer(
          job.template.id,
          selectedField,
          columnConfigs[selectedField]
        ).catch((err) => null);
      }
    }
  }, [isOpen])

  return (
    <>
      <Modal size="xl" show={isOpen} onHide={toggle}>
        <Modal.Header closeButton={true}>
          <strong>Configure Columns</strong>
        </Modal.Header>

        <Modal.Body>
          {job?.type && (<CustomFormat jobType={job.type} />)}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={toggle}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ColumnConfigModal;

import {
  JobDocument,
  TemplateFieldV2,
  TransactionLine,
} from "../../../../docuclipper/DocuclipperTypes";
import { isNullOrUndefined } from "../../../../utils/utils";

export const getDocumentName = (jobDocs: JobDocument[], documentId: string) => {
  // console.log({ jobDocs, documentId })
  const doc = jobDocs.find((d) => d.documentId === documentId);
  return doc ? doc.originalname : documentId;
};

export const convertTlsToTable = (
  transactions: TransactionLine[],
  documents,
  job,
  hasQbo,
  accountChunkLabels
) => {
  const template = job ? job.template || null : null;
  let rectangles: TemplateFieldV2[] = [];
  if (template) {
    try {
      const templateObj = JSON.parse(template.template);
      rectangles = templateObj.rectangles;
    } catch (err) {
      //
    }
  }

  const getColumnMapping = (fieldName: string) => {
    const empty = {
      date: null,
      date2: null,
      amount: null,
      name: null,
      credit: null,
      debit: null,
      balance: null,
      checkNumber: null,
      category: null,
      editedCategory: null,
      payee: null,
    };
    const r = rectangles.filter((x) => x.name === fieldName);
    if (r && r.length > 0) {
      const qbo = r[0].qbo;
      return qbo ? qbo.columnMapping || empty : empty;
    }
    return empty;
  };

  const columnsWithNoData = {
    date: null,
    date2: null,
    amount: null,
    name: null,
    credit: null,
    debit: null,
    balance: null,
    checkNumber: null,
    category: null,
    editedCategory: null,
    payee: null,
  };

  const data = (transactions || []).map((row) => {
    let columnMapping;
    if (row.fieldName === "bankMode") {
      columnMapping = {
        date: 0,
        name: 1,
        amount: 2,
        checkNumber: 3,
        balance: 4,
        date2: 5,
        refNumber: 6,
        category: 7,
        editedCategory: 8,
        payee: 9,
      };
    } else {
      columnMapping = getColumnMapping(row.fieldName) || {};
    }
    const obj = typeof row.row === 'string' ? JSON.parse(row.row) : row.row;
    for (const key in columnMapping || {}) {
      if (!isNullOrUndefined(columnMapping[key])) {
        delete columnsWithNoData[key];
      }
    }
    const otherCols = {};
    if (!hasQbo) {
      for (let i = 0; i < (obj || []).length; i += 1) {
        otherCols[`col${i}`] = obj[i];
      }
    }
    return {
      id: row.id,
      chunk: row.chunk,
      period: accountChunkLabels ? accountChunkLabels[row.chunk || ""] : '',
      category: row.editedCategory || row.category,
      categoryKeyword: row.categoryKeyword,
      editedCategory: row.editedCategory,
      account: row.account,
      pageNumber: row.pageNumber,
      transactionId: row.id,
      rowNumber: row.rowNumber,
      included: row.included,
      documentId: row.documentId,
      documentName: getDocumentName(documents, row.documentId),
      status: row.status,
      fieldName: row.fieldName,
      tags: row.tags || [],
      date: !isNullOrUndefined(columnMapping.date)
        ? obj[columnMapping.date as number] || ""
        : null,
      date2: !isNullOrUndefined(columnMapping.date2)
        ? obj[columnMapping.date2 as number] || ""
        : null,
      description: !isNullOrUndefined(columnMapping.name)
        ? obj[columnMapping.name as number] || ""
        : null,
      amount: !isNullOrUndefined(columnMapping.amount)
        ? obj[columnMapping.amount as number] || ""
        : null,
      debit: !isNullOrUndefined(columnMapping.debit)
        ? obj[columnMapping.debit as number] || ""
        : null,
      credit: !isNullOrUndefined(columnMapping.credit)
        ? obj[columnMapping.credit as number] || ""
        : null,
      balance: !isNullOrUndefined(columnMapping.balance)
        ? obj[columnMapping.balance as number] || ""
        : null,
      checkNumber: !isNullOrUndefined(columnMapping.checkNumber)
        ? obj[columnMapping.checkNumber as number] || ""
        : null,
      refNumber: !isNullOrUndefined(columnMapping.refNumber)
        ? obj[columnMapping.refNumber as number] || ""
        : null,
      payee: !isNullOrUndefined(columnMapping.payee)
        ? obj[columnMapping.payee as number] || ""
        : null,
      columnMapping,
      ...otherCols,
    };
  });

  return data;
};

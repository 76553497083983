import React from "react";
import { Badge, Form, FormGroup, Row } from "react-bootstrap";
import {
  DocuClipperBillingCycle,
  DocuClipperPlan,
  DocuClipperPricing,
  StripeSubscription,
} from "../../../../docuclipper/DocuclipperTypes";
import { getCurrencySymbol } from "./PlanSelector2";
type Props = {
  onSelected: (billingCycle: DocuClipperBillingCycle) => any;
  pricing: DocuClipperPricing;
  discountedPricing: DocuClipperPricing | null;
  selectedPlan: DocuClipperPlan;
  selectedBillingCycle: DocuClipperBillingCycle;
  subscription: StripeSubscription;
  billingCycleOptions?: DocuClipperBillingCycle[] | null;
};
export const BillingCycleSelector: React.FC<Props> = ({
  onSelected,
  pricing,
  discountedPricing,
  selectedPlan,
  selectedBillingCycle,
  subscription,
  billingCycleOptions,
}) => {
  // console.log({ pricing, billingCycleOptions });
  const getDiscounted = (originalPrice) => {
    if (!subscription || !subscription.coupon) {
      return originalPrice;
    }
    return Math.round((originalPrice * subscription.coupon.percent_off) / 100);
  };
  const monthlyAmount = Math.round(pricing[selectedPlan]["Monthly"].amount);
  const monthlyAmountDiscounted = discountedPricing
    ? Math.round(discountedPricing[selectedPlan]["Monthly"].amount)
    : monthlyAmount;

  const annualAmount = Math.floor(pricing[selectedPlan]["Annually"].amount);
  const annualAmountDiscounted = discountedPricing
    ? Math.floor(discountedPricing[selectedPlan]["Annually"].amount)
    : annualAmount;

  const monthlyAmountInAnnualPlanDiscounted = Math.floor(
    annualAmountDiscounted / 12
  );

  const currencySymbol = getCurrencySymbol(
    pricing[selectedPlan]["Annually"].currency
  );
  const annualLabel = `${currencySymbol}${monthlyAmountInAnnualPlanDiscounted} / month, annual plan billed upfront`;

  const annualSavings =
    pricing[selectedPlan]["Annually"].annualSavings.toFixed(0);
  const annualSavingsDiscounted = getDiscounted(annualSavings);

  const annualBilledMonthlyAmount = Math.floor(
    pricing[selectedPlan]["AnnuallyBilledMonthly"].amount
  );
  const annualBilledMonthlyLabel = (
    <>{`${currencySymbol}${annualBilledMonthlyAmount} / month, annual plan billed monthly, 12-month commitment`}</>
  );
  const annualBilledMonthlySavings =
    pricing[selectedPlan]["AnnuallyBilledMonthly"].annualSavings.toFixed(0);
  const annualBilledMonthlySavingsDiscounted = getDiscounted(
    annualBilledMonthlySavings
  );

  return (
    <FormGroup className="m-2">
      {(!billingCycleOptions || billingCycleOptions.includes("Monthly")) && (
        <FormGroup
          className={`option-container ${
            selectedBillingCycle === "Monthly" ? "selected-option" : ""
          }`}
          onClick={() => onSelected("Monthly")}
        >
          {
            <div className="p-1">
              <Form.Check
                type="radio"
                value="Monthly"
                checked={selectedBillingCycle === "Monthly"}
                onChange={() => onSelected("Monthly")}
                label={
                  <>
                    <div className="price-details">
                      {`${currencySymbol}${monthlyAmountDiscounted} ${
                        [
                          "Starter",
                          "Starter100",
                          "Business",
                          "Professional",
                        ].includes(selectedPlan)
                          ? "/ month"
                          : " / month / user"
                      }`}
                    </div>
                  </>
                }
              />
            </div>
          }
        </FormGroup>
      )}
      {(!billingCycleOptions || billingCycleOptions.includes("Annually")) && (
        <FormGroup
          className={`option-container ${
            selectedBillingCycle === "Annually" ? "selected-option" : ""
          }`}
          onClick={() => onSelected("Annually")}
        >
          <Row className="p-1 align-items-center mx-0">
            <div>
              <Form.Check
                type="radio"
                value="Annually"
                checked={selectedBillingCycle === "Annually"}
                onChange={() => onSelected("Annually")}
                label={<div className="price-details">{annualLabel}</div>}
              />
            </div>
            <div className="savings-text  ml-2 mt-1">
              {`Save ${currencySymbol}${annualSavingsDiscounted} per year!`}
            </div>
          </Row>
        </FormGroup>
      )}
      {(!billingCycleOptions ||
        billingCycleOptions.includes("AnnuallyBilledMonthly")) && (
        <FormGroup
          className={`option-container ${
            selectedBillingCycle === "AnnuallyBilledMonthly"
              ? "selected-option"
              : ""
          }`}
          onClick={() => onSelected("AnnuallyBilledMonthly")}
        >
          <Row className="p-1 align-items-center mx-0">
            <div>
              <Form.Check
                type="radio"
                value="AnnuallyBilledMonthly"
                checked={selectedBillingCycle === "AnnuallyBilledMonthly"}
                onChange={() => onSelected("AnnuallyBilledMonthly")}
                label={
                  <div className="price-details">
                    {annualBilledMonthlyLabel}
                  </div>
                }
              />
            </div>
            <div className="savings-text ml-2 mt-1">
              {`Save ${currencySymbol}${annualBilledMonthlySavingsDiscounted} per year!`}
            </div>
          </Row>
          {/* <Row className="ml-2">
            <Badge variant="info" className=" ml-2">
              12-month commitment
            </Badge>
          </Row> */}
        </FormGroup>
      )}
    </FormGroup>
  );
};
export default BillingCycleSelector;

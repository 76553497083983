import React, { useState } from "react";
import { Form, Button, Alert, Container, Row, Col } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const TwoFactorAuth = () => {
  const [token, setToken] = useState("");
  const location = useLocation();
  const tempToken = queryString.parse(location.search).token;

  const handleSubmit = (e: React.FormEvent) => {
    if (!token || !tempToken) {
      e.preventDefault();
    }
  };

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col md={6}>
          <h2 className="text-center mb-4">Two-Factor Authentication</h2>
          <Form
            onSubmit={handleSubmit}
            action="/api/v1/auth/verify2fa"
            method="POST"
          >
            <Form.Group className="mb-3">
              <Form.Label>Enter 2FA Code</Form.Label>
              <Form.Control
                type="text"
                name="token"
                value={token}
                onChange={(e) => setToken(e.target.value)}
                placeholder="Enter 6-digit code"
                required
                pattern="[0-9]{6}"
              />
            </Form.Group>

            <input type="hidden" name="tempToken" value={tempToken || ""} />

            <Button variant="primary" type="submit" className="w-100">
              Verify
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default TwoFactorAuth;

import React, { useEffect, useState } from "react";
import { Row, Form, Col, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../../../redux";
import { LoadingTrackedButton } from "../../../../Docuclipper/TrackedButton";
import DateFormatOptions from "./DateFormatOptions";
import { downloadSpreadsheetHelper } from "./utils";
import CsvColumnNames from "./CsvColumnNames";
import { setReloadExportPreview } from "src/redux/reducers/DownloadInvoiceOptions";
import { setDownloadInvoiceModalOpen } from "src/redux/reducers/JobExport";
import { ExportType } from "src/docuclipper/DocuclipperTypes";
import { InvoiceFieldsSelector } from "./InvoiceFieldsSelector";

type Props = {
  cta?: string;
};
interface PreviewTable {
  columns: string[];
  rows: (string | number)[][]; // Array of arrays of data that can be strings or numbers
}

export const CsvInvoiceOptions: React.FC<Props> = ({ cta }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [fileType, setFileType] = useState("CSV");
  const [previewTable, setPreviewTable] = useState<PreviewTable>({
    columns: [],
    rows: [],
  });

  const {
    selectedExportType,
    selectedCustomFormatType,
    sortBy,
    sortDirection,
    reloadExportPreview,
    options,
  } = useSelector((state: ReduxState) => state.DownloadInvoiceOptions);

  const { job } = useSelector((state: ReduxState) => state.JobData);

  const { downloadInvoiceModalOpen } = useSelector(
    (state: ReduxState) => state.JobExport
  );

  const { downloadOptions, transactionLines, selectedExportFields } =
    useSelector((state: ReduxState) => state.InvoiceManager);
  const { T, T2, T3, T4 } = useSelector(
    (state: ReduxState) => state.GenericOptions
  );
  const tlIds = transactionLines.map((tl) => tl.id || "").filter((id) => id);

  const { selectedDocumentIds, selectedFieldNames } = downloadOptions;

  // Get available fields based on the current invoice data

  const onExport = ({ preview = false }) => {
    setLoading(true);
    downloadSpreadsheetHelper(
      job,
      "Invoice",
      selectedCustomFormatType as ExportType,
      {
        T,
        T2,
        T3,
        T4,
        dateFormat: options[`docuclipper.prefs.dateFormat`] || "",
        fileType: fileType.toUpperCase(),
      },
      selectedDocumentIds,
      selectedFieldNames,
      tlIds,
      [],
      sortBy,
      sortDirection,
      fileType as "CSV" | "XLSX",
      preview,
      selectedExportFields
    )
      .then((data) => {
        if (preview) {
          setPreviewTable({ columns: data.columns, rows: data.rows });
        } else {
          dispatch(setDownloadInvoiceModalOpen(false));
        }
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  if (fileType === "XLSX") {
    cta = cta?.replace("CSV", "XLSX");
  }

  useEffect(() => {
    if (reloadExportPreview || downloadInvoiceModalOpen) {
      onExport({
        preview: true,
      });
      dispatch(setReloadExportPreview(false));
    }
  }, [reloadExportPreview, downloadInvoiceModalOpen]);

  return (
    <div>
      <Row>
        <Col className="my-2">
          <strong>File Type</strong>
          <div className="d-flex">
            <Form.Check
              value="CSV"
              checked={fileType === "CSV"}
              onChange={(e) => setFileType(e.currentTarget.value)}
              type="radio"
              label="CSV"
            />
            <Form.Check
              className="ml-2"
              value="XLSX"
              checked={fileType === "XLSX"}
              onChange={(e) => setFileType(e.currentTarget.value)}
              type="radio"
              label="XLSX"
            />
          </div>
        </Col>

        <Col className="my-2">
          <div>
            <div className="font-weight-bold">Date Format</div>
            <DateFormatOptions />
          </div>
        </Col>
      </Row>

      <CsvColumnNames selectedExportType={selectedExportType} />
      {previewTable.columns?.length > 0 && previewTable.rows?.length > 0 && (
        <>
          <strong>Preview</strong>
          {!loading ? (
            <Row>
              <Col>
                <Table striped bordered hover className="m-0">
                  <thead>
                    <tr>
                      {previewTable.columns.map((column, idx) => (
                        <th key={idx}>{column}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {previewTable.rows.map((row, idx) => (
                      <tr key={idx}>
                        {row.map((cell, cellIdx) => (
                          <td key={cellIdx}>{cell}</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          ) : (
            <div>Loading Preview...</div>
          )}
        </>
      )}

      <Row className="justify-content-end mx-1 mt-4">
        <LoadingTrackedButton
          isLoading={loading}
          className={`btn-save-${selectedExportType} m-2`}
          variant="primary"
          onClick={() => onExport({ preview: false })}
        >
          {`${cta}`}
        </LoadingTrackedButton>
      </Row>
    </div>
  );
};
CsvInvoiceOptions.defaultProps = {
  cta: "Download",
};
export default CsvInvoiceOptions;

import React from "react";
import { InlineEditableTextfield } from "../inline-edit/src";
// import EdiText from "react-editext";

// import styled from "styled-components";
type Props = {
  initialValue: string;
  onConfirm: (value: string) => any;
  regex?: string;
  errorMsg?: string;
};

// export const EditableText2: React.FC<Props> = ({
//   initialValue,
//   onConfirm,
//   ...rest
// }) => {
//   return (
//     <div>
//       <EdiText type="text" value={initialValue} onSave={onConfirm} />
//     </div>
//   );
// };
export const EditableText: React.SFC<Props> = ({
  initialValue,
  onConfirm,
  regex,
  errorMsg,
  ...rest
}) => {
  const [value, setValue] = React.useState(initialValue || "");
  const [error, setError] = React.useState("");

  return (
    <>
      <InlineEditableTextfield
        {...rest}
        placeholder=""
        defaultValue={value}
        onConfirm={(e) => {
          e = e.trim();
          if (regex && !new RegExp(regex, "i").exec(e)) {
            setError(errorMsg || "Validation Error");
          } else {
            setValue(e);
            setError("");
            onConfirm(e);
          }
        }}
      />

      {error && <span className="text-danger">{error}</span>}
    </>
  );
};

export default EditableText;

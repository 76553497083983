import Bottleneck from "bottleneck";
import queryString from "query-string";
import { isNullOrUndefined } from "util";
import { getJwt, logUserIn } from "../auth";
import { lsGetItem } from "../LocalStorageWrapper";
import store, { ReduxState } from "../redux";
import { CREATE_ALERT, createAlert, REMOVE_ALERT } from "../redux/reducers/Alerts";
import { DELETE_TOKEN } from "../redux/reducers/Auth";
import { openModal } from "../redux/reducers/ModalManager";
import { fetchLastUsedTemplates } from "../redux/reducers/UserRecentTemplateServer";
import {
  fetchWithTimeout,
  goToNewTab,
  sendGAEvent,
  sleep,
} from "../utils/utils";
import {
  ApiError,
  ApiTableParams,
  InvoiceApprovalStatus,
  CustomFormat,
  CustomFormatType,
  DocType,
  DocuClipperBillingCycle,
  DocuClipperPlan,
  DocuClipperPromotions,
  Document,
  EdgeType,
  ExportType,
  FieldType,
  File,
  FilteredItem,
  Fragment,
  InvoiceApprovalRule,
  InvoiceRoutingRule,
  Job,
  Page,
  PdfCommands,
  Rectangle,
  ReferredUser,
  SortedItem,
  StripeCoupon,
  StripeCustomer,
  StripePlan,
  StripeSubscription,
  TagCategoryRule,
  TagInvoiceRule,
  TagRegexesType,
  TagRule,
  Template,
  TemplateAddedColumnMapping,
  TemplateFieldV2,
  Thumbnail,
  ToolType,
  User,
  VariableLocation,
  VariableLocationEdges,
  XPosition,
  YPosition,
  PotentialMatches,
  MatchGroup,
  Tag,
  ApprovalCheck,
  ApprovalProgress,
  TableQueryParams,
  TableFilterConfig,
  ContractAddonsAttributes,
  SubscriptionItemAttributes,
} from "./DocuclipperTypes";
import { getUiVersion } from "./Logger";
import { fetchOnboardingSteps } from "src/redux/reducers/OnboardingSteps";
import { openSurvey } from "src/redux/reducers/Survey";

const API_PREFIX = "/api/v1/protected";
const UNAUTH_API_PREFIX = "/api/v1";

export const PERMISSIONS_ERROR = "permissions-error";

//
// limiters
//
const limiter1ReqPerSec = new Bottleneck({
  minTime: 1000, // 1 req / sec
  maxConcurrent: 1,
});

const limiter2ReqsPerSec = new Bottleneck({
  minTime: 500, // 2 reqs / sec
  maxConcurrent: 1,
});

// Add this near the other limiters (around line 70)
const uploadLimiter = new Bottleneck({
  maxConcurrent: 1,  // Only one upload at a time
  minTime: 0  // No minimum time between uploads
});

const withTracking = async (
  eventType: string,
  url: string,
  fetchArgs,
  onprogress?: (e) => any,
  timeout?: number
) => {
  try {
    return withTrackingHelper(
      eventType,
      url,
      fetchArgs,
      10,
      onprogress,
      timeout
    );
  } catch (err) {
    if ((err as any).type === PERMISSIONS_ERROR) {
      // try refreshing the permissions
      const rsp = await getToken();
      if (rsp.token) {
        logUserIn(rsp.token);
        return withTrackingHelper(
          eventType,
          url,
          fetchArgs,
          10,
          onprogress,
          timeout
        );
      } else {
        throw err;
      }
    }
  }
};

function isVersionNewer(version1, version2) {
  const parts1 = version1.split(".");
  const parts2 = version2.split(".");

  for (let i = 0; i < 3; i++) {
    const num1 = parseInt(parts1[i]);
    const num2 = parseInt(parts2[i]);

    if (num1 > num2) {
      return true;
    } else if (num1 < num2) {
      return false;
    }
  }

  // If the versions are equal
  return false;
}

const withTrackingHelper = async (
  eventType: string,
  url: string,
  fetchArgs,
  numRetries: number = 10,
  onprogress?: (e) => any,
  timeout?: number
) => {
  if (!("headers" in fetchArgs)) {
    fetchArgs.headers = {};
  }
  fetchArgs.headers["X-Docuclipper-Ui-Version"] = getUiVersion();
  for (let retry = 0; retry < numRetries; retry += 1) {
    // sendGAEvent({ action: eventType, category: "API request", label: "" });
    // const t0 = Math.round(performance.now());
    let status = 500;
    try {
      const response = await fetchWithTimeout(
        url,
        fetchArgs,
        timeout || 30000,
        onprogress
      );
      status = response.status;
      const backendUiVersion = response.headers.get("X-Docuclipper-Ui-Version");
      const uiVersion = getUiVersion();
      const trialLimit = response.headers.get("X-Docuclipper-Trial-Limit");
      if (trialLimit && !isNaN(trialLimit as any)) {
        store.dispatch({
          type: "TrialLimitation/setShowModal",
          payload: true,
        });
        store.dispatch({
          type: "TrialLimitation/setShowBanner",
          payload: true,
        });
      }
      if (
        backendUiVersion &&
        new RegExp("^[\\.\\d]+$").exec(backendUiVersion) &&
        uiVersion &&
        new RegExp("^[\\.\\d]+$").exec(uiVersion) &&
        backendUiVersion !== uiVersion &&
        isVersionNewer(backendUiVersion, uiVersion)
      ) {
        // console.log('backendUiVersion: ', backendUiVersion);
        // console.log('uiVersion: ', uiVersion);


        store.dispatch({
          type: "Announcement/setAnnouncement",
          payload: {
            externalLink: "",
            id: "reloadNewVersion",
            imageKey: "reloadNewVersion",
            link: "",
            subtitle: "There's a new version of the software available",
            title: "New Version Available",
          },
        });
      }
      if ([
        "CreateJob",
        "downloadSpreadsheet",
        "getJobMetadataFields",
        "categorizeJobTransactionsAsync",
      ].includes(eventType)) {
        store.dispatch(fetchOnboardingSteps())
      }
      const contentType = response.headers.get("content-type");
      let response2;
      if (
        response.ok &&
        fetchArgs &&
        fetchArgs.headers &&
        fetchArgs.headers.responseType &&
        fetchArgs.headers.responseType.toLowerCase() === "blob"
      ) {
        response2 = await response.blob();
      } else if (
        contentType &&
        contentType.indexOf("application/json") !== -1
      ) {
        response2 = await response.json();
      } else {
        response2 = await response.text();
      }

      // const value = Math.round(performance.now() - t0);
      // sendGATiming(eventType, value);

      if (status === 429 || status === 502) {
        await sleep(100 * 2 ** retry);
        continue;
      }
      if (status >= 400 && status <= 599) {
        if (status === 401) {
          store.dispatch({
            type: DELETE_TOKEN,
            payload: {},
          });
          // window.location.href = "/login";
        } else if (status === 403) {
          const error = new Error(response2.errorMessage);
          (error as any).type = PERMISSIONS_ERROR;
          throw error;
        }
        if (response2.errorCode === 1034) {
          store.dispatch(
            // openModal("outOfPages", {
            //   outOfCredits: true,
            //   ...(response2.data || {}),
            // })
            createAlert({
              timeout: 0,
              type: "error",
              messageWithUpdate: `You've run out of pages`,
              message: "",
            })
          );
        }
        if (response2.errorCode === 1042) {
          setTimeout(() => {
            store.dispatch(
              // openUpgradePlanModal(
              //   response2.data.neededPlan,
              //   response2.data.feature
              // )
              createAlert({
                timeout: 0,
                type: "error",
                messageWithUpdate: `Your plan doesn't include this feature`,
                message: "",
              })
            );
          }, 1000)

        }
        if (response2.errorCode === 1061) {
          store.dispatch(openModal("awsOnboarding", {}));
          return;
        }
        if (response2.errorCode !== undefined && response2.errorCode !== null) {
          throw new ApiError(
            status,
            response2.errorCode,
            response2.errorMessage || "Error performing operation",
            response2.data || []
          );
        }
        throw Error(response2.errorMessage || "Error performing operation");
      }
      return response2;
    } catch (error) {
      sendGAEvent({ action: eventType, category: "API error", label: "" });
      if (![1034, 1042, 1061, 1064].includes((error as any).errorCode)) {
        store.dispatch({
          type: CREATE_ALERT,
          payload: {
            alert: {
              type: "error",
              timeout: 0,
              message: (error as any).message,
            },
          },
        });
      }
      throw error;
    }
  }
  sendGAEvent({ action: eventType, category: "API error", label: "" });
  const message = "Max number of retries reached";
  store.dispatch({
    type: CREATE_ALERT,
    payload: {
      alert: {
        type: "error",
        timeout: 0,
        message,
      },
    },
  });
  throw new Error(message);
};

const uploadFileHelper = (
  file: File["file"],
  // asyncProcessing: boolean,
  // isCamera: boolean,
  // customerId: number | null,
  endpoint: string,
  onprogress?: (e) => any,
  timeout?: number
) => {
  const data = new FormData();
  data.append("document", file as any);

  return withTracking(
    "UploadedDocument",
    `${API_PREFIX}/${endpoint}`,
    {
      method: "POST",
      body: data,
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    },
    onprogress,
    timeout
  );
};

export const uploadFile = (
  file: File["file"],
  endpoint: string,
  onprogress?: (e) => any
): Promise<Document> => {
  return uploadLimiter.schedule(() =>
    uploadFileHelper(file, endpoint, onprogress, 1200000).then(
      (rsp) => rsp.document
    )
  );
};

export const uploadCompressedFile = ({
  content,
  file,
  onprogress,
}: {
  content: string;
  file: File;
  onprogress?: (e) => any;
}) => {
  const data = new FormData();
  data.append("metadata", JSON.stringify(file));
  data.append("content", content);

  return uploadLimiter.schedule(() =>
    withTracking(
      "UploadedCompressedDocument",
      `${API_PREFIX}/document/compressed`,
      {
        method: "POST",
        body: data,
        headers: {
          Authorization: `Bearer ${getJwt()}`,
        },
      },
      onprogress
    ).then((rsp) => rsp.document)
  );
};

export const parseCsv = ({
  documentId,
  dryRun,
  templateId,
}: {
  documentId: number;
  dryRun: boolean;
  templateId: number;
}) => {
  return withTracking(
    "parseCsv",
    `${API_PREFIX}/template/${templateId}/addedColumnMappings/addItemsCsv`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ documentId, dryRun }),
    }
  )
    .then((rsp) => rsp.csvLineStatuses)
    .catch((err) => {
      throw err;
    });
};

export const deleteDocument = (documentId: number) => {
  return withTracking(
    "DeletedDocument",
    `${API_PREFIX}/document/${documentId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  );
};

export const getDocumentFraudSignals = (documentId) => {
  return withTracking(
    "getDocumentFraudSignals",
    `${API_PREFIX}/document/${documentId}/fraudSignals`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  );
};

export const getDocumentFraudScore = (documentId) => {
  return withTracking(
    "getDocumentFraudScore",
    `${API_PREFIX}/document/${documentId}/fraudScore`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  ).then(x => x.score);
};

export const getPageUnthrottled = (
  documentId: number,
  pageNumber: number
): Promise<{ page: Page; data: string }> => {
  return withTracking(
    "GotPage",
    `${API_PREFIX}/document/${documentId}/page/${pageNumber}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  );
};
export const getPage = limiter1ReqPerSec.wrap(getPageUnthrottled);

export const getPageImageUnthrottled = (
  documentId: number,
  pageNumber: number
): Promise<{ page: Page; data: string }> => {
  return withTracking(
    "getPageImage",
    `${API_PREFIX}/document/${documentId}/page/${pageNumber}/image`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  );
};
export const getPageImage = limiter1ReqPerSec.wrap(getPageImageUnthrottled);

export const getTemplateFields = (documentId: number, pageNumber: number) => {
  return withTracking(
    "GetTemplateFields",
    `${API_PREFIX}/document/${documentId}/page/${pageNumber}/fields`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  );
};

const getThumbnailsUnthrottled = (
  documentId: number,
  offset: number,
  limit: number = 10
): Promise<Thumbnail[]> => {
  return withTracking(
    "GotThumbnails",
    `${API_PREFIX}/document/${documentId}/thumbnails?offset=${offset}&limit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  ).then((response) => {
    const thumbnails = response.thumbnails.map((t) => ({
      height: t.page.height,
      width: t.page.width,
      imageSrc: t.data,
      pageNumber: t.page.pageNumber,
    }));
    return thumbnails;
  });
};
export const getThumbnails = limiter1ReqPerSec.wrap(getThumbnailsUnthrottled);

const getTableUnthrottled = ({
  documentId,
  pageNumber,
  jobId,
  tableConfig,
  fragmentId,
  toolType,
  x0,
  x1,
  y0,
  y1,
}: {
  documentId: number;
  pageNumber: number;
  jobId: number | null;
  tableConfig: TemplateFieldV2["tableConfig"];
  fragmentId: number | null;
  toolType: ToolType;
  x0: number;
  x1: number;
  y0: number;
  y1: number;
}) => {
  return withTracking(
    "GotTable",
    `${API_PREFIX}/document/${documentId}/page/${pageNumber}/table`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        x0,
        x1,
        y0,
        y1,
        jobId,
        tableConfig,
        fragmentId: fragmentId ? parseInt(fragmentId.toString(), 10) : null,
        toolType,
      }),
    }
  );
};

const getVTableUnthrottled = ({
  documentId,
  pageNumber,
  variableLocations,
  tableConfig,
  toolType,
}: {
  documentId: number;
  pageNumber: number;
  variableLocations: VariableLocationEdges[];

  tableConfig: TemplateFieldV2["tableConfig"];

  toolType: ToolType;
}) => {
  return withTracking(
    "GotVTable",
    `${API_PREFIX}/document/${documentId}/page/${pageNumber}/vtable?`,
    {
      method: "POST",
      body: JSON.stringify({
        variableLocations,

        tableConfig,
        toolType,
      }),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

const getFragmentUnthrottled = ({
  documentId,
  pageNumber,
  x0,
  x1,
  y0,
  y1,
  toolType,
  jobId,
  fieldType,
  fragmentId,
  checkLabels,
}: {
  documentId: number;
  pageNumber: number;
  x0: number;
  x1: number;
  y0: number;
  y1: number;
  toolType: string;
  jobId: number | null;
  fieldType: FieldType;
  fragmentId: number | null;
  // rectangleId: string,
  checkLabels: Rectangle["checkLabels"];
}): Promise<Fragment> => {
  return withTracking(
    "GotFragment",
    `${API_PREFIX}/document/${documentId}/page/${pageNumber}/fragment`,
    {
      method: "post",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        x0,
        x1,
        y0,
        y1,
        toolType,
        jobId,
        fieldType,
        // rectangleId,
        fragmentId: fragmentId ? parseInt(fragmentId.toString(), 10) : null,
        checkLabels,
      }),
    }
  );
};

const getVFragmentUnthrottled = ({
  documentId,
  pageNumber,
  variableLocations,
  toolType,
  fieldType,
  tableConfig,
  checkLabels,
}: {
  documentId: number;
  pageNumber: number;
  variableLocations: VariableLocation["variableLocations"];
  toolType: ToolType;
  fieldType: FieldType;
  tableConfig: TemplateFieldV2["tableConfig"];
  checkLabels: TemplateFieldV2["checkLabels"];
}): Promise<{ fragments: Fragment[]; errors: string[] }> => {
  return withTracking(
    "GotVFragment",
    `${API_PREFIX}/document/${documentId}/page/${pageNumber}/vfragment`,
    {
      method: "post",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        variableLocations,
        toolType,
        fieldType,
        tableConfig,
        checkLabels,
      }),
    }
  );
};
export const getTable = limiter1ReqPerSec.wrap(getTableUnthrottled);
export const getVTable = limiter1ReqPerSec.wrap(getVTableUnthrottled);

export const getFragment = limiter1ReqPerSec.wrap(getFragmentUnthrottled);
export const getVFragment = limiter1ReqPerSec.wrap(getVFragmentUnthrottled);

export const getCustomerSupportInfo = ({ email }: { email: string }) => {
  return withTracking(
    "getCustomerSupportInfo",
    `${API_PREFIX}/support/byEmails/`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getJwt()}`,
      },
      method: "POST",
      body: JSON.stringify({ emails: [email] }),
    }
  );
};

export const customerSupportUpdatePages = ({
  credits,
  purchasedCredits,
  userId,
}: {
  credits: string;
  purchasedCredits: string;
  userId;
}) => {
  return withTracking(
    "customerSupportUpdatePages",
    `${API_PREFIX}/support/updatePages/`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getJwt()}`,
      },
      method: "POST",
      body: JSON.stringify({ credits, purchasedCredits, userId }),
    }
  );
};

export const customerSupportUpdateSubscription = ({
  email, subscriptionId,
}: {
  email: string;
  subscriptionId: string;
}) => {
  return withTracking(
    "customerSupportUpdatePages",
    `${API_PREFIX}/support/updateSubscription/`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getJwt()}`,
      },
      method: "POST",
      body: JSON.stringify({ email, subscriptionId }),
    }
  );
};

export const customerSupportResetPassword = ({ userId }: { userId }) => {
  return withTracking(
    "customerSupportResetPassword",
    `${API_PREFIX}/support/resetPassword`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getJwt()}`,
      },
      method: "POST",
      body: JSON.stringify({ userId }),
    }
  );
};

export const customerSupportAddToUnlimitedTrial = ({
  domain,
}: {
  domain: string;
}) => {
  return withTracking(
    "customerSupportAddToUnlimitedTrial",
    `${API_PREFIX}/support/unlimitedTrial`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getJwt()}`,
      },
      method: "POST",
      body: JSON.stringify({ domain }),
    }
  );
};

export const customerSupportDisableEmail = ({
  email,
}: {
  email: string;
}) => {
  return withTracking(
    "customerSupportDisableEmail",
    `${API_PREFIX}/support/disableEmail`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getJwt()}`,
      },
      method: "POST",
      body: JSON.stringify({ email }),
    }
  );
};

export const customerSupportAddToDontEmail = ({ email }: { email: string }) => {
  return withTracking(
    "customerSupportAddToDontEmail",
    `${API_PREFIX}/support/dontEmail`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getJwt()}`,
      },
      method: "POST",
      body: JSON.stringify({ email }),
    }
  );
};

export const customerSupportUploadProspectCsv = ({
  documentId,
  campaignId,
}: {
  documentId: number;
  campaignId: number;
}) => {
  return withTracking(
    "customerSupportUploadProspectCsv",
    `${API_PREFIX}/support/prospectCsv`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getJwt()}`,
      },
      method: "POST",
      body: JSON.stringify({ documentId, campaignId }),
    }
  );
};

export const customerSupportGetProspectCampaigns = () => {
  return withTracking(
    "customerSupportGetProspectCampaigns",
    `${API_PREFIX}/support/prospectCampaigns`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getJwt()}`,
      },
      method: "GET",
    }
  ).then((rsp) => rsp.campaigns);
};

export const customerSupportGetProspects = (campaignId) => {
  return withTracking(
    "customerSupportGetProspects",
    `${API_PREFIX}/support/prospects/${campaignId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getJwt()}`,
      },
      method: "GET",
    }
  ).then((rsp) => rsp.prospects);
};

export const customerSupportCreateAccount = ({
  firstName,
  lastName,
  email,
}: {
  firstName: string;
  lastName: string;
  email: string;
}) => {
  return withTracking(
    "customerSupportCreateAccount",
    `${API_PREFIX}/support/createAccount`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getJwt()}`,
      },
      method: "POST",
      body: JSON.stringify({ firstName, lastName, email }),
    }
  );
};

export const updateFragment = ({
  fragmentId,
  editedText,
  rating,
}: {
  fragmentId: number;
  editedText?: string;
  rating?: "bad" | "tracking";
}) => {
  const req = {} as any;
  if (editedText) {
    req.editedText = editedText;
  }
  if (rating) {
    req.rating = rating;
  }
  return withTracking(
    "UpdateFragment",
    `${API_PREFIX}/fragment/${fragmentId}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getJwt()}`,
      },
      method: "POST",
      body: JSON.stringify(req),
    }
  );
};

const getFragmentImageUnthrottled = async ({
  fragmentId,
}: {
  fragmentId: number;
}) => {
  const rsp = await withTracking(
    "GotFragment",
    `${API_PREFIX}/fragment/image?fragmentId=${fragmentId}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  );
  return rsp.fragmentImage;
};

export const getFragmentImage = limiter2ReqsPerSec.wrap(
  getFragmentImageUnthrottled
);

const getFragmentPageUnthrottled = async ({
  documentId,
  pageNumber,
}: {
  documentId: number;
  pageNumber: number;
}) => {
  const rsp = await withTracking(
    "GotFragment",
    `${API_PREFIX}/fragment/page?documentId=${documentId}&pageNumber=${pageNumber}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  );
  return rsp.pageImage;
};

export const getFragmentPage = limiter2ReqsPerSec.wrap(
  getFragmentPageUnthrottled
);

export const getWordsInPage = ({
  documentId,
  pageNumber,
}: {
  documentId: number;
  pageNumber: number;
}) => {
  return withTracking(
    "GetWordsInPage",
    `${API_PREFIX}/document/${documentId}/page/${pageNumber}/words`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((rsp) => rsp.words);
};

export const sendFeedback = (feedback: string) => {
  return withTracking("SentFeedback", `${API_PREFIX}/feedback`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwt()}`,
    },
    method: "POST",
    body: JSON.stringify({ feedback }),
  });
};

export const cancelContactSupport = (feedback: any) => {
  return withTracking(
    "cancelContactSupport",
    `${API_PREFIX}/cancelContactSupport`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${getJwt()}`,
      },
      method: "POST",
      body: JSON.stringify({ feedback }),
    }
  );
};

export const getBackDoorData = ({
  context,
  offset,
  limit,
  filtered,
  sorted,
  timerange,
}) => {
  return withTracking(
    "GotBackDoorData",
    `${API_PREFIX}/admin/db/${context}?${queryString.stringify({
      offset,
      limit,
      filtered: JSON.stringify(filtered),
      sorted: JSON.stringify(sorted),
    })}&timerange=${timerange}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  ).then((rsp) => rsp.data);
};
export const getAuditLog = ({ context, offset, limit, timerange }) => {
  return withTracking(
    "GotAuditLog",
    `${API_PREFIX}/admin/auditlog?offset=${offset}&limit=${limit}&email=${encodeURIComponent(
      context
    )}&timerange=${timerange}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  );
};

export const getPgbossJobs = ({ offset, limit, filtered, sorted }) => {
  return withTracking(
    "GotAuditLog",
    `${API_PREFIX}/admin/pgboss?${queryString.stringify({
      offset,
      limit,
      filtered: JSON.stringify(filtered),
      sorted: JSON.stringify(sorted),
    })}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  ).then((rsp) => rsp.jobs);
};

export const getIntegrations = () => {
  return withTracking("GotIntegrations", `${API_PREFIX}/integration`, {
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  }).then((rsp) => rsp.integrations);
};

export const testIntegration = (integration) => {
  return withTracking(
    "TestIntegration",
    `${API_PREFIX}/integration/${integration}/test`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
      method: "POST",
    }
  );
};

export const deleteIntegration = (integration) => {
  return withTracking(
    "DeleteIntegration",
    `${API_PREFIX}/integration/${integration}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
      method: "DELETE",
    }
  );
};

export const getTemplateTags = ({ column }: { column: string }) => {
  return withTracking(
    "GotTemplateTags",
    `${API_PREFIX}/template/tags?column=${column}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  ).then((rsp) => rsp.items);
};

export const getTemplates = ({
  offset = 0,
  limit = 100,
  visibility = "private",
  search = "",
  category = "",
  geo = "",
  time = "",
}: {
  offset: number;
  limit: number;
  visibility: "private" | "public";
  search: string;
  category: string;
  geo: string;
  time: string;
}) => {
  return withTracking(
    "GotTemplates",
    `${API_PREFIX}/template?offset=${offset}&limit=${limit}&search=${search}&visibility=${visibility}&category=${category}&geo=${geo}&time=${time}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  ).then((rsp) => rsp.templates);
};

export const getLastUsedTemplates = ({
  offset = 0,
  limit = 100,
}: {
  offset: number;
  limit: number;
}) => {
  return withTracking(
    "getLastUsedTemplates",
    `${API_PREFIX}/template/lastUsed?offset=${offset}&limit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  ).then((rsp) => rsp.templates);
};

export const getLastUsedBids = () => {
  return withTracking("getLastUsedBids", `${API_PREFIX}/bid/lastUsed`, {
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  }).then((rsp) => rsp.bids);
};

export const getTemplateByName = (templateName, offset = 0, limit = 10) => {
  return withTracking(
    "GotTemplateByName",
    `${API_PREFIX}/template?name=${templateName}&visibility=private&offset=${offset}&limit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  ).then((rsp) => rsp.templates);
};

export const getAllTemplatesByName = (
  templateName: string,
  userId: number | null,
  offset = 0,
  limit = 10
) => {
  return withTracking(
    "GotTemplateByName",
    `${API_PREFIX}/template?all=1&name=${templateName}&userId=${userId}&offset=${offset}&limit=${limit}&visibility=private`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  ).then((rsp) => rsp.templates);
};
export const getTemplate = (templateId): Promise<Template> => {
  return withTracking("GotTemplate", `${API_PREFIX}/template/${templateId}`, {
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  });
};

export const importTemplate = (
  templateId: number,
  templateName: string,
  templateDescription: string,
  documentId: number | null
) => {
  return withTracking(
    "ImportTemplate",
    `${API_PREFIX}/template/${templateId}/import`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: templateName,
        description: templateDescription,
        documentId,
      }),
    }
  );
};

export const createTemplate = (data) => {
  return withTracking("CreateTemplate", `${API_PREFIX}/template`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};
export const updateTemplate = (templateId, data) => {
  return withTracking(
    "UpdateTemplate",
    `${API_PREFIX}/template/${templateId}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const updateTemplateOwner = ({
  templateId,
  userId,
}: {
  templateId: number;
  userId: number;
}) => {
  return withTracking(
    "updateTemplateOwner",
    `${API_PREFIX}/template/${templateId}/updateOwner`,
    {
      method: "POST",
      body: JSON.stringify({
        userId,
      }),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const deleteTemplate = (templateId) => {
  return withTracking(
    "DeleteTemplate",
    `${API_PREFIX}/template/${templateId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const createJob = (data) => {
  const userEmail = (store.getState() as ReduxState).User.user?.email;

  const combineIntoSingleDocument = data.enableMultiPage
    ? "?combineIntoSingleDocument=1"
    : "";
  return withTracking(
    "CreateJob",
    `${API_PREFIX}/job${combineIntoSingleDocument}`,
    {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
    () => null,
    60000
  ).then((rsp) => {
    // Add dataLayer push
    if ((window as any).dataLayer) {
      (window as any).dataLayer.push({
        event: 'start_pw',
        pw_user_email: userEmail
      });
      // console.log((window as any).dataLayer);

    }

    getPendingSurvey()
      .then((rsp) => {
        if (rsp?.survey === "jobInProgress") {
          const SURVEY_SHOWN_KEY = 'docuclipper.survey.jobInProgress.shown'
          // Check if survey has already been shown
          const surveyShown = localStorage.getItem(SURVEY_SHOWN_KEY);
          if (!surveyShown) {
            store.dispatch(openSurvey(rsp.survey));
            // Save in localStorage that survey has been shown
            localStorage.setItem(SURVEY_SHOWN_KEY, 'true');
          }
        }
      })
      .catch((err) => null);
    setTimeout(() => fetchLastUsedTemplates()(store.dispatch), 5000);
    return rsp;
  });
};

export const writePdf = (data: {
  templateId: number | string;
  excelDocumentId: number | string;
  jobName: string;
}) => {
  return withTracking("WritePdf", `${API_PREFIX}/document/writePdf`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rsp) => {
    // store.dispatch(fetchLastUsedTemplates());
    return rsp;
  });
};

export const updateJob = (jobId: number, data) => {
  return withTracking("CreateJob", `${API_PREFIX}/job/${jobId}`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const getJobDailyBalances = (jobId: number, account: string, startDate: string, endDate: string) => {
  return withTracking("CreateJob", `${API_PREFIX}/job/${jobId}/dailyBalances?account=${encodeURIComponent(account)}&startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const deleteJob = (jobId: number) => {
  return withTracking("DeleteJob", `${API_PREFIX}/job/${jobId}`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  });
};

export const resumeJob = (jobId: number) => {
  return withTracking("ResumeJob", `${API_PREFIX}/job/${jobId}/resume`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  });
};

export const redoJob = (jobId: number) => {
  return withTracking("RedoJob", `${API_PREFIX}/job/${jobId}/redo`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  });
};

export const addDocumentsToJob = (jobId: number, documents: number[]) => {
  return withTracking("RedoJob", `${API_PREFIX}/job/${jobId}/addDocuments`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
    body: JSON.stringify({ documents })
  });
};

export const debugJob = (jobId: number) => {
  return withTracking("debugJob", `${API_PREFIX}/job/${jobId}/debug`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  });
};

export const redoJobInBankMode = (jobId: number, useV2: boolean, disableAutoRotation: boolean) => {
  return withTracking(
    "RedoJob",
    `${API_PREFIX}/job/${jobId}/redoInBankMode?useV2=${useV2}&disableAutoRotation=${disableAutoRotation}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  );
};

export const redoJobInOldMode = (jobId: number) => {
  return withTracking("RedoJob", `${API_PREFIX}/job/${jobId}/redoInOldMode`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  });
};

export const emailJob = (jobId: number) => {
  return withTracking("EmailJob", `${API_PREFIX}/job/${jobId}/email`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  });
};

export const exportJob = (
  jobId: number,
  {
    jobType,
    integrationId,
    flattenTables,
    documentIds,
  }: {
    jobType: "FieldsToExcel1" | "FieldsToQuickbooks1";
    integrationId: number | string;
    flattenTables: boolean;
    documentIds: string[];
  }
) => {
  return withTracking(
    "ExportJob",
    `${API_PREFIX}/job/${jobId}/export`,
    {
      method: "POST",
      body: JSON.stringify({ jobType, integrationId, flattenTables }),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
    undefined,
    300000
  );
};

export const rateJob = (data) => {
  return withTracking("RateJob", `${API_PREFIX}/job/${data.jobId}/rate`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const getJobs = ({
  type,
  status,
  all,
  top,
  paying,
  templateId,
  offset = 0,
  limit = 10,
  tags = "",
}: {
  type: Job["type"];
  status: Job["status"] | null;
  all: boolean;
  top: string;
  paying: boolean;
  templateId?: number;
  offset: number;
  limit: number;
  tags: string;
}) => {
  return withTracking(
    "GotJobs",
    `${API_PREFIX}/job?offset=${offset}&limit=${limit}&tags=${tags}&type=${type}${!isNullOrUndefined(templateId) ? `&templateId=${templateId}` : ""
    }&status=${status}${all ? "&all=1" : ""}${top ? `&top=${top}` : ""}${paying ? `&paying=1` : ""
    }`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  ).then((rsp) => rsp.jobs);
};

export const getAllTags = (jobId?: any) => {
  return withTracking("getAllTags", `${API_PREFIX}/job/tags2?jobId=${jobId || ''}`, {
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  }).then((rsp) => rsp);
};

export const editJobTags = (jobId: number, newTags: string[]) => {
  return withTracking("editJobTags", `${API_PREFIX}/job/${jobId}/tags2/`, {
    method: "POST",
    body: JSON.stringify({
      tags: newTags,
    }),
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const getAsyncTask = (asyncTaskId) => {
  return withTracking(
    "getAsyncJobStatus",
    `${API_PREFIX}/asyncTask/${asyncTaskId}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  ).then((rsp) => rsp.asyncTask);
};

export const categorizeJobTransactions = (
  jobId: number,
  tagRegexesSetName: string
) => {
  return withTracking(
    "categorizeJobTransactions",
    `${API_PREFIX}/job/${jobId}/categorize/`,
    {
      method: "POST",
      body: JSON.stringify({
        tagRegexesSetName,
      }),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const getCategorizedTransactions = (jobId: number, selectedAccount?: string) => {
  return withTracking(
    "getCategorizedTransactions",
    `${API_PREFIX}/job/${jobId}/categorizedTransactions2/${selectedAccount ? `?selectedAccount=${selectedAccount}` : ''}`,
    {
      method: "get",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const categorizeJobTransactionsAsync = (
  jobId: number,
  tagRegexesSetName: string
) => {
  return withTracking(
    "categorizeJobTransactionsAsync",
    `${API_PREFIX}/job/${jobId}/categorizeAsync/`,
    {
      method: "POST",
      body: JSON.stringify({
        tagRegexesSetName,
      }),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const payeeCleanupAsync = (
  jobId: number,
  config: any
) => {
  return withTracking(
    "cleanupPayeeAsync",
    `${API_PREFIX}/job/${jobId}/cleanupPayeeAsync/`,
    {
      method: "POST",
      body: JSON.stringify({
        config
      }),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const validateInvoicesServer = (
  jobId: number,

) => {
  return withTracking(
    "validateInvoicesServer",
    `${API_PREFIX}/job/${jobId}/validateInvoices/`,
    {
      method: "POST",
      body: JSON.stringify({
      }),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const previewAutomaticModeAsync = (
  jobId: number,
  T: any,
  T2: any,
  T3: any,
  T4: any
) => {
  return withTracking(
    "previewAutomaticModeAsync",
    `${API_PREFIX}/job/${jobId}/previewAutomaticModeAsync/`,
    {
      method: "POST",
      body: JSON.stringify({
        T,
        T2,
        T3,
        T4,
      }),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const getTransactionCategories = (jobId: number) => {
  return withTracking(
    "getTransactionCategories",
    `${API_PREFIX}/job/${jobId}/categories/`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const getFlowOfFunds = (jobId: number) => {
  return withTracking(
    "getFlowOfFunds",
    `${API_PREFIX}/job/${jobId}/flowOfFunds/`,
    {
      method: "GET",

      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((x) => x.data);
};

export const updateAccountName = ({
  jobId,
  documentId,
  newAccountName,
  oldAccountName,
}) => {
  return withTracking(
    "updateAccountName",
    `${API_PREFIX}/job/${jobId}/transaction/updateAccountName/`,
    {
      method: "POST",
      body: JSON.stringify({
        documentId,
        newAccountName,
        oldAccountName,
      }),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const updateAccountName2 = ({
  jobId,
  chunks,
  newAccountName,
}: {
  jobId: any;
  chunks: { accountName; chunk; documentId }[];
  newAccountName: string;
}) => {
  return withTracking(
    "updateAccountName2",
    `${API_PREFIX}/job/${jobId}/transaction/updateAccountName2/`,
    {
      method: "POST",
      body: JSON.stringify({
        chunks,
        newAccountName,
      }),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const updateAccountNameByChunk = ({
  jobId,
  chunk,
  oldAccountName,
  newAccountName,
}) => {
  return withTracking(
    "updateAccountNameByChunk",
    `${API_PREFIX}/job/${jobId}/transaction/updateAccountNameByChunk/`,
    {
      method: "POST",
      body: JSON.stringify({
        chunk,
        newAccountName,
        oldAccountName,
      }),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const performBulkEdit = ({
  jobId,
  transactionIds,
  operation,
  params,
}) => {
  return withTracking(
    "updateAccountNameByChunk",
    `${API_PREFIX}/job/${jobId}/transactions/bulkEdit/`,
    {
      method: "POST",
      body: JSON.stringify({
        transactionIds,
        operation,
        params,
      }),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const getJobTransactionsDateGaps = (
  jobId: number,
  startDate: string,
  endDate: string
) => {
  return withTracking(
    "getJobTransactionsDateGaps",
    `${API_PREFIX}/job/${jobId}/dateGaps?start=${startDate}&end=${endDate}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((rsp) => rsp.dateGaps);
};

export const getJob = (jobId: number) => {
  return withTracking("GetJob", `${API_PREFIX}/job/${jobId}`, {
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  });
};

export const getJobBadRatings = (jobId: number) => {
  return withTracking("getJobBadRatings", `${API_PREFIX}/job/${jobId}/ratings`, {
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  }).then(rsp => rsp.jobRatings);;
};

export const getSupersetDashboard = (jobId: number, dashboardName) => {
  return withTracking(
    "GetJob",
    `${API_PREFIX}/job/${jobId}/superset?dashboardName=${dashboardName}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  );
};

export const getJobTransactions = (
  jobId: number
) => {
  return withTracking(
    "getJobTransactions",
    `${API_PREFIX}/job/${jobId}/transactions`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  );
};

export const getJobTransactions2 = (
  jobId: number,
  options?: TableQueryParams & {
    callback?: () => void;
  }
) => {
  const queryParams = options ? `?options=${encodeURIComponent(JSON.stringify(options))}` : '';
  return withTracking(
    "getJobTransactions2",
    `${API_PREFIX}/job/${jobId}/transactions2${queryParams}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  );
};

export const getJobMatchingTransactions = (jobId: number) => {
  return withTracking(
    "getJobMatchingTransactions",
    `${API_PREFIX}/job/${jobId}/transactions/matching`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  );
};

export const createMatchingTransactions = (jobId: number, matches: any[]) => {
  return withTracking(
    "getJobMatchingTransactions",
    `${API_PREFIX}/job/${jobId}/transactions/createMatching`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
      method: "POST",
      body: JSON.stringify({ matches }),
    }
  );
};

export const approveMatchingTransactions = (jobId: number, matchId) => {
  return withTracking(
    "getJobMatchingTransactions",
    `${API_PREFIX}/job/${jobId}/transactions/matching/${matchId}/approve`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
      method: "POST",
      body: JSON.stringify({}),
    }
  );
};

export const rejectMatchingTransactions = (jobId: number, matchId) => {
  return withTracking(
    "getJobMatchingTransactions",
    `${API_PREFIX}/job/${jobId}/transactions/matching/${matchId}/reject`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
      method: "POST",
      body: JSON.stringify({}),
    }
  );
};

export const matchTransactions = (jobId: number, days: number) => {
  return withTracking(
    "matchTransactions",
    `${API_PREFIX}/job/${jobId}/transactions/matching`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
      method: "POST",
      body: JSON.stringify({ days }),
    }
  );
};

export const updateJobTransaction = (
  jobId: number,
  transactionId: number,
  params: {
    included?: boolean;
    col?: number;
    newValue?: string;
    editedCategory?: string;
    tags?: string[];
    account?: string;
  }
) => {
  return withTracking(
    "updateJobTransaction",
    `${API_PREFIX}/job/${jobId}/transactions/${transactionId}`,
    {
      method: "POST",
      body: JSON.stringify({
        ...params,
        newValue: params.newValue?.toString(),
      }),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const addTagToTransactions = (
  jobId: number,
  tag: string,
  transactionIds: any[]
) => {
  return withTracking(
    "addTagToTransactions",
    `${API_PREFIX}/job/${jobId}/transactions/addTagToTransactions`,
    {
      method: "POST",
      body: JSON.stringify({
        tag,
        transactionIds,
      }),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const removeTagFromTransactions = (
  jobId: number,
  tag: string,
  transactionIds: any[]
) => {
  return withTracking(
    "removeTagFromTransactions",
    `${API_PREFIX}/job/${jobId}/transactions/removeTagFromTransactions`,
    {
      method: "POST",
      body: JSON.stringify({
        tag,
        transactionIds,
      }),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const getJobMetadataFields = (jobId: number) => {
  return withTracking(
    "getJobMetadataFields",
    `${API_PREFIX}/job/${jobId}/metadataFields`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  );
};

export const updateJobMetadataField = (
  jobId: number,
  documentId: number,
  name: string,
  newValue: string,
  accountName: string,
  chunk: string
) => {
  return withTracking(
    "updateJobMetadataField",
    `${API_PREFIX}/job/${jobId}/updateMetadataField`,
    {
      method: "POST",
      body: JSON.stringify({
        documentId,
        name,
        newValue: newValue.toString(),
        accountName,
        chunk,
      }),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const getContractPreference = async (key: string) => {
  return null;
  if (key === 'view') {
    // return null;
    return 'accountant'
  }
}
export const getJobDocuments = (jobId: number) => {
  return withTracking(
    "GetJobDocuments",
    `${API_PREFIX}/job/${jobId}/documents`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  ).then((rsp) => rsp.documents);
};

export const getJobDocumentsByTagLevels = (jobId: number) => {
  return Promise.resolve([{
    documentId: '2606742',
    originalname: 'last.csv',
    mimetype: 'text/csv'
  }, {
    documentId: '2606740',
    originalname: 'last.pdf',
    mimetype: 'application/pdf'
  }])
};

export const getJobData = ({
  jobId,
  pageNumber,
  documentId,
  offset = 0,
  limit = 10,
}: {
  jobId: number;
  pageNumber?: number;
  documentId?: number;
  offset: number;
  limit: number;
}) => {
  return withTracking(
    "GetJobData",
    `${API_PREFIX}/job/${jobId}/data?offset=${offset}&limit=${limit}${pageNumber !== undefined ? `&pageNumber=${pageNumber}` : ""
    }${documentId !== undefined ? `&documentId=${documentId}` : ""}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  ).then((rsp) => rsp.fragments);
};

export const getJobExports = (jobId, offset = 0, limit = 10) => {
  return withTracking(
    "GetJobData",
    `${API_PREFIX}/job/${jobId}/exports?offset=${offset}&limit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  ).then((rsp) => rsp.exports);
};

export const createContract = ({
  adminId,
  name,
  logo,
}: {
  adminId: string;
  name: string;
  logo: string;
}) => {
  return withTracking("CreatedContract", `${API_PREFIX}/admin/contracts`, {
    method: "POST",
    body: JSON.stringify({
      adminId,
      name,
      logo,
    }),
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const switchToEnterprise = ({ adminId }: { adminId: string }) => {
  return withTracking(
    "CreatedContract",
    `${API_PREFIX}/admin/contracts/switchToEnterprise`,
    {
      method: "POST",
      body: JSON.stringify({
        adminId,
      }),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const getContracts = (offset: number = 0, limit: number = 100) => {
  return withTracking(
    "GetContracts",
    `${API_PREFIX}/admin/contracts?offset=${offset}&limit=${limit}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  ).then((rsp) => rsp.contracts);
};

// export const createSeat = (
//   contractId: number,
//   email: string,
//   isAdmin: boolean
// ) => {
//   return withTracking("CreatedSeat", `${API_PREFIX}/admin/seats`, {
//     method: "POST",
//     body: JSON.stringify({
//       contractId,
//       email,
//       isAdmin
//     }),
//     headers: {
//       Authorization: `Bearer ${getJwt()}`,
//       Accept: "application/json",
//       "Content-Type": "application/json"
//     }
//   });
// };

export const addSeat = ({
  firstName,
  lastName,
  email,
  plan,
}: {
  firstName: string;
  lastName: string;
  email: string;
  plan: string;
}) => {
  return withTracking("addSeat", `${API_PREFIX}/stripe/seat`, {
    method: "POST",
    body: JSON.stringify({
      firstName,
      lastName,
      email,
      plan,
    }),
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const previewAddSeat = () => {
  return withTracking("previewAddSeat", `${API_PREFIX}/stripe/previewAddSeat`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const disableSeat = (seatId: number) => {
  return withTracking(
    "disableSeat",
    `${API_PREFIX}/stripe/seat/${seatId}/disable`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const enableSeat = (seatId: number) => {
  return withTracking(
    "disableSeat",
    `${API_PREFIX}/stripe/seat/${seatId}/enable`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const markReviewed = (jobRatingId: number) => {
  return withTracking(
    "markReviewed",
    `${API_PREFIX}/job/rating/${jobRatingId}/markReviewed`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const markJobReviewed = (jobId: number) => {
  return withTracking(
    "markJobReviewed",
    `${API_PREFIX}/job/${jobId}/markReviewed`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const getSeats = ({
  offset,
  limit,
  filtered,
  sorted,
}: {
  offset: number;
  limit: number;
  filtered: FilteredItem[];
  sorted: SortedItem[];
}) => {
  return withTracking(
    "getSeats",
    `${API_PREFIX}/stripe/seat?${queryString.stringify({
      offset,
      limit,
      filtered: JSON.stringify(filtered),
      sorted: JSON.stringify(sorted),
    })}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((rsp) => rsp.seats);
};

export const getBadRatings = ({
  offset,
  limit,
  filtered,
  sorted,
}: {
  offset: number;
  limit: number;
  filtered: FilteredItem[];
  sorted: SortedItem[];
}) => {
  return withTracking(
    "getBadRatings",
    `${API_PREFIX}/job/badRatings?${queryString.stringify({
      offset,
      limit,
      filtered: JSON.stringify(filtered),
      sorted: JSON.stringify(sorted),
    })}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((rsp) => rsp.badRatings);
};

export const createGoogleDocsSpreadsheet = (csvText: string) => {
  return withTracking(
    "CreateGoogleDocsSpreadsheet",
    `${API_PREFIX}/integration/googleDocsSpreadsheets`,
    {
      method: "POST",
      body: JSON.stringify({
        csvText,
      }),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const exportTable = (csvText: string, format: "Csv" | "Xlsx") => {
  return withTracking(
    `exportAs${format}`,
    `${API_PREFIX}/integration/exportAs${format}`,
    {
      method: "POST",
      body: JSON.stringify({
        csvText,
      }),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        responseType: "blob",
      },
    }
  );
};

export const exportAsXlsx = (csvText: string) => {
  return withTracking("exportAsCsv", `${API_PREFIX}/integration/exportAsCsv`, {
    method: "POST",
    body: JSON.stringify({
      csvText,
    }),
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const getTours = () => {
  return withTracking("getTours", `${API_PREFIX}/tour`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const getOnboardingSteps = () => {
  return withTracking("getOnboardingSteps", `${API_PREFIX}/onboardingSteps`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rsp) => {
    rsp.steps.sort((a, b) => a.order - b.order);
    return rsp.steps;
  });
};

export const getTour = (tourId: string) => {
  const key = `docuclipper.tours.${tourId}`;
  const cachedTour = lsGetItem(key);
  if (cachedTour) {
    return Promise.resolve({
      completed: true,
    });
  }

  return withTracking("getTour", `${API_PREFIX}/tour/${tourId}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const markTourCompleted = (tourId: string, completed: boolean) => {
  return withTracking(
    "MarkTourCompleted",
    `${API_PREFIX}/tour/${tourId}/${completed ? "markCompleted" : "markNotCompleted"
    }`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

//
// stripe
//
export const updateSubscription = (subscription: {
  source: string;
  plan: DocuClipperPlan;
  billingCycle: DocuClipperBillingCycle;
  coupon?: string;
  lm_data?: any;
}) => {
  return withTracking(
    "updateSubscription",
    `${API_PREFIX}/stripe/subscription`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(subscription),
    }
  );
};

export const addCard = (source: string, setAsDefault: boolean) => {
  return withTracking("addCard", `${API_PREFIX}/stripe/card`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ source, setAsDefault }),
  });
};

export const removeCard = (source: string) => {
  return withTracking("removeCard", `${API_PREFIX}/stripe/card`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ source }),
  });
};

export const setDefaultCard = (source: string) => {
  return withTracking("addCard", `${API_PREFIX}/stripe/card/setDefault`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ source }),
  });
};

export const buyCredits = (charge: {
  source: string;
  plan: "100" | "250" | "500" | "1000";
}) => {
  return withTracking("BuyCredits", `${API_PREFIX}/stripe/buyCredits`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(charge),
  });
};

export const cancelSubscription = (reason: string, feedback: string) => {
  return withTracking(
    "CancelSubscription",
    `${API_PREFIX}/stripe/subscription/cancel`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ reason, feedback }),
    }
  );
};

export const pauseSubscription = (pauseFor: string) => {
  return withTracking(
    "CancelSubscription",
    `${API_PREFIX}/stripe/subscription/pause`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ pauseFor }),
    }
  );
};

export const resumeSubscription = () => {
  return withTracking(
    "CancelSubscription",
    `${API_PREFIX}/stripe/subscription/resume`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    }
  );
};

export const reactivateSubscription = () => {
  return withTracking(
    "ReactivateSubscription",
    `${API_PREFIX}/stripe/subscription/reactivate`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    }
  );
};

export const previewSubscription = (data: { plan: string, billingCycle: string, itemIds: any[] }) => {
  return withTracking(
    "PreviewSubscription",
    `${API_PREFIX}/stripe/previewSubscription`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
};


export const getSubscriptionUnthrottled = (): Promise<StripeSubscription> => {
  return withTracking("GetSubscription", `${API_PREFIX}/stripe/subscription`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  });
};
export const getSubscription = limiter1ReqPerSec.wrap(
  getSubscriptionUnthrottled
);

export const getStripeCustomer = (): Promise<StripeCustomer> => {
  return withTracking("getStripeCustomer", `${API_PREFIX}/stripe/customer`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  });
};

export const getInvoices = (
  endingBefore: string,
  startingAfter: string,
  limit = 10
) => {
  return withTracking(
    "GetInvoices",
    `${API_PREFIX}/stripe/invoices?endingBefore=${endingBefore}&startingAfter=${startingAfter}&limit=${limit}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  );
};

export const getPlans = ({
  coupon,
}: {
  coupon?: string;
}): Promise<{ plans: StripePlan[]; coupon: StripeCoupon; contractAddons: ContractAddonsAttributes[], subscriptionItems: SubscriptionItemAttributes[] }> => {
  return withTracking(
    "getPlans",
    `${API_PREFIX}/stripe/plans${coupon ? `?coupon=${coupon}` : ""}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((rsp) => rsp);
};

export const getReferredUsers = (): Promise<{
  referredUsers: ReferredUser[];
  referringUser: ReferredUser | null;
}> => {
  return withTracking(
    "getReferredUsers",
    `${API_PREFIX}/referrals?offset=0&limit=100`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const getPromotions = (): Promise<{
  promotion: DocuClipperPromotions | null;
}> => {
  return withTracking("getPromotions", `${API_PREFIX}/promotions`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const getAnnouncements = (): Promise<any> => {
  return withTracking("getAnnouncements", `${API_PREFIX}/announcements`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rsp) => rsp.announcement);
};

export const trackAnnouncementEvent = (event: {
  [k: string]: any;
}): Promise<StripePlan[]> => {
  return withTracking(
    "postAnnouncementEvent",
    `${API_PREFIX}/announcements/track`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(event),
    }
  );
};

export const triggerFBConversion = (): Promise<any> => {
  return withTracking("triggerFBConversion", `${API_PREFIX}/fbconversion`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const talkToSales = (): Promise<StripePlan[]> => {
  return withTracking("talkToSales", `${API_PREFIX}/talkToSales`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const getFragments = ({ offset, limit, sorted, filtered }) => {
  const url = `${API_PREFIX}/fragment?${queryString.stringify({
    offset,
    limit,
    filtered: JSON.stringify(filtered),
    sorted: JSON.stringify(sorted),
  })}`;
  return withTracking("getFragments", url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rsp) => rsp.fragments);
};

const getToken = () => {
  return withTracking("getToken", `${API_PREFIX}/auth/token`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const searchIndex = async (
  index: "templates" | "fragments" | "documents" | "customers",
  { offset, limit, query }
) => {
  const url = `${API_PREFIX}/search/${index}?offset=${offset}&limit=${limit}&q=${query}`;
  const rsp = await withTracking(`search${index}`, url, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return rsp;
};

export const createCustomer = (data) => {
  return withTracking("CreateCustomer", `${API_PREFIX}/customer`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const getCustomers = ({
  offset = 0,
  limit = 100,
  query = "",
}: {
  offset: number;
  limit: number;
  query: string;
}) => {
  return withTracking(
    "GetCustomers",
    `${API_PREFIX}/customer?offset=${offset}&limit=${limit}&query=${query}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  ).then((rsp) => rsp.customers);
};

type Credits = {
  credits: number;
  purchasedCredits: number;
};

export const getCredits = (): Promise<Credits> => {
  return withTracking("GetCredits", `${API_PREFIX}/credits`, {
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  });
};

export const superLogin = (userId: string, email: string) => {
  return withTracking("SuperLogin", `${API_PREFIX}/auth/superlogin`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getJwt()}`,
    },
    method: "POST",
    body: JSON.stringify({ userId, email }),
  }).then((rsp) => rsp.token);
};

export const deleteFragment = (fragmentId: number) => {
  return withTracking(
    "DeleteFragment",
    `${API_PREFIX}/fragment/${fragmentId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  );
};

export const doLogin = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => {
  return withTracking("Login", `${UNAUTH_API_PREFIX}/auth/jwt`, {
    method: "POST",
    body: JSON.stringify({
      email,
      password,
    }),
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const doSignup = ({
  email,
  password,
  firstName,
  lastName,
  utm_source,
  utm_campign,
  utm_medium,
  utm_term,
  utm_content,
  companyName,
  jobTitle,
  coupon,
  recaptchaToken,
  guestToken
}: {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  utm_source?: string;
  utm_campign?: string;
  utm_medium?: string;
  utm_term?: string;
  utm_content?: string;
  companyName: string;
  jobTitle: string;
  coupon?: string;
  recaptchaToken?: string;
  guestToken?: string;
}) => {
  return withTracking("Signup", `${UNAUTH_API_PREFIX}/auth/signup`, {
    method: "POST",
    body: JSON.stringify({
      email,
      password,
      firstName,
      lastName,
      utm_source,
      utm_campign,
      utm_medium,
      utm_term,
      utm_content,
      companyName,
      jobTitle,
      coupon: coupon || lsGetItem("docuclipper.coupon"),
      recaptchaToken,
      guestToken
    }),
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const doEnterpriseSignup = ({
  password,
  token,
}: {
  password: string;
  token: string;
}) => {
  return withTracking("Register", `${UNAUTH_API_PREFIX}/register`, {
    method: "POST",
    body: JSON.stringify({
      password,
      token,
    }),
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const doResetPassword = ({
  password,
  token,
}: {
  password: string;
  token: string;
}) => {
  return withTracking("Register", `${UNAUTH_API_PREFIX}/resetPassword`, {
    method: "POST",
    body: JSON.stringify({
      password,
      token,
    }),
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const sendPasswordResetRequest = (email: string) => {
  return withTracking(
    "PasswordResetRequest",
    `${UNAUTH_API_PREFIX}/requestPasswordReset`,
    {
      method: "POST",
      body: JSON.stringify({
        email,
      }),
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const getDocuments = ({ offset, limit, sorted, filtered }) => {
  return withTracking(
    "GetDocuments",
    `${API_PREFIX}/document?${queryString.stringify({
      offset,
      limit,
      filtered: JSON.stringify(filtered),
      sorted: JSON.stringify(sorted),
    })}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  ).then((rsp) => rsp.documents);
};

export const getAdminDocuments = ({ offset, limit, sorted, filtered }) => {
  return withTracking(
    "GetAdminDocuments",
    `${API_PREFIX}/admin/documents?${queryString.stringify({
      offset,
      limit,
      filtered: JSON.stringify(filtered),
      sorted: JSON.stringify(sorted),
    })}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  ).then((rsp) => rsp.documents);
};

export const downloadDocumentAsAdmin = (documentId: number) => {
  const url = `${process.env.NODE_ENV === "production" ? "" : "http://localhost:8080"
    }${API_PREFIX}/admin/document/${documentId}/download?token=${getJwt()}`;
  goToNewTab(url);
};

export const downloadSpreadsheet = (
  jobId: number,
  jobType: Job["jobType"],
  format: ExportType,
  flattenTables: boolean,
  documentIds: string[],
  extraValues: {},
  selectedFieldNames: string[],
  filteredTlIds: string[],
  filters: TableFilterConfig[],
  sortBy: string,
  sortDirection: string,
  fileType?: "CSV" | "XLSX",
  preview?: boolean,
  selectedExportFields?: any
) => {
  let url = `${API_PREFIX}/job/${jobId}/export`
  if (preview) url += "?preview=true"

  const headers = {
    Authorization: `Bearer ${getJwt()}`,
    Accept: "application/json",
    "Content-Type": "application/json",
  }

  if (!preview) {
    headers['responseType'] = "blob"
  }

  return withTracking(
    "downloadSpreadsheet",
    url,
    {
      method: "POST",
      body: JSON.stringify({
        format,
        flattenTables,
        jobType,
        documentIds,
        ...extraValues,
        selectedFieldNames,
        filteredTlIds,
        filters,
        sortBy,
        sortDirection,
        fileType,
        selectedExportFields
      }),
      headers
    }
  );
};

export const downloadJobHistory = () => {
  return withTracking("downloadJobHistory", `${API_PREFIX}/job/history`, {
    method: "POST",
    body: JSON.stringify({}),
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      responseType: "blob",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((rsp) => {
    const url = window.URL.createObjectURL(rsp);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = "docuclipper_job_history.csv";

    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
  });
};

export const getTotals = (jobId: number, cleanCsvDocumentId: string | null) => {
  return withTracking("getTotals", `${API_PREFIX}/job/${jobId}/getTotals`, {
    method: "POST",
    body: JSON.stringify({
      cleanCsvDocumentId,
    }),
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const downloadDocument = (documentId: number) => {
  return withTracking(
    "downloadDocument",
    `${API_PREFIX}/document/${documentId}/download`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        responseType: "blob",
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const createCorrection = (
  jobId: string,
  documentId: string,
  name: string,
  row: number,
  col: number | null,
  newValue: string | null,
  included: boolean,
  toggleSign: boolean,
  divideBy100: boolean,
  multilineIndex?: number
) => {
  return withTracking(
    "createCorrection",
    `${API_PREFIX}/job/${jobId}/correction`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        documentId,
        name,
        row,
        col,
        newValue: newValue?.toString(),
        included,
        toggleSign,
        divideBy100,
        multilineIndex,
      }),
    }
  );
};

export const switchTransactionSigns = (
  jobId: string,
  documentId: string,
  scope: string,
  pageNumber: number
) => {
  return withTracking("switchSigns", `${API_PREFIX}/job/${jobId}/switchSigns`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ documentIds: [documentId], scope, pageNumber }),
  });
};

export const switchIncludedExcluded = (
  jobId: string,
  documentId: string,
  scope: string,
  pageNumber: number
) => {
  return withTracking(
    "switchSigns",
    `${API_PREFIX}/job/${jobId}/switchIncludedExcluded`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ documentIds: [documentId], scope, pageNumber }),
    }
  );
};

export const createTransactions = (
  jobId: string,
  documentId: string,
  transactions: any[]
) => {
  return withTracking(
    "createCorrection",
    `${API_PREFIX}/job/${jobId}/transaction`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ documentId, transactions }),
    }
  );
};

export const loadPreferenceServer = ({ key }: { key: string }) => {
  return withTracking("loadPreference", `${API_PREFIX}/preference/${key}`, {
    headers: {
      Authorization: `Bearer ${getJwt()}`,
    },
  }).then((rsp) => rsp.preference);
};

export const savePreferenceServer = ({
  key,
  value,
}: {
  key: string;
  value: string;
}) => {
  return withTracking("savePreference", `${API_PREFIX}/preference/${key}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ value }),
  });
};

export const editDocument = ({ pdfCommands }: { pdfCommands: PdfCommands }) => {
  return withTracking("editDocument", `${API_PREFIX}/document/edit`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(pdfCommands),
  });
};

export const editDocumentAsync = ({
  pdfCommands,
}: {
  pdfCommands: PdfCommands;
}) => {
  return withTracking("editDocument", `${API_PREFIX}/document/editAsync`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(pdfCommands),
  });
};

export const getTemplateAddedColumnMapping = ({
  templateId,
  offset,
  limit,
  sorted,
  filtered,
}: {
  templateId: number;
} & ApiTableParams) => {
  return withTracking(
    "addTemplateAddedColumnMapping",
    `${API_PREFIX}/template/${templateId}/addedColumnMappings?${queryString.stringify(
      {
        offset,
        limit,
        filtered: JSON.stringify(filtered),
        sorted: JSON.stringify(sorted),
      }
    )}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const downloadExampleSpreadsheet = ({
  templateId,
}: {
  templateId: number;
}) => {
  return withTracking(
    "downloadExampleSpreadsheet",
    `${API_PREFIX}/template/${templateId}/downloadExampleSpreadsheet`,
    {
      method: "post",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        responseType: "blob",
      },
    }
  );
};

export const addTemplateAddedColumnMapping = ({
  templateId,
  mappings,
}: {
  templateId: number;
  mappings: TemplateAddedColumnMapping[];
}) => {
  return withTracking(
    "addTemplateAddedColumnMapping",
    `${API_PREFIX}/template/${templateId}/addedColumnMappings/addItems`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ mappings }),
    }
  );
};

export const updateTemplateAddedColumnMapping = ({
  templateId,
  id,
  columnName,
  addedColumnName,
  value,
  addedValue,
}: {
  templateId: number;
  id: number;
  columnName: string;
  value: string;
  addedColumnName: string;
  addedValue: string;
}) => {
  return withTracking(
    "updateTemplateAddedColumnMapping",
    `${API_PREFIX}/template/${templateId}/addedColumnMappings/update/${id}`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ columnName, addedColumnName, value, addedValue }),
    }
  );
};

export const deleteTemplateAddedColumnMapping = ({
  templateId,
  mappingId,
}: {
  templateId: number;
  mappingId: number;
}) => {
  return withTracking(
    "addTemplateAddedColumnMapping",
    `${API_PREFIX}/template/${templateId}/addedColumnMappings/${mappingId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const updateDocument = ({
  documentId,
  tags,
}: {
  documentId: string;
  tags: { [k: string]: string };
}) => {
  return withTracking(
    "updateDocument",
    `${API_PREFIX}/document/${documentId}/update`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ tags }),
    }
  );
};

export const getUser = (): Promise<User> => {
  return withTracking("getUser", `${API_PREFIX}/user/`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const resendConfirmationLink = () => {
  return withTracking(
    "resendConfirmationLink",
    `${API_PREFIX}/user/resendConfirmationLink`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const updateUser = ({
  userId,
  tags,
}: {
  userId: string;
  tags: { [k: string]: string };
}) => {
  return withTracking("updateUser", `${API_PREFIX}/user/${userId}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ tags }),
  });
};

export const updatePayeeCleanupConfig = (jobId, config) => {
  return withTracking("updatePayeeCleanupConfig", `${API_PREFIX}/jobs/${jobId}/updatePayeeCleanupConfig`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ jobId, config }),
  });
};

export const getTemplateFieldNames = ({
  templateId,
}: {
  templateId: number;
}) => {
  return withTracking(
    "getTemplateFieldNames",
    `${API_PREFIX}/template/${templateId}/fields`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((rsp) => rsp.fields);
};

export const getWordsInRectangle = ({
  documentId,
  pageNumber,
  x0,
  x1,
  y0,
  y1,
  edge,
  position,
}: {
  documentId: number;
  pageNumber: number;
  x0: number;
  x1: number;
  y0: number;
  y1: number;
  edge: EdgeType;
  position: XPosition | YPosition;
}): Promise<{ words: string[]; edges: number[] }> => {
  return withTracking(
    "getWordsInRectangle",
    `${API_PREFIX}/document/${documentId}/page/${pageNumber}/wordsInRectangle`,
    {
      method: "post",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        x0,
        x1,
        y0,
        y1,
        edge,
        position,
      }),
    }
  );
};

export const getEdgesFromWords = ({
  documentId,
  pageNumber,
  edge,
  words,
  position,
  offset,
}: {
  documentId: number;
  pageNumber: number;
  edge: EdgeType;
  words: string[];
  position: YPosition | XPosition;
  offset: number;
}): Promise<{ edges: number[] }> => {
  return withTracking(
    "getEdgesFromWords",
    `${API_PREFIX}/document/${documentId}/page/${pageNumber}/edgesFromWords`,
    {
      method: "post",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        words,
        edge,
        position,
        offset,
      }),
    }
  );
};

export const updateProfile = ({
  firstName,
  lastName,
  email,
  password,
}: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}): Promise<{ token: string }> => {
  return withTracking("updateProfile", `${API_PREFIX}/user/updateProfile`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      firstName,
      lastName,
      email,
      password,
    }),
  });
};

export const generatePayingUsersReport = (): Promise<any> => {
  return withTracking(
    "generatePayingUsersReport",
    `${API_PREFIX}/stripe/report/paying`,
    {
      method: "post",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        responseType: "blob",
      },
      body: JSON.stringify({}),
    }
  );
};

export const generateMauReport = (): Promise<any> => {
  return withTracking("generateMauReport", `${API_PREFIX}/stripe/report/mau`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
      responseType: "blob",
    },
    body: JSON.stringify({}),
  });
};

export const createQboInvoice = (
  integrationId: string,
  {
    date,
    customerName,
    lineItems,
  }: {
    date: string;
    customerName: string;
    lineItems: string;
  }
): Promise<any> => {
  return withTracking(
    "createQboInvoice",
    `${API_PREFIX}/job/createQboInvoice`,
    {
      method: "post",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
        responseType: "blob",
      },
      body: JSON.stringify({
        integrationId,
        invoice: { date, customerName, lineItems },
      }),
    }
  );
};

export const inviteByEmail = (emails: string): Promise<any> => {
  return withTracking("invite", `${API_PREFIX}/invite`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      emails,
    }),
  });
};

export const deleteUser = (userId: string): Promise<any> => {
  return withTracking("deleteUser", `${API_PREFIX}/user/deleteUser`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      userId,
    }),
  });
};

export const restartTrial = (): Promise<any> => {
  return withTracking(
    "restartTrial",
    `${API_PREFIX}/stripe/subscription/restartTrial`,
    {
      method: "post",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({}),
    }
  );
};

export const getColumnConfigs = (templateId: number): Promise<any> => {
  return withTracking(
    "getColumnConfigs",
    `${API_PREFIX}/columnConfig/${templateId}`,
    {
      method: "get",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((x) => x.columnConfigs);
};

export const getCustomFormats = ({
  type,
}): Promise<any> => {
  return withTracking("getCustomFormats", `${API_PREFIX}/customFormat?type=${type}`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((x) => x.customFormats);
};

export const previewCsv = (documentId: number): Promise<any> => {
  // return Promise.resolve({
  //   mapping: {
  //     0: "date",
  //     1: "name",
  //     2: "amount",
  //     3: "amount",
  //     4: "amount",
  //     5: "amount",
  //     6: "amount",
  //     7: "amount",
  //     8: "amount",
  //   },
  //   preview: [
  //     [
  //       "date",
  //       "description",
  //       "amount",
  //       "desc 1",
  //       "100.23",
  //       "desc 1",
  //       "100.23",
  //       "desc 1",
  //       "100.23",
  //     ],
  //     [
  //       "2022-01-02",
  //       "desc 1",
  //       "100.23",
  //       "desc 1",
  //       "100.23",
  //       "desc 1",
  //       "100.23",
  //       "desc 1",
  //       "100.23",
  //     ],
  //     [
  //       "2022-01-03",
  //       "desc 2",
  //       "100.78",
  //       "desc 1",
  //       "100.23",
  //       "desc 1",
  //       "100.23",
  //       "desc 1",
  //       "100.23",
  //     ],
  //   ],
  // });
  return withTracking(
    "previewCsv",
    `${API_PREFIX}/document/${documentId}/previewCsv`,
    {
      method: "post",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((x) => x);
};

export const previewProspectCsv = (documentId: number): Promise<any> => {
  return withTracking(
    "previewProspectCsv",
    `${API_PREFIX}/document/${documentId}/previewProspectCsv`,
    {
      method: "post",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((x) => x);
};

export const previewVendorsCsv = (documentId: number): Promise<any> => {
  return withTracking(
    "previewCsv",
    `${API_PREFIX}/document/${documentId}/previewVendorsCsv`,
    {
      method: "post",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((x) => x);
};

export const createCustomFormat = (
  name: CustomFormat["name"],
  data: CustomFormat["data"],
  type: CustomFormatType
): Promise<any> => {
  return withTracking("createCustomFormat", `${API_PREFIX}/customFormat`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      data: JSON.stringify(data),
      type,
    }),
  });
};

export const updateCustomFormat = (
  customFormat: CustomFormat
): Promise<any> => {
  return withTracking(
    "updateCustomFormat",
    `${API_PREFIX}/customFormat/${customFormat.id}`,
    {
      method: "post",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name: customFormat.name,
        data: JSON.stringify(customFormat.data),
      }),
    }
  ).then(() => {
    store.dispatch({
      type: "DownloadInvoiceOptions/setReloadExportPreview",
      payload: true,
    });
  });
};

export const deleteCustomFormat = (id: string): Promise<any> => {
  return withTracking(
    "deleteCustomFormat",
    `${API_PREFIX}/customFormat/${id}`,
    {
      method: "delete",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const updateColumnConfigServer = (
  templateId: number,
  fieldName: string,
  data: { [k: string]: any }
): Promise<any> => {
  return withTracking(
    "updateColumnConfig",
    `${API_PREFIX}/columnConfig/${templateId}`,
    {
      method: "post",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fieldName,
        data,
      }),
    }
  );
};

export const getBullMqJobs = ({ jobId }: { jobId: string }) => {
  return withTracking(
    "getBullMqJobs",
    `${API_PREFIX}/bullmq?jobId=${jobId}`,
    {
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    },
    undefined,
    120000
  );
};

export const trackPurchase = (amount: number) => {
  if (window && (window as any).gtag) {
    (window as any).gtag("event", "purchase", {
      value: amount,
      currency: "USD",
      send_to: "G-TMN0RY3CC7",
      // debug_mode: true,
    });
  }
};

export const trackSignup = () => {
  if (window && (window as any).gtag) {
    (window as any).gtag("event", "sign_up", {
      send_to: "G-TMN0RY3CC7",
      // debug_mode: true,
    });
  }
};

export const getQboTaxCodes = (integrationId: string) => {
  return withTracking(
    "getQboTaxCodes",
    `${API_PREFIX}/qbo/taxCodes?integrationId=${integrationId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((rsp) => rsp.taxCodes);
};

export const getQboCustomers = (integrationId: number): Promise<any> => {
  return withTracking(
    "getQboCustomers",
    `${API_PREFIX}/qbo/customers?integrationId=${integrationId}`,
    {
      method: "get",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((rsp) => rsp.customers);
};

export const getQboVendors = (integrationId: number): Promise<any> => {
  return withTracking(
    "getQboVendors",
    `${API_PREFIX}/qbo/vendors?integrationId=${integrationId}`,
    {
      method: "get",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((rsp) => rsp.vendors);
};

export const getQboAccounts = (integrationId: number, paymentType: boolean): Promise<any> => {
  return withTracking(
    "getQboAccounts",
    `${API_PREFIX}/qbo/accounts?integrationId=${integrationId}&paymentType=${paymentType}`,
    {
      method: "get",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((rsp) => rsp.accounts);
};


export const getQboItems = (integrationId: number): Promise<any> => {
  return withTracking(
    "getQboAccounts",
    `${API_PREFIX}/qbo/items?integrationId=${integrationId}`,
    {
      method: "get",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((rsp) => rsp.items);
};

export const createQboInvoice2 = (
  integrationId: number,
  transactionId: number,
  customer: { name: string; value: string },
  options: { forceImport?: boolean } = {}
): Promise<any> => {
  return withTracking("createQboInvoice2", `${API_PREFIX}/qbo/invoice`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      integrationId,
      transactionId,
      customer,
      ...options
    }),
  });
};

export const createQboBill2 = (
  integrationId: number,
  transactionId: number,
  vendor: { name: string; value: string },
  options: { forceImport?: boolean } = {}
): Promise<any> => {
  return withTracking("createQboBill2", `${API_PREFIX}/qbo/bill`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      integrationId,
      transactionId,
      vendor,
      ...options
    }),
  });
};

export const createQboExpense2 = (
  integrationId: number,
  transactionId: number,
  account: { name: string; value: string },
  customer: { name: string; value: string },
  options: { forceImport?: boolean } = {}
): Promise<any> => {
  return withTracking("createQboBill2", `${API_PREFIX}/qbo/purchase`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      integrationId,
      transactionId,
      account,
      customer,
      ...options
    }),
  });
};

export const createQboCustomer = (
  integrationId: number,
  name: string
): Promise<any> => {
  return withTracking("createQboCustomer", `${API_PREFIX}/qbo/customer`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      integrationId,
      name,
    }),
  });
};

export const saveSurveyResults = (surveyId, data): Promise<any> => {
  return withTracking("saveSurveyResults", `${API_PREFIX}/user/saveSurvey`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      surveyId,
      data,
    }),
  });
};

export const getPendingSurvey = (): Promise<any> => {
  return withTracking("getPendingSurvey", `${API_PREFIX}/user/pendingSurvey`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const createQboVendor = (
  integrationId: number,
  name: string
): Promise<any> => {
  return withTracking("createQboVendor", `${API_PREFIX}/qbo/vendor`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      integrationId,
      name,
    }),
  });
};

export const createQboAccount = (
  integrationId: number,
  name: string
): Promise<any> => {
  return withTracking("createQboAccount", `${API_PREFIX}/qbo/account`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      integrationId,
      name,
    }),
  });
};

export const getTagRegexes = (type: TagRegexesType): Promise<any> => {
  return withTracking(
    "getTagRegexes",
    `${API_PREFIX}/tagRegexes?type=${type}`,
    {
      method: "get",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const getTaxRegexesByType = (type: TagRegexesType, integrationId: number | null, docType: DocType): Promise<any> => {
  return withTracking(
    "getTagRegexesByType",
    `${API_PREFIX}/tagRegexes/${type}?integrationId=${integrationId}&docType=${docType}`,
    {
      method: "get",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const uploadVendorsCsv = ({
  name,
  documentId,
  mapping,
}): Promise<any> => {
  return withTracking(
    "uploadVendorsCsv",
    `${API_PREFIX}/tagRegexes/uploadVendorsCsv`,
    {
      method: "post",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        name,
        documentId,
        mapping,
      }),
    }
  );
};

export const getFeatureGates = (): Promise<any> => {
  return withTracking("getFeatureGates", `${API_PREFIX}/featureGate`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const getFeatureGatesByPlanEnabled = (featureId): Promise<boolean> => {
  return withTracking(
    "getFeatureGates",
    `${API_PREFIX}/featureGateByPlan/${featureId}/enabled`,
    {
      method: "get",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  ).then((x) => x.enabled);
};

//
// tag regexes set
//
export const createTagRegexesSet = (name, tagRegexesId, type): Promise<any> => {
  return withTracking("createTagRegexesSet", `${API_PREFIX}/tagRegexesSet`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      tagRegexesId,
      type,
    }),
  });
};

export const updateEmail = ({ email }): Promise<any> => {
  return withTracking("updateEmail", `${API_PREFIX}/user/updateEmail`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
    }),
  });
};



export const getUserRatings = (): Promise<any> => {
  return withTracking("updateEmail", `${API_PREFIX}/user/ratings`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(rsp => rsp.jobRatings);
};

export const requestBadRatingUpdate = (): Promise<any> => {
  return withTracking("requestBadRatingUpdate", `${API_PREFIX}/user/requestBadRatingUpdate`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({}),
  });
};


export const updateName = ({ firstName, lastName }): Promise<any> => {
  return withTracking("updateEmail", `${API_PREFIX}/user/updateName`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      firstName,
      lastName,
    }),
  });
};

export const createGuestAccount = ({ firstName, lastName, email, password }): Promise<any> => {
  return withTracking("updateEmail", `${API_PREFIX}/user/updateGuestAccount`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      firstName,
      lastName,
      email,
      password
    }),
  });
};

export const updateSegment = (data): Promise<any> => {
  return withTracking("updateSegment", `${API_PREFIX}/user/updateSegment`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });
};

export const deleteTagRegexesSet = (
  setName,
  type: TagRegexesType
): Promise<any> => {
  return withTracking(
    "deleteTagRegexesSet",
    `${API_PREFIX}/tagRegexesSet/${setName}`,
    {
      method: "delete",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        type,
      }),
    }
  );
};

//
// tag regexes
//
export const createTagRegexes = (
  tag: string,
  tagLabel: string,
  regexes: string,
  rule: TagCategoryRule
): Promise<any> => {
  return withTracking("createTagRegexes", `${API_PREFIX}/tagRegexes`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      tag,
      tagLabel,
      regexes,
      rule,
    }),
  });
};

export const createTagRegexesInvoice = (
  tag: string,
  regexes: string,
  rule: TagInvoiceRule
): Promise<any> => {
  return withTracking("createTagRegexesInvoice", `${API_PREFIX}/tagRegexes/invoice`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      tag,
      regexes,
      rule,
    }),
  });
};

export const createTagRule = (rule: TagRule): Promise<any> => {
  return withTracking("createTagRule", `${API_PREFIX}/tagRules`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      rule,
    }),
  });
};

export const getJobEmail = (jobType): Promise<any> => {
  return withTracking("getJobEmail", `${API_PREFIX}/jobEmail?jobType=${jobType}`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(rsp => rsp.jobEmail);
};

export const updateJobEmail = (
  id,
  email: string
): Promise<any> => {
  return withTracking("updateJobEmail", `${API_PREFIX}/jobEmail/${id}`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email
    }),
  });
};

export const updateTagRegexes = (
  id,
  tag: string,
  tagLabel: string,
  regexes: string,
  rule: TagCategoryRule
): Promise<any> => {
  return withTracking("updateTagRegexes", `${API_PREFIX}/tagRegexes/${id}`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      tag,
      tagLabel,
      regexes,
      rule
    }),
  });
};

export const updateTagRegexesInvoice = (
  id,
  rule: TagInvoiceRule
): Promise<any> => {
  return withTracking("updateTagRegexesInvoice", `${API_PREFIX}/tagRegexes/${id}/invoice`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      rule,
    }),
  });
};

export const updateTagRule = (rule: TagRule): Promise<any> => {
  return withTracking("updateTagRule", `${API_PREFIX}/tagRules/${rule.id}`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      rule,
    }),
  });
};

export const deleteTagRegexes = (id): Promise<any> => {
  return withTracking("deleteTagRegexes", `${API_PREFIX}/tagRegexes/${id}`, {
    method: "delete",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const runInvoiceRules = (jobId, integrationId, docType): Promise<any> => {
  return withTracking("runInvoiceRules", `${API_PREFIX}/tagRegexes/runInvoiceRules`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      jobId,
      integrationId,
      docType
    }),
  });
}

export const fetchTeams = (contractId): Promise<any> => {
  return withTracking("fetchTeams", `${API_PREFIX}/team/${contractId}/user`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((x) => x.teams);
};

export const fetchTeamMembers = (teamId: string, contractId: string): Promise<any> => {
  return withTracking("fetchTeamMembers", `${API_PREFIX}/team/${contractId}/${teamId}/members`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then((x) => x.members);
};

export const addTeamMember = (teamId: string, contractId: string, email: string, role: string): Promise<any> => {
  return withTracking("addTeamMember", `${API_PREFIX}/team/${contractId}/${teamId}/members`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, role }),
  }).then((x) => x.member);
};

export const removeTeamMember = (teamId: string, contractId: string, userId: string): Promise<any> => {
  return withTracking("removeTeamMember", `${API_PREFIX}/team/${contractId}/${teamId}/members/${userId}`, {
    method: "delete",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const updateTeamMemberRole = (teamId: string, contractId: string, userId: string, role: string): Promise<any> => {
  return withTracking("updateTeamMemberRole", `${API_PREFIX}/team/${contractId}/${teamId}/members/${userId}/role`, {
    method: "put",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ role }),
  }).then((x) => x.member);
};

export const createTeam = (name: string, contractId): Promise<any> => {
  return withTracking("createTeam", `${API_PREFIX}/team/${contractId}`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ name }),
  });
};


export const makeAdmin = ({ userId, contractId }: { userId: string, contractId: string }): Promise<any> => {
  return withTracking("makeAdmin", `${API_PREFIX}/team/${contractId}/makeAdmin`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userId, contractId }),
  });
};

export const removeAdmin = ({ userId, contractId }: { userId: string, contractId: string }): Promise<any> => {
  return withTracking("removeAdmin", `${API_PREFIX}/team/${contractId}/removeAdmin`, {
    method: "post",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ userId, contractId }),
  });
};

export const getContractAdmins = (contractId: string): Promise<any> => {
  return withTracking("getContractAdmins", `${API_PREFIX}/team/contractAdmins/${contractId}`, {
    method: "get",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const cancelAnnualBilledMonthlyPlan = ({
  userId,
  preview,
}: {
  userId: string;
  preview: boolean;
}): Promise<any> => {
  return withTracking(
    "cancelAnnualBilledMonthlyPlan",
    `${API_PREFIX}/support/cancelAnnualBilledMonthly`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ userId, preview }),
    }
  );
};

export const assignEmailToGuest = ({
  guestEmail,
  newEmail,
  firstName,
  lastName
}: {
  guestEmail: string;
  newEmail: string;
  firstName: string;
  lastName: string;

}): Promise<any> => {
  return withTracking(
    "assignEmailToGuest",
    `${API_PREFIX}/support/assignEmailToGuest`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ guestEmail, newEmail, firstName, lastName }),
    }
  );
};

export const createQboTaxCode = (integrationId: string, taxCodeName: string) => {
  return withTracking(
    "createQboTaxCode",
    `${API_PREFIX}/qbo/${integrationId}/taxcode`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name: taxCodeName }),
    }
  ).then((rsp) => rsp.taxCode);
};

export const enableAuditLog = (enabled: boolean) => {
  return withTracking("EnableAuditLog", `${API_PREFIX}/auditLog/enable`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ enabled }),
  });
};

export const exportAuditLog = () => {
  return withTracking("ExportAuditLog", `${API_PREFIX}/auditLog/export`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};


export const setup2FA = (): Promise<{ secret: string; otpauth_url: string }> => {
  return withTracking("setup2FA", `${API_PREFIX}/2fa/setup`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const confirm2FA = (token: string): Promise<void> => {
  return withTracking("confirm2FA", `${API_PREFIX}/2fa/confirm`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token }),
  });
};

export const disable2FA = (): Promise<void> => {
  return withTracking("disable2FA", `${API_PREFIX}/2fa/disable`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getJwt()}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const getInvoiceApprovalRules = (): Promise<InvoiceApprovalRule[]> => {
  return withTracking(
    "getInvoiceApprovalRules",
    `${API_PREFIX}/invoice/approvalRules`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const createInvoiceApprovalRule = (
  rule: InvoiceApprovalRule
): Promise<InvoiceApprovalRule> => {
  return withTracking(
    "createApprovalRule",
    `${API_PREFIX}/invoice/approvalRules`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(rule),
    }
  );
};

export const updateInvoiceApprovalRule = (
  ruleId: number,
  rule: InvoiceApprovalRule
): Promise<InvoiceApprovalRule> => {
  return withTracking(
    "updateApprovalRule",
    `${API_PREFIX}/invoice/approvalRules/${ruleId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(rule),
    }
  );
};

export const deleteInvoiceApprovalRule = (
  ruleId: number
): Promise<void> => {
  return withTracking(
    "deleteApprovalRule",
    `${API_PREFIX}/invoice/approvalRules/${ruleId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const getInvoiceApprovalHistory = (
  transactionLineId: number
): Promise<any> => {
  return withTracking(
    "getInvoiceApprovalHistory",
    `${API_PREFIX}/invoice-approval/history/${transactionLineId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

// Approval Rules
export const createApprovalRule = (contractId: number, rule: InvoiceApprovalRule): Promise<InvoiceApprovalRule> => {
  return withTracking(
    "createApprovalRule",
    `${API_PREFIX}/invoice/approvalRules`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(rule),
    }
  );
};

export const getApprovalRules = (contractId: number): Promise<InvoiceApprovalRule[]> => {
  return withTracking(
    "getApprovalRules",
    `${API_PREFIX}/invoice/approvalRules`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const updateApprovalRule = (
  ruleId: number,
  rule: InvoiceApprovalRule
): Promise<InvoiceApprovalRule> => {
  return withTracking(
    "updateApprovalRule",
    `${API_PREFIX}/invoice/approvalRules/${ruleId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(rule),
    }
  );
};

export const deleteApprovalRule = (contractId: number, ruleId: number): Promise<void> => {
  return withTracking(
    "deleteApprovalRule",
    `${API_PREFIX}/invoice/approvalRules/${ruleId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

// Transaction Line Approval Status
export const updateInvoiceApprovalStatus = (
  transactionLineId: number,
  status: 'approved' | 'rejected',
  comment: string,
): Promise<{
  approvalStatus: InvoiceApprovalStatus;
  isApprover: boolean;
  needsApproval: boolean;
  approvalProgress: ApprovalProgress;
}> => {
  return withTracking(
    "updateApprovalStatus",
    `${API_PREFIX}/invoice/transactionLines/${transactionLineId}/approval`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status, comment }),
    }
  );
};

export const getInvoiceApprovalStatus = (
  transactionLineId: number
): Promise<{
  approvalStatus: InvoiceApprovalStatus;
  isApprover: boolean;
  needsApproval: boolean;
  approvalProgress: ApprovalProgress;
}> => {
  return withTracking(
    "getApprovalStatus",
    `${API_PREFIX}/invoice/transactionLines/${transactionLineId}/approval`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};


export const getApprovalHistory = (
  transactionLineId: number
): Promise<any> => {
  return withTracking(
    "getApprovalHistory",
    `${API_PREFIX}/invoice/transactionLines/${transactionLineId}/history`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};



export const submitForApproval = (
  transactionLineId: number
): Promise<{
  approvalStatus: InvoiceApprovalStatus;
  isApprover: boolean;
  needsApproval: boolean;
  approvalProgress: ApprovalProgress;
}> => {
  return withTracking(
    "submitForApproval",
    `${API_PREFIX}/invoice/transactionLines/${transactionLineId}/submitForApproval`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: ''
    }
  );
};


// Invoice Routing Rules
export const createInvoiceRoutingRule = (
  rule: InvoiceRoutingRule
): Promise<InvoiceRoutingRule> => {
  return withTracking(
    "createInvoiceRoutingRule",
    `${API_PREFIX}/invoice/routingRules`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(rule),
    }
  );
};

export const getInvoiceRoutingRules = (contractId: number): Promise<InvoiceRoutingRule[]> => {
  return withTracking(
    "getInvoiceRoutingRules",
    `${API_PREFIX}/invoice/routingRules`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const updateInvoiceRoutingRule = (
  ruleId: number,
  rule: InvoiceRoutingRule
): Promise<InvoiceRoutingRule> => {
  return withTracking(
    "updateInvoiceRoutingRule",
    `${API_PREFIX}/invoice/routingRules/${ruleId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(rule),
    }
  );
};

export const deleteInvoiceRoutingRule = (contractId: number, ruleId: number): Promise<void> => {
  return withTracking(
    "deleteInvoiceRoutingRule",
    `${API_PREFIX}/invoice/routingRules/${ruleId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};



// Get potential matches for a transaction line
export const getPotentialMatches = (
  transactionLineId: number,
  filters?: {
    vendorName?: string;
    documentType?: string;
    similarityThreshold?: number;
    limit?: number;
  }
): Promise<PotentialMatches> => {
  const queryParams = new URLSearchParams();

  if (filters) {
    if (filters.vendorName) queryParams.append('vendorName', filters.vendorName);
    if (filters.documentType) queryParams.append('documentType', filters.documentType);
    if (filters.similarityThreshold) queryParams.append('similarityThreshold', filters.similarityThreshold.toString());
    if (filters.limit) queryParams.append('limit', filters.limit.toString());
  }

  const queryString = queryParams.toString();
  const url = `${API_PREFIX}/invoice/transactionLines/${transactionLineId}/matches${queryString ? `?${queryString}` : ''}`;

  return withTracking(
    "getPotentialMatches",
    url,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

// Create a new match group
export const createMatchGroup = (
  transactionLineIds: number[],
  requiredMatches?: number
): Promise<MatchGroup> => {
  return withTracking(
    "createMatchGroup",
    `${API_PREFIX}/invoice/matchGroups`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        transactionLineIds,
        requiredMatches
      })
    }
  );
};

// Update match group status
export const updateMatchGroupStatus = (
  matchGroupId: number,
  status: 'confirmed' | 'rejected',
  comment?: string
): Promise<MatchGroup> => {
  return withTracking(
    "updateMatchGroupStatus",
    `${API_PREFIX}/invoice/matchGroups/${matchGroupId}/status`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        status,
        comment
      })
    }
  );
};

// Get match groups for a transaction line
export const getMatchGroups = (
  transactionLineId: number
): Promise<MatchGroup[]> => {
  return withTracking(
    "getMatchGroups",
    `${API_PREFIX}/invoice/transactionLines/${transactionLineId}/matchGroups`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};


/*
Tags
*/


export const getTags = (): Promise<Tag[]> => {
  return withTracking(
    "getTags",
    `${API_PREFIX}/tag`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const createTag = (data: { name: string; color: string }): Promise<Tag> => {
  return withTracking(
    "createTag",
    `${API_PREFIX}/tag`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
};

export const updateTag = (tagId: number, data: { name: string; color: string }): Promise<Tag> => {
  return withTracking(
    "updateTag",
    `${API_PREFIX}/tag/${tagId}`,
    {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    }
  );
};

export const deleteTag = (tagId: number): Promise<void> => {
  return withTracking(
    "deleteTag",
    `${API_PREFIX}/tag/${tagId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const tagItem = (tagId: number, taggableId: number, taggableType: string): Promise<void> => {
  return withTracking(
    "tagItem",
    `${API_PREFIX}/tag/tag`,
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ tagId, taggableId, taggableType }),
    }
  );
};

export const untagItem = (tagId: number, taggableType: string, taggableId: number): Promise<void> => {
  return withTracking(
    "untagItem",
    `${API_PREFIX}/tag/tag/${tagId}/${taggableType}/${taggableId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  );
};

export const getEntityTags = (entityType: string, entityId: number): Promise<Tag[]> => {
  return withTracking(
    "getEntityTags",
    `${API_PREFIX}/tag/${entityType}/${entityId}`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getJwt()}`,
      },
    }
  );
};

export default {
  createGoogleDocsSpreadsheet,
  getTour,
  withTracking,
  withTrackingHelper,
  getSubscription,
  getCredits,
  getInvoices,
  getTemplates,
  getTemplateTags,
  uploadFile,
  getIntegrations,
  createJob,
  getJobs,
  getJobData,
  getJob,
  exportJob,
  getJobExports,
  importTemplate,
  downloadSpreadsheet,
  savePreferenceServer,
  loadPreferenceServer,
  getTemplateAddedColumnMapping,
  addTemplateAddedColumnMapping,
  deleteTemplateAddedColumnMapping,
  deleteDocument,
  uploadCompressedFile,
  getTemplateFieldNames,
  getWordsInRectangle,
  inviteByEmail,
  getJobDocuments,
  getBadRatings,
  markReviewed,
  markJobReviewed,
  getDocumentFraudSignals,
  getDocumentFraudScore,
  getInvoiceApprovalHistory,
  createApprovalRule,
  getApprovalRules,
  updateApprovalRule,
  deleteApprovalRule,
  updateInvoiceApprovalStatus,
  getInvoiceApprovalStatus,
  createInvoiceRoutingRule,
  getInvoiceRoutingRules,
  updateInvoiceRoutingRule,
  deleteInvoiceRoutingRule
};

import { useSelector } from "react-redux";
import { ReduxState } from "../../../../redux";
import { useHasQbo } from "../../../../utils/utils";
import { convertTlsToTable, getDocumentName } from "./utils";
import { groupBy } from "lodash";

export function useTransactions(onlyIncluded = true) {
  const hasQbo = useHasQbo();

  const { job } = useSelector((state: ReduxState) => state.JobData);

  const { tls, accountChunkLabels } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );
  const { tls: transactions, documents } = tls;

  return convertTlsToTable(
    onlyIncluded
      ? (transactions || []).filter((x) => !!x.included)
      : transactions || [],
    documents,
    job,
    hasQbo,
    accountChunkLabels
  );
}

export function useTransactionsAutomaticMode(documentId, onlyIncluded = true) {
  const { tls } = useSelector((state: ReduxState) => state.TransactionManager);

  const data = tls.tls
    .filter((t) => t.documentId.toString() === documentId)
    .map((t) => {
      try {
        const row = JSON.parse(t.row as any);
        const obj = {};
        row.forEach((value, index) => {
          obj[index] = value;
        });
        return obj;
      } catch (err) {
        return {};
      }
    });

  return data;
}

export function useTotalsByAccount() {
  // account => chunk => fieldName
  const totalsByAccount = {};

  const { totalsByChunk } = useSelector(
    (state: ReduxState) => state.Reconciler
  );

  for (const account in totalsByChunk) {
    if (!(account in totalsByAccount)) {
      totalsByAccount[account] = {};
    }
    for (const documentId in totalsByChunk[account]) {
      for (const chunk in totalsByChunk[account][documentId]) {
        for (const name in totalsByChunk[account][documentId][chunk]) {
          if (!(name in totalsByAccount[account])) {
            totalsByAccount[account][name] = {
              numCredits: 0,
              numPayments: 0,
              totalCredits: 0,
              totalPayments: 0,
            };
          }
          totalsByAccount[account][name] = {
            numCredits:
              totalsByAccount[account][name].numCredits +
              totalsByChunk[account][documentId][chunk][name].numCredits,
            numPayments:
              totalsByAccount[account][name].numPayments +
              totalsByChunk[account][documentId][chunk][name].numPayments,

            totalCredits:
              totalsByAccount[account][name].totalCredits +
              parseFloat(
                totalsByChunk[account][documentId][chunk][name].totalCredits
              ),
            totalPayments:
              totalsByAccount[account][name].totalPayments +
              parseFloat(
                totalsByChunk[account][documentId][chunk][name].totalPayments
              ),
          };
        }
      }
    }

    return totalsByAccount;
  }
}

export function useChunkData() {
  const { tls, mfs } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );

  const grouped = groupBy(
    mfs.mfs || [],
    (item: any) => `${item?.documentId}-${item?.accountName}-${item?.chunk}`
  );

  const { documents } = tls;

  const { totalsByChunk } = useSelector(
    (state: ReduxState) => state.Reconciler
  );

  // console.log({ grouped });
  return Object.keys(grouped).map((key) => {
    const fields = grouped[key];
    const startBalance = fields.filter(
      (x) => (x as any)?.name === "startBalance"
    );
    const endBalance = fields.filter((x) => (x as any)?.name === "endBalance");
    const helper = (x) => (x.length > 0 ? x[0].value : "");

    const startDate = fields.filter((x) => (x as any)?.name === "startDate");
    const endDate = fields.filter((x) => (x as any)?.name === "endDate");
    const isReconciled = fields.filter(
      (x) => (x as any)?.name === "isReconciled"
    );
    let documentId = "";
    let accountName = "";
    let chunk = "";
    if (fields.length > 0) {
      documentId = (fields[0] as any).documentId;
      accountName = (fields[0] as any).accountName;
      chunk = (fields[0] as any).chunk;
    }

    let totals = {} as any;
    if (
      accountName in totalsByChunk &&
      documentId in totalsByChunk[accountName] &&
      chunk in totalsByChunk[accountName][documentId] &&
      "Transactions" in totalsByChunk[accountName][documentId][chunk]
    )
      totals = totalsByChunk[accountName][documentId][chunk].Transactions;

    return {
      documentId,
      documentName: getDocumentName(documents, documentId),
      accountName,
      chunk,
      startBalance: helper(startBalance),
      endBalance: helper(endBalance),
      startDate: helper(startDate),
      endDate: helper(endDate),
      isReconciled: helper(isReconciled),
      credits: `${totals?.totalCredits} (${totals?.numCredits})`,
      debits: `${totals?.totalPayments} (${totals?.numPayments})`,
    };
  });
}

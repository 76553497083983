import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import excelIcon from "../../../../../icons/excel.png";
import { ReduxState } from "../../../../../redux";
import {
  setReloadExportPreview,
  setSelectedCustomFormatType,
  setSelectedExportTypeModal,
} from "../../../../../redux/reducers/DownloadInvoiceOptions";
import { IconOption, IconValue } from "./utils";
import ClipLoader from "react-spinners/ClipLoader";
import {
  fetchCustomFormats,
  deleteSelectedCustomFormat,
  setSelectedCustomFormat,
  createCustomFormatRedux,
} from "../../../../../redux/reducers/CustomFormat";
import { toggleModal as toggleColumnConfigModal } from "../../../../../redux/reducers/ColumnConfig";
import { InvoiceFieldsSelector } from "./InvoiceFieldsSelector";

const allOptions = [
  {
    value: "csv",
    label: "Excel",
    icon: excelIcon,
  },
];

const defaultCustomFormatTypeOptions = [
  {
    value: "custom-all",
    label: "All (all columns)",
  },
];

const addNewCustomOption = {
  value: "add-new",
  label: "Create New Export Format",
};

export const ExportTypeInvoiceModalDropdown: React.FC = () => {
  const dispatch = useDispatch();
  const { customFormats, loading } = useSelector(
    (state: ReduxState) => state.CustomFormat
  );
  const { selectedExportTypeModal, selectedCustomFormatType } = useSelector(
    (state: ReduxState) => state.DownloadInvoiceOptions
  );
  const isDeleting = useRef(false);

  const customOptions = customFormats
    .map((x) => ({
      value: `custom-${x.name}`,
      label: x.name.charAt(0).toUpperCase() + x.name.slice(1),
      onEdit: () => {
        dispatch(setSelectedCustomFormat(`${x.name}`));
        dispatch(setSelectedExportTypeModal(`custom-${x.name}` as any));
        dispatch(toggleColumnConfigModal(true));
      },
      onDelete: () => {
        if (
          window.confirm("Are you sure you want to delete this configuration?")
        ) {
          isDeleting.current = true;
          dispatch(setSelectedCustomFormat(`${x.name}`));
          dispatch(setSelectedExportTypeModal(`custom-${x.name}` as any));
          dispatch(deleteSelectedCustomFormat());
          setTimeout(() => {
            isDeleting.current = false;
          }, 0);
        }
      },
    }))
    .filter((x) => x.value !== "custom-all" && x.value !== "custom-basic");

  const customFormatOptions = [
    ...defaultCustomFormatTypeOptions,
    {
      value: "custom",
      label: "Custom",
      options: [addNewCustomOption].concat(customOptions),
    },
  ];

  const getOptions = () => {
    return allOptions;
  };

  const optionsByType = getOptions();

  const selectedExportTypeOption =
    optionsByType.find((option) => option.value === selectedExportTypeModal) ||
    allOptions[0];

  const selectedCustomFormatTypeOption =
    customOptions.find((option) => option.value === selectedCustomFormatType) ||
    defaultCustomFormatTypeOptions[0];

  const handleExportTypeSelectChange = (option) => {
    dispatch(setSelectedExportTypeModal(option.value as any));
  };

  const handleCustomFormatSelectChange = (option) => {
    const callback = (newFormatName = "") => {
      dispatch(setSelectedCustomFormatType(newFormatName || option.value));
      dispatch(setReloadExportPreview(true));
    };

    if (option && !isDeleting.current) {
      if (option.value === "add-new") {
        dispatch(toggleColumnConfigModal(true));
        dispatch(createCustomFormatRedux({ type: "invoice", callback }));
        return;
      }
      callback();
    }
  };

  useEffect(() => {
    dispatch(fetchCustomFormats({ init: false, type: "invoice" }));
  }, []);

  if (loading) {
    return <ClipLoader />;
  }
  // console.log({ selectedExportTypeOption, selectedCustomFormatTypeOption });
  return (
    <>
      <Select
        maxMenuHeight={600}
        className="select-export-type"
        options={optionsByType}
        value={selectedExportTypeOption}
        onChange={handleExportTypeSelectChange}
        components={{ Option: IconOption, SingleValue: IconValue }}
      />
      <Select
        maxMenuHeight={600}
        className="select-custom-format mt-2"
        options={customFormatOptions}
        value={selectedCustomFormatTypeOption}
        onChange={handleCustomFormatSelectChange}
        components={{ Option: IconOption, SingleValue: IconValue }}
      />
      {selectedCustomFormatTypeOption.value === "custom-all" && (
        <div className="mt-2">
          <InvoiceFieldsSelector />
        </div>
      )}
    </>
  );
};

export default ExportTypeInvoiceModalDropdown;

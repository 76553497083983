import { Customer } from "../../docuclipper/DocuclipperTypes";

const initialState: {
  customer: Customer | null;
} = {
  customer: null
};

export const SET_CUSTOMER = "set-customer";
export const CLEAR_CUSTOMER = "clear-customer";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_CUSTOMER:
      return {
        ...state,
        customer: action.payload.customer
      };
    case CLEAR_CUSTOMER:
      return {
        ...state,
        customer: null
      };

    default:
      return state;
  }
}

export type SetCustomerFn = (customer: Customer) => any;
export const setCustomer = (customer: Customer) => ({
  type: SET_CUSTOMER,
  payload: {
    customer
  }
});

export type ClearCustomerFn = () => any;
export const clearCustomer = () => ({
  type: CLEAR_CUSTOMER,
  payload: {}
});

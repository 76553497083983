import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getTotals } from "../../docuclipper/api";
import { fetchJobDocuments } from "./JobDocument";
import { setAccountChunks, setAccounts } from "./TransactionManager";
import { ReduxState } from "..";
import { isNullOrUndefined } from "../../utils/utils";
import { uniq, flatten } from 'lodash';
import { ChunkData, MetadataField } from "src/docuclipper/DocuclipperTypes";


const initialState: {
  totals: {};
  totalsByDocumentId: {};
  totalsByChunk: Record<string, Record<string, Record<string, ChunkData>>>;
  badTransactionsByDocumentId: {};
  byDayByDocumentId: { reconciled: string[]; unreconciled: string[] };
  loading: boolean;
  error: string | null;
  allAccounts: string[];
  selectedAccount: string | null; // used for allaccounts dropdowns in fraud detection, categorization etc
} = {
  totals: {},
  totalsByDocumentId: {},
  totalsByChunk: {},
  badTransactionsByDocumentId: {},
  byDayByDocumentId: { reconciled: [], unreconciled: [] },
  loading: false,
  error: null,
  allAccounts: [],
  selectedAccount: null
};

const slice = createSlice({
  name: "Reconciler",
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
      state.error = null;
    },
    setTotals(state, action: PayloadAction<{}>) {
      state.loading = false;
      state.totals = action.payload;
    },
    setSelectedAccount(state, action: PayloadAction<string>) {
      state.selectedAccount = action.payload;

    },
    setTotalsByDocumentId(state, action: PayloadAction<{}>) {
      state.loading = false;
      state.totalsByDocumentId = action.payload;
      const docIds = Object.keys(action.payload || {});
      let accounts: any[] = [];
      for (const docId of docIds) {
        accounts.push(Object.keys((action.payload || {})[docId]));
      }

      state.allAccounts = uniq(flatten(accounts));

    },
    setTotalsByChunk(state, action: PayloadAction<{}>) {
      state.loading = false;
      state.totalsByChunk = action.payload;
    },
    setBadTransactionsByDocumentId(state, action: PayloadAction<{}>) {
      state.loading = false;
      state.badTransactionsByDocumentId = action.payload;
    },
    setByDayByDocumentId(
      state,
      action: PayloadAction<{ reconciled: string[]; unreconciled: string[] }>
    ) {
      state.loading = false;
      state.byDayByDocumentId = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  load,
  setError,
  setTotals,
  setTotalsByDocumentId,
  setTotalsByChunk,
  setBadTransactionsByDocumentId,
  setByDayByDocumentId,
  setSelectedAccount
} = slice.actions;

export const fetchTotals = () => async (dispatch, getState) => {
  const state: ReduxState = getState();

  const { files } = getState().Files;
  const cleanCsvDocumentId =
    files.length > 0 &&
      files[0].document &&
      files[0].document.mimetype ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ? files[0].document.id
      : null;

  // console.log(cleanCsvDocumentId, files);
  const { loading } = getState().Reconciler;
  if (loading) {
    return;
  }

  if (!state.JobData.job) {
    return;
  }
  const jobId = state.JobData.job.id;

  dispatch(load());
  try {
    const data = await getTotals(jobId, cleanCsvDocumentId);
    dispatch(setTotals(data.totals));
    dispatch(setTotalsByDocumentId(data.totalsByDocumentId || {}));
    dispatch(setTotalsByChunk(data.totalsByChunk || {}));
    // let selectedField: string = "";
    // const docIds = Object.keys(data.totalsByDocumentId || {});
    // if (docIds.length > 0) {
    //   const fields = Object.keys(data.totalsByDocumentId[docIds[0]]);
    //   if (fields.length > 0) {
    //     selectedField = fields[0];
    //     dispatch(setSelectedField(selectedField));
    //   }
    // }

    dispatch(setBadTransactionsByDocumentId(data.badTransactionsByDocumentId));
    dispatch(setByDayByDocumentId(data.byDayByDocumentId));
    // dispatch(fetchJobDocuments());

    // const totals = !isNullOrUndefined(
    //   state.TransactionManager.selectedDocumentId
    // )
    //   ? data.totalsByDocumentId
    //     ? data.totalsByDocumentId[
    //         state.TransactionManager.selectedDocumentId as string
    //       ]
    //     : {}
    //   : {};

    // dispatch(
    //   setAccounts({
    //     accounts: Object.keys(totals || {}),
    //     resetSelectedAccount: false,
    //   })
    // );
  } catch (err) {
    dispatch(setError(err.message || "Error getting totals"));
  }
};

export default slice.reducer;

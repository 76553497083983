import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import groupBy from "lodash/groupBy";
import uuidv4 from "uuid/v4";
import {
  getEdgesFromWords,
  getFragment,
  getJobData,
  getPage,
  getTable,
  getThumbnails,
  getVFragment,
  getVTable,
  getWordsInRectangle,
  updateFragment,
} from "../../docuclipper/api";
import {
  CanvasMode,
  Document,
  EdgeType,
  Fragment,
  Job,
  KonvaRectangle,
  Percentage,
  TemplateFieldV2,
  ToolType,
  VariableLocation,
  VariableLocationEdges,
  VerticalLine,
  Words,
  XPosition,
  YPosition,
} from "../../docuclipper/DocuclipperTypes";
import { doRectangleMath } from "../../rectangle-math";
import { sleep } from "../../utils/utils";
import {
  _clearJob,
  //   _setDocument,
  //   _fileLoad,
  //   _fileSetError,
  _setJob,
} from "./TemplateField/documents/documents";
import {
  _clearVFragments,
  _jobDataLoad,
  _jobDataSetData,
  _jobDataSetError,
  _load,
  _setError,
  _setFragment,
  _setVFragments,
  _updateFragmentRedux,
} from "./TemplateField/extract-data/extract-data";
import {
  _expandRectangle,
  _fixNegativeRectangles,
  _moveRectangle,
  _moveRectangleFromKey,
  _transformRectangle,
  _updateFixedLocation,
  _updateRectanglesOnPageChange,
} from "./TemplateField/fixed/fixed";
import {
  _pageLoad,
  _pageSetData,
  _pageSetError,
} from "./TemplateField/pages/pages";
import {
  _setAlignMiddleMultilineField,
  _setAlignMiddleReferenceField,
  _setAlignMiddleSeparator,
  _setNumColumns,
  _updateAlignMiddleEnabled,
  _updateColumnFieldType,
  _updateColumnName,
  _updateColumnWidth,
  _updateColumnWidthFromRectangle,
  _updateNegativeRegex,
  _updateNumHeaderRows,
  _updateQboConfig,
  _updateRegex,
  _updateVerticalMergeMasks,
  _updateVerticalMergeMasksEnabled,
} from "./TemplateField/table/table";
import {
  _setTemplateFields,
  _templateClear,
  _templateLoad,
  _templateSetData,
  _templateSetError,
} from "./TemplateField/templates/templates";
import {
  _thumbnailsDeleteAll,
  _thumbnailsLoad,
  _thumbnailsSetData,
  _thumbnailsSetError,
} from "./TemplateField/thumbnails/thumbnails";
import {
  _addForbiddenWords,
  _addVariableField,
  _deleteForbiddenWords,
  _deleteVariableField,
  _duplicateVariableField,
  _setVariableSelectedId,
  _updateEdgePercentage,
  _updateEdgePercentageDisable,
  _updateEdgePercentageFromKey,
  _updateEdgePercentageFromLine,
  _updateForbiddenWords,
  _updateForbiddenWordsEnabled,
  _updateIncludeWords,
  _updateVlOffset,
  _wordsLoad,
  _wordsSetData,
  _wordsSetError,
  _wordsSetWords,
} from "./TemplateField/variable/variable";
import { TemplateFieldState } from "./TemplateFieldTypes";
import { mapTemplateFieldFromClientToServer } from "./TemplateFieldUtils";

const initialState: TemplateFieldState = {
  copiedRectangleId: null,
  template: {
    loading: false,
    error: null,
    template: null,
  },
  // template: {
  //   loading: false,
  //   error: null,
  //   template: {
  //     name: "v2 template",
  //     type: "FreeForm",
  //     description: "new v2 template",
  //   },
  // },
  templateFields: [],
  selectedFieldId: null,
  stage: null,
  // page: null,
  page: {
    loading: false,
    error: null,
    // page: {
    //   // height: 1650,
    //   // width: 1275,
    //   height: 1275,
    //   width: 1650,
    //   imageSrc: "http://127.0.0.1:8081/2x3.png",
    //   // imageSrc: "http://127.0.0.1:8081/tables-150.png",

    //   pageNumber: 0,
    // },
    page: null,
  },
  canvasMode: "rectangles",
  // selectedEdge: null,
  selectedEdge: "top", //TODO
  // // document: {
  // //   document: null,
  // //   loading: false,
  // //   error: null,
  // // },
  // document: {
  //   document: {
  //     id: 4573,
  //     // id: 4577,
  //     numPages: 2,
  //     // numPages: 108,
  //   } as any,
  //   loading: false,
  //   error: null,
  // },
  job: {
    job: null,
    loading: false,
    error: null,
    fragments: [],
  },
  thumbnails: {
    error: null,
    loading: false,
    hasMore: true,
    offset: 0,
    thumbnails: [],
  },
  fieldSection: {
    bottomEdge: { hasError: false, expanded: false },
    leftEdge: { hasError: false, expanded: false },
    topEdge: { hasError: false, expanded: false },
    rightEdge: { hasError: false, expanded: false },

    tableConfig: { hasError: false, expanded: false },
    variableLocation: { hasError: false, expanded: false },
    fieldFormat: { hasError: false, expanded: true },
    fieldLocation: { hasError: false, expanded: true },
    fieldPreview: { hasError: false, expanded: true },
    fieldType: { hasError: false, expanded: true },
    pageConfig: { hasError: false, expanded: false },
    name: { hasError: false, expanded: true },
    fieldConfig: { hasError: false, expanded: true },
  },
};

const slice = createSlice({
  name: "TemplateField",
  initialState,
  reducers: {
    // setDocument: _setDocument,
    setJob: _setJob,
    clearJob: _clearJob,
    // fileLoad: _fileLoad,
    // fileSetError: _fileSetError,

    load: _load,
    setFragment: _setFragment,
    setError: _setError,
    setVFragments: _setVFragments,
    clearVFragments: _clearVFragments,
    updateFragmentRedux: _updateFragmentRedux,

    jobDataLoad: _jobDataLoad,
    jobDataSetError: _jobDataSetError,
    jobDataSetData: _jobDataSetData,

    templateLoad: _templateLoad,
    templateSetData: _templateSetData,
    templateSetError: _templateSetError,
    templateClear: _templateClear,
    setTemplateFields: _setTemplateFields,

    thumbnailsLoad: _thumbnailsLoad,
    thumbnailsSetData: _thumbnailsSetData,
    thumbnailsSetError: _thumbnailsSetError,
    thumbnailsDeleteAll: _thumbnailsDeleteAll,

    setNumColumns: _setNumColumns,
    updateColumnFieldType: _updateColumnFieldType,
    updateColumnWidth: _updateColumnWidth,
    updateColumnName: _updateColumnName,
    updateColumnWidthFromRectangle: _updateColumnWidthFromRectangle,
    updateVerticalMergeMasks: _updateVerticalMergeMasks,
    updateVerticalMergeMasksEnabled: _updateVerticalMergeMasksEnabled,

    updateAlignMiddleEnabled: _updateAlignMiddleEnabled,
    setAlignMiddleReferenceField: _setAlignMiddleReferenceField,
    setAlignMiddleMultilineField: _setAlignMiddleMultilineField,
    setAlignMiddleSeparator: _setAlignMiddleSeparator,

    updateNumHeaderRows: _updateNumHeaderRows,
    updateRegex: _updateRegex,
    updateNegativeRegex: _updateNegativeRegex,
    updateQboConfig: _updateQboConfig,

    pageLoad: _pageLoad,
    pageSetData: _pageSetData,
    pageSetError: _pageSetError,

    // variable location
    setVariableSelectedId: _setVariableSelectedId,
    updateEdgePercentageFromLine: _updateEdgePercentageFromLine,
    updateEdgePercentage: _updateEdgePercentage,
    updateEdgePercentageDisable: _updateEdgePercentageDisable,
    wordsLoad: _wordsLoad,
    wordsSetError: _wordsSetError,
    wordsSetWords: _wordsSetWords,
    wordsSetData: _wordsSetData,
    updateEdgePercentageFromKey: _updateEdgePercentageFromKey,
    // words
    updateIncludeWords: _updateIncludeWords,
    updateVlOffset: _updateVlOffset,
    updateForbiddenWordsEnabled: _updateForbiddenWordsEnabled,
    updateForbiddenWords: _updateForbiddenWords,
    addForbiddenWords: _addForbiddenWords,
    deleteForbiddenWords: _deleteForbiddenWords,

    addVariableField: _addVariableField,
    deleteVariableField: _deleteVariableField,
    duplicateVariableField: _duplicateVariableField,

    moveRectangle: _moveRectangle,
    moveRectangleFromKey: _moveRectangleFromKey,
    transformRectangle: _transformRectangle,
    fixNegativeRectangles: _fixNegativeRectangles,
    updateFixedLocation: _updateFixedLocation,
    expandRectangle: _expandRectangle,
    updateRectanglesOnPageChange: _updateRectanglesOnPageChange,

    copyRectangle(state, action: PayloadAction<{ id: string }>) {
      state.copiedRectangleId = action.payload.id;
    },

    fieldSectionSetExpanded(
      state,
      action: PayloadAction<{ name: string; expanded: boolean }>
    ) {
      const { name, expanded } = action.payload;

      state.fieldSection = {
        ...state.fieldSection,
        [name]: {
          ...state.fieldSection[name],
          expanded,
        },
      };
      return state;
    },
    fieldSectionSetHasError(
      state,
      action: PayloadAction<{ name: string; hasError: boolean }>
    ) {
      const { name, hasError } = action.payload;

      state.fieldSection = {
        ...state.fieldSection,
        [name]: {
          ...state.fieldSection[name],
          hasError,
        },
      };
      return state;
    },
    setCanvasMode(state, action: PayloadAction<{ canvasMode: CanvasMode }>) {
      state.canvasMode = action.payload.canvasMode;
    },
    setSelectedEdge(
      state,
      action: PayloadAction<{
        selectedEdge: TemplateFieldState["selectedEdge"];
      }>
    ) {
      state.selectedEdge = action.payload.selectedEdge;
    },
    setPage(
      state,
      action: PayloadAction<{
        height: number;
        width: number;
        imageSrc: string;
        pageNumber: number;
      }>
    ) {
      const { height, width, imageSrc, pageNumber } = action.payload;
      state.page.page = {
        height,
        width,
        imageSrc,
        pageNumber: parseInt(pageNumber.toString(), 10),
      };
    },

    deleteAllFields(state, action: PayloadAction<void>) {
      state.templateFields = [];
      state.selectedFieldId = "";
    },
    addTemplateFieldWithId(
      state,
      action: PayloadAction<{ id: string; name: string; toolType: ToolType }>
    ) {
      const { id, name, toolType } = action.payload;
      // if (!state.page.page) {
      //   return state;
      // }
      // if (!state.stage) {
      //   return state;
      // }
      const tf: TemplateFieldV2 = createDefaultFixedLocationTemplateField({
        stageWidth: state.stage ? state.stage.width || 0 : 0,
        stageHeight: state.stage ? state.stage.height || 0 : 0,
        id,
        page: -1, //state.page.page.pageNumber,
        fixedLocation: null,
      });

      tf.locationType = "variable";
      tf.toolType = toolType;
      tf.name = name;
      state.templateFields = [...state.templateFields, tf];
      return state;
    },
    addTemplateField(
      state,
      action: PayloadAction<{ x: number; y: number; id: string }>
    ) {
      if (!state.page.page) {
        return state;
      }
      if (!state.stage) {
        return state;
      }
      const id = uuidv4();
      const { x, y, id: rectangleId } = action.payload;
      const rectangle = createDefaultRectangle({
        id: rectangleId,
        x,
        y,
        templateFieldId: id,
      });
      const { x0, x1, y0, y1 } = doRectangleMath(
        { ...rectangle },
        { stageHeight: state.stage.height, stageWidth: state.stage.width },
        {
          pageHeight: state.page.page.height,
          pageWidth: state.page.page.width,
        }
      );
      const tf = createDefaultFixedLocationTemplateField({
        stageWidth: state.stage?.width || 0,
        stageHeight: state.stage?.height || 0,
        id,
        page: parseInt(state.page.page.pageNumber.toString(), 10),
        fixedLocation: {
          pageLocation: {
            x0,
            x1,
            y0,
            y1,
          },
          rectangle,
        },
      });
      state.templateFields = [...state.templateFields, tf];
    },
    selectTemplateFieldOnRectangleClick(
      state,
      action: PayloadAction<{ id: string }>
    ) {
      const { id } = action.payload;

      if (!id) {
        state.selectedFieldId = "";
        return state;
      }
      const maybeField = state.templateFields.filter((tf) => {
        if (!tf.fixedLocation || !tf.fixedLocation.rectangle) {
          return false;
        }
        return tf.fixedLocation.rectangle.id === id;
      });
      if (maybeField.length > 0) {
        state.selectedFieldId = maybeField[0].id;
      } else {
        // loop thru fragments
        state.templateFields = state.templateFields.map((tf) => {
          for (const f of tf.fragments.fragments) {
            if (f.rectangle.id === id) {
              tf.fragments.selectedFragmentId = id;
              state.selectedFieldId = tf.id;
            }
          }
          return tf;
        });
      }
    },
    selectTemplateField(state, action: PayloadAction<{ id: string }>) {
      state.selectedFieldId = action.payload.id;
    },
    removeTemplateFieldFromRectangle(
      state,
      action: PayloadAction<{ id: string }>
    ) {
      const { id: rectangleId } = action.payload;

      state.templateFields = [
        ...state.templateFields.filter((tf) => {
          if (!tf.fixedLocation || !tf.fixedLocation.rectangle) {
            return true;
          }
          return tf.fixedLocation.rectangle.id !== rectangleId;
        }),
      ];
    },
    removeTemplateField(state, action: PayloadAction<{ id: string }>) {
      state.templateFields = [
        ...state.templateFields.filter((tf) => tf.id !== action.payload.id),
      ];
      if (state.selectedFieldId === action.payload.id) {
        if (state.templateFields.length > 0) {
          state.selectedFieldId = state.templateFields[0].id;
        } else {
          state.selectedFieldId = "";
        }
      }
    },
    duplicateTemplateField(
      state,
      action: PayloadAction<{ id: string; newId: string }>
    ) {
      const { id, newId } = action.payload;
      const tfs = state.templateFields.filter((tf) => tf.id === id);
      if (tfs.length > 0) {
        const tf = tfs[0];
        const copiedField = {
          ...tf,
          name: `${tf.name} copy`,
          tableConfig: {
            ...tf.tableConfig,
            columns: tf.tableConfig.columns.map((c) => {
              return {
                ...c,
                line: {
                  ...c.line,
                  id: uuidv4(),
                },
              };
            }),
          },
          fixedLocation: !tf.fixedLocation
            ? null
            : {
                ...tf.fixedLocation,
                rectangle: {
                  ...tf.fixedLocation?.rectangle,
                  id: uuidv4(),
                },
              },
          variableLocation: !tf.variableLocation
            ? null
            : {
                ...tf.variableLocation,
                variableLocations: tf.variableLocation.variableLocations.map(
                  (vl) => {
                    return {
                      ...vl,
                      forbiddenWords: vl.forbiddenWords.map((fw) => ({
                        ...fw,
                        id: uuidv4(),
                      })),
                      top: {
                        ...vl.top,
                        words: {
                          ...(vl.top.words as Words),
                          lines: [],
                        },
                        percentage: !vl.top.percentage
                          ? null
                          : {
                              ...vl.top.percentage,
                              line: {
                                ...vl.top.percentage?.line,
                                id: uuidv4(),
                              },
                            },
                      },
                      bottom: {
                        ...vl.bottom,
                        words: {
                          ...(vl.bottom.words as Words),
                          lines: [],
                        },
                        percentage: !vl.bottom.percentage
                          ? null
                          : {
                              ...vl.bottom.percentage,
                              line: {
                                ...vl.bottom.percentage?.line,
                                id: uuidv4(),
                              },
                            },
                      },
                      left: {
                        ...vl.left,
                        words: {
                          ...(vl.left.words as Words),
                          lines: [],
                        },
                        percentage: !vl.left.percentage
                          ? null
                          : {
                              ...vl.left.percentage,
                              line: {
                                ...vl.left.percentage?.line,
                                id: uuidv4(),
                              },
                            },
                      },
                      right: {
                        ...vl.right,
                        words: {
                          ...(vl.right.words as Words),
                          lines: [],
                        },
                        percentage: !vl.right.percentage
                          ? null
                          : {
                              ...vl.right.percentage,
                              line: {
                                ...vl.right.percentage?.line,
                                id: uuidv4(),
                              },
                            },
                      },
                    };
                  }
                ),
              },
          fragments: {
            fragments: [],
            loading: false,
            error: null,
            selectedFragmentId: "",
          },
          id: newId,
        };
        state.templateFields = [...state.templateFields, copiedField];
      }
    },
    updateTemplateField(
      state,
      action: PayloadAction<{
        update: Partial<TemplateFieldV2>;
        id: string;
      }>
    ) {
      const { id, update } = action.payload;
      state.templateFields = state.templateFields.map((tf) => {
        if (tf.id !== id) {
          return tf;
        }

        return {
          ...tf,
          ...update,
        };
      });
    },

    zoomStageIn(state, action: PayloadAction<{}>) {
      if (!state.stage) {
        return;
      }
      state.stage.scaleBy *= 1.05;
    },
    zoomStageOut(state, action: PayloadAction<{}>) {
      if (!state.stage) {
        return;
      }
      state.stage.scaleBy /= 1.05;
    },
    setDragMode(state, action: PayloadAction<{ dragModeEnabled: boolean }>) {
      if (!state.stage) {
        return;
      }
      state.stage.dragModeEnabled = action.payload.dragModeEnabled;
    },
    resizeRectangles(
      state,
      action: PayloadAction<{
        width: number;
        height: number;
        previousWidth: number;
        previousHeight: number;
      }>
    ) {
      const { width, height, previousHeight, previousWidth } = action.payload;

      if (
        width === 0 ||
        height === 0 ||
        previousHeight === 0 ||
        previousWidth === 0
      ) {
        return;
      }
      state.templateFields = state.templateFields.map(
        (tf): TemplateFieldV2 => {
          const scaleWidth = width / previousWidth;
          const scaleHeight = height / previousHeight;

          let rectangle;
          if (tf.fixedLocation) {
            rectangle = tf.fixedLocation.rectangle;
          }

          return {
            ...tf,
            fixedLocation:
              tf.locationType === "fixed" && tf.fixedLocation
                ? {
                    pageLocation: tf.fixedLocation.pageLocation,
                    rectangle: {
                      ...tf.fixedLocation.rectangle,
                      x: rectangle.x * scaleWidth,
                      y: rectangle.y * scaleHeight,
                      height: rectangle.height * scaleHeight,
                      width: rectangle.width * scaleWidth,
                    },
                  }
                : tf.fixedLocation,
            variableLocation:
              tf.locationType === "variable" && tf.variableLocation
                ? {
                    ...tf.variableLocation,
                    variableLocations: tf.variableLocation.variableLocations.map(
                      (vl) => {
                        return {
                          ...vl,
                          top:
                            vl.top.percentage &&
                            vl.top.percentage.line.horizontalLine
                              ? {
                                  ...vl.top,
                                  percentage: {
                                    ...vl.top.percentage,
                                    line: {
                                      ...vl.top.percentage.line,
                                      horizontalLine: {
                                        ...vl.top.percentage.line
                                          .horizontalLine,
                                        y:
                                          vl.top.percentage.line.horizontalLine
                                            .y * scaleHeight,
                                      },
                                    },
                                  },
                                }
                              : vl.top,
                          bottom:
                            vl.bottom.percentage &&
                            vl.bottom.percentage.line.horizontalLine
                              ? {
                                  ...vl.bottom,
                                  percentage: {
                                    ...vl.bottom.percentage,
                                    line: {
                                      ...vl.bottom.percentage.line,
                                      horizontalLine: {
                                        ...vl.bottom.percentage.line
                                          .horizontalLine,
                                        y:
                                          vl.bottom.percentage.line
                                            .horizontalLine.y * scaleHeight,
                                      },
                                    },
                                  },
                                }
                              : vl.bottom,
                          left:
                            vl.left.percentage &&
                            vl.left.percentage.line.verticalLine
                              ? {
                                  ...vl.left,
                                  percentage: {
                                    ...vl.left.percentage,
                                    line: {
                                      ...vl.left.percentage.line,
                                      verticalLine: {
                                        ...vl.left.percentage.line.verticalLine,
                                        x:
                                          vl.left.percentage.line.verticalLine
                                            .x * scaleWidth,
                                      },
                                    },
                                  },
                                }
                              : vl.left,
                          right:
                            vl.right.percentage &&
                            vl.right.percentage.line.verticalLine
                              ? {
                                  ...vl.right,
                                  percentage: {
                                    ...vl.right.percentage,
                                    line: {
                                      ...vl.right.percentage.line,
                                      verticalLine: {
                                        ...vl.right.percentage.line
                                          .verticalLine,
                                        x:
                                          vl.right.percentage.line.verticalLine
                                            .x * scaleWidth,
                                      },
                                    },
                                  },
                                }
                              : vl.right,
                        };
                      }
                    ),
                  }
                : tf.variableLocation,
            tableConfig: {
              ...tf.tableConfig,
              columns: tf.tableConfig.columns.map((col, i) => {
                const newX =
                  (col.line.verticalLine as VerticalLine).x * scaleWidth;
                if (i > 0) {
                  (tf.tableConfig.columns[i - 1].line
                    .verticalLine as VerticalLine).maxX = newX;
                }
                if (i < tf.tableConfig.columns.length - 1) {
                  (tf.tableConfig.columns[i + 1].line
                    .verticalLine as VerticalLine).minX = newX;
                }
                return {
                  ...col,
                  line: {
                    ...col.line,
                    verticalLine: {
                      ...(col.line.verticalLine as VerticalLine),
                      x: newX,
                      y0:
                        ((col.line.verticalLine as VerticalLine).y0 as number) *
                        scaleHeight,
                      y1:
                        ((col.line.verticalLine as VerticalLine).y1 as number) *
                        scaleHeight,
                    },
                  },
                };
              }),
            },
            fragments: {
              ...tf.fragments,
              fragments: tf.fragments.fragments.map((f) => {
                return {
                  ...f,
                  rectangle: {
                    ...f.rectangle,
                    x: f.rectangle.x * scaleWidth,
                    y: f.rectangle.y * scaleHeight,
                    height: f.rectangle.height * scaleHeight,
                    width: f.rectangle.width * scaleWidth,
                  },
                };
              }),
            },
          };
        }
      );
    },
    setStageSize(
      state,
      action: PayloadAction<{ height: number; width: number }>
    ) {
      state.stage = {
        height: action.payload.height,
        width: action.payload.width,
        scaleBy: 1.0,
        dragModeEnabled: false,
      };
    },
  },
});

export const {
  // fields
  addTemplateField,
  addTemplateFieldWithId,
  removeTemplateField,
  duplicateTemplateField,
  removeTemplateFieldFromRectangle,
  updateTemplateField,
  deleteAllFields,
  selectTemplateField,
  selectTemplateFieldOnRectangleClick,

  // fixed location
  moveRectangle,
  moveRectangleFromKey,
  transformRectangle,
  fixNegativeRectangles,
  updateFixedLocation,
  expandRectangle,
  updateRectanglesOnPageChange,

  // variable location
  setVariableSelectedId,
  updateEdgePercentageFromLine,
  updateEdgePercentage,
  updateEdgePercentageDisable,

  updateEdgePercentageFromKey,
  wordsLoad,
  wordsSetError,
  wordsSetWords,
  wordsSetData,
  // words
  updateIncludeWords,
  updateVlOffset,

  updateForbiddenWordsEnabled,
  updateForbiddenWords,
  addForbiddenWords,
  deleteForbiddenWords,

  addVariableField,
  deleteVariableField,
  duplicateVariableField,

  // stage
  setStageSize,
  resizeRectangles,
  zoomStageIn,
  zoomStageOut,
  setDragMode,

  // misc
  setCanvasMode,
  setSelectedEdge,
  fieldSectionSetExpanded,
  fieldSectionSetHasError,
  copyRectangle,

  // table
  setNumColumns,
  updateColumnFieldType,
  updateColumnWidth,
  updateColumnName,
  updateColumnWidthFromRectangle,
  updateVerticalMergeMasks,
  updateVerticalMergeMasksEnabled,
  updateNumHeaderRows,
  updateQboConfig,

  updateAlignMiddleEnabled,
  setAlignMiddleMultilineField,
  setAlignMiddleReferenceField,
  setAlignMiddleSeparator,

  // regex
  updateRegex,
  updateNegativeRegex,

  // extract data
  load,
  setFragment,
  setError,
  setVFragments,
  clearVFragments,
  updateFragmentRedux,
  jobDataLoad,
  jobDataSetData,
  jobDataSetError,

  // pages
  pageLoad,
  pageSetData,
  pageSetError,

  // thumbnails
  thumbnailsLoad,
  thumbnailsSetData,
  thumbnailsSetError,
  thumbnailsDeleteAll,

  // templates

  templateLoad,
  templateSetData,
  templateSetError,
  setTemplateFields,
  templateClear,

  // documents

  // setDocument,
  setJob,
  clearJob,
  // fileLoad,
  // fileSetError,
} = slice.actions;

export const fetchPage = ({ pageNumber }: { pageNumber: number }) => async (
  dispatch,
  getState
) => {
  let state: TemplateFieldState = getState().TemplateField;
  if (state.page.loading) {
    return;
  }

  let document: Document | undefined;
  const files = getState().Files.files;
  if (files.length > 0) {
    document = files[0].document;
  }
  const { selectedFieldId, templateFields, canvasMode, job } = state;

  if (!document) {
    return;
  }

  try {
    dispatch(pageLoad({}));

    dispatch(clearVFragments());

    if (job.job) {
      dispatch(jobDataLoad({}));
    }

    const requests: Promise<any>[] = [getPage(document.id, pageNumber)];
    if (job.job) {
      requests.push(
        getJobData({
          jobId: job.job.id,
          pageNumber,
          documentId: document.id,
          offset: 0,
          limit: 100,
        })
      );
    } else {
      requests.push(Promise.resolve());
    }
    const [rsp1, rsp2] = await Promise.all(requests);
    const { page, data } = rsp1;
    const jobData = rsp2;

    dispatch(pageSetData({ page: { ...page, imageSrc: data } }));

    if (canvasMode === "rectangles" && selectedFieldId) {
      dispatch(
        fetchExtractedData({
          id: selectedFieldId,
          pageNumber,
        })
      );
      // if vlocation words, calculate edges
      const tfs = templateFields.filter((tf) => tf.id === selectedFieldId);
      if (tfs.length > 0) {
        const tf = tfs[0];

        if (tf.locationType === "variable") {
          dispatch(fetchEdgesFromWords({ id: tf.id, edge: "top", pageNumber }));
          dispatch(
            fetchEdgesFromWords({ id: tf.id, edge: "bottom", pageNumber })
          );
          dispatch(
            fetchEdgesFromWords({ id: tf.id, edge: "left", pageNumber })
          );
          dispatch(
            fetchEdgesFromWords({ id: tf.id, edge: "right", pageNumber })
          );
        }
      }
    }

    if (job.job) {
      dispatch(jobDataSetData());

      state = getState().TemplateField;
      let i = 0;
      while ((!state.stage || state.page.loading) && i < 40) {
        await sleep(50);
        state = getState().TemplateField;
        i += 1;
      }

      // stage height still changing
      await sleep(100);

      dispatch(
        setFragmentsFromJobData({
          job: job.job,
          pageNumber,
          data: jobData,
        })
      );
    }
  } catch (err) {
    dispatch(pageSetError({ error: err.message || "Error getting page" }));
    dispatch(jobDataSetError(err.message || "Error fetching job data"));
  }
};

export const fetchExtractedData = ({
  id,
  pageNumber,
}: {
  id: string;
  pageNumber?: number;
}) => async (dispatch, getState) => {
  const state: TemplateFieldState = getState().TemplateField;
  const { templateFields } = state;
  const tfs = templateFields.filter((tf) => tf.id === id);
  if (tfs.length > 0) {
    const tf = tfs[0];
    if (tf.locationType === "fixed") {
      dispatch(fetchFixedExtractedData({ id, pageNumber }));
    } else if (tf.locationType === "variable") {
      dispatch(
        fetchVariableExtractedData({ id, pageNumber, clearOnError: false })
      );
    }
  }
};

export const fetchExtractedDataFromRectangle = ({
  id,
}: {
  id: string;
}) => async (dispatch, getState) => {
  const state: TemplateFieldState = getState().TemplateField;
  const { templateFields } = state;
  const tfs = templateFields.filter(
    (tf) =>
      tf.locationType === "fixed" &&
      tf.fixedLocation &&
      tf.fixedLocation.rectangle.id === id
  );
  if (tfs.length > 0) {
    const tf = tfs[0];
    dispatch(fetchFixedExtractedData({ id: tf.id }));
  }
};

export const fetchExtractedDataFromLine = ({ id }: { id: string }) => async (
  dispatch,
  getState
) => {
  // table column or edge
  const state: TemplateFieldState = getState().TemplateField;
  const { templateFields } = state;
  let selectedTf: TemplateFieldV2 | null = null;

  for (const tf of templateFields) {
    for (const col of tf.tableConfig.columns) {
      if (col.line.id === id) {
        selectedTf = tf;
        break;
      }
    }
  }

  if (!selectedTf) {
    for (const tf of templateFields) {
      if (tf.locationType === "variable" && tf.variableLocation) {
        for (const vl of tf.variableLocation.variableLocations) {
          if (vl.id === tf.variableLocation.selectedId) {
            for (const edge of ["top", "bottom", "left", "right"]) {
              if (
                vl[edge as EdgeType].percentage &&
                (vl[edge as EdgeType].percentage as Percentage).line.id === id
              ) {
                selectedTf = tf;
                break;
              }
            }
          }
        }
      }
    }
  }

  if (selectedTf) {
    if (selectedTf.locationType === "fixed") {
      dispatch(fetchFixedExtractedData({ id: selectedTf.id }));
    } else if (selectedTf.locationType === "variable") {
      dispatch(fetchVariableExtractedData({ id: selectedTf.id }));
    }
  }
};

const fetchFixedExtractedData = ({
  id,
  pageNumber,
}: {
  id: string;
  pageNumber?: number;
}) => async (dispatch, getState) => {
  const state: TemplateFieldState = getState().TemplateField;
  if (!state.page.page) {
    return;
  }
  let document: Document | undefined;
  const files = getState().Files.files;
  if (files.length > 0) {
    document = files[0].document;
  }

  if (!document) {
    return;
  }

  const tfs = state.templateFields.filter((tf) => tf.id === id);
  if (tfs.length > 0) {
    const tf = tfs[0];
    dispatch(load({ id }));
    try {
      if (tf.fixedLocation && state.page && document) {
        const { checkLabels, fieldType, toolType } = tf;
        const { x0, x1, y0, y1 } = tf.fixedLocation.pageLocation;
        let fragment: Fragment | null = null;
        if (["ocr", "handwriting", "checkbox"].includes(tf.toolType)) {
          fragment = await getFragment({
            checkLabels: checkLabels,
            fieldType,
            fragmentId: null,
            jobId: state.job.job ? state.job.job.id : null,
            documentId: document.id,
            pageNumber:
              pageNumber !== undefined
                ? pageNumber
                : state.page.page.pageNumber,
            toolType: toolType,
            x0,
            x1,
            y0,
            y1,
          });
        } else if (["tables", "ocrtables", "checkbox"].includes(tf.toolType)) {
          const { x0, x1, y0, y1 } = tf.fixedLocation.pageLocation;
          fragment = await getTable({
            fragmentId: null,
            jobId: state.job.job ? state.job.job.id : null,
            documentId: document.id,
            pageNumber:
              pageNumber !== undefined
                ? pageNumber
                : state.page.page.pageNumber,
            tableConfig: tf.tableConfig,
            toolType: tf.toolType,
            x0,
            x1,
            y0,
            y1,
          });
        }

        if (fragment) {
          dispatch(setFragment({ id, fragment }));
        }
      }
    } catch (err) {
      dispatch(setError({ id, error: err.message || "Error extracting data" }));
    }
  }
};

const fetchVariableExtractedData = ({
  id,
  pageNumber,
  clearOnError,
}: {
  id: string;
  pageNumber?: number;
  clearOnError?: boolean;
}) => async (dispatch, getState) => {
  const state: TemplateFieldState = getState().TemplateField;
  if (!state.page.page) {
    return;
  }
  let document: Document | undefined;
  const files = getState().Files.files;
  if (files.length > 0) {
    document = files[0].document;
  }

  if (!document) {
    return;
  }

  dispatch(load({ id }));
  try {
    const tfs = state.templateFields.filter((tf) => tf.id === id);
    if (tfs.length > 0) {
      const tf = tfs[0];
      if (tf.variableLocation && state.page && document) {
        for (const edge of ["top", "bottom", "left", "right"]) {
          dispatch(
            fieldSectionSetHasError({
              name: `${edge}Edge`,
              hasError: false,
            })
          );
        }

        let fragments: Fragment[] = [];
        let errors: string[] = [];
        let rsp;
        const tfServer = mapTemplateFieldFromClientToServer(tf);
        if (["ocr", "handwriting", "checkbox"].includes(tf.toolType)) {
          rsp = await getVFragment({
            documentId: document.id,
            pageNumber:
              pageNumber !== undefined
                ? pageNumber
                : state.page.page.pageNumber,
            variableLocations: (tfServer.variableLocation as VariableLocation)
              .variableLocations,
            toolType: tfServer.toolType,
            fieldType: tfServer.fieldType,
            tableConfig: tfServer.tableConfig,
            checkLabels: tfServer.checkLabels,
          });
        } else if (["tables", "ocrtables", "checkbox"].includes(tf.toolType)) {
          rsp = await getVTable({
            documentId: document.id,
            pageNumber:
              pageNumber !== undefined
                ? pageNumber
                : state.page.page.pageNumber,
            variableLocations: (tfServer.variableLocation as VariableLocation)
              .variableLocations,
            toolType: tfServer.toolType,
            tableConfig: tfServer.tableConfig,
          });
        }
        fragments = rsp.fragments;
        errors = rsp.errors;

        if (errors.length > 0) {
          if (clearOnError === undefined || clearOnError === true) {
            dispatch(clearVFragments());
          }

          errors.push("Please update the variable field configuration below.");
          dispatch(setError({ id, error: errors.join("\n") }));
          for (const error of errors) {
            for (const edge of ["top", "bottom", "left", "right"]) {
              if (error.includes(`${edge} edge`)) {
                dispatch(
                  fieldSectionSetHasError({
                    name: "variableLocation",
                    hasError: true,
                  })
                );
                dispatch(
                  fieldSectionSetExpanded({
                    name: "variableLocation",
                    expanded: true,
                  })
                );
                break;
              }
            }
          }
        } else {
          dispatch(setVFragments({ id, fragments }));
        }
      }
    }
  } catch (err) {
    dispatch(setError({ id, error: err.message || "Error extracting data" }));
  }
};

export const fetchWords = ({
  id,
  x,
  y,
  width,
  height,
  vlId,
}: {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  vlId?: string;
}) => async (dispatch, getState) => {
  const state: TemplateFieldState = getState().TemplateField;

  if (!state.stage) {
    return;
  }
  if (!state.page.page) {
    return;
  }
  let document: Document | undefined;
  const files = getState().Files.files;
  if (files.length > 0) {
    document = files[0].document;
  }

  if (!document) {
    return;
  }
  if (!state.selectedFieldId) {
    return;
  }
  const { x0, x1, y0, y1 } = doRectangleMath(
    { x, y, width, height, scaleX: 1.0, scaleY: 1.0 },
    {
      stageHeight: state.stage.height,
      stageWidth: state.stage.width,
    },
    {
      pageHeight: state.page.page.height,
      pageWidth: state.page.page.width,
    }
  );

  let position: XPosition | YPosition = "top";
  const tfs = state.templateFields.filter(
    (tf) => tf.id === state.selectedFieldId
  );
  if (tfs.length > 0) {
    const tf = tfs[0];
    if (tf.variableLocation) {
      const vls = tf.variableLocation.variableLocations.filter(
        (vl) => vl.id === tf.variableLocation?.selectedId
      );
      if (vls.length > 0) {
        const vl = vls[0];
        if (state.selectedEdge) {
          if (vl[state.selectedEdge].words) {
            if (["top", "bottom"].includes(state.selectedEdge)) {
              position = (vl[state.selectedEdge].words as Words)
                .yPosition as YPosition;
            } else if (["left", "right"].includes(state.selectedEdge)) {
              position = (vl[state.selectedEdge].words as Words)
                .xPosition as XPosition;
            }
          }
        }
      }

      try {
        dispatch(
          wordsLoad({ id, vlId: vlId || tf.variableLocation.selectedId })
        );

        if (state.page.page && document) {
          const { words, edges } = await getWordsInRectangle({
            documentId: document.id,
            pageNumber: state.page.page.pageNumber,
            x0,
            x1,
            y0,
            y1,
            edge: state.selectedEdge as EdgeType,
            position,
          });
          dispatch(
            wordsSetData({
              id: state.selectedFieldId,
              words,
              edges,
              vlId: vlId || tf.variableLocation.selectedId,
            })
          );
          dispatch(fetchExtractedData({ id: state.selectedFieldId }));
        }
      } catch (err) {
        dispatch(
          wordsSetError({
            id,
            error: err.message || "Error fetchings words",
            vlId: vlId || tf.variableLocation.selectedId,
          })
        );
      }
    }
  }
};

export const fetchEdgesFromWords = ({
  id,
  edge,
  pageNumber,
  vlId,
}: {
  id: string;
  edge: EdgeType;
  pageNumber?: number;
  vlId?: string;
}) => async (dispatch, getState) => {
  const state: TemplateFieldState = getState().TemplateField;

  if (!state.page.page) {
    return;
  }
  let document: Document | undefined;
  const files = getState().Files.files;
  if (files.length > 0) {
    document = files[0].document;
  }

  if (!document) {
    return;
  }

  const tfs = state.templateFields.filter((tf) => tf.id === id);
  if (tfs.length > 0) {
    const tf = tfs[0];
    if (tf.variableLocation) {
      let vls: VariableLocationEdges[] = [];
      if (vlId) {
        vls = tf.variableLocation.variableLocations.filter(
          (vl) => vl.id === tf.variableLocation?.selectedId
        );
      } else {
        vls = tf.variableLocation.variableLocations;
      }
      for (const vl of vls || []) {
        if (vl[edge].words) {
          let position: XPosition | YPosition = "top";

          if (["top", "bottom"].includes(edge)) {
            position = (vl[edge].words as Words).yPosition as YPosition;
          } else if (["left", "right"].includes(edge)) {
            position = (vl[edge].words as Words).xPosition as XPosition;
          }

          const words = (vl[edge].words as Words).words;
          if (words.length > 0) {
            try {
              dispatch(wordsLoad({ id, vlId: vlId || vl.id }));
              const { edges } = await getEdgesFromWords({
                documentId: document.id,
                pageNumber: pageNumber
                  ? pageNumber
                  : state.page.page.pageNumber,
                words,
                edge,
                position,
                offset: (vl[edge].words as Words).offset || 0,
              });

              dispatch(
                wordsSetData({ id, words, edges, edge, vlId: vlId || vl.id })
              );
            } catch (err) {
              dispatch(
                wordsSetError({
                  id,
                  vlId: vlId || vl.id,
                  error: err.message || "Error fetchings words",
                })
              );
            }
          } else {
            dispatch(
              wordsSetData({ id, words, edges: [], edge, vlId: vlId || vl.id })
            );
          }
        }
      }
    }
  }
};

export const fetchThumbnails = ({ count }: { count: number }) => async (
  dispatch,
  getState
) => {
  const { thumbnails } = getState().TemplateField as TemplateFieldState;
  if (thumbnails.loading) {
    return;
  }
  let document: Document | undefined;
  const { files } = getState().Files;
  if (files.length > 0) {
    document = files[0].document;
  }

  if (!document) {
    return;
  }
  const { offset } = thumbnails;
  if (!document) {
    return;
  }
  dispatch(thumbnailsLoad());
  try {
    const newThumbnails = await getThumbnails(document.id, offset, count);
    dispatch(
      thumbnailsSetData({
        thumbnails: [...thumbnails.thumbnails, ...newThumbnails],
        document,
      })
    );
  } catch (err) {
    dispatch(thumbnailsSetError(err.message || "Error getting thumbnails"));
  }
};

// export const uploadFileFn = ({ file }: { file: File }) => async (
//   dispatch,
//   getState
// ) => {
//   dispatch(fileLoad());
//   try {
//     const document = await uploadFile(file as any, "document");
//     dispatch(setDocument(document));
//     dispatch(deleteAllFields());
//   } catch (err) {
//     dispatch(fileSetError(err.message || "Error uploading file"));
//   }
// };

export const updateFragmentFn = ({
  text,
  id,
  fragmentId,
}: {
  text: string;
  id: string;
  fragmentId: number;
}) => async (dispatch, getState) => {
  dispatch(load({ id }));
  try {
    const updatedFragment = await updateFragment({
      fragmentId,
      editedText: text,
    });
    dispatch(updateFragmentRedux({ fragment: updatedFragment, id }));
  } catch (err) {
    dispatch(setError(err.message || "Error updating fragment"));
  }
};

export const reportBadExtraction = ({
  id,
  fragmentId,
}: {
  id: string;
  fragmentId: number;
}) => async (dispatch, getState) => {
  dispatch(load({ id }));
  try {
    const updatedFragment = await updateFragment({
      fragmentId,
      rating: "bad",
    });
    dispatch(updateFragmentRedux({ fragment: updatedFragment, id }));
  } catch (err) {
    dispatch(setError(err.message || "Error updating fragment"));
  }
};

export const createDefaultFixedLocationTemplateField = ({
  stageWidth,
  stageHeight,
  id,
  page,
  fixedLocation,
}: {
  stageWidth: number;
  stageHeight: number;
  id: string;
  page: number;
  fixedLocation: TemplateFieldV2["fixedLocation"];
}): TemplateFieldV2 => ({
  id,
  checkLabels: {
    checked: "yes",
    unchecked: "no",
  },
  tableConfig: {
    transforms: {
      verticalMergeMasksEnabled: true,
      alignMiddleEnabled: false,
      verticalMergeMasks: null,
      alignMiddle: null,
    },
    columns: [],
    headerRows: 0,
  },
  fieldType: "text",
  fixedLocation,
  variableLocation: getDefaultVariableLocation({
    id: uuidv4(),
    stageWidth,
    stageHeight,
  }),

  locationType: "fixed",
  name: "",
  pages: {
    page,
    include: [],
    exclude: [],
    pagesType: "currentPage",
  },
  toolType: "ocr",
  fragments: {
    error: null,
    fragments: [],
    loading: false,
    selectedFragmentId: null,
  },
});

export const createDefaultRectangle = ({
  id,
  x,
  y,
  templateFieldId,
}: {
  id: string;
  x: number;
  y: number;
  templateFieldId: string;
}): KonvaRectangle => ({
  id,
  draggable: true,
  fill: "yellow",
  height: 0,
  width: 0,
  x,
  y,
  scaleX: 1.0,
  scaleY: 1.0,
  templateFieldId,
});

const getDefaultVariableLocation = ({
  id,
  stageHeight,
  stageWidth,
}: {
  id: string;
  stageHeight: number;
  stageWidth: number;
}): VariableLocation => {
  return {
    selectedId: id,
    variableLocations: [
      {
        forbiddenWords: [],
        forbiddenWordsEnabled: false,
        bottom: {
          words: {
            includeWordsChecked: true,
            loading: false,
            error: null,
            words: [],
            lines: [],
            wordType: "y",
            xPosition: null,
            yPosition: "bottom",
            offset: 0,
          },
          // edgeDetectionType: "words",
          // percentage: {
          //   percentage: 50,
          //   line: {
          //     type: "horizontalFullWidth",
          //     draggable: true,
          //     horizontalLine: {
          //       maxY: null,
          //       minY: null,
          //       scaleX: 1.0,
          //       scaleY: 1.0,
          //       x0: null,
          //       x1: null,
          //       y: stageHeight * 0.5,
          //     },
          //     verticalLine: null,
          //     id: uuidv4(),
          //     stroke: edge2color("bottom"),
          //     strokeWidth: 5,
          //     text: "",
          //   },
          // },
          percentage: null,
        },
        top: {
          words: {
            includeWordsChecked: true,
            loading: false,
            error: null,
            words: [],
            lines: [],
            wordType: "y",
            xPosition: null,
            yPosition: "top",
            offset: 0,
          },
          // edgeDetectionType: "words",
          // percentage: {
          //   percentage: 10,
          //   line: {
          //     type: "horizontalFullWidth",
          //     draggable: true,
          //     horizontalLine: {
          //       maxY: null,
          //       minY: null,
          //       scaleX: 1.0,
          //       scaleY: 1.0,
          //       x0: null,
          //       x1: null,
          //       y: stageHeight * 0.1,
          //     },
          //     verticalLine: null,
          //     id: uuidv4(),
          //     stroke: edge2color("top"),
          //     strokeWidth: 5,
          //     text: "",
          //   },
          // },
          percentage: null,
        },
        left: {
          words: {
            includeWordsChecked: true,
            loading: false,
            error: null,
            words: [],
            lines: [],
            wordType: "x",
            xPosition: "left",
            yPosition: null,
            offset: -2,
          },
          // edgeDetectionType: "words",
          // percentage: {
          //   percentage: 10,
          //   line: {
          //     type: "verticalFullHeight",
          //     draggable: true,
          //     verticalLine: {
          //       maxX: null,
          //       minX: null,
          //       scaleX: 1.0,
          //       scaleY: 1.0,
          //       y0: null,
          //       y1: null,
          //       x: stageWidth * 0.1,
          //     },
          //     horizontalLine: null,
          //     id: uuidv4(),
          //     stroke: edge2color("left"),
          //     strokeWidth: 5,
          //     text: "",
          //   },
          // },
          percentage: null,
        },
        right: {
          words: {
            includeWordsChecked: true,
            loading: false,
            error: null,
            words: [],
            lines: [],
            wordType: "x",
            xPosition: "right",
            yPosition: null,
            offset: 2,
          },
          // edgeDetectionType: "words",
          percentage: null,
          // percentage: {
          //   percentage: 90,
          //   line: {
          //     type: "verticalFullHeight",
          //     draggable: true,
          //     verticalLine: {
          //       maxX: null,
          //       minX: null,
          //       scaleX: 1.0,
          //       scaleY: 1.0,
          //       y0: null,
          //       y1: null,
          //       x: stageWidth * 0.9,
          //     },
          //     horizontalLine: null,
          //     id: uuidv4(),
          //     stroke: edge2color("right"),
          //     strokeWidth: 5,
          //     text: "",
          //   },
          // },
        },
        id,
      },
    ],
  };
};

export const pasteRectangle = () => async (dispatch, getState) => {
  const state = getState().TemplateField;
  if (state.copiedRectangleId) {
    const newId = uuidv4();
    dispatch(duplicateTemplateField({ id: state.copiedRectangleId, newId }));
    dispatch(selectTemplateField({ id: newId }));
  }
};

export const setFragmentsFromJobData = ({
  job,
  pageNumber,
  data,
}: {
  job: Job;
  pageNumber: number;
  data: any;
}) => async (dispatch, getState) => {
  // group fragments by rectangle name
  // for every name create a TF, set vfragments

  dispatch(deleteAllFields());
  if (data.rows.length > 0) {
    const fragments2 = data.rows[0].fragments.filter((f) => !!f.templateId);
    const fragments = groupBy(fragments2, "fieldName");
    let firstId;
    for (const fieldName of Object.keys(fragments)) {
      // const { templateFields } = state;
      // const tfs = templateFields.filter((tf) => tf.name === fieldName);
      // let id;
      // if (tfs.length === 0) {
      const id = uuidv4();
      let toolType: ToolType = "ocr";
      if (fragments[fieldName].length > 0) {
        toolType = fragments[fieldName][0].toolType;
      }
      dispatch(addTemplateFieldWithId({ id, name: fieldName, toolType }));
      // } else {
      //   id = tfs[0].id;
      // }

      if (!firstId) {
        // select first
        firstId = id;
        dispatch(selectTemplateField({ id }));
      }

      dispatch(
        setVFragments({
          id,
          fragments: fragments[fieldName],
        })
      );
    }
  }

  // group fragments by field
  // dispatch(setVFragments());
};

export const moveRectangleWithKeys = ({
  id,
  deltaX,
  deltaY,
}: {
  id: string;
  deltaX: number;
  deltaY: number;
}) => async (dispatch, getState) => {
  const state: TemplateFieldState = getState().TemplateField;
  const tfs = state.templateFields.filter(
    (tf) => tf.id === state.selectedFieldId
  );

  if (tfs.length > 0) {
    const tf = tfs[0];
    if (tf.locationType === "fixed") {
      dispatch(
        moveRectangleFromKey({
          id,
          deltaX,
          deltaY,
        })
      );
    } else if (tf.locationType === "variable") {
      dispatch(
        updateEdgePercentageFromKey({
          id: tf.id,
          deltaX: deltaX / 4,
          deltaY: deltaY / 4,
        })
      );
    }
  }
};

export default slice.reducer;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Col, Row, Modal, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ReduxState } from "../../../redux";
import { resumeJob, savePreferenceServer } from "../../../docuclipper/api";
import { setJobData } from "../../../redux/reducers/JobData";
import { createAlert } from "../../../redux/reducers/Alerts";

// Feature flag for overages
const ENABLE_OVERAGES = true;

export const JobOutOfPages = ({ job }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [numPages, setNumPages] = useState("5000");

  const isContractAdmin = useSelector(
    (state: ReduxState) => state.User.user?.isContractAdmin
  );

  React.useEffect(() => {
    dispatch(
      createAlert({
        timeout: 0,
        type: "error",
        messageWithUpdate: `You've run out of pages`,
        message: "",
      })
    );
  }, []);

  const onResume = () => {
    if (!job) return;
    resumeJob(job.id)
      .then((rsp) => {
        dispatch(setJobData({ job: rsp, progress: null }));
        dispatch(
          createAlert({
            message: "Job resumed successfully",
            timeout: 0,
            type: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(
          createAlert({
            message: "Error resuming job",
            timeout: 0,
            type: "error",
          })
        );
      });
  };

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await savePreferenceServer({
        key: "overageConfig",
        value: JSON.stringify({
          enabled: true,
          numPages: parseInt(numPages, 10),
        }),
      });

      setShowModal(false);
      onResume();
    } catch (error) {
      console.error("Error saving overage configuration:", error);
      dispatch(
        createAlert({
          message: "Error configuring overages",
          timeout: 0,
          type: "error",
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: "50vw" }}>
      <Row className="text-center">
        <Col>
          <div style={{ maxWidth: "20vw" }}>
            <div className="h2 m-4">
              <FontAwesomeIcon icon="times" color="red" />{" "}
              <span className="ml-2">You've run out of pages</span>
            </div>
            <Button variant="light" className="mx-1" onClick={onResume}>
              Resume Job
            </Button>
            <Button
              className="my-2 mx-1"
              variant="primary"
              onClick={() => history.push("/account/upgrade")}
            >
              Upgrade
            </Button>
            {ENABLE_OVERAGES && isContractAdmin && (
              <Button
                className="my-2 mx-1"
                variant="link"
                onClick={() => setShowModal(true)}
              >
                Configure Overages
              </Button>
            )}
          </div>
        </Col>
      </Row>

      {ENABLE_OVERAGES && (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Configure Overages</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group controlId="numPages">
              <Form.Label>Select Overage Package</Form.Label>
              <Form.Control
                as="select"
                value={numPages}
                onChange={(e) => setNumPages(e.target.value)}
                disabled={isLoading}
              >
                <option value="5000">5,000 pages (6 cents/page)</option>
                <option value="2000">2,000 pages (8 cents/page)</option>
                <option value="1000">1,000 pages (16 cents/page)</option>
                <option value="500">500 pages (20 cents/page)</option>
                <option value="250">250 pages (24 cents/page)</option>
              </Form.Control>
            </Form.Group>
            <p>
              When you run out of pages, DocuClipper will automatically add{" "}
              {parseInt(numPages).toLocaleString()} pages for $
              {(
                parseInt(numPages) *
                ({
                  "5000": 0.06,
                  "2000": 0.08,
                  "1000": 0.16,
                  "500": 0.2,
                  "250": 0.24,
                }[numPages] ?? 0.06)
              ).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSave} disabled={isLoading}>
              Enable Overages
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

import React from "react";
import { DocuclipperToken } from "./DocuclipperTypes";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

type Props = {
  component: any;
  isProtected?: boolean;
  permission?: string;
  path: string;
  exact: boolean;
};

type ReduxProps = {
  token: DocuclipperToken;
};

export const PrivateRoute: React.SFC<Props & ReduxProps> = ({
  component: Component,
  token,
  isProtected,
  permission,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      const hasPermission =
        !permission ||
        ((token && token.permissions) || []).includes(permission);
      if ((!!token || !isProtected) && hasPermission) {
        return <Component {...props} />;
      } else {
        return (
          <Redirect
            to={{
              pathname: "/login",
              search: `?next=${encodeURIComponent(
                `${props.location.pathname}${props.location.search}`
              )}`,
            }}
          />
        );
      }
    }}
  />
);
const mapStateToProps = (state) => ({
  token: state.Auth.token,
});

PrivateRoute.defaultProps = {
  isProtected: true,
};
const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "..";
import { getQboTaxCodes, createQboTaxCode } from "../../docuclipper/api";
import { QboTaxCode } from "../../docuclipper/DocuclipperTypes";

const initialState: {
  qboTaxCodes: QboTaxCode[];
  loading: boolean;
  error: string | null;
} = {
  qboTaxCodes: [],
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "QboTaxCode",
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
      state.error = null;
    },
    setData(state, action: PayloadAction<QboTaxCode[]>) {
      state.loading = false;
      state.qboTaxCodes = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    addQboTaxCode(state, action: PayloadAction<QboTaxCode>) {
      state.qboTaxCodes.unshift(action.payload);
    },
  },
});

export const { load, setError, setData, addQboTaxCode } = slice.actions;

export const fetchQboTaxCodes = (integrationId) => async (dispatch, getState) => {
  const state: ReduxState = getState();
  if (state.QboTaxCode.loading) {
    return;
  }
  dispatch(load());
  try {
    const job = state.JobData.job;
    if (job) {
      const QboTaxCodes = await getQboTaxCodes(integrationId);
      dispatch(
        setData(
          QboTaxCodes.map((c) => ({
            name: c.Name,
            value: c.Id,
          }))
        )
      );
    }
  } catch (err) {
    dispatch(setError((err as any).message || "Error getting QboTaxCodes"));
  }
};

export const createAndAddQboTaxCode = (integrationId, taxCodeName) => async (dispatch, getState) => {
  try {
    const taxCode = await createQboTaxCode(integrationId, taxCodeName);
    dispatch(addQboTaxCode({
      name: taxCode.Name,
      value: taxCode.Id,
    }));
  } catch (err) {
    dispatch(setError((err as any).message || "Error creating QboTaxCode"));
  }
};

export default slice.reducer;
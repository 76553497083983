import { getLastUsedTemplates } from "../../docuclipper/api";
import { load, setData, setError } from "./UserRecentTemplate";

export const fetchLastUsedTemplates = () => async (dispatch) => {
  try {
    dispatch(load());
    const templates = await getLastUsedTemplates({ offset: 0, limit: 10 });
    dispatch(setData(templates));
  } catch (err) {
    dispatch(setError(err.message || "Error getting last used templates"));
  }
};

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Job } from "../../../docuclipper/DocuclipperTypes";
import WebSocketsHandler from "../../../docuclipper/ws";
import { ReduxState } from "../../../redux";
import { setJobData } from "../../../redux/reducers/JobData";
import { updateJob } from "../../../redux/reducers/Jobs";
import { openModal } from "../../../redux/reducers/ModalManager";
import { fetchUser } from "../../../redux/reducers/User";
import { createAlert } from "../../../redux/reducers/Alerts";

// {
//   type: 'update-current-job',
//     payload: {
//       progress: 60,
//       job: {}
//     }
// }

type Props = {};

export const JobWSUpdater: React.SFC<Props> = () => {
  const webSocketsHandler = React.useRef(new WebSocketsHandler());

  const reduxJobId = useSelector((state: ReduxState) =>
    state.JobData.job ? state.JobData.job.id : null
  );

  const dispatch = useDispatch();

  const handleJobStatusUpdate = (data: {
    messageType: any;
    id: number;
    progress: number;
    status: Job["status"];
    output: string;
    isGeneric: boolean;
    isBankMode: boolean;
    bankModeIfReconciled: boolean;
    isPartial: boolean;
    name: string;
  }) => {
    // console.log(`ws update => ${JSON.stringify(data)}`);
    const {
      id: jobId,
      progress,
      status,
      output,
      isGeneric,
      isPartial,
      name,
      isBankMode,
      bankModeIfReconciled,
    } = data;
    if (status === "OutOfCredits") {
      // dispatch(openModal("outOfPages", { outOfCredits: true }));
      dispatch(
        createAlert({
          timeout: 0,
          type: "error",
          messageWithUpdate: `You've run out of pages`,
          message: "",
        })
      );
    }
    if (["Succeeded", "Failed", "Canceled", "OutOfCredits"].includes(status)) {
      dispatch(fetchUser());
    }

    const newJob = {
      id: jobId,
      status,
      output,
      isGeneric,
      isPartial,
      name,
      isBankMode,
      bankModeIfReconciled,
    };

    dispatch(
      setJobData({
        job: newJob,
        progress,
        onlyUpdateIfJobIdMatches: true,
      })
    );

    dispatch(
      updateJob({
        job: newJob,
        progress,
      })
    );
  };

  React.useEffect(() => {
    webSocketsHandler.current.registerHandler(
      "JobStatusUpdate",
      handleJobStatusUpdate
    );

    return () => {
      webSocketsHandler.current.disconnect();
    };
  }, []);

  // console.log("JobWSUpdater re-render");
  return null;
};
export default JobWSUpdater;

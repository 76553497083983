import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../../../redux";
import { setT3 } from "../../../../../redux/reducers/GenericOption";

export const GenericOptions: React.FC = () => {
  const dispatch = useDispatch();

  const { T3 } = useSelector((state: ReduxState) => state.GenericOptions);

  return (
    <Row>
      <Col md="8">
        <input
          style={{ width: "100%" }}
          type="range"
          id="T3"
          name="T3"
          min="1"
          max="20"
          value={T3}
          onChange={(e) => dispatch(setT3(parseInt(e.target.value, 10)))}
        />
      </Col>
      <Col md="4">
        <label className="ml-2">{T3}</label>
      </Col>
    </Row>
  );
};
export default GenericOptions;

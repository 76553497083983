import React from "react";
import { Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { ReduxState } from "src/redux"; // Adjust the import path as necessary
import { fetchTotals, setSelectedAccount } from "src/redux/reducers/Reconciler";

const AllAccountsSelector: React.FC<any> = ({
  onAccountChange,
}: {
  onAccountChange: any;
}) => {
  const dispatch = useDispatch();

  // Remove props and use useSelector to get accounts and selected account
  const { allAccounts, selectedAccount, totalsByDocumentId } = useSelector(
    (state: ReduxState) => state.Reconciler
  );

  React.useEffect(() => {
    if (!selectedAccount && allAccounts.length > 0) {
      dispatch(setSelectedAccount(allAccounts[0]));
    }
  }, [selectedAccount, allAccounts]);

  React.useEffect(() => {
    if (Object.keys(totalsByDocumentId || {}).length === 0) {
      dispatch(fetchTotals());
    }
  }, [totalsByDocumentId]);

  // Function to set selected account

  return (
    <div className="d-flex align-items-center gap-2">
      <Form.Label className="mb-0 ml-3 mr-1">Account</Form.Label>

      {allAccounts.length > 0 ? (
        <Form.Control
          as="select"
          value={selectedAccount || ""}
          onChange={(e) => {
            if (onAccountChange) {
              onAccountChange(e?.target?.value);
            }
            dispatch(setSelectedAccount(e?.target?.value));
          }}
          style={{ width: "auto" }}
          className="px-3 ml-2"
        >
          {allAccounts.map((account) => (
            <option key={account} value={account}>
              {account}
            </option>
          ))}
        </Form.Control>
      ) : (
        <div>No accounts available</div>
      )}
    </div>
  );
};

export default AllAccountsSelector;

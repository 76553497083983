import React from "react";
import { Job } from "../../../docuclipper/DocuclipperTypes";
import ClipLoader from "react-spinners/ClipLoader";
import { Badge, ProgressBar, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../redux";

export const JobInProgress = ({ job }: { job: Job }) => {
  let text = "";
  if (job.type === "ExtractData") {
    text = "Converting documents";
  } else if (job.type === "WritePdf") {
    text = "Populating documents";
  } else if (job.type === "EditDocument") {
    text = "Editing documents";
  }

  const { progress } = useSelector((state: ReduxState) => state.JobData);
  const progress2 = ((progress || 0) * 100).toFixed(0);

  return (
    <div>
      <Row className="align-items-center">
        <div className="h3">{text}</div>
        <div className="ml-2">
          <ClipLoader />
        </div>
      </Row>
      <Row>
        {progress === null && (
          <Badge
            variant="primary"
            style={{ width: "15vw", fontSize: "1.2rem" }}
          >
            In Progress
          </Badge>
        )}
        {progress !== null && (
          <ProgressBar
            variant="primary"
            animated={true}
            style={{ width: "15vw", fontSize: "1.2rem", height: "30px" }}
            now={parseFloat(progress2)}
            label={`${progress2}%`}
          />
        )}
      </Row>
    </div>
  );
};

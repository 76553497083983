// src/Pages/views/Invoices/InvoicePage.tsx
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Col,
  Container,
  FormGroup,
  Row,
  Badge,
  Button,
  Modal,
} from "react-bootstrap";
import Select from "react-select";
import Split from "react-split";
import { groupBy } from "lodash";
import {
  fetchInvoiceTls,
  setDocumentId,
  setTransactionLineRowNumber,
} from "src/redux/reducers/InvoiceManager";
import PDFViewer from "../PdfViewer";
import InvoiceContainer from "./InvoiceContainer";
import useInvoicePDF from "./useInvoicePDF";
import { Formik } from "formik";
import { Invoice2 } from "src/docuclipper/DocuclipperTypes";
import SelectWithErrorBadge from "../DataArchive/TransactionManager/SelectWithErrorBadge";
import { ReduxState } from "src/redux";
import InvoiceApprovalRules from "./InvoiceApprovalRules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InvoiceMatchManager } from "./InvoiceMatching/InvoiceMatchManager";
import SelectCreatable from "react-select/creatable";
import { tagItem, untagItem, getTags } from "src/docuclipper/api";
import { TagSelector } from "./TagSelector";

const InvoicePage: React.FC = () => {
  const dispatch = useDispatch();
  const {
    documentId,
    transactionLineRowNumber,
    transactionLines,
    documents,
    loading,
    error,
    validationErrors,
  } = useSelector((state: ReduxState) => state.InvoiceManager);

  const [showApprovalRules, setShowApprovalRules] = useState(false);
  const [showMatchModal, setShowMatchModal] = useState(false);

  useEffect(() => {
    dispatch(fetchInvoiceTls());
  }, []);

  const tlsByDocId = groupBy(transactionLines, "documentId");

  const rowOptions =
    tlsByDocId?.[documentId || ""]
      ?.sort((a, b) => a.pageNumber - b.pageNumber)
      ?.map((tl, index) => ({
        label: `${index + 1}`,
        value: index,
        hasWarning:
          validationErrors[tl.id || ""] && !validationErrors[tl.id || ""].valid
            ? true
            : false,
      })) || [];

  const transactionLine = documentId
    ? transactionLines.find(
        (tl) =>
          tl.documentId === documentId &&
          tl.rowNumber === transactionLineRowNumber
      )
    : null;

  const { pdfURL, pageNumber } = useInvoicePDF({
    selectedDocumentId: documentId,
    transactionLine,
  });

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  const rowSelectValue = rowOptions.find(
    (x) => x.value === transactionLineRowNumber
  );

  const documentOptions = documents
    .map((doc) => ({
      label: doc.originalname,
      value: doc.id,
      hasWarning: transactionLines.some(
        (tl) =>
          tl.documentId.toString() === doc.id.toString() &&
          validationErrors[tl.id || ""] &&
          !validationErrors[tl.id || ""].valid
      ),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));

  const documentSelectValue = documentOptions.find(
    (x) => x.value?.toString() === documentId
  );

  const handleDocumentChange = (option) => {
    dispatch(setDocumentId(option.value));
  };

  const handleRowChange = (option) => {
    dispatch(setTransactionLineRowNumber(option.value));
  };

  const handleApprovalRulesClose = () => setShowApprovalRules(false);
  const handleApprovalRulesShow = () => setShowApprovalRules(true);

  return (
    <Container fluid>
      <Row className="mb-3">
        <Col className="d-flex justify-content-end">
          <Button
            variant="link"
            className="mr-1"
            onClick={handleApprovalRulesShow}
          >
            <FontAwesomeIcon icon="check" />
            <span className="ml-1">Approval Rules</span>
          </Button>
          {/* <Button variant="link" onClick={() => setShowMatchModal(true)}>
            Match Rules
          </Button> */}
        </Col>
      </Row>

      <Split className="split" sizes={[40, 60]}>
        <div className="d-flex flex-column">
          <Row className="mb-2 mr-1 invoice-tour-select-doc">
            <Col md="8">
              <FormGroup>
                <strong>Document</strong>
                <SelectWithErrorBadge
                  value={documentSelectValue || null}
                  onChange={handleDocumentChange}
                  options={documentOptions}
                />
              </FormGroup>
            </Col>
            {/* <Col md="4">
              <TagSelector
                entityId={transactionLine?.id as any}
                entityType="transactionLine"
              />
            </Col> */}
            <Col md="4">
              {documentId && (
                <FormGroup>
                  <strong>Invoice</strong>
                  <SelectWithErrorBadge
                    value={rowSelectValue || null}
                    onChange={handleRowChange}
                    options={rowOptions}
                  />
                </FormGroup>
              )}
            </Col>
          </Row>
          <Row className="mr-1">
            <Col md="12">
              {pdfURL && (
                <PDFViewer key={`${documentId}-${pageNumber}`} url={pdfURL} />
              )}
            </Col>
          </Row>
        </div>

        <Row className="ml-1">
          <Col>
            <InvoiceContainer />
          </Col>
        </Row>
      </Split>

      <Modal
        show={showApprovalRules}
        onHide={handleApprovalRulesClose}
        size="xl"
      >
        <Modal.Header
          closeButton
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          placeholder=""
        >
          <Modal.Title>Invoice Approval Rules</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InvoiceApprovalRules />
        </Modal.Body>
      </Modal>

      <Modal
        show={showMatchModal}
        onHide={() => setShowMatchModal(false)}
        size="lg"
      >
        <Modal.Header
          closeButton
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          placeholder=""
        >
          <Modal.Title>Match Rules</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InvoiceMatchManager
            transactionLineId={Number(transactionLine?.id)}
            onMatchCreated={() => {
              setShowMatchModal(false);
            }}
          />
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default InvoicePage;

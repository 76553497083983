import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getJobBadRatings, getUserRatings, updateSegment } from "src/docuclipper/api";

const initialState: {
  jobRatings: any[],
  userRatings: any[]
} = {
  jobRatings: [],
  userRatings: []
};

const slice = createSlice({
  name: "JobRating",
  initialState,
  reducers: {
    setJobRatings(state, action: PayloadAction<any[]>) {
      state.jobRatings = action.payload
    },
    setUserRatings(state, action: PayloadAction<any[]>) {
      state.userRatings = action.payload
    },
  },
});

export const { setJobRatings, setUserRatings } = slice.actions;

export const fetchJobRatings = (jobId) =>
  async (dispatch, getState) => {
    try {
      const jobRatings = await getJobBadRatings(jobId);
      // console.log({ segmentUpdated });
      dispatch(setJobRatings(jobRatings))
    } catch (err) {
      // console.error(err);
    }
  };

export const fetchUserRatings = () =>
  async (dispatch, getState) => {
    try {
      const userRatings = await getUserRatings();
      // console.log({ segmentUpdated });
      dispatch(setUserRatings(userRatings))
    } catch (err) {
      // console.error(err);
    }
  };

export default slice.reducer;

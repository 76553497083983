// src/components/ColumnSelector.tsx
import React, { useEffect, useState, useRef } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";

import { ReduxState } from "src/redux";
import {
  loadColumns,
  setColumns,
  setColumnWidth,
  toggleColumnVisibility,
} from "src/redux/reducers/AgGridColumnConfig";

interface Column {
  field: string;
  headerName: string;
  hide?: boolean;
  configurable?: boolean;
  width?: number;
  // Add other properties as needed
}

const ColumnSelector = ({ tableId }) => {
  const dispatch = useDispatch();
  const columns = useSelector(
    (state: ReduxState) => state.AgGridColumnConfig[tableId] || []
  );

  useEffect(() => {
    // Load columns from local storage when the component mounts
    dispatch(loadColumns(tableId));
  }, [tableId, dispatch]);

  const handleColumnToggle = (field: string) => {
    dispatch(toggleColumnVisibility({ tableId, field }));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const updatedColumns = [...columns]; // Create a copy of the columns array
    const [movedColumn] = updatedColumns.splice(result.source.index, 1); // Remove the moved column
    updatedColumns.splice(result.destination.index, 0, movedColumn); // Insert it at the new index

    // Update the index for each column if necessary
    const columnsWithUpdatedIndex = updatedColumns.map((col, index) => ({
      ...col,
      index, // Assign the new index
    }));

    dispatch(setColumns({ tableId, columns: columnsWithUpdatedIndex as any }));
    // console.log(
    //   `Updated column order for ${tableId}:`,
    //   columnsWithUpdatedIndex
    // );
  };

  // Filter to only include configurable columns
  return (
    <div>
      <p>
        Use the checkboxes to show/hide columns. Drag and drop to reorder them.
      </p>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-columns">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              {columns.map((col, index) => (
                <Draggable
                  key={col.field}
                  draggableId={col.field}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <input
                        type="checkbox"
                        checked={!col.hide}
                        onChange={() => handleColumnToggle(col.field)}
                        disabled={!col.configurable}
                        id={`checkbox-${col.field}`}
                      />
                      <label
                        htmlFor={`checkbox-${col.field}`}
                        style={{ marginLeft: "8px" }}
                      >
                        {col.headerName || col.field}
                      </label>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default ColumnSelector;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  categorizeJobTransactions,
  categorizeJobTransactionsAsync,
  getAllTags,
  getAsyncTask,
  getCategorizedTransactions,
  getJobTransactions,
  getTransactionCategories,
  loadPreferenceServer,
  payeeCleanupAsync,
} from "../../docuclipper/api";
import { ReduxState } from "..";

import { fetchTls, setTls } from "./TransactionManager";
import { setCategories } from "./Categories";
import { createAlert } from "./Alerts";
import { pollForCompletion } from "./AsyncUtils";

export function randomColor(i) {
  const colors = ["#7982B9", "#A5C1DC", "#E9F6FA"];
  return colors[i % colors.length];
}
const initialState: {
  loading: boolean;
  asyncJobId: string | null;
  payeeCleanupConfig: any;
  payeeCleanupConfigLoading: boolean;
} = {
  loading: false,
  asyncJobId: null,
  payeeCleanupConfig: { "removeDates": true, "removeCurrencyValues": true, "removePhoneNumbers": true, "removeTwoLetterStates": true, "removeHashNumbers": true, "replacePunctuation": true, "removeSpecialCharacters": true, "removeNumbersFromWords": true, "removeExtraSpaces": true, "removeShortNumbers": false, "shortNumberThreshold": 3, "wordsOrPhrasesToRemove": [], "regexesToRemove": [] },
  payeeCleanupConfigLoading: false
};

const slice = createSlice({
  name: "PayeeCleanupAsync",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setPayeeCleanupConfig(state, action: PayloadAction<any>) {
      state.payeeCleanupConfig = action.payload;
    },
    setPayeeCleanupConfigLoading(state, action: PayloadAction<boolean>) {
      state.payeeCleanupConfigLoading = action.payload;
    },
    setAsyncJobId(state, action: PayloadAction<string>) {
      state.asyncJobId = action.payload;
    },
  },
});

export const { setAsyncJobId, setLoading, setPayeeCleanupConfig, setPayeeCleanupConfigLoading } = slice.actions;

export const fetchCleanupPayeeConfig = () => async (dispatch, getState) => {
  dispatch(setPayeeCleanupConfigLoading(true));

  loadPreferenceServer({ key: 'payeeCleanupConfig' })
    .then(pref => {
      dispatch(setPayeeCleanupConfig(JSON.parse(pref.value)));
    })
    .catch(err => dispatch(createAlert({
      message: 'Error loading payee cleanup config',
      timeout: 0,
      type: 'error'
    }))).finally(() => {
      dispatch(setPayeeCleanupConfigLoading(false))
    })
}
export const payeeCleanupActionAsync = (config: any) => async (dispatch, getState) => {
  const state: ReduxState = getState();
  if (state.PayeeCleanupAsync.loading) {
    return;
  }
  const { job } = state.JobData;
  if (!job) {
    return;
  }
  const jobId = job.id;

  try {
    dispatch(setLoading(true));
    const { asyncJobId } = await payeeCleanupAsync(
      jobId,
      config
    );

    dispatch(setAsyncJobId(asyncJobId));

    const onSuccess = async () => {
      dispatch(setLoading(false));
      const { transactions, documents } = await getJobTransactions(
        job.id
      );
      dispatch(setTls({ transactions, resetPages: false }));

      dispatch(
        createAlert({
          message: "Payee cleanup done successfully",
          timeout: 0,
          type: "success",
        })
      );
    };
    const onFail = async () => {
      dispatch(setLoading(false));
      dispatch(
        createAlert({
          message: "Error doing payee cleanup",
          timeout: 0,
          type: "error",
        })
      );
    };
    pollForCompletion(dispatch, asyncJobId, jobId, onSuccess, onFail);

    //   dispatch(setTls({ transactions, resetPages: false }));
    //   dispatch(
    //     setCategories(
    //       categories
    //         .sort((a, b) => a.name.localeCompare(b.name))
    //         .map((c, i) => ({
    //           ...c,
    //           color: randomColor(i),
    //         }))
    //     )
    //   );
  } catch (err) {
    dispatch(setLoading(false));
  }
};

export default slice.reducer;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Row, Col, Badge, Button } from "react-bootstrap";
import { QboAccountCategory, QboItem } from "src/docuclipper/DocuclipperTypes";

type Props = {
  rules: Array<{
    ifConditions: Array<{ field: string, operator: string, value: string | { keywords: string[] } }>,
    thenSet: Array<{ categoryAutomated: string, value: string }>
  }>,
  categories: QboItem[] | QboAccountCategory[],
  onEdit: (index: number) => void,
  onRemove: (index: number) => void,
};

const TagRegexesInvoiceViewOnly: React.FC<Props> = ({ rules, categories, onEdit, onRemove }) => {
  const categoriesMap = categories.reduce((acc, category) => {
    acc[category.value] = category;
    return acc;
  }, {});

  return (
    <div>
      {!rules.length && (
        <div className="text-center">
          <p>No rules created yet.</p>
        </div>
      )}
      {rules.map((rule, ruleIndex) => (
        <Card key={ruleIndex} className="mb-4">
          <Card.Body>
            <Row>
              <Col>
                <h5>Rule {ruleIndex + 1}</h5>
              </Col>
              <Col className="text-right">
                <Button
                  variant="link"
                  onClick={() => onEdit(ruleIndex)}
                >
                  <FontAwesomeIcon icon="edit" />
                </Button>
                <Button
                  variant="link"
                  onClick={() => onRemove(ruleIndex)}
                >
                  <FontAwesomeIcon icon="trash" />
                </Button>
              </Col>
            </Row>
            <h6>If Conditions:</h6>
            {rule.ifConditions.map((condition, index) => (
              <Row key={index} className="mb-2">
                <Col>
                  <Badge variant="light" className="mr-2">{condition.field}</Badge>
                  <Badge variant="primary" className="mr-2">{condition.operator === "containsAny" ? "Contains" : "Not Contains"}</Badge>
                  <Badge variant="light">
                    {typeof condition.value === 'object' && 'keywords' in condition.value
                      ? condition.value.keywords.join(", ")
                      : condition.value}
                  </Badge>
                </Col>
              </Row>
            ))}
            <h6>Then Set:</h6>
            {rule.thenSet.map((thenSet, index) => (
              <Row key={index} className="mb-2">
                <Col>
                  <Badge variant="primary" className="mr-2">{thenSet.categoryAutomated}</Badge>
                  <Badge variant="light">
                    {categoriesMap[thenSet.value] ? categoriesMap[thenSet.value].name : thenSet.value}
                  </Badge>
                </Col>
              </Row>
            ))}
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

export default TagRegexesInvoiceViewOnly;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getAllTags } from "../../docuclipper/api";
import { ReduxState } from "..";

const initialState: {
  tags: string[];
  selectedTags: string[];
  loading: boolean;
  error: string | null;
} = {
  tags: [],
  selectedTags: [],
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "JobTags",
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
      state.error = null;
    },
    setTags(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.tags = action.payload;
    },
    setSelectedTags(state, action: PayloadAction<string[]>) {
      state.loading = false;
      state.selectedTags = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { load, setError, setTags, setSelectedTags } = slice.actions;

export const fetchTags =
  (jobId?) => async (dispatch, getState) => {
    const state: ReduxState = getState();
    if (state.JobTags.loading) {
      return;
    }
    dispatch(load());
    dispatch(setTags([]));

    try {
      const { tags, selectedTags } = await getAllTags(jobId);
      dispatch(setSelectedTags(selectedTags.map(x => x.Tag.name)));
      dispatch(setTags(tags.map(x => x.name)));
    } catch (err) {
      dispatch(setError((err as any).message || "Error getting tags"));
    }
  };

export default slice.reducer;

import { User } from "../../docuclipper/DocuclipperTypes";

const initialState: {
  user: User | null;
} = {
  user: null
};

export const SET_USER = "set-user";
export const CLEAR_USER = "clear-user";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.user
        }
      };
    case CLEAR_USER:
      return {
        ...state,
        user: null
      };

    default:
      return state;
  }
}
export type SetUserFn = (user: User) => any;
export const setUser = (user: User) => ({
  type: SET_USER,
  payload: {
    user
  }
});

export type ClearUserFn = () => any;
export const clearUser = () => ({
  type: CLEAR_USER,
  payload: {}
});

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../../redux";
import {
  setDownloadModalOpen,
} from "../../../../redux/reducers/JobExport";
import DownloadSection from "./DownloadOptions/DownloadSection";

export const DownloadModal = () => {
  const dispatch = useDispatch();
  const { downloadModalOpen } = useSelector(
    (state: ReduxState) => state.JobExport
  );
  const toggle = () => dispatch(setDownloadModalOpen(!downloadModalOpen));

  return (
    <>
      <Button className="btn--download-data" variant="primary" onClick={toggle}>
        <FontAwesomeIcon icon="download" />
        <span className="ml-1">Download Data</span>
      </Button>

      <Modal size="lg" show={downloadModalOpen} onHide={toggle}>
        <Modal.Header closeButton>
          <Modal.Title>Download Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <DownloadSection />
        </Modal.Body>
      </Modal>
    </>
  );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "..";

import { createAlert } from "./Alerts";
import { categorizeAction } from "./Categories";
import { getJobMatchingTransactions } from "../../docuclipper/api";
import { TransactionLine } from "../../docuclipper/DocuclipperTypes";

type MatchedTransaction = {
  match: {
    id: string;
    userId: string;
    jobId: string;
    sourceTransactionId: string;
    destinationTransactionId: string;
    status: "pending" | "approved" | "rejected";
  };
  transactions: TransactionLine[];
};
const initialState: {
  loading: boolean;
  matches: MatchedTransaction[];
} = {
  loading: false,
  matches: [],
};

const slice = createSlice({
  name: "MatchedTransactions",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setMatched(state, action: PayloadAction<MatchedTransaction[]>) {
      state.matches = action.payload;
    },
  },
});

export const { setLoading, setMatched } = slice.actions;

export const fetchMatchedTransactions = () => async (dispatch, getState) => {
  const state: ReduxState = getState();
  if (state.MatchedTransactions.loading) {
    return;
  }

  if (!state.JobData.job) {
    return;
  }
  try {
    dispatch(setLoading(true));
    const rsp = await getJobMatchingTransactions(state.JobData.job.id);
    dispatch(setMatched(rsp.transactionMatchs));
    dispatch(setLoading(false));
  } catch (err) {
    // dispatch(setError((err as any).message || "Error getting tags"));
    dispatch(setLoading(false));
  }
};

export default slice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { StripeCustomer } from "../../docuclipper/DocuclipperTypes";
import { getStripeCustomer } from "../../docuclipper/api";

const initialState: {
  customer: StripeCustomer | null;
  loading: boolean;
  error: string | null;
} = {
  customer: null,
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "StripeCustomer",
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
      state.error = null;
    },
    setData(state, action: PayloadAction<StripeCustomer>) {
      state.loading = false;
      state.customer = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const { load, setError, setData } = slice.actions;

export const fetchCustomer = () => async (dispatch) => {
  dispatch(load());
  try {
    const customer = await getStripeCustomer();
    dispatch(setData(customer));
  } catch (err) {
    dispatch(setError(err.message || "Error getting plans"));
  }
};

export default slice.reducer;

import React, { useEffect, useState } from "react";
import { Row, Form, Alert, Col, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../../../redux";
import { useHasQbo } from "../../../../../utils/utils";
import { LoadingTrackedButton } from "../../../../Docuclipper/TrackedButton";
import DateFormatOptions from "./DateFormatOptions";
import { downloadSpreadsheetHelper } from "./utils";
import { groupBy } from "lodash";
import CsvColumnNames from "./CsvColumnNames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertExternalFiltersToTableFilterConfig } from "src/utils/filters";

type Props = {
  cta?: string;
};

interface PreviewTable {
  columns: string[];
  rows: (string | number)[][]; // Array of arrays of data that can be strings or numbers
}

export const CsvOptions: React.FC<Props> = ({ cta }) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [separateFilesForAccounts, setSeparateFilesForAccounts] =
    useState(true);
  const [splitMultilineDescription, setSplitMultilineDescription] =
    useState(false);
  const [fileType, setFileType] = useState("CSV");
  const [previewTable, setPreviewTable] = useState<PreviewTable>({
    columns: [],
    rows: [],
  });

  const { selectedExportType, options } = useSelector(
    (state: ReduxState) => state.DownloadOptions
  );

  const { job } = useSelector((state: ReduxState) => state.JobData);
  const hasQbo = useHasQbo();
  const { downloadOptions } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );
  const { T, T2, T3, T4 } = useSelector(
    (state: ReduxState) => state.GenericOptions
  );
  const { externalFilterSortBy, externalFilterSortDirection, externalFilters } =
    useSelector((state: ReduxState) => state.ExternalFilter);

  const { allAccounts } = useSelector((state: ReduxState) => state.Reconciler);
  // const allAccounts = Object.keys(groupBy(tls.tls || [], "account"));

  const { selectedDocumentIds, selectedFieldNames } = downloadOptions;

  const onExport = ({ preview = false }) => {
    setLoading(true);
    return downloadSpreadsheetHelper(
      job,
      "FieldsToExcel1",
      selectedExportType,
      {
        dateFormat: options[`docuclipper.prefs.dateFormat`] || "",
        T,
        T2,
        T3,
        T4,
        separateFilesForAccounts,
        fileType,
        splitMultilineDescription,
      },
      selectedDocumentIds,
      selectedFieldNames,
      [],
      convertExternalFiltersToTableFilterConfig(externalFilters),
      externalFilterSortBy,
      externalFilterSortDirection,
      fileType as "CSV" | "XLSX",
      preview
    )
      .then((data) => {
        if (preview) {
          setPreviewTable({ columns: data.columns, rows: data.rows });
        }
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  if (fileType === "XLSX") {
    cta = cta?.replace("CSV", "XLSX");
  }

  useEffect(() => {
    // console.log({ selectedExportType });
    onExport({
      preview: true,
    });
  }, [selectedExportType]);

  return (
    <div>
      {(selectedExportType === "csv" ||
        selectedExportType?.startsWith("custom-")) && (
        <Row>
          <Col className="my-2">
            <strong>File Type</strong>
            <div className="d-flex">
              <Form.Check
                value="CSV"
                checked={fileType === "CSV"}
                onChange={(e) => setFileType(e.currentTarget.value)}
                type="radio"
                label="CSV"
              />
              <Form.Check
                className="ml-2"
                value="XLSX"
                checked={fileType === "XLSX"}
                onChange={(e) => setFileType(e.currentTarget.value)}
                type="radio"
                label="XLSX"
              />
            </div>
          </Col>
          <Col className="my-2">
            {hasQbo &&
              (selectedExportType === "csv" ||
                selectedExportType?.startsWith("custom-")) && (
                <div>
                  <div className="font-weight-bold">Date Format</div>
                  <DateFormatOptions />
                </div>
              )}
          </Col>
        </Row>
      )}
      {([
        "csv",
        "sage-csv",
        "xero-csv",
        "sage-cloud-csv",
        "myob",
        "relate",
        "netsuite",
      ].includes(selectedExportType) ||
        selectedExportType?.startsWith("custom-")) && (
        <CsvColumnNames selectedExportType={selectedExportType} />
      )}
      {(selectedExportType === "csv" ||
        selectedExportType?.startsWith("custom-")) && (
        <>
          <strong>Advanced Options</strong>
          <Form.Group className="mx-1" controlId="splitMultilineDescription">
            <Form.Check
              checked={splitMultilineDescription}
              onChange={(e) => setSplitMultilineDescription(e.target.checked)}
              type="checkbox"
              label="Split multiline descriptions into separate columns"
            />
          </Form.Group>
        </>
      )}

      {(selectedExportType === "csv" ||
        selectedExportType?.startsWith("custom-")) &&
        allAccounts.length > 1 && (
          <>
            {separateFilesForAccounts && (
              <Alert variant="danger" className="mt-2">
                <>
                  <span className="mr-2">
                    {`DocuClipper has detected multiple accounts and will download
                    a zip file containing separate files for each account`}
                  </span>
                  <a
                    href="https://www.docuclipper.com/docs/docuclipper-exports-data-in-zip-file/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FontAwesomeIcon icon="question-circle" />
                    <span className="ml-1">See instructions</span>
                    <FontAwesomeIcon
                      className="ml-2"
                      icon="external-link-alt"
                    />
                  </a>
                </>
              </Alert>
            )}
            <Form.Group className="mx-1 my-3" controlId="oneFilePerAccount">
              <Form.Check
                checked={separateFilesForAccounts}
                onChange={(e) => setSeparateFilesForAccounts(e.target.checked)}
                type="checkbox"
                label="Download separate files for each account"
              />
            </Form.Group>
          </>
        )}

      {selectedExportType !== "qif" &&
        previewTable.columns?.length > 0 &&
        previewTable.rows?.length > 0 && (
          <>
            <strong>Preview</strong>
            {!loading ? (
              <Row>
                <Col>
                  <Table striped bordered hover className="m-0">
                    <thead>
                      <tr>
                        {previewTable.columns.map((column, idx) => (
                          <th key={idx}>{column}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {previewTable.rows.map((row, idx) => (
                        <tr key={idx}>
                          {row.map((cell, cellIdx) => (
                            <td key={cellIdx}>{cell}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            ) : (
              <div>Loading Preview...</div>
            )}
          </>
        )}

      <Row className="justify-content-end mx-1 mt-4">
        <LoadingTrackedButton
          isLoading={loading}
          className={`btn-save-${selectedExportType} m-2`}
          // disabled={anyFileUploading}
          variant="primary"
          onClick={() => onExport({ preview: false })}
        >
          {`${cta}`}
        </LoadingTrackedButton>
      </Row>
    </div>
  );
};
CsvOptions.defaultProps = {
  cta: "Download",
};
export default CsvOptions;

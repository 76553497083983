import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { debugJob, redoJob, redoJobInBankMode } from "../../../docuclipper/api";
import { createAlert } from "../../../redux/reducers/Alerts";
import { setJobData } from "../../../redux/reducers/JobData";
import { useIsAdmin } from "../../../utils/auth";

export function DebugJobButton({ job }) {
  const { isAdmin, isSuperLogin } = useIsAdmin();
  const dispatch = useDispatch();
  const history = useHistory();
  if (!isAdmin && !isSuperLogin) {
    return null;
  }

  const onJobRedo = () => {
    if (!job) {
      return;
    }
    debugJob(job.id)
      .then((jobRsp) => {
        dispatch(setJobData({ job: jobRsp, progress: null }));
        dispatch(
          createAlert({
            message: "Job debugged successfully",
            timeout: 0,
            type: "success",
          })
        );
        history.push(`/jobData?jobId=${jobRsp.id}`);
      })
      .catch((err) => {
        dispatch(
          createAlert({
            message: "Error debugging job",
            timeout: 0,
            type: "error",
          })
        );
      });
  };

  return (
    <>
      <Button variant="link" onClick={onJobRedo}>
        <FontAwesomeIcon icon="undo" />
        <span className="ml-1">Debug job</span>
      </Button>
    </>
  );
}

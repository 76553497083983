import React from "react";
import { Alert, Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { isNullOrUndefined } from "util";
import { talkToSales } from "../../../docuclipper/api";
import {
  StripePlan,
  StripeSubscription,
} from "../../../docuclipper/DocuclipperTypes";
import { logErrorToGA } from "../../../docuclipper/Logger";
import { ReduxState } from "../../../redux";
import { createAlert, CreateAlertFn } from "../../../redux/reducers/Alerts";
import { closeModal } from "../../../redux/reducers/ModalManager";
import { SwitchPlans } from "../../Pages/Account/ChangePlan/SwitchPlans";
import { SelectPlanAfterTrial } from "../../Pages/Account/SelectPlanAfterTrial/SelectPlanAfterTrial";
import { WithAccountAndPlans } from "../../Pages/Account/utils";
import TrackedButton from "../TrackedButton";

type Props = {
  open: boolean;
  data: { [k: string]: any };
  closeCreditsModal: () => any;
  plans: StripePlan[];
  subscription: StripeSubscription;
  createAlert: CreateAlertFn;
};

export const SubscriptionModalUnconnected: React.FC<
  Props & RouteComponentProps
> = ({
  open,
  data,
  closeCreditsModal,
  createAlert,
  plans,
  subscription,
  history,
}) => {
  // console.log({ data, plans, subscription });
  const toggle = () => closeCreditsModal();

  const onKeyPress = (e) => {
    if (e.key === "Escape") {
      e.preventDefault();
      toggle();
    }
  };

  const trialAlert =
    subscription && subscription.status === "trialing"
      ? `. (The trial is limited to 20 pages)`
      : "";
  return (
    <>
      <Modal
        size="xl"
        onHide={toggle}
        keyboard={true}
        onKeyPress={onKeyPress}
        show={open}
      >
        <Modal.Header closeButton={true}>
          <div className="page-title-icon">
            <i className="pe-7s-piggy" />
            <span className="ml-2">Choose a plan</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          {/* <Elements stripe={stripePromise}>
            <BuyCredits outOfCredits={outOfCredits} />
          </Elements> */}
          {data.outOfCredits &&
            (isNullOrUndefined(data.neededCredits) ||
              isNullOrUndefined(data.totalCredits)) && (
              // <Container style={{ marginLeft: "auto", marginRight: "auto" }}>
              <Alert className="my-2" variant="danger">
                You've run out of pages
              </Alert>
              // </Container>
            )}
          {data.outOfCredits &&
            !isNullOrUndefined(data.neededCredits) &&
            !isNullOrUndefined(data.totalCredits) && (
              <Alert className="my-2" variant="danger">
                {`The job has `}
                <strong>{`${data.neededCredits}`}</strong> pages but you only
                have <strong>{`${data.totalCredits}`}</strong>
                {` pages left${trialAlert}`}
              </Alert>
            )}

          {subscription && subscription.status === "trialing" && (
            <div className="my-4">
              <SelectPlanAfterTrial
                redirectToAccountOnConfirmed={false}
                onConfirmed={() => closeCreditsModal()}
                plans={plans}
                subscription={subscription}
              />
            </div>
          )}
          {subscription && subscription.status === "canceled" && (
            <div className="my-4">
              <SwitchPlans
                redirectToAccountOnConfirmed={false}
                onConfirmed={() => closeCreditsModal()}
                plans={plans}
                subscription={subscription}
                createAlert={createAlert}
              />
            </div>
          )}
          {subscription &&
            subscription.status !== "past_due" &&
            ["Starter", "Free", "Professional", "Business"].includes(
              subscription.docuclipperPlan.name
            ) && (
              <div className="my-4">
                <SwitchPlans
                  redirectToAccountOnConfirmed={false}
                  onConfirmed={() => closeCreditsModal()}
                  plans={plans}
                  subscription={subscription}
                  createAlert={createAlert}
                />
              </div>
            )}
          {subscription && subscription.status === "past_due" && (
            <>
              <Container style={{ marginLeft: "auto", marginRight: "auto" }}>
                <div className="my-2">
                  Select a plan to continue using DocuClipper
                </div>
                <TrackedButton
                  action="outOfPagesSelectPlanClick"
                  className="m-2"
                  variant="primary"
                  onClick={() => {
                    closeCreditsModal();
                    history.push("/account/upgrade");
                  }}
                >
                  Select Plan
                </TrackedButton>
              </Container>
            </>
          )}
          {subscription &&
            subscription.status !== "trialing" &&
            ["Enterprise"].includes(subscription.docuclipperPlan.name) &&
            subscription.status === "active" && (
              <Container style={{ marginLeft: "auto", marginRight: "auto" }}>
                <div className="text-center" style={{ maxWidth: "600px" }}>
                  <div className="my-2">{`Your plan includes ${subscription.docuclipperPlan.numPages} pages / month. Need more pages? Contact support to switch to a custom plan that fits your needs.`}</div>

                  <Row>
                    <Col>
                      <TrackedButton
                        action="contactSupportClick"
                        className="m-2"
                        variant="secondary"
                        onClick={() => {
                          try {
                            talkToSales();
                            closeCreditsModal();
                            createAlert({
                              message: `Request received. We'll be in touch soon`,
                              timeout: 0,
                              type: "success",
                            });
                          } catch (err) {
                            closeCreditsModal();
                            logErrorToGA(err);
                            createAlert({
                              message: `Error sending request`,
                              timeout: 0,
                              type: "error",
                            });
                          }
                        }}
                      >
                        Contact support
                      </TrackedButton>
                      {/* <Button
                        variant="primary"
                        className="m-2"
                        onClick={() => history.push("/account?section=plan")}
                      >
                        Buy Pages
                      </Button> */}
                    </Col>
                  </Row>
                </div>
              </Container>
            )}
          {subscription &&
            subscription.status === ("Local" as any) &&
            subscription.seat &&
            subscription.admin &&
            !subscription.seat.stripePriceId && (
              <Container style={{ marginLeft: "auto", marginRight: "auto" }}>
                <div className="text-center">
                  <div className="my-2">{`The account is out pages. We've notified the account administrator (${subscription.admin.email}).`}</div>
                  <div className="m-2">
                    Need to finish the job right now? Create your own account
                  </div>
                  <div>
                    <Button
                      className="m-2"
                      onClick={() => history.push("/signup")}
                    >
                      Create Account
                    </Button>
                  </div>
                </div>
              </Container>
            )}
          {subscription &&
            subscription.status === ("Local" as any) &&
            subscription.seat &&
            subscription.admin &&
            subscription.seat.stripePriceId && (
              <Container style={{ marginLeft: "auto", marginRight: "auto" }}>
                <div className="my-2">{`The account is out pages. We've notified the account administrator (${subscription.admin.email}).`}</div>
              </Container>
            )}
        </Modal.Body>
      </Modal>
    </>
  );
};

const SubscriptionModalWithData = withRouter(
  WithAccountAndPlans(withRouter(SubscriptionModalUnconnected))
) as any;

export const SubscriptionModal: React.FC = () => {
  const dispatch = useDispatch();

  const { activeModal, modals } = useSelector(
    (state: ReduxState) => state.ModalManager
  );
  if (activeModal !== "outOfPages") {
    return null;
  }
  const data = modals.filter((x) => x.name === "outOfPages");
  return (
    <SubscriptionModalWithData
      open={activeModal === "outOfPages"}
      data={data.length > 0 ? data[0].data : null}
      closeCreditsModal={() => dispatch(closeModal("outOfPages"))}
      createAlert={(x) => dispatch(createAlert(x))}
    />
  );
};

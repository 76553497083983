import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import Tour from "reactour";

// {
/* <Reactour
  lastStepNextButton={
    <TrackedButton
      className="tour__btn--finish"
      variant="primary"
      onClick={this.onRequestClose}
      action="tourFinishClick"
      label={this.props.id}
    >
      Finish
    </TrackedButton>
  }
  steps={this.props.steps}
  isOpen={run}
  onRequestClose={this.onRequestClose}
  update={this.props.hashes[this.props.id] || ""}
/>; */
// }

type Props = {};

// transaction-manager-filter-fieldName
// transaction-manager-missing-transactions
// transaction-manager-pagination
// transaction-manager-transactions
// btn--report-bad-job
// btn--download-data
// transaction-manager-missing-transactions

const steps = [
  {
    selector: ".categorization-tour-select-group",
    content:
      "Select the categorization group. Each group contains a list of categories and each category has a list of keywords.",
  },
  {
    selector: ".categorization-tour-edit-keywords",
    content: "Configure the categories and keywords in a given group.",
  },
  {
    selector: ".categorization-tour-add-group",
    content:
      "Create a new categorization group by manually adding categories and keywords.",
  },
  {
    selector: ".categorization-tour-upload-vendors",
    content:
      "Create a new categorization group by uploading an Excel spreadsheet with vendors and categories.",
  },
  {
    selector: ".categorization-tour-categorize",
    content: "Click on categorize to start the categorization process.",
  },
  {
    selector: ".categorization-tour-summary",
    content: "Summary of total spend by category.",
  },
  {
    selector: ".categorization-tour-table",
    content: "Browse and filter the transactions.",
  },

  {
    selector: ".categorization-tour-override-category",
    content:
      "Manually set the category. When a category is set this way, the transaction won't be classified automatically using keywords.",
  },
  {
    selector: ".categorization-tour-update-bulk",
    content:
      "Use the checkboxes to select one or more transactions. Update the category of the selected transactions in bulk.",
  },
  {
    selector: ".categorization-tour-download-data",
    content: "Download the transaction data, including the categories.",
  },

  // ...
];

export const CategorizationTour: React.FC<Props> = ({}) => {
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <Button variant="link" onClick={() => setOpen(true)}>
        <FontAwesomeIcon icon="play" />
        <span className="ml-1">Tutorial</span>
      </Button>
      <Tour steps={steps} isOpen={open} onRequestClose={() => setOpen(false)} />
    </>
  );
};
export default CategorizationTour;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "..";
import {
  createTagRegexesInvoice,
  createTagRegexesSet,
  getTaxRegexesByType,
  updateTagRegexesInvoice,

} from "../../docuclipper/api";
import {
  DocType,
  TagInvoiceRule,
  TagRegexes,
  TagRegexesSets,
  TagRegexesType,

} from "../../docuclipper/DocuclipperTypes";
import { createAlert } from "./Alerts";
import { deleteTagRegexes } from '../../docuclipper/api';
import { applyRunInvoiceRules } from "./InvoiceManager";

const initialState: {
  loading: boolean;
  tagRegexesInvoice: TagRegexes[];
  TagRegexesInvoiceSets: TagRegexesSets[];

} = {
  loading: false,
  tagRegexesInvoice: [],
  TagRegexesInvoiceSets: [],
};

const slice = createSlice({
  name: "TagRegexesInvoice",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },

    updateTagRegexesInvoice2(state, action: PayloadAction<{ id; jsonRules }>) {
      state.tagRegexesInvoice = state.tagRegexesInvoice.map((x) =>
        x.id === action.payload.id
          ? { ...x, jsonRules: action.payload.jsonRules }
          : x
      );
    },
    addTagRegexesInvoice(state, action: PayloadAction<TagRegexes>) {
      state.tagRegexesInvoice = [...state.tagRegexesInvoice, action.payload];
    },
    addTagRegexesInvoiceSet(state, action: PayloadAction<TagRegexesSets>) {
      state.TagRegexesInvoiceSets = [...state.TagRegexesInvoiceSets, action.payload];
    },
    setTagRegexesInvoice(state, action: PayloadAction<TagRegexes[]>) {
      state.tagRegexesInvoice = action.payload;
    },
    setTagRegexesInvoiceSets(state, action: PayloadAction<TagRegexesSets[]>) {
      state.TagRegexesInvoiceSets = action.payload;
    },
  },
});

export const {
  setLoading,
  updateTagRegexesInvoice2,
  setTagRegexesInvoice,
  setTagRegexesInvoiceSets,
  addTagRegexesInvoice,
  addTagRegexesInvoiceSet,
} = slice.actions;


export const fetchTagRegexesInvoice = (type: TagRegexesType, integrationId: number | null, docType: DocType) => async (dispatch, getState) => {
  const state: ReduxState = getState();
  if (state.TagRegexesInvoice.loading) {
    return;
  }
  dispatch(setLoading(true));

  try {
    const {
      tagRegexes: tagRegexesInvoice,
      tagRegexesSets: tagRegexesInvoiceSets
    } = await getTaxRegexesByType(type, integrationId, docType);

    dispatch(setTagRegexesInvoice(tagRegexesInvoice));
    dispatch(setTagRegexesInvoiceSets(tagRegexesInvoiceSets));
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
  }
};



export const updateTagRegexesInvoiceAction =
  (id, rule, integrationId, docType) => async (dispatch, getState) => {
    try {
      updateTagRegexesInvoice(id, rule)
        .then((res) => {
          dispatch(updateTagRegexesInvoice2({ id, jsonRules: res.jsonRules }));
          dispatch(
            createAlert({
              message: "Invoice rules updated successfully",
              timeout: 3000,
              type: "success",
            })
          );
          dispatch(applyRunInvoiceRules(integrationId, docType));
        })
        .catch((err) => null);
    } catch (err) { }
  };



export const deleteTagRegexesInvoiceAction = (id, integrationId, docType) => async (dispatch, getState) => {
  try {
    const state: ReduxState = getState()
    deleteTagRegexes(id)
      .then(() => {
        dispatch(
          createAlert({
            message: "Invoice Rule deleted successfully",
            timeout: 3000,
            type: "success",
          })
        );
        dispatch(applyRunInvoiceRules(integrationId, docType));

        dispatch(setTagRegexesInvoice(state.TagRegexesInvoice.tagRegexesInvoice.filter((x) => x.id !== id)));
        dispatch(setTagRegexesInvoiceSets(state.TagRegexesInvoice.TagRegexesInvoiceSets.filter((x) => x.tagRegexesId !== id)));
      })
      .catch((err) => null);
  } catch (err) { }
}



export const addNewInvoiceGroup = (name, rule: TagInvoiceRule, integrationId, docType) => async (dispatch, getState) => {
  if (!name) {
    dispatch(
      createAlert({
        message: "Need to specify the invoice group name",
        timeout: 0,
        type: "error",
      })
    );
    return;
  }
  try {
    createTagRegexesInvoice(`${rule.tag}`, "[]", rule)
      .then((rsp) => {
        dispatch(
          addTagRegexesInvoice({
            id: rsp.id,
            regexes: rsp.regexes,
            tag: rsp.tag,
            jsonRules: JSON.parse(rsp.jsonRules)
          })
        );
        createTagRegexesSet(name, rsp.id, "invoiceRules")
          .then((rsp2) => {
            dispatch(addTagRegexesInvoiceSet(rsp2));
            dispatch(applyRunInvoiceRules(integrationId, docType));
          })
          .catch((err) => dispatch(createAlert({
            message: err.message || "Error adding rule",
            timeout: 0,
            type: "error",
          })));
      })
      .catch((err) => null);
  } catch (err) { }
};

export default slice.reducer;

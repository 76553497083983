import React from "react";
import { downloadDocumentAsAdmin } from "../../../docuclipper/api";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIsAdmin } from "src/utils/auth";
type Props = {
  documentId: number;
};
export const DownloadAsAdminButton: React.SFC<Props> = ({ documentId }) => {
  const download = () => downloadDocumentAsAdmin(documentId);

  const { isAdmin, isSuperLogin, isSupportRep } = useIsAdmin();
  if (!isAdmin && !isSuperLogin && !isSupportRep) {
    return null;
  }
  return (
    <>
      <Button variant="link" onClick={download}>
        <FontAwesomeIcon icon="download" />
      </Button>
    </>
  );
};
export default DownloadAsAdminButton;

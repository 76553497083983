import React, { useState } from "react";
import { Card, Button, Badge, Alert } from "react-bootstrap";
import { updateMatchGroupStatus } from "src/docuclipper/api";

interface Props {
  groups: any[];
  onStatusUpdated: () => void;
}

export const ExistingMatchGroups: React.FC<Props> = ({
  groups = [],
  onStatusUpdated,
}) => {
  const [error, setError] = useState("");

  const handleStatusUpdate = async (
    groupId: number,
    status: "confirmed" | "rejected"
  ) => {
    try {
      await updateMatchGroupStatus(groupId, status);
      onStatusUpdated();
    } catch (err) {
      setError("Failed to update match group status");
    }
  };

  const getStatusBadgeVariant = (status: string) => {
    switch (status) {
      case "confirmed":
        return "success";
      case "rejected":
        return "danger";
      default:
        return "warning";
    }
  };

  if (groups.length === 0) {
    return (
      <Alert variant="info" className="mt-3">
        No existing match groups found.
      </Alert>
    );
  }

  return (
    <div className="mt-3">
      {error && <Alert variant="danger">{error}</Alert>}

      {(Array.isArray(groups) ? groups : []).map((group) => (
        <Card key={group.id} className="mb-3">
          <Card.Header className="d-flex justify-content-between align-items-center">
            <span>
              Match Group #{group.id}
              <Badge
                variant={getStatusBadgeVariant(group.status)}
                className="ml-2"
              >
                {group.status}
              </Badge>
            </span>
            {group.status === "pending" && (
              <div>
                <Button
                  variant="success"
                  size="sm"
                  className="mr-2"
                  onClick={() => handleStatusUpdate(group.id, "confirmed")}
                >
                  Confirm
                </Button>
                <Button
                  variant="danger"
                  size="sm"
                  onClick={() => handleStatusUpdate(group.id, "rejected")}
                >
                  Reject
                </Button>
              </div>
            )}
          </Card.Header>
          <Card.Body>
            <div>Matched By: {group.matchedBy}</div>
            <div>Matched At: {new Date(group.matchedAt).toLocaleString()}</div>
            {group.confirmedBy && (
              <div>
                Confirmed By: {group.confirmedBy} at{" "}
                {new Date(group.confirmedAt).toLocaleString()}
              </div>
            )}
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { deleteJob } from "../../../docuclipper/api";
import { createAlert } from "../../../redux/reducers/Alerts";
import { setJobData } from "../../../redux/reducers/JobData";
import { getCustomUi } from "../../Docuclipper/ConfirmAlert/ConfirmAlert";

export function DeleteJobButton({ job }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const onJobDelete = () => {
    if (!job) {
      return;
    }

    const getMessage = () => (
      <div style={{ marginBottom: "20px" }}>
        <span>{`Are you sure you want to delete this job?`}</span>
      </div>
    );

    confirmAlert({
      customUI: getCustomUi({
        onConfirm: () => {
          deleteJob(job.id)
            .then((jobRsp) => {
              dispatch(setJobData({ job: null as any }));
              history.push("/dataArchive");
              dispatch(
                createAlert({
                  message: "Job deleted successfully",
                  timeout: 0,
                  type: "success",
                })
              );
            })
            .catch((err) => {
              dispatch(
                createAlert({
                  message: "Error deleting job",
                  timeout: 0,
                  type: "error",
                })
              );
            });
        },
        confirmCTA: "Delete Job",
        cancelCTA: "Cancel",
        getMessage,
      }),
    });
  };

  return (
    <Button variant="link" onClick={onJobDelete}>
      <FontAwesomeIcon icon="trash" />
      <span className="ml-1">Delete Job</span>
    </Button>
  );
}

import React from "react";
import TagRules from "../Categorize/TagRules";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TaggingTransactionsTable } from "./TaggingTransactionTable";
type Props = {};
export const Tagging: React.FC<Props> = ({}) => {
  const [show, setShow] = React.useState(false);

  const toggle = () => setShow(!show);

  return (
    <div>
      <div>
        <Button variant="link" onClick={toggle}>
          <FontAwesomeIcon icon="cog" />
          <span className="ml-1">Edit Tagging Rules</span>
        </Button>

        <Modal size="xl" show={show} onHide={toggle}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Tagging Rules</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TagRules />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggle}>
              Close
            </Button>
            {/* <Button variant="primary" onClick={toggle}>
              Save Changes
            </Button> */}
          </Modal.Footer>
        </Modal>
      </div>
      <div>
        <TaggingTransactionsTable />
      </div>
    </div>
  );
};
export default Tagging;

import React from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../../redux";
import {
  setDownloadInvoiceModalOpen,
} from "../../../../redux/reducers/JobExport";
import CsvInvoiceOptions from "./DownloadOptions/CsvInvoiceOptions";
import DocumentsInvoiceDropdown from "./DownloadOptions/DocumentsInvoiceDropdown";
import ExportTypeInvoiceModalDropdown from "./DownloadOptions/ExportTypeInvoiceModalDropdown";

export const DownloadInvoiceModal = () => {
  const dispatch = useDispatch();
  const { downloadInvoiceModalOpen } = useSelector(
    (state: ReduxState) => state.JobExport
  );

  const toggle = () => dispatch(setDownloadInvoiceModalOpen(!downloadInvoiceModalOpen));

  return (
    <>
      <Modal size="lg" show={downloadInvoiceModalOpen} onHide={toggle}>
        <Modal.Header closeButton>
          <Modal.Title>Download Invoice Data</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container className="p-0">
            <Row className="my-2">
              <Col md="6">
                <div className="font-weight-bold">Documents</div>
                <DocumentsInvoiceDropdown />
              </Col>
              <Col md="6">
                <div className="font-weight-bold">Output Format</div>
                <ExportTypeInvoiceModalDropdown />
              </Col>
            </Row>
            <div className="my-2">
              <CsvInvoiceOptions cta={"Download"} />
            </div>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

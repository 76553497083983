import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert, Button, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  DocuClipperBillingCycle,
  DocuClipperPlan,
  DocuClipperPricing,
} from "../../../../docuclipper/DocuclipperTypes";
import { ReduxState } from "../../../../redux";
import { createAlert, CreateAlertFn } from "../../../../redux/reducers/Alerts";
import PricingCard from "../PricingCard";

type Props = {
  onSelect: (
    plan: DocuClipperPlan,
    billingCycle: DocuClipperBillingCycle
  ) => any;
  pricing: DocuClipperPricing;
  discountedPricing: DocuClipperPricing | null;
  currentPlan: null | DocuClipperPlan;
  currentBillingCycle: null | DocuClipperBillingCycle;

  renderHeading: () => any;
};
type ReduxProps = {
  createAlert: CreateAlertFn;
};

export const getCurrencySymbol = (currency) => {
  switch (currency) {
    case "zar":
      return "R";
    case "cad":
      return "C$";
    case "aud":
      return "A$";
    case "gbp":
      return "£";
    default:
      return "$";
  }
};
export const PlanSelector2: React.FC<
  Props & ReduxProps & RouteComponentProps
> = ({
  onSelect,
  currentPlan,
  currentBillingCycle,
  createAlert,
  renderHeading,
  history,
  location,
  pricing,
  discountedPricing,
}) => {
  // console.log({ pricing });
  const { subscription } = useSelector(
    (state: ReduxState) => state.Subscription
  );
  const coupon = subscription ? subscription.coupon : undefined;

  const [billingCycle, setBillingCycle] =
    React.useState<DocuClipperBillingCycle>("Annually");

  const getPriceJsx = (
    plan: "Starter" | "Professional" | "Business" | "Enterprise"
  ) => {
    const maybeFrom = plan === "Enterprise" ? "From " : "";
    const regularPrice =
      billingCycle === "Monthly"
        ? pricing[plan][billingCycle].amount
        : Math.floor(pricing[plan][billingCycle].amount / 12);
    const discountedPrice = discountedPricing
      ? billingCycle === "Monthly"
        ? discountedPricing[plan][billingCycle].amount
        : Math.floor(discountedPricing[plan][billingCycle].amount / 12)
      : null;
    const yearlySavings =
      regularPrice && discountedPrice
        ? (regularPrice - discountedPrice) * 12
        : 0;

    return discountedPricing ? (
      <div className="price-container">
        <div
          className="regular-price"
          style={{
            fontSize: "1em",
            color: "gray",
            textDecoration: "line-through",
          }}
        >
          {`${getCurrencySymbol(
            pricing[plan][billingCycle].currency
          )}${regularPrice}`}
          <span style={{ fontSize: "0.6em", color: "#2D374880" }}>/mo</span>
        </div>
        <div
          className="discounted-price"
          style={{ fontSize: "1.8em", fontWeight: "bold", color: "black" }}
        >
          {`${maybeFrom}${getCurrencySymbol(
            pricing[plan][billingCycle].currency
          )}${discountedPrice}`}
          <span style={{ fontSize: "0.6em", color: "#2D374880" }}>/mo</span>
        </div>
        <div
          className="yearly-savings"
          style={{ fontSize: "1em", color: "green" }}
        >
          {`Save $${yearlySavings}/yr`}
        </div>
      </div>
    ) : (
      <div className="price-container">
        <div
          className="discounted-price"
          style={{ fontSize: "1.8em", fontWeight: "bold", color: "black" }}
        >
          {`${maybeFrom}${getCurrencySymbol(
            pricing[plan][billingCycle].currency
          )}${regularPrice}`}
          <span style={{ fontSize: "0.6em", color: "#2D374880" }}>/mo</span>
        </div>
      </div>
    );
  };

  const checkCircleStyle = {
    color: "#0CC418",
    fontSize: "20px",
  };

  return (
    <div className="text-center justify-content-center p-4">
      {renderHeading && renderHeading()}
      <Row className="justify-content-center align-items-center m-4">
        <Button
          className={`billing-button ${
            billingCycle === "Annually" ? "active" : "inactive"
          }`}
          onClick={() => setBillingCycle("Annually")}
        >
          Annually (Save 30%)
        </Button>
        <Button
          className={`billing-button ${
            billingCycle === "Monthly" ? "active" : "inactive"
          } ml-1`}
          onClick={() => setBillingCycle("Monthly")}
        >
          Monthly
        </Button>
      </Row>

      <Row className="justify-content-center">
        <PricingCard
          popular={false}
          current={
            false &&
            currentPlan === "Starter" &&
            currentBillingCycle === billingCycle
          }
          selected={true}
          planName="Starter"
          billingCycle={billingCycle}
          onSelected={() => onSelect("Starter", billingCycle)}
          percent={coupon ? coupon.percent_off : undefined}
          featuresFn={() => (
            <div
              style={{ minHeight: "350px" }}
              className="text-left plan-features mb-4"
            >
              <p className="font-weight-bold">
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">120 pages / month</span>
              </p>
              <p>
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">
                  Capture Data from Invoices from Invoices/Receipts
                </span>
              </p>
              <p>
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">Convert any Bank Statement</span>
              </p>
              <p>
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">
                  Convert CSV files to QBO Online/Desktop
                </span>
              </p>
              <p>
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">Unlimited Users</span>
              </p>
              <p>
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">Accounting Integrations</span>
              </p>
              <p>
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">Batch Processing</span>
              </p>
              <p>
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">Customizable Output</span>
              </p>
              <p>
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">30-day Data Retention</span>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="t-categorization-tooltip" {...props}>
                      Set your desired data retention within the plan's
                      allowance, ensuring your data is both securely held and
                      available when you need it.
                    </Tooltip>
                  )}
                >
                  <FontAwesomeIcon className="ml-2" icon="question-circle" />
                </OverlayTrigger>
              </p>
            </div>
          )}
          renderPrice={() => getPriceJsx("Starter")}
          btnText="Choose this plan"
        />
        <PricingCard
          popular={false}
          current={
            false &&
            currentPlan === "Professional" &&
            currentBillingCycle === billingCycle
          }
          selected={true}
          planName="Professional"
          billingCycle={billingCycle}
          onSelected={() => onSelect("Professional", billingCycle)}
          percent={coupon ? coupon.percent_off : undefined}
          featuresFn={() => (
            <div
              style={{ minHeight: "350px" }}
              className="text-left plan-features mb-4"
            >
              <div className="h4 mb-3">All in Starter, plus:</div>
              <p className="font-weight-bold">
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">280 pages / month</span>
              </p>
              <p>
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">Transaction Categorization</span>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="t-categorization-tooltip" {...props}>
                      Automatically categorize transactions using keywords
                    </Tooltip>
                  )}
                >
                  <FontAwesomeIcon className="ml-2" icon="question-circle" />
                </OverlayTrigger>
              </p>
              <p>
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">1-year data retention</span>
              </p>
            </div>
          )}
          // price={`$${starterPrice.regular} / month`}
          renderPrice={() => getPriceJsx("Professional")}
          btnText="Choose this plan"
        />

        <PricingCard
          popular={true}
          current={
            false &&
            currentPlan === "Business" &&
            currentBillingCycle === billingCycle
          }
          selected={true}
          planName="Business"
          billingCycle={billingCycle}
          onSelected={() => onSelect("Business", billingCycle)}
          percent={coupon?.percent_off}
          featuresFn={() => (
            <div
              style={{ minHeight: "350px" }}
              className="text-left plan-features mb-4"
            >
              <div className="h4 mb-3">All in Professional, plus:</div>

              <p className="font-weight-bold">
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">640 pages / month</span>
              </p>

              <p>
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">Advanced Reporting/Analysis</span>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="analysis-tooltip" {...props}>
                      Visualize flow of funds between accounts, detect
                      transfers, and more.
                    </Tooltip>
                  )}
                >
                  <FontAwesomeIcon className="ml-2" icon="question-circle" />
                </OverlayTrigger>
              </p>
              <p>
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">
                  Premium Support With Dedicated Account Manager
                </span>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="analysis-tooltip" {...props}>
                      Get access to a dedicated account manager, and priority
                      support from our knowledgeable support team.
                    </Tooltip>
                  )}
                >
                  <FontAwesomeIcon className="ml-2" icon="question-circle" />
                </OverlayTrigger>
              </p>
              <p>
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">
                  Dedicated Queues For Faster Processing
                </span>
              </p>
              <p>
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">API Access</span>
              </p>
              <p>
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">
                  Team Management & Role Based Access Control
                </span>
              </p>
              <p>
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">2-year Data Retention</span>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="analysis-tooltip" {...props}>
                      Set your desired data retention within the plan's
                      allowance, ensuring your data is both securely held and
                      available when you need it.
                    </Tooltip>
                  )}
                >
                  <FontAwesomeIcon className="ml-2" icon="question-circle" />
                </OverlayTrigger>
              </p>
            </div>
          )}
          renderPrice={() => getPriceJsx("Business")}
          // price={`$${
          //   billingCycle === "Monthly"
          //     ? pricing.Business[billingCycle].amount
          //     : Math.floor(pricing.Business[billingCycle].amount / 12)
          // } / month`}
          btnText="Choose this plan"
        />
        <PricingCard
          current={null}
          popular={false}
          selected={true}
          planName="Enterprise"
          billingCycle={billingCycle}
          percent={coupon?.percent_off}
          onSelected={() => {
            onSelect("Enterprise", billingCycle);
            // try {
            //   talkToSales();
            //   createAlert({
            //     message: `Request received. We'll be in touch soon`,
            //     timeout: 0,
            //     type: "success",
            //   });
            // } catch (err) {
            //   logErrorToGA(err);
            //   createAlert({
            //     message: `Error sending request`,
            //     timeout: 0,
            //     type: "error",
            //   });
            // }
          }}
          featuresFn={() => (
            <div
              style={{ minHeight: "350px" }}
              className="text-left plan-features mb-4"
            >
              <div className="h4 mb-3">All in Business, plus:</div>

              <p className="font-weight-bold">
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">Custom number of pages</span>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="analysis-tooltip" {...props}>
                      DocuClipper seamlessly scales to meet your processing
                      needs.
                    </Tooltip>
                  )}
                >
                  <FontAwesomeIcon className="ml-2" icon="question-circle" />
                </OverlayTrigger>
              </p>
              {/* <p>
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">Reporting</span>
              </p> */}
              <p>
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">Single Sign On</span>
              </p>
              <p>
                <FontAwesomeIcon icon="check-circle" style={checkCircleStyle} />
                <span className="ml-2">
                  <strong>5-year</strong> data retention
                  <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={(props) => (
                      <Tooltip id="analysis-tooltip" {...props}>
                        Set your desired data retention within the plan's
                        allowance, ensuring your data is both securely held and
                        available when you need it.
                      </Tooltip>
                    )}
                  >
                    <FontAwesomeIcon className="ml-2" icon="question-circle" />
                  </OverlayTrigger>
                </span>
              </p>
            </div>
          )}
          renderPrice={() => getPriceJsx("Enterprise")}
          btnText="Choose this plan"
        />
      </Row>
    </div>
  );
};
export default connect((state) => ({}), { createAlert })(
  withRouter(PlanSelector2)
);

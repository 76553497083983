import React, { useState } from "react";
import { Button, Row, Modal, Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { savePreferenceServer, resumeJob } from "src/docuclipper/api";
import { ReduxState } from "src/redux";

// Feature flag for overages
const ENABLE_OVERAGES = false;

type Props = {
  message: string;
};

export const UpgradeAlertBanner: React.FC<Props> = ({ message }) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [numPages, setNumPages] = useState("10000");

  const jobId = useSelector((state: ReduxState) => state.JobData?.job?.id);
  const isContractAdmin = useSelector(
    (state: ReduxState) => state.User.user?.isContractAdmin
  );

  const handleSave = async () => {
    setIsLoading(true);
    try {
      await savePreferenceServer({
        key: "overageConfig",
        value: JSON.stringify({
          enabled: true,
          numPages: parseInt(numPages, 10),
        }),
      });

      setShowModal(false);

      if (jobId) {
        await resumeJob(jobId);
      }
    } catch (error) {
      console.error("Error saving overage configuration:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <span>{message}</span>

      <Button
        onClick={() => history.push("/account/upgrade")}
        className="me-2"
        variant="link"
      >
        Upgrade
      </Button>
      {ENABLE_OVERAGES && isContractAdmin && (
        <Button
          onClick={() => setShowModal(true)}
          className="me-2"
          variant="link"
        >
          Configure Overages
        </Button>
      )}

      {ENABLE_OVERAGES && (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Configure Overages</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p>Enable overages to continue processing.</p>
            <Form.Group controlId="numPages">
              <Form.Label>Select Overage Package</Form.Label>
              <Form.Control
                as="select"
                value={numPages}
                onChange={(e) => setNumPages(e.target.value)}
                disabled={isLoading}
              >
                <option value="10000">10,000 pages (4 cents/page)</option>
                <option value="5000">5,000 pages (6 cents/page)</option>
                <option value="2000">2,000 pages (8 cents/page)</option>
              </Form.Control>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSave} disabled={isLoading}>
              Enable Overages
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};

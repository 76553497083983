import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import queryString from "query-string";
import React from "react";
import {
  Form,
  Alert,
  Button,
  Col,
  Row,
  Pagination,
  Card,
  Modal,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import {
  useFilters,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useRowSelect,
  useSortBy,
  useTable,
} from "react-table"; // new
import {
  createCorrection,
  performBulkEdit,
  redoJobInOldMode,
  switchIncludedExcluded,
  switchTransactionSigns,
  updateJobTransaction,
} from "../../../../docuclipper/api";
import { TemplateFieldV2 } from "../../../../docuclipper/DocuclipperTypes";
import { ReduxState } from "../../../../redux";
import { createAlert } from "../../../../redux/reducers/Alerts";
import { fetchTotals } from "../../../../redux/reducers/Reconciler";
import {
  deselectAllRows,
  fetchMfs,
  fetchTls,
  goToFirstPage,
  goToLastPage,
  goToNextPage,
  goToPreviousPage,
  selectAllRows,
  setSelectedChunk,
  setSelectedDocumentId,
  setSelectedFieldNames,
  setSelectedRowIds,
  setSelectedTransactionStatus,
  setTls,
  toggleBulkEdit,
  toggleLoading,
  toggleSelectedRows,
} from "../../../../redux/reducers/TransactionManager";
import {
  isNullOrUndefined,
  useHasQbo,
  useIsCsv2Qbo,
} from "../../../../utils/utils";
import EditableText from "../../../Docuclipper/EditableText";
import { AddTransactionModal, AddTransactionPopover } from "./AddTransaction";
import ShowDiscardedLines from "./ShowDiscardedLines";
import { DownloadModal } from "./DownloadModal";
import { JobAdminButtons, JobButtons } from "./JobButtons";
import JobHeader from "./JobHeader";
import { GenericJobHelp } from "../GenericJobHelp";
import { useJobInfo } from "../../../../custom-hooks/useJobInfo";
import PopoverHelp from "../../../Docuclipper/PopoverHelp";
import { setJobData } from "../../../../redux/reducers/JobData";
import { isArray } from "lodash";
import { confirmAlert } from "react-confirm-alert";
import { Provider } from "react-redux";
import store from "../../../../redux";
import TransactionManagerTour, {
  TransactionManagerTourAndReportIssue,
} from "./TransactionManagerTour";
import { setSelectedAccount } from "../../../../redux/reducers/TransactionManager";
import SelectAccount from "./ReassignAccount/SelectAccount";
import SummaryTableAccountViewHorizontal from "./SummaryTableAccountViewHorizontal";
import SelectWithBadge from "./SelectWithBadge";
import SelectPages from "./SelectPages";
import { NewTransactionManager } from "./NewTransactionManager";
import { useIsAdmin } from "../../../../utils/auth";
import NewTransactionManagerTour from "./NewTransactionManagerTour";
import AutomaticModeTable from "./AutomaticModeTable";
import DownloadAutomaticMode from "./DownloadOptions/DownloadAutomaticMode";
import { downloadSpreadsheetHelper } from "./DownloadOptions/utils";
import DownloadTemplate from "./DownloadOptions/DownloadTemplate";
import JobBadRatings from "./JobBadRatings";
import HelpDrawer from "src/views/Docuclipper/Ratings/HelpDrawer";
import DocumentSelector from "./DocumentSelector";

function XXX({ onClose, onConfirm, getMessage, confirmCTA, cancelCTA }) {
  const [scope, setScope] = React.useState<"page" | "file" | "allFiles">(
    "page"
  );

  const { tls, selectedDocumentId, pageIndex } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );

  const fn = () => {
    onConfirm(scope, pageIndex);
    onClose();
  };

  const { documents } = tls;

  let docName = "this file";
  if (selectedDocumentId) {
    const d = documents.filter(
      (d) => d.id.toString() === selectedDocumentId.toString()
    );
    if (d.length > 0) {
      docName = d[0].originalname;
    }
  }

  return (
    <div className="react-confirm-alert-overlay">
      <div className="react-confirm-alert">
        <div className="react-confirm-alert-body" style={{ padding: "50px" }}>
          <h3>{getMessage()}</h3>
          <div>Apply to:</div>
          <div className="mb-3">
            <Form.Check
              type="radio"
              label={`Transactions in this page`}
              checked={scope === "page"}
              value={"page"}
              onChange={(e) => setScope(e.target.value as any)}
            />
            <Form.Check
              type="radio"
              label={`Transactions in ${docName}`}
              checked={scope === "file"}
              value={"file"}
              onChange={(e) => setScope(e.target.value as any)}
            />
            <Form.Check
              type="radio"
              value={"allFiles"}
              label={`Transactions in this job - ${documents.length} file(s)`}
              checked={scope === "allFiles"}
              onChange={(e) => setScope(e.target.value as any)}
            />
          </div>

          <div className="pull-right">
            <Button
              variant="outline-secondary"
              className="ml-2"
              onClick={onClose}
            >
              {cancelCTA}
            </Button>
            <Button className="ml-2" variant="danger" onClick={fn}>
              {confirmCTA}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

const getSwitchSignUp =
  (props) =>
  ({ onClose }) =>
    (
      <Provider store={store}>
        <XXX {...props} onClose={onClose} />
      </Provider>
    );

const IndeterminateCheckbox = React.forwardRef<any, any>(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      (resolvedRef as any).current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const { tls, reconciled, badDates } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );

  const { data: jobDocs } = useSelector(
    (state: ReduxState) => state.JobDocument
  );

  const { documents } = tls;

  const getName = (documentId) => {
    const x = documents.filter((x) => x.id === documentId);
    if (x && x.length > 0) {
      return x[0].originalname;
    }

    return documentId;
  };
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()].map((x) => ({
      value: x,
      label: getName(x),
      reconciled: reconciled.documentIds[(x as any) || ""],
      reconciledType: "document",
      isBadDate: badDates.documentIds[(x as any) || ""],
    }));
  }, [id, preFilteredRows, jobDocs]);

  const dispatch = useDispatch();

  let value = undefined as any;
  if (filterValue) {
    const values = options.filter((x) => x.value === filterValue);
    if (values.length > 0) {
      value = { ...values[0] };
    }
  }
  const getFilterName = (id) => {
    switch (id) {
      case "documentId":
        return "Document";
      default:
        return "";
    }
  };

  return (
    <Row className="align-items-center transation-manager-select-document-id">
      <Form.Label>
        <strong className="mx-2">{getFilterName(id)}</strong>
      </Form.Label>

      {id === "documentId" && (
        <SelectWithBadge
          value={value}
          onChange={(e) => {
            setFilter(e.value);
            if (id === "documentId") {
              dispatch(setSelectedDocumentId(e.value));
            }
          }}
          options={options}
        />
      )}
      {id !== "documentId" && (
        <Form.Control
          as="select"
          className="d-inline mx-2"
          style={{ width: "400px" }}
          value={value ? value.value : ""}
          onChange={(e) => {
            setFilter(e.target.value);
          }}
        >
          {options.map((option) => (
            <option value={option.value as any}>{option.label}</option>
          ))}
        </Form.Control>
      )}
    </Row>
  );
}

export function TxTable({
  columns,
  data,
  getRowProps,
  getCellProps,
  filters,
  renderMfs,
  hideColumns,
  mode,
}) {
  // Use the state and functions returned from useTable to build your UI
  const dispatch = useDispatch();
  const { job } = useSelector((state: ReduxState) => state.JobData);
  const { isBulkEdit } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );
  const { totalsByDocumentId } = useSelector(
    (state: ReduxState) => state.Reconciler
  );

  const { data: jobDocs } = useSelector(
    (state: ReduxState) => state.JobDocument
  );

  const hasQbo = useHasQbo();
  const {
    selectedPage,
    firstPage,
    lastPage,
    selectedRowIds: selectedRowIdsRedux,
  } = useSelector((state: ReduxState) => state.TransactionManager);
  const { selectedDocumentId } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );
  React.useEffect(() => {
    if (hasQbo) {
      const docIds = Object.keys(totalsByDocumentId);
      if (isNullOrUndefined(selectedDocumentId) && docIds.length > 0) {
        dispatch(setSelectedDocumentId(docIds[0]));
      }
    }
  }, [totalsByDocumentId]);

  React.useEffect(() => {
    if (!hasQbo) {
      if (isNullOrUndefined(selectedDocumentId) && jobDocs.length > 0) {
        dispatch(setSelectedDocumentId(jobDocs[0].documentId));
      }
    }
  }, [jobDocs.length]);

  const filterTypes = React.useMemo(
    () => ({
      multiple: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined && filterValue !== undefined
            ? filterValue.includes(rowValue)
            : true;
        });
      },
    }),
    []
  );
  const defaultColumn = React.useMemo(
    () => ({
      // When using the useFlexLayout:
      minWidth: 30, // minWidth is only used as a limit for resizing
      width: 150, // width is used for both the flex-basis and flex-grow
      maxWidth: 500, // maxWidth is only used as a limit for resizing
    }),
    []
  );

  React.useEffect(() => {
    if (!isBulkEdit) {
      setTableHiddenColumns([
        "documentId",
        "documentName",
        "rowNumber",
        "status",
        "fieldName",
        "transactionId",
        "columnMapping",
        "pageNumber",
        // "chunk",
        "selection",
        ...hideColumns,
      ]);
    } else {
      setTableHiddenColumns([
        "documentId",
        "documentName",
        "rowNumber",
        "status",
        "fieldName",
        "transactionId",
        "columnMapping",
        "pageNumber",
        // "chunk",
        ...hideColumns,
      ]);
    }
  }, [isBulkEdit]);

  const hc = [
    "documentId",
    "documentName",
    "rowNumber",
    "status",
    "fieldName",
    "transactionId",
    "columnMapping",
    "pageNumber",
    // "chunk",
    ...hideColumns,
  ];
  if (!isBulkEdit) {
    hc.push("selection");
  }
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    allColumns,
    prepareRow,

    page, // Instead of using 'rows', we'll use page,
    rows,
    preFilteredRows,
    state: { selectedRowIds },
    setHiddenColumns: setTableHiddenColumns,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,

      filterTypes,
      autoResetPage: false,
      initialState: {
        pageSize: 1000,
        // selectedRowIds: dataToIncludedMap(data),
        selectedRowIds: selectedRowIdsRedux,
        hiddenColumns: hc,
        filters,
      } as any,
    } as any,
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    usePagination, // new
    useRowSelect,
    useFlexLayout,
    useResizeColumns
  ) as any;

  const selected = Object.keys(selectedRowIds).filter(
    (x) => !!selectedRowIds[x]
  );
  const selectedRows = rows.filter((x) => x.id in selected);

  // React.useEffect(() => {
  //   dispatch(setSelectedRowIds(selectedRowIds));
  // }, [Object.keys(selectedRowIds || {}).length]);

  const onClickSwitchSign = ({ message, confirmCTA, type }) => {
    const getMessage = () => (
      <div style={{ marginBottom: "20px" }}>
        <span>{message}</span>
      </div>
    );
    confirmAlert({
      customUI: getSwitchSignUp({
        onConfirm: (scope, pageNumber) => {
          if (job && selectedDocumentId) {
            const fn =
              type === "switchSigns"
                ? switchTransactionSigns
                : switchIncludedExcluded;
            dispatch(toggleLoading());
            fn(job.id as any, selectedDocumentId, scope, pageNumber)
              .then(({ transactions }) => {
                dispatch(toggleLoading());
                dispatch(setTls({ transactions, resetPages: false }));

                dispatch(fetchTotals());
                dispatch(fetchMfs());
                dispatch(
                  createAlert({
                    message: "Signs switched successfully",
                    timeout: 0,
                    type: "success",
                  })
                );
              })
              .catch(() => {
                dispatch(toggleLoading());
                dispatch(
                  createAlert({
                    message: "Error switching signs",
                    timeout: 0,
                    type: "error",
                  })
                );
              });
          }
        },
        confirmCTA,
        cancelCTA: "Cancel",
        getMessage,
      }),
    });
  };

  const bulkEditHelper = (operation) => {
    if (job) {
      if (selected.length === 0) {
        setSelectionError("Select at least 1 row");
        return;
      }
      setSelectionError("");
      dispatch(toggleLoading());
      const transactionIds = Object.keys(selectedRowIds).map(
        (rowId) => data[rowId].transactionId
      );
      performBulkEdit({
        jobId: job.id,
        operation,
        params: {},
        transactionIds,
      })
        .then(({ transactions }) => {
          dispatch(toggleLoading());
          dispatch(setTls({ transactions, resetPages: false }));

          dispatch(fetchTotals());
          dispatch(fetchMfs());

          dispatch(
            createAlert({
              message: "Correction made successfully",
              timeout: 0,
              type: "success",
            })
          );
          dispatch(setSelectedRowIds({}));
        })
        .catch(() => {
          dispatch(toggleLoading());
          dispatch(
            createAlert({
              message: "Error making correction",
              timeout: 0,
              type: "error",
            })
          );
        });
    }
  };
  // Render the UI for your table
  const [selectionError, setSelectionError] = React.useState("");
  return (
    <>
      <Row>
        <Col md="12">
          <Row className="mt-2">
            {allColumns.map((column) => {
              return column.Filter ? (
                <div className="my-2 mx-2">{column.render("Filter")}</div>
              ) : null;
            })}
          </Row>
          <>
            {data.length === 0 && (
              <Alert variant="danger">
                {job?.isGeneric || !hasQbo
                  ? `The document doesn't have any data`
                  : `The statement doesn't have any transactions`}
              </Alert>
            )}
          </>
          {data.length > 0 && (
            <>
              {mode === "reconcile" && (
                <>
                  <div className="mx-2 mb-2">{renderMfs()}</div>
                  <Row>
                    <Col>
                      {!job?.isGeneric && hasQbo && (
                        <SummaryTableAccountViewHorizontal />
                      )}
                    </Col>
                  </Row>
                </>
              )}
              <Row className="justify-content-between align-items-center">
                <Col md="7">
                  <Row>
                    <div className="pagination mt-2 mx-3 ">
                      <Pagination className="transaction-manager-pagination">
                        <Pagination.First
                          disabled={firstPage === selectedPage}
                          onClick={() => dispatch(goToFirstPage())}
                        >
                          {"<< First Page"}
                        </Pagination.First>
                        <Pagination.Prev
                          disabled={firstPage === selectedPage}
                          onClick={() => dispatch(goToPreviousPage())}
                        >
                          {"< Previous Page"}
                        </Pagination.Prev>

                        <Pagination.Item activeLabel="">
                          Page {(selectedPage as number) + 1}
                        </Pagination.Item>

                        <Pagination.Next
                          disabled={lastPage === selectedPage}
                          onClick={() => dispatch(goToNextPage())}
                        >
                          {/* {"Next >"} */}
                          {"Next Page >"}
                        </Pagination.Next>
                        <Pagination.Last
                          disabled={lastPage === selectedPage}
                          onClick={() => dispatch(goToLastPage())}
                        >
                          {/* {"Last >>"} */}
                          {"Last Page >>"}
                        </Pagination.Last>
                      </Pagination>
                      <PopoverHelp
                        header=""
                        placement="right"
                        id="reconciledHelp"
                      >
                        DocuClipper only shows pages that have transactions
                      </PopoverHelp>
                    </div>
                  </Row>
                </Col>

                <Col md="5">
                  {hasQbo && (
                    <>
                      <Row className="justify-content-end">
                        {mode === "reconcile" && !isBulkEdit && (
                          <div className="">
                            <div className="transaction-manager-missing-transactions">
                              {!job?.isBankMode && (
                                <>
                                  <div>
                                    <strong>Missing transactions?</strong>
                                  </div>
                                  <ShowDiscardedLines />
                                </>
                              )}
                              <div className="">
                                <AddTransactionModal
                                  buttonVariant="light"
                                  extended={false}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {!isBulkEdit && (
                          <Button
                            className="ml-2"
                            variant="light"
                            onClick={() => dispatch(toggleBulkEdit())}
                          >
                            <FontAwesomeIcon icon="edit" />
                            <span className="ml-1">Bulk Edit Transactions</span>
                          </Button>
                        )}
                        {isBulkEdit && (
                          <Button
                            variant="link"
                            onClick={() => dispatch(toggleBulkEdit())}
                          >
                            <FontAwesomeIcon icon="times" />
                            <span className="ml-1">Exit Bulk Edit</span>
                          </Button>
                        )}
                      </Row>
                    </>
                  )}

                  {false && (
                    <Row className="justify-content-end">
                      <div className="mr-5">
                        <Row>
                          <Button
                            className="m-1"
                            variant="link"
                            onClick={() =>
                              onClickSwitchSign({
                                confirmCTA: "Flip Signs",
                                message: `DocuClipper will flip the sign of the transactions (credits will become debits and debits will become credits). Are you sure you want to continue?`,
                                type: "switchSigns",
                              })
                            }
                          >
                            <FontAwesomeIcon icon="redo" />
                            <span className="ml-1">
                              Bulk Flip Credits/Debits
                            </span>
                          </Button>
                          <Button
                            className="m-1"
                            variant="link"
                            onClick={() =>
                              onClickSwitchSign({
                                confirmCTA: "Flip included/excluded",
                                message: `DocuClipper will flip which transactions are included (included transactions will be excluded and excluded transactions will be included). Are you sure you want to continue?`,
                                type: "switchIncludedExcluded",
                              })
                            }
                          >
                            <FontAwesomeIcon icon="check" />
                            <span className="ml-1">
                              Bulk Flip Included/Excluded
                            </span>
                          </Button>
                          {mode === "reconcile" && (
                            <div className="">
                              <div className="transaction-manager-missing-transactions">
                                {!job?.isBankMode && (
                                  <>
                                    <div>
                                      <strong>Missing transactions?</strong>
                                    </div>
                                    <ShowDiscardedLines />
                                  </>
                                )}
                                <div className="my-2">
                                  <AddTransactionPopover buttonVariant="link" />
                                </div>
                              </div>
                            </div>
                          )}
                        </Row>
                      </div>
                    </Row>
                  )}
                </Col>
              </Row>
              {isBulkEdit && (
                <Row className="bulk-edit-buttons align-items-center justify-content-between">
                  <Col md="4">
                    <Row className="align-items-center">
                      <div className="mr-2">
                        <strong>
                          {
                            Object.keys(selectedRowIds).filter(
                              (key) => !!selectedRowIds[key]
                            ).length
                          }{" "}
                          rows selected
                        </strong>
                      </div>
                      {selectionError &&
                        Object.keys(selectedRowIds).length === 0 && (
                          <div className="text-danger mr-2">
                            {selectionError}
                          </div>
                        )}
                      <Button
                        variant="light"
                        className="mr-2"
                        onClick={() => {
                          dispatch(
                            selectAllRows(
                              preFilteredRows
                                .filter(
                                  (x) =>
                                    x.original.documentId.toString() ===
                                    selectedDocumentId?.toString()
                                )
                                .map((x) => x.id)
                            )
                          );
                        }}
                      >
                        <FontAwesomeIcon icon="hand-pointer" />
                        <span className="ml-1">Select All</span>
                      </Button>
                      <Button
                        variant="light"
                        className="mr-2"
                        onClick={() => {
                          dispatch(
                            deselectAllRows(
                              preFilteredRows
                                .filter(
                                  (x) =>
                                    x.original.documentId.toString() ===
                                    selectedDocumentId?.toString()
                                )
                                .map((x) => x.id)
                            )
                          );
                        }}
                      >
                        <FontAwesomeIcon icon="hand-pointer" />
                        <span className="ml-1">Select None</span>
                      </Button>
                      <SelectPages
                        buttonVariant={"light"}
                        onSubmit={({ startPage, endPage }) => {
                          const rowIds: string[] = [];
                          preFilteredRows.forEach((row) => {
                            if (
                              startPage <= row.original.pageNumber &&
                              row.original.pageNumber <= endPage &&
                              row.original.documentId === selectedDocumentId
                            ) {
                              // console.log(`toggle row ${row.id}`);
                              // toggleRowSelected(row.id);
                              rowIds.push(row.id);
                            }
                            // console.log({ row, firstPage, lastPage });
                          });
                          dispatch(setSelectedRowIds({}));
                          dispatch(selectAllRows(rowIds));
                        }}
                      />
                    </Row>
                  </Col>

                  <Col md="8" className="text-right">
                    <Button
                      variant="light"
                      onClick={() => bulkEditHelper("switchSigns")}
                      className="m-2"
                    >
                      <FontAwesomeIcon icon="random"></FontAwesomeIcon>
                      <span className="ml-1">Switch credits/debits</span>
                    </Button>
                    <Button
                      variant="light"
                      onClick={() => bulkEditHelper("switchIncludedExcluded")}
                      className="m-2 mr-4"
                    >
                      <FontAwesomeIcon icon="random"></FontAwesomeIcon>
                      <span className="ml-1">Switch included/excluded</span>
                    </Button>

                    <SelectAccount
                      transactionIds={selectedRows.map(
                        (x) => x.original.transactionId
                      )}
                      setSelectionError={setSelectionError}
                    />
                  </Col>
                </Row>
              )}
              {
                <>
                  <div
                    className="transaction-manager-transactions"
                    style={{
                      overflowX: "scroll",
                      overflowY: "scroll",
                      maxHeight: "50vh",
                    }}
                  >
                    <table
                      className="ml-0"
                      {...getTableProps()}
                      style={{ overflowX: "visible" }}
                      border="1"
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                              // Add the sorting props to control sorting. For this example
                              // we can add them into the header props
                              <th
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                {column.render("Header")}
                                {/* Add a sort direction indicator */}
                                <span>
                                  {column.isSorted
                                    ? column.isSortedDesc
                                      ? " 🔽"
                                      : " 🔼"
                                    : ""}
                                </span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody {...getTableBodyProps()}>
                        {page.map((row) => {
                          // new
                          prepareRow(row);
                          return (
                            <tr {...row.getRowProps(getRowProps(row))}>
                              {row.cells.map((cell) => {
                                return (
                                  <td
                                    {...cell.getCellProps(getCellProps(cell))}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              }
            </>
          )}
          {/* new */}
        </Col>
      </Row>
    </>
  );
}

function MultiSelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}) {
  const dispatch = useDispatch();
  const { totalsByDocumentId } = useSelector(
    (state: ReduxState) => state.Reconciler
  );

  const { selectedDocumentId, selectedFieldNames } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );

  const totals = !isNullOrUndefined(selectedDocumentId)
    ? totalsByDocumentId
      ? totalsByDocumentId[selectedDocumentId as string]
      : {}
    : {};

  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()].map((x) => ({
      value: x,
      label: x,
      totals: totals
        ? totals[x as any]
          ? totals[x as any]
          : undefined
        : undefined,
      reconciledType: "field",
    }));
  }, [id, preFilteredRows, totals, selectedDocumentId]);

  const idToName = (id) => {
    switch (id) {
      case "status":
        return "Transaction Status";
      case "fieldName":
        return "Fields";
      default:
        return id;
    }
  };

  if (id === "fieldName") {
    return null;
  }

  if (id === "status") {
    return null;
  }

  if (id === "pageNumber") {
    return null;
  }

  return (
    <div className={`transaction-manager-filter-${id}`}>
      <strong>{idToName(id)}</strong>
      {options.map((option) => (
        <>
          <Form.Check
            type="checkbox"
            checked={(filterValue || []).includes(option.value)}
            label={option.label as string}
            onChange={(e) => {
              if (id === "fieldName") {
                let newValue: string[] = [];
                if (e.target.checked) {
                  newValue = [
                    ...(selectedFieldNames || []),
                    option.value as string,
                  ];
                } else {
                  newValue = (selectedFieldNames || []).filter(
                    (x) => x !== option.value
                  );
                }

                setFilter(newValue);
                dispatch(setSelectedFieldNames(newValue));
              }
            }}
          />
        </>
      ))}
    </div>
  );
}

function qboFieldComponent(field, jobId, row, dispatch) {
  const createCorrectionHelper = (
    newText,
    toggleSign,
    divideBy100,
    multilineIndex
  ) => {
    dispatch(toggleLoading());
    createCorrection(
      jobId,
      row.values.documentId,
      row.values.fieldName,
      row.values.rowNumber,
      row.values.columnMapping[field],
      newText,
      row.values.included,
      toggleSign,
      divideBy100,
      multilineIndex
    )
      .then(({ transactions }) => {
        dispatch(toggleLoading());
        dispatch(setTls({ transactions, resetPages: false }));

        dispatch(fetchTotals());
        dispatch(fetchMfs());
        dispatch(setSelectedAccount(row.values.account));
        dispatch(setSelectedChunk(row.values.chunk));

        // dispatch(fetchJobDocuments());
        // dispatch(fetchTls());
        dispatch(
          createAlert({
            message: "Transaction fixed",
            timeout: 0,
            type: "success",
          })
        );
      })
      .catch(() => {
        dispatch(toggleLoading());
        dispatch(
          createAlert({
            message: "Error fixing transaction",
            timeout: 0,
            type: "error",
          })
        );
      });
  };

  let elem = (
    <EditableText
      initialValue={row.values[field]}
      onConfirm={(newText) =>
        createCorrectionHelper(newText, false, false, null)
      }
    />
  );
  if (field === "name" && isArray(row.values[field])) {
    const lines = row.values[field];
    elem = (lines || []).map((line, i) => (
      <EditableText
        initialValue={line}
        onConfirm={(newText) =>
          createCorrectionHelper(newText, false, false, i)
        }
      />
    ));
  }
  return (
    <>
      <Row className={`${field === "date" ? "tx-manager-column-date" : ""}`}>
        <Col>
          <Row className="align-items-center justify-content-between">
            <Col>{elem}</Col>
            {["amount", "balance", "debit", "credit"].includes(field) && (
              <Col className="px-0">
                <Button
                  className="p-0"
                  variant="light"
                  size="sm"
                  onClick={() => createCorrectionHelper("", true, false, null)}
                >
                  +/-
                </Button>
                <Button
                  className="p-0 ml-2"
                  variant="light"
                  size="sm"
                  onClick={() => createCorrectionHelper("", false, true, null)}
                >
                  .00
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
}

function genericFieldComponent(col, jobId, row, dispatch) {
  return (
    <EditableText
      initialValue={row.values[`col${col}`]}
      onConfirm={(newValue) =>
        updateJobTransaction(jobId, row.values.transactionId, {
          col,
          newValue,
        })
          .then(() => {
            dispatch(
              createAlert({
                message: "Transaction fixed",
                timeout: 0,
                type: "success",
              })
            );
          })
          .catch(() =>
            dispatch(
              createAlert({
                message: "Error fixing transaction",
                timeout: 0,
                type: "error",
              })
            )
          )
      }
    />
  );
}

export function TransactionManager({
  hasQbo,
  mode,
}: {
  hasQbo: boolean;
  mode: "reconcile" | "categorize";
  reconcileView: "account" | "file";
}) {
  const dispatch = useDispatch();
  const location = useLocation();
  const { jobId } = queryString.parse(location.search);

  const { job } = useSelector((state: ReduxState) => state.JobData);

  const {
    tls,
    selectedDocumentId,
    selectedFieldNames,
    selectedTransactionStatus,
    selectedPage,
    selectedRowIds,
  } = useSelector((state: ReduxState) => state.TransactionManager);

  React.useEffect(() => {
    if (job && !["InProgress", "Finishing", "Canceled"].includes(job.status)) {
      dispatch(fetchTls(true));
      dispatch(fetchMfs());
      dispatch(fetchTotals());
      dispatch(setSelectedFieldNames(undefined));
      dispatch(setSelectedFieldNames(undefined));
      dispatch(setSelectedTransactionStatus(undefined));
    }
  }, [job]);

  React.useEffect(() => {
    dispatch(
      setSelectedTransactionStatus(["unknown", "reconciled", "added manually"])
    );
  }, []);

  const {
    tls: transactions,
    loading: tlsLoading,
    error: tlsError,
    documents,
  } = tls;

  const columns = React.useMemo(() => {
    const cols = [
      {
        id: "documentId",
        accessor: (row) => row.documentId,
        Filter: SelectColumnFilter, // new
        filter: "includes",
        cols: 6,
      },
      {
        id: "documentName",
        accessor: (row) => row.documentName,
      },
      {
        id: "status",
        accessor: (row) => row.status,
        Filter: MultiSelectColumnFilter, // new
        filter: "multiple",
        cols: 6,
      },
      {
        id: "fieldName",
        accessor: (row) => row.fieldName,
        Filter: MultiSelectColumnFilter, // new
        filter: "multiple",
        cols: 12,
      },

      {
        id: "rowNumber",
        accessor: (row) => row.rowNumber,
      },
      {
        id: "columnMapping",
        accessor: (row) => row.columnMapping,
      },
      {
        id: "transactionId",
        accessor: (row) => row.transactionId,
      },

      {
        id: "pageNumber",
        Filter: MultiSelectColumnFilter,
        Header: "pn",
        accessor: (row) => row.pageNumber,
        filter: "equals",
        width: 20,
      },
    ];

    const qboCols =
      mode === "categorize"
        ? [
            {
              Header: "date",
              accessor: "date",
              width: 100,
              Cell: ({ row }) =>
                qboFieldComponent("date", jobId, row, dispatch),
            },
            {
              Header: "name",
              accessor: "name",
              width: 300,

              Cell: ({ row }) =>
                qboFieldComponent("name", jobId, row, dispatch),
            },
            {
              Header: "amount",
              accessor: "amount",
              width: 200,
              Cell: ({ row }) =>
                qboFieldComponent("amount", jobId, row, dispatch),
            },
            {
              Header: "category",
              accessor: "category",
              width: 200,
              Cell: ({ row }) =>
                qboFieldComponent("category", jobId, row, dispatch),
            },
          ]
        : ([
            {
              id: "selection",
              width: 100,
              className: "d-flex align-items-center ",

              // The header can use the table's getToggleAllRowsSelectedProps method
              // to render a checkbox
              Header: ({ page }: any) => {
                const numChecked = page.filter(
                  (x) => !!selectedRowIds[x.id]
                ).length;

                return (
                  <div
                    className="d-flex align-items-center tx-manager-column-bulk-selection"
                    style={{ height: "100%" }}
                  >
                    <IndeterminateCheckbox
                      indeterminate={numChecked > 0 && numChecked < page.length}
                      checked={numChecked === page.length}
                      onChange={() =>
                        dispatch(toggleSelectedRows(page.map((x) => x.id)))
                      }
                    />
                    <span className="ml-1">Selected</span>
                  </div>
                );
              },
              // The cell can use the individual row's getToggleRowSelectedProps method
              // to the render a checkbox
              Cell: ({ row }: any) => {
                return (
                  <div
                    className="d-flex align-items-center"
                    style={{ height: "100%" }}
                  >
                    <IndeterminateCheckbox
                      checked={selectedRowIds[row.id] || false}
                      indeterminate={false}
                      onChange={() => dispatch(toggleSelectedRows([row.id]))}
                    />
                  </div>
                );
              },
            },

            {
              Header: "Included",
              accessor: "included",
              className: "tx-manager-column-included",
              width: 65,
              Cell: ({ row }) => (
                <div
                  className="d-flex align-items-center tx-manager-column-included"
                  style={{ height: "100%" }}
                >
                  <Form.Check
                    type="checkbox"
                    label=""
                    checked={row.values.included}
                    onChange={(e) => {
                      if (job) {
                        dispatch(toggleLoading());
                        createCorrection(
                          job.id.toString(),
                          row.values.documentId,
                          row.values.fieldName,
                          row.values.rowNumber,
                          -1,
                          null,
                          e.target.checked,
                          false,
                          false
                        )
                          .then(({ transactions }) => {
                            dispatch(toggleLoading());
                            dispatch(
                              setTls({ transactions, resetPages: false })
                            );
                            dispatch(fetchMfs());

                            dispatch(fetchTotals());

                            dispatch(setSelectedAccount(row.values.account));
                            dispatch(setSelectedChunk(row.values.chunk));

                            // dispatch(
                            //   createAlert({
                            //     message: "Transaction fixed",
                            //     timeout: 0,
                            //     type: "success",
                            //   })
                            // );
                          })
                          .catch(
                            () => {
                              dispatch(toggleLoading());
                            }
                            // dispatch(
                            //   createAlert({
                            //     message: "Error fixing transaction",
                            //     timeout: 0,
                            //     type: "error",
                            //   })
                            // )
                          );
                      }
                    }}
                  />
                </div>
              ),
            },
            {
              id: "account",
              Header: "Account",
              accessor: (row) => row.account,
              width: 100,
              Cell: ({ row }) => (
                <div
                  className="d-flex align-items-center text-truncate"
                  style={{ height: "100%" }}
                >
                  {row.original.account}
                </div>
              ),
              // Cell: ({ row }) => (
              //   <EditableText
              //     initialValue={row.values.account}
              //     onConfirm={(newValue) => {
              //       if (job) {
              //         updateJobTransaction(job.id, row.values.transactionId, {
              //           account: newValue,
              //         })
              //           .then(() => {
              //             dispatch(fetchTotals());
              //             // dispatch(fetchJobDocuments());
              //             dispatch(fetchTls(false));
              //             dispatch(fetchMfs());
              //             dispatch(
              //               createAlert({
              //                 message: "Transaction fixed",
              //                 timeout: 0,
              //                 type: "success",
              //               })
              //             );
              //           })
              //           .catch((err) =>
              //             dispatch(
              //               createAlert({
              //                 message: "Error fixing transaction",
              //                 timeout: 0,
              //                 type: "error",
              //               })
              //             )
              //           );
              //       }
              //     }}
              //   />
              // ),
            },
            {
              id: "chunk",
              Header: "Period",
              width: 100,
              accessor: (row) => row.chunk,
              Cell: ({ row }) => {
                const lines = row.original.chunkLabel
                  .split(" ")
                  .filter((x) => x.trim() !== "to");
                return (
                  <div className="">
                    {lines.map((x, i) => (
                      <>
                        {x}
                        {i !== lines.length - 1 && <br />}
                      </>
                    ))}
                  </div>
                );
              },
              // Cell: ({ row }) => {
              //   if (row.values.rowNumber === 0) {
              //     console.log({
              //       chunkLabel: row.values.chunkLabel,
              //       chunk: row.values.chunk,
              //       rowNumber: row.values.rowNumber,
              //       values: row.values,
              //     });
              //   }
              //   return <div>{row.values.chunkLabel}</div>;
              // },
            },
            {
              Header: "Date",
              accessor: "date",
              width: 100,
              className: "tx-column-date",
              Cell: ({ row }) =>
                qboFieldComponent("date", jobId, row, dispatch),
            },
            {
              Header: "Name",
              accessor: "name",
              width: 300,

              Cell: ({ row }) =>
                qboFieldComponent("name", jobId, row, dispatch),
            },
            {
              Header: "Amount",
              accessor: "amount",
              width: 200,
              Cell: ({ row }) =>
                qboFieldComponent("amount", jobId, row, dispatch),
            },
            {
              Header: "Debit",
              accessor: "debit",
              width: 200,
              Cell: ({ row }) =>
                qboFieldComponent("debit", jobId, row, dispatch),
            },
            {
              Header: "Credit",
              accessor: "credit",
              width: 200,
              Cell: ({ row }) =>
                qboFieldComponent("credit", jobId, row, dispatch),
            },
            {
              Header: "Balance",
              accessor: "balance",
              width: 200,
              Cell: ({ row }) =>
                qboFieldComponent("balance", jobId, row, dispatch),
            },

            {
              Header: "CheckNumber",
              width: 100,
              accessor: "checkNumber",
              Cell: ({ row }) =>
                qboFieldComponent("checkNumber", jobId, row, dispatch),
            },
            {
              Header: "Date2",
              width: 100,
              accessor: "date2",
              Cell: ({ row }) =>
                qboFieldComponent("date2", jobId, row, dispatch),
            },
            {
              Header: "RefNumber",
              width: 100,
              accessor: "refNumber",
              Cell: ({ row }) =>
                qboFieldComponent("refNumber", jobId, row, dispatch),
            },
          ] as any);

    const numCols = (transactions || []).reduce((acc, x) => {
      try {
        const res = Math.max(acc, JSON.parse(x.row as any).length);
        return isNaN(res) ? 0 : res;
      } catch (err) {
        return 0;
      }
    }, 0);
    const dataCols = [...Array(numCols).keys()].map((x, i) => ({
      Header: i.toString(),
      accessor: `col${i}`,
      width: (window.innerWidth * 0.3) / numCols,
      Cell: ({ row }) => genericFieldComponent(i, jobId, row, dispatch),
    }));

    return cols.concat(hasQbo ? qboCols : dataCols);
  }, [hasQbo, transactions, selectedRowIds]);

  const template = job ? job.template || null : null;
  let rectangles: TemplateFieldV2[] = [];
  if (template) {
    try {
      const templateObj = JSON.parse(template.template);
      rectangles = templateObj.rectangles;
    } catch (err) {
      //
    }
  }

  const getColumnMapping = (fieldName: string) => {
    const empty = {
      date: null,
      date2: null,
      amount: null,
      name: null,
      credit: null,
      debit: null,
      balance: null,
      checkNumber: null,
    };
    const r = rectangles.filter((x) => x.name === fieldName);
    if (r && r.length > 0) {
      const qbo = r[0].qbo;
      return qbo ? qbo.columnMapping || empty : empty;
    }
    return empty;
  };

  const getDocumentName = (documentId: any) => {
    const r = documents.filter((x) => x.id === documentId);
    if (r && r.length > 0) {
      return r[0].originalname;
    }
    return "";
  };

  const columnsWithNoData = {
    date: null,
    date2: null,
    amount: null,
    name: null,
    credit: null,
    debit: null,
    balance: null,
    checkNumber: null,
    refNumber: null,
  };

  const data = (transactions || []).map((row) => {
    let columnMapping;
    if (row.fieldName === "bankMode") {
      columnMapping = {
        date: 0,
        name: 1,
        amount: 2,
        checkNumber: 3,
        balance: 4,
        date2: 5,
        refNumber: 6,
        category: 7,
        editedCategory: 8,
        payee: 9,
      };
    } else {
      columnMapping = getColumnMapping(row.fieldName) || {};
    }

    // Add error handling for JSON parse
    let obj = [];
    try {
      obj = JSON.parse((row.row as any) || "[]");
      if (!Array.isArray(obj)) {
        obj = [];
      }
    } catch (err) {
      console.warn("Invalid JSON data for row:", row.id);
      obj = [];
    }

    for (const key in columnMapping || {}) {
      if (!isNullOrUndefined(columnMapping[key])) {
        delete columnsWithNoData[key];
      }
    }
    const otherCols = {};
    if (!hasQbo) {
      for (let i = 0; i < (obj || []).length; i += 1) {
        otherCols[`col${i}`] = obj[i];
      }
    }

    return {
      pageNumber: row.pageNumber,
      transactionId: row.id,
      account: row.account,
      chunk: row.chunk,
      chunkLabel: row.chunkLabel,
      rowNumber: row.rowNumber,
      included: row.included,
      documentId: row.documentId,
      documentName: getDocumentName(row.documentId),
      status: row.status,
      fieldName: row.fieldName,
      date: !isNullOrUndefined(columnMapping.date)
        ? obj[columnMapping.date as number] || ""
        : null,
      date2: !isNullOrUndefined(columnMapping.date2)
        ? obj[columnMapping.date2 as number] || ""
        : null,
      name: !isNullOrUndefined(columnMapping.name)
        ? obj[columnMapping.name as number] || ""
        : null,
      amount: !isNullOrUndefined(columnMapping.amount)
        ? obj[columnMapping.amount as number] || ""
        : null,
      debit: !isNullOrUndefined(columnMapping.debit)
        ? obj[columnMapping.debit as number] || ""
        : null,
      credit: !isNullOrUndefined(columnMapping.credit)
        ? obj[columnMapping.credit as number] || ""
        : null,
      balance: !isNullOrUndefined(columnMapping.balance)
        ? obj[columnMapping.balance as number] || ""
        : null,
      checkNumber: !isNullOrUndefined(columnMapping.checkNumber)
        ? obj[columnMapping.checkNumber as number] || ""
        : null,
      refNumber: !isNullOrUndefined(columnMapping.refNumber)
        ? obj[columnMapping.refNumber as number] || ""
        : null,
      category: !isNullOrUndefined(columnMapping.category)
        ? obj[columnMapping.category as number] || ""
        : null,
      payee: !isNullOrUndefined(columnMapping.payee)
        ? obj[columnMapping.payee as number] || ""
        : null,
      columnMapping,
      ...otherCols,
    };
  });

  if (tlsLoading) {
    return <ClipLoader />;
  }

  if (!tlsLoading && tlsError) {
    return <Alert variant="danger">{tlsError}</Alert>;
  }

  const getColor = (status) => {
    switch (status) {
      case "error":
        return "#ffcccb";
      case "reconciled":
        return "";
      default:
        return "";
    }
  };

  if (job && ["InProgress", "Finishing"].includes(job.status)) {
    return null;
  }

  return (
    <Row>
      {!job?.isGeneric && (
        <Col>
          <TxTable
            mode={mode}
            columns={columns}
            data={data}
            getRowProps={(row) => ({
              style: {
                backgroundColor: getColor(row.values.status),
              },
            })}
            getCellProps={() => ({
              className: "px-1",
            })}
            filters={[
              { id: "documentId", value: selectedDocumentId || "" },
              { id: "status", value: selectedTransactionStatus },
              { id: "fieldName", value: selectedFieldNames },
              { id: "pageNumber", value: [selectedPage] },
            ]}
            renderMfs={() => null}
            hideColumns={Object.keys(columnsWithNoData)}
          />
        </Col>
      )}
    </Row>
  );
}

const Step1 = ({
  hasQbo,
}: {
  hasQbo: boolean;
  nextStep?: any;
  isActive?: boolean;
}) => {
  const { job } = useJobInfo();
  const dispatch = useDispatch();
  const redoInOldModeHelper = () => {
    if (!job) {
      return;
    }
    redoJobInOldMode(job.id)
      .then((jobRsp) => {
        dispatch(setJobData({ job: jobRsp, progress: null }));
        dispatch(
          createAlert({
            message: "Job restarted successfully",
            timeout: 0,
            type: "success",
          })
        );
      })
      .catch(() => {
        dispatch(
          createAlert({
            message: "Error restarting job",
            timeout: 0,
            type: "error",
          })
        );
      });
  };

  const isCsv2Qbo = useIsCsv2Qbo();
  const { isAdmin, isSupportRep } = useIsAdmin();

  const { tls } = useSelector((state: ReduxState) => state.TransactionManager);
  const { loading: asyncLoading } = useSelector(
    (state: ReduxState) => state.AutomaticModeAsync
  );

  const { documents } = tls;

  const [showAutomaticModeHelp, setShowAutomaticModeHelp] =
    React.useState(false);
  const toggleAutomaticModeHelp = () =>
    setShowAutomaticModeHelp(!showAutomaticModeHelp);
  // console.log({ job, hasQbo });
  return (
    <>
      <Row className="justity-content-end align-items-center">
        <Col md="6">
          <JobBadRatings />
          <JobHeader />
        </Col>
        <Col md="6" className="justity-content-end">
          <Row className="justify-content-end mt-2">
            <div className="mx-3">
              {job && !job.isGeneric && hasQbo && (
                <>
                  <>{<TransactionManagerTourAndReportIssue />}</>
                </>
              )}
            </div>
            <JobButtons
              downloadButton={() => {
                return (
                  <>
                    {!job?.isGeneric &&
                      (isNullOrUndefined(job?.templateId) ||
                        (job?.templateId && hasQbo)) && <DownloadModal />}
                    {!isNullOrUndefined(job?.templateId) && !hasQbo && (
                      <div className="ml-2">
                        <DownloadTemplate />
                      </div>
                    )}
                  </>
                );
              }}
            />
          </Row>
          <JobAdminButtons />
        </Col>
      </Row>
      {/* <Row>
        {isCsv2Qbo && (
          <Alert style={{}} variant="info">
            We just added support for converting CSV files. Email{" "}
            <a href="mailto:support@docuclipper.com">support@docuclipper.com</a>{" "}
            if you run into any issues or have feedback.
          </Alert>
        )}
      </Row> */}
      {hasQbo && !job?.isGeneric && <NewTransactionManager />}
      {/* <Tabs
        activeKey={activeTab}
        id="admin-tabs"
        onSelect={(activeTab) => setActiveTab(activeTab || "1")}
      >
        <Tab eventKey="1" title="File View">
          {activeTab === "1" ? ( */}
      {(!hasQbo || job?.isGeneric) && (
        <>
          <Row>
            <Col md={12}>
              {job?.template &&
                ((job.bankModeIfReconciled &&
                  !job?.originalBankModeIfReconciled) ||
                  (job.isBankMode && !job?.originalIsBankMode)) &&
                !job?.isGeneric && (
                  <Alert variant="warning">
                    <Row>
                      <Col md="10">
                        DocuClipper has converted the statement(s) using the new
                        bank mode. <br />
                        Getting worse results than before? Redo the job using
                        the old mode (doesn't take any additional pages)
                        <br />
                      </Col>
                      <Col md="2">
                        <Button
                          className="m-2"
                          variant="light"
                          onClick={redoInOldModeHelper}
                        >
                          <FontAwesomeIcon icon="redo" />{" "}
                          <span className="ml-1">Redo Job</span>
                        </Button>
                      </Col>
                    </Row>
                  </Alert>
                )}
              {
                <Card className="p-4">
                  <div>
                    {!job?.originalIsGeneric && job?.isGeneric && (
                      <>
                        <Alert variant="info">
                          <FontAwesomeIcon icon="exclamation-triangle" />
                          <span className="ml-2">
                            The data has been extracted using "automatic mode"
                          </span>
                          <Button
                            onClick={toggleAutomaticModeHelp}
                            variant="link"
                          >
                            Learn More
                          </Button>
                        </Alert>

                        <Modal
                          size="xl"
                          // keyboard={true}
                          // onKeyPress={onKeyPress}
                          show={showAutomaticModeHelp}
                          onHide={toggleAutomaticModeHelp}
                        >
                          <Modal.Header closeButton={true}></Modal.Header>
                          <Modal.Body>
                            <GenericJobHelp job={job} />
                          </Modal.Body>
                        </Modal>
                      </>
                    )}

                    {(job?.isGeneric || job?.template) && (
                      <>
                        <Row className="justify-content-end align-items-center">
                          <Col>
                            <DocumentSelector />
                          </Col>
                          <Col className="justify-content-end">
                            {job?.isGeneric && <DownloadAutomaticMode />}
                          </Col>
                        </Row>
                        <div className="mt-4">
                          <AutomaticModeTable />
                        </div>
                      </>
                    )}
                    {!(job?.isGeneric || job?.template) && (
                      <TransactionManager
                        hasQbo={hasQbo}
                        mode="reconcile"
                        reconcileView={"file"}
                      />
                    )}
                  </div>
                </Card>
              }
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export const TransactionManagerWrapper = ({ hasQbo }) => {
  return <Step1 hasQbo={hasQbo} />;
};
export default TransactionManagerWrapper;

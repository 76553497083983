import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { ReduxState } from "..";
import {
  createCustomFormat,
  deleteCustomFormat,
  getCustomFormats,
  updateCustomFormat,
} from "../../docuclipper/api";
import {
  CustomFormat,
  CustomFormatItem,
  CustomFormatSource,
  CustomFormatType,
} from "../../docuclipper/DocuclipperTypes";
import { createAlert } from "./Alerts";
import { lsKeySelectedCustomConfigCSV, lsKeySelectedExportType, setSelectedCustomConfigCSV, setSelectedExportType } from "./DownloadOptions";
import { lsSetItem, lsGetItem, lsRemoveItem } from "src/LocalStorageWrapper";

const defaultData: CustomFormat["data"] = {
  columnConfigs: [
    {
      index: 0,
      source: "date" as CustomFormatSource,
      params: {},
      type: "date",
      heading: { hasHeading: true, text: "Date" },
    },
    {
      index: 1,
      source: "name" as CustomFormatSource,
      params: {},
      type: "text",
      heading: { hasHeading: true, text: "Description" },
    },
    {
      index: 2,
      source: "amount" as CustomFormatSource,
      params: {},
      type: "number",
      heading: { hasHeading: true, text: "Amount" },
    },
  ],
};

const defaultInvoiceData: CustomFormat["data"] = {
  columnConfigs: [
    {
      index: 0,
      source: "id" as CustomFormatSource,
      params: {},
      type: "text",
      heading: { hasHeading: true, text: "Invoice Number" },
    },
    {
      index: 1,
      source: "date" as CustomFormatSource,
      params: {},
      type: "date",
      heading: { hasHeading: true, text: "Invoice Date" },
    },
    {
      index: 2,
      source: "dueDate" as CustomFormatSource,
      params: {},
      type: "date",
      heading: { hasHeading: true, text: "Invoice Due Date" },
    },
    {
      index: 3,
      source: "tax" as CustomFormatSource,
      params: {},
      type: "number",
      heading: { hasHeading: true, text: "Tax Amount" },
    },
    {
      index: 4,
      source: "total" as CustomFormatSource,
      params: {},
      type: "number",
      heading: { hasHeading: true, text: "Invoice Total" },
    },
    {
      index: 5,
      source: "item" as CustomFormatSource,
      params: {},
      type: "text",
      heading: { hasHeading: true, text: "Line Item Description" },
    },
    {
      index: 6,
      source: "quantity" as CustomFormatSource,
      params: {},
      type: "number",
      heading: { hasHeading: true, text: "Line Item Quantity" },
    },
    {
      index: 7,
      source: "unitPrice" as CustomFormatSource,
      params: {},
      type: "number",
      heading: { hasHeading: true, text: "Unit Price per Item" },
    },
    {
      index: 8,
      source: "price" as CustomFormatSource,
      params: {},
      type: "number",
      heading: { hasHeading: true, text: "Line Item Total" },
    },
    {
      index: 9,
      source: "service" as CustomFormatSource,
      params: {},
      type: "text",
      heading: { hasHeading: true, text: "Service Description" },
    },
    {
      index: 10,
      source: "category" as CustomFormatSource,
      params: {},
      type: "text",
      heading: { hasHeading: true, text: "Item/Service Category" },
    },
    {
      index: 11,
      source: "customer" as CustomFormatSource,
      params: {},
      type: "text",
      heading: { hasHeading: true, text: "Customer/Bill To" },
    },
    {
      index: 12,
      source: "vendor" as CustomFormatSource,
      params: {},
      type: "text",
      heading: { hasHeading: true, text: "Vendor/From" },
    },
    {
      index: 13,
      source: "poNumber" as CustomFormatSource,
      params: {},
      type: "text",
      heading: { hasHeading: true, text: "Purchase Order Number" },
    },
  ],
};

const initialState: {
  customFormats: CustomFormat[];
  customFormatType: CustomFormatType;
  selected: null | CustomFormat;
  loading: boolean;
} = {
  customFormats: [],
  selected: null,
  loading: false,
  customFormatType: "bankStatement",
};

const slice = createSlice({
  name: "customFormat",
  initialState,
  reducers: {
    setcustomFormats(state, action: PayloadAction<CustomFormat[]>) {
      state.customFormats = action.payload;
    },
    setSelectedCustomFormat(state, action: PayloadAction<string>) {
      const cState = current(state);
      const type = cState.customFormatType;
      const selected = cState.customFormats.filter(
        (x) => x.name === action.payload
      )[0];

      if (selected) {
        state.selected = selected;
      } else {
        state.selected = {
          name: action.payload,
          data: type === "invoice" ? defaultInvoiceData : defaultData,
          id: action.payload
        }
      }
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    updateSelected(state, action: PayloadAction<CustomFormat | null>) {
      state.selected = action.payload;
    },
    setCustomFormatType(state, action: PayloadAction<CustomFormatType>) {
      state.customFormatType = action.payload;
    }
  },
});

export const {
  setcustomFormats,
  setSelectedCustomFormat,
  setLoading,
  updateSelected,
  setCustomFormatType
} = slice.actions;


export const createCustomFormatRedux = ({
  type,
  callback = (newFormatName = "") => { },
}: {
  type: CustomFormatType;
  callback?: (newFormatName) => void;
}) => async (dispatch, getState) => {
  const state: ReduxState = getState();
  try {
    const existingNames = state.CustomFormat.customFormats.map((x) => x.name);
    let nameIndex = 1;
    let name = `configuration ${nameIndex}`;

    while (existingNames.includes(name)) {
      nameIndex += 1;
      name = `configuration ${nameIndex}`;
    }

    const cf = await createCustomFormat(
      name,
      type === "invoice" ? defaultInvoiceData : defaultData,
      type
    );
    try {
      cf.data = JSON.parse(cf.data);
    } catch (err) {
      cf.data = { columnConfigs: [] };
    }

    dispatch(setcustomFormats([...state.CustomFormat.customFormats, cf]));
    dispatch(setSelectedCustomFormat(cf.name));

    const newCustomFormatName = `custom-${cf.name}` as any;

    if (type === "bankStatement") {
      dispatch(setSelectedExportType(newCustomFormatName));
      dispatch(setSelectedCustomConfigCSV({
        value: newCustomFormatName,
        label: cf.name.charAt(0).toUpperCase() + cf.name.slice(1)
      }));

      lsSetItem(lsKeySelectedExportType, JSON.stringify({
        value: newCustomFormatName,
      }));
      lsSetItem(lsKeySelectedCustomConfigCSV, JSON.stringify({
        value: newCustomFormatName,
        label: cf.name.charAt(0).toUpperCase() + cf.name.slice(1),
      }));
    }

    callback(newCustomFormatName);
  } catch (err) {
    //
  }
};

export const deleteSelectedCustomFormat = () => async (dispatch, getState) => {
  const state: ReduxState = getState();
  if (!state.CustomFormat.selected) {
    return;
  }
  try {
    const newFormats = state.CustomFormat.customFormats.filter(
      (x) => x.name !== state.CustomFormat.selected?.name
    );
    dispatch(setcustomFormats(newFormats));
    await deleteCustomFormat(state.CustomFormat.selected.id);

    const currentExportType = JSON.parse(lsGetItem(lsKeySelectedExportType) || '{}').value;
    if (currentExportType === `custom-${state.CustomFormat.selected.name}`) {
      lsRemoveItem(lsKeySelectedExportType);
      lsRemoveItem(lsKeySelectedCustomConfigCSV);
    }

    dispatch(
      setSelectedCustomFormat(newFormats.length > 0 ? newFormats[0].name : "")
    );
    if (newFormats.length === 0) {
      dispatch(updateSelected(null));
    }
  } catch (err) {
    //
  }
};

export const deleteCustomFormatItemRedux =
  (cfi: CustomFormatItem) => async (dispatch, getState) => {
    const state: ReduxState = getState();
    if (!state.CustomFormat.selected) {
      return;
    }

    const newSelected = {
      ...state.CustomFormat.selected,
      data: {
        ...state.CustomFormat.selected.data,
        columnConfigs: state.CustomFormat.selected.data.columnConfigs
          .filter((x) => x.index !== cfi.index)
          .map((x, i) => ({ ...x, index: i })),
      },
    };

    try {
      await updateCustomFormat(newSelected);
      dispatch(updateSelected(newSelected));
    } catch (err) {
      //
      dispatch(
        createAlert({
          message: "Error updating configuration",
          timeout: 0,
          type: "error",
        })
      );
    }
  };

export const addCustomFormatItemRedux = () => async (dispatch, getState) => {
  const state: ReduxState = getState();
  if (!state.CustomFormat.selected) {
    return;
  }

  const newCfi: CustomFormatItem = {
    index: state.CustomFormat.selected.data.columnConfigs.length,
    params: {},
    type: "date",
    source: "date" as CustomFormatSource,
  };
  const newSelected = {
    ...state.CustomFormat.selected,
    data: {
      ...state.CustomFormat.selected.data,
      columnConfigs: [
        ...state.CustomFormat.selected.data.columnConfigs,
        newCfi,
      ],
    },
  };

  try {
    await updateCustomFormat(newSelected);
    dispatch(updateSelected(newSelected));
  } catch (err) {
    //
    dispatch(
      createAlert({
        message: "Error updating configuration",
        timeout: 0,
        type: "error",
      })
    );
  }
};

export const updateCustomFormatName =
  (name: string) => async (dispatch, getState) => {
    const state: ReduxState = getState();
    if (!state.CustomFormat.selected) {
      return;
    }

    const newSelected = {
      ...state.CustomFormat.selected,
      name,
    };

    const newFormats = state.CustomFormat.customFormats.map((x) =>
      x.name === state.CustomFormat.selected?.name ? { ...x, name } : x
    );
    try {
      await updateCustomFormat(newSelected);
      dispatch(updateSelected(newSelected));
      dispatch(setcustomFormats(newFormats));
      dispatch(setSelectedCustomFormat(newSelected.name));
      lsSetItem(lsKeySelectedCustomConfigCSV, JSON.stringify({
        value: `custom-${newSelected.name}`,
        label: newSelected.name.charAt(0).toUpperCase() + newSelected.name.slice(1),
      }))
    } catch (err) {
      dispatch(
        createAlert({
          message: "Error updating configuration",
          timeout: 0,
          type: "error",
        })
      );
    }
  };

export const updateCustomFormatItemRedux =
  (cfi: CustomFormatItem) => async (dispatch, getState) => {
    const state: ReduxState = getState();
    if (!state.CustomFormat.selected) {
      return;
    }
    if (!state.CustomFormat.selected) {
      return;
    }

    const newSelected = {
      ...state.CustomFormat.selected,
      data: {
        ...state.CustomFormat.selected.data,
        columnConfigs: state.CustomFormat.selected.data.columnConfigs.map((x) =>
          x.index === cfi.index ? { ...x, ...cfi } : x
        ),
      },
    };

    try {
      await updateCustomFormat(newSelected);
      dispatch(updateSelected(newSelected));
    } catch (err) {
      dispatch(
        createAlert({
          message: "Error updating configuration",
          timeout: 0,
          type: "error",
        })
      );
    }
  };

type CustomFormatsProps = {
  init: boolean;
  type: 'bankStatement' | 'invoice'
}

export const fetchCustomFormats = ({
  init,
  type
}: CustomFormatsProps) => async (dispatch, getState) => {
  const state: ReduxState = getState();
  if (state.CustomFormat.loading) {
    return;
  }

  try {
    dispatch(setLoading(true));
    // const type = state.CustomFormat.customFormatType;
    const customFormats = await getCustomFormats({
      type
    });
    dispatch(setLoading(false));
    dispatch(
      setcustomFormats(
        customFormats.map((x) => {
          let data = { columnConfigs: [] };
          try {
            data = JSON.parse(x.data);
          } catch (err) {
            //
            console.error(err);
          }
          return { ...x, data };
        })
      )
    );
    if (customFormats.length > 0 && !state.CustomFormat.selected && init) {
      const cf = customFormats[0];
      dispatch(setSelectedCustomFormat(cf.name));
      dispatch(setSelectedExportType(`custom-${cf.name}` as any));
    }
  } catch (err) {
    dispatch(setLoading(false));
    //
  }
};

export default slice.reducer;

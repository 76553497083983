import styled from "@emotion/styled";

const ErrorMessage = styled.div`
  color: #e55353;
  font-size: 80%;
  margin-top: 0.25rem;
`;

export default ErrorMessage;


import React, { useEffect, useState } from "react";

// import CreatableSelect from "react-select/creatable";

import Select from "react-select";

import { FieldProps, getIn } from "formik";
import { FormGroup } from "react-bootstrap";
import ErrorMessage from "src/views/Docuclipper/ErrorMessage";

interface Option {
  readonly label: string;
  readonly value: string;
}

type Props = {
  type: "customer" | "vendor";
  value: Option | null;
  createFn: (newValue: string) => Promise<{ name: string; id: string }>;
  defaultOptions: Option[];
  onChange: (option: any) => any;
  isInvalid?: boolean;
};

export const QboSelect: React.FC<Props> = ({
  createFn,
  defaultOptions,
  onChange,
  type,
  value,
  isInvalid,
  ...rest
}) => {
  // const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(defaultOptions);
  const [selectedValue, setSelectedValue] = useState<Option | null>(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  useEffect(() => {
    setOptions(defaultOptions);
  }, [defaultOptions]);

  // const handleCreate = (inputValue: string) => {
  //   setIsLoading(true);

  //   createFn(inputValue)
  //     .then((rsp) => {
  //       const newOption = { label: rsp.name, value: rsp.id };
  //       setIsLoading(false);
  //       setOptions((prev) => [...prev, newOption]);
  //       setSelectedValue(newOption);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //     });
  // };

  return (
    // <CreatableSelect
    //   placeholder="Type to create a new item"
    //   {...rest}
    //   isClearable
    //   isDisabled={isLoading}
    //   isLoading={isLoading}
    //   onChange={(newValue) => {
    //     setSelectedValue(newValue ? newValue : null);
    //     onChange(newValue ? newValue.value : null);
    //   }}
    //   onCreateOption={handleCreate}
    //   options={options}
    //   value={selectedValue}
    //   formatCreateLabel={(val) => `Create ${type} "${val}"`}
    //   styles={{
    //     control: (base, state) => ({
    //       ...base,
    //       border: isInvalid ? '1px solid #e55353 !important' : '1px solid #ced4da',
    //       boxShadow: 'none',
    //       '&:hover': {
    //         border: isInvalid ? '1px solid #e55353 !important' : '1px solid #ced4da',
    //       }
    //     })
    //   }}
    // />
    <Select
      placeholder="Type to search an item"
      {...rest}
      isClearable
      onChange={(newValue) => {
        setSelectedValue(newValue ? newValue : null);
        onChange(newValue ? newValue.value : null);
      }}
      options={options}
      value={selectedValue}
      styles={{
        control: (base, state) => ({
          ...base,
          border: isInvalid
            ? "1px solid #e55353 !important"
            : "1px solid #ced4da",
          boxShadow: "none",
          "&:hover": {
            border: isInvalid
              ? "1px solid #e55353 !important"
              : "1px solid #ced4da",
          },
        }),
        menu: (provided) => ({
          ...provided,
          zIndex: 9999, // Add this line to increase the z-index of the dropdown
        }),
      }}
    />
  );
};

export const FormikQboSelect = ({
  createFn,
  onChangeFn,
  type,
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  value,
  ...props
}: FieldProps & {
  label: string;
  options: Array<{ value: string; label: string }>;
  createFn: any;
  onChangeFn?: any;
  type: "customer" | "vendor";
  value: any;
  style?: React.CSSProperties;
  dataCy?: string;
}) => {
  const { options } = props;
  const isInvalid = getIn(touched, field.name) && getIn(errors, field.name);
  const error = getIn(errors, field.name);

  return (
    <FormGroup
      style={props.style ? props.style : {}}
      data-cy={props.dataCy ? props.dataCy : ""}
    >
      {props.label && <label htmlFor={field.name}>{props.label}</label>}
      <QboSelect
        {...field}
        {...props}
        defaultOptions={options}
        value={value}
        onChange={(option) => {
          setFieldValue(field.name, option ? option : "");
          if (onChangeFn) {
            onChangeFn(option);
          }
        }}
        createFn={createFn}
        type={type}
        isInvalid={!!isInvalid}
      />
      {isInvalid && <ErrorMessage>{error}</ErrorMessage>}
    </FormGroup>
  );
};

import React, { useState, useEffect } from "react";
import { Table, Button, Badge, Form, Alert, Modal } from "react-bootstrap";
import { createMatchGroup, getPotentialMatches } from "src/docuclipper/api";
import PDFViewer from "../../PdfViewer";
import useInvoicePDF from "../useInvoicePDF";

interface Props {
  transactionLineId: number;
  onMatchCreated: () => void;
}

interface MatchResult {
  transactionLineId: number;
  documentType: string;
  vendorNormalized: string;
  amount: number;
  vendor_similarity: number;
  documentDate: string;
  documentId: string;
}

interface PotentialMatches {
  [documentType: string]: MatchResult[];
}

interface MatchResponse {
  potentialMatches: PotentialMatches;
  existingGroups: any[]; // Define proper type if needed
  requiredMatches: number;
}

export const PotentialMatchList: React.FC<Props> = ({
  transactionLineId,
  onMatchCreated,
}) => {
  const [selectedMatches, setSelectedMatches] = useState<number[]>([]);
  const [error, setError] = useState("");
  const [matches, setMatches] = useState<PotentialMatches>({});
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({
    vendorName: "",
    documentType: "",
    similarityThreshold: 0.3,
    limit: 10,
  });
  const [selectedRow, setSelectedRow] = useState<MatchResult | null>(null);
  const [showPdfModal, setShowPdfModal] = useState(false);

  const { pdfURL } = useInvoicePDF({
    selectedDocumentId: selectedRow?.documentId,
    transactionLine: selectedRow,
  });

  const fetchMatches = async () => {
    try {
      setLoading(true);
      setError("");

      const response = await getPotentialMatches(transactionLineId, {
        similarityThreshold: filters.similarityThreshold,
        limit: filters.limit,
        vendorName: filters.vendorName || undefined,
        documentType: filters.documentType || undefined,
      });
      setMatches(response.potentialMatches);
    } catch (err) {
      setError("Failed to fetch potential matches");
    } finally {
      setLoading(false);
    }
  };

  // Fetch matches on component mount and when filters change
  useEffect(() => {
    fetchMatches();
  }, [filters, transactionLineId]);

  const handleMatchSelect = (matchId: number) => {
    setSelectedMatches((prev) => {
      if (prev.includes(matchId)) {
        return prev.filter((id) => id !== matchId);
      }
      return [...prev, matchId];
    });
  };

  const handleCreateGroup = async () => {
    try {
      await createMatchGroup([transactionLineId, ...selectedMatches]);
      setSelectedMatches([]);
      onMatchCreated();
    } catch (err) {
      setError("Failed to create match group");
    }
  };

  return (
    <div className="mt-3">
      {error && <Alert variant="danger">{error}</Alert>}

      <div className="mb-3">
        <Form className="row g-3">
          <Form.Group className="col-md-3">
            <Form.Label>Vendor Name</Form.Label>
            <Form.Control
              type="text"
              value={filters.vendorName}
              onChange={(e) =>
                setFilters({ ...filters, vendorName: e.target.value })
              }
              placeholder="Filter by vendor"
            />
          </Form.Group>

          <Form.Group className="col-md-3">
            <Form.Label>Document Type</Form.Label>
            <Form.Control
              type="text"
              value={filters.documentType}
              onChange={(e) =>
                setFilters({ ...filters, documentType: e.target.value })
              }
              placeholder="Filter by document type"
            />
          </Form.Group>

          <Form.Group className="col-md-3">
            <Form.Label>Similarity Threshold</Form.Label>
            <Form.Control
              type="number"
              min="0"
              max="1"
              step="0.1"
              value={filters.similarityThreshold}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  similarityThreshold: parseFloat(e.target.value),
                })
              }
            />
          </Form.Group>

          <Form.Group className="col-md-3">
            <Form.Label>Result Limit</Form.Label>
            <Form.Control
              type="number"
              min="1"
              value={filters.limit}
              onChange={(e) =>
                setFilters({ ...filters, limit: parseInt(e.target.value) })
              }
            />
          </Form.Group>
        </Form>
      </div>

      {loading ? (
        <div className="text-center">
          <span>Loading matches...</span>
        </div>
      ) : (
        <>
          {Object.entries(matches).map(([docType, docMatches]) => (
            <div key={docType} className="mb-4">
              <h6>
                {docType}
                <Badge variant="info" className="ml-2">
                  {docMatches.length}
                </Badge>
              </h6>

              <Table responsive>
                <thead>
                  <tr>
                    <th>Select</th>
                    <th>Vendor</th>
                    <th>Amount</th>
                    <th>Similarity</th>
                    <th>Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {docMatches.map((match) => (
                    <tr
                      key={match.transactionLineId}
                      className={
                        selectedRow?.transactionLineId ===
                        match.transactionLineId
                          ? "table-active"
                          : ""
                      }
                    >
                      <td>
                        <Form.Check
                          type="checkbox"
                          checked={selectedMatches.includes(
                            match.transactionLineId
                          )}
                          onChange={() =>
                            handleMatchSelect(match.transactionLineId)
                          }
                        />
                      </td>
                      <td>{match.vendorNormalized}</td>
                      <td>
                        $
                        {match.amount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </td>
                      <td>{match.vendor_similarity * 100}%</td>
                      <td>
                        {new Date(match.documentDate).toLocaleDateString()}
                      </td>
                      <td>
                        <Button
                          variant="link"
                          onClick={() => {
                            setSelectedRow(match);
                            setShowPdfModal(true);
                          }}
                        >
                          View Document
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ))}

          <Button
            variant="primary"
            disabled={selectedMatches.length === 0}
            onClick={handleCreateGroup}
            className="mb-3"
          >
            Create Match Group
          </Button>
        </>
      )}

      <Modal
        show={showPdfModal}
        onHide={() => setShowPdfModal(false)}
        size="xl"
        fullscreen
        className="pdf-viewer-modal"
      >
        <Modal.Header
          closeButton
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          placeholder=""
        >
          <Modal.Title>
            {selectedRow?.vendorNormalized} - $
            {selectedRow?.amount.toLocaleString()}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {pdfURL ? (
            <PDFViewer url={pdfURL} />
          ) : (
            <div className="text-center text-muted mt-4">
              Loading document...
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

import React from "react";
import ColumnConfigModal from "../../../../Docuclipper/ColumnConfig/ColumnConfig";
import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../../../redux";
import { getCustomFormatHeading } from "../../../CustomFormat/CustomFormat";
type Props = { selectedExportType };
export const CsvColumnNames: React.FC<Props> = ({ selectedExportType }) => {
  const { customFormats } = useSelector(
    (state: ReduxState) => state.CustomFormat
  );
  let colnames = "";
  if (selectedExportType.startsWith("custom-")) {
    const cf = customFormats.filter(
      (x) => `custom-${x.name}` === selectedExportType
    );
    if (cf.length > 0) {
      colnames = cf[0].data.columnConfigs
        .map((x) => getCustomFormatHeading(x))
        .join(",");
    }
  } else if (selectedExportType === "xero-csv") {
    colnames = "*Date,*Amount,Payee,Description,Reference,Check Number";
  } else if (["csv", "sage-cloud-csv"].includes(selectedExportType)) {
    colnames = "Date, Description, Amount";
  } else if (selectedExportType === "sage-csv") {
    colnames =
      "Type,Account,Reference,Nominal A/C Ref,Date,Details,Net Amount,Tax Code,Tax Amount";
  } else if (selectedExportType === "myob") {
    colnames = "Date,Memo,Credit,Debit,Currency,Code";
  } else if (selectedExportType === "relate") {
    colnames = "Period,Date,Details,Debit,Credit,Balance,Cheque";
  } else if (selectedExportType === "netsuite") {
    colnames =
      "Date (MM/DD/YYYY),Payer or Payee name,Transaction ID,Transaction Type,Amount,Memo,NS Internal Customer ID,NS Customer Name,Invoice Number";
  } 
  return (
    <Row className="align-items-center p-3">
      {(selectedExportType !== 'csv' && !selectedExportType.startsWith("custom-")) && (
        <>
          <strong>Columns:</strong>
          <span className="ml-1 mr-5">{colnames}</span>
        </>
      )}
      <ColumnConfigModal selectedExportType={selectedExportType} />
    </Row>
  );
};
export default CsvColumnNames;

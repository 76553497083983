import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
  useRef,
} from "react";
import { Button, Col, Row, Modal } from "react-bootstrap";
import Switch from "react-switch";
import Split from "react-split";
import {
  EditableFieldAmount,
  EditableFieldDateYYYYMMDD,
  EditableFieldText,
} from "../../../../utils/EditableField";
// import NewTransactionTable from "./NewTransactionTable";
import { datefilterParamsYYYYMMDD } from "../../Analyze/AnalyzeTransactionsTable";
import {
  useChunkData,
  useTransactions,
} from "../../Analyze/AnalyzeTransactionsTable/hooks";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../../redux";
import {
  fetchMfs,
  fetchTls,
  setSelectedChunk,
  setSelectedFieldNames,
  setSelectedTransactionStatus,
  setTls,
  toggleLoading,
  setRefreshKey,
  setAccountChunks,
  setSelectedDocumentId,
  setSelectedAccount,
} from "../../../../redux/reducers/TransactionManager";
import { fetchTotals } from "../../../../redux/reducers/Reconciler";
import { createCorrection, performBulkEdit } from "../../../../docuclipper/api";
import { createAlert } from "../../../../redux/reducers/Alerts";
import { isArray } from "lodash";
import SummaryTableAccountViewHorizontal2 from "./SummaryTableAccountViewHorizontal2";
import { getDocumentName } from "../../Analyze/AnalyzeTransactionsTable/utils";
import ExternalFilter from "src/views/Docuclipper/ExternalFilter";
import {
  setExternalFiltersColumnDefs,
  clearExternalFilters,
} from "src/redux/reducers/ExternalFilter";
import PDFViewer from "../../PdfViewer";
import useBankStatementPDF from "./useBankStatementPDF";
import { isNullOrUndefined, useHasQbo } from "src/utils/utils";
import { GridReadyEvent } from "ag-grid-community";
import { IDatasource } from "ag-grid-community";
import ColumnSelector from "./ColumnSelector";
import {
  setColumnWidth,
  setColumns,
} from "../../../../redux/reducers/AgGridColumnConfig";
import PayeeCleanerConfig from "./PayeeCleanerConfig";
import { AddTransactionModal } from "./AddTransaction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SelectAccount2 from "./ReassignAccount/SelectAccount2";
import AgBulkTable from "../../Analyze/AnalyzeTransactionsTable/AgBulkTable";
import { TableFilterConfig } from "src/docuclipper/DocuclipperTypes";

type Props = {};
export const NewTransactionManager: React.FC<Props> = () => {
  const job = useSelector((state: ReduxState) => state.JobData.job);
  const { data: jobDocs } = useSelector(
    (state: ReduxState) => state.JobDocument
  );

  const {
    selectedDocumentId,
    tls,
    selectedChunk,
    // accountChunkLabels,
    selectedAccount,
    pagination,
    refreshKey,
    // accountChunks,
  } = useSelector((state: ReduxState) => state.TransactionManager);

  // const isCSV = job?.metadata?.includes("csv2QboMapping");
  // console.log({ jobDocs });

  const selectedDoc = jobDocs.filter(
    (x) => x.documentId.toString() === (selectedDocumentId || "").toString()
  );
  const isCSV =
    selectedDoc.length > 0
      ? selectedDoc[0].mimetype.includes("pdf")
        ? false
        : true
      : false;

  // console.log({ isCSV, selectedDoc, jobDocs, selectedDocumentId });
  // const chunkData = useChunkData();
  const dispatch = useDispatch();

  const { externalFilters } = useSelector(
    (state: ReduxState) => state.ExternalFilter
  );

  const PageNumberCellRenderer = (props) => {
    if (!props.data) return "";
    return <span>{parseInt(props.data.pageNumber.toString(), 10) + 1}</span>;
  };

  const SwitchCellRenderer = (props) => {
    const [included, setIncluded] = React.useState(
      props.data ? props.data.included : false
    );

    if (!props.data) return "";
    const { documentId, fieldName, rowNumber } = props.data;

    return (
      <Switch
        key={included}
        checked={included}
        onChange={(e) => {
          if (job) {
            dispatch(toggleLoading());
            createCorrection(
              job.id.toString(),
              documentId,
              fieldName,
              rowNumber,
              -1,
              null,
              e,
              false,
              false
            )
              .then(({ transactions }) => {
                // dispatch(setRefreshKey());
                setIncluded(!included);
                dispatch(toggleLoading());
                // dispatch(setTls({ transactions, resetPages: false }));
                dispatch(fetchMfs());
                dispatch(fetchTotals());

                dispatch(
                  createAlert({
                    message: "Included transactions updated successfully",
                    timeout: 0,
                    type: "success",
                  })
                );
              })
              .catch(() => {
                dispatch(toggleLoading());
                dispatch(
                  createAlert({
                    message: "Error updating included transactions",
                    timeout: 0,
                    type: "error",
                  })
                );
              });
          }
        }}
        onColor="#86d3ff"
        onHandleColor="#2693e6"
        handleDiameter={15}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={10}
        width={30}
        className="react-switch"
        id="material-switch"
      />
    );
  };

  const EditableQboField = (props) => {
    const { field, fieldType, value } = props;

    const [value2, setValue2] = React.useState(value);

    if (!props.data) return "";

    const createCorrectionHelper = (
      newText,
      toggleSign,
      divideBy100,
      multilineIndex
    ) => {
      const values = props.data;
      const { documentId, fieldName, rowNumber, columnMapping, included } =
        values;

      if (!job) {
        // console.warn("No job found, cannot create correction");
        return;
      }

      // console.log("Creating correction with params:", {
      //   jobId: job.id,
      //   documentId,
      //   fieldName,
      //   rowNumber,
      //   columnIndex: columnMapping[props.field],
      //   newText,
      //   included,
      //   toggleSign,
      //   divideBy100,
      //   multilineIndex,
      // });
      dispatch(toggleLoading());
      createCorrection(
        job.id as any,
        documentId,
        fieldName,
        rowNumber,
        columnMapping[props.field],
        newText,
        included,
        toggleSign,
        divideBy100,
        multilineIndex
      )
        .then(({ transactions }) => {
          dispatch(toggleLoading());
          // // dispatch(setTls({ transactions, resetPages: false }));
          // dispatch(setRefreshKey());

          dispatch(fetchTotals());
          dispatch(fetchMfs());
          dispatch(
            createAlert({
              message: "Transaction fixed",
              timeout: 0,
              type: "success",
            })
          );
          if (gridApi.current) {
            gridApi.current.purgeInfiniteCache();
          }
        })
        .catch(() => {
          dispatch(toggleLoading());
          dispatch(
            createAlert({
              message: "Error fixing transaction",
              timeout: 0,
              type: "error",
            })
          );
        });
    };

    if (fieldType === "date") {
      return (
        <EditableFieldDateYYYYMMDD
          onSave={(newValue) => {
            createCorrectionHelper(newValue, false, false, null);
          }}
          value={value}
        />
      );
    } else if (fieldType === "number") {
      if (["amount", "balance", "debit", "credit"].includes(field)) {
        return (
          <Row className="align-items-center justify-content-between">
            <Col>
              <EditableFieldAmount
                key={value2}
                onSave={(newValue) => {
                  createCorrectionHelper(newValue, false, false, null);
                }}
                value={value2}
              />
            </Col>
            <Col className="px-0">
              <Button
                className="p-0"
                variant="light"
                size="sm"
                onClick={() => {
                  createCorrectionHelper("", true, false, null);
                }}
              >
                +/-
              </Button>
              <Button
                className="p-0 ml-2"
                variant="light"
                size="sm"
                onClick={() => {
                  createCorrectionHelper("", false, true, null);
                }}
              >
                .00
              </Button>
            </Col>
          </Row>
        );
      } else {
        return (
          <EditableFieldAmount
            onSave={(newValue) =>
              createCorrectionHelper(newValue, false, false, null)
            }
            value={value}
          />
        );
      }
    } else {
      // text
      if (isArray(value)) {
        const concatenatedValue = value.join(" ");
        return (
          <EditableFieldText
            onSave={(newValue) => {
              createCorrectionHelper(newValue, false, false, 0);
            }}
            value={concatenatedValue}
            multiline={true}
            placement="top"
          />
        );
      } else {
        return (
          <EditableFieldText
            onSave={(newValue) =>
              createCorrectionHelper(newValue, false, false, null)
            }
            value={value}
          />
        );
      }
    }
  };

  const defaultColumnDefs = [
    {
      field: "selection",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 40,
      minWidth: 40,
      maxWidth: 40,
      pinned: "left",
      lockPosition: true,
      suppressMenu: true,
      index: -1,
    },
    {
      field: "included",
      headerName: "Included",
      minWidth: 100,
      maxWidth: 100,
      cellRenderer: SwitchCellRenderer,
      cellClass: "ag-grid-cell-included",
      index: 0,
    },
    {
      field: "pageNumber",
      headerName: "PN",
      filter: "agNumberColumnFilter",
      sortable: true,
      minWidth: 75,
      maxWidth: 75,
      cellRenderer: PageNumberCellRenderer,
      index: 1,
    },
    {
      key: "documentDate",
      field: "date",
      filter: "agDateColumnFilter",
      filterParams: datefilterParamsYYYYMMDD,
      sortable: true,
      minWidth: 150,
      cellRenderer: EditableQboField,
      cellRendererParams: {
        field: "date",
        fieldType: "date",
      },
      cellClass: "ag-grid-cell-date",
      configurable: true,
      index: 2,
    },
    {
      key: "documentDate2",
      field: "date2",
      filter: "agDateColumnFilter",
      filterParams: datefilterParamsYYYYMMDD,
      sortable: true,
      minWidth: 150,
      cellRenderer: EditableQboField,
      cellRendererParams: {
        field: "date2",
        fieldType: "date",
      },
      configurable: true,
      index: 3,
    },
    {
      key: "documentPayee",
      field: "payee",
      filterParams: {},
      minWidth: 200,
      cellRenderer: EditableQboField,
      cellRendererParams: {
        field: "payee",
        fieldType: "text",
      },
      configurable: true,
      index: 4,
    },
    {
      key: "documentDescription",
      field: "description",
      filterParams: {},
      minWidth: 400,
      cellRenderer: EditableQboField,
      cellRendererParams: {
        field: "name",
        fieldType: "text",
      },
      configurable: true,
      index: 5,
    },
    {
      key: "documentAmount",
      field: "amount",
      sortable: true,
      minWidth: 250,
      cellRenderer: EditableQboField,
      cellRendererParams: {
        field: "amount",
        fieldType: "number",
      },
      configurable: true,
      index: 6,
      filter: "agNumberColumnFilter", // Make sure this is set correctly
      // filterParams: {
      //   filterOptions: ['equals', 'lessThan', 'greaterThan', 'inRange'],
      //   defaultOption: 'equals'
      // }
    },
    {
      key: "documentBalance",
      field: "balance",
      filter: "agNumberColumnFilter", // Make sure this is set correctly
      filterParams: {},
      minWidth: 250,
      cellRenderer: EditableQboField,
      cellRendererParams: {
        field: "balance",
        fieldType: "number",
      },
      configurable: true,
      index: 7,
    },
    {
      key: "documentCheckNumber",
      field: "checkNumber",
      filterParams: {},
      minWidth: 150,
      cellRenderer: EditableQboField,
      cellRendererParams: {
        field: "checkNumber",
        fieldType: "text",
      },
      configurable: true,
      index: 8,
    },
  ];

  const reduxColumnDefs =
    useSelector(
      (state: ReduxState) => state.AgGridColumnConfig.transactionManager
    ) || [];

  const initialColumnDefs = useMemo(() => {
    if (reduxColumnDefs.length === 0) {
      const defaultColumns = defaultColumnDefs.map((col) => ({
        ...col,
        hide: false,
        width: (col as any).width,
      }));

      dispatch(
        setColumns({
          tableId: "transactionManager",
          columns: defaultColumns as any,
        })
      );
      return defaultColumns;
    }

    // Sort columns by index
    return reduxColumnDefs
      .slice()
      .sort((a, b) => (a as any).index - (b as any).index) // Sort by index
      .map((reduxCol) => {
        const defaultCol = defaultColumnDefs.find(
          (col) => col.field === reduxCol.field
        );
        return {
          ...defaultCol,
          hide:
            reduxCol.hide !== undefined
              ? reduxCol.hide
              : (defaultCol as any).hide,
          width:
            reduxCol.width !== undefined
              ? reduxCol.width
              : (defaultCol as any).width,
        };
      });
  }, [reduxColumnDefs, dispatch]);

  const defaultColDef = React.useMemo(() => {
    return {
      resizable: true,
      headerCheckboxSelectionFilteredOnly: true,
      filter: true,
    };
  }, []);

  const { totalsByChunk } = useSelector(
    (state: ReduxState) => state.Reconciler
  );

  useEffect(() => {
    return () => {
      dispatch(setSelectedDocumentId(null)); // Assuming this action exists
      dispatch(setSelectedChunk(null));
      dispatch(setSelectedAccount(null));
    };
  }, []);

  // console.log({ accountChunks });
  const { documents } = tls;

  // console.log({ job });
  React.useEffect(() => {
    // console.log("fetching totals");
    dispatch(fetchMfs());
    dispatch(fetchTotals());
  }, [job]);
  const gridApi = useRef<any>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  const externalFilterColumns = React.useMemo(
    () => [
      {
        field: "pageNumber",
        label: "Page Number",
        filter: true,
        filterType: "number",
        defaultFilterType: "equals",
      },
      {
        field: "description",
        label: "Description",
        filter: true,
        configurable: true,
        filterType: "text",
        defaultFilterType: "contains",
      },
      {
        field: "amount",
        label: "Amount",
        filter: true,
        filterType: "number",
        defaultFilterType: "equals",
        configurable: true,
      },
      {
        field: "date",
        label: "Date",
        filter: true,
        filterType: "date",
        defaultFilterType: "equals",
        configurable: true,
      },
      {
        field: "date2",
        label: "Date 2",
        filter: true,
        filterType: "date",
        defaultFilterType: "equals",
      },
      {
        field: "balance",
        label: "Balance",
        filter: true,
        filterType: "number",
        defaultFilterType: "equals",
      },
      {
        field: "checkNumber",
        label: "Check Number",
        filter: true,
        filterType: "text",
        defaultFilterType: "equals",
      },
    ],
    []
  );

  const paginationPageSizeSelector = React.useMemo(() => {
    return [20, 50, 100];
  }, []);

  useEffect(() => {
    dispatch(clearExternalFilters());
    dispatch(
      setExternalFiltersColumnDefs({
        externalFilterColumnDefs: initialColumnDefs,
      })
    );
  }, []);

  const { pdfURL } = useBankStatementPDF({
    selectedDocumentId,
  });

  const onColumnResized = useCallback(
    (event) => {
      if (event.finished) {
        const columnState = event.columnApi.getColumnState();

        columnState.forEach((column) => {
          if (column.width) {
            dispatch(
              setColumnWidth({
                tableId: "transactionManager",
                field: column.colId,
                width: column.width,
              })
            );
          }
        });
      }
    },
    [dispatch]
  );

  const hasQbo = useHasQbo();

  React.useEffect(() => {
    if (
      !isNullOrUndefined(selectedAccount) &&
      !isNullOrUndefined(selectedDocumentId) &&
      (selectedAccount || "") in totalsByChunk &&
      (selectedDocumentId || "") in totalsByChunk[selectedAccount || ""]
    ) {
      const chunksInAccount =
        totalsByChunk[selectedAccount || ""][selectedDocumentId || ""];
      const chunks = Object.keys(chunksInAccount);

      dispatch(setAccountChunks(chunks));
      if (chunks.length > 0) {
        dispatch(setSelectedChunk(chunks[0]));
      }
    }
  }, [selectedAccount, selectedDocumentId, totalsByChunk]);

  // console.log({ selectedAccount, selectedChunk });
  const getRows = useCallback(
    (params) => {
      const convertFilterModel = (): TableFilterConfig[] => {
        return Object.entries(params.filterModel).map(
          ([field, filter]: [string, any]) => {
            // Get the actual filter type from the filter object
            const filterType = filter.filterType || "text"; // Default to 'text' if filterType is not specified
            const operationType = filter.type; // This is the operation (equals, contains, lessThan, etc.)

            // Initialize the filter object
            const filterOutput = {
              field,
              operator: operationType, // The operation (equals, contains, lessThan, etc.)
              valueFrom: undefined,
              valueTo: undefined,
              value: undefined,
            };

            if (filterType === "number") {
              // Handle number filters
              if (operationType === "inRange") {
                filterOutput.valueFrom = filter.filter; // For range filters
                filterOutput.valueTo = filter.filterTo;
              } else if (
                operationType === "blank" ||
                operationType === "notBlank"
              ) {
                filterOutput.value = undefined; // No specific value for blank/not blank
              } else {
                filterOutput.value = filter.filter; // Single value filters
              }
            } else if (filterType === "date") {
              // Handle date filters
              if (operationType === "inRange") {
                filterOutput.valueFrom = filter.dateFrom; // Date filters use dateFrom
                filterOutput.valueTo = filter.dateTo; // and dateTo
              } else if (
                operationType === "blank" ||
                operationType === "notBlank"
              ) {
                filterOutput.value = undefined; // No specific value for blank/not blank
              } else {
                filterOutput.value = filter.dateFrom; // Single value date filters use dateFrom
              }
            } else {
              // Handle text filters and other types
              filterOutput.value = filter.filter; // Default case
            }

            return filterOutput;
          }
        );
      };

      const convertSortModel = (sortModel: any[]) => {
        return sortModel.map((sort) => ({
          field: sort.colId,
          direction: sort.sort.toUpperCase(),
        }));
      };

      dispatch(
        fetchTls(false, {
          pagination: {
            page: Math.floor(params.startRow / pagination.pageSize) + 1,
            pageSize: params.endRow - params.startRow,
          },
          filters: [
            ...(selectedDocumentId
              ? [
                  {
                    field: "documentId",
                    operator: "equals" as const,
                    value: selectedDocumentId,
                  },
                ]
              : []),
            ...(selectedAccount
              ? [
                  {
                    field: "account",
                    operator: "equals" as const,
                    value: selectedAccount,
                  },
                ]
              : []),
            ...(selectedChunk
              ? [
                  {
                    field: "chunk",
                    operator: "equals" as const,
                    value: selectedChunk,
                  },
                ]
              : []),
            ...convertFilterModel(),
          ],
          sorting: convertSortModel(params.sortModel),
          successCallback: params.successCallback,
          documents,
          hasQbo,
          job,
        })
      );
    },
    [
      dispatch,
      pagination.pageSize,
      selectedDocumentId,
      selectedAccount,
      selectedChunk,
      documents,
      hasQbo,
      job,
      refreshKey,
      gridApi.current,
    ]
  );

  const dataSource: IDatasource = useMemo(
    () => ({
      rowCount: undefined,
      getRows,
    }),
    [getRows]
  );

  useEffect(() => {
    if (!gridApi.current) {
      return;
    }

    if (
      !job ||
      ["InProgress", "Finishing", "Canceled"].includes(job.status) ||
      isNullOrUndefined(selectedDocumentId) ||
      isNullOrUndefined(selectedAccount) ||
      isNullOrUndefined(selectedChunk)
    ) {
      return;
    }

    gridApi.current.setDatasource(dataSource);
  }, [
    job,
    selectedDocumentId,
    selectedAccount,
    selectedChunk,
    refreshKey,
    externalFilters,
    dataSource,
  ]);

  const [showColumnSelectorModal, setShowColumnSelectorModal] = useState(false);

  const handleShow = () => setShowColumnSelectorModal(true);
  const handleClose = () => setShowColumnSelectorModal(false);

  const tableId = "transactionManager";

  const bulkEditHelper = (operation: string, transactionIds: any[]) => {
    if (!job) {
      return Promise.resolve();
    }
    dispatch(toggleLoading());
    return performBulkEdit({
      jobId: job.id,
      operation,
      params: {},
      transactionIds,
    })
      .then(({ transactions }) => {
        // dispatch(setRefreshKey());
        dispatch(toggleLoading());
        // dispatch(setTls({ transactions, resetPages: false }));
        dispatch(fetchMfs());
        dispatch(fetchTotals());

        if (gridApi.current) {
          gridApi.current.purgeInfiniteCache();
        }

        dispatch(
          createAlert({
            message: "Correction made successfully",
            timeout: 0,
            type: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(toggleLoading());
        dispatch(
          createAlert({
            message: "Error making correction",
            timeout: 0,
            type: "error",
          })
        );
      });
  };

  const handleSelectAll = useCallback(() => {
    if (!gridApi.current) return;

    // Get the total row count from the grid
    const totalRows = gridApi.current.getInfiniteRowCount();
    if (totalRows === undefined || totalRows <= 0) return;

    // For infinite grids, we need to select rows as they are loaded
    gridApi.current.forEachNode((node) => {
      node.setSelected(true);
    });

    // // Optionally, we can also force the grid to load all rows and select them
    // // This depends on your use case and data size
    // gridApi.current.ensureIndexVisible(totalRows - 1, "bottom");
  }, []);

  const handleClearSelection = useCallback(() => {
    if (!gridApi.current) return;
    gridApi.current.deselectAll();
  }, []);

  // console.log({ page: pagination.page });
  return (
    <div>
      <Row>
        <Col md="12">
          <div className="new-account-summary pl-3 pr-2">
            <SummaryTableAccountViewHorizontal2 />
          </div>
        </Col>
      </Row>
      <Row>
        <Col md="12" className="new-transactions">
          <Split
            sizes={isCSV ? [0, 100] : [40, 60]}
            direction="horizontal"
            style={{ display: "flex", height: "90vh" }}
          >
            {!isCSV && (
              <div style={{ width: "100%" }} className="mr-2">
                <PDFViewer
                  key={`${selectedDocumentId}-${job?.id}`}
                  url={pdfURL}
                  height={"90vh"}
                />
              </div>
            )}
            <div style={{ width: "100%" }} className="ml-4 transaction-manager">
              <Row>
                <Col md="9">
                  <Row>
                    <span className="mr-2">Filter by</span>
                    <ExternalFilter
                      columnDefs={externalFilterColumns}
                      gridApi={gridApi.current}
                    />
                  </Row>
                </Col>
              </Row>

              <Modal show={showColumnSelectorModal} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Configure Columns</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ColumnSelector tableId={tableId} />
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>

              <AgBulkTable
                onSetApi={(api) => (gridApi.current = api)}
                columnDefs={initialColumnDefs}
                bulkOpsClassName={"new-transactions-bulk-operations"}
                renderBulkOps={(data, setSelectedRowsIds) => {
                  const transactionIds = data.map((x) => x.id);
                  const hasSelection = data.length > 0;

                  return (
                    <>
                      <Row className="justify-content-between mb-2 bulk-edit-buttons">
                        <Col md="10">
                          <Row>
                            <div className="d-flex align-items-center mr-5">
                              <Button
                                variant="light"
                                onClick={handleSelectAll}
                                className="m-1"
                              >
                                <FontAwesomeIcon icon="check-square" />
                                <span className="ml-1">Select All</span>
                              </Button>

                              <Button
                                variant="light"
                                onClick={handleClearSelection}
                                className="m-1"
                                disabled={!hasSelection}
                              >
                                <FontAwesomeIcon icon="times" />
                                <span className="ml-1">Clear Selection</span>
                              </Button>

                              {hasSelection && (
                                <span className="ml-3">
                                  {data.length} rows selected
                                </span>
                              )}
                            </div>

                            <div className="d-flex align-items-center">
                              <SelectAccount2
                                transactionIds={transactionIds}
                                setSelectionError={() => null}
                                onDone={() => setSelectedRowsIds([])}
                                disabled={!hasSelection}
                              />
                              <Button
                                disabled={!hasSelection}
                                variant="light"
                                onClick={() =>
                                  bulkEditHelper(
                                    "switchSigns",
                                    transactionIds
                                  ).then(() => {
                                    if (setSelectedRowsIds) {
                                      setSelectedRowsIds({});
                                    }
                                  })
                                }
                                className="mx-1"
                              >
                                <FontAwesomeIcon icon="random" />
                                <span className="ml-1">
                                  Switch credits/debits
                                </span>
                              </Button>
                              <Button
                                disabled={!hasSelection}
                                variant="light"
                                onClick={() =>
                                  bulkEditHelper(
                                    "switchIncludedExcluded",
                                    transactionIds
                                  ).then(() => {
                                    if (setSelectedRowsIds) {
                                      setSelectedRowsIds({});
                                    }
                                  })
                                }
                                className="mx-1"
                              >
                                <FontAwesomeIcon icon="random" />
                                <span className="ml-1">
                                  Switch included/excluded
                                </span>
                              </Button>
                            </div>
                          </Row>
                        </Col>
                        <Col md="2">
                          <AddTransactionModal
                            buttonVariant="light"
                            extended={true}
                            onTransactionAdded={() => {
                              if (gridApi.current) {
                                gridApi.current.purgeInfiniteCache();
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    </>
                  );
                }}
                hasExport={false}
                height={"75vh"}
                width={"100%"}
                defaultColDef={defaultColDef}
                colResizeDefault="shift"
                singleClickEdit
                rowModelType={"infinite"}
                onColumnResized={onColumnResized}
                cacheBlockSize={pagination.pageSize}
                cacheOverflowSize={2}
                maxConcurrentDatasourceRequests={1}
                infiniteInitialRowCount={1}
                maxBlocksInCache={2}
                pagination={true}
                paginationPageSize={pagination.pageSize}
              ></AgBulkTable>
            </div>
          </Split>
        </Col>
      </Row>
    </div>
  );
};
export default NewTransactionManager;

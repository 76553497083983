import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { logUserIn } from "../../../auth";
import { updateProfile } from "../../../docuclipper/api";
import { ReduxState } from "../../../redux";
import { createAlert } from "../../../redux/reducers/Alerts";
import { closeModal } from "../../../redux/reducers/ModalManager";
import AlertBanner from "../../Docuclipper/AlertBanner/AlertBanner";
import { SignupFields } from "../Signup/SignupB2C";

type Props = {};

// redux

export const AWSOnboardingModal: React.SFC<Props> = () => {
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();
  const toggle = () => dispatch(closeModal("awsOnboarding"));

  const onClick = async ({
    firstName,
    lastName,
    workEmail,
    password,
  }: {
    firstName: string;
    lastName: string;
    workEmail: string;
    password: string;
  }) => {
    try {
      setLoading(true);
      const rsp = await updateProfile({
        firstName,
        lastName,
        email: workEmail,
        password,
      });

      if (rsp.token) {
        logUserIn(rsp.token);
      }
      toggle();
    } catch (err) {
      dispatch(
        createAlert({
          message: err.message || "Error updating profile",
          timeout: 0,
          type: "error",
        })
      );
      setLoading(false);
    }
  };

  const { activeModal } = useSelector(
    (state: ReduxState) => state.ModalManager
  );
  return (
    <Modal onHide={toggle} show={activeModal === "awsOnboarding"}>
      <Modal.Body>
        <div className="h4 font-weight-bold text-center m-4">
          Welcome to DocuClipper!
        </div>
        <div className="h5 text-muted text-center m-4">
          Let's set up your account
        </div>
        <div className="mt-2 mb-2">
          <AlertBanner />
        </div>
        <SignupFields
          btnCta="Set up account"
          onClick={onClick}
          loading={loading}
        />
      </Modal.Body>
    </Modal>
  );
};

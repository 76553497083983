import React from "react";
import { Badge, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { updateJob } from "../../../../docuclipper/api";
import { ReduxState } from "../../../../redux";
import EditableText from "../../../Docuclipper/EditableText";
import { EditText } from "react-edit-text";
import "react-edit-text/dist/index.css";
import EditJobTags from "../../../Docuclipper/Tags/EditJobTags";
import { ReconciledNo, ReconciledYes } from "../ReconciledBadge";
import ClipLoader from "react-spinners/ClipLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  EditableFieldRequiredText,
  EditableFieldText,
} from "../../../../utils/EditableField";
import { useIsAdmin } from "src/utils/auth";

type Props = {};
export const JobHeader: React.FC<Props> = ({}) => {
  const { isAdmin, isSuperLogin, isSupportRep } = useIsAdmin();
  const { job, progress } = useSelector((state: ReduxState) => state.JobData);
  const { reconciled, loading, badDates } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );

  let jobIsReconciled = false;

  if (Object.keys(reconciled.documentIds).length > 0) {
    jobIsReconciled = Object.keys(reconciled.documentIds).reduce(
      (acc, x) => reconciled.documentIds[x] && acc,
      true
    );
  }

  let jobHasBadDates = false;

  if (Object.keys(badDates.documentIds).length > 0) {
    jobHasBadDates = Object.keys(badDates.documentIds).reduce(
      (acc, x) => badDates.documentIds[x] || acc,
      false
    );
  }

  let jobName = "";
  if (job && job.name) {
    jobName = job.name;
  } else if (job && job.template && job.template.name) {
    jobName = job.template.name;
  }

  return (
    <>
      <Row className="align-items-center justify-content-between">
        <div className="text-truncate">
          {/* <EditText
          defaultValue={jobName}
          showEditButton={true}
          onSave={({ name, value, previousValue }) => {
            if (job) {
              updateJob(job.id, { name: value }).catch((err) => null);
            }
          }}
        /> */}
          <div className="mx-2">
            <Row className="align-items-center mx-2">
              <div className="h2">
                {/* <EditableText
                  initialValue={jobName}
                  onConfirm={(name) => {
                    if (job) {
                      updateJob(job.id, { name }).catch((err) => null);
                    }
                  }}
                /> */}

                <EditableFieldRequiredText
                  value={jobName}
                  onSave={(name) => {
                    if (job) {
                      updateJob(job.id, { name }).catch((err) => null);
                    }
                  }}
                />
              </div>
              {!loading && (isAdmin || isSuperLogin || isSupportRep) && (
                <>
                  {JSON.parse(job?.metadata || "{}").algoVersion === "v1" && (
                    <Badge
                      style={{ fontSize: "1.2rem" }}
                      className="m-2"
                      variant="info"
                    >
                      V1
                    </Badge>
                  )}
                  {JSON.parse(job?.metadata || "{}").algoVersion === "v2" && (
                    <Badge
                      style={{ fontSize: "1.2rem" }}
                      className="m-2"
                      variant="warning"
                    >
                      V2
                    </Badge>
                  )}
                </>
              )}
              {loading && <ClipLoader />}
              {!loading && job?.type === "ExtractData" && !job?.isGeneric && (
                <>
                  {jobIsReconciled ? (
                    <ReconciledYes type="job" />
                  ) : (
                    <ReconciledNo type="job" />
                  )}
                </>
              )}

              {!loading && (
                <>
                  {jobHasBadDates && (
                    <Badge
                      variant="warning"
                      className="mt-1 ml-3 p-1 pr-2 text-white"
                    >
                      <FontAwesomeIcon
                        color="white"
                        icon="times"
                      ></FontAwesomeIcon>
                      <span className="ml-1">Bad date</span>
                    </Badge>
                  )}
                </>
              )}
              {!loading && (
                <div style={{ minWidth: "300px" }} className="ml-2">
                  <EditJobTags />
                </div>
              )}
            </Row>
          </div>
        </div>

        <div className="mx-5">
          {job && job.template && job.template.smallImageUrl && (
            <img
              src={job?.template?.smallImageUrl}
              alt="Template logo"
              height="64px"
            />
          )}
        </div>
      </Row>
    </>
  );
};
export default JobHeader;

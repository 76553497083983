import React from "react";
type Props = {
  title: string;
  description: string;
  headerClassName?: string;
  topClassName?: string;
};
export const PageHeader: React.SFC<Props> = ({
  title,
  description,
  children,
  topClassName,
  headerClassName,
}) => {
  return (
    <div className={topClassName}>
      {title && (
        <>
          <div className={`${headerClassName} mb-4 font-weight-bold`}>
            {title}
          </div>
          <div className="h6 text-muted">
            {description}
          </div>
        </>
      )}
      {children}
    </div>
  );
};
PageHeader.defaultProps = {
  headerClassName: "h2",
  topClassName: "p-4",
};
export default PageHeader;

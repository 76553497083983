import { Alert } from "../../docuclipper/DocuclipperTypes";

const initialState = {
  alert: null,
};
export const CREATE_ALERT = "create-alert";
export const REMOVE_ALERT = "remove-alert";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_ALERT:
      return {
        ...state,
        alert: action.payload.alert,
      };
    case REMOVE_ALERT:
      return {
        ...state,
        alert: null,
      };
    default:
      return state;
  }
}

export type CreateAlertFn = (alert: Alert) => any;
export const createAlert = (alert: Alert) => ({
  type: CREATE_ALERT,
  payload: {
    alert,
  },
});

export type RemoveAlertFn = () => any;
export const removeAlert = () => ({
  type: REMOVE_ALERT,
  payload: {},
});

import { getJwt } from "../auth";

export default class WebSocketsHandler {
  private socket: WebSocket;
  private handlers: object = {};

  constructor() {
    // console.log("connecting WS");
    this.handlers = {};
    const protocol = window.location.protocol === "http:" ? "ws" : "wss";
    let host = window.location.host;
    if (window.location.host === "localhost:3000") {
      host = "localhost:8080";
    }
    this.socket = new WebSocket(`${protocol}://${host}/api/v1/ws`);
    this.socket.onopen = () => {
      this.socket.send(JSON.stringify({ jwt: getJwt() }));
    };
    this.handleMessage = this.handleMessage.bind(this);
    this.socket.onmessage = this.handleMessage;
  }

  public disconnect() {
    this.socket.close();
  }

  public registerHandler(messageType: string, handler: (message) => void) {
    this.handlers[messageType] = handler;
  }

  private handleMessage(message) {
    const data = JSON.parse(message.data);

    if (data.messageType in this.handlers) {
      this.handlers[data.messageType](data);
    }
  }
}

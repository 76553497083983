import React from "react";
import { Button } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import { useDispatch, useSelector } from "react-redux";
import { downloadDocument } from "../../../../docuclipper/api";
import { ReduxState } from "../../../../redux";
import { createAlert } from "../../../../redux/reducers/Alerts";
import { logErrorToGA } from "../../../../docuclipper/Logger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {};
export const WritePdfDownloadButton: React.FC<Props> = ({}) => {
  const dispatch = useDispatch();
  const { job } = useSelector((state: ReduxState) => state.JobData);
  const [loading, setLoading] = React.useState(false);
  if (loading) {
    return <ClipLoader />;
  }
  if (!job) {
    return null;
  }
  let extension = "";
  if (job.type === "WritePdf") {
    extension = ".zip";
  }
  return (
    <Button
      variant="primary"
      onClick={() => {
        try {
          const obj = JSON.parse(job.output || "{}");
          if (obj.documentId !== undefined) {
            setLoading(true);
            downloadDocument(obj.documentId)
              .then((data) => {
                setLoading(false);
                saveAs(data, `${job.name}.${extension}`);
                dispatch(
                  createAlert({
                    type: "success",
                    message: [`Document downloaded.`],
                    timeout: 0,
                  })
                );
              })
              .catch((err) => {
                setLoading(false);
                logErrorToGA(err);
                dispatch(
                  createAlert({
                    type: "error",
                    message: err.message || "Error downloading document.",
                    timeout: 0,
                  })
                );
              });
          }
        } catch (err) {}
      }}
    >
      <FontAwesomeIcon icon="download" />
      <span className="ml-1">Download Data</span>
    </Button>
  );
};
export default WritePdfDownloadButton;

import React from "react";

const PDFViewer = ({ url, width, height }) => {
  // console.log(`Re-rendering pdfviewer ${url} ${width} ${height}`);
  return (
    <iframe
      title="PDF Document Viewer"
      aria-label="PDF Viewer"
      src={`${url}`}
      style={{ width, height, border: "none" }}
      allowFullScreen
    >
      Your browser does not support PDFs. Please download the PDF to view it:{" "}
      <a href={url}>Download PDF</a>
    </iframe>
  );
};

PDFViewer.defaultProps = {
  width: "100%",
  height: "100vh",
};

export default PDFViewer;

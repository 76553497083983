import React, { useEffect, useCallback } from "react";
import Select, { ActionMeta } from "react-select";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "src/redux";
import {
  setSelectedExportFields,
  loadSavedExportFields,
} from "src/redux/reducers/InvoiceManager";

interface FieldOption {
  value: string;
  label: string;
  group: "regular" | "extra" | "lineItem" | "lineItemExtra";
}

interface FieldSelectorProps {}

const STORAGE_KEY = "invoice-export-selected-fields";

export const InvoiceFieldsSelector: React.FC<FieldSelectorProps> = ({}) => {
  const { downloadOptions, transactionLines, selectedExportFields } =
    useSelector((state: ReduxState) => state.InvoiceManager);
  const availableFields = {
    regular: [
      // Temporarily disabled - uncomment to re-enable
      /*
      "id",
      "date",
      "dueDate",
      "total",
      "tax",
      "poNumber",
      "vendor",
      "customer",
      "account",
      */
    ],
    extra: [
      ...new Set(
        transactionLines.flatMap((tl) => {
          try {
            const rowData = JSON.parse((tl.row as any) || "{}");
            return Object.keys(rowData.extraFields || {});
          } catch {
            return [];
          }
        })
      ),
    ],
    lineItem: [
      // Temporarily disabled - uncomment to re-enable
      /*
      "item",
      "quantity",
      "unitPrice",
      "price",
      "tax",
      "category",
      "service",
      */
    ],
    lineItemExtra: [
      ...new Set(
        transactionLines.flatMap((tl) => {
          try {
            const rowData = JSON.parse((tl.row as any) || "{}");
            return (rowData.lines || []).flatMap((line) =>
              Object.keys(line.extraFields || {})
            );
          } catch {
            return [];
          }
        })
      ),
    ],
  };

  // console.log({ availableFields });

  const dispatch = useDispatch();
  const selectedFields = useSelector(
    (state: ReduxState) => state.InvoiceManager.selectedExportFields
  );

  // Load saved fields only once on mount
  useEffect(() => {
    dispatch(loadSavedExportFields(availableFields));
  }, []); // Empty dependency array - runs once on mount

  // Memoize the options to prevent recreating on every render
  const options = React.useMemo(
    () => [
      {
        label: "Regular Fields",
        options: availableFields.regular.map((field) => ({
          value: field,
          label: field,
          group: "regular",
        })),
      },
      {
        label: "Extra Fields",
        options: availableFields.extra.map((field) => ({
          value: `extra.${field}`,
          label: field,
          group: "extra",
        })),
      },
      {
        label: "Line Item Fields",
        options: availableFields.lineItem.map((field) => ({
          value: `lineItem.${field}`,
          label: field,
          group: "lineItem",
        })),
      },
      {
        label: "Line Item Extra Fields",
        options: availableFields.lineItemExtra.map((field) => ({
          value: `lineItem.extra.${field}`,
          label: field,
          group: "lineItemExtra",
        })),
      },
    ],
    [availableFields]
  );

  // Memoize allFields
  const allFields = React.useMemo(
    () => options.flatMap((group) => group.options.map((opt) => opt.value)),
    [options]
  );

  // Memoize handlers
  const handleSelectAll = useCallback(() => {
    dispatch(setSelectedExportFields(allFields));
  }, [dispatch, allFields]);

  const handleSelectNone = useCallback(() => {
    dispatch(setSelectedExportFields([]));
  }, [dispatch]);

  const handleChange = useCallback(
    (
      selected: readonly FieldOption[] | null | undefined,
      actionMeta: ActionMeta<FieldOption>
    ) => {
      const newFields = selected ? selected.map((option) => option.value) : [];
      dispatch(setSelectedExportFields(newFields));
    },
    [dispatch]
  );

  // Save to localStorage when selection changes, but debounce it
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      localStorage.setItem(STORAGE_KEY, JSON.stringify(selectedFields));
    }, 300); // Debounce for 300ms

    return () => clearTimeout(timeoutId);
  }, [selectedFields]);

  return (
    <div className="mb-3">
      <Form.Label className="mb-0">
        <strong>Extra Fields</strong>
      </Form.Label>
      <div className="">
        <button
          type="button"
          className="btn btn-link btn-sm mr-2"
          onClick={handleSelectAll}
        >
          Select All
        </button>
        <button
          type="button"
          className="btn btn-link btn-sm"
          onClick={handleSelectNone}
        >
          Select None
        </button>
      </div>
      <Select<FieldOption, true>
        isMulti
        options={options as any}
        value={
          options
            .flatMap((group) => group.options)
            .filter((option) => selectedFields.includes(option.value)) as any
        }
        onChange={handleChange}
        placeholder="Search and select fields..."
        className="basic-multi-select"
        classNamePrefix="select"
      />
    </div>
  );
};

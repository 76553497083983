import React from "react";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from "react-select/creatable";
import { editJobTags } from "../../../docuclipper/api";
import { ReduxState } from "../../../redux";
import {
  fetchTags,
  setSelectedTags,
  setTags,
} from "../../../redux/reducers/JobTags";

type Props = {};
export const EditJobTags: React.FC<Props> = ({}) => {
  const { loading, error, tags, selectedTags } = useSelector(
    (state: ReduxState) => state.JobTags
  );
  const { job } = useSelector((state: ReduxState) => state.JobData);

  const dispatch = useDispatch();
  // const jobTags = job ? job.tags : "";
  // const jobId = job ? job.id : "";
  React.useEffect(() => {
    if (job) {
      dispatch(fetchTags(job.id));
    }
  }, [job]);

  return (
    <CreatableSelect
      placeholder="Type to create a new tag"
      isClearable
      isDisabled={loading}
      isLoading={loading}
      onChange={(newValue) => {
        const newTags = ((newValue as any) || []).map((x) => x.value);
        dispatch(setSelectedTags(newTags));
        // onChange(newValue);
        if (job) {
          editJobTags(job.id, newTags).catch((err) => null);
        }
      }}
      isMulti={true}
      //   onCreateOption={(handleCreate)}
      options={(tags || []).map((t) => ({ label: t, value: t }))}
      value={selectedTags.map((t) => ({ label: t, value: t }))}
      formatCreateLabel={(val) => `Create tag "${val}"`}
    />
  );
};

export default EditJobTags;

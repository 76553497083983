import Alerts from "./Alerts";
import Announcement from "./Announcement";
import Auth from "./Auth";
import ColumnConfig from "./ColumnConfig";
import CsvUpload from "./CsvUpload";
import Customer from "./Customer";
import DocumentEditor from "./DocumentEditor";
import DownloadOptions from "./DownloadOptions";
import DownloadInvoiceOptions from "./DownloadInvoiceOptions";
import Files from "./Files";
import GenericOptions from "./GenericOption";
import Job from "./Job";
import JobData from "./JobData";
import JobDocument from "./JobDocument";
import Jobs from "./Jobs";
import ModalManager from "./ModalManager";
import OnboardingSteps from "./OnboardingSteps";
import Plans from "./Plans";
import Promotions from "./Promotions";
import Reconciler from "./Reconciler";
import Referrals from "./Referrals";
import Sidebar from "./Sidebar";
import StripeCustomer from "./StripeCustomer";
import Subscription from "./Subscription";
import Template from "./Template";
import TemplateField from "./TemplateField";
import TemplateFieldsProgress from "./TemplateFieldsProgress";
import TemplateMetadataFields from "./TemplateMetadataFields";
import Tours from "./Tours";
import TransactionManager from "./TransactionManager";
import UpgradePlan from "./UpgradePlan";
import User from "./User";
import UserOfInterest from "./UserOfInterest";
import UserRecentTemplate from "./UserRecentTemplate";
import CustomFormat from "./CustomFormat";
import JobExport from "./JobExport";
import HelpDrawer from "./HelpDrawer";
import Integration from "./Integration";
import QboCustomer from "./QboCustomer";
import QboVendor from "./QboVendor";
import QboAccount from "./QboAccount";
import JobTags from "./JobTags";
import TagRegexes from "./TagRegexes";
import Categories from "./Categories";
import AsyncCategories from "./CategoriesAsync";
import MatchedTransactions from "./MatchedTransactions";
import FeatureGate from "./FeatureGate";
import TrialLimitation from "./TrialLimitation";
import SupportTool from "./SupportTool";
import Survey from "./Survey";
import AutomaticModeAsync from "./AutomaticModeAsync";
import QboAccountCategory from "./QboAccountCategory";
import QboTaxCode from "./QboTaxCode";
import QboItem from "./QboItem";
import ExternalFilter from "./ExternalFilter";
import InvoiceManager from "./InvoiceManager";
import JobRatings from "./JobRatings";
import PayeeCleanupAsync from "./PayeeCleanupAsync";
import TagRegexesInvoice from "./TagRegexesInvoice";
import AgGridColumnConfig from "./AgGridColumnConfig";
import TagLevel from "./TagLevel";

const reducers = {
  Alerts,
  Auth,
  CsvUpload,
  Customer,
  DocumentEditor,
  Files,
  Job,
  JobData,
  OnboardingSteps,
  Template,
  TemplateFieldsProgress,
  Tours,
  UpgradePlan,
  UserOfInterest,
  Subscription,
  Plans,
  User,
  StripeCustomer,
  TemplateField,
  UserRecentTemplate,
  Reconciler,
  Sidebar,
  Referrals,
  Promotions,
  JobDocument,
  ModalManager,
  TransactionManager,
  DownloadOptions,
  DownloadInvoiceOptions,
  GenericOptions,
  TemplateMetadataFields,
  Announcement,
  Jobs,
  ColumnConfig,
  CustomFormat,
  JobExport,
  HelpDrawer,
  Integration,
  QboCustomer,
  QboVendor,
  QboAccount,
  QboAccountCategory,
  QboItem,
  JobTags,
  TagRegexes,
  Categories,
  MatchedTransactions,
  FeatureGate,
  TrialLimitation,
  SupportTool,
  Survey,
  AsyncCategories,
  AutomaticModeAsync,
  ExternalFilter,
  InvoiceManager,
  JobRatings,
  PayeeCleanupAsync,
  TagRegexesInvoice,
  QboTaxCode,
  AgGridColumnConfig,
  TagLevel
};

export default reducers;

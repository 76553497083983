import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { useTransactions } from "../AnalyzeTransactionsTable/hooks";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { ReduxState } from "src/redux";
import { fetchMfs, fetchTls } from "src/redux/reducers/TransactionManager";
import { getJobDailyBalances } from "src/docuclipper/api";
import { createAlert } from "src/redux/reducers/Alerts";
import { flatten, uniq } from "lodash";
import { fetchTotals } from "src/redux/reducers/Reconciler";
import AllAccountsSelector from "../AnalyzeTransactionsTable/AllAccountsSelector";

interface PLSummary {
  initialBalance: number;
  endBalance: number;
  categories: {
    [key: string]: number;
  };
}

interface MFSRawEntry {
  name: string;
  value: string;
  accountName: string;
  chunk: string;
  documentId: string;
}

interface MFSPeriod {
  startDate: string;
  endDate: string;
  startBalance: string;
  endBalance: string;
  accountName: string;
  chunk: string;
}

export const CashflowChart: React.FC = () => {
  const dispatch = useDispatch();

  const [dailyBalances, setDailyBalances] = React.useState();
  const [plSummary, setPlSummary] = React.useState<PLSummary>();

  const { totalsByDocumentId, allAccounts } = useSelector(
    (state: ReduxState) => state.Reconciler
  );

  const [selectedAccount, setSelectedAccount] = React.useState<string | null>(
    null
  );
  const [dateRange, setDateRange] = React.useState({
    startDate: "",
    endDate: "",
  });
  const { job } = useSelector((state: ReduxState) => state.JobData);
  React.useEffect(() => {
    dispatch(fetchTotals());
  }, []);
  React.useEffect(() => {
    if (job && selectedAccount) {
      getJobDailyBalances(
        job.id,
        selectedAccount,
        dateRange.startDate,
        dateRange.endDate
      )
        .then(({ dailyBalances, plSummary }) => {
          setDailyBalances(dailyBalances);
          setPlSummary(plSummary);
        })
        .catch((err) =>
          dispatch(
            createAlert({
              message: err.message || "Error getting balances",
              timeout: 0,
              type: "error",
            })
          )
        );
    }
  }, [job?.id, dateRange?.startDate, dateRange?.endDate, selectedAccount]);

  // Initialize selectedAccount as null instead of empty string

  React.useEffect(() => {
    if (allAccounts.length > 0 && !selectedAccount) {
      setSelectedAccount(allAccounts[0]);
    }
  }, [allAccounts, selectedAccount]);

  // Add date filter state

  const handleExportToCSV = () => {
    if (!plSummary) return;

    // Prepare CSV content
    const csvContent = [
      "Balance Summary",
      `Initial Balance,${plSummary.initialBalance}`,
      `End Balance,${plSummary.endBalance}`,
      `Net Change,${plSummary.endBalance - plSummary.initialBalance}`,
      "",
      "Category Breakdown",
      "Category,Amount",
      ...Object.entries(plSummary.categories).map(
        ([category, amount]) => `${category},${amount}`
      ),
    ].join("\n");

    // Create and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `pl_summary_${selectedAccount}.csv`;
    link.click();
  };

  return (
    <Container fluid className="mt-4">
      <Row className="mb-4 align-items-center">
        <Col>
          <div className="d-flex align-items-center justify-content-between">
            <Row className="d-flex align-items-center">
              <AllAccountsSelector
                onAccountChange={(newAccount) => setSelectedAccount(newAccount)}
              />
              <div className="d-flex align-items-center ">
                <Form.Label className="mb-0 ml-2">Start Date</Form.Label>
                <Form.Control
                  type="date"
                  value={dateRange.startDate}
                  onChange={(e) =>
                    setDateRange((prev) => ({
                      ...prev,
                      startDate: e?.target?.value,
                    }))
                  }
                  className="px-3"
                />
              </div>

              <div className="d-flex align-items-center ">
                <Form.Label className="mb-0 ml-2">End Date</Form.Label>
                <Form.Control
                  type="date"
                  value={dateRange.endDate}
                  onChange={(e) =>
                    setDateRange((prev) => ({
                      ...prev,
                      endDate: e?.target?.value,
                    }))
                  }
                  className="px-3"
                />
              </div>
            </Row>
            {plSummary && (
              <Button variant="primary" onClick={handleExportToCSV}>
                Export to CSV
              </Button>
            )}
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg={8}>
          <h4 className="mb-0">Account Balance Over Time</h4>

          <LineChart
            width={800}
            height={400}
            data={dailyBalances}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="balance"
              stroke="#8884d8"
              name="Balance"
            />
          </LineChart>
        </Col>

        {plSummary && (
          <Col lg={4}>
            <Card className="mb-3">
              <Card.Body>
                <Card.Title>Balance Summary</Card.Title>
                <div>
                  Initial Balance: ${plSummary.initialBalance.toFixed(2)}
                </div>
                <div>End Balance: ${plSummary.endBalance.toFixed(2)}</div>
                <div>
                  Net Change: $
                  {(plSummary.endBalance - plSummary.initialBalance).toFixed(2)}
                </div>
              </Card.Body>
            </Card>

            <Card>
              <Card.Body>
                <Card.Title>Category Breakdown</Card.Title>
                {Object.entries(plSummary.categories).map(
                  ([category, amount]) => (
                    <div
                      key={category}
                      className="d-flex justify-content-between"
                    >
                      <span>{category}</span>
                      <span
                        className={`text-${amount >= 0 ? "success" : "danger"}`}
                      >
                        ${amount.toFixed(2)}
                      </span>
                    </div>
                  )
                )}
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </Container>
  );
};

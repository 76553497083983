import React from "react";
import { Alert } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  DocuClipperBillingCycle,
  DocuClipperPlan,
  StripePlan,
  StripeSubscription,
} from "../../../../docuclipper/DocuclipperTypes";
import { createAlert, CreateAlertFn } from "../../../../redux/reducers/Alerts";
import ConfirmPlan from "../SelectPlanAfterTrial/ConfirmPlan";
import {
  getDiscountedPricing,
  getPricing,
  WithAccountAndPlans,
} from "../utils";
import { ConfirmDowngrade } from "./ConfirmDowngrade";
import PlanSelector2 from "../SelectPlanAfterTrial/PlanSelector2";
import { useSeat } from "../../../../custom-hooks/useSeat";
import { ReduxState } from "src/redux";

type Props = {
  subscription: StripeSubscription;
  plans: StripePlan[];
  onConfirmed: () => any;
};
type ReduxProps = {
  createAlert: CreateAlertFn;
};

const SwitchPlans2: React.SFC<Props & ReduxProps & RouteComponentProps> = ({
  subscription,
  plans,
  history,
  createAlert,
  onConfirmed,
}) => {
  const [view, setView] = React.useState<
    "select" | "confirmdowngrade" | "confirm"
  >("select");
  const [plan, setPlan] = React.useState<DocuClipperPlan | null>(null);
  const [billingCycle, setBillingCycle] =
    React.useState<DocuClipperBillingCycle>("Monthly");

  const pricing = getPricing(plans);
  if (!pricing) {
    return <Alert variant="danger">Error loading plans</Alert>;
  }

  const discountedPricing = getDiscountedPricing(pricing, subscription);

  return (
    <>
      {view === "select" && (
        <>
          <PlanSelector2
            renderHeading={() => (
              <div className="h3 text-center my-4">
                Automate Data Entry from Invoices, Receipts, and Bank
                Statements.
                <br />
                Start Free
              </div>
            )}
            onSelect={(
              plan: DocuClipperPlan,
              billingCycle: DocuClipperBillingCycle
            ) => {
              let isDowngrade = false;
              if (subscription.status !== "canceled") {
                if (
                  subscription.docuclipperPlan.name === "Business" &&
                  ["Professional", "Starter"].includes(plan)
                ) {
                  isDowngrade = true;
                } else if (
                  subscription.docuclipperPlan.name === "Professional" &&
                  ["Starter"].includes(plan)
                ) {
                  isDowngrade = true;
                }
              }
              setPlan(plan);
              setBillingCycle(billingCycle);
              setView(isDowngrade ? "confirmdowngrade" : "confirm");
            }}
            pricing={pricing}
            discountedPricing={discountedPricing}
            currentPlan={subscription.docuclipperPlan.name}
            currentBillingCycle={subscription.docuclipperPlan.billingCycle}
          />
        </>
      )}
      {view === "confirmdowngrade" && (
        <ConfirmDowngrade
          subscription={subscription}
          plans={plans}
          selectedPlan={plan || "Starter"}
          onConfirmed={() => setView("confirm")}
        />
      )}
      {view === "confirm" && plan && (
        <ConfirmPlan
          plans={plans}
          pricing={pricing}
          discountedPricing={discountedPricing}
          selectedPlan={plan}
          selectedBillingCycle={billingCycle}
          btnText="Change plan"
          subscription={subscription}
          onConfirmed={onConfirmed}
          onBack={() => setView("select")}
        />
      )}
    </>
  );
};

export const _SwitchPlans2: React.FC<
  Props &
    ReduxProps &
    RouteComponentProps & {
      redirectToAccountOnConfirmed: boolean;
      onConfirmed?: () => any;
    }
> = ({ history, redirectToAccountOnConfirmed, onConfirmed, ...rest }) => {
  const { isSeat, admin } = useSeat();
  const isContractAdmin = useSelector(
    (state: ReduxState) => state.User.user?.isContractAdmin
  );

  if (!isContractAdmin) {
    let email = "";
    if (admin && admin.email) {
      email = admin.email;
    }
    return (
      <Alert variant="danger">{`Only admins can make changes to the plan. Contact ${
        email ? email : "your admin"
      } to get help.`}</Alert>
    );
  }
  return (
    <SwitchPlans2
      {...rest}
      history={history}
      onConfirmed={() => {
        if (redirectToAccountOnConfirmed) {
          history.push("/account?section=plan");
        }
        onConfirmed();
      }}
    />
  );
};
export const SwitchPlans = withRouter(_SwitchPlans2);

SwitchPlans.defaultProps = {
  redirectToAccountOnConfirmed: true,
  onConfirmed: () => null,
};

export default connect((state) => ({}), { createAlert })(
  withRouter(WithAccountAndPlans(SwitchPlans))
);

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { Col, FormGroup, Form, Row, Button, Alert } from "react-bootstrap";
import { formikArrayInput, formikInput } from "../../../utils/utils";
import {
  Invoice2,
  InvoiceApprovalStatus,
  LineItem2,
  QboCompany,
  TransactionLine,
  ApprovalProgress,
} from "src/docuclipper/DocuclipperTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  LoadingButton,
  TrackedButtonWithTooltip,
} from "src/views/Docuclipper/TrackedButton";
import {
  applyRunInvoiceRules,
  handleImportToQbo,
  setDuplicateError,
  updateInvoiceField,
  updateInvoiceLineItem,
} from "src/redux/reducers/InvoiceManager";
import { ReduxState } from "src/redux";
import {
  createAndAddQboAccount,
  fetchQboAccounts,
} from "src/redux/reducers/QboAccount";
import {
  createAndAddQboCustomer,
  fetchQboCustomers,
} from "src/redux/reducers/QboCustomer";
import {
  createAndAddQboVendor,
  fetchQboVendors,
} from "src/redux/reducers/QboVendor";
import { fetchIntegrations } from "src/redux/reducers/Integration";
import {
  createAndAddQboAccountCategory,
  fetchQboAccountCategorys,
} from "src/redux/reducers/QboAccountCategory";
import { fetchQboItems } from "src/redux/reducers/QboItem";
import { goToQuickbooksFn } from "../Integrations/IntegrationsTable";
import quickbooksConnectIcon from "../../../icons/C2QB_green_btn_med_default.svg";
import { FormikQboSelect } from "./QboSelect";
import CategorizationBadge from "./CategorizationBadge";
import { setShowModalInvoiceRules } from "src/redux/reducers/InvoiceManager";
import TagRegexesInvoice from "../Analyze/Categorize/TagRegexesInvoice";
import { fetchQboTaxCodes } from "src/redux/reducers/QboTaxCode";
import {
  getInvoiceApprovalStatus,
  submitForApproval,
  updateInvoiceApprovalStatus,
} from "src/docuclipper/api";
import { ApprovalHistory } from "./ApprovalHistory";
import { createAlert } from "src/redux/reducers/Alerts";

const defaultInvoiceLine = {
  item: "",
  quantity: "",
  unitPrice: "",
  price: "",
  categoryAutomated: "",
  serviceAutomated: "",
  categoryEdited: "",
  serviceEdited: "",
  taxAutomated: "",
  taxEdited: "",
};

type Props = {
  invoice: Invoice2;
  getMarginTop: (
    index: number,
    lineItem: any,
    margin: string,
    defaultMargin: string
  ) => string;
  tl: TransactionLine;
};

const ApprovalBanner = ({
  onApprove,
  onReject,
  isLoading,
  isApproved,
  approvalProgress,
  currentUserHasActed,
  onViewHistory,
  isApprover,
  needsApproval,
}) => {
  const [comment, setComment] = useState("");

  return (
    <Alert variant={isApproved ? "success" : "warning"} className="mb-3">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <div className="d-flex align-items-center">
          <span>
            {isApproved
              ? "This invoice has been approved"
              : isApprover && !currentUserHasActed
              ? "This invoice requires your approval"
              : "This invoice requires approval"}
          </span>
          <Button
            variant="link"
            size="sm"
            onClick={onViewHistory}
            className="ml-2 p-0"
          >
            <FontAwesomeIcon icon="history" className="mr-1" />
            View Logs
          </Button>
        </div>
        <div>
          {!isApproved && isApprover && !currentUserHasActed && (
            <div className="d-flex align-items-center">
              <Form.Control
                type="text"
                placeholder="Optional comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                className="mr-2"
                size="sm"
                style={{ width: "200px" }}
              />
              <Button
                variant="danger"
                className="mr-2"
                onClick={() => onReject(comment)}
                disabled={isLoading}
              >
                {isLoading ? "Rejecting..." : "Reject"}
              </Button>
              <Button
                variant="success"
                onClick={() => onApprove(comment)}
                disabled={isLoading}
              >
                {isLoading ? "Approving..." : "Approve"}
              </Button>
            </div>
          )}
          {currentUserHasActed && (
            <span className="badge badge-info">
              You have {currentUserHasActed} this invoice
            </span>
          )}
        </div>
      </div>

      {approvalProgress && (
        <div className="mt-2">
          <div className="d-flex justify-content-between mb-1">
            <small>Approval Progress:</small>
            <small>
              {approvalProgress.approved}/{approvalProgress.total} approved
            </small>
          </div>
          <div className="progress" style={{ height: "20px" }}>
            <div
              className="progress-bar bg-success"
              style={{
                width: `${
                  (approvalProgress.approved / approvalProgress.total) * 100
                }%`,
              }}
            >
              {approvalProgress.approved > 0 &&
                `${approvalProgress.approved} Approved`}
            </div>
            <div
              className="progress-bar bg-warning"
              style={{
                width: `${
                  (approvalProgress.pending / approvalProgress.total) * 100
                }%`,
              }}
            >
              {approvalProgress.pending > 0 &&
                `${approvalProgress.pending} Pending`}
            </div>
            <div
              className="progress-bar bg-danger"
              style={{
                width: `${
                  (approvalProgress.rejected / approvalProgress.total) * 100
                }%`,
              }}
            >
              {approvalProgress.rejected > 0 &&
                `${approvalProgress.rejected} Rejected`}
            </div>
          </div>

          <div className="mt-2">
            <small>Approvers:</small>
            <ul className="list-unstyled">
              {approvalProgress.requirements.map((req, i) => (
                <li key={i} className="small">
                  <span
                    className={`text-${
                      req.status === "approved"
                        ? "success"
                        : req.status === "rejected"
                        ? "danger"
                        : "warning"
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={
                        req.status === "approved"
                          ? "check-circle"
                          : req.status === "rejected"
                          ? "times-circle"
                          : "clock"
                      }
                      className="mr-1"
                    />
                    {req.approverType === "team"
                      ? `team: ${req.approver.name}`
                      : `user: ${req.approver.email}}`}
                    : {req.status}
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </Alert>
  );
};

const getApprovalStateButtons = (
  status: InvoiceApprovalStatus | null,
  needsApproval: boolean,
  tl: TransactionLine,
  approvalActionLoading: boolean,
  values: any,
  importToQboLoading: boolean,
  dispatch: any,
  submitForm: () => void,
  onSubmitApproval: (result: any) => void,
  validateForm: () => Promise<any>,
  hasIntegrationError: boolean
) => {
  // If invoice is already published, show the "View in QuickBooks" button instead
  if (values.status === "published" && values.link) {
    return (
      <a
        href={values.link}
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-primary"
      >
        View in QuickBooks
      </a>
    );
  }

  // If no approval is needed, show the import button
  if (!needsApproval) {
    return (
      <LoadingButton
        variant="primary"
        isLoading={importToQboLoading}
        onClick={submitForm}
        disabled={approvalActionLoading || hasIntegrationError}
        title={hasIntegrationError ? "Fix QuickBooks integration first" : ""}
      >
        Import to QuickBooks
      </LoadingButton>
    );
  }

  // If approval is needed but no status yet, show submit button
  if (needsApproval && !status) {
    return (
      <Button
        variant="primary"
        onClick={async () => {
          // Validate the form first
          const errors = await validateForm();
          if (Object.keys(errors).length === 0) {
            const result = await submitForApproval(tl.id as any);
            onSubmitApproval(result);
          } else {
            // Show validation errors
            dispatch(
              createAlert({
                type: "error",
                message:
                  "Please fix form errors before submitting for approval",
                timeout: 5000,
              })
            );
            submitForm(); // This will trigger form validation and show errors
          }
        }}
        disabled={approvalActionLoading}
      >
        Submit for Approval
      </Button>
    );
  }

  // If status is pending, show waiting message
  if (status?.status === "pending") {
    return (
      <Alert variant="warning" className="mb-0">
        Approval pending - cannot import
      </Alert>
    );
  }

  // If status is rejected, show resubmit button
  if (status?.status === "rejected") {
    return (
      <Button
        variant="primary"
        onClick={async () => {
          // Validate the form first
          const errors = await validateForm();
          if (Object.keys(errors).length === 0) {
            const result = await submitForApproval(tl.id as any);
            onSubmitApproval(result);
          } else {
            // Show validation errors
            dispatch(
              createAlert({
                type: "error",
                message:
                  "Please fix form errors before submitting for approval",
                timeout: 5000,
              })
            );
            submitForm(); // This will trigger form validation and show errors
          }
        }}
        disabled={approvalActionLoading}
      >
        Resubmit for Approval
      </Button>
    );
  }

  // If status is approved, show import button
  if (status?.status === "approved") {
    return (
      <LoadingButton
        variant="primary"
        isLoading={importToQboLoading}
        onClick={submitForm}
        disabled={hasIntegrationError}
        title={hasIntegrationError ? "Fix QuickBooks integration first" : ""}
      >
        Import to QuickBooks
      </LoadingButton>
    );
  }

  // Default case - show submit button
  return (
    <Button
      variant="primary"
      onClick={async () => {
        // Validate the form first
        const errors = await validateForm();
        if (Object.keys(errors).length === 0) {
          const result = await submitForApproval(tl.id as any);
          onSubmitApproval(result);
        } else {
          // Show validation errors
          dispatch(
            createAlert({
              type: "error",
              message: "Please fix form errors before submitting for approval",
              timeout: 5000,
            })
          );
          submitForm(); // This will trigger form validation and show errors
        }
      }}
      disabled={approvalActionLoading}
    >
      Submit for Approval
    </Button>
  );
};

const InvoiceQbo: React.FC<Props> = ({ invoice, getMarginTop, tl }) => {
  const location = useLocation();
  // console.log({ invoice });
  const dispatch = useDispatch();
  const {
    qboAccounts,
    qboVendors,
    qboCustomers,
    qboItemsAll,
    qboAccountCategory,
    qboIntegrations,
    qboCustomersLoading,
    qboVendorsLoading,
    qboAccountsLoading,
    qboAccountCategoryLoading,
    qboItemLoading,
    qboIntegrationsLoading,
    importToQboLoading,
    runInvoiceRulesLoading,
    qboTaxCodes,
    qboTaxCodesLoading,
    duplicateError,
  } = useSelector((state: ReduxState) => ({
    qboAccounts: state.QboAccount.qboAccounts,
    qboAccountsLoading: state.QboAccount.loading,
    qboVendors: state.QboVendor.qboVendors,
    qboVendorsLoading: state.QboVendor.loading,
    qboCustomers: state.QboCustomer.qboCustomers,
    qboCustomersLoading: state.QboCustomer.loading,
    qboItemsAll: state.QboItem.qboItems,
    qboItemLoading: state.QboItem.loading,
    qboAccountCategory: state.QboAccountCategory.qboAccountCategory,
    qboAccountCategoryLoading: state.QboAccountCategory.loading,
    qboIntegrations: state.Integration.integrations.filter(
      (i) => i.scope === "intuit.quickbooks.accounting"
    ),
    qboIntegrationsLoading: state.Integration.loading,
    importToQboLoading: state.InvoiceManager.importToQboLoading,
    runInvoiceRulesLoading: state.InvoiceManager.runInvoiceRulesLoading,
    qboTaxCodes: state.QboTaxCode.qboTaxCodes,
    qboTaxCodesLoading: state.QboTaxCode.loading,
    duplicateError: state.InvoiceManager.duplicateError,
  }));

  const qboItems = qboItemsAll.filter((x) =>
    ["invoice"].includes(invoice.docType)
      ? ["Service", "Inventory"].includes(x.type)
      : ["category"].includes(x.type)
  );
  const fullPath = location.pathname + location.search;

  useEffect(() => {
    dispatch(fetchIntegrations());
  }, []);

  useEffect(() => {
    if (qboIntegrations.length > 0) {
      const integrationId = invoice.integration || qboIntegrations[0].id;
      dispatch(fetchQboCustomers(integrationId));
      dispatch(fetchQboVendors(integrationId));
      dispatch(fetchQboAccounts(integrationId));
      dispatch(fetchQboAccountCategorys(integrationId));
      dispatch(fetchQboItems(integrationId));
      dispatch(fetchQboTaxCodes(integrationId));

      if (!invoice.integration) {
        dispatch(
          updateInvoiceField({
            invoice,
            field: "integration",
            value: integrationId,
          })
        );
      }
    }
  }, [invoice.integration, qboIntegrations.length]);

  useEffect(() => {
    if (!["expense", "invoice", "bill"].includes(invoice.docType)) {
      dispatch(
        updateInvoiceField({ invoice, field: "docType", value: "expense" })
      );
      dispatch(setDuplicateError(null));
    }
  }, [invoice.docType]);

  useEffect(() => {
    if (invoice?.integration) {
      dispatch(
        applyRunInvoiceRules(Number(invoice.integration), invoice.docType)
      );
      dispatch(setDuplicateError(null));
    }
  }, [invoice?.integration, invoice?.docType]);

  useEffect(() => {
    if (invoice?.link) {
      dispatch(setDuplicateError(null));
    }
  }, [invoice?.link]);

  const handleFieldChange = ({
    values,
    field,
    value,
  }: {
    values: Invoice2;
    field: string;
    value: any;
  }) => {
    // Add name handling for vendor fields
    if (field === "vendorEdited") {
      dispatch(
        updateInvoiceField({
          invoice: values,
          field: "vendorEditedName",
          value: value ? getVendorById(value) : "",
        })
      );
    } else if (field === "vendorAutomated") {
      dispatch(
        updateInvoiceField({
          invoice: values,
          field: "vendorAutomatedName",
          value: value ? getVendorById(value) : "",
        })
      );
    } else if (field === "vendor") {
      dispatch(
        updateInvoiceField({
          invoice: values,
          field: "vendorName",
          value: value ? getVendorById(value) : "",
        })
      );
    }
    // Add name handling for customer fields
    else if (field === "customerEdited") {
      dispatch(
        updateInvoiceField({
          invoice: values,
          field: "customerEditedName",
          value: value ? getCustomerById(value) : "",
        })
      );
    } else if (field === "customerAutomated") {
      dispatch(
        updateInvoiceField({
          invoice: values,
          field: "customerAutomatedName",
          value: value ? getCustomerById(value) : "",
        })
      );
    } else if (field === "customer") {
      dispatch(
        updateInvoiceField({
          invoice: values,
          field: "customerName",
          value: value ? getCustomerById(value) : "",
        })
      );
    }
    // Add name handling for account fields
    else if (field === "accountEdited") {
      dispatch(
        updateInvoiceField({
          invoice: values,
          field: "accountEditedName",
          value: value ? getAccountById(value) : "",
        })
      );
    } else if (field === "accountAutomated") {
      dispatch(
        updateInvoiceField({
          invoice: values,
          field: "accountAutomatedName",
          value: value ? getAccountById(value) : "",
        })
      );
    } else if (field === "account") {
      dispatch(
        updateInvoiceField({
          invoice: values,
          field: "accountName",
          value: value ? getAccountById(value) : "",
        })
      );
    }

    dispatch(updateInvoiceField({ invoice: values, field, value }));
  };

  const handleLineItemChange = ({
    values,
    lineIndex,
    field,
    value,
  }: {
    values: Invoice2;
    lineIndex: number;
    field: string;
    value: any;
  }) => {
    const updatedValues = { ...values };
    const updatedLines = [...updatedValues.lines];
    const updatedLine = { ...updatedLines[lineIndex] };

    // Handle name fields for categories
    if (field === "categoryEdited") {
      updatedLine.categoryEditedName = value ? getCategoryById(value) : "";
    } else if (field === "categoryAutomated") {
      updatedLine.categoryAutomatedName = value ? getCategoryById(value) : "";
    } else if (field === "category") {
      updatedLine.categoryName = value ? getCategoryById(value) : "";
    }

    // Handle name fields for services
    else if (field === "serviceEdited") {
      updatedLine.serviceEditedName = value ? getServiceById(value) : "";
    } else if (field === "serviceAutomated") {
      updatedLine.serviceAutomatedName = value ? getServiceById(value) : "";
    } else if (field === "service") {
      updatedLine.serviceName = value ? getServiceById(value) : "";
    }

    // Handle name fields for tax codes
    else if (field === "taxEdited") {
      updatedLine.taxEditedName = value ? getTaxCodeById(value) : "";
    } else if (field === "taxAutomated") {
      updatedLine.taxAutomatedName = value ? getTaxCodeById(value) : "";
    } else if (field === "tax") {
      updatedLine.taxName = value ? getTaxCodeById(value) : "";
    }

    // Set the original field value
    updatedLine[field] = value;

    updatedLines[lineIndex] = updatedLine;
    updatedValues.lines = updatedLines;

    dispatch(
      updateInvoiceLineItem({
        invoice: updatedValues,
        lineIndex,
        field,
        value: value || "",
      })
    );
  };

  // Add state for tracking approval status
  const [approvalStatus, setApprovalStatus] = useState<{
    loading: boolean;
    status: InvoiceApprovalStatus | null;
    isApprover: boolean;
    needsApproval: boolean;
    approvalProgress: ApprovalProgress | null;
  }>({
    loading: true,
    status: null,
    isApprover: false,
    needsApproval: false,
    approvalProgress: null,
  });

  // Add state for approval actions
  const [approvalActionLoading, setApprovalActionLoading] = useState(false);

  // Add state for showing history
  const [showHistory, setShowHistory] = useState(false);

  // Add useEffect to fetch approval status
  useEffect(() => {
    const fetchApprovalStatus = async () => {
      if (invoice?.id) {
        try {
          const {
            isApprover,
            approvalStatus,
            needsApproval,
            approvalProgress,
          } = await getInvoiceApprovalStatus(tl.id as any);
          setApprovalStatus({
            loading: false,
            status: approvalStatus,
            isApprover,
            needsApproval,
            approvalProgress,
          });
        } catch (error) {
          console.error("Error fetching approval status:", error);
          setApprovalStatus({
            loading: false,
            status: null,
            isApprover: false,
            needsApproval: false,
            approvalProgress: null,
          });
        }
      }
    };

    fetchApprovalStatus();
  }, [JSON.stringify(invoice)]);

  const handleApprove = async (comment = "") => {
    setApprovalActionLoading(true);
    try {
      const response = await updateInvoiceApprovalStatus(
        Number(tl.id),
        "approved",
        comment
      );

      setApprovalStatus({
        loading: false,
        status: response.approvalStatus,
        isApprover: response.isApprover,
        needsApproval: response.needsApproval,
        approvalProgress: response.approvalProgress || null,
      });
    } catch (error) {
      console.error("Error approving invoice:", error);
    } finally {
      setApprovalActionLoading(false);
    }
  };

  const handleReject = async (comment = "") => {
    setApprovalActionLoading(true);
    try {
      const response = await updateInvoiceApprovalStatus(
        Number(tl.id),
        "rejected",
        comment
      );

      setApprovalStatus({
        loading: false,
        status: response.approvalStatus,
        isApprover: response.isApprover,
        needsApproval: response.needsApproval,
        approvalProgress: response.approvalProgress || null,
      });
    } catch (error) {
      console.error("Error rejecting invoice:", error);
    } finally {
      setApprovalActionLoading(false);
    }
  };

  if (!invoice) return null;

  const getCategoryById = (id) => {
    const category = qboAccountCategory.filter((x) => x.value === id);
    return category.length > 0 ? category[0].name : "";
  };

  const getServiceById = (id) => {
    const service = qboItems.filter((x) => x.value === id);
    return service.length > 0 ? service[0].name : "";
  };

  const getTaxCodeById = (id) => {
    const taxCode = qboTaxCodes.filter((x) => x.value === id);
    return taxCode.length > 0 ? taxCode[0].name : "";
  };

  const getCustomerById = (id) => {
    const customer = qboCustomers.filter((x) => x.value === id);
    return customer.length > 0 ? customer[0].name : "";
  };

  const getVendorById = (id) => {
    const vendor = qboVendors.filter((x) => x.value === id);
    return vendor.length > 0 ? vendor[0].name : "";
  };

  const getAccountById = (id) => {
    const account = qboAccounts.filter((x) => x.value === id);
    return account.length > 0 ? account[0].name : "";
  };

  const getCompanyByIntegrationId = (integrationId) => {
    if (!integrationId) return { value: "", name: "" };

    const companyQbo = qboIntegrations.filter((x) => x.id === integrationId);
    let nameCompanyQbo: QboCompany = { value: "", name: "" };
    if (companyQbo.length > 0) {
      const tmpCompanyQbo = JSON.parse(companyQbo[0].extraParams);
      if (tmpCompanyQbo && tmpCompanyQbo.companyName) {
        nameCompanyQbo = {
          name: tmpCompanyQbo.companyName,
          value: companyQbo[0].id,
        };
      }
    }
    return nameCompanyQbo;
  };

  const ctaLabel = `Import ${
    invoice?.docType === "invoice"
      ? "invoice"
      : invoice?.docType === "bill"
      ? "bills"
      : "expense"
  } into QuickBooks Online`;

  const validationSchema = Yup.object().shape({
    id: Yup.string().required("ID is required"),
    date: Yup.string()
      .trim()
      .matches(/\d{4}-\d{2}-\d{2}/, "Invalid date, use YYYY-MM-DD format")
      .required("Date is required"),
    dueDate: Yup.string()
      .trim()
      .matches(/\d{4}-\d{2}-\d{2}/, "Invalid date, use YYYY-MM-DD format"),
    total: Yup.number().required("Total is required"),
    tax: Yup.number().required("Tax is required"),
    lines: Yup.array().of(
      Yup.object()
        .shape({
          item: Yup.string().required("Item is required"),
          quantity: Yup.number().required("Quantity is required"),
          unitPrice: Yup.number().required("Unit price is required"),
          price: Yup.number().required("Price is required"),
          tax: Yup.string().nullable(),
          ...(["bill", "expense"].includes(invoice.docType) && {
            category: Yup.string().nullable(),
            categoryEdited: Yup.string().nullable(),
            categoryAutomated: Yup.string().nullable(),
          }),
          ...(["invoice"].includes(invoice.docType) && {
            service: Yup.string().nullable(),
            serviceAutomated: Yup.string().nullable(),
            serviceEdited: Yup.string().nullable(),
          }),
        })
        .test(
          "at-least-one-category",
          "Category is required",
          function (value) {
            if (["bill", "expense"].includes(invoice.docType)) {
              return !!(
                value.category ||
                value.categoryEdited ||
                value.categoryAutomated
              );
            }
            return true;
          }
        )
        .test(
          "at-least-one-service",
          "Product/service is required",
          function (value) {
            if (["invoice"].includes(invoice.docType)) {
              return !!(
                value.service ||
                value.serviceEdited ||
                value.serviceAutomated
              );
            }
            return true;
          }
        )
    ),
    integration: Yup.string().required("QuickBooks Company is required"),
    ...(invoice.docType === "invoice" && {
      customer: Yup.string().required("QuickBooks Customer is required"),
    }),
    ...(["bill", "expense"].includes(invoice.docType) && {
      vendor: Yup.string().test(
        "vendor-required",
        "QuickBooks Vendor is required",
        function (this: any, value?: any) {
          if (!this.parent) return false;

          return !!(
            this.parent.vendorEdited ||
            this.parent.vendorAutomated ||
            this.parent.vendor
          );
        }
      ),
    }),
    ...(invoice.docType === "expense" && {
      account: Yup.string().test(
        "account-required",
        "QuickBooks Account is required",
        function (this: any, value?: any) {
          if (!this.parent) return false;

          return !!(
            this.parent.accountEdited ||
            this.parent.accountAutomated ||
            this.parent.account
          );
        }
      ),
    }),
  });

  const initialValues = {
    ...invoice,
    customer: invoice.customer || "",
    customerName: invoice.customerName || "",
    customerEdited: invoice.customerEdited || "",
    customerEditedName: invoice.customerEditedName || "",
    customerAutomated: invoice.customerAutomated || "",
    customerAutomatedName: invoice.customerAutomatedName || "",
    vendor: invoice.vendor || "",
    vendorName: invoice.vendorName || "",
    vendorEdited: invoice.vendorEdited || "",
    vendorEditedName: invoice.vendorEditedName || "",
    vendorAutomated: invoice.vendorAutomated || "",
    vendorAutomatedName: invoice.vendorAutomatedName || "",
    account: invoice.account || "",
    accountName: invoice.accountName || "",
    accountEdited: invoice.accountEdited || "",
    accountEditedName: invoice.accountEditedName || "",
    accountAutomated: invoice.accountAutomated || "",
    accountAutomatedName: invoice.accountAutomatedName || "",
    lines: invoice.lines.map((l: LineItem2) => ({
      ...l,
      categoryEdited: l?.categoryEdited || "",
      serviceEdited: l?.serviceEdited || "",
      categoryAutomated: l?.categoryAutomated || "",
      serviceAutomated: l?.serviceAutomated || "",
    })),
  };

  const getIntegrationName = (integrationId) => {
    try {
      // console.log({ integrationId, qboIntegrations });
      const matches = qboIntegrations.filter(
        (x) => x.id.toString() === integrationId.toString()
      );
      return matches.length > 0
        ? JSON.parse(matches[0].extraParams).companyName || ""
        : "";
    } catch (err) {
      return "";
    }
  };

  const companyOptions = qboIntegrations.map((c) => ({
    label: getCompanyByIntegrationId(c.id).name,
    value: c.id,
  }));

  // In the component, determine if current user has acted
  const currentUserHasActed =
    approvalStatus.approvalProgress?.requirements?.find(
      (req) =>
        req.isCurrentUser && ["approved", "rejected"].includes(req.status)
    )?.status;

  return (
    <>
      {!approvalStatus.loading &&
        approvalStatus.status &&
        (approvalStatus.isApprover ||
          approvalStatus.needsApproval ||
          approvalStatus.status) && (
          <ApprovalBanner
            onApprove={handleApprove}
            onReject={handleReject}
            isLoading={approvalActionLoading}
            isApproved={approvalStatus.status?.status === "approved"}
            approvalProgress={approvalStatus.approvalProgress}
            currentUserHasActed={currentUserHasActed}
            onViewHistory={() => setShowHistory(true)}
            isApprover={approvalStatus.isApprover}
            needsApproval={approvalStatus.needsApproval}
          />
        )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        context={{ docType: invoice.docType }}
        onSubmit={(values: Invoice2) => {
          dispatch(handleImportToQbo(values));
        }}
      >
        {({ values, setFieldValue, submitForm, errors, validateForm }) => {
          const integrationWithError = qboIntegrations.find(
            (integration) =>
              integration.id === values.integration &&
              (integration.errorMessage || integration.errorCode !== null)
          );

          // console.log({ companyOptions });
          return (
            <>
              <Row>
                <Col>
                  <FormGroup>
                    <strong>
                      <Form.Label>QuickBooks Company</Form.Label>
                    </strong>
                    <Button
                      variant="link"
                      className="ml-2"
                      onClick={() =>
                        goToQuickbooksFn(
                          `&next=${encodeURIComponent(fullPath)}`
                        )
                      }
                    >
                      <FontAwesomeIcon icon="plus" className="mr-1" />
                      Add Company
                    </Button>

                    {integrationWithError && (
                      <Alert
                        variant="warning"
                        className="mt-2 mb-2"
                        style={{
                          display: "inline-block",
                          width: "auto",
                          maxWidth: "100%",
                        }}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span>
                            {integrationWithError.errorMessage ===
                            "invalid_grant"
                              ? "QuickBooks credentials are invalid or expired"
                              : "There's a problem with the QuickBooks integration"}
                          </span>
                          <Button
                            variant="warning"
                            size="sm"
                            className="ml-3"
                            onClick={() =>
                              goToQuickbooksFn(
                                `&next=${encodeURIComponent(fullPath)}`
                              )
                            }
                          >
                            {integrationWithError.errorMessage ===
                            "invalid_grant"
                              ? "Reconnect"
                              : "Reconnect"}
                          </Button>
                        </div>
                      </Alert>
                    )}

                    <div className="d-flex align-items-center">
                      <div className="flex-grow-1">
                        <Field
                          dataCy="integration-select"
                          name="integration"
                          placeholder="Search company"
                          defaultValue={
                            invoice.integration
                              ? {
                                  label: getCompanyByIntegrationId(
                                    invoice.integration
                                  ).name,
                                  value: invoice.integration,
                                }
                              : null
                          }
                          value={
                            invoice.integration
                              ? {
                                  label: getCompanyByIntegrationId(
                                    invoice.integration
                                  ).name,
                                  value: invoice.integration,
                                }
                              : null
                          }
                          component={FormikQboSelect}
                          options={companyOptions}
                          type={"integration"}
                          onChangeFn={(value) => {
                            handleFieldChange({
                              values,
                              field: "integration",
                              value,
                            });
                          }}
                        />
                      </div>

                      <div className="ml-2" style={{ marginTop: "-1rem" }}>
                        {getApprovalStateButtons(
                          approvalStatus.status,
                          approvalStatus.needsApproval,
                          tl,
                          approvalActionLoading,
                          values,
                          importToQboLoading,
                          dispatch,
                          submitForm,
                          (result) =>
                            setApprovalStatus({
                              loading: false,
                              status: result.approvalStatus,
                              isApprover: result.isApprover,
                              needsApproval: result.needsApproval,
                              approvalProgress: result.approvalProgress || null,
                            }),
                          validateForm,
                          !!integrationWithError
                        )}
                      </div>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row className="">
                <Col className="d-flex justify-content-end align-items-center">
                  <div>
                    {duplicateError && (
                      <Alert
                        variant="danger"
                        className="mb-0 mr-3"
                        style={{ display: "flex", alignItems: "center" }}
                      >
                        <span>
                          Duplicate {duplicateError.docType} found. View in
                          QuickBooks{" "}
                          <a
                            href={duplicateError.qboUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            here
                          </a>
                        </span>
                        <Button
                          variant="danger"
                          size="sm"
                          className="ml-3"
                          onClick={() =>
                            dispatch(handleImportToQbo(values, true))
                          }
                        >
                          Force Import
                        </Button>
                      </Alert>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                {["invoice"].includes(invoice.docType) && (
                  <Col>
                    <FormGroup>
                      <strong>
                        <Form.Label>QuickBooks Customer</Form.Label>
                      </strong>
                      <Field
                        name="customer"
                        placeholder="Search or create a new customer"
                        defaultValue={
                          invoice.customerEdited ||
                          invoice.customerAutomated ||
                          invoice.customer
                            ? {
                                label: getCustomerById(
                                  invoice.customerEdited ||
                                    invoice.customerAutomated ||
                                    invoice.customer
                                ),
                                value:
                                  invoice.customerEdited ||
                                  invoice.customerAutomated ||
                                  invoice.customer,
                              }
                            : null
                        }
                        value={
                          invoice.customerEdited ||
                          invoice.customerAutomated ||
                          invoice.customer
                            ? {
                                label: getCustomerById(
                                  invoice.customerEdited ||
                                    invoice.customerAutomated ||
                                    invoice.customer
                                ),
                                value:
                                  invoice.customerEdited ||
                                  invoice.customerAutomated ||
                                  invoice.customer,
                              }
                            : null
                        }
                        component={FormikQboSelect}
                        options={qboCustomers.map((c) => ({
                          label: c.name,
                          value: c.value,
                        }))}
                        createFn={(value) => {
                          dispatch(
                            createAndAddQboCustomer(invoice.integration, value)
                          );
                        }}
                        type={"customer"}
                        onChangeFn={(value) => {
                          handleFieldChange({
                            values,
                            field: "customerEdited",
                            value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                )}
                {["bill", "expense"].includes(invoice.docType) && (
                  <Col>
                    <FormGroup>
                      <strong>
                        <Form.Label>QuickBooks Vendor</Form.Label>
                      </strong>
                      <Field
                        name="vendor"
                        placeholder="Search or create a new vendor"
                        defaultValue={
                          invoice.vendorEdited ||
                          invoice.vendorAutomated ||
                          invoice.vendor
                            ? {
                                label: getVendorById(
                                  invoice.vendorEdited ||
                                    invoice.vendorAutomated ||
                                    invoice.vendor
                                ),
                                value:
                                  invoice.vendorEdited ||
                                  invoice.vendorAutomated ||
                                  invoice.vendor,
                              }
                            : null
                        }
                        value={
                          invoice.vendorEdited ||
                          invoice.vendorAutomated ||
                          invoice.vendor
                            ? {
                                label: getVendorById(
                                  invoice.vendorEdited ||
                                    invoice.vendorAutomated ||
                                    invoice.vendor
                                ),
                                value:
                                  invoice.vendorEdited ||
                                  invoice.vendorAutomated ||
                                  invoice.vendor,
                              }
                            : null
                        }
                        component={FormikQboSelect}
                        options={qboVendors.map((c) => ({
                          label: c.name,
                          value: c.value,
                        }))}
                        createFn={(value) =>
                          dispatch(
                            createAndAddQboVendor(invoice.integration, value)
                          )
                        }
                        type={"vendor"}
                        onChangeFn={(value) => {
                          handleFieldChange({
                            values,
                            field: "vendorEdited",
                            value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                )}
                {["expense"].includes(invoice.docType) && (
                  <Col>
                    <FormGroup>
                      <strong>
                        <Form.Label>QuickBooks Account</Form.Label>
                      </strong>
                      <Field
                        name="account"
                        placeholder="Search or create a new account"
                        defaultValue={
                          invoice.account
                            ? {
                                label: getAccountById(invoice.account),
                                value: invoice.account,
                              }
                            : null
                        }
                        value={
                          invoice.account
                            ? {
                                label: getAccountById(invoice.account),
                                value: invoice.account,
                              }
                            : null
                        }
                        component={FormikQboSelect}
                        options={qboAccounts.map((c) => ({
                          label: c.name,
                          value: c.value,
                        }))}
                        createFn={(value) =>
                          dispatch(
                            createAndAddQboAccount(invoice.integration, value)
                          )
                        }
                        type={"account"}
                        onChangeFn={(value) => {
                          handleFieldChange({
                            values,
                            field: "account",
                            value,
                          });
                        }}
                      />
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <strong>
                      <Form.Label>{`Id #`}</Form.Label>
                    </strong>
                    <Field
                      placeholder=""
                      name="id"
                      type="text"
                      component={formikInput}
                      onBlur={(event) =>
                        handleFieldChange({
                          values,
                          field: "id",
                          value: event.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <strong>
                      <Form.Label>{`Date`}</Form.Label>
                    </strong>
                    <Field
                      data-cy={`${invoice.docType}-date`}
                      placeholder=""
                      name="date"
                      type="text"
                      component={formikInput}
                      onBlur={(event) =>
                        handleFieldChange({
                          values,
                          field: "date",
                          value: event.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                {["bill", "invoice"].includes(invoice.docType) && (
                  <Col>
                    <FormGroup>
                      <strong>
                        <Form.Label>{`Due date`}</Form.Label>
                      </strong>
                      <Field
                        placeholder=""
                        name="dueDate"
                        type="text"
                        component={formikInput}
                        onBlur={(event) =>
                          handleFieldChange({
                            values,
                            field: "dueDate",
                            value: event.target.value,
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <strong>
                      <Form.Label>Tax</Form.Label>
                    </strong>
                    <Field
                      placeholder=""
                      name="tax"
                      type="text"
                      component={formikInput}
                      onBlur={(event) =>
                        handleFieldChange({
                          values,
                          field: "tax",
                          value: event.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <strong>
                      <Form.Label>Total</Form.Label>
                    </strong>
                    <Field
                      placeholder=""
                      name="total"
                      type="text"
                      component={formikInput}
                      onBlur={(event) =>
                        handleFieldChange({
                          values,
                          field: "total",
                          value: event.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="6" className="p-0">
                  <Button
                    className="invoice-tour-edit-rules"
                    variant="link"
                    onClick={() => {
                      dispatch(setShowModalInvoiceRules(true));
                    }}
                  >
                    <FontAwesomeIcon icon="cog" />
                    <span className="ml-1" data-cy="edit-rules-link">
                      {`Edit Rules for  ${
                        invoice.docType
                      }s - ${getIntegrationName(invoice.integration)}`}
                    </span>
                  </Button>
                  {runInvoiceRulesLoading && (
                    <span className="ml-1">Loading rules...</span>
                  )}
                </Col>
                <Col md="6" className="text-left p-0">
                  {!invoice?.integration && (
                    <Alert variant="warning">
                      To create rules, connect a QuickBooks company first
                    </Alert>
                  )}
                </Col>
              </Row>
              <Row className="ml-0">
                <FieldArray name="lines" key={invoice?.integration}>
                  {({ remove, push }) => (
                    <div
                      className="w-100"
                      style={{
                        maxHeight: "120vh",
                        overflowY: "scroll",
                      }}
                    >
                      {values.lines.length > 0 &&
                        values.lines.map((lineItem, index) => (
                          <div className="row my-2" key={`line-${index}`}>
                            <div className="col-3 pr-0">
                              {index === 0 && (
                                <strong>
                                  {["bill", "expense"].includes(
                                    invoice.docType
                                  ) && (
                                    <label
                                      htmlFor={`lines.${index}.categoryEdited`}
                                    >
                                      Category
                                    </label>
                                  )}
                                  {["invoice"].includes(invoice.docType) && (
                                    <label
                                      htmlFor={`lines.${index}.serviceEdited`}
                                    >
                                      Product/Service
                                    </label>
                                  )}
                                </strong>
                              )}

                              {["bill", "expense"].includes(
                                invoice.docType
                              ) && (
                                <div
                                  style={{
                                    position: "relative",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <CategorizationBadge
                                    primaryCondition={lineItem.categoryEdited}
                                    secondaryCondition={
                                      lineItem.categoryAutomated
                                    }
                                  />
                                  <Field
                                    key={`lines.${index}.categoryEdited-${lineItem.categoryEdited}-${lineItem.categoryAutomated}`}
                                    name={`lines.${index}.categoryEdited`}
                                    placeholder="Category"
                                    defaultValue={{
                                      label: getCategoryById(
                                        lineItem?.categoryEdited ||
                                          lineItem?.categoryAutomated ||
                                          ""
                                      ),
                                      value:
                                        lineItem?.categoryEdited ||
                                        lineItem?.categoryAutomated ||
                                        "",
                                    }}
                                    value={{
                                      label: getCategoryById(
                                        lineItem?.categoryEdited ||
                                          lineItem?.categoryAutomated ||
                                          ""
                                      ),
                                      value:
                                        lineItem?.categoryEdited ||
                                        lineItem?.categoryAutomated ||
                                        "",
                                    }}
                                    component={FormikQboSelect}
                                    options={qboAccountCategory.map((c) => ({
                                      label: c.name,
                                      value: c.value,
                                    }))}
                                    createFn={(value) => {
                                      dispatch(
                                        createAndAddQboAccountCategory(
                                          invoice.integration,
                                          value
                                        )
                                      );
                                    }}
                                    onChangeFn={(value) => {
                                      handleLineItemChange({
                                        values,
                                        lineIndex: index,
                                        field: "categoryEdited",
                                        value: !value ? "" : value,
                                      });
                                    }}
                                    type={"category"}
                                    style={{
                                      marginTop:
                                        index === 0 &&
                                        (lineItem.categoryEdited ||
                                          lineItem.categoryAutomated)
                                          ? "1rem"
                                          : "0",
                                    }}
                                    validate={() => {
                                      const hasCategory =
                                        lineItem.category ||
                                        lineItem.categoryEdited ||
                                        lineItem.categoryAutomated;
                                      return !hasCategory
                                        ? "Category is required"
                                        : undefined;
                                    }}
                                  />
                                </div>
                              )}
                              {["invoice"].includes(invoice.docType) && (
                                <div
                                  style={{
                                    position: "relative",
                                    marginBottom: "1rem",
                                  }}
                                >
                                  <CategorizationBadge
                                    primaryCondition={lineItem.serviceEdited}
                                    secondaryCondition={
                                      lineItem.serviceAutomated
                                    }
                                  />
                                  <Field
                                    key={`lines.${index}.serviceEdited-${lineItem.serviceEdited}-${lineItem.serviceAutomated}`}
                                    name={`lines.${index}.serviceEdited`}
                                    placeholder="Product/Service"
                                    defaultValue={{
                                      label: getServiceById(
                                        lineItem?.serviceEdited ||
                                          lineItem?.serviceAutomated ||
                                          lineItem?.service ||
                                          ""
                                      ),
                                      value:
                                        lineItem?.serviceEdited ||
                                        lineItem?.serviceAutomated ||
                                        lineItem?.service ||
                                        "",
                                    }}
                                    className="pr-0"
                                    component={FormikQboSelect}
                                    options={qboItems.map((c) => ({
                                      label: c.name,
                                      value: c.value,
                                    }))}
                                    onChangeFn={(value) => {
                                      handleLineItemChange({
                                        values,
                                        lineIndex: index,
                                        field: "serviceEdited",
                                        value: value || "",
                                      });
                                    }}
                                    type={"service"}
                                    style={{
                                      marginTop:
                                        index === 0 &&
                                        (lineItem.serviceEdited ||
                                          lineItem.serviceAutomated)
                                          ? "1rem"
                                          : "0",
                                    }}
                                    validate={() => {
                                      const hasService =
                                        lineItem.service ||
                                        lineItem.serviceEdited ||
                                        lineItem.serviceAutomated;
                                      return !hasService
                                        ? "Product/service is required"
                                        : undefined;
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                            <div className={`${`col-4`} pr-0`}>
                              {index === 0 && (
                                <strong>
                                  <label htmlFor={`lines.${index}.item`}>
                                    Item
                                  </label>
                                </strong>
                              )}
                              <Field
                                name={`lines.${index}.item`}
                                placeholder=""
                                type="text"
                                component={formikArrayInput}
                                onBlur={(event) =>
                                  handleLineItemChange({
                                    values,
                                    lineIndex: index,
                                    field: "item",
                                    value: event.target.value,
                                  })
                                }
                                style={{
                                  marginTop: getMarginTop(
                                    index,
                                    lineItem,
                                    "1rem",
                                    "0"
                                  ),
                                }}
                              />
                            </div>
                            <div className="col-1 pr-0">
                              {index === 0 && (
                                <strong>
                                  <label htmlFor={`lines.${index}.quantity`}>
                                    Quantity
                                  </label>
                                </strong>
                              )}
                              <Field
                                name={`lines.${index}.quantity`}
                                placeholder=""
                                type="text"
                                component={formikArrayInput}
                                onBlur={(event) =>
                                  handleLineItemChange({
                                    values,
                                    lineIndex: index,
                                    field: "quantity",
                                    value: event.target.value,
                                  })
                                }
                                style={{
                                  marginTop: getMarginTop(
                                    index,
                                    lineItem,
                                    "1rem",
                                    "0"
                                  ),
                                }}
                              />
                            </div>
                            <div className="col-1 pr-0">
                              {index === 0 && (
                                <strong>
                                  <label htmlFor={`lines.${index}.unitPrice`}>
                                    Unit Price
                                  </label>
                                </strong>
                              )}
                              <Field
                                name={`lines.${index}.unitPrice`}
                                placeholder=""
                                type="text"
                                component={formikArrayInput}
                                onBlur={(event) =>
                                  handleLineItemChange({
                                    values,
                                    lineIndex: index,
                                    field: "unitPrice",
                                    value: event.target.value,
                                  })
                                }
                                style={{
                                  marginTop: getMarginTop(
                                    index,
                                    lineItem,
                                    "1rem",
                                    "0"
                                  ),
                                }}
                              />
                            </div>
                            <div className="col-1 pr-0">
                              {index === 0 && (
                                <strong>
                                  <label htmlFor={`lines.${index}.price`}>
                                    Price
                                  </label>
                                </strong>
                              )}
                              <Field
                                name={`lines.${index}.price`}
                                placeholder=""
                                type="text"
                                component={formikArrayInput}
                                onBlur={(event) =>
                                  handleLineItemChange({
                                    values,
                                    lineIndex: index,
                                    field: "price",
                                    value: event.target.value,
                                  })
                                }
                                style={{
                                  marginTop: getMarginTop(
                                    index,
                                    lineItem,
                                    "1rem",
                                    "0"
                                  ),
                                }}
                              />
                            </div>

                            <div className="col-1 pr-0">
                              {index === 0 && (
                                <strong>
                                  <label htmlFor={`lines.${index}.tax`}>
                                    Tax
                                  </label>
                                </strong>
                              )}
                              <div
                                style={{
                                  position: "relative",
                                  marginBottom: "1rem",
                                  marginTop: getMarginTop(
                                    index,
                                    lineItem,
                                    "1rem",
                                    "0"
                                  ),
                                }}
                              >
                                <CategorizationBadge
                                  primaryCondition={lineItem.taxEdited}
                                  secondaryCondition={lineItem.taxAutomated}
                                />
                                <Field
                                  key={`lines.${index}.taxEdited-${lineItem.taxEdited}-${lineItem.taxAutomated}`}
                                  name={`lines.${index}.taxEdited`}
                                  placeholder="Tax"
                                  defaultValue={{
                                    label: getTaxCodeById(
                                      lineItem?.taxEdited ||
                                        lineItem?.taxAutomated ||
                                        ""
                                    ),
                                    value:
                                      lineItem?.taxEdited ||
                                      lineItem?.taxAutomated ||
                                      "",
                                  }}
                                  className="pr-0"
                                  component={FormikQboSelect}
                                  options={qboTaxCodes.map((c) => ({
                                    label: c.name,
                                    value: c.value,
                                  }))}
                                  onChangeFn={(value) => {
                                    handleLineItemChange({
                                      values,
                                      lineIndex: index,
                                      field: "taxEdited",
                                      value: value || "",
                                    });
                                  }}
                                  type={"tax"}
                                />
                              </div>
                            </div>

                            <TrackedButtonWithTooltip
                              style={{
                                display: "flex",
                                alignItems: "center",
                                marginRight: "0.5rem",
                                marginTop: getMarginTop(
                                  index,
                                  lineItem,
                                  "1.5rem",
                                  "-1rem"
                                ),
                              }}
                              id={index.toString()}
                              placement="top"
                              variant="link"
                              renderTooltip={() =>
                                values.lines.length === 1 ? (
                                  <>Cannot delete last line item</>
                                ) : (
                                  <>Delete Line Item</>
                                )
                              }
                              action={"removeItem"}
                              onClick={() =>
                                values.lines.length > 1 && remove(index)
                              }
                              disabled={values.lines.length === 1}
                            >
                              <FontAwesomeIcon icon="trash"></FontAwesomeIcon>
                            </TrackedButtonWithTooltip>
                          </div>
                        ))}

                      <Button
                        variant="link"
                        className="secondary"
                        onClick={() => push(defaultInvoiceLine)}
                      >
                        <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
                        <span className="ml-1">Add Line Item</span>
                      </Button>
                      <div className="p-5"></div>
                    </div>
                  )}
                </FieldArray>
              </Row>
              <Row className="mt-2 text-right">
                <Col
                  md={12}
                  className="d-flex justify-content-end align-items-center"
                >
                  {duplicateError && (
                    <Alert
                      variant="danger"
                      className="mb-0 mr-3"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span>
                        Duplicate {duplicateError.docType} found. View in
                        QuickBooks{" "}
                        <a
                          href={duplicateError.qboUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          here
                        </a>
                      </span>
                      <Button
                        variant="danger"
                        size="sm"
                        className="ml-3"
                        onClick={() =>
                          dispatch(handleImportToQbo(values, true))
                        }
                      >
                        Force Import
                      </Button>
                    </Alert>
                  )}
                </Col>
              </Row>
            </>
          );
        }}
      </Formik>
      <TagRegexesInvoice
        categories={
          ["bill", "expense"].includes(invoice.docType)
            ? qboAccountCategory
            : qboItems
        }
        docType={invoice.docType}
        integrationId={Number(invoice?.integration) || null}
      />

      <ApprovalHistory
        show={showHistory}
        onHide={() => setShowHistory(false)}
        transactionLineId={tl.id ? Number(tl.id) : 0}
      />
    </>
  );
};

export default InvoiceQbo;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { Col, FormGroup, Form, Row, Button } from "react-bootstrap";
import { formikArrayInput, formikInput } from "../../../utils/utils";
import { Invoice2, TransactionLine } from "src/docuclipper/DocuclipperTypes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CategorizationBadge from "./CategorizationBadge";
import { TrackedButtonWithTooltip } from "src/views/Docuclipper/TrackedButton";
import { setDownloadInvoiceModalOpen } from "src/redux/reducers/JobExport";
import {
  updateInvoiceField,
  updateInvoiceLineItem,
  setShowModalInvoiceRules,
  applyRunInvoiceRules,
} from "src/redux/reducers/InvoiceManager";
import TagRegexesInvoice from "../Analyze/Categorize/TagRegexesInvoice";
import { ReduxState } from "src/redux";

const documentTypeFieldsLabels = {
  expense: "Expense",
  invoice: "Invoice",
  bill: "Bill",
  payables: "Vendor",
  receivables: "Customer",
};

const defaultInvoiceLine = {
  item: "",
  quantity: "",
  unitPrice: "",
  price: "",
  categoryExcelAutomated: "",
  serviceExcelAutomated: "",
  categoryExcelEdited: "",
  serviceExcelEdited: "",
};

type Props = {
  invoice: Invoice2;
  transactionLines: TransactionLine[];
  getMarginTop: (
    index: number,
    lineItem: any,
    margin: string,
    defaultMargin: string
  ) => string;
};

const InvoiceExcel: React.FC<Props> = ({
  invoice,
  transactionLines,
  getMarginTop,
}) => {
  const dispatch = useDispatch();
  const [showInvoiceExtraFields, setShowInvoiceExtraFields] =
    React.useState(false);
  const [showLineItemExtraFields, setShowLineItemExtraFields] =
    React.useState(false);
  const [showErrors, setShowErrors] = React.useState(true);

  const { runInvoiceRulesLoading } = useSelector((state: ReduxState) => ({
    runInvoiceRulesLoading: state.InvoiceManager.runInvoiceRulesLoading,
  }));

  const fieldName =
    invoice.docType === "payables" ? "vendorExcel" : "customerExcel";
  const ctaLabel = `Download Excel - ${transactionLines.length}  invoices`;

  const hasInvoiceExtraFields =
    Object.keys(invoice.extraFields || {}).length > 0;

  const handleFieldChange = ({
    values,
    field,
    value,
  }: {
    values: Invoice2;
    field: string;
    value: any;
  }) => {
    if (field.startsWith("extraFields.")) {
      const extraFieldKey = field.split(".")[1];
      dispatch(
        updateInvoiceField({
          invoice: values,
          field: "extraFields",
          value: { ...values.extraFields, [extraFieldKey]: value },
        })
      );
    } else {
      dispatch(updateInvoiceField({ invoice: values, field, value }));
    }
  };

  const handleLineItemChange = ({
    values,
    lineIndex,
    field,
    value,
  }: {
    values: Invoice2;
    lineIndex: number;
    field: string;
    value: any;
  }) => {
    dispatch(
      updateInvoiceLineItem({ invoice: values, lineIndex, field, value })
    );
  };

  useEffect(() => {
    if (!["payables", "receivables"].includes(invoice.docType)) {
      dispatch(
        updateInvoiceField({ invoice, field: "docType", value: "payables" })
      );
    }
  }, [invoice.docType]);

  useEffect(() => {
    if (!invoice?.id) {
      dispatch(applyRunInvoiceRules(null, invoice.docType));
    }
  }, [invoice?.id, invoice?.docType]);

  const validationSchema = Yup.object().shape({
    id: Yup.string(), // .required("ID is required"),
    date: Yup.string()
      .trim()
      .matches(/\d{4}-\d{2}-\d{2}/, "Invalid date, use YYYY-MM-DD format")
      .required("Date is required"),
    dueDate: Yup.string()
      .trim()
      .matches(/\d{4}-\d{2}-\d{2}/, "Invalid date, use YYYY-MM-DD format"),
    total: Yup.number().required("Total is required"),
    tax: Yup.number(),
    lines: Yup.array().of(
      Yup.object().shape({
        item: Yup.string().required("Item is required"),
        quantity: Yup.number().required("Quantity is required"),
        unitPrice: Yup.number().required("Unit price is required"),
        price: Yup.number().required("Price is required"),
        categoryExcelEdited: Yup.string(),
        serviceExcelEdited: Yup.string(),
      })
    ),
  });

  const initialValues = {
    ...invoice,
    customer: invoice.customer || "",
    vendor: invoice.vendor || "",
    account: invoice.account || "",
    lines: invoice.lines.map((l) => ({
      ...l,
      categoryExcelEdited: l.categoryExcelEdited || "",
      serviceExcelEdited: l.serviceExcelEdited || "",
      categoryExcelAutomated: l.categoryExcelAutomated || "",
      serviceExcelAutomated: l.serviceExcelAutomated || "",
    })),
  };

  const renderExtraFields = (values, extraFields, prefix = "") => {
    extraFields = extraFields || {};
    return Object.entries(extraFields)
      .sort(([a], [b]) => a.localeCompare(b)) // Sort alphabetically
      .map(([key, value]) => (
        <FormGroup key={`${prefix}${key}`}>
          <Form.Label>
            <strong>{key}</strong>
          </Form.Label>
          <Field
            name={`${prefix}${key}`}
            type="text"
            component={formikInput}
            onBlur={(event) =>
              handleFieldChange({
                values,
                field: `${prefix}${key}`,
                value: event.target.value,
              })
            }
          />
        </FormGroup>
      ));
  };

  const renderExtraFieldsSummary = (extraFields) => {
    extraFields = extraFields || {};
    const fieldCount = Object.keys(extraFields).length;
    const sampleFields = Object.entries(extraFields)
      .sort(([a], [b]) => a.localeCompare(b)) // Sort alphabetically
      .slice(0, 3);

    return fieldCount > 0 ? (
      <span style={{ fontSize: "0.9em", color: "#666", marginLeft: "1em" }}>
        ({fieldCount} extra field{fieldCount !== 1 ? "s" : ""}:
        {sampleFields.map(([key, value], index) => (
          <span key={key}>
            {key}:{" "}
            {typeof value === "string"
              ? value.substring(0, 10)
              : JSON.stringify(value).substring(0, 10)}
            {typeof value === "string" && value.length > 10 ? "..." : ""}
            {index < sampleFields.length - 1 ? ", " : ""}
          </span>
        ))}
        {fieldCount > 3 && "..."})
      </span>
    ) : null;
  };

  const renderErrorSummary = (errors: any, values: any) => {
    if (!errors.lines || !showErrors) return null;

    const lineErrors = errors.lines.filter(Boolean);
    if (lineErrors.length === 0) return null;

    return (
      <Row className="mb-3">
        <Col>
          <div
            className="alert alert-danger"
            style={{ maxHeight: "200px", overflowY: "auto" }}
          >
            <strong>Line Item Errors:</strong>
            <ul className="mb-0">
              {errors.lines.map((error: any, index: number) => {
                if (!error) return null;
                return (
                  <li key={index}>
                    Line {index + 1} ({values.lines[index].item || "Empty item"}
                    ):
                    {Object.values(error).join(", ")}
                  </li>
                );
              })}
            </ul>
          </div>
        </Col>
      </Row>
    );
  };

  const renderLineItems = (values, showExtraFields, remove, errors) => {
    const allExtraFields = new Set();
    values.lines.forEach((line) => {
      Object.keys(line.extraFields || {}).forEach((key) =>
        allExtraFields.add(key)
      );
    });
    const extraFieldsArray = Array.from(allExtraFields).sort((a, b) =>
      a.localeCompare(b)
    ); // Sort alphabetically

    return (
      <div style={{ overflowX: "auto" }}>
        <div style={{ display: "flex", minWidth: "100%" }}>
          {/* First column header */}
          <div style={{ flex: "0 0 auto", width: "15%" }}>
            <strong>
              {["payables"].includes(values.docType)
                ? "Category"
                : "Product/Service"}
            </strong>
          </div>
          {/* Existing columns */}
          <div style={{ flex: "0 0 auto", width: "20%" }}>
            <strong>Item</strong>
          </div>
          <div style={{ flex: "0 0 auto", width: "10%" }}>
            <strong>Quantity</strong>
          </div>
          <div style={{ flex: "0 0 auto", width: "15%" }}>
            <strong>Unit Price</strong>
          </div>
          <div style={{ flex: "0 0 auto", width: "15%" }}>
            <strong>Price</strong>
          </div>
          <div style={{ flex: "0 0 auto", width: "10%" }}>
            <strong>Tax</strong>
          </div>

          {/* Extra field columns */}
          {showExtraFields &&
            extraFieldsArray.map((field) => (
              <div
                key={field as any}
                style={{ flex: "0 0 auto", width: "15%" }}
              >
                <strong>{field}</strong>
              </div>
            ))}
          <div style={{ flex: "0 0 auto", width: "5%" }}>
            <strong>Action</strong>
          </div>
        </div>

        {values.lines.map((lineItem, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              minWidth: "100%",
              backgroundColor: errors?.lines?.[index]
                ? "#fff5f5"
                : "transparent",
            }}
          >
            {/* First column field */}
            <div style={{ flex: "0 0 auto", width: "15%" }}>
              {["payables"].includes(values.docType) ? (
                <div style={{ position: "relative", marginBottom: "1rem" }}>
                  <CategorizationBadge
                    primaryCondition={lineItem.categoryExcelEdited}
                    secondaryCondition={lineItem.categoryExcelAutomated}
                  />
                  <Field
                    name={`lines.${index}.categoryExcelEdited`}
                    value={
                      lineItem.categoryExcelEdited ||
                      lineItem.categoryExcelAutomated ||
                      ""
                    }
                    type="text"
                    component={formikArrayInput}
                    onBlur={(event) => {
                      handleLineItemChange({
                        values,
                        lineIndex: index,
                        field: "categoryExcelEdited",
                        value: event.target.value,
                      });
                    }}
                    style={{
                      marginTop: getMarginTop(index, lineItem, "1rem", "0"),
                    }}
                  />
                </div>
              ) : (
                <div style={{ position: "relative", marginBottom: "1rem" }}>
                  <CategorizationBadge
                    primaryCondition={lineItem.serviceExcelEdited}
                    secondaryCondition={lineItem.serviceExcelAutomated}
                  />
                  <Field
                    name={`lines.${index}.serviceExcelEdited`}
                    value={
                      lineItem.serviceExcelEdited ||
                      lineItem.serviceExcelAutomated ||
                      ""
                    }
                    type="text"
                    component={formikArrayInput}
                    onBlur={(event) => {
                      handleLineItemChange({
                        values,
                        lineIndex: index,
                        field: "serviceExcelEdited",
                        value: event.target.value,
                      });
                    }}
                    style={{
                      marginTop: getMarginTop(index, lineItem, "1rem", "0"),
                    }}
                  />
                </div>
              )}
            </div>
            {/* Existing fields */}
            <div style={{ flex: "0 0 auto", width: "20%" }}>
              <Field
                name={`lines.${index}.item`}
                type="text"
                component={formikArrayInput}
                onBlur={(event) =>
                  handleLineItemChange({
                    values,
                    lineIndex: index,
                    field: "item",
                    value: event.target.value,
                  })
                }
              />
            </div>
            <div style={{ flex: "0 0 auto", width: "10%" }}>
              <Field
                name={`lines.${index}.quantity`}
                type="text"
                component={formikArrayInput}
                onBlur={(event) =>
                  handleLineItemChange({
                    values,
                    lineIndex: index,
                    field: "quantity",
                    value: event.target.value,
                  })
                }
              />
            </div>
            <div style={{ flex: "0 0 auto", width: "15%" }}>
              <Field
                name={`lines.${index}.unitPrice`}
                type="text"
                component={formikArrayInput}
                onBlur={(event) =>
                  handleLineItemChange({
                    values,
                    lineIndex: index,
                    field: "unitPrice",
                    value: event.target.value,
                  })
                }
              />
            </div>
            <div style={{ flex: "0 0 auto", width: "15%" }}>
              <Field
                name={`lines.${index}.price`}
                type="text"
                component={formikArrayInput}
                onBlur={(event) =>
                  handleLineItemChange({
                    values,
                    lineIndex: index,
                    field: "price",
                    value: event.target.value,
                  })
                }
              />
            </div>
            <div style={{ flex: "0 0 auto", width: "10%" }}>
              <Field
                name={`lines.${index}.taxExcel`}
                type="text"
                component={formikArrayInput}
                onBlur={(event) =>
                  handleLineItemChange({
                    values,
                    lineIndex: index,
                    field: "taxExcel",
                    value: event.target.value,
                  })
                }
              />
            </div>

            {/* Extra fields */}
            {showExtraFields &&
              extraFieldsArray.map((field) => (
                <div
                  key={field as any}
                  style={{ flex: "0 0 auto", width: "15%" }}
                >
                  <Field
                    name={`lines.${index}.extraFields.${field}`}
                    type="text"
                    component={formikArrayInput}
                    onBlur={(event) =>
                      handleLineItemChange({
                        values,
                        lineIndex: index,
                        field: `extraFields.${field}`,
                        value: event.target.value,
                      })
                    }
                  />
                </div>
              ))}
            <div style={{ flex: "0 0 auto", width: "5%" }}>
              <TrackedButtonWithTooltip
                id={index.toString()}
                placement="top"
                variant="link"
                renderTooltip={() =>
                  values.lines.length === 1 ? (
                    <>Cannot delete the last line item</>
                  ) : (
                    <>Delete Line Item</>
                  )
                }
                action={"removeItem"}
                onClick={() => remove(index)}
                disabled={values.lines.length === 1}
              >
                <FontAwesomeIcon
                  icon="trash"
                  style={{
                    opacity: values.lines.length === 1 ? 0.5 : 1,
                  }}
                />
              </TrackedButtonWithTooltip>
            </div>
          </div>
        ))}
      </div>
    );
  };

  // console.log({ invoice });
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        context={{ docType: invoice.docType }}
        onSubmit={(values) => {
          dispatch(setDownloadInvoiceModalOpen(true));
        }}
      >
        {({ values, errors, submitForm }) => {
          // console.log({ errors, values });
          const hasLineItemExtraFields = values.lines.some(
            (line) => Object.keys(line.extraFields || {}).length > 0
          );

          return (
            <>
              {renderErrorSummary(errors, values)}

              <Row className="mb-3 text-right invoice-tour-download-data">
                <Col md={12}>
                  <Button
                    className="btn--cta"
                    variant="primary"
                    onClick={submitForm}
                  >
                    {ctaLabel}
                  </Button>
                </Col>
              </Row>

              {hasInvoiceExtraFields && (
                <Row className="mb-3">
                  <Col>
                    <Button
                      variant="link"
                      onClick={() =>
                        setShowInvoiceExtraFields(!showInvoiceExtraFields)
                      }
                    >
                      <FontAwesomeIcon
                        icon={
                          showInvoiceExtraFields ? "chevron-up" : "chevron-down"
                        }
                      />
                      <span className="ml-1">
                        {showInvoiceExtraFields ? "Hide" : "Show"} Invoice Extra
                        Fields
                      </span>
                    </Button>
                    {!showInvoiceExtraFields &&
                      renderExtraFieldsSummary(values.extraFields)}
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <FormGroup>
                    <strong>
                      <Form.Label>
                        {documentTypeFieldsLabels[invoice.docType]}
                      </Form.Label>
                    </strong>
                    <Field
                      placeholder=""
                      name={fieldName}
                      type="text"
                      component={formikInput}
                      onBlur={(event) =>
                        handleFieldChange({
                          values,
                          field: fieldName,
                          value: event.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <strong>
                      <Form.Label>{`Id #`}</Form.Label>
                    </strong>
                    <Field
                      placeholder=""
                      name="id"
                      type="text"
                      component={formikInput}
                      onBlur={(event) =>
                        handleFieldChange({
                          values,
                          field: "id",
                          value: event.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <strong>
                      <Form.Label>{`Date`}</Form.Label>
                    </strong>
                    <Field
                      data-cy={`${invoice.docType}-date`}
                      placeholder=""
                      name="date"
                      type="text"
                      component={formikInput}
                      onBlur={(event) =>
                        handleFieldChange({
                          values,
                          field: "date",
                          value: event.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <strong>
                      <Form.Label>{`Due date`}</Form.Label>
                    </strong>
                    <Field
                      placeholder=""
                      name="dueDate"
                      type="text"
                      component={formikInput}
                      onBlur={(event) =>
                        handleFieldChange({
                          values,
                          field: "dueDate",
                          value: event.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                <Col>
                  <FormGroup>
                    <strong>
                      <Form.Label>Tax</Form.Label>
                    </strong>
                    <Field
                      placeholder=""
                      name="tax"
                      type="text"
                      component={formikInput}
                      onBlur={(event) =>
                        handleFieldChange({
                          values,
                          field: "tax",
                          value: event.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <strong>
                      <Form.Label>Total</Form.Label>
                    </strong>
                    <Field
                      placeholder=""
                      name="total"
                      type="text"
                      component={formikInput}
                      onBlur={(event) =>
                        handleFieldChange({
                          values,
                          field: "total",
                          value: event.target.value,
                        })
                      }
                    />
                  </FormGroup>{" "}
                </Col>
                <Col>
                  <FormGroup>
                    <strong>
                      <Form.Label>PO Number</Form.Label>
                    </strong>
                    <Field
                      placeholder=""
                      name="poNumber"
                      type="text"
                      component={formikInput}
                      onBlur={(event) =>
                        handleFieldChange({
                          values,
                          field: "poNumber",
                          value: event.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              {showInvoiceExtraFields && hasInvoiceExtraFields && (
                <Row>
                  <Col>
                    {renderExtraFields(
                      values,
                      values.extraFields,
                      "extraFields."
                    )}
                  </Col>
                </Row>
              )}

              <Row>
                <Col>
                  <Button
                    className="invoice-tour-edit-rules"
                    variant="link"
                    onClick={() => {
                      dispatch(setShowModalInvoiceRules(true));
                    }}
                  >
                    <FontAwesomeIcon icon="cog" />
                    <span className="ml-1" data-cy="edit-rules-link">
                      {`Edit Rules for  ${invoice.docType}`}
                    </span>
                  </Button>
                  {runInvoiceRulesLoading && (
                    <span className="ml-1">Loading rules...</span>
                  )}
                </Col>
              </Row>

              {hasLineItemExtraFields && (
                <Row className="mb-3">
                  <Col>
                    <Button
                      variant="link"
                      onClick={() =>
                        setShowLineItemExtraFields(!showLineItemExtraFields)
                      }
                    >
                      <FontAwesomeIcon
                        icon={
                          showLineItemExtraFields
                            ? "chevron-up"
                            : "chevron-down"
                        }
                      />
                      <span className="ml-1">
                        {showLineItemExtraFields ? "Hide" : "Show"} Line Item
                        Extra Fields
                      </span>
                    </Button>
                    {!showLineItemExtraFields &&
                      renderExtraFieldsSummary(values.lines[0]?.extraFields)}
                  </Col>
                </Row>
              )}

              <Row className="ml-0">
                <FieldArray name="lines" key={invoice?.integration}>
                  {({ remove, push }) => (
                    <div style={{ maxHeight: "120vh", overflowY: "scroll" }}>
                      {renderLineItems(
                        values,
                        showLineItemExtraFields,
                        remove,
                        errors
                      )}

                      <Button
                        variant="link"
                        className="secondary"
                        onClick={() => push(defaultInvoiceLine)}
                      >
                        <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
                        <span className="ml-1">Add Line Item</span>
                      </Button>
                    </div>
                  )}
                </FieldArray>
              </Row>
            </>
          );
        }}
      </Formik>
      <TagRegexesInvoice docType={invoice.docType} />
    </>
  );
};

export default InvoiceExcel;

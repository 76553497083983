import * as React from "react";
import { Badge, Button, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../redux";
import TrackedButton from "../../Docuclipper/TrackedButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type PricingCardProps = {
  planName: string;
  billingCycle: string;
  selected: boolean;
  onSelected: () => any;
  featuresFn: () => any;
  // price: string;
  popular: boolean;
  current: boolean | null;
  btnText: string;
  percent: number | undefined;
  renderPrice: () => JSX.Element;
};

const PricingCard: React.FunctionComponent<PricingCardProps> = ({
  planName,
  billingCycle,
  selected,
  onSelected,
  featuresFn,
  // price,
  popular,
  current,
  btnText,
  percent,
  renderPrice,
}: PricingCardProps) => {
  const { token, tokenStr } = useSelector((state: ReduxState) => state.Auth);

  const style = {
    borderRadius: "10px",
    borderColor: popular ? "#D8AD3F" : "#e0e0e0",
    borderStyle: "solid",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.1)",
    opacity: 1.0,
    backgroundColor: "red !important",
  };

  return (
    <Col
      xs="12"
      sm="12"
      md="12"
      lg="3"
      xl="3"
      onClick={current ? () => null : onSelected}
      style={{ cursor: current ? "" : "pointer", maxWidth: "400px" }}
    >
      <div
        style={style}
        className="pricing-box p-4"
      >
        {popular ? (
          <div className="ribbon d-flex">
            <FontAwesomeIcon icon="star" />
            <span className="ml-2">Popular</span>
          </div>
        ) : null}
        <div className="text-center plan-title mt-3">
          <h2 style={{ color: "#3f6ad8", fontWeight: "bold"}} className="mb-0">
            {planName}
            {current && (
              <>
                <br />
                <span className="ml-1 text-muted" style={{ fontSize: "0.8em" }}>
                  (current plan)
                </span>
              </>
            )}
          </h2>
        </div>
        <div className="text-center plan-price align-items-center">
          <span
            className="ml-1"
            style={{ fontWeight: "bold", fontSize: "1.8em" }}
          >
            {/* {!renderPrice && price} */}
            {renderPrice && renderPrice()}
            {percent && (
              <Badge
                variant="danger"
                className="ml-2"
                style={{ fontSize: "0.6em" }}
              >{`${percent}% off`}</Badge>
            )}
          </span>
        </div>
        <div className="text-center mb-4">
          <TrackedButton
            disabled={!!current}
            className={`btn--select-plan-${planName}`}
            onClick={onSelected}
            action={`selectPlan`}
            label={planName}
            variant="primary"
            style={{
              backgroundColor: "#007bff",
              color: "white",
              borderColor: "#007bff",
              fontWeight: "bold",
              padding: "10px 20px",
              borderRadius: "20px",
            }}
          >
            {current ? "Current Plan" : btnText}
          </TrackedButton>
        </div>

        {featuresFn()}
      </div>
    </Col>
  );
};

export default PricingCard;

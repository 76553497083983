export const lsGetItem = (key: string) => {
  return localStorage.getItem(key);
};

export const lsSetItem = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};

export const lsRemoveItem = (key: string) => {
  return localStorage.removeItem(key);
};

export const localStorageWorks = () => {
  try {
    const k = "docuclipper.localstoragetest";
    lsSetItem(k, k);
    lsRemoveItem(k);
    return true;
  } catch (e) {
    return false;
  }
};

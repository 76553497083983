const parse = require("csv-parse/lib/es5/sync");

export function parseCsvToRowsAndColumn(csvText) {
  try {
    const records = parse(csvText.trim(), {
      relax_column_count: true
      //skip_empty_lines: true
    });

    if (records.length === 0) {
      return records;
    }
    const numColumns = records[0].length;
    if (numColumns === 0) {
      return records;
    }

    // remove last col if empty
    let isEmpty = true;
    do {
      isEmpty = true;
      for (let i = 0; i < records.length; i += 1) {
        const last = records[i][numColumns - 1];
        if (last !== "") {
          isEmpty = false;
          break;
        }
      }
      if (isEmpty) {
        // pop last column
        for (let i = 0; i < records.length; i += 1) {
          records[i].pop();
        }
      } else {
        break;
      }
    } while (isEmpty);
    return records;
  } catch (err) {
    return [];
  }
}

import React from "react";
import { logErrorToGA } from "./Logger";

type State = {
  error: any;
  errorInfo: any;
};

export class ErrorBoundary extends React.Component<{}, State> {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    if (error.name === "ChunkLoadError") {
      window.location.reload();
      return;
    }

    // Catch errors in any components below and re-render with error message
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });

    logErrorToGA(error, true);
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div>
          <h2>Something went wrong</h2>
          <p>
            <span>Get support at</span>{" "}
            <a href="mailto:support@docuclipper.com">support@docuclipper.com</a>
          </p>
          {/* <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details> */}
        </div>
      );
    }
    // Normally, just render children
    return this.props.children;
  }
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type MiniTemplate = {
  id: string;
  name: string;
};
const initialState: {
  templates: MiniTemplate[];
  loading: boolean;
  error: string | null;
} = {
  templates: [],
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "UserRecentTemplate",
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
      state.error = null;
    },
    setData(state, action: PayloadAction<MiniTemplate[]>) {
      state.loading = false;
      state.templates = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { load, setError, setData } = slice.actions;

export default slice.reducer;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ReduxState } from "../../../../../redux";
import { setDownloadOptionsSelectedDocumentIds } from "../../../../../redux/reducers/InvoiceManager";
import { CheckboxOption } from "./utils";

export const DocumentsInvoiceDropdown: React.FC = () => {
  const dispatch = useDispatch();

  const { documents, downloadOptions } = useSelector(
    (state: ReduxState) => state.InvoiceManager
  );

  const { selectedDocumentIds } = downloadOptions;

  const options = (documents || []).map((doc) => ({
    value: doc.id.toString(),
    label: doc.originalname,
  }));
  const selectedOptions = options.filter((option) =>
    selectedDocumentIds.includes(option.value)
  );
  return (
    <Select
      options={options}
      value={selectedOptions}
      onChange={(value) => {
        dispatch(
          setDownloadOptionsSelectedDocumentIds(
            ((value || []) as any).map((x) => x.value)
          )
        );
      }}
      isMulti={true}
      closeMenuOnSelect={false}
      allowSelectAll={true}
      components={{
        Option: CheckboxOption,
      }}
    />
  );
};
export default DocumentsInvoiceDropdown;

import React from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { createAlert } from "../../redux/reducers/Alerts";
import queryString from "query-string";
import { trackPurchaseGA4, trackSignupGA4 } from "../../utils/utils";

type Props = {};

const CURRENCY_TO_USD = {
  GBP: 1.25,
  CAD: 0.74,
  AUD: 0.65,
  ZAR: 0.053,
  USD: 1,
};

export const QueryParamBanner: React.FC<Props> = ({}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { error, success, value, currency, event } = queryString.parse(
    location.search
  );

  if (error) {
    dispatch(
      createAlert({
        type: "error",
        message: error,
        timeout: 0,
      })
    );
  } else if (success) {
    dispatch(
      createAlert({
        type: "success",
        message: success,
        timeout: 0,
      })
    );
  }

  if (event === "purchase" && currency && value) {
    // trackPurchaseGA4(value, currency);
    if (window && (window as any).lintrk) {
      (window as any).lintrk("track", { conversion_id: 10568572 });
    }
    if (window && (window as any).rdt) {
      const currencyUpper = (currency as string).toUpperCase();
      const valueInUSD =
        parseFloat(value.toString()) *
        (CURRENCY_TO_USD[currencyUpper as keyof typeof CURRENCY_TO_USD] || 1);

      (window as any).rdt("track", "Purchase", {
        value: valueInUSD,
        currency: "USD",
        itemCount: 1,
      });
    }
  }

  return null;
};
export default QueryParamBanner;

import { PayloadAction } from "@reduxjs/toolkit";
import { TemplateFieldState } from "../../TemplateFieldTypes";

export const _pageLoad = (state, action: PayloadAction<{}>) => {
  state.page.loading = true;
  state.page.error = null;
};
export const _pageSetError = (
  state: TemplateFieldState,
  action: PayloadAction<{ error: string }>
) => {
  state.page.error = action.payload.error;
  state.page.loading = false;
};
export const _pageSetData = (state, action: PayloadAction<{ page }>) => {
  if (!action.payload.page) {
    return state;
  }
  state.page.page = {
    ...action.payload.page,
    height: parseInt(action.payload.page.height.toString(), 10),
    width: parseInt(action.payload.page.width.toString(), 10),
    pageNumber: parseInt(action.payload.page.pageNumber.toString(), 10),
  };
  state.page.loading = false;
};

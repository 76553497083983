import React, {
  Component,
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from "react";
import { Sankey, Tooltip } from "recharts";
import _, { filter, flatten } from "lodash";
import DemoSankeyLink from "./DemoSankeyLink";
import DemoSankeyNode from "./DemoSankeyNode";
import { getFlowOfFunds } from "../../../../docuclipper/api";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../../redux";
import AgTable from "../AnalyzeTransactionsTable/AgTable";
import { datefilterParamsYYYYMMDD } from "../AnalyzeTransactionsTable";
import { useTransactions } from "../AnalyzeTransactionsTable/hooks";
import { Button, Col, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AgGridReact } from "ag-grid-react";
import { fetchMatchedTransactions } from "../../../../redux/reducers/MatchedTransactions";
// import SupersetDash from "../Categorize/SupersetPieChart.tsx.disabled";
import { useIsAdmin } from "src/utils/auth";
import { sankey, sankeyLinkHorizontal } from "d3-sankey";
import * as d3 from "d3";
import { SizeMe } from "react-sizeme";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { IDatasource } from "ag-grid-community";
import { TableFilterConfig } from "src/docuclipper/DocuclipperTypes";
import { fetchTls } from "src/redux/reducers/TransactionManager";
import { useHasQbo } from "src/utils/utils";
import ExternalFilter from "src/views/Docuclipper/ExternalFilter";

// Add these type definitions
interface SankeyNode extends d3.SimulationNodeDatum {
  name: string;
  category: string;
  x0?: number;
  x1?: number;
  y0?: number;
  y1?: number;
}

interface SankeyLink {
  source: any;
  target: any;
  value: number;
}

let source;
let destination;
export function FlowOfFunds() {
  // const { isAdmin } = useIsAdmin()
  const isAdmin = false;

  const ref = useRef();

  const externalFilterColumns = useMemo(
    () => [
      // {
      //   field: "account",
      //   label: "Account",
      //   filter: true,
      //   filterType: "text",
      //   defaultFilterType: "contains",
      // },
      {
        field: "date",
        label: "Date",
        filter: true,
        filterType: "date",
        defaultFilterType: "equals",
      },
      {
        field: "description",
        label: "Description",
        filter: true,
        filterType: "text",
        defaultFilterType: "contains",
      },
      {
        field: "amount",
        label: "Amount",
        filter: true,
        filterType: "number",
        defaultFilterType: "equals",
      },
    ],
    []
  );

  const externalFilterChanged = useCallback((s, d) => {
    source = s;
    destination = d;
    (ref as any).current.api.onFilterChanged();
  }, []);

  // const transactions = useTransactions();

  const {
    selectedDocumentId,
    tls,
    selectedChunk,
    accountChunkLabels,
    pagination,
    refreshKey,
  } = useSelector((state: ReduxState) => state.TransactionManager);

  const gridApi = React.useRef<any>(null);

  const [isGridReady, setIsGridReady] = useState(false);

  const onGridReady = (params) => {
    gridApi.current = params.api;
    setIsGridReady(true);
  };
  const dispatch = useDispatch();

  const [selectedCategory, setSelectedCategory] = useState<string | undefined>(
    undefined
  );
  const [selectedAccount, setSelectedAccount] = useState<string | undefined>(
    undefined
  );
  const [tx1Account, setTx1Account] = useState<string | undefined>(undefined);
  const [tx2Account, setTx2Account] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!gridApi.current) {
      // console.log("exit 0");
      return;
    }

    const dataSource: IDatasource = {
      rowCount: undefined,
      getRows: (params) => {
        const convertFilterModel = (): TableFilterConfig[] => {
          // TODO use params.filterModel
          return Object.entries(params.filterModel).map(
            ([field, filter]: [string, any]) => {
              // Get the actual filter type from the filter object
              const filterType = filter.filterType || "text"; // Default to 'text' if filterType is not specified
              const operationType = filter.type; // This is the operation (equals, contains, lessThan, etc.)

              // Initialize the filter object
              const filterOutput = {
                field,
                operator: operationType, // The operation (equals, contains, lessThan, etc.)
                valueFrom: undefined,
                valueTo: undefined,
                value: undefined,
              };

              if (filterType === "number") {
                // Handle number filters
                if (operationType === "inRange") {
                  filterOutput.valueFrom = filter.filter; // For range filters
                  filterOutput.valueTo = filter.filterTo;
                } else if (
                  operationType === "blank" ||
                  operationType === "notBlank"
                ) {
                  filterOutput.value = undefined; // No specific value for blank/not blank
                } else {
                  filterOutput.value = filter.filter; // Single value filters
                }
              } else if (filterType === "date") {
                // Handle date filters
                if (operationType === "inRange") {
                  filterOutput.valueFrom = filter.dateFrom; // Date filters use dateFrom
                  filterOutput.valueTo = filter.dateTo; // and dateTo
                } else if (
                  operationType === "blank" ||
                  operationType === "notBlank"
                ) {
                  filterOutput.value = undefined; // No specific value for blank/not blank
                } else {
                  filterOutput.value = filter.dateFrom; // Single value date filters use dateFrom
                }
              } else {
                // Handle text filters and other types
                filterOutput.value = filter.filter; // Default case
              }

              return filterOutput;
            }
          );
        };

        const convertSortModel = (sortModel: any[]) => {
          return sortModel.map((sort) => ({
            field: sort.colId,
            direction: sort.sort.toUpperCase(),
          }));
        };

        dispatch(
          fetchTls(false, {
            pagination: {
              page: Math.floor(params.startRow / pagination.pageSize) + 1,
              pageSize: params.endRow - params.startRow,
            },
            filters: [
              // Base filters
              // ...(selectedDocumentId
              //   ? [
              //       {
              //         field: "documentId",
              //         operator: "equals" as const,
              //         value: selectedDocumentId,
              //       },
              //     ]
              //   : []),
              // ...(selectedAccount
              //   ? [
              //       {
              //         field: "account",
              //         operator: "equals" as const,
              //         value: selectedAccount,
              //       },
              //     ]
              //   : []),
              // ...(selectedChunk
              //   ? [
              //       {
              //         field: "chunk",
              //         operator: "equals" as const,
              //         value: selectedChunk,
              //       },
              //     ]
              //   : []),
              {
                field: "included",
                operator: "equals" as const,
                value: true,
              },
              // Dynamic filters from AG Grid
              ...convertFilterModel(),
              // Add new filters
              ...(selectedCategory
                ? [
                    {
                      field: "category",
                      operator: "equals" as const,
                      value: selectedCategory,
                    },
                  ]
                : []),
              ...(selectedAccount
                ? [
                    {
                      field: "account",
                      operator: "equals" as const,
                      value: selectedAccount,
                    },
                  ]
                : []),
              ...(tx1Account
                ? [
                    {
                      field: "tx1Account",
                      operator: "equals" as const,
                      value: tx1Account,
                    },
                  ]
                : []),
              ...(tx2Account
                ? [
                    {
                      field: "tx2Account",
                      operator: "equals" as const,
                      value: tx2Account,
                    },
                  ]
                : []),
            ],
            sorting: convertSortModel(params.sortModel),
            successCallback: params.successCallback,
            accountChunkLabels,
            documents,
            hasQbo,
            job,
          })
        );
      },
    };

    if (gridApi.current && dataSource) {
      gridApi.current.setDatasource(dataSource);
    } else {
      console.error("Grid API or dataSource is not defined");
    }
  }, [
    dispatch,
    pagination.pageSize,
    selectedDocumentId,
    selectedAccount,
    selectedChunk,
    refreshKey,
    isGridReady,
    selectedCategory,
    tx1Account,
    tx2Account,
  ]);
  const { documents } = tls;
  const hasQbo = useHasQbo();

  const { matches } = useSelector(
    (state: ReduxState) => state.MatchedTransactions
  );

  React.useEffect(() => {
    if (matches.length === 0) {
      dispatch(fetchMatchedTransactions());
    }
  }, []);

  const matchedIds = flatten(
    matches
      .filter((x) => x.match.status === "approved")
      .map((x) => x.transactions)
  ).map((x) => x.id);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
    };
  }, []);

  const [columnDefs] = useState([
    {
      field: "account",
    },
    {
      field: "date",
      filter: "agDateColumnFilter",
      filterParams: datefilterParamsYYYYMMDD,
      sortable: true,
    },
    {
      field: "description",
      filter: "agTextColumnFilter",
      filterParams: {
        // pass in additional parameters to the Text Filter
      },
    },
    {
      field: "amount",
      filter: "agNumberColumnFilter",
      sortable: true,
      filterParams: {
        // pass in additional parameters to the Number Filter
      },
    },
    { field: "category", filter: "agSetColumnFilter" },
    // {
    //   headerName: "Is Transfer",
    //   field: "id",
    //   width: 120,
    //   cellRenderer: (params) => {
    //     if (!params.data) {
    //       return "";
    //     }
    //     const isTransfer = matchedIds.includes(params?.data?.id);
    //     // const isTransfer =
    //     return (
    //       <FontAwesomeIcon
    //         icon={isTransfer ? faCheck : faTimes}
    //         color={isTransfer ? "#2ecc71" : "#e74c3c"}
    //       />
    //     );
    //   },
    //   filter: "agSetColumnFilter",
    //   valueGetter: (params) => matchedIds.includes(params?.data?.id),
    // },
  ]);
  const onBtnExport = useCallback(() => {
    if (ref && ref.current) {
      (ref.current as any).api.exportDataAsCsv();
    }
  }, []);
  const { job } = useSelector((state: ReduxState) => state.JobData);
  interface SankeyData {
    nodes: Array<{ name: string; category: string; type: any }>;
    links: Array<{ source: number; target: number; value: number }>;
  }

  const [data2, setData2] = React.useState<SankeyData>({
    nodes: [],
    links: [],
  });
  React.useEffect(() => {
    if (job) {
      getFlowOfFunds(job.id)
        .then((data) => {
          const reorganizedData = reorganizeNodes(data);
          setData2(reorganizedData);
        })
        .catch((err) => null);
    }
  }, []);

  const reorganizeNodes = (data) => {
    // Add position information to nodes based on category
    const orderedNodes = data.nodes.map((node) => ({
      ...node,
      x:
        node.category === "income"
          ? 20
          : node.category === "expense"
          ? 840
          : 400,
    }));

    // Create index mapping
    const nodeIndexMap = Object.fromEntries(
      orderedNodes.map((node, index) => [node.name, index])
    );

    // Update links
    const updatedLinks = data.links.map((link) => ({
      ...link,
      source: nodeIndexMap[data.nodes[link.source].name],
      target: nodeIndexMap[data.nodes[link.target].name],
    }));

    return { nodes: orderedNodes, links: updatedLinks };
  };

  // const [source, setSource] = React.useState();
  // const [destination, setDestination] = React.useState();
  if ((data2.links || []).length === 0 || (data2.nodes || []).length === 0) {
    return (
      <div className="my-2 alert alert-warning" role="alert">
        Please categorize your transactions first to see the flow of funds
        visualization.
      </div>
    );
  }

  function filterFn(rowNode) {
    if (!source || !destination) {
      return true;
    }

    const amount = parseFloat(rowNode.data.amount);
    // console.log("Filtering:", {
    //   id: rowNode.data.id,
    //   account: rowNode.data.account,
    //   category: rowNode.data.category,
    //   amount,
    //   source,
    //   destination,
    //   isMatched: matchedIds.includes(rowNode.data.id),
    // });

    // Income link clicked (from specific income category to account)
    if (data2.nodes.find((n) => n.name === source)?.category === "income") {
      const matches =
        !matchedIds.includes(rowNode.data.id) && // Not a matched transaction
        rowNode.data.account === destination && // Must be to the destination account
        amount > 0 && // Must be positive (income)
        `Income:${rowNode.data.category}` === source; // Must match the specific income category name
      // console.log("Income filter result:", matches);
      return matches;
    }

    // Expense link clicked (from account to specific expense category)
    if (
      data2.nodes.find((n) => n.name === destination)?.category === "expense"
    ) {
      const matches =
        !matchedIds.includes(rowNode.data.id) && // Not a matched transaction
        rowNode.data.account === source && // Must be from the source account
        amount < 0 && // Must be negative (expense)
        `Expense:${rowNode.data.category}` === destination; // Must match the specific expense category name
      // console.log("Expense filter result:", matches);
      return matches;
    }

    // Account to account transfer clicked

    // TODO don't ues matches to filter account to account, use source and destination

    if (matchedIds.includes(rowNode.data.id)) {
      const match = matches.find((x) =>
        x.transactions.some((t) => t.id === rowNode.data.id)
      );

      if (match) {
        const [tx1, tx2] = match.transactions;
        const isValidTransfer =
          (tx1.account === source && tx2.account === destination) ||
          (tx1.account === destination && tx2.account === source);
        // console.log("Transfer filter result:", isValidTransfer);
        return isValidTransfer;
      }
    }

    return false;
  }

  const D3Sankey = ({ data }) => {
    return (
      <SizeMe>
        {({ size }) => (
          <div style={{ width: "100%", height: "500px" }}>
            <SankeyChart data={data} width={size.width || 0} height={500} />
          </div>
        )}
      </SizeMe>
    );
  };

  const SankeyChart = ({ data, width = 1040, height = 500 }) => {
    const [tooltip, setTooltip] = useState({
      show: false,
      content: "",
      x: 0,
      y: 0,
    });

    // Add data validation at the start
    if (!data || !data.nodes || !data.links) {
      console.warn("Invalid data provided to SankeyChart");
      return null;
    }

    const formatValue = (value: number) =>
      `$${Math.abs(value || 0).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`;

    // Pre-process nodes to assign columns and IDs
    const processedData = {
      nodes: (data.nodes || []).map((node) => {
        const accountNodes =
          data.nodes.filter((n) => n.category === "account") || [];
        const incomeNodes =
          data.nodes.filter((n) => n.category === "income") || [];

        let column;
        switch (node.category) {
          case "income":
            column = 0;
            break;
          case "account":
            // If we have income nodes, put accounts after them, otherwise start at 0
            column =
              incomeNodes.length > 0
                ? accountNodes.findIndex((n) => n.name === node.name) + 1
                : accountNodes.findIndex((n) => n.name === node.name);
            break;
          case "expense":
            // Put expenses in the last column
            column =
              incomeNodes.length > 0
                ? accountNodes.length + 1
                : accountNodes.length;
            break;
          default:
            column = 0;
        }

        return {
          ...node,
          id: node.name,
          column,
        };
      }),
      links: (data.links || [])
        .map((link) => {
          // Add null checks
          const sourceNode = data.nodes[link.source]?.name
            ? data.nodes[link.source]
            : data.nodes.find((n) => n.name === link.source);
          const targetNode = data.nodes[link.target]?.name
            ? data.nodes[link.target]
            : data.nodes.find((n) => n.name === link.target);

          if (!sourceNode || !targetNode) {
            console.warn("Invalid link found:", link);
            return null;
          }

          return {
            ...link,
            source: link.source?.name || link.source,
            target: link.target?.name || link.target,
          };
        })
        .filter(Boolean), // Remove any null links
    };

    // Add validation before generating sankey
    if (!processedData.nodes.length || !processedData.links.length) {
      console.warn("No valid nodes or links after processing");
      return null;
    }

    const sankeyGenerator = sankey<SankeyNode, SankeyLink>()
      .nodeWidth(15)
      .nodePadding(10)
      .extent([
        [1, 5],
        [width - 1, height - 5],
      ])
      .nodeAlign((node: any) => node.column);

    const { nodes, links } = sankeyGenerator(processedData);

    // After sankey generation, log the processed links
    // console.log(
    //   "Processed Links:",
    //   links.map((link) => ({
    //     source: {
    //       name: link.source.name,
    //       category: link.source.category,
    //       column: link.source.column,
    //     },
    //     target: {
    //       name: link.target.name,
    //       category: link.target.category,
    //       column: link.target.column,
    //     },
    //     value: link.value,
    //     path: `${link.source.category}/${link.source.name} → ${link.target.category}/${link.target.name}`,
    //   }))
    // );

    const getLinkColor = (link) => {
      const sourceNode = link.source;
      const targetNode = link.target;

      if (targetNode.category === "expense") return "#e74c3c"; // Red for expenses
      if (sourceNode.category === "income") return "#2ecc71"; // Green for income
      return "#3498db"; // Blue for transfers between accounts
    };

    const customLinkHorizontal = (link: any) => {
      const source = link.source;
      const target = link.target;

      // // For expense nodes, adjust the path to connect from account to expense
      // if (target.category === "expense") {
      //   return `
      //     M${source.x1},${source.y0}
      //     C${source.x1 + (target.x0 - source.x1) / 2},${source.y0}
      //      ${source.x1 + (target.x0 - source.x1) / 2},${target.y0}
      //      ${target.x0},${target.y0}
      //   `;
      // }

      // Default sankey path for other links
      return sankeyLinkHorizontal()(link);
    };

    return (
      <div style={{ position: "relative" }}>
        <svg width={width} height={height}>
          <g style={{ mixBlendMode: "multiply" }}>
            {/* Links with tooltips */}
            {links.map((link, i) => (
              <g
                key={i}
                onClick={() => {
                  // externalFilterChanged(link.source.name, link.target.name);
                  const sourceNode = data2.nodes.filter(
                    (x) => x.name === link.source.name
                  );
                  const targetNode = data2.nodes.filter(
                    (x) => x.name === link.target.name
                  );
                  // console.log({
                  //   source: link.source.name,
                  //   target: link.target.name,
                  //   nodes: data2.nodes,
                  //   sourceNode,
                  //   targetNode,
                  // });

                  if (sourceNode.length > 0 && targetNode.length > 0) {
                    if (
                      sourceNode[0].type === "accounts" &&
                      targetNode[0].type === "accounts"
                    ) {
                      setSelectedAccount(undefined);
                      setSelectedCategory(undefined);
                      setTx1Account(sourceNode[0].name);
                      setTx2Account(targetNode[0].name);
                      // transfer
                    } else if (
                      sourceNode[0].type === "categories" &&
                      targetNode[0].type === "accounts"
                    ) {
                      setSelectedCategory(sourceNode[0].name.split(":")[1]);
                      setSelectedAccount(targetNode[0].name);
                      setTx1Account(undefined);
                      setTx2Account(undefined);
                    } else if (
                      sourceNode[0].type === "accounts" &&
                      targetNode[0].type === "categories"
                    ) {
                      setSelectedAccount(sourceNode[0].name.split(":")[1]);
                      setSelectedCategory(targetNode[0].name);
                      setTx1Account(undefined);
                      setTx2Account(undefined);
                    } else {
                      setTx1Account(undefined);
                      setTx2Account(undefined);
                    }
                  }
                }}
                style={{ cursor: "pointer" }}
                onMouseEnter={(e) => {
                  setTooltip({
                    show: true,
                    content: `${link.source.name} → ${
                      link.target.name
                    }\nAmount: ${formatValue(
                      link.value
                    )}\nClick to view transactions`,
                    x: e.clientX,
                    y: e.clientY,
                  });
                }}
                onMouseLeave={() => setTooltip({ ...tooltip, show: false })}
              >
                <path
                  d={customLinkHorizontal(link)}
                  style={{
                    fill: "none",
                    strokeOpacity: 0.5,
                    stroke: getLinkColor(link),
                    strokeWidth: Math.max(1, link.width),
                  }}
                />
              </g>
            ))}

            {/* Nodes with tooltips */}
            {nodes.map((node, i) => (
              <g
                key={i}
                onClick={() => {
                  if (node.category === "income") {
                    externalFilterChanged("Income", node.name);
                  } else if (node.category === "expense") {
                    externalFilterChanged(node.name, "Expenses");
                  }
                  // console.log({
                  //   node,
                  // });
                  if (node.type === "categories") {
                    setSelectedCategory(node.name.split(":")[1]);
                    setSelectedAccount(undefined);
                  } else if (node.type === "accounts") {
                    setSelectedCategory(undefined);
                    setSelectedAccount(node.name);
                  }
                  setTx1Account(undefined);
                  setTx2Account(undefined);
                }}
                style={{ cursor: "pointer" }}
                onMouseEnter={(e) => {
                  setTooltip({
                    show: true,
                    content: `${node.name} (${
                      node.category
                    })\nTotal Flow: ${formatValue(
                      node.value
                    )}\nInflow: ${formatValue(
                      node.targetLinks?.reduce(
                        (sum, link) => sum + link.value,
                        0
                      ) || 0
                    )}\nOutflow: ${formatValue(
                      node.sourceLinks?.reduce(
                        (sum, link) => sum + link.value,
                        0
                      ) || 0
                    )}\nClick to view transactions`,
                    x: e.clientX,
                    y: e.clientY,
                  });
                }}
                onMouseLeave={() => setTooltip({ ...tooltip, show: false })}
              >
                <rect
                  x={node.x0}
                  y={node.y0}
                  width={node.x1 - node.x0}
                  height={node.y1 - node.y0}
                  fill="#666"
                  opacity={0.8}
                />
                <text
                  x={
                    node.category === "expense"
                      ? node.x0 - 6
                      : node.x0 < width / 2
                      ? node.x1 + 6
                      : node.x0 - 6
                  }
                  y={(node.y1 + node.y0) / 2}
                  dy="0.35em"
                  textAnchor={
                    node.category === "expense"
                      ? "end"
                      : node.x0 < width / 2
                      ? "start"
                      : "end"
                  }
                  fontSize={12}
                  style={{ fill: "black" }}
                >
                  {node.name}
                  <tspan
                    x={
                      node.category === "expense"
                        ? node.x0 - 6
                        : node.x0 < width / 2
                        ? node.x1 + 6
                        : node.x0 - 6
                    }
                    dy="1.2em"
                  >
                    {formatValue(Math.abs(node.value))}
                  </tspan>
                </text>
              </g>
            ))}
          </g>
        </svg>

        {tooltip.show && (
          <div
            style={{
              position: "absolute",
              top: tooltip.y,
              left: tooltip.x,
              background: "rgba(0, 0, 0, 0.8)",
              color: "white",
              padding: "8px",
              borderRadius: "4px",
              fontSize: "12px",
              pointerEvents: "none",
              whiteSpace: "pre-line",
              transform: "translate(10px, -50%)",
              zIndex: 1000,
            }}
          >
            {tooltip.content}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="sankey-charts">
      <div style={{ width: "100%" }}>
        <D3Sankey data={data2} />
      </div>

      <div className="h3 mt-4">Transactions</div>

      <Row>
        {/* <Col>
          {isAdmin && (
            <Button variant="link" onClick={onBtnExport}>
              <FontAwesomeIcon icon="download" />
              <span className="ml-1">Export as CSV</span>
            </Button>
          )}
        </Col> */}

        <Col>
          Filter by
          <ExternalFilter
            columnDefs={externalFilterColumns}
            gridApi={gridApi.current}
          />
          <Row className="align-items-center">
            <div className="filter-info ml-3">
              {(selectedAccount ||
                selectedCategory ||
                tx1Account ||
                tx2Account) && <span>Filter: </span>}
              {selectedAccount && selectedCategory && (
                <span>
                  Account: {selectedAccount}, Category: {selectedCategory}
                </span>
              )}
              {selectedAccount && !selectedCategory && (
                <span>Account: {selectedAccount}</span>
              )}
              {selectedCategory && !selectedAccount && (
                <span>Category: {selectedCategory}</span>
              )}
              {tx1Account && tx2Account && (
                <span>
                  Transfer: {tx1Account} ↔ {tx2Account}
                </span>
              )}
            </div>
            {(selectedAccount ||
              selectedCategory ||
              tx1Account ||
              tx2Account) && (
              <Button
                variant="link"
                onClick={() => {
                  // externalFilterChanged(undefined, undefined);
                  setSelectedCategory(undefined);
                  setSelectedAccount(undefined);
                  setTx1Account(undefined);
                  setTx2Account(undefined);
                }}
              >
                <FontAwesomeIcon icon="trash" />
                <span className="ml-1">Clear Filters</span>
              </Button>
            )}
          </Row>
        </Col>
      </Row>

      <Row>
        <div className="ag-theme-alpine" style={{ height: 600, width: "100%" }}>
          <AgGridReact
            singleClickEdit
            defaultColDef={defaultColDef}
            ref={ref as any}
            // rowData={rowData}
            columnDefs={columnDefs}
            isExternalFilterPresent={() => true}
            doesExternalFilterPass={filterFn}
            pagination={true}
            paginationPageSize={pagination.pageSize}
            rowModelType={"infinite"}
            cacheBlockSize={pagination.pageSize}
            cacheOverflowSize={2}
            maxConcurrentDatasourceRequests={1}
            infiniteInitialRowCount={1}
            maxBlocksInCache={2}
            rowData={undefined}
            onGridReady={onGridReady}
          ></AgGridReact>
        </div>
      </Row>
      {/* {isAdmin && (
        <Row>
          <SupersetDash
            dashboardName={"flowOfFunds"}
            height={"600px"}
            width={"100%"}
            reload={true} // change dinamically to reload the dashboard
          />
        </Row>
      )} */}
    </div>
  );
}

export default FlowOfFunds;

import React, { useState } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { getJobTransactionsDateGaps } from "../../../../docuclipper/api";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../../redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AgTable from "../AnalyzeTransactionsTable/AgTable";
import { flatten } from "lodash";
import moment from "moment";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { SizeMe } from "react-sizeme";

var filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const dateAsString = cellValue;
    if (dateAsString === null) return -1;
    const cellDate = moment(dateAsString, "YYYY-MM").toDate();
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
};

type Props = {};
export const DateGaps: React.FC<Props> = ({}) => {
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const { job } = useSelector((state: ReduxState) => state.JobData);

  const [dateGaps, setDateGaps] = React.useState({});

  const accounts = Object.keys(dateGaps);
  const months: { [k: string]: boolean } =
    accounts.length > 0 ? dateGaps[accounts[0]] : [];

  const jobId = job ? job.id : "";

  const getGaps = () => {
    if (jobId) {
      getJobTransactionsDateGaps(jobId, startDate, endDate)
        .then((dateGaps) => setDateGaps(dateGaps))
        .catch((e) => null);
    }
  };

  const findDefaultDateRange = (gaps: any) => {
    let earliest = "9999-12";
    let latest = "0000-01";

    Object.values(gaps).forEach((account: any) => {
      const months = Object.keys(account);
      months.forEach((month) => {
        if (month < earliest) earliest = month;
        if (month > latest) latest = month;
      });
    });

    return { earliest, latest };
  };

  React.useEffect(() => {
    if (jobId) {
      getGaps();
    }
  }, [jobId]);

  React.useEffect(() => {
    if (Object.keys(dateGaps).length > 0 && !startDate && !endDate) {
      const { earliest, latest } = findDefaultDateRange(dateGaps);
      setStartDate(earliest + "-01");
      setEndDate(latest + "-01");
    }
  }, [dateGaps, startDate, endDate]);

  const getScatterData = () => {
    const scatterData: any[] = [];
    accounts.forEach((account, accountIndex) => {
      Object.entries(dateGaps[account] || {}).forEach(([month, hasData]) => {
        scatterData.push({
          x: new Date(month + "-01").getTime(),
          y: accountIndex,
          account,
          month,
          hasData,
          fill: hasData ? "#4CAF50" : "#f44336",
          shape: "rect",
          width: 15,
          height: 30,
        });
      });
    });
    return scatterData;
  };

  const data = flatten(
    Object.keys(dateGaps || {}).map((account) =>
      Object.keys(dateGaps[account] || {}).map((month) => ({
        account,
        month,
        value: dateGaps[account][month],
      }))
    )
  );

  const [columnDefs] = useState([
    {
      headerName: "Account",
      field: "account",
      filter: "agSetColumnFilter",
      filterParams: {
        // pass in additional parameters to the Text Filter
      },
    },
    {
      headerName: "Month",
      field: "month",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
    },
    {
      headerName: "Has Data",
      field: "value",
      filter: "agSetColumnFilter",
      filterParams: {
        // pass in additional parameters to the Number Filter
      },
      cellRenderer: function (params) {
        const cellValue = params.value.toString(); // Get the value to display in the cell
        if (cellValue === "true") {
          return <FontAwesomeIcon icon="check" />;
        } else {
          return <FontAwesomeIcon icon="times" />;
        }
      },
    },
  ]);

  const CustomShape = (props: any) => {
    const { cx, cy, fill } = props;
    return <rect x={cx - 7.5} y={cy - 10} width={15} height={20} fill={fill} />;
  };

  return (
    <div>
      <Row className="mt-4">
        <Col md="2">
          <Form.Group>
            <Form.Label>Start Date</Form.Label>
            <Form.Control
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col md="2">
          <Form.Group>
            <Form.Label>End Date</Form.Label>
            <Form.Control
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col md="4" className="text-right m-1">
          <Button
            onClick={() => {
              getGaps();
            }}
          >
            Calculate Date Gaps
          </Button>
        </Col>
      </Row>

      <div className="mt-4">
        {accounts.length > 0 && (
          <Row>
            <Col lg={3}>
              <AgTable
                hasExport={true}
                columnDefs={columnDefs}
                rowData={data}
                height={400}
                width="100%"
              />
            </Col>

            <Col lg={9}>
              <div style={{ width: "100%", height: "400px" }}>
                <SizeMe>
                  {({ size }) => (
                    <div style={{ width: "100%", height: "400px" }}>
                      <ScatterChart
                        width={size.width || 800}
                        height={400}
                        margin={{ top: 20, right: 20, bottom: 40, left: 200 }}
                      >
                        <CartesianGrid />
                        <XAxis
                          type="number"
                          dataKey="x"
                          domain={["auto", "auto"]}
                          tickFormatter={(unixTime) =>
                            moment(unixTime).format("YYYY-MM")
                          }
                          name="Month"
                        />
                        <YAxis
                          type="number"
                          dataKey="y"
                          name="Account"
                          ticks={accounts.map((_, i) => i)}
                          tickFormatter={(index) => accounts[index]}
                          padding={{ top: 20, bottom: 20 }}
                          domain={[-0.5, accounts.length - 0.5]}
                          interval={0}
                        />
                        <Tooltip
                          content={({ payload }) => {
                            if (!payload || !payload[0]) return null;
                            const data = payload[0].payload;
                            return (
                              <div
                                style={{
                                  backgroundColor: "white",
                                  padding: "10px",
                                  border: "1px solid #ccc",
                                }}
                              >
                                <p>{`${data.account} - ${data.month}`}</p>
                                <p>{data.hasData ? "Has Data" : "No Data"}</p>
                              </div>
                            );
                          }}
                        />
                        <Scatter
                          data={getScatterData()}
                          shape={<CustomShape />}
                        />
                      </ScatterChart>
                    </div>
                  )}
                </SizeMe>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};
export default DateGaps;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Accordion, Button, Card, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { rateJob } from "../../../docuclipper/api";
import { ReduxState } from "../../../redux";
import { createAlert } from "../../../redux/reducers/Alerts";
import { closeModal } from "../../../redux/reducers/ModalManager";

import Drawer from "react-modern-drawer";

//import styles 👇
import "react-modern-drawer/dist/index.css";
import { setExportType } from "../../../redux/reducers/JobExport";
import CsvHelp from "./help/CsvHelp";
import { FaqItem } from "./FaqItem";
import { DescribeIssue } from "./HelpMenu";
import { setHelpDrawerOpen } from "../../../redux/reducers/HelpDrawer";
import { useJobInfo } from "../../../custom-hooks/useJobInfo";
export const HelpDrawer = () => {
  const { exportType } = useSelector((state: ReduxState) => state.JobExport);
  const { job } = useSelector((state: ReduxState) => state.JobData);
  const { open } = useSelector((state: ReduxState) => state.HelpDrawer);

  const { redoneAsGeneric, missingBank } = useJobInfo();
  const dispatch = useDispatch();

  const toggleDrawer = () => {
    dispatch(setHelpDrawerOpen(!open));
    dispatch(setExportType(null));
  };

  const dontShowLS =
    localStorage.getItem("docuclipper.helpDrawer.dontShow") === "true";
  const [dontShow, setDontShow] = React.useState(dontShowLS || false);

  return (
    <Drawer
      open={open}
      onClose={toggleDrawer}
      direction="right"
      className=""
      size="35vw"
      zIndex={10000}
      style={{ overflow: "auto" }}
    >
      <div className="p-4">
        {/* {(exportType as any) === "csv" && <CsvHelp />} */}

        <div className="m-2 text-right">
          <Form.Check
            type="checkbox"
            label="Don't show this help menu again"
            checked={dontShow}
            onChange={(e) => {
              localStorage.setItem(
                "docuclipper.helpDrawer.dontShow",
                e.target.checked ? "true" : "false"
              );
              setDontShow(e.target.checked);
              //   toggleDrawer();
            }}
          />
        </div>

        <div className="mb-5">
          <div className="h2">Frequently Asked Questions</div>
        </div>

        {!redoneAsGeneric && (
          <>
            <div className="h3">General</div>

            <FaqItem
              heading={() => "Where can I find the downloaded files?"}
              body={() => (
                <div>
                  You can find all the files DocuClipper has downloaded in the
                  "Downloads" folder.
                </div>
              )}
            />

            {job?.type !== "Invoice" && (
              <FaqItem
                heading={() => "What are the supported output formats?"}
                body={() => (
                  <div>
                    <div className="my-2">
                      DocuClipper supports the following output formats:
                    </div>
                    <div className="my-2">
                      <div className="font-weight-bold">Excel spreadsheets</div>
                      <div className="mx-2">XLSX</div>
                      <div className="mx-2">CSV</div>
                    </div>
                    <div className="font-weight-bold my-2">
                      QBO Web Connect files
                    </div>
                    <div className="my-2">
                      <div>
                        Many other accounting systems and third party apps
                      </div>
                    </div>
                  </div>
                )}
              />
            )}
            {job?.type !== "Invoice" && (
              <>
                <FaqItem
                  heading={() => "A statement didn't reconcile"}
                  body={() => (
                    <>
                      <div className="my-2">
                        Make sure the start and end balances are correct (they
                        are in the summary table, right of the page). You can
                        edit the values by clicking on them.
                      </div>
                      <div className="my-2">
                        Make sure all transactions are present and have the
                        right values.
                      </div>
                      <div className="my-2">
                        Sometimes DocuClipper can't reconcile documents that
                        contain multiple accounts or multiple statements in a
                        single file. For better results try to process each
                        statement separately.
                      </div>
                      <div className="m-2">
                        <a
                          href="https://www.docuclipper.com/docs/ui/user-guide/resolving-issues/reconciliation/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon="question-circle" />
                          <span className="ml-1">
                            Read more on how to reconcile the statements
                          </span>
                          <FontAwesomeIcon
                            className="ml-2"
                            icon="external-link-alt"
                          />
                        </a>
                      </div>
                    </>
                  )}
                />
                <FaqItem
                  heading={() => "The year is wrong"}
                  body={() => (
                    <div>
                      Make sure the start and end dates have been detected
                      correctly (they are in the summary table, right of the
                      page). You can edit the values by clicking on them.
                    </div>
                  )}
                />
                <FaqItem
                  heading={() => "A few transactions are wrong/missing"}
                  body={() => (
                    <div>
                      <div>
                        You can fix the transaction by clicking on the value (on
                        the transactions table, left of the page). For a
                        transaction to be considered valid, the date needs to be
                        valid and the amount must be a numeric value. You can
                        also add missing transactions by clicking on "Add
                        transaction" (left of the table).
                      </div>
                    </div>
                  )}
                />

                <FaqItem
                  heading={() =>
                    "Many transactions are wrong/missing or full pages/sections are missing"
                  }
                  body={() => (
                    <div>
                      <div>
                        Email{" "}
                        <a href="mailto:support@docuclipper.com">
                          support@docuclipper.com
                        </a>{" "}
                        to get help.
                      </div>
                    </div>
                  )}
                />

                <div className="h3">QuickBooks</div>
                <FaqItem
                  heading={() =>
                    "How do I import the QBO Web Connect file into QuickBooks?"
                  }
                  body={() => (
                    <>
                      <div>
                        <div className="m-2">
                          <a
                            href="https://www.docuclipper.com/docs/ui/user-guide/extracting-data/import-data-quickbooks-online/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FontAwesomeIcon icon="question-circle" />
                            <span className="ml-1">
                              Documentation on how to import the data into
                              QuickBooks Online.
                            </span>
                            <FontAwesomeIcon
                              className="ml-2"
                              icon="external-link-alt"
                            />
                          </a>
                        </div>
                        <div className="m-2">
                          <a
                            href="https://www.docuclipper.com/docs/ui/user-guide/extracting-data/import-data-quickbooks-desktop/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FontAwesomeIcon icon="question-circle" />
                            <span className="ml-1">
                              Documentation on how to import the data into
                              QuickBooks Desktop.
                            </span>
                            <FontAwesomeIcon
                              className="ml-2"
                              icon="external-link-alt"
                            />
                          </a>
                        </div>
                      </div>
                    </>
                  )}
                />
                <FaqItem
                  heading={() =>
                    "Import error: Unable to Verify Financial Institution"
                  }
                  body={() => (
                    <div>
                      QuickBooks displays this error when a number in the QBO
                      Web Connect file is incorrect. Email{" "}
                      <a href="mailto:support@docuclipper.com">
                        support@docuclipper.com
                      </a>{" "}
                      to get help.
                    </div>
                  )}
                />
                <FaqItem
                  heading={() => "QuickBooks doesn't show the account"}
                  body={() => (
                    <div>
                      Make sure you enter the right Routing and Account numbers
                      when downloading the QBO Web Connect file.
                    </div>
                  )}
                />
                <FaqItem
                  heading={() => "QuickBooks doesn't support the account type"}
                  body={() => (
                    <div>
                      Make sure you select the right account type (e.g.{" "}
                      <span className="font-weight-bold">Credit Card</span>)
                      when downloading the QBO Web Connect file.
                    </div>
                  )}
                />
                <FaqItem
                  heading={() => "No new transactions"}
                  body={() => (
                    <div>
                      The transactions in the QBO Web Connect file have already
                      been imported.
                    </div>
                  )}
                />
                <FaqItem
                  heading={() => "Other Import Errors"}
                  body={() => (
                    <div>
                      <div>
                        Here are the instruction on how to import the QuickBooks
                        Web Connect file into QuickBooks:
                      </div>
                      <div>
                        <div className="m-2 mx-4">
                          <a
                            href="https://www.docuclipper.com/docs/ui/user-guide/extracting-data/import-data-quickbooks-online/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FontAwesomeIcon icon="question-circle" />
                            <span className="ml-1">
                              Documentation on how to import the data into
                              QuickBooks Online.
                            </span>
                            <FontAwesomeIcon
                              className="ml-2"
                              icon="external-link-alt"
                            />
                          </a>
                        </div>
                        <div className="m-2 mx-4">
                          <a
                            href="https://www.docuclipper.com/docs/ui/user-guide/extracting-data/import-data-quickbooks-desktop/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <FontAwesomeIcon icon="question-circle" />
                            <span className="ml-1">
                              Documentation on how to import the data into
                              QuickBooks Desktop.
                            </span>
                            <FontAwesomeIcon
                              className="ml-2"
                              icon="external-link-alt"
                            />
                          </a>
                        </div>
                      </div>
                      <div>
                        If you are unable to import the QBO Web Connect file you
                        can also try importing a CSV file instead or contacting
                        DocuClipper support (
                        <a href="mailto:support@docuclipper.com">
                          support@docuclipper.com
                        </a>
                        ) or Intuit support.
                      </div>
                    </div>
                  )}
                />
              </>
            )}
          </>
        )}
        <div className="mt-3" style={{ marginBottom: "6rem" }}>
          <div className="h3">Still need help?</div>

          <DescribeIssue
            cta="Report Issue"
            description={`Describe the issue in as much detail as possible. Include document names, page numbers and any other information to help the support team solve the issue`}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default HelpDrawer;

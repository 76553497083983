import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "..";
import {
  getAnnouncements,
  trackAnnouncementEvent,
} from "../../docuclipper/api";

type Announcement = {
  id: string;
  title: string;
  subtitle: string;
  imageKey: any;
  link: string;
  externalLink: string;
};
const initialState: {
  announcement: Announcement | null;
} = {
  announcement: null,
};

const slice = createSlice({
  name: "Announcement",
  initialState,
  reducers: {
    setAnnouncement(state, action: PayloadAction<Announcement>) {
      state.announcement = action.payload;
    },
    clearAnnouncement(state) {
      state.announcement = null;
    },
  },
});

export const { setAnnouncement, clearAnnouncement } = slice.actions;

export const fetchAnnouncement = () => async (dispatch, getState) => {
  const state: ReduxState = getState();
  try {
    const announcement = await getAnnouncements();
    dispatch(setAnnouncement(announcement));
    if (announcement) {
      // track event, announcementId, click
      trackAnnouncementEvent({
        eventType: "load",
        announcementId: announcement.id,
      }).catch((err) => null);
    }
  } catch (err) {
    //
  }
};

export default slice.reducer;

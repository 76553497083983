import { getOnboardingSteps } from "../../docuclipper/api";

export type OnboardingStep = {
  completed: boolean;
  name: string;
  title: string;
  description: string;
  order: number;
  link: string;
};
const initialState: {
  steps: OnboardingStep[];
  loading: boolean;
  error: string | null;
} = {
  steps: [],
  loading: false,
  error: null,
};

export const SET_STEPS = "SET_STEPS";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_STEPS:
      return {
        ...state,
        steps: [...action.payload.steps],
        loading: action.payload.loading,
        error: action.payload.error,
      };
    default:
      return state;
  }
}

export type SetStepsFn = (steps: OnboardingStep[]) => any;

export const setSteps = (steps: OnboardingStep[]) => ({
  type: SET_STEPS,
  payload: {
    steps,
  },
});

export type fetchOnboardingStepsFn = () => any;

export const fetchOnboardingSteps = () => async (dispatch) => {
  try {
    dispatch({
      type: SET_STEPS,
      payload: {
        steps: [],
        error: null,
        loading: true,
      },
    });

    const steps = await getOnboardingSteps();

    dispatch({
      type: SET_STEPS,
      payload: {
        steps,
        error: null,
        loading: false,
      },
    });
  } catch (err) {
    dispatch({
      type: SET_STEPS,
      payload: {
        steps: [],
        error: err.message,
        loading: false,
      },
    });
  }
};

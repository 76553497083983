// src/Pages/views/Invoices/InvoiceContainer.tsx
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { groupBy } from "lodash";
import { updateInvoiceField } from "src/redux/reducers/InvoiceManager";
import InvoiceQbo from "./InvoiceQbo";
import InvoiceExcel from "./InvoiceExcel";
import { ReduxState } from "src/redux";
import { Invoice2 } from "src/docuclipper/DocuclipperTypes";
import { Col, FormGroup, Row, Form, Button } from "react-bootstrap";
import ExportTypeInvoiceDropdown from "../DataArchive/TransactionManager/DownloadOptions/ExportTypeInvoiceDropdown";
import { DownloadInvoiceModal } from "../DataArchive/TransactionManager/DownloadInvoiceModal";
import { Formik } from "formik";
import { ApprovalHistory } from "./ApprovalHistory";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const InvoiceContainer: React.FC = () => {
  const dispatch = useDispatch();
  const [showHistory, setShowHistory] = useState(false);

  const { documentId, transactionLineRowNumber, transactionLines } =
    useSelector((state: ReduxState) => state.InvoiceManager);
  const exportTo = useSelector(
    (state: ReduxState) => state.DownloadInvoiceOptions.selectedExportType
  );

  const selectedDocumentId =
    documentId || transactionLines[0]?.documentId || "";

  const tlsByDocId = groupBy(transactionLines, "documentId");

  if (transactionLineRowNumber === null) return null;

  const selectedTransactionLine =
    tlsByDocId?.[selectedDocumentId]?.[transactionLineRowNumber];
  const invoice = selectedTransactionLine?.row
    ? (JSON.parse(selectedTransactionLine.row as any) as Invoice2)
    : null;

  if (!invoice) return null;

  const handleFieldChange = (field: string, value: any) => {
    dispatch(updateInvoiceField({ invoice, field, value }));
  };

  const getMarginTop = (index, lineItem, margin, defaultMargin) => {
    if (index !== 0) {
      return defaultMargin;
    }
    if (
      ["bill", "expense"].includes(invoice.docType) &&
      (lineItem.categoryEdited || lineItem.categoryAutomated)
    ) {
      return margin;
    }
    if (
      ["invoice"].includes(invoice.docType) &&
      (lineItem.serviceEdited || lineItem.serviceAutomated)
    ) {
      return margin;
    }
    if (
      ["payables"].includes(invoice.docType) &&
      (lineItem.categoryExcelEdited || lineItem.categoryExcel)
    ) {
      return margin;
    }
    if (
      ["receivables"].includes(invoice.docType) &&
      (lineItem.serviceExcelEdited || lineItem.serviceExcel)
    ) {
      return margin;
    }
    return defaultMargin;
  };

  const documentTypeOptions =
    exportTo === "qbo"
      ? ["expense", "invoice", "bill"]
      : ["payables", "receivables"];

  const documentTypeLabels = {
    expense: "Expense",
    invoice: "Invoice",
    bill: "Bill",
    payables: "Bills/Payables",
    receivables: "Invoices/Receivables",
  };

  return (
    <>
      <Formik
        initialValues={{
          docType: invoice?.docType || documentTypeOptions[0],
        }}
        enableReinitialize={true}
        onSubmit={(values) => {
          //
        }}
      >
        {({ values, setFieldValue }) => (
          <>
            <Row>
              <Col>
                <FormGroup className="invoice-tour-export-to">
                  <strong>
                    <Form.Label>Export To</Form.Label>
                  </strong>
                  <ExportTypeInvoiceDropdown />
                </FormGroup>
              </Col>
              <Col>
                <Form.Group
                  controlId="select-document-type"
                  className="invoice-tour-document-type"
                >
                  <Form.Label>
                    <strong>Document Type</strong>
                  </Form.Label>
                  <Form.Control
                    as="select"
                    value={values.docType}
                    onChange={(e) => {
                      setFieldValue("docType", e.target.value);
                      handleFieldChange("docType", e.target.value);
                    }}
                  >
                    {documentTypeOptions.map((type) => (
                      <option key={type} value={type}>
                        {documentTypeLabels[type]}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
          </>
        )}
      </Formik>
      {
        <div>
          <div className="invoice-tour-fields">
            {exportTo === "qbo" ? (
              <InvoiceQbo
                key={`${documentId}-${transactionLineRowNumber}`}
                invoice={invoice}
                getMarginTop={getMarginTop}
                tl={selectedTransactionLine}
              />
            ) : (
              <InvoiceExcel
                key={`${documentId}-${transactionLineRowNumber}`}
                invoice={invoice}
                transactionLines={transactionLines}
                getMarginTop={getMarginTop}
              />
            )}
          </div>
        </div>
      }
      {exportTo !== "qbo" && <DownloadInvoiceModal />}
      {/* <Button
        variant="outline-secondary"
        size="sm"
        onClick={() => setShowHistory(true)}
        className="ml-2"
      >
        <FontAwesomeIcon icon="history" className="mr-1" />
        History
      </Button>
      <ApprovalHistory
        show={showHistory}
        onHide={() => setShowHistory(false)}
        transactionLineId={selectedTransactionLine?.id}
      /> */}
    </>
  );
};

export default InvoiceContainer;

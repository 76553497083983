import csvStringify from "csv-stringify/lib/es5/sync";
import { saveAs } from "file-saver";
import { getIn } from "formik";
import zip from "lodash/zip";
import moment from "moment";
import React, { useState } from "react";
import { Form, FormControl, Table } from "react-bootstrap";
import ReactGA from "react-ga4";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Job, Rectangle } from "../docuclipper/DocuclipperTypes";
import { parseCsvToRowsAndColumn } from "../react-csv-to-table/utils";
import { ReduxState } from "../redux";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import uuidv4 from "uuid/v4";

ReactGA.initialize("G-TMN0RY3CC7");

export function trackSignupGA4(method) {
  ReactGA.event("sign_up_frontend", {
    // method: method, // e.g., 'Email', 'Google', 'Facebook'
  });
}

export function trackPurchaseGA4(value, currency) {
  ReactGA.event("purchase_frontend", {
    transaction_id: uuidv4(), // Unique ID for the transaction
    value: value, // Numerical value of the transaction
    currency: currency, // Currency code, e.g., 'USD'
    // You can add more parameters here as needed
  });
}

export const formikInput = ({
  field,
  form: { touched, errors },
  children,
  ...props
}) => {
  return (
    <>
      <Form.Control
        isInvalid={!!(touched[field.name] && errors[field.name])}
        {...field}
        {...props}
      >
        {children}
      </Form.Control>

      {touched[field.name] && errors[field.name] && (
        <FormControl.Feedback type="invalid">
          {errors[field.name]}
        </FormControl.Feedback>
      )}
    </>
  );
};

export const formikTextarea = ({ field, form: { touched, errors }, ...props }) => (
  <div>
    <Form.Control
      as="textarea"
      rows={3}
      isInvalid={!!(touched[field.name] && errors[field.name])}
      {...field}
      {...props}
    />
    {touched[field.name] && errors[field.name] && (
      <FormControl.Feedback type="invalid">
        {errors[field.name]}
      </FormControl.Feedback>
    )}
  </div>
);

export const formikArrayInput = ({
  field,
  form: { touched, errors },
  children,
  ...props
}) => {
  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);
  return (
    <>
      <Form.Control isInvalid={touch && error} {...field} {...props}>
        {children}
      </Form.Control>

      {touch && error && (
        <FormControl.Feedback type="invalid">{error}</FormControl.Feedback>
      )}
    </>
  );
};

export const formikTextArea = ({
  field,
  form: { touched, errors },
  children,
  ...props
}) => {
  return (
    <>
      <Form.Control
        as="textarea"
        isInvalid={!!(touched[field.name] && errors[field.name])}
        {...field}
        {...props}
      >
        {children}
      </Form.Control>

      {touched[field.name] && errors[field.name] && (
        <FormControl.Feedback type="invalid">
          {errors[field.name]}
        </FormControl.Feedback>
      )}
    </>
  );
};

export const formikSelectInput = ({
  field,
  form: { touched, errors },
  children,
  ...props
}) => {
  return (
    <>
      <Form.Control
        as="select"
        isInvalid={!!(touched[field.name] && errors[field.name])}
        {...field}
        {...props}
      >
        {children}
      </Form.Control>

      {touched[field.name] && errors[field.name] && (
        <FormControl.Feedback type="invalid">
          {errors[field.name]}
        </FormControl.Feedback>
      )}
    </>
  );
};

export const formikReactSelectInput = ({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  options,
  children,
  ...props
}) => {
  return (
    <>
      <Select
        {...props}
        options={options}
        name={field.name}
        value={options.find((option) => option.value === field.value) || ""}
        onChange={(selectedOption) => {
          // Set the value of the field to the value of the selected option
          setFieldValue(field.name, selectedOption ? selectedOption.value : "");
        }}
        onBlur={() => {
          // Mark the field as touched
          setFieldTouched(field.name, true);
        }}
        // react-select uses `isClearable` prop to allow clearing the selection
        isClearable
      />
      {touched[field.name] && errors[field.name] && (
        <div className="text-danger">{errors[field.name]}</div>
      )}
    </>
  );
};

export const CreatableSelectField = ({
  field,
  form,
  initialOptions,
  isMulti,
}) => {
  const [options, setOptions] = useState(initialOptions);
  const { touched, errors, setFieldValue } = form;

  const handleChange = (newValue, actionMeta) => {
    newValue = newValue || [];
    if (actionMeta.action === "create-option") {
      setOptions(newValue);
    }

    const value = isMulti ? newValue.map((item) => item.value) : newValue.value;
    setFieldValue(field.name, value);
  };

  const getValue = () => {
    if (!options || !field.value) {
      return isMulti ? [] : "";
    }
    if (isMulti) {
      return options.filter((option) => field.value.includes(option.value));
    }
    return options.find((option) => option.value === field.value);
  };

  const showError = touched[field.name] && errors[field.name];

  return (
    <Form.Group>
      <CreatableSelect
        {...field}
        isMulti={isMulti}
        options={options}
        onChange={handleChange}
        value={getValue()}
        className={showError ? "is-invalid" : ""}
      />
      {showError && (
        <FormControl.Feedback type="invalid">
          {errors[field.name]}
        </FormControl.Feedback>
      )}
    </Form.Group>
  );
};

export const formikCheckInput = ({
  field,
  form: { touched, errors },
  children,
  ...props
}) => (
  <>
    <Form.Check
      isInvalid={!!(touched[field.name] && errors[field.name])}
      {...field}
      {...props}
    >
      {children}
    </Form.Check>
  </>
);

export const sendGAEvent = ({ action, category, label }) => {
  // if (action) {
  //   ReactGA.set({
  //     ...getFields(),
  //     // sessionId
  //     dimension6:
  //       new Date().getTime() + "." + Math.random().toString(36).substring(5),
  //   });
  //   ReactGA.event({
  //     category: category || "UI",
  //     label,
  //     action,
  //   });
  // }
};

export const fetchXHR = (
  url,
  opts: any = {},
  onProgress
): Promise<Response> => {
  return new Promise((res, rej) => {
    const headerMap = {};

    const xhr = new XMLHttpRequest();
    xhr.open(opts.method || "get", url);
    for (const k in opts.headers || {})
      xhr.setRequestHeader(k, opts.headers[k]);
    xhr.onload = (e: any) => {
      res({
        json: () => Promise.resolve(JSON.parse(e.target.responseText)),
        text: () => Promise.resolve(e.target.responseText),
        status: e.target.status,
        headers: {
          get: (name) => {
            const name2 = name.toLowerCase();
            return name2 in headerMap ? headerMap[name2] : "";
          },
        },
      } as Response);
    };
    xhr.onerror = rej;
    xhr.onreadystatechange = function () {
      if (this.readyState === this.HEADERS_RECEIVED) {
        // Get the raw header string
        const headers = xhr.getAllResponseHeaders();

        // Convert the header string into an array
        // of individual headers
        const arr = headers.trim().split(/[\r\n]+/);

        // Create a map of header names to values

        arr.forEach(function (line) {
          const parts = line.split(": ");
          const header = parts.shift();
          const value = parts.join(": ");
          if (header) {
            headerMap[header.toLowerCase()] = value;
          }
        });
      }
    };

    if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
    xhr.send(opts.body);
  });
};

export const fetchWithTimeout = (
  url: string,
  options,
  timeoutMs: number = 30000,
  onprogress?: (e) => any
) => {
  let didTimeOut = false;

  return new Promise<Response>((resolve, reject) => {
    const timeout = setTimeout(() => {
      didTimeOut = true;
      reject(new Error("Operation timed out"));
    }, timeoutMs);

    const fn = onprogress ? fetchXHR : fetch;
    return fn(url, options, onprogress)
      .then((response) => {
        // Clear the timeout as cleanup
        clearTimeout(timeout);
        if (!didTimeOut) {
          // console.log('fetch good! ', response);
          resolve(response);
        }
      })
      .catch((err) => {
        // console.log("fetch failed! ", err);

        // Rejection already happened with setTimeout
        if (didTimeOut) {
          return;
        }
        // Reject with error
        reject(err);
      });
  });
};

export const sendGATiming = (label: string, value: number) => {
  ReactGA.timing({
    category: "docuclipper",
    variable: "API request",
    value, // in milliseconds
    label,
  });
};

export const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const goTo = (href: string) => (window.location.href = href);
export const goToNewTab = (href: string) =>
  window.open(
    href,
    "_blank" // <- This is what makes it open in a new window.
  );

export const emptyFn = () => null;

export const daysUntilTimestamp = (ts) => {
  const a = moment.unix(ts);
  const b = moment(new Date());
  return a.diff(b, "days"); // =1
};

export const replaceForDomId = (s: string) => {
  return s.replace(/^[^a-z]+|[^\w:.-]+/gi, "z");
};

export const saveAsCsv = (data) => {
  const blob = new Blob([data], {
    type: "text/csv;charset=utf-8",
  });
  saveAs(blob, "file.csv");
};
export const saveAsXlsx = (data) => {
  const blob = new Blob([data], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
  });
  saveAs(blob, "file.xlsx");
};

export const updateCell = (
  table: string,
  newText: string,
  row: number,
  col: number
) => {
  const rows = parseCsvToRowsAndColumn((table || "").trim());

  if (rows && rows.length > 0 && row < rows.length && col < rows[0].length) {
    rows[row][col] = newText;
    return csvStringify(rows);
  } else {
    return table;
  }
};

export const fitToRange = (value: number, min: number, max: number) => {
  if (value < min) {
    return min;
  }
  if (value > max) {
    return max;
  }
  return value;
};

export const isValidVlocation = (rect: Rectangle) => {
  const isValidY0 =
    rect.vlocation.y0HeightPercentage !== null ||
    (rect.vlocation.y0Words && rect.vlocation.y0Words.length > 0);
  const isValidY1 =
    rect.vlocation.y1HeightPercentage !== null ||
    (rect.vlocation.y1Words && rect.vlocation.y1Words.length > 0);

  const isValidVlocation = !rect.vlocationEnabled || (isValidY0 && isValidY1);
  return isValidVlocation;
};

export const goToGoogle = () => goTo(`/api/v1/auth/google`);
export const goToFacebook = () => goTo(`/api/v1/auth/facebook`);
export const goToMicrosoft = () => goTo(`/api/v1/auth/facebook`);

export const isNullOrUndefined = (value) =>
  value === null || value === undefined;

export const onProgress = (cb) => (e) => {
  if (e.total === 0) {
    return 0;
  }
  const progress = ((e.loaded / e.total) * 100).toFixed(0);
  cb(progress);
};

export const mergeRows = (row1: string[], row2: string[], separator = " ") => {
  if (row1.length !== row2.length) {
    throw new Error("cant merge rows of different sizes");
  }
  return zip(row1, row2).map((x) => x.join(separator).trim());
};

export const mergeRowsInTableHelper = (
  table: string[][],
  i: number,
  j: number
) => {
  if (j !== i + 1) {
    throw new Error("can only merge consecutive rows");
  }
  const rows = table.splice(i, j - i + 1);
  const newRow = mergeRows(rows[0], rows[1], " ");
  table.splice(i, 0, newRow);
  return table;
};

export const mergeRowsInTable = (
  table: string[][],
  selected: { [k: number]: boolean }
) => {
  let newRows = table;
  let numMerged = 0;
  for (const i of Object.keys(selected)) {
    const ii = parseInt(i, 10);
    if (selected[ii] && selected[ii + 1]) {
      const insertIndex = ii - numMerged;
      newRows = mergeRowsInTableHelper(newRows, insertIndex, insertIndex + 1);
      numMerged += 1;
    }
  }
  return newRows;
};

export const deleteRowsInTable = (
  table: string[][],
  selected: { [k: number]: boolean }
) => {
  let newRows = table.filter((row, i) => !(i in selected) || !selected[i]);
  return newRows;
};

export const listToMap = (list: any[]) =>
  list.reduce((map, item) => {
    map[item] = true;
    return map;
  }, {});

export const jsonAsTable = (row) => {
  return (
    <Table striped={true} bordered={true}>
      <tbody>
        {Object.keys(row.value || {}).map((key, i) => (
          <tr key={i}>
            <td style={{ padding: 0 }}>{key}</td>
            <td style={{ padding: 0 }}>{row.value[key]}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export const string2number = (s: string): number => {
  let hash = 0;
  let i;
  let chr;

  if (s.length === 0) return hash;
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const breakpoints = {
  xs: "0",
  sm: "576px",
  md: "768px",
  lg: "992px",
  xl: "1200px",
  xxl: "1400px",
};

export function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function hasQboHelper(job: Job) {
  if (!job) {
    return false;
  }
  if (job.isBankMode) {
    return true;
  }

  const { template, id } = job;

  let hasQbo = false;
  if (template && template.template) {
    try {
      const templateObj = JSON.parse(template?.template);
      hasQbo = !!templateObj.qbo;
      // console.log({ hasQbo });
    } catch (err) {
      // console.error(err);
    }
  }
  if (job && job.isBankMode) {
    hasQbo = true;
  }
  return hasQbo;
}
export function useHasQbo() {
  const { job } = useSelector((state: ReduxState) => state.JobData);

  return job ? hasQboHelper(job) : false;
}

export function useIsCsv2Qbo() {
  const { job } = useSelector((state: ReduxState) => state.JobData);
  let isCsv2Qbo = false;
  try {
    if (job) {
      isCsv2Qbo = !!JSON.parse(job.metadata || "{}").csv2QboMapping;
    }
  } catch (err) {}
  return isCsv2Qbo;
}

export const amountFilterOptions = [
  { value: "", label: "" },
  { value: "lessThan", label: "<" },
  { value: "lessThanInclusive", label: "<=" },
  { value: "greaterThan", label: ">" },
  { value: "greaterThanInclusive", label: ">=" },
];


export function updateObjKeyValue(originalObj, updateObj) {
  for (const key in updateObj) {
    if (updateObj.hasOwnProperty(key)) {
      const value = updateObj[key];
      const matchValue = key.match(/^value-(.*)$/);
      const matchKey = key.match(/^key-(.*)$/);
      if (matchValue) {
        const originalKey = matchValue[1];
        if (originalObj.hasOwnProperty(originalKey)) {
          originalObj[originalKey] = value;
        }
      }
      if (matchKey) {
          const originalKey = matchKey[1];
          if (originalObj.hasOwnProperty(originalKey)) {
            const newValue = updateObj[key];
            const oldValue = originalObj[originalKey];
            delete originalObj[originalKey]; 
            originalObj[newValue] = oldValue;
          }
        }
    }
  }
  return originalObj;
}

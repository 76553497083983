import React from "react";
import { Badge } from "react-bootstrap";

type Props = {
  primaryCondition: string;
  secondaryCondition: string;
  styles?: any;
};

const CategorizationBadge: React.FC<Props> = ({
  primaryCondition,
  secondaryCondition,
  styles = null,
}) => {
  if (!primaryCondition && !secondaryCondition) return null;

  const text = primaryCondition ? "Manual Edit" : "Automated Rule";
  const variant = primaryCondition ? "primary" : "success";

  const defaultStyles = { position: "absolute", margin: "-1.2rem 0" };

  return (
    <div style={styles ? { ...styles } : defaultStyles}>
      <Badge variant={variant}>{text}</Badge>
    </div>
  );
};

export default CategorizationBadge;

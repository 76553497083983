import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, Formik } from "formik";
import queryString from "query-string";
import React from "react";
import { Alert, Col, Form, FormGroup, Row } from "react-bootstrap";
import { Experiment, Variant } from "react-optimize";
import { connect } from "react-redux";
import { RouteComponentProps, useLocation, withRouter } from "react-router-dom";
import * as Yup from "yup";
import { logUserIn } from "../../../auth";
import { doSignup, trackSignup } from "../../../docuclipper/api";
import { logErrorToGA } from "../../../docuclipper/Logger";
import { createAlert, CreateAlertFn } from "../../../redux/reducers/Alerts";
import {
  formikInput,
  goToFacebook,
  goToGoogle,
  goToMicrosoft,
  sendGAEvent,
} from "../../../utils/utils";
import AlertBanner from "../../Docuclipper/AlertBanner/AlertBanner";
import { LoadingTrackedButton } from "../../Docuclipper/TrackedButton";
import ContinueWithSocial from "../Login/ContinueWithSocial";
import Logo from "../Login/Logo";
import { Helmet } from "react-helmet";

type SignupFieldsProps = {
  onClick: (fields: any) => any;
  btnCta: string;
  loading: boolean;
  email?: string | null;
};

export const SignupFields: React.FC<SignupFieldsProps> = ({
  onClick,
  btnCta,
  loading,
  email,
}) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const guestToken = queryParams.get("guestToken");

  return (
    <Formik
      initialValues={{
        email: email || "",
        password: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        companyName: "",
        jobTitle: "",
        guestToken,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .required("Email is required")
          .email("Invalid email"),
        password: Yup.string()
          .required("Password is required")
          .min(8, "Password is too short"),
        firstName: Yup.string() /*.required("First name is required")*/,
        lastName: Yup.string() /*.required("Last name is required")*/,
        phoneNumber: Yup.string(),
      })}
      onSubmit={(fields) => {
        onClick(fields);
      }}
      render={({ errors, status, isValid, submitForm, values }) => {
        const onClick = () => {
          submitForm();
        };
        return (
          <>
            <Helmet>
              ‍<title>DocuClipper signup</title>‍
              <meta
                name="description"
                content="Sign up for a free 14-day trial"
              />
            </Helmet>
            <Form>
              <Field
                type="hidden"
                name="guestToken"
                value={values.guestToken}
              />

              <Experiment id="CGzJ-ClJTEuNYx0U0PSMfA">
                <Variant id="0">
                  <Row>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Form.Label>
                          <strong>First Name</strong>
                        </Form.Label>
                        <Field
                          placeholder="First Name"
                          name="firstName"
                          type="text"
                          component={formikInput}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup className="mb-3">
                        <Form.Label>
                          <strong>Last name</strong>
                        </Form.Label>
                        <Field
                          placeholder="Last Name"
                          name="lastName"
                          type="text"
                          component={formikInput}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Variant>
              </Experiment>
              {/* <Row>
          <Col md="6">
            <FormGroup className="mb-3">
              <Form.Label><strong>Company name</strong></Form.Label>
              <Field
                placeholder="Company name (optional)"
                name="companyName"
                type="text"
                component={formikInput}
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup className="mb-3">
              <Form.Label><strong>Job Title</strong></Form.Label>
              <Field
                placeholder="Job title (optional)"
                name="jobTitle"
                type="text"
                component={formikInput}
              />
            </FormGroup>
          </Col>
        </Row> */}

              <Row>
                <Col md="12">
                  <FormGroup>
                    <Form.Label>
                      <strong>Email</strong>
                    </Form.Label>
                    <Field
                      placeholder="Email"
                      name="email"
                      type="email"
                      component={formikInput}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <FormGroup>
                    <Form.Label>
                      <strong>Password</strong>
                    </Form.Label>
                    <Field
                      placeholder="Password"
                      name="password"
                      type="password"
                      component={formikInput}
                    />
                  </FormGroup>
                </Col>
              </Row>

              {/* <Row>
      <Col md="12">
        <FormGroup>
          <Form.Label><strong>Phone number</strong></Form.Label>
          <Field
            placeholder="Phone number"
            name="phoneNumber"
            component={formikInput}
          />
        </FormGroup>
      </Col>
    </Row> */}

              <Row style={{ display: "flex", justifyContent: "center" }}>
                <LoadingTrackedButton
                  isLoading={loading}
                  action="signupB2C"
                  className="btn--signup-b2c text-center"
                  onClick={onClick}
                  variant="primary"
                  style={{
                    fontWeight: "bold",
                    // padding: "0.75rem"
                  }}
                >
                  {btnCta}
                </LoadingTrackedButton>
              </Row>
              <Row
                className="justify-content-around px-5 mt-2"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  maxWidth: "450px",
                }}
              >
                <div className="mx-2">
                  <FontAwesomeIcon icon="check" />
                  <span className="ml-1">14 day free trial</span>
                </div>
                <div className="mx-2">
                  <FontAwesomeIcon icon="check" />
                  <span className="ml-1">No credit card required</span>
                </div>
                {/* <div className="mx-2">
                <FontAwesomeIcon icon="check" />
                <span className="ml-1">Cancel anytime</span>
              </div> */}
              </Row>
            </Form>
          </>
        );
      }}
    />
  );
};
type Values = {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
};

type Props = {
  btnCta?: string;
};

type ReduxProps = {
  createAlert: CreateAlertFn;
};

export const SignupB2C: React.SFC<Props & ReduxProps & RouteComponentProps> = ({
  history,
  location,
  btnCta,
  createAlert,
}) => {
  const [loading, setLoading] = React.useState(false);

  const {
    utm_source,
    utm_campign,
    utm_medium,
    utm_term,
    utm_content,
    logo,
    coupon,
    header,
    email,
  } = queryString.parse(location.search);

  React.useEffect(() => {
    const { error } = queryString.parse(location.search);

    if (error) {
      const tokens = (error as string).split("\n");
      createAlert({
        type: "error",
        message: (
          <>
            <span>{error}</span>
            <br></br>
            <span>
              Email <b>support@docuclipper.com</b> to get help
            </span>
          </>
        ),
        timeout: 0,
      });
    }
  }, []);
  const onClick = ({
    email,
    password,
    firstName,
    lastName,
    companyName,
    jobTitle,
    guestToken,
  }) => {
    setLoading(true);
    doSignup({
      email: email,
      password,
      firstName,
      lastName,
      utm_source: (utm_source as string) || "",
      utm_campign: (utm_campign as string) || "",
      utm_medium: (utm_medium as string) || "",
      utm_term: (utm_term as string) || "",
      utm_content: (utm_content as string) || "",
      companyName,
      jobTitle,
      coupon: coupon as string,
      guestToken,
    })
      .then((rsp) => {
        trackSignup();
        sendGAEvent({
          action: "signupSuccess",
          category: "other",
          label: "",
        });
        setLoading(false);
        if (rsp.token) {
          logUserIn(rsp.token);
          history.push("/extractdata");
        }
        if (window && (window as any).lintrk) {
          (window as any).lintrk("track", { conversion_id: 10568564 });
        }
      })
      .catch((err) => {
        logErrorToGA(err);
        createAlert({
          message: err.message || "Error signing up",
          timeout: 0,
          type: "error",
        });
        sendGAEvent({
          action: "signupError",
          category: "other",
          label: "",
        });
        setLoading(false);
      });
  };

  return (
    <div
      className="px-4"
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        // marginTop: "4rem",
        maxWidth: "600px",
      }}
    >
      <Row>
        <Col md="12">
          <div className="mt-2">
            <Row>
              <Col>
                <Logo
                  fill="#3f6ad8"
                  stroke="3f6ad8"
                  style={{
                    display: "block",
                    margin: "auto",
                    height: "50px",
                    width: "120px",
                  }}
                />
              </Col>
              {logo && (
                <Col className="text-center">
                  <img alt="Logo" src={logo as string} height="40px" />
                </Col>
              )}
            </Row>
          </div>

          <div className="p-4 bg-white">
            <div className="text-center my-4">
              {header && (
                <Alert variant="success">
                  {((header as any) || "").split("\n").map((x) => (
                    <>
                      {x}
                      <br />
                    </>
                  ))}
                </Alert>
              )}
              <div className="h4 font-weight-bold">
                Let's create your account
              </div>
              <div>
                Already have an account? <a href="/login">Sign in</a>
              </div>
            </div>
            <div className="mt-2 mb-2">
              <AlertBanner />
            </div>
            <SignupFields
              onClick={onClick}
              btnCta={btnCta || "Start my free trial"}
              loading={loading}
              email={email as string}
            />
            <div style={{ marginTop: "2rem" }}>
              <ContinueWithSocial
                goToFacebook={goToFacebook}
                goToGoogle={goToGoogle}
                goToMicrosoft={goToMicrosoft}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

SignupB2C.defaultProps = {
  btnCta: "Start my free trial",
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {
  createAlert,
})(withRouter(SignupB2C));

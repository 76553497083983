import uuidv4 from "uuid/v4";
import {
  EdgeType,
  KonvaLine,
  KonvaRectangle,
  TemplateFieldV2,
} from "../../docuclipper/DocuclipperTypes";
import { doRectangleInverseMath } from "../../rectangle-math";
import { TemplateFieldState } from "./TemplateFieldTypes";

export const computeLine = ({
  edge,
  state,
  percentage,
}: {
  edge: EdgeType;
  state;
  percentage: number;
}) => {
  let line: KonvaLine;
  if (["top", "bottom"].includes(edge)) {
    // horizontal line
    line = {
      draggable: true,
      type: "horizontalFullWidth",
      horizontalLine: {
        y: state.stage ? (state.stage.height * percentage) / 100 : 0,
        maxY: null,
        minY: null,
        scaleX: 1.0,
        scaleY: 1.0,
        x0: null,
        x1: null,
      },
      verticalLine: null,
      id: uuidv4(),
      stroke: edge2color(edge),
      strokeWidth: 5,
      text: "",
      dash: [10, 10],
    };
  } else {
    line = {
      draggable: true,
      type: "verticalFullHeight",
      verticalLine: {
        x: state.stage ? (state.stage.width * percentage) / 100 : 0,
        maxX: null,
        minX: null,
        scaleX: 1.0,
        scaleY: 1.0,
        y0: null,
        y1: null,
      },
      horizontalLine: null,
      id: uuidv4(),
      stroke: edge2color(edge),
      strokeWidth: 5,
      text: "",
      dash: [10, 10],
    };
  }
  return line;
};

export const edge2color = (edge: EdgeType) => {
  switch (edge) {
    case "top":
      return "red";
    case "bottom":
      return "yellow";
    case "left":
      return "green";
    case "right":
      return "blue";
  }
};

export const mapTemplateFieldFromServerToClient = (
  templateField: TemplateFieldV2,
  state: TemplateFieldState,
  dispatch
): TemplateFieldV2 => {
  let tf: TemplateFieldV2 | null = null;
  if (!state.stage) {
    throw new Error("Stage not ready");
  }
  if (!state.page.page) {
    throw new Error("Page not ready");
  }
  if (templateField.locationType === "fixed" && templateField.fixedLocation) {
    const { height, width, scaleY, scaleX, x, y } = doRectangleInverseMath(
      templateField.fixedLocation.pageLocation,
      {
        stageHeight: state.stage.height,
        stageWidth: state.stage.width,
      },
      {
        pageHeight: state.page.page.height,
        pageWidth: state.page.page.width,
      }
    );

    const rectangle: KonvaRectangle = {
      draggable: true,
      fill: "yellow",
      height,
      id: uuidv4(),
      scaleX,
      scaleY,
      templateFieldId: templateField.id,
      width,
      x,
      y,
    };
    const fixedLocation = {
      ...templateField.fixedLocation,
      rectangle,
    };

    // table

    tf = {
      ...templateField,
      fixedLocation,
      variableLocation: !templateField.variableLocation
        ? null
        : {
            ...templateField.variableLocation,
            selectedId:
              templateField.variableLocation.variableLocations.length > 0
                ? templateField.variableLocation.variableLocations[0].id
                : "",
          },
      tableConfig: {
        ...templateField.tableConfig,
        columns: templateField.tableConfig.columns.map((c, i) => {
          let nextX: number | null = null;
          if (i < templateField.tableConfig.columns.length - 1) {
            nextX =
              x +
              (width *
                templateField.tableConfig.columns[i + 1]
                  .columnWidthPercentage) /
                100;
          }
          let prevX: number | null = null;
          if (i > 0) {
            prevX =
              x +
              (width *
                templateField.tableConfig.columns[i - 1]
                  .columnWidthPercentage) /
                100;
          }

          return {
            ...c,
            line: {
              dash: null,
              draggable: true,
              horizontalLine: null,
              id: uuidv4(),
              stroke: "blue",
              strokeWidth: 5,
              text: c.name,
              type: "vertical",
              verticalLine: {
                maxX: nextX,
                minX: prevX,
                scaleX: 1.0,
                scaleY: 1.0,
                x: x + (width * c.columnWidthPercentage) / 100,
                y0: y,
                y1: y + height,
              },
            },
          };
        }),
      },
    };
  } else if (
    templateField.locationType === "variable" &&
    templateField.variableLocation &&
    templateField.variableLocation.variableLocations.length > 0
  ) {
    // TODO use actions, don't repeat logic
    tf = {
      ...templateField,
      tableConfig: {
        ...templateField.tableConfig,
        columns: templateField.tableConfig.columns.map((c, i) => {
          return {
            ...c,
            line: {
              dash: null,
              draggable: true,
              horizontalLine: null,
              id: uuidv4(),
              stroke: "blue",
              strokeWidth: 5,
              text: c.name,
              type: "vertical",
              verticalLine: {
                maxX: 0,
                minX: 0,
                scaleX: 1.0,
                scaleY: 1.0,
                x: 0,
                y0: 0,
                y1: 0,
              },
            },
          };
        }),
      },
      variableLocation: {
        ...templateField.variableLocation,
        selectedId: templateField.variableLocation.variableLocations[0].id,
        variableLocations: templateField.variableLocation.variableLocations.map(
          (vl) => {
            return {
              ...vl,
              forbiddenWordsEnabled: (vl.forbiddenWords || []).length > 0,
              forbiddenWords: (vl.forbiddenWords || []).map((fw) => ({
                ...fw,
                id: uuidv4(),
              })),
              top: {
                ...vl.top,
                words: !vl.top.words
                  ? null
                  : {
                      ...vl.top.words,
                      loading: false,
                      error: null,
                      lines: [],
                      includeWordsChecked: vl.top.words?.yPosition === "top",
                      wordType: "y",
                    },
                percentage: !vl.top.percentage
                  ? null
                  : {
                      ...vl.top.percentage,
                      line: computeLine({
                        edge: "top",
                        percentage: vl.top.percentage.percentage,
                        state,
                      }),
                    },
              },
              bottom: {
                ...vl.bottom,
                words: !vl.bottom.words
                  ? null
                  : {
                      ...vl.bottom.words,
                      loading: false,
                      error: null,
                      lines: [],
                      includeWordsChecked:
                        vl.bottom.words?.yPosition === "bottom",
                      wordType: "y",
                    },
                percentage: !vl.bottom.percentage
                  ? null
                  : {
                      ...vl.bottom.percentage,
                      line: computeLine({
                        edge: "bottom",
                        percentage: vl.bottom.percentage.percentage,
                        state,
                      }),
                    },
              },
              left: {
                ...vl.left,
                words: !vl.left.words
                  ? null
                  : {
                      ...vl.left.words,
                      loading: false,
                      error: null,
                      lines: [],
                      includeWordsChecked: vl.left.words?.xPosition === "left",
                      wordType: "x",
                    },
                percentage: !vl.left.percentage
                  ? null
                  : {
                      ...vl.left.percentage,
                      line: computeLine({
                        edge: "left",
                        percentage: vl.left.percentage.percentage,
                        state,
                      }),
                    },
              },
              right: {
                ...vl.right,
                words: !vl.right.words
                  ? null
                  : {
                      ...vl.right.words,
                      loading: false,
                      error: null,
                      lines: [],
                      includeWordsChecked: vl.left.words?.xPosition === "left",
                      wordType: "x",
                    },
                percentage: !vl.right.percentage
                  ? null
                  : {
                      ...vl.right.percentage,
                      line: computeLine({
                        edge: "right",
                        percentage: vl.right.percentage.percentage,
                        state,
                      }),
                    },
              },
            };
          }
        ),
      },
    };
  } else {
    throw new Error(`Unexpected locationType: ${templateField.locationType}`);
  }
  tf.fragments = {
    loading: false,
    error: null,
    fragments: [],
    selectedFragmentId: null,
  };
  return tf;
};

export const mapTemplateFieldFromClientToServer = (
  templateField: TemplateFieldV2
): TemplateFieldV2 => {
  const copy = JSON.parse(JSON.stringify(templateField));
  if (copy && copy.fixedLocation) {
    delete copy.fixedLocation.rectangle;
  }

  // fragments
  delete copy.fragments;

  // table
  copy.tableConfig.columns = copy.tableConfig.columns.map((col) => {
    delete col.line;
    return col;
  });

  if (copy.tableConfig.transforms.verticalMergeMasksEnabled) {
    copy.tableConfig.transforms.alignMiddle = null;
  }
  if (copy.tableConfig.transforms.alignMiddleEnabled) {
    copy.tableConfig.transforms.verticalMergeMasks = null;
  }
  delete copy.tableConfig.transforms.verticalMergeMasksEnabled;
  delete copy.tableConfig.transforms.alignMiddleEnabled;

  // vl
  if (copy.variableLocation) {
    delete copy.variableLocation.selectedId;
    for (const vl of copy.variableLocation.variableLocations) {
      delete vl.forbiddenWordsEnabled;
      for (const fw of vl.forbiddenWords || []) {
        delete fw.id;
      }
      for (const edge of ["top", "bottom", "left", "right"]) {
        delete vl[edge].words.loading;
        delete vl[edge].words.error;
        delete vl[edge].words.lines;
        delete vl[edge].words.includeWordsChecked;
        if (vl[edge].percentage) {
          delete vl[edge].percentage.line;
        }
      }
    }
  }

  return copy;
};

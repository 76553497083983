// src/redux/reducers/InvoiceManager.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DocType, Document, Invoice2, QboAccount, QboCustomer, QboVendor, TransactionLine } from "../../docuclipper/DocuclipperTypes";
import { cloneDeep, uniq } from "lodash";
import {
  createQboBill2,
  createQboExpense2,
  createQboInvoice2,
  getJobTransactions,
  runInvoiceRules,
  updateJobTransaction,
} from "../../docuclipper/api";
import { ReduxState } from "..";
import { isNullOrUndefined } from "src/utils/utils";
import { createAlert } from "./Alerts";

const initialState: {
  documentId: string | null;
  transactionLineRowNumber: number | null;
  transactionLines: TransactionLine[];
  loading: boolean;
  importToQboLoading: boolean;
  runInvoiceRulesLoading: boolean;
  error: string | null;
  documents: Document[];
  downloadOptions: {
    selectedDocumentIds: string[];
    selectedFieldNames: string[];
  };
  showModalInvoiceRules: boolean;
  validationErrors: Record<string, {
    valid: boolean;
    errors: {
      property: string;
      message: string;
      name: string;
    }[];
  }>;
  duplicateError: {
    qboUrl: string;
    docType: DocType;
  } | null;
  selectedExportFields: string[];
} = {
  documentId: null,
  transactionLineRowNumber: 0,
  transactionLines: [],
  loading: false,
  importToQboLoading: false,
  runInvoiceRulesLoading: false,
  error: null,
  documents: [],
  downloadOptions: {
    selectedDocumentIds: [],
    selectedFieldNames: [],
  },
  showModalInvoiceRules: false,
  validationErrors: {},
  duplicateError: null,
  selectedExportFields: [],
};

const invoiceManagerSlice = createSlice({
  name: "InvoiceManager",
  initialState,
  reducers: {
    setDocumentId(state, action: PayloadAction<string>) {
      state.documentId = action.payload;
      state.transactionLineRowNumber = 0;
    },
    setTransactionLineRowNumber(state, action: PayloadAction<number>) {
      state.transactionLineRowNumber = action.payload;
    },
    updateTransactionLines(state, action: PayloadAction<TransactionLine[]>) {
      state.transactionLines = action.payload;
    },
    loadTls(state) {
      state.loading = true;
      state.error = null;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
    setDocuments(state, action: PayloadAction<Document[]>) {
      state.documents = action.payload;
    },
    setDownloadOptionsSelectedDocumentIds(
      state,
      action: PayloadAction<string[]>
    ) {
      state.downloadOptions.selectedDocumentIds = action.payload;
    },
    setDownloadOptionsSelectedFieldNames(
      state,
      action: PayloadAction<string[]>
    ) {
      state.downloadOptions.selectedFieldNames = action.payload;
    },
    setImportToQboLoading(state, action: PayloadAction<boolean>) {
      state.importToQboLoading = action.payload;
    },
    setShowModalInvoiceRules(state, action: PayloadAction<boolean>) {
      state.showModalInvoiceRules = action.payload;
    },
    setRunInvoiceRulesLoading(state, action: PayloadAction<boolean>) {
      state.runInvoiceRulesLoading = action.payload;
    },
    setValidationErrors(state, action: PayloadAction<Record<string, {
      valid: boolean;
      errors: {
        property: string;
        message: string;
        name: string;
      }[];
    }>>) {
      state.validationErrors = action.payload;
    },
    setDuplicateError(state, action: PayloadAction<{
      qboUrl: string;
      docType: DocType;
    } | null>) {
      state.duplicateError = action.payload;
    },
    setSelectedExportFields(state, action: PayloadAction<string[]>) {
      state.selectedExportFields = action.payload;
    },
  },
});

export const {
  setDocumentId,
  setTransactionLineRowNumber,
  updateTransactionLines,
  setLoading,
  setError,
  setDocuments,
  setDownloadOptionsSelectedDocumentIds,
  setDownloadOptionsSelectedFieldNames,
  setImportToQboLoading,
  setShowModalInvoiceRules,
  setRunInvoiceRulesLoading,
  setValidationErrors,
  setDuplicateError,
  setSelectedExportFields,
} = invoiceManagerSlice.actions;

export const fetchInvoiceTls = () => async (dispatch, getState) => {
  const state: ReduxState = getState();
  const job = state.JobData.job;

  if (!job) return;

  try {
    dispatch(setLoading(true));
    const { transactions: tls, documents, validations } = await getJobTransactions(job.id);
    const transactions: TransactionLine[] = tls.sort((a, b) => a.rowNumber - b.rowNumber);
    const allFields = uniq<string>(tls.map((x) => x.fieldName));
    dispatch(setDocuments(documents));
    if (tls.length > 0) {
      dispatch(setDocumentId(tls[0].documentId));
    }
    dispatch(updateTransactionLines(transactions));
    dispatch(
      setDownloadOptionsSelectedDocumentIds(
        uniq(tls.map((x) => x.documentId))
      )
    );
    dispatch(setDownloadOptionsSelectedFieldNames(allFields));


    dispatch(setValidationErrors(validations));
  } catch (err: any) {
    dispatch(setError(err.message || "Error getting job transactions"));
  } finally {
    dispatch(setLoading(false));
  }
};

export const handleOnUpdateJobTransaction = (invoice2: Invoice2, transactionLine: TransactionLine) => async (dispatch, getState) => {
  const state: ReduxState = getState();
  const job = state.JobData.job;

  if (!job || !transactionLine?.id) return;

  try {
    const { transaction, validation } = await updateJobTransaction(
      job.id,
      Number(transactionLine.id),
      { newValue: JSON.stringify(invoice2) }
    );

    const selectedTlIndex = state.InvoiceManager.transactionLines.findIndex(
      (tl) => tl.documentId === state.InvoiceManager.documentId && tl.rowNumber === state.InvoiceManager.transactionLineRowNumber
    );

    dispatch(updateTransactionLines(
      state.InvoiceManager.transactionLines.map((tl, index) => {
        if (index === selectedTlIndex) {
          return {
            ...tl,
            row: JSON.stringify(invoice2) as any,
          }
        }
        return tl;
      })
    ));
    dispatch(setValidationErrors({ ...state.InvoiceManager.validationErrors, [transaction.id]: validation }))
  } catch (err) {
    console.error("Error updating invoice:", err);
  }
};

export const updateInvoiceField = ({ invoice, field, value }: { invoice: Invoice2, field: string, value: any }) => (dispatch, getState) => {
  const state: ReduxState = getState();
  const transactionLine = cloneDeep(state.InvoiceManager.transactionLines.find(
    (tl) => tl.documentId === state.InvoiceManager.documentId && tl.rowNumber === state.InvoiceManager.transactionLineRowNumber
  ));
  if (transactionLine) {
    invoice[field] = value;
    transactionLine.row = JSON.stringify(invoice) as any;
    dispatch(handleOnUpdateJobTransaction(invoice, transactionLine));
  }
};

export const updateInvoiceLineItem = (
  { invoice, lineIndex, field, value }: { invoice: Invoice2, lineIndex: number, field: string, value: any }
) => (dispatch, getState) => {
  const state: ReduxState = getState();
  const transactionLine = cloneDeep(state.InvoiceManager.transactionLines.find(
    (tl) => tl.documentId === state.InvoiceManager.documentId && tl.rowNumber === state.InvoiceManager.transactionLineRowNumber
  ));
  if (transactionLine) {
    invoice.lines[lineIndex][field] = ["quantity", "unitPrice", "price"].includes(field) && value
      ? parseFloat(value)
      : value;

    // Handle name fields automatically
    if (field === "categoryEdited") {
      invoice.lines[lineIndex].categoryEditedName = value ? state.QboAccountCategory.qboAccountCategory.find(acc => acc.value === value)?.name || "" : "";
      invoice.lines[lineIndex].categoryAutomated = "";
    }
    if (field === "serviceEdited") {
      invoice.lines[lineIndex].serviceEditedName = value ? state.QboItem.qboItems.find(item => item.value === value)?.name || "" : "";
      invoice.lines[lineIndex].serviceAutomated = "";
    }
    if (field === "categoryExcelEdited") {
      invoice.lines[lineIndex].categoryExcelAutomated = "";
    }
    if (field === "serviceExcelEdited") {
      invoice.lines[lineIndex].serviceExcelAutomated = "";
    }
    if (field === "taxEdited") {
      invoice.lines[lineIndex].taxEditedName = value ? state.QboTaxCode.qboTaxCodes.find(tax => tax.value === value)?.name || "" : "";
    }

    transactionLine.row = JSON.stringify(invoice) as any;
    dispatch(handleOnUpdateJobTransaction(invoice, transactionLine));
  }
};

export const handleImportToQbo = (values: Invoice2, forceImport: boolean = false) => async (dispatch, getState) => {
  const state: ReduxState = getState();
  const job = state.JobData.job;

  const selectedTl = state.InvoiceManager.transactionLines.find(
    (tl) => tl.documentId === state.InvoiceManager.documentId && tl.rowNumber === state.InvoiceManager.transactionLineRowNumber
  );

  const invoice = selectedTl ? JSON.parse(selectedTl.row as any) as Invoice2 : null;

  if (!job || !invoice) return;

  const linesCopy = values.lines.map(line => {
    return {
      ...line,
      category: line.categoryEdited || line.categoryAutomated,
      service: line.serviceEdited || line.serviceAutomated,
    }
  });

  const newValues = {
    ...values,
    lines: linesCopy
  };

  const qboCustomers = state.QboCustomer.qboCustomers
  const qboVendors = state.QboVendor.qboVendors
  const qboAccounts = state.QboAccount.qboAccounts

  if (invoice.docType === "invoice") {
    const c: QboCustomer[] = qboCustomers.filter(
      (x) => x.value === newValues.customer
    );
    if (!isNullOrUndefined(newValues.integration) && c.length > 0) {
      dispatch(setImportToQboLoading(true));
      createQboInvoice2(
        newValues.integration as any,
        selectedTl?.id as any,
        c[0],
        { forceImport }
      )
        .then((invoicereps) => {
          const updatedValues = {
            ...newValues,
            link: `https://app.qbo.intuit.com/app/${invoice.docType}?txnId=${invoicereps.invoice.Id}`,
            status: "published",
          };
          updateJobTransaction(job?.id, selectedTl?.id as any, {
            newValue: JSON.stringify(updatedValues),
          });

          dispatch(updateTransactionLines(
            state.InvoiceManager.transactionLines.map((tl) => {
              if (tl.id === selectedTl?.id) {
                return {
                  ...tl,
                  row: JSON.stringify(updatedValues) as any,
                }
              }
              return tl;
            })
          ));

          dispatch(
            createAlert({
              message: "Invoice created successfully",
              timeout: 0,
              type: "success",
            })
          );
          dispatch(setDuplicateError(null));
        })
        .catch((err) => {
          if (err?.errorCode === 1093) {
            dispatch(setDuplicateError({
              qboUrl: err.data.qboUrl,
              docType: "invoice"
            }));
          } else {
            dispatch(
              createAlert({
                message: err.message || "Error creating invoice",
                timeout: 0,
                type: "error",
              })
            );
          }
        }).finally(() => {
          dispatch(setImportToQboLoading(false));
        });
    }
  }

  if (invoice.docType === "bill") {
    const v: QboVendor[] = qboVendors.filter(
      (x) => x.value === newValues.vendor
    );
    // const a: QboAccount[] = qboAccounts.filter(
    //   (x) => x.value === newValues.account
    // );
    if (
      !isNullOrUndefined(newValues.integration) &&
      v.length > 0 &&
      // a.length > 0 &&
      selectedTl
    ) {
      dispatch(setImportToQboLoading(true));
      createQboBill2(
        newValues.integration as any,
        selectedTl.id as any,
        v[0],
        // a[0],
        { forceImport }
      )
        .then((bill) => {
          const updatedValues = {
            ...newValues,
            link: `https://app.qbo.intuit.com/app/${invoice.docType}?txnId=${bill.bill.Id}`,
            status: "published",
          };
          updateJobTransaction(job?.id, selectedTl.id as any, {
            newValue: JSON.stringify(updatedValues),
          });

          dispatch(updateTransactionLines(
            state.InvoiceManager.transactionLines.map((tl) => {
              if (tl.id === selectedTl?.id) {
                return {
                  ...tl,
                  row: JSON.stringify(updatedValues) as any,
                }
              }
              return tl;
            })
          ));

          dispatch(
            createAlert({
              message: "Bill created successfully",
              timeout: 0,
              type: "success",
            })
          );
          dispatch(setDuplicateError(null));
        })
        .catch((err) => {
          if (err?.errorCode === 1093) {
            dispatch(setDuplicateError({
              qboUrl: err.data.qboUrl,
              docType: "bill"
            }));
          } else {
            dispatch(
              createAlert({
                message: err.message || "Error creating bill",
                timeout: 0,
                type: "error",
              })
            );
          }
        }).finally(() => {
          dispatch(setImportToQboLoading(false));
        });
    }
  }

  if (invoice.docType === "expense") {
    // const a: QboAccount[] = qboAccounts.filter(
    //   (x) => x.value === newValues.account
    // );
    // const b: QboCustomer[] = qboCustomers.filter(
    //   (x) => x.value === newValues.customer
    // );
    const v: QboVendor[] = qboVendors.filter(
      (x) => x.value === newValues.vendor
    );
    const a: QboAccount[] = qboAccounts.filter(
      (x) => x.value === newValues.account
    );

    if (
      !isNullOrUndefined(newValues.integration) &&
      a.length > 0 &&
      selectedTl
    ) {
      dispatch(setImportToQboLoading(true));
      createQboExpense2(
        newValues.integration as any,
        selectedTl.id as any,
        a[0],
        v[0],
        { forceImport }
      )
        .then((expense) => {
          // console.log({ a, b })
          const updatedValues = {
            ...newValues,
            status: "published",
            link: `https://app.qbo.intuit.com/app/${invoice.docType}?txnId=${expense.purchase.Id}`,
          };
          updateJobTransaction(job?.id, selectedTl.id as any, {
            newValue: JSON.stringify(updatedValues),
          });

          dispatch(updateTransactionLines(
            state.InvoiceManager.transactionLines.map((tl) => {
              if (tl.id === selectedTl?.id) {
                return {
                  ...tl,
                  row: JSON.stringify(updatedValues) as any,
                }
              }
              return tl;
            })
          ));

          dispatch(
            createAlert({
              message: "Expense created successfully",
              timeout: 0,
              type: "success",
            })
          );
          dispatch(setDuplicateError(null));
        })
        .catch((err) => {
          if (err?.errorCode === 1093) {
            dispatch(setDuplicateError({
              qboUrl: err.data.qboUrl,
              docType: "expense"
            }));
          } else {
            dispatch(
              createAlert({
                message: err.message || "Error creating expense",
                timeout: 0,
                type: "error",
              })
            );
          }
        }).finally(() => {
          dispatch(setImportToQboLoading(false));
        });
    }
  }
};

export const applyRunInvoiceRules = (integrationId: number | null = null, docType: DocType) => async (dispatch, getState) => {
  try {
    const state: ReduxState = getState();
    const job = state.JobData.job;

    if (!job) return;

    dispatch(setRunInvoiceRulesLoading(true));
    const { newTls } = await runInvoiceRules(job.id, integrationId, docType)
    dispatch(updateTransactionLines(newTls));

    const mapTlsById = newTls.reduce((acc, tl) => {
      acc[tl.id] = tl;
      return acc;
    }, {});

    dispatch(updateTransactionLines(
      state.InvoiceManager.transactionLines.map((tl, index) => {
        const newTl = tl?.id ? mapTlsById[tl.id] : null;
        if (newTl) {
          return { ...tl, ...newTl };
        }
        return tl;
      })
    ));
  } catch (err) {
    console.error(err);
  } finally {
    dispatch(setRunInvoiceRulesLoading(false));
  }
};

const STORAGE_KEY = "invoice-export-selected-fields";

export const loadSavedExportFields = (availableFields: {
  regular: string[];
  extra: string[];
  lineItem: string[];
  lineItemExtra: string[];
}) => (dispatch) => {
  try {
    const savedFields = localStorage.getItem(STORAGE_KEY);
    if (savedFields) {
      const parsedFields = JSON.parse(savedFields);
      const validFields = parsedFields.filter((field: string) => {
        if (field.startsWith("lineItem.extra.")) {
          const extraField = field.replace("lineItem.extra.", "");
          return availableFields.lineItemExtra.includes(extraField);
        }
        if (field.startsWith("lineItem.")) {
          const lineField = field.replace("lineItem.", "");
          return availableFields.lineItem.includes(lineField);
        }
        if (field.startsWith("extra.")) {
          const extraField = field.replace("extra.", "");
          return availableFields.extra.includes(extraField);
        }
        return availableFields.regular.includes(field);
      });
      dispatch(setSelectedExportFields(validFields));
    }
  } catch (e) {
    console.warn("Failed to parse saved fields from localStorage");
  }
};

export default invoiceManagerSlice.reducer;
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Tour from 'reactour';
import tours from './tours.json'; // Adjust the path to your JSON file as needed

const ProductTour = () => {
    const [isTourOpen, setIsTourOpen] = useState(false);
    const [currentTour, setCurrentTour] = useState<any>(null);
    const location = useLocation();

    useEffect(() => {
        const currentPath = location.pathname;
        const tour = tours.find(tour => currentPath.includes(tour.URL));

        if (tour) {
            const completedTourKey = `completedTour_${tour.id}`;
            const hasCompletedTour = localStorage.getItem(completedTourKey);

            if (!hasCompletedTour) {
                setCurrentTour(tour);
                setIsTourOpen(true);
            }
        }
    }, [location]);

    const closeTour = () => {
        setIsTourOpen(false);
        // Mark the tour as completed in local storage using a unique key
        if (currentTour) {
            const completedTourKey = `completedTour_${currentTour.id}`;
            localStorage.setItem(completedTourKey, 'true');
        }
    };

    return null;
    return currentTour ? (
        <Tour
            isOpen={isTourOpen}
            steps={currentTour.steps}
            onRequestClose={closeTour}
            rounded={5} // Example of adding additional props, customize as needed


            accentColor={'#5cb7b7'}
            disableInteraction={true} // Prevents user interaction with the highlighted element
            disableFocusLock={true} // Allows interaction with other elements outside the tour
            // Optionally, adjust the mask space to have some padding around the highlighted element if needed
            // maskSpace={5}
            // Customization to show the helper next to the element without overlaying the whole screen
            className="helper"
            nextButton={<button>Next</button>} // Customize as needed
            prevButton={<button>Prev</button>} // Customize as needed


        />
    ) : null;
};

export default ProductTour;

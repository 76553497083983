import React from "react";
import TrackedButton from "../TrackedButton";

export const getCustomUi = ({
  onConfirm,
  getMessage,
  cancelCTA,
  confirmCTA,
}) => ({ onClose }) => {
  const fn = () => {
    onConfirm();
    onClose();
  };
  return (
    <div className="react-confirm-alert-overlay">
      <div className="react-confirm-alert">
        <div className="react-confirm-alert-body" style={{ padding: "50px" }}>
          <h3>{getMessage()}</h3>
          <div className="pull-right">
            <TrackedButton
              action="alertCancelClick"
              variant="outline-secondary"
              className="ml-2"
              onClick={onClose}
            >
              {cancelCTA}
            </TrackedButton>
            <TrackedButton
              action="alertConfirmClick"
              className="ml-2"
              variant="danger"
              onClick={fn}
            >
              {confirmCTA}
            </TrackedButton>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toggleErrors } from "../../../../redux/reducers/TransactionManager";

type Props = {};
export const ShowDiscardedLines: React.SFC<Props> = ({}) => {
  const [show, setShow] = React.useState(false);

  const dispatch = useDispatch();

  return (
    <>
      <Form.Check
        type="checkbox"
        label="Show discarded lines"
        checked={show}
        onChange={(e) => {
          dispatch(toggleErrors());
          setShow(e.target.checked);
        }}
      />
    </>
  );
};
export default ShowDiscardedLines;

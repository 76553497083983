import React from "react";
import { Alert, Button, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import {
  DocuClipperPlan,
  StepWizardProps,
  StripePlan,
  StripeSubscription,
} from "../../../../docuclipper/DocuclipperTypes";
import { ReduxState } from "../../../../redux";

type Props = {
  plans: StripePlan[];
  onConfirmed: (plan: DocuClipperPlan) => any;
  subscription: StripeSubscription;
  selectedPlan: DocuClipperPlan;
};
export const _ConfirmDowngrade: React.SFC<
  Props & StepWizardProps & RouteComponentProps
> = ({ plans, nextStep, onConfirmed, history, subscription, selectedPlan }) => {
  const { user, loading } = useSelector((state: ReduxState) => state.User);
  if ((plans || []).length === 0) {
    return null;
  }

  if (loading) {
    return <ClipLoader loading={loading} />;
  }
  const starterPages = plans.filter(
    (p) => p.docuclipperPlan.name === "Starter"
  )[0].docuclipperPlan.numPages;
  const businessPages = plans.filter(
    (p) => p.docuclipperPlan.name === "Business"
  )[0].docuclipperPlan.numPages;

  const professionalPages = plans.filter(
    (p) => p.docuclipperPlan.name === "Professional"
  )[0].docuclipperPlan.numPages;

  let pagesAfterDowngrade = starterPages;
  if (selectedPlan === "Professional") {
    pagesAfterDowngrade = professionalPages;
  } else if (selectedPlan === "Business") {
    pagesAfterDowngrade = businessPages;
  }
  let retention =
    "Jobs will only be saved for 30 days. Any older jobs will be automatically deleted.";
  switch (selectedPlan) {
    case "Starter":
      retention =
        "Future jobs will only be saved for 30 days. Any older jobs will be automatically deleted.";
      break;
    case "Professional":
      retention =
        "Future jobs will only be saved for 1 year. Any older jobs will be automatically deleted.";
      break;
    case "Business":
      retention =
        "Future jobs will only be saved for 2 years. Any older jobs will be automatically deleted.";
      break;
  }
  return (
    <>
      <div className="text-center justify-content-center">
        <div className="h4">{`Before you downgrade, ${
          user ? user.firstName : ""
        }`}</div>
        <div
          className="my-4"
          style={{ maxWidth: "400px", marginLeft: "auto", marginRight: "auto" }}
        >{`Remember that after downgrading to DocuClipper ${selectedPlan}:`}</div>

        <Alert variant="danger" className="m-2">
          {`Your account will go from ${subscription.docuclipperPlan.numPages} to ${pagesAfterDowngrade} pages per month.`}
        </Alert>

        <Alert variant="danger" className="m-2">
          {`You will lose access to premium support`}
        </Alert>

        <Alert variant="danger" className="m-2">
          {retention}
        </Alert>
        <Row className="mt-4 justify-content-center">
          {/* <div>
            
            <a href="mailto:support@docuclipper.com">
              <Button variant="primary">Contact support</Button>
            </a>
          </div> */}
          <div>
            <Button
              className="ml-2  btn--go-back-docuclipper"
              variant="primary"
              onClick={() => {
                history.push("/");
              }}
            >
              Go back to DocuClipper
            </Button>
          </div>
          <div>
            <Button
              className="ml-2 btn--still-want-downgrade"
              variant="outline-primary"
              onClick={() => {
                onConfirmed(selectedPlan);
              }}
            >
              I still want to downgrade
            </Button>
          </div>
        </Row>
      </div>
    </>
  );
};
export const ConfirmDowngrade = withRouter(_ConfirmDowngrade);
export default ConfirmDowngrade;

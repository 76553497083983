import React from "react";
import { Alert } from "react-bootstrap";
import ReactTable, { Column } from "react-table-6";
import { isNullOrUndefined } from "util";
import TableWithPreferences from "../../../custom-hooks/useTablePreferences";
import {
  ApiTableParams,
  FilteredItem,
  SortedItem,
  TableParams,
} from "../../../docuclipper/DocuclipperTypes";

type Props = {
  pageSize: number;
  onPageSizeChange: (...args) => any;
  filtered: FilteredItem[];
  onFilteredChange: (...args) => any;
  sorted: SortedItem[];
  onSortedChange: (...args) => any;
  page: number;
  onPageChange: (...args) => any;
};

type State<T> = {
  data: T[];
  loading: boolean;
  error: null | undefined | JSX.Element;
  numPages: number;
};

export class DocuClipperTable<T> extends React.Component<
  Props & TableWithPreferencesProps<T>,
  State<T>
> {
  static defaultProps = {
    errorElem: <span>Unable to fetch data</span>,
  };

  private table: any;

  constructor(props: Props & TableWithPreferencesProps<T>) {
    super(props);
    this.state = {
      data: [],
      loading: false,
      error: null,
      numPages: 0,
    };
    this.table = React.createRef();

    this.fetchDataFn = this.fetchDataFn.bind(this);
  }

  public componentDidUpdate(prevProps: Props & TableWithPreferencesProps<T>) {
    if (this.table && this.table.current && this.table.current.state) {
      const { page, pageSize, sorted, filtered } = this.table.current.state;
      if (prevProps.externalLoading && !this.props.externalLoading) {
        this.fetchDataFn({ page, pageSize, sorted, filtered }, null);
      }

      if (prevProps.pageSize !== this.props.pageSize) {
        this.fetchDataFn({ page, pageSize, sorted, filtered }, null);
      }
    }
  }

  public render() {
    const {
      columns,
      getTrProps,
      externalLoading,
      renderSearchBar,
      pageSize,
      filtered,
      sorted,
      onPageSizeChange,
      onFilteredChange,
      onSortedChange,
      onPageChange,
      page,
    } = this.props;

    const { error, data, numPages, loading } = this.state;

    if (error) {
      return <Alert variant="danger">{error}</Alert>;
    }

    return (
      <>
        {renderSearchBar ? renderSearchBar(this.fetchDataFn, this.table) : null}
        <ReactTable
          getTrProps={getTrProps}
          ref={this.table}
          columns={columns}
          data={data}
          pages={numPages} // Display the total number of pages
          loading={loading || externalLoading} // Display the loading overlay when we need it
          onFetchData={this.fetchDataFn} // Request new data when things change
          filterable={false}
          onPageSizeChange={onPageSizeChange}
          defaultSorted={sorted}
          defaultFiltered={filtered}
          defaultPageSize={pageSize}
          sorted={sorted}
          filtered={filtered}
          pageSize={pageSize}
          onFilteredChange={onFilteredChange}
          onSortedChange={onSortedChange}
          // className="-striped -highlight"
          className="-highlight"
          showPaginationTop={false}
          showPaginationBottom={true}
          manual={true}
          page={page}
          onPageChange={onPageChange}
        />
      </>
    );
  }

  private fetchDataFn = (state: TableParams, instance: any) => {
    if (
      isNaN(state.pageSize) ||
      isNaN(state.page) ||
      isNullOrUndefined(state.pageSize) ||
      isNullOrUndefined(state.page)
    ) {
      return;
    }
    this.setState({
      loading: true,
      error: null,
      data: [],
    });

    const { filtered, sorted } = state;
    return this.props
      .fetchData({
        filtered,
        sorted,
        limit: state.pageSize,
        offset: state.page * state.pageSize,
      })
      .then((res) => {
        this.setState({
          data: res.rows,
          numPages: Math.ceil(res.count / state.pageSize),
          loading: false,
        });
      })
      .catch((err) => {
        this.setState({ loading: false, error: this.props.errorElem });
      });
  };
}

type TableWithPreferencesProps<T> = {
  fetchData: ({
    offset,
    limit,
    filtered,
    sorted,
  }: ApiTableParams) => Promise<{ count: number; rows: any[] }>;

  errorElem?: JSX.Element;
  columns: Column<T>[];
  getTrProps?: (...args: any[]) => any;
  tableId: string;
  externalLoading?: boolean;
  renderSearchBar?: (
    fetchData: (state: TableParams, instance: any) => any,
    table: any
  ) => any;
  defaultPageSize: number;
  defaultSorted: SortedItem[];
  defaultFiltered: FilteredItem[];
  defaultPage?: number;
  syncUrl: boolean;
};

const TableWithPreferencesWrapper: <T extends any>(
  p: TableWithPreferencesProps<T>
) => React.ReactElement<TableWithPreferencesProps<T>> = ({
  tableId,
  defaultFiltered,
  defaultPageSize,
  defaultSorted,
  defaultPage,
  syncUrl,
  ...rest
}) => (
  <TableWithPreferences
    tableId={tableId}
    defaultFiltered={defaultFiltered}
    defaultSorted={defaultSorted}
    defaultPageSize={defaultPageSize}
    defaultPage={defaultPage}
    syncUrl={syncUrl}
  >
    {({
      pageSize,
      filtered,
      sorted,
      onPageSizeChange,
      onFilteredChange,
      onSortedChange,
      page,
      onPageChange,
    }) => (
      <DocuClipperTable
        tableId={tableId}
        {...rest}
        pageSize={pageSize}
        filtered={filtered}
        sorted={sorted}
        onPageSizeChange={onPageSizeChange}
        onFilteredChange={onFilteredChange}
        onSortedChange={onSortedChange}
        defaultFiltered={defaultFiltered}
        defaultPageSize={defaultPageSize}
        defaultSorted={defaultSorted}
        syncUrl={syncUrl}
        page={page}
        onPageChange={onPageChange}
        defaultPage={defaultPage}
      />
    )}
  </TableWithPreferences>
);

(TableWithPreferencesWrapper as any).defaultProps = {
  defaultPage: 0,
};
export default TableWithPreferencesWrapper;

import { PayloadAction } from "@reduxjs/toolkit";
import uuidv4 from "uuid/v4";
import {
  FieldType,
  TableColumnV2,
  TemplateFieldV2,
  VerticalLine,
} from "../../../../docuclipper/DocuclipperTypes";
import { doRectangleInverseMath } from "../../../../rectangle-math";
import { TemplateFieldState } from "../../TemplateFieldTypes";

export const _updateColumnWidthFromRectangle = (
  state: TemplateFieldState,
  action: PayloadAction<{
    x: number;
    lineX: number;
    id: string;
    lineId: string;
  }>
) => {
  const { id, x, lineX, lineId } = action.payload;
  state.templateFields = state.templateFields.map(
    (tf): TemplateFieldV2 => {
      if (tf.id !== id) {
        return tf;
      }

      tf.tableConfig.columns = tf.tableConfig.columns.map((c, i) => {
        if (c.line.id !== lineId) {
          return c;
        }
        if (!tf.fixedLocation) {
          return c;
        }

        let rectangle;
        if (tf.locationType === "fixed") {
          rectangle = tf.fixedLocation.rectangle;
        } else if (tf.locationType === "variable") {
          if (tf.fragments.fragments.length > 0) {
            rectangle = doRectangleInverseMath(
              tf.fragments.fragments[0].fragment,
              {
                stageHeight: state.stage?.height,
                stageWidth: state.stage?.width,
              },
              {
                pageHeight: (state.page as any).page.height,
                pageWidth: (state.page as any).page.width,
              }
            );
          }
        }

        let x2 = x;
        if (c.line && c.line.verticalLine) {
          if (c.line.verticalLine.maxX !== null) {
            x2 = Math.min(x, c.line.verticalLine.maxX);
          }

          if (c.line.verticalLine.minX !== null) {
            x2 = Math.max(x2, c.line.verticalLine.minX);
          }
        }

        if (i > 0) {
          if (
            tf.tableConfig.columns[i - 1].line &&
            tf.tableConfig.columns[i - 1].line.verticalLine
          ) {
            (tf.tableConfig.columns[i - 1].line
              .verticalLine as VerticalLine).maxX = x2;
          }
        }

        if (i < tf.tableConfig.columns.length - 1) {
          if (
            tf.tableConfig.columns[i + 1].line &&
            tf.tableConfig.columns[i + 1].line.verticalLine
          ) {
            (tf.tableConfig.columns[i + 1].line
              .verticalLine as VerticalLine).minX = x2;
          }
        }

        const columnWidthPercentage =
          100 *
          (Math.max(0, Math.min(x2 - rectangle.x, rectangle.width)) /
            rectangle.width);
        return {
          ...c,
          columnWidthPercentage,
          line: {
            ...c.line,
            verticalLine: {
              ...(c.line.verticalLine as VerticalLine),
              x: x2,
            },
          },
          // line: {
          //   ...c.line,
          //   verticalLine: {
          //     ...(c.line.verticalLine as VerticalLine),
          //     x: lineX - extraRight + extraLeft,
          //   },
          // },
        };
      });
      return tf;
    }
  );
};
export const _updateColumnWidth = (
  state: TemplateFieldState,
  action: PayloadAction<{
    columnIndex: number;
    percentage: number;
    id: string;
  }>
) => {
  const { id, percentage, columnIndex } = action.payload;
  state.templateFields = state.templateFields.map(
    (tf): TemplateFieldV2 => {
      if (tf.id !== id) {
        return tf;
      }

      tf.tableConfig.columns = tf.tableConfig.columns.map((c, i) => {
        if (c.index !== columnIndex) {
          return c;
        }
        if (!tf.fixedLocation) {
          return c;
        }

        let percentage2 = percentage;
        percentage2 = Math.max(0, percentage);
        percentage2 = Math.min(percentage2, 100);

        if (i > 0) {
          percentage2 = Math.max(
            tf.tableConfig.columns[i - 1].columnWidthPercentage,
            percentage2
          );
        }

        if (i < tf.tableConfig.columns.length - 1) {
          percentage2 = Math.min(
            tf.tableConfig.columns[i + 1].columnWidthPercentage,
            percentage2
          );
        }

        c.columnWidthPercentage = percentage2;

        const { x, width } = tf.fixedLocation.rectangle;
        const newX = x + (width * percentage2) / 100;

        c.line = {
          ...c.line,
          verticalLine: {
            ...(c.line.verticalLine as VerticalLine),
            x: newX,
          },
        };
        // TODO update line
        return c;
      });
      return tf;
    }
  );
};

export const _updateColumnName = (
  state: TemplateFieldState,
  action: PayloadAction<{
    columnIndex: number;
    name: string;
    id: string;
  }>
) => {
  const { id, name, columnIndex } = action.payload;
  state.templateFields = state.templateFields.map(
    (tf): TemplateFieldV2 => {
      if (tf.id !== id) {
        return tf;
      }

      tf.tableConfig.columns = tf.tableConfig.columns.map((c) => {
        if (c.index !== columnIndex) {
          return c;
        }
        c.name = name;
        c.line.text = name;
        return c;
      });
      return tf;
    }
  );
};

export const _updateColumnFieldType = (
  state: TemplateFieldState,
  action: PayloadAction<{
    columnIndex: number;
    fieldType: FieldType;
    id: string;
  }>
) => {
  const { id, fieldType, columnIndex } = action.payload;
  state.templateFields = state.templateFields.map(
    (tf): TemplateFieldV2 => {
      if (tf.id !== id) {
        return tf;
      }

      tf.tableConfig.columns = tf.tableConfig.columns.map((c) => {
        if (c.index !== columnIndex) {
          return c;
        }
        c.fieldType = fieldType;
        return c;
      });
      return tf;
    }
  );
};

export const _setNumColumns = (
  state: TemplateFieldState,
  action: PayloadAction<{
    numColumns: number;
    id: string;
  }>
) => {
  let { id, numColumns } = action.payload;
  numColumns = Math.max(numColumns, 0);
  const columnWidthPercentage = 100 / numColumns;
  if (numColumns === 0) {
    return state;
  }
  state.templateFields = state.templateFields.map(
    (tf): TemplateFieldV2 => {
      if (tf.id !== id) {
        return tf;
      }
      if (!tf.fixedLocation) {
        return tf;
      }

      const prevNumColumns = tf.tableConfig.columns.length;

      let x;
      let y;
      let width;
      let height;
      let scaleX;
      let scaleY;

      if (tf.locationType === "fixed") {
        if (!tf.fixedLocation.rectangle) {
          return tf;
        }
        x = tf.fixedLocation.rectangle.x;
        y = tf.fixedLocation.rectangle.y;
        width = tf.fixedLocation.rectangle.width;
        height = tf.fixedLocation.rectangle.height;
        scaleX = tf.fixedLocation.rectangle.scaleX;
        scaleY = tf.fixedLocation.rectangle.scaleY;
      } else if (tf.locationType === "variable") {
        if (tf.fragments.fragments.length > 0) {
          const f = tf.fragments.fragments[0];
          const obj = doRectangleInverseMath(
            f.fragment,
            {
              stageHeight: state.stage?.height,
              stageWidth: state.stage?.width,
            },
            {
              pageHeight: (state.page as any).page.height,
              pageWidth: (state.page as any).page.width,
            }
          );
          x = obj.x;
          y = obj.y;
          width = obj.width;
          height = obj.height;
          scaleX = obj.scaleX;
          scaleY = obj.scaleY;
        } else {
          return tf;
        }
      }

      const newColumns: TableColumnV2[] = Array(numColumns || 0);
      for (let i = 0; i < numColumns; i += 1) {
        const prevColX =
          i === 0
            ? x
            : x + width * scaleX * (((i - 1) * columnWidthPercentage) / 100);

        const nextColX =
          i === numColumns - 1
            ? x + width * scaleX
            : x + width * scaleX * (((i + 1) * columnWidthPercentage) / 100);

        if (i < prevNumColumns) {
          newColumns[i] = {
            ...tf.tableConfig.columns[i],
            columnWidthPercentage: i * columnWidthPercentage,
            line: {
              ...tf.tableConfig.columns[i].line,
              verticalLine: {
                ...(tf.tableConfig.columns[i].line
                  .verticalLine as VerticalLine),
                y0: y,
                y1: y + height * scaleY,
                maxX: nextColX,
                minX: prevColX,
                x: x + width * scaleX * ((i * columnWidthPercentage) / 100),
              },
            },
          };
        } else {
          tf.tableConfig.transforms.verticalMergeMasks?.prev.push(0);
          tf.tableConfig.transforms.verticalMergeMasks?.next.push(0);
          newColumns[i] = {
            columnWidthPercentage: i * columnWidthPercentage,
            fieldType: "text",
            index: i,
            name: "",
            line: {
              draggable: true,
              horizontalLine: null,
              verticalLine: {
                maxX: nextColX,
                minX: prevColX,
                x: x + width * scaleX * ((i * columnWidthPercentage) / 100),
                y0: y,
                y1: y + height * scaleY,
                scaleY: tf.fixedLocation.rectangle
                  ? tf.fixedLocation.rectangle.scaleY
                  : 1.0,
                scaleX: tf.fixedLocation.rectangle
                  ? tf.fixedLocation.rectangle.scaleX
                  : 1.0,
              },
              id: uuidv4(),
              stroke: "blue",
              strokeWidth: 5,
              type: "vertical",
              text: "",
              dash: null,
            },
          };
        }
      }
      tf.tableConfig.columns = newColumns;
      return tf;
    }
  );
};

export const _updateVerticalMergeMasks = (
  state: TemplateFieldState,
  action: PayloadAction<{
    id: string;
    which: "prev" | "next";
    index: number;
    value: 0 | 1 | 2;
  }>
) => {
  const { id, which, index, value } = action.payload;

  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }
    if (!tf.tableConfig.transforms.verticalMergeMasks) {
      return tf;
    }
    return {
      ...tf,
      tableConfig: {
        ...tf.tableConfig,
        transforms: {
          ...tf.tableConfig.transforms,
          verticalMergeMasks: {
            ...tf.tableConfig.transforms.verticalMergeMasks,
            [which]: tf.tableConfig.transforms.verticalMergeMasks[
              which
            ].map((x, i) => (i === index ? value : x)),
          },
        },
      },
    };
  });
};

export const _updateVerticalMergeMasksEnabled = (
  state,
  action: PayloadAction<{
    id: string;
    enabled: boolean;
  }>
) => {
  const { id, enabled } = action.payload;

  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }

    const numCols = tf.tableConfig.columns.length;
    let prev: number[] = [];
    let next: number[] = [];
    if (numCols > 0) {
      prev = new Array(numCols).fill(0);
      next = new Array(numCols).fill(1);
    }

    return {
      ...tf,
      tableConfig: {
        ...tf.tableConfig,
        transforms: {
          ...tf.tableConfig.transforms,
          verticalMergeMasksEnabled: enabled,
          alignMiddleEnabled: !enabled,
          verticalMergeMasks:
            enabled && !tf.tableConfig.transforms.verticalMergeMasks
              ? { prev, next }
              : tf.tableConfig.transforms.verticalMergeMasks,
        },
      },
    };
  });
};

export const _updateAlignMiddleEnabled = (
  state,
  action: PayloadAction<{
    id: string;
    enabled: boolean;
  }>
) => {
  const { id, enabled } = action.payload;

  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }

    const numCols = tf.tableConfig.columns.length;
    let prev: number[] = [];
    let next: number[] = [];
    if (numCols > 0) {
      prev = new Array(numCols).fill(0);
      next = new Array(numCols).fill(1);
    }

    return {
      ...tf,
      tableConfig: {
        ...tf.tableConfig,
        transforms: {
          ...tf.tableConfig.transforms,
          verticalMergeMasksEnabled: !enabled,
          alignMiddleEnabled: enabled,
          alignMiddle:
            enabled && !tf.tableConfig.transforms.alignMiddle
              ? {
                  referenceFieldIndex: 0,
                  multilineFieldIndex: [],
                  separator: " ",
                }
              : tf.tableConfig.transforms.alignMiddle,
        },
      },
    };
  });
};

export const _setAlignMiddleReferenceField = (
  state,
  action: PayloadAction<{
    id: string;
    referenceFieldIndex: string;
  }>
) => {
  const { id, referenceFieldIndex } = action.payload;

  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }

    const numCols = tf.tableConfig.columns.length;
    let prev: number[] = [];
    let next: number[] = [];
    if (numCols > 0) {
      prev = new Array(numCols).fill(0);
      next = new Array(numCols).fill(1);
    }

    return {
      ...tf,
      tableConfig: {
        ...tf.tableConfig,
        transforms: {
          ...tf.tableConfig.transforms,
          alignMiddle: {
            ...tf.tableConfig.transforms.alignMiddle,
            referenceFieldIndex: parseInt(referenceFieldIndex.toString(), 10),
          },
        },
      },
    };
  });
};

export const _setAlignMiddleMultilineField = (
  state,
  action: PayloadAction<{
    id: string;
    multilineFieldIndex: number[];
  }>
) => {
  const { id, multilineFieldIndex } = action.payload;

  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }

    const numCols = tf.tableConfig.columns.length;
    let prev: number[] = [];
    let next: number[] = [];
    if (numCols > 0) {
      prev = new Array(numCols).fill(0);
      next = new Array(numCols).fill(1);
    }

    return {
      ...tf,
      tableConfig: {
        ...tf.tableConfig,
        transforms: {
          ...tf.tableConfig.transforms,
          alignMiddle: {
            ...tf.tableConfig.transforms.alignMiddle,
            multilineFieldIndex,
          },
        },
      },
    };
  });
};

export const _setAlignMiddleSeparator = (
  state,
  action: PayloadAction<{
    id: string;
    separator: string;
  }>
) => {
  const { id, separator } = action.payload;

  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }

    const numCols = tf.tableConfig.columns.length;
    let prev: number[] = [];
    let next: number[] = [];
    if (numCols > 0) {
      prev = new Array(numCols).fill(0);
      next = new Array(numCols).fill(1);
    }

    return {
      ...tf,
      tableConfig: {
        ...tf.tableConfig,
        transforms: {
          ...tf.tableConfig.transforms,
          alignMiddle: {
            ...tf.tableConfig.transforms.alignMiddle,
            separator,
          },
        },
      },
    };
  });
};

export const _updateNumHeaderRows = (
  state,
  action: PayloadAction<{
    headerRows: number;
    id: string;
  }>
) => {
  const { id, headerRows } = action.payload;
  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }
    return {
      ...tf,
      tableConfig: {
        ...tf.tableConfig,
        headerRows: parseInt((headerRows || 0).toString(), 10),
      },
    };
  });
};

export const _updateRegex = (
  state,
  action: PayloadAction<{
    fieldName: string;
    regex: string;
    id: string;
  }>
) => {
  const { id, regex, fieldName } = action.payload;
  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }
    return {
      ...tf,
      qbo: {
        ...tf.qbo,
        regex: {
          ...(tf.qbo.regex || {}),
          [fieldName]: regex,
        },
      },
    };
  });
};

export const _updateNegativeRegex = (
  state,
  action: PayloadAction<{
    fieldName: string;
    regex: string;
    id: string;
  }>
) => {
  const { id, regex, fieldName } = action.payload;
  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }
    return {
      ...tf,
      qbo: {
        ...tf.qbo,
        negativeRegex: {
          ...(tf.qbo.regex || {}),
          [fieldName]: regex,
        },
      },
    };
  });
};

export const _updateQboConfig = (
  state,
  action: PayloadAction<{
    qboConfig: TemplateFieldV2["qbo"];
    id: string;
  }>
) => {
  const { id, qboConfig } = action.payload;
  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }
    return {
      ...tf,
      qbo: qboConfig,
    };
  });
};

import React, { useCallback, useRef, useState } from "react";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { formikInput } from "./utils";
import { Button, Overlay, Popover, PopoverContent, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import uuidv4 from "uuid/v4";

export const FormWithOverlay = ({
  cta,
  ctaClassName,
  onSave,
  validationSchema,
  initialValues,
  renderFields,
  icon,
  disabled,
}) => {
  // Validation Schema using Yup
  //   const validationSchema = Yup.object().shape({
  //     tag: Yup.string().required("Tag is required"),
  //   });

  // Initial values for the form

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    onSave(values);
    setSubmitting(false);
    setEditing(false);
    resetForm();
  };

  // Handle form submission
  //   const onSubmit = (values) => {
  //     console.log(values);
  //   };
  const [editing, setEditing] = useState(false);
  const target = useRef(null);

  return (
    <>
      <Button
        className={`m-2 ${ctaClassName}`}
        ref={target}
        variant="light"
        onClick={() => setEditing(!editing)}
        disabled={disabled}
      >
        <FontAwesomeIcon className="" icon={icon} />
        <span className="ml-1">{cta}</span>
      </Button>

      <Overlay
        show={editing}
        target={target.current}
        placement="right"
        containerPadding={20} // Optional: Padding for the overlay container
      >
        <Popover id={`edit-field-popover-${uuidv4()}`}>
          {/* <Popover.Title as="h3">Edit Field</Popover.Title> */}
          <PopoverContent>
            <Row className="justify-content-end">
              <Button variant="link" onClick={() => setEditing(!editing)}>
                <FontAwesomeIcon icon="times" />
              </Button>
            </Row>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  {renderFields()}
                  <Button className="mt-2 mx-2" type="submit">
                    {cta}
                  </Button>
                </Form>
              )}
            </Formik>
          </PopoverContent>
        </Popover>
      </Overlay>
    </>
  );
};

import { getAsyncTask } from "../../docuclipper/api";
import { createAlert } from "./Alerts";

export async function pollForCompletion(
  dispatch,
  asyncJobId,
  jobId,
  onSuccess,
  onFail
) {
  const checkJobCompletion = async () => {
    try {
      const asyncTask = await getAsyncTask(asyncJobId);
      if (["Succeeded", "Failed"].includes(asyncTask.status)) {
        clearInterval(interval);
        if (asyncTask.status === "Succeeded") {
          await onSuccess();
        } else if (asyncTask.status === "Failed") {
          await onFail();
        }
      }
    } catch (error) {
      dispatch(
        createAlert({
          message: "Error checking status",
          timeout: 0,
          type: "error",
        })
      );
    }
  };

  const interval = setInterval(() => {
    checkJobCompletion();
  }, 2000); // poll every 2 seconds

  const timeoutDuration = 5 * 60 * 1000; // 5min
  const timeout = setTimeout(() => {
    clearInterval(interval);
  }, timeoutDuration);
}

export type JwtData = {
  user: string;
  firstName: string;
  lastName: string;
  picture: string;
  permissions: string[];
};

export type Integration = {
  id: string;
  accountId: string;
  scope: string;
  extraParams: string;
  errorCode: number | null;
  errorMessage: string;
};

export type IntegrationsInfo = {
  googleProfile: {
    scope: string;
    url: string;
  };
  googleDocuments: {
    scope: string;
    url: string;
  };
  googleDrive: {
    scope: string;
    url: string;
  };
  googleSpreadsheets: {
    scope: string;
    url: string;
  };
  quickbooks: {
    scope: string;
    url: string;
  };
};

export type Document = {
  id: number;
  numPages: number;
  originalname: string;
  password?: string;
};

export type TemplateType = "Invoice" | "FreeForm";
export type Template = {
  id: number;
  userId: string | number;

  name: string;
  document: Document;
  template: string;
  type: TemplateType;
  email: string;
  description: string;

  updatedAt?: string;
  smallImageUrl?: string;
  bigImageUrl?: string;

  nameIsInvalid?: boolean;

  category: TemplateCategory;
  visibility: "public" | "private" | "";
};

// export type Box = {
//   x0: number;
//   x1: number;
//   y0: number;
//   y1: number;
//   text: string;
//   name: string;
//   loading: boolean;
//   toolType: string;
//   pageNumber: number;
//   googleDocsSpreadsheetUrl: string | null;
//   googleDocsSpreasheetLoading: boolean;
//   googleDocsSpreasheetError: boolean;
// };

export type Thumbnail = {
  width: number;
  height: number;
  imageSrc: string;
  pageNumber: number;
};

export type StageDimensions = {
  stageHeight: number;
  stageWidth: number;
};

export type RectangleMap<T> = {
  [key: number]: T;
};

export type ToolType =
  | "ocr"
  | "handwriting"
  | "ocrtables"
  | "tables"
  | "checkbox";
export type Rectangle = {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  // x0: number;
  // x1: number;
  // y0: number;
  // y1: number;
  fill: string;
  toolType: ToolType;
  name: string;
  draggable: boolean;
  onDragEnd: (e: any) => void;
  onTransform: (e: any) => void;
  onTransformEnd: (e: any) => void;
  onClick: (e: any) => void;
  scaleX: number;
  scaleY: number;
  tableMapping: TableMapping;
  loading: boolean;
  text: string;
  editedText?: string;
  improvedText?: string;
  extractedText?: string;
  origin: "user" | "suggested";
  fragment: Fragment | null;
  fieldType: FieldType;
  hasHeader: boolean; // for tables and ocrtables
  vlocation: Vlocation;
  vlocationEnabled: boolean;
  validVlocation: boolean;
  hasError: boolean;
  errorMsg: string;
  pageNumber: number;
  applyToAllPages: boolean;
  includePages: string[];
  excludePages: string[];
  instance: /*DrawRectangle*/ any | null;
  checkLabels: {
    checked: string;
    unchecked: string;
  };
  transformSteps: TransformStep[];
  forbiddenWords: string[][];
};

export type RectangleServer = {
  x0: number;
  x1: number;
  y0: number;
  y1: number;
  toolType: ToolType;
  name: string;

  tableMapping: TableMapping;
  transformSteps: TransformStep[];
  fieldType: FieldType;
  hasHeader: boolean;
  vlocation: Vlocation;
  forbiddenWords: string[][];
};

export type TemplateTemplate = {
  rectangles: Rectangle[];
};
export type WithName = {
  name: string;
};
export type WithNameAndId = {
  name: string;
  id: string;
};

export type WithNameAndValue = {
  name: string;
  value: string;
};

export type WithIndexAndName = {
  index: number;
  name: string;
};

export type TableColumn = {
  index: number;
  name: string;
  fieldType: FieldType;
  columnWidthPercentage: number;
};
export type TableColumnV2 = {
  index: number;
  name: string;
  fieldType: FieldType;
  columnWidthPercentage: number;
  line: KonvaLine;
};
export type TableMapping = TableColumn[];

export type Field = {
  name: string;
  tableMapping: TableMapping;
  fieldType: FieldType;
  hasHeader: boolean;
};

export type Line = {
  item: string;
  description: string;
  unitPrice: number;
  quantity: number;
  lineTotal: number;
};

export type TaxDetail = {
  taxTotal: number;
  taxable: number;
  taxRate: number;
};

export type Invoice = {
  date: string;
  subTotal: number;
  total: number;
  lines: Line[];
  customer: string;
  taxDetail: TaxDetail;
};
export type StripeSubscriptionStatus =
  | "active"
  | "incomplete"
  | "incomplete_expired"
  | "past_due"
  | "unpaid"
  | "trialing"
  | "canceled";

export type StripeCard = {
  id: string;
  last4: string;
  brand: string;
  exp_month: number;
  exp_year: number;
};
export type StripeCustomer = {
  default_source: string | null;
  sources: {
    data: StripeCard[];
  };
};

export type AwsEntitlement = {
  CustomerIdentifier: string;
  Dimension: string;
  ExpirationDate: number;
  ProductCode: string;
  Value: {
    BooleanValue: boolean;
    DoubleValue: number;
    IntegerValue: number;
    StringValue: string;
  };
};

export type StripeSubscription = {
  discount: null | { coupon: StripeCoupon };
  cancelAtPeriodEnd: boolean;
  cancelAt: number | null;
  currentPeriodEnd: number;
  currentPeriodStart: number;
  customer: StripeCustomer;
  quantity: number;
  plan: {
    amount: number;
    currency: string;
    interval: string;
    interval_count: number;
  } | null;
  docuclipperPlan: {
    name: DocuClipperPlan;
    billingCycle: DocuClipperBillingCycle;
    numPages: number;
    trialNumPages: number;
    perSeatPricing?: boolean;
  };
  status: StripeSubscriptionStatus | "AWS" | "saml";
  trialEnd: number | null;
  trialStart: number | null;
  entitlements?: AwsEntitlement[];
  coupon: Coupon | null;
  isSeat?: boolean;
  admin?: User;
  seat?: Seat;
  contract?: Contract;
  items: {
    data: {
      quantity: number;
      price: {
        unit_amount: number;
        nickname: string;
        currency: string;
        recurring: {
          interval: string;
        };
      };
    }[];
  };
  invoice: {
    next_payment_attempt: number;
    total: number;
  } | null;
  lastInvoice?: {
    hosted_invoice_url: string;
  } | null;
  pauseCollection: any;
};

export type StripeInvoice = {
  id: string;
  status: string;
  total: number;
  currency: string;
  periodStart: number;
  periodEnd: number;
  dueDate: number;
  hostedInvoiceUrl: string;
  invoicePdf: string;
};

export type StripeCharge = {
  id: string;
  status: string;
  currency: string;
  amount: number;
  receipt_url: string;
  description: string;
  created: number;
};

export type Timerange = "lastDay" | "lastWeek" | "lastMonth" | "allTime";

export type File = {
  file: {
    lastModified: number;
    lastModifiedDate: string;
    name: string;
    path: string;
    size: number;
    type: string;

    uuid: string;
  };

  uploading: boolean;
  document: Document | null;

  asyncProcessing: boolean;
  status: "ok" | "error";

  split: boolean;

  splitX: number | null;
  splitY: number | null;
  progress: number;

  password: string;
  needsPassword: boolean;
};

export type User = {
  email: string;
  id: number;
  firstName: string;
  lastName: string;
  credits: number;
  purchasedCredits: number;
  authMethod: "Local" | "Google" | "Facebook" | "AWS" | 'Guest';
  status: "pending" | "confirmed";
  referralCode: string;
  contract?: {
    id: number;
    name: string;
    logo: string;
  };
  Seat?: DocuClipperSeat;
  isContractAdmin?: boolean;
  createdAt: string;
  twoFactorEnabled?: boolean;
};

export type Seat = {
  id?: string;
  contractId: number | string;
  email: string;
  status: "confirmed" | "pending";
  enabled: boolean;
  userId: number;
  stripePriceId: string;
  createdAt?: Date;
  updatedAt?: Date;
};

export type Contract = {
  id?: string;
  name: string;
  logo: string;
  adminId: number;
  authMethod: "Local" | "saml";
  seatStripePriceId: string;
  hoursPerTransaction?: number;
  amountPerHour?: number;
  currency?: string;
  enableMonthlyReport?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
};

export type Page = {
  DocumentId: number;
  height: number;
  width: number;
  isThumbnail: boolean;
  path: string;
  pageNumber: number;
};

export type SelectedPage = {
  page: Page;
  data: string;
};

export type Customer = {
  id: number | string;
  firstName: string;
  lastName: string;
  company: string;
  jobTitle: string;
  phoneNumbers: string[];
  emails: string[];
};

export type JobExport = {
  id: number;
  jobId: number;
  exportType: "FieldsToExcel1" | "FieldsToQuickbooks1";
  output: string;
  errorMessage: string;
  createdAt: string;
  updatedAt: string;
};

export type DocumentSearchRecord = {
  userId: number | string;
  id: number;
  name: string;
  url: string;
};
export type TemplateSearchRecord = {
  userId: number | string;
  name: string;
  description: string;
  category: string;
  geo: string;
  time: string;
  url: string;
  visibility: "public" | "private";
};

export type CustomerSearchRecord = {
  id: number | string;
  userId: number | string;
  firstName: string;
  lastName: string;
  phoneNumbers: string[];
  emails: string[];
  jobTitle: string;
  company: string;
  url: string;
};

export type FragmentSearchRecord = {
  id: number | string;
  userId: number | string;
  document: {
    id: number;
  };
  template: {
    name: string;
    description: string;
    category: string;
    geo: string;
    time: string;
  };
  customer: {
    firstName: string;
    lastName: string;
    phoneNumbers: string[];
    emails: string[];
    jobTitle: string;
    company: string;
  };
  pageNumber: number;
  fieldName: string;
  url: string;
  x0: number;
  x1: number;
  y0: number;
  y1: number;
  toolType: ToolType;
  extractedText: string;
  editedText: string;
  improvedText: string;
};

export type Fragment = {
  id: number;
  documentId: number;
  pageNumber: number;
  fieldName: string;
  x0: number;
  x1: number;
  y0: number;
  y1: number;
  toolType: ToolType;
  extractedText: string;
  editedText: string;
  improvedText: string;
  rating: "tracking" | "bad";
};

export type StepWizardProps = {
  previousStep?: () => any;
  nextStep?: () => any;
  goToStep?: (step: number) => any;
  firstStep?: () => any;
  lastStep?: () => any;
  currentStep?: number;
  totalSteps?: number;
  isActive?: boolean;
};

export type JobStatus =
  | "InProgress"
  | "Succeeded"
  | "Failed"
  | "Canceled"
  | "OutOfCredits"
  | "Finishing";

export type JobType = "ExtractData" | "EditDocument" | "WritePdf" | "Invoice" | "Csv2Qbo" | "Form"

export type JobExportType = "FieldsToExcel1" | "FieldsToQuickbooks1" | "Invoice" | "Csv2Qbo" | "Form";

export type Job = {
  id: number;
  templateId?: number;
  type?: JobType;
  jobType?: JobExportType;
  status: JobStatus;
  output?: string | null;
  errorMessage?: string;
  completedOn?: string;
  name: string;
  template?: Template;
  createdAt: string;
  updatedAt: string;
  progress?: number;
  isPartial?: boolean;
  isGeneric?: boolean;
  originalIsGeneric?: boolean;
  isBankMode?: boolean;
  originalIsBankMode?: boolean;
  bankModeIfReconciled?: boolean;
  originalBankModeIfReconciled?: boolean;
  metadata?: string;
  tags?: string;
};

export type RegionsMode = "editAfterImport" | "edit" | "viewonly";

export type Alert = {
  message: any;
  timeout: number;
  type: "success" | "error" | "info";
  messageWithUpdate?: string;
};

export type DocuclipperToken = {
  permissions: string[];
  user: number;
  email: string;
  firstName: string;
  lastName: string;
  picture: string;
  seat?: {
    contractLogo: string;
    contractName: string;
  };
};

export type VlocationYPosition = "top" | "bottom";

export type Vlocation = {
  y0Words: string[];
  y1Words: string[];
  y0Position: VlocationYPosition;
  y1Position: VlocationYPosition;
  x0Percent: number;
  x1Percent: number;
  y0HeightPercentage: number | null;
  y1HeightPercentage: number | null;
  y0SolutionIndex: number;
  y1SolutionIndex: number;
};

export type DocuclipperPreference =
  | "table.pageSize.invoices"
  | "table.pageSize.adminDocuments"
  | "table.pageSize.adminDb"
  | "table.pageSize.adminFlaggedFragments"
  | "table.pageSize.jobDataTable"
  | "table.pageSize.jobExportsTable"
  | "table.pageSize.templatesTable";

type Data = {
  splitX?: number;
  splitY?: number;
  orientation?: "north" | "south" | "east" | "west";
  angle?: number;
  topCropPercentage?: number;
  bottomCropPercentage?: number;
  leftCropPercentage?: number;
  rightCropPercentage?: number;
};
export type Command = {
  pageNumber: number;
  documentId: number;
  command: "remove" | "rotate" | "split" | "include" | "fixSkew" | "crop";
  data: Data;
};

export type PdfCommands = {
  commands: Command[];
};

export type SortedItem = {
  id: string;
  desc: boolean;
};
export type FilteredItem = {
  id: string;
  value: string;
  op: "like" | "eq";
};

export type TableParams = {
  pageSize: number;
  page: number;
  sorted: SortedItem[];
  filtered: FilteredItem[];
};

export type ApiTableParams = {
  offset: number;
  limit: number;
  sorted: SortedItem[];
  filtered: FilteredItem[];
};

export type TemplateAddedColumnMapping = {
  id?: number;
  columnName: string;
  value: string;
  addedColumnName: string;
  addedValue: string;
};

export type CsvLineStatusError = {
  columnName: string;
  type: "COLUMN_NOT_FOUND" | "MISSING_VALUE";
};

export type CsvLineStatus = {
  line: TemplateAddedColumnMapping;
  lineNumber: number;
  status: "ok" | "error" | "already_exists";
  errors: CsvLineStatusError[];
};

export class ApiError extends Error {
  public httpCode: number;
  public errorCode: number;
  public errorMessage: string;
  public data: {};

  constructor(
    httpCode: number,
    errorCode: number,
    errorMessage: string,
    data: {}
  ) {
    // super(
    //   `${errorMessage}${
    //     Object.keys(data).length > 0 ? `\ndata:${JSON.stringify(data)}` : ""
    //   }`
    // );
    super(errorMessage);
    this.httpCode = httpCode;
    this.errorCode = errorCode;
    this.errorMessage = errorMessage;
    this.data = data;
  }

  public toJson() {
    return {
      errorCode: this.errorCode,
      errorMessage: this.errorMessage,
      data: this.data,
    };
  }
}
export type FieldType = "date" | "currency" | "text" | "number";

export type RotateConfig = {
  orientation: Orientation;
  angle: number | null;
};

export type CropConfig = {
  topPercentage: number;
  bottomPercentage: number;
  leftPercentage: number;
  rightPercentage: number;
};
export type Orientation = "north" | "south" | "east" | "west";

export enum MaskType {
  NOT_EMPTY,
  EMPTY,
  ANY,
}

export type TransformStep = {
  verticalMergeMasks?: {
    prev: MaskType[];
    next: MaskType[];
  };
  removeTopRows?: number;
  removeBottomRows?: number;
};

export type DocuClipperPlan =
  | "Starter"
  | "Starter100"
  | "Business"
  | "Free"
  | "Enterprise"
  | "Professional";
export type DocuClipperBillingCycle = "Monthly" | "Annually" | "AnnuallyBilledMonthly";

export type DocuClipperPlanPricing = {
  amount: number;
  currency: string;
  annualSavings: number | null;
  discountSavings: number | null;
  discountPercent: number | null;
};

export type DocuClipperAllPlanPricing = {
  Monthly: DocuClipperPlanPricing;
  Annually: DocuClipperPlanPricing;
  AnnuallyBilledMonthly: DocuClipperPlanPricing;
};
export type DocuClipperPricing = {
  Business: DocuClipperAllPlanPricing;
  Starter: DocuClipperAllPlanPricing;
  Starter100: DocuClipperAllPlanPricing;
  Enterprise: DocuClipperAllPlanPricing;
  Professional: DocuClipperAllPlanPricing;
};

export type StripeCoupon = {
  id: string;
  amount_off: number | null;
  duration: string;
  redeem_by: number;
  percent_off: number;
  duration_in_months: number;
};

export type StripePlan = {
  amount: number;
  originalAmount: number;
  currency: string;
  interval: string;
  interval_count: number;
  docuclipperPlan: {
    name: DocuClipperPlan;
    billingCycle: DocuClipperBillingCycle;
    numPages: number;
  };
};

export type DocuClipperSeat = {
  id: number;
  firstName: string;
  lastName: string;
  status: "confirmed" | "pending";
  enabled: boolean;
  email: string;
  userId?: string;
};

//
// Template V2
//

export type KonvaRectangle = {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  fill: string;
  draggable: boolean;
  // onDragEnd: (e) => any;
  // onTransform: (e) => any;
  // onTransformEnd: (e) => any;
  // onClick: (e) => any;
  scaleX: number;
  scaleY: number;
  templateFieldId: string;
};

export type FixedLocation = {
  pageLocation: {
    x0: number;
    x1: number;
    y0: number;
    y1: number;
  };
  rectangle: KonvaRectangle;
};

export type YPosition = "top" | "bottom";
export type XPosition = "left" | "right";

export type Words = {
  loading: boolean;
  error: string | null;
  words: string[];
  wordType: "x" | "y";
  xPosition: XPosition | null;
  yPosition: YPosition | null;
  includeWordsChecked: boolean;
  offset: number;
  lines: KonvaLine[];
};

export type EdgeDetectionType = "words" | "percentage";

export type Percentage = {
  percentage: number;
  line: KonvaLine;
};
export type Edge = {
  // edgeDetectionType: EdgeDetectionType;
  percentage: null | Percentage;
  words: null | Words;
};

export type VariableLocationEdges = {
  top: Edge;
  bottom: Edge;
  left: Edge;
  right: Edge;
  id: string;
  // order: number;
  forbiddenWords: { id: string; words: string[] }[];
  forbiddenWordsEnabled: boolean;
};

export type VariableLocation = {
  selectedId: string;
  variableLocations: VariableLocationEdges[];
};
export type Transform = {
  name: string;
  data: any;
};

export type FragmentWithRectangle = {
  fragment: Fragment;
  rectangle: KonvaRectangle;
};
export type FragmentV2 = {
  loading: boolean;
  error: string | null;
  selectedFragmentId: string | null;
  fragments: FragmentWithRectangle[];
};

export type QboFieldType = "credits" | "debits" | "both";

export type TemplateFieldV2 = {
  id: string; // unique in template
  name: string;
  locationType: "fixed" | "variable";
  fixedLocation: null | FixedLocation;
  variableLocation: null | VariableLocation;
  toolType: ToolType;
  fieldType: FieldType;
  // forbiddenWords: string[][];
  pages: {
    page: number | null; // single page
    // allPages: boolean | null;
    include: string[] | null; // only included
    exclude: string[] | null; // all except included
    pagesType: "currentPage" | "allPages" | "custom";
  };
  tableConfig: {
    columns: TableColumnV2[];
    headerRows: number;
    transforms: {
      verticalMergeMasksEnabled: boolean;
      alignMiddleEnabled: boolean;
      verticalMergeMasks: {
        prev: number[];
        next: number[];
      } | null;
      alignMiddle: {
        multilineFieldIndex: number | number[];
        referenceFieldIndex: number;
        separator: string;
      } | null;
    };
  };
  checkLabels: {
    checked: string;
    unchecked: string;
  };
  fragments: FragmentV2;
  qbo?: {
    columnMapping: {
      name: number | null;
      date: number | null;
      date2?: number | null;
      amount: number | null;
      balance?: number | null;
      credit: number | null;
      debit: number | null;
      checkNumber?: number | null;
    };
    fieldType: QboFieldType;
    decimalSeparator?: "," | ".";
    creditsAreNegative?: boolean;
    regex?: {
      name?: string;
      date?: string;
      date2?: string;
      amount?: string;
      balance?: string;
      credit?: string;
      debit?: string;
    };
    negativeRegex?: {
      name?: string;
      date?: string;
      date2?: string;
      amount?: string;
      balance?: string;
      credit?: string;
      debit?: string;
    };
  };

  // draft: TemplateFieldV2 | null;
};

export type HorizontalLine = {
  x0: null | number;
  x1: null | number;
  y: number;
  minY: number | null;
  maxY: number | null;
  scaleX: number;
  scaleY: number;
};

export type VerticalLine = {
  y0: null | number;
  y1: null | number;
  x: number;

  minX: number | null;
  maxX: number | null;
  scaleX: number;
  scaleY: number;
};

export type LineType =
  | "horizontalFullWidth"
  | "verticalFullHeight"
  | "vertical"
  | "horizontal";
export type KonvaLine = {
  id: string;
  horizontalLine: HorizontalLine | null;
  stroke: string;
  strokeWidth: number;
  verticalLine: VerticalLine | null;
  type: LineType;
  draggable: boolean;
  text: string;
  dash: number[] | null;
};

export type CanvasMode =
  | "rectangles"
  | "edges"
  | "selectWords"
  | "viewExtractedData"
  | "documentOnly"
  | "tableColumns";

export type EdgeType = "top" | "bottom" | "left" | "right";

export type FieldSection = {
  expanded: boolean;
  hasError: boolean;
};

export type TemplateCategory = "bankStatement" | "tax" | "";

export type ReferredUser = {
  firstName: string;
  lastName: string;
  email: string;
  status: "active" | "trialing" | "inactive";
};

export type DocuClipperPromotions = "referFriend";

export const DEFAULT_TOAST_TIMEOUT = 2000;

export type Coupon = {
  duration: "once" | "repeating" | "forever";
  duration_in_months: number;
  percent_off: number;
  redeem_by: number;
};

export type JobDocumentRating = {
  originalName: string;
  pages: number[];
  missing: boolean[];
  wrong: boolean[];
  other: string[];
};

export type JobDocument = {

  numPages: string;
  originalname: string;
  mimetype: string;
  isReconciled: boolean;
  shouldReconcile: boolean;
  documentId: string;
};

export type BadRating = {
  templateName: string;
  email: string;
  firstName: string;
  userId: string;
  jobRating: number;
  jobRatingId: string;
  missing: boolean;
  wrong: boolean;
  otherText: string;
  jobCreatedAt: string;
};

export type TransactionLine = {
  id?: string;
  documentId: string;
  fieldName: string;
  rowNumber: number;
  row: string[];
  status: "reconciled" | "error" | "unknown";
  included: boolean;
  pageNumber: number;
  y0: number;
  y1: number;
  x0: number;
  x1: number;
  account: string;
  accountNickname: string;
  chunk?: string;
  chunkLabel?: string;
  category?: string;
  categoryKeyword?: string;
  editedCategory?: string;
  date?: string;
  date2?: string;
  description?: string;
  amount?: number;
  balance?: number;
  tags?: string[];
  createdAt?: Date;
  updatedAt?: Date;
};

export type MetadataField = {
  id?: number;
  documentId: string;
  pageNumber: string;
  name: string;
  value: string;
  bbox: string;
  details: string;
  createdAt?: Date;
  updatedAt?: Date;
  accountName?: string;
  chunk?: string;
};

export type QboDecimalSeparator = "," | ".";

export type TemplateMetadataField = {
  name: string;
  fieldName?: string; // associated to a field=rectangle
  documents: {
    description?: string;
    regexes: {
      regexes: string[];
      index?: number;
      dateLocale?: "fr" | "es";
      dateFormat?: string;
      decimalSeparator?: QboDecimalSeparator;
      toleranceInPixels?: number;
    }[];
  }[];
};

export type ColumnConfig = {
  index: number;
  included?: boolean;
  type: "date" | "number" | "text" | "boolean"; // defaults to text
  params: {
    date?: {
      dateFormat?: string;
    };
    number?: {
      decimalSeparator?: string;
    };
    boolean?: {};
    text?: {};
  };
  regex?: {
    // 1. transform
    replaceRegex?: {
      regex: string;
      replace: string;
    }[]; // replace, executed first

    // 2. filter

    regex?: string; // if matches, row is included
    negativeRegex?: string; // if matches, row is excluded

    // 3. extract
    extract?: {
      extractRegex: string; // extract part of the field
      separator: string;
    };
  };

  heading?: string;
};

export type CustomFormat = {
  id: string;
  name: string;
  data: { columnConfigs: CustomFormatItem[] };
};

export type FeatureGate = {
  featureId: number;
  featureName: string;
  enabled: boolean;
};

export enum CustomFormatSource {
  Date = "date",
  Name = "name",
  Amount = "amount",
  Debit = "debit",
  Credit = "credit",
  Balance = "balance",
  Date2 = "date2",
  CheckNumber = "checkNumber",
  Fixed = "fixed",
  Account = "account",
  FieldName = "fieldName",
  RefNumber = "refNumber",
  Description = "description",
  CategoryKeyword = "categoryKeyword",
  Id = "id",
  Tax = "tax",
  Total = "total",
  Item = "item",
  Quantity = "quantity",
  UnitPrice = "unitPrice",
  Price = "price",
  Integration = "integration",
  Customer = "customer",
  Vendor = "vendor",
  Status = "status",
  Link = "link",
  Service = "service",
  Category = "category",
  CategoryExcel = "categoryExcel",
  ServiceExcel = "serviceExcel",
  TransactionType = "transactionType",
  HardcodedValue = "hardcodedValue",
  AutoIncrement = "autoIncrement",

}

export type CustomFormatItem = {
  index: number;
  included?: boolean;
  type: "date" | "number" | "text" | "boolean"; // defaults to text
  source?: CustomFormatSource;

  params: {
    date?: {
      dateFormat?: string;
    };
    number?: {
      decimalSeparator?: string;
      alwaysPositive?: boolean;
      alwaysNegative?: boolean;
    };
    boolean?: {};
    text?: {};
  };
  regex?: {
    // 1. transform
    replaceRegex?: {
      regex: string;
      replace: string;
    }[]; // replace, executed first

    // 2. filter

    regex?: string; // if matches, row is included
    negativeRegex?: string; // if matches, row is excluded

    // 3. extract
    extract?: {
      extractRegex: string; // extract part of the field
      separator: string;
    };
  };

  heading?: {
    hasHeading?: boolean;
    text?: string;
  };
  value?: {
    type: "string" | "credit_or_debit";
    value?: string;
    creditValue?: string;
    debitValue?: string;
  };
  creditLabel?: string;
  debitLabel?: string;
  hardcodedValue?: string;
};

export type ExportType =
  | "xlsx"
  | "csv"
  | "qbo"
  | "iif"
  | "qif"
  | "xero-csv"
  | "sage-csv"
  | "sage-cloud-csv"
  | "myob"
  | "relate"
  | "netsuite"
  | "ofx";

export type LineItem2 = {
  item: string;
  description: string;
  quantity: string;
  unitPrice: string;
  price: string;
  category: string;
  categoryName: string;
  categoryEdited: string;
  categoryEditedName: string;
  categoryAutomated: string;
  categoryAutomatedName: string;
  categoryExcel: string;
  categoryExcelEdited: string;
  categoryExcelAutomated: string;
  service: string;
  serviceName: string;
  serviceEdited: string;
  serviceEditedName: string;
  serviceAutomated: string;
  serviceAutomatedName: string;
  serviceExcel: string;
  serviceExcelEdited: string;
  serviceExcelAutomated: string;
  tax: string;
  taxName: string;
  taxEdited: string;
  taxEditedName: string;
  taxAutomated: string;
  taxAutomatedName: string;
  extraFields: { [key: string]: any };
};

export type DocType = "invoice" | "bill" | "expense" | "payables" | "receivables";

export type Invoice2 = {
  id: string;
  date: string;
  dueDate: string;
  total: string;
  subTotal: string;
  tax: string;
  lines: LineItem2[];
  vendor: string;
  vendorName: string;
  vendorEdited: string;
  vendorEditedName: string;
  vendorAutomated: string;
  vendorAutomatedName: string;
  customer: string;
  customerName: string;
  customerEdited: string;
  customerEditedName: string;
  customerAutomated: string;
  customerAutomatedName: string;
  account: string;
  accountName: string;
  accountEdited: string;
  accountEditedName: string;
  accountAutomated: string;
  accountAutomatedName: string;
  vendorExcel: string;
  vendorExcelEdited: string;
  vendorExcelAutomated: string;
  customerExcel: string;
  customerExcelEdited: string;
  customerExcelAutomated: string;
  integration: string;
  status: string;
  link: string;
  docType: DocType;
  extraFields: { [key: string]: any };
};

export type FormDoc = {};

export type FormValues = {
  key: string;
  value: string;
  dueDate: string;
  lines: LineItem2[];
  total: number;
  tax: number;
};

export type QboCustomer = {
  name: string;
  value: string;
};

export type QboCompany = {
  name: string;
  value: string;
};

export type QboVendor = {
  name: string;
  value: string;
};

export type QboAccount = {
  name: string;
  value: string;
};

export type QboItem = {
  name: string;
  value: string;
  type: string;
};

export type QboAccountCategory = {
  name: string;
  value: string;
};

export type TagRegexesType = "categorize" | "tag" | "invoiceRules";

export type TagRegexes = {
  [x: string]: any;
  id: string;
  tag: string;
  regexes: string;
  jsonRules?: string;
};

export type TagRegexesSets = {
  id: string;
  name: string;
  tagRegexesId: string;
  type: TagRegexesType;
};


export type TagRule = {
  id?: string;
  tag: string;
  description: {
    keywords: string[];
    negativeKeywords: string[];
  };
  amount: {
    operator: "string";
    value: number;
  }
};

export type TagCategoryRule = {
  id?: string;
  tag: string;
  tagLabel?: string;
  keywords: string[];
  negativeKeywords: string[];
};

export type TagInvoiceRule = {
  id?: string;
  tag: string;
  jsonRules: string;
};

export type CustomFormatType = "bankStatement" | "invoice";

export type TeamMemberRole = {
  id: string;
  userId: string;
  teamId: string;
  role: "admin" | "member";
  createdAt: string;
  updatedAt: string;
  User: User;
};

export type Team = {
  id: string;
  name: string;
  contractId: string;
  createdAt: string;
  updatedAt: string;
  TeamMemberships: TeamMemberRole[];
  // Add any other properties that a Team might have, for example:
  // description?: string;
};

export interface QboTaxCode {
  name: string;
  value: string;
}

interface JsonRule {
  conditions: {
    [operator: string]: Array<{
      fact: string;
      operator: string;
      value: any;
    }>;
  };
  event: {
    type: string;
    params: any;
  };
}


export interface InvoiceApprovalRule {
  id?: number;
  name: string;
  description?: string;
  rules: any;
  isActive: boolean;
  ifConditions: Array<{
    field: string;
    operator: string;
    value: { amount?: number; keywords?: string[] };
  }>;
  approverOperator: "all" | "any";
  thenApprovers: Array<{ id: string, type: 'team' | 'user' }>;
  combinationOperator: "all" | "any";
  jsonRule?: JsonRule;
  requireNWayMatch: boolean;
  requiredMatches: number;
}

export interface ApprovalRequirement {
  id: number;
  ruleId?: number;
  status: 'approved' | 'pending' | 'rejected';
  approverName?: string;
  approverEmail?: string;
  approvedAt?: string;
  isCurrentUser?: boolean;
  approver?: {
    id: string | number;
    name: string;
  };
}

export interface ApprovalRule {
  id: number;
  name?: string;
  approverOperator: 'all' | 'any';
  requireNWayMatch?: boolean;
  requiredMatches?: number;
}

export interface ApprovalProgress {
  total: number;
  approved: number;
  rejected: number;
  pending: number;
  requirements: ApprovalRequirement[];
  rules: ApprovalRule[];
}

export interface InvoiceApprovalStatus {
  transactionLineId: any;
  status: 'approved' | 'pending' | 'rejected' | 'canceled';
  approvalProgress?: ApprovalProgress;
}

export interface InvoiceRoutingRule {
  id?: number;
  // Add other required fields
}



export interface MatchGroup {
  id: number;
  status: 'pending' | 'confirmed' | 'rejected';
  requiredMatches: number;
  matchedBy: string;
  matchedAt: Date;
  confirmedBy?: number;
  confirmedAt?: Date;
  InvoiceMatchGroupItems?: any[];
}

export interface PotentialMatches {
  potentialMatches: Record<string, any[]>;
  existingGroups: MatchGroup[];
  requiredMatches: number;
}

export interface Tag {
  id: number;
  name: string;
  color: string;
}

export interface ApprovalCheck {
  needsApproval: boolean;
  reason: 'would_auto_approve' | 'needs_approval';
  matchingRules: InvoiceApprovalRule[];
  isApprover: boolean;
}

export interface TableSortConfig {
  field: string;
  direction: 'asc' | 'desc';
}

export interface TableFilterConfig {
  field: string;
  operator: 'equals' | 'contains' | 'startsWith' | 'endsWith' | 'gt' | 'lt' | 'between';
  value?: any;
  valueFrom?: any;
  valueTo?: any;
}

export interface TableQueryParams {
  pagination: {
    page: number;
    pageSize: number;
  };
  sorting?: TableSortConfig[];
  filters?: TableFilterConfig[];
  search?: {
    global?: string;
    fields?: string[];
  };
}

export interface TableResponse<T> {
  data: T[];
  metadata: {
    totalCount: number;
    filteredCount: number;
    page: number;
    pageSize: number;
    totalPages: number;
  };
}


export interface SubscriptionItemAttributes {
  id?: number;
  type: string; // 'user', 'page', 'integration', 'addon'
  stripePriceId: string;
  amount: number;
  name: string;
  description: string;
  metadata: any;
  includedInPlan: boolean;
  billingCycle: string; // e.g., 'monthly', 'yearly'
  currency: string; // e.g., 'USD', 'EUR'
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ContractAddonsAttributes {
  id?: number;
  contractId: number;
  subscriptionItemId: number;
  createdAt?: Date;
  updatedAt?: Date;
  subscriptionItem?: SubscriptionItemAttributes;
  quantity: number;

}

export interface SubscriptionPreview {
  total: number;
  lines: { description: string; amount: number }[];
}

export interface ChunkData {
  Transactions: {
    totalCredits: number;
    totalPayments: number;
    numCredits: number;
    numPayments: number;
  };
  mfs: MetadataField[][];
  label: string;
}

export interface TagLevel {
  name: string;
}
import React, { useRef, useState, useCallback, useMemo } from "react";
import * as Yup from "yup";
import {
  Button,
  Form,
  Overlay,
  Popover,
  PopoverContent,
  Row,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { Field, Formik } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formikInput, formikTextarea } from "./utils";
import { v4 as uuidv4 } from "uuid";
import { Placement } from "react-bootstrap/esm/Overlay";

export const EditableFieldAmount = ({
  value,
  onSave,
  placement,
}: {
  value;
  onSave;
  placement?: Placement;
}) => {
  return (
    <EditableField
      placement={placement}
      value={value}
      onSave={onSave}
      validationSchema={Yup.object({
        value: Yup.number().required(), // Basic non-empty validation
      })}
    />
  );
};

export const EditableFieldDateYYYYMMDD = ({
  value,
  onSave,
  placement,
}: {
  value: string;
  onSave: (value: string) => void;
  placement?: Placement;
}) => {
  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        value: Yup.string()
          .required()
          .matches(/^\d{4}-\d{2}-\d{2}$/, "Date must be in YYYY-MM-DD format")
          .test("isValidDate", "Date is invalid or out of range", (value) => {
            if (!value) return false;
            const date = new Date(value);
            return (
              !isNaN(date.getTime()) && date.toISOString().startsWith(value)
            );
          }),
      }),
    []
  );

  return (
    <EditableField
      placement={placement}
      value={value}
      onSave={onSave}
      validationSchema={validationSchema}
    />
  );
};

export const EditableFieldRequiredText = ({
  value,
  onSave,
  placement,
}: {
  value;
  onSave;
  placement?: Placement;
}) => {
  return (
    <EditableField
      placement={placement}
      value={value}
      onSave={onSave}
      validationSchema={Yup.object({
        value: Yup.string().required(), // Basic non-empty validation
      })}
    />
  );
};

export const EditableFieldText = ({
  value,
  onSave,
  placement,
  multiline = false,
}: {
  value;
  onSave;
  placement?: Placement;
  multiline?: boolean;
}) => {
  return (
    <EditableField
      placement={placement}
      value={value}
      onSave={onSave}
      validationSchema={Yup.object({
        value: Yup.string(), // Basic non-empty validation
      })}
      multiline={multiline}
    />
  );
};

export const EditableField = ({
  value,
  onSave,
  validationSchema,
  placement = "right",
  multiline = false,
}: {
  value: string;
  onSave: (value: string) => void;
  validationSchema: any;
  placement?: Placement;
  multiline?: boolean;
}) => {
  const [editing, setEditing] = useState(false);
  const [displayValue, setDisplayValue] = useState(value);
  const target = useRef(null);

  const handleSubmit = useCallback(
    (values, { setSubmitting, resetForm }) => {
      onSave(values.value);
      setSubmitting(false);
      setEditing(false);
      resetForm();
      setDisplayValue(values.value);
    },
    [onSave]
  );

  const toggleEditing = useCallback(() => setEditing((prev) => !prev), []);

  const tooltipId = useMemo(() => `tooltip-${uuidv4()}`, []);
  const popoverId = useMemo(() => `edit-field-popover-${uuidv4()}`, []);

  const formComponent = useMemo(
    () => (multiline ? formikTextarea : formikInput),
    [multiline]
  );

  return (
    <>
      <div className="editable-text-icon-container">
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={tooltipId}>{displayValue}</Tooltip>}
        >
          <span ref={target} className="editable-text-truncate-text">
            {displayValue}
          </span>
        </OverlayTrigger>

        <FontAwesomeIcon
          className="editable-text-fas"
          icon="edit"
          onClick={toggleEditing}
        />
      </div>

      <Overlay
        show={editing}
        target={target.current}
        placement={placement}
        containerPadding={20}
      >
        <Popover id={popoverId}>
          <PopoverContent>
            <Formik
              initialValues={{ value: value }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group>
                    <Field
                      type="text"
                      id="value"
                      name="value"
                      component={formComponent}
                    />
                  </Form.Group>
                  <Row className="justify-content-end">
                    <Button
                      className="mr-2"
                      variant="secondary"
                      onClick={toggleEditing}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="mr-2"
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Row>
                </Form>
              )}
            </Formik>
          </PopoverContent>
        </Popover>
      </Overlay>
    </>
  );
};

EditableField.defaultProps = {
  placement: "right",
  multiline: false,
};

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ButtonGroup, Col, Container, Row } from "react-bootstrap";
import { ReduxState } from "../../../../../redux";
import PopoverHelp from "../../../../Docuclipper/PopoverHelp";
import CsvOptions from "./CsvOptions";
import DocumentsDropdown from "./DocumentsDropdown";
import ExportTypeDropdown from "./ExportTypeDropdown";
import FieldNamesDropdown from "./FieldNamesDropdown";
import GenericOptions from "./GenericOptions";
import IifOptions from "./IifOptions";
import Instructions from "./Instructions";
import QboOptions from "./QboOptions";
import DisplayExternalFilters from "src/views/Docuclipper/DisplayExternalFilters";
import {
  setExternalFiltersSortBy,
  setExternalFiltersSortDirection,
} from "src/redux/reducers/ExternalFilter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function exportToName(selectedExportType) {
  switch (selectedExportType) {
    case "xlsx":
      return "Download XLSX";
    case "csv":
      return "Download CSV";
    case "qif":
      return "Download QIF";
    case "xero-csv":
      return "Download Xero CSV";
    case "sage-csv":
    case "sage-cloud-csv":
      return "Download Sage CSV";
    case "myob":
      return "Download MYOB CSV";
    case "relate":
      return "Download Relate CSV";
    case "netsuite":
      return "Download NetSuite CSV";
    default:
      return "Download";
  }
}

export const DownloadSection: React.FC = () => {
  const dispatch = useDispatch();
  const { selectedExportType, job, sortBy, sortDirection } = useSelector(
    (state: ReduxState) => ({
      selectedExportType: state.DownloadOptions.selectedExportType,
      job: state.JobData.job,
      sortBy: state.ExternalFilter.externalFilterSortBy,
      sortDirection: state.ExternalFilter.externalFilterSortDirection,
      externalFilterColumnDefs: state.ExternalFilter.externalFilterColumnDefs,
    })
  );

  // const sortOptions = externalFilterColumnDefs.map(def => ({
  //   value: def.field,
  //   label: def.label || def.field,
  // }));

  const sortOptions = [
    {
      value: "date",
      label: "Date",
    },
    {
      value: "",
      label: "Original Document Order",
    },
  ];
  const handleSortByChange = (selectedOption) => {
    dispatch(setExternalFiltersSortBy(selectedOption.value));
  };

  const handleSortDirectionChange = (direction) => {
    dispatch(setExternalFiltersSortDirection(direction));
  };

  return (
    <Container className="p-0">
      <Instructions selectedExportType={selectedExportType} />

      <Row
        style={{
          width: "100%",
        }}
      >
        <Col>
          <DisplayExternalFilters />
        </Col>
        <Col>
          <div className="font-weight-bold">Sort By</div>
          <Select
            options={sortOptions}
            value={sortOptions.find((option) => option.value === sortBy)}
            onChange={handleSortByChange}
            placeholder="Select Field to Sort By"
          />
        </Col>
        <Col>
          <div className="font-weight-bold">Sort Direction</div>
          <ButtonGroup>
            <button
              className={`btn ${
                sortDirection === "asc" ? "btn-primary" : "btn-outline-primary"
              }`}
              onClick={() => handleSortDirectionChange("asc")}
            >
              <span className="mr-1">Asc</span>

              <FontAwesomeIcon icon="chevron-up" />
            </button>
            <button
              className={`btn ${
                sortDirection === "desc" ? "btn-primary" : "btn-outline-primary"
              }`}
              onClick={() => handleSortDirectionChange("desc")}
            >
              <span className="mr-1">Desc</span>
              <FontAwesomeIcon icon="chevron-down" />
            </button>
          </ButtonGroup>
        </Col>
      </Row>

      <Row className="my-2">
        <Col>
          <>
            <div className="font-weight-bold">Documents</div>
            <DocumentsDropdown />
          </>
          {job && !job.isGeneric && !job.isBankMode && (
            <>
              <div className="font-weight-bold">Fields</div>
              <FieldNamesDropdown />
            </>
          )}
        </Col>
        <Col>
          <div className="font-weight-bold">Output Format</div>
          <ExportTypeDropdown />
        </Col>
      </Row>

      {job &&
        job.isGeneric &&
        ["csv", "xlsx"].includes(selectedExportType || "") && (
          <div className="my-2">
            <div className="font-weight-bold">
              Columns
              <PopoverHelp header="" placement="right" id="t3Help">
                Controls how many columns to split the data into.
              </PopoverHelp>
            </div>

            <div className="my-2">
              <GenericOptions />
            </div>
          </div>
        )}

      {typeof selectedExportType === "string" &&
        ([
          "xlsx",
          "csv",
          "xero-csv",
          "sage-csv",
          "netsuite",
          "sage-cloud-csv",
          "myob",
          "relate",
          "qif",
        ].includes(selectedExportType || "") ||
          selectedExportType?.startsWith("custom-")) && (
          <div className="my-2">
            <CsvOptions cta={exportToName(selectedExportType)} />
          </div>
        )}

      {["qbo", "ofx", "qfx"].includes(selectedExportType as string) && (
        <QboOptions format={selectedExportType} />
      )}
      {selectedExportType === "iif" && <IifOptions />}
    </Container>
  );
};
export default DownloadSection;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { rateJob } from "../../../docuclipper/api";
import { ReduxState } from "../../../redux";
import { createAlert } from "../../../redux/reducers/Alerts";
import { closeModal } from "../../../redux/reducers/ModalManager";
import AlertBanner from "../AlertBanner/AlertBanner";
import ClipLoader from "react-spinners/ClipLoader";
import { Field, Formik } from "formik";
import { Form } from "react-bootstrap";
import * as Yup from "yup";
import { formikTextArea } from "../../../utils/utils";
import { fetchJobRatings } from "src/redux/reducers/JobRatings";

type Props = {};
export const DescribeIssue = ({ description, cta }) => {
  const [loading, setLoading] = React.useState(false);
  const { job } = useSelector((state: ReduxState) => state.JobData);
  const dispatch = useDispatch();
  const initialValues = {
    description: "",
  };

  const handleSubmit = (values, { resetForm }) => {
    // Handle form submission here
    const { description } = values;
    if (job) {
      rateJob({
        jobId: job.id,
        rating: 1,
        details: description,
      })
        .then(() => {
          setLoading(false);
          dispatch(
            createAlert({
              message:
                "We're looking at the issue, thank you for your feedback",
              timeout: 0,
              type: "success",
            })
          );
          resetForm();
          dispatch(fetchJobRatings(job.id));
        })
        .catch((err) => {
          setLoading(false);
          dispatch(
            createAlert({
              message: "Error reporting issue",
              timeout: 0,
              type: "error",
            })
          );
        });
    }
  };
  const validationSchema = Yup.object().shape({
    description: Yup.string().required(
      "Description is required. Provide as much detail as possible so we can investigate and fix the issue"
    ),
  });

  return (
    <>
      <AlertBanner />

      <div className="m-2">{description}</div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, errors }) => {
          return (
            <Form>
              <Form.Group className="mx-1" controlId="description">
                {/* <Form.Label>Start Date</Form.Label> */}
                <Field
                  name="description"
                  placeholder=""
                  component={formikTextArea}
                />
              </Form.Group>

              <div>
                <Button className="mx-1" onClick={() => handleSubmit()}>
                  <FontAwesomeIcon icon="hand-pointer" />
                  <span className="ml-1">Report Issue</span>
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
      {/* <div className="m-2">
        <textarea
          rows={5}
          style={{ width: "100%" }}
          value={details}
          onChange={(e) => setDetails(e.target.value)}
        ></textarea>
      </div> */}
      {/* <Row className="justify-content-end">
        <div className="m-2">
          <div className="mx-2">
            {loading && <ClipLoader />}
            {!loading && (
              <Button
                onClick={() => {
                  if (job && job.id) {
                    setLoading(true);
                    
                  }

                  dispatch(closeModal("ratings"));
                }}
              >
                {cta}
              </Button>
            )}
          </div>
        </div>
      </Row> */}
    </>
  );
};

export const JobHelpMenu: React.SFC<Props> = ({}) => {
  const options = [
    {
      label: "The job didn't reconcile",
      value: "reconciliation",
      type: "qbo",
    },
    {
      label: "Dates are wrong",
      value: "dates",
      type: "qbo",
    },
    {
      label: "A few transactions are wrong/missing",
      value: "fewMissingTransactions",
      type: "qbo",
    },
    {
      label:
        "Many transactions are wrong/missing or full pages/sections are missing",
      value: "pageMissingTransactions",
      type: "qbo",
    },
    {
      label: "There are lots of red lines",
      value: "redLines",
      type: "qbo",
    },
    {
      label: "Issues importing into QuickBooks",
      value: "quickbooks",
      type: "qbo",
    },
    {
      label: "This job only supports XLSX downloads",
      value: "onlyXlsx",
      type: "generic",
    },
    {
      label: "The spreadsheet has extra data, I only want the transactions",
      value: "extraData",
      type: "generic",
    },
    {
      label: "Other issues",
      value: "other",
      type: "both",
    },
  ];

  const { job } = useSelector((state: ReduxState) => state.JobData);

  const validOptions = options.filter((o) => {
    if (o.type === "both") {
      return true;
    }
    if (job && job.isGeneric) {
      return o.type === "generic";
    } else if (job && !job.isGeneric) {
      return o.type === "qbo";
    }
    return false;
  });

  const [issueType, setIssueType] = React.useState<{
    value: string;
    label: string;
    type: string;
  }>(validOptions[0]);

  return (
    <div>
      <Select
        options={validOptions}
        value={issueType}
        onChange={(value) => {
          if (value) {
            setIssueType(value);
          }
        }}
      />

      <div className="m-2">
        {issueType.value === "reconciliation" && (
          <div className="m-2">
            <a
              href="https://www.docuclipper.com/docs/ui/user-guide/resolving-issues/reconciliation/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon="question-circle" />
              <span className="ml-1">
                Documentation on how to reconcile the statements.
              </span>
              <FontAwesomeIcon className="ml-2" icon="external-link-alt" />
            </a>
          </div>
        )}

        {issueType.value === "dates" && (
          <div className="m-2">
            <a
              href="https://www.docuclipper.com/docs/ui/user-guide/resolving-issues/dates/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon="question-circle" />
              <span className="ml-1">Documentation on how to fix dates.</span>
              <FontAwesomeIcon className="ml-2" icon="external-link-alt" />
            </a>
          </div>
        )}
        {issueType.value === "pageMissingTransactions" && (
          <div>
            <DescribeIssue
              cta="Report Issue"
              description={`Describe the issue. Include details like file names, page numbers and as
        much information as possible to help the support team triage and fix the
        problem.`}
            />
          </div>
        )}
        {issueType.value === "fewMissingTransactions" && (
          <div className="m-2">
            <a
              href="https://www.docuclipper.com/docs/ui/user-guide/resolving-issues/reconciliation/#fixing-errors-to-reconcile-the-statement"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon="question-circle" />
              <span className="ml-1">
                Documentation on how to fix the transactions.
              </span>
              <FontAwesomeIcon className="ml-2" icon="external-link-alt" />
            </a>
          </div>
        )}
        {issueType.value === "redLines" && (
          <div className="m-2">
            <a
              href="https://www.docuclipper.com/docs/ui/user-guide/resolving-issues/reconciliation/#fixing-errors-to-reconcile-the-statement"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon="question-circle" />
              <span className="ml-1">
                Documentation on how to fix the red lines.
              </span>
              <FontAwesomeIcon className="ml-2" icon="external-link-alt" />
            </a>
          </div>
        )}

        {issueType.value === "quickbooks" && (
          <>
            <div className="m-2">
              <a
                href="https://www.docuclipper.com/docs/ui/user-guide/extracting-data/import-data-quickbooks-desktop/#handling-import-errors"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon="question-circle" />
                <span className="ml-1">
                  Documentation on how to fix QuickBooks import errors.
                </span>
                <FontAwesomeIcon className="ml-2" icon="external-link-alt" />
              </a>
            </div>
          </>
        )}

        {issueType.value === "onlyXlsx" && (
          <>
            <div className="m-2">
              <a
                href="https://www.docuclipper.com/docs/ui/user-guide/extracting-data/generic-conversion/#generic-conversion"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon="question-circle" />
                <span className="ml-1">
                  How to fix "This job only supports XLSX downloads".
                </span>
                <FontAwesomeIcon className="ml-2" icon="external-link-alt" />
              </a>
            </div>
          </>
        )}

        {issueType.value === "extraData" && (
          <>
            <div className="m-2">
              <a
                href="https://www.docuclipper.com/docs/ui/user-guide/extracting-data/generic-conversion/#generic-conversion"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon="question-circle" />
                <span className="ml-1">
                  How to control what data gets extracted.
                </span>
                <FontAwesomeIcon className="ml-2" icon="external-link-alt" />
              </a>
            </div>
          </>
        )}

        {issueType.value === "other" && (
          <div>
            <DescribeIssue
              cta="Report Issue"
              description={`Describe the issue in as much detail as possible`}
            />
          </div>
        )}
      </div>
    </div>
  );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "..";

import { createAlert } from "./Alerts";
import { categorizeAction } from "./Categories";
import { getJobMatchingTransactions } from "../../docuclipper/api";
import { TransactionLine } from "../../docuclipper/DocuclipperTypes";

const initialState: {
  text: string;
} = {
  text: "",
};

const slice = createSlice({
  name: "MatchedTransactions",
  initialState,
  reducers: {
    setText(state, action: PayloadAction<string>) {
      state.text = action.payload;
    },
  },
});

export const { setText } = slice.actions;

export default slice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "..";
import { getJobs } from "../../docuclipper/api";
import { Job } from "../../docuclipper/DocuclipperTypes";

type JobWithProgress = {
  job: Job;
  progress: number | null;
};
const initialState: {
  jobs: JobWithProgress[];
  loading: boolean;
  error: string | null;
  totalJobs: number;
} = {
  jobs: [],
  loading: false,
  error: null,
  totalJobs: 0,
};

const slice = createSlice({
  name: "Jobs",
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
      state.error = null;
    },
    setJobs(state, action: PayloadAction<Job[]>) {
      state.jobs = action.payload.map((j) => ({ job: j, progress: null }));
      state.loading = false;
    },
    setTotalJobs(state, action: PayloadAction<number>) {
      state.totalJobs = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    updateJob(
      state,
      action: PayloadAction<{ job: Partial<Job>; progress?: number | null }>
    ) {
      state.jobs = state.jobs.map((j) =>
        j.job.id === action.payload.job.id
          ? {
              job: { ...j.job, ...action.payload.job },
              progress: action.payload.progress || j.progress,
            }
          : j
      );
    },
  },
});

export const { load, setJobs, setError, updateJob, setTotalJobs } =
  slice.actions;

export const fetchJobs =
  (
    jobType: Job["type"],
    jobStatus: Job["status"] | null,
    all: boolean,
    paying: boolean,
    templateId: number | undefined,
    top: string,
    offset: number,
    limit: number,
    tags: string
  ) =>
  async (dispatch, getState) => {
    const state: ReduxState = getState();
    if (state.Jobs.loading) {
      return;
    }
    dispatch(load());

    try {
      const jobs = await getJobs({
        type: jobType,
        status: jobStatus,
        all,
        paying,
        templateId,
        top,
        offset,
        limit,
        tags,
      });
      dispatch(setJobs(jobs.rows));
      dispatch(setTotalJobs(jobs.count));
    } catch (err) {
      //
      dispatch(setError(err.message || "Error getting jobs"));
    }
  };

export default slice.reducer;

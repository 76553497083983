// src/views/Pages/Invoices/useInvoicePDF.ts
import { getJwt } from "src/auth";

const BASE_URL = process.env.NODE_ENV === "production" ? "https://www.docuclipper.com" : "http://localhost:8080";

const useInvoicePDF = ({
  transactionLine,
  selectedDocumentId,
}) => {
  const pageNumber = transactionLine ? transactionLine.pageNumber : 0;

  const pdfURL = selectedDocumentId && transactionLine
    ? `${BASE_URL}/api/v1/protected/document/${selectedDocumentId}/file.pdf?token=${getJwt()}#page=${pageNumber + 1}&toolbar=0`
    : "";

  return {
    pdfURL,
    pageNumber,
  };
};

export default useInvoicePDF;
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  categorizeJobTransactions,
  categorizeJobTransactionsAsync,
  getAllTags,
  getAsyncTask,
  getCategorizedTransactions,
  getTransactionCategories,
} from "../../docuclipper/api";
import { ReduxState } from "..";

import { setRefreshKey, setTls } from "./TransactionManager";
import { setByCategoryKeyword, setCategories } from "./Categories";
import { createAlert } from "./Alerts";
import { pollForCompletion } from "./AsyncUtils";

export function randomColor(i) {
  const colors = ["#7982B9", "#A5C1DC", "#E9F6FA"];
  return colors[i % colors.length];
}
const initialState: {
  loading: boolean;
  asyncJobId: string | null;
} = {
  loading: false,
  asyncJobId: null,
};

const slice = createSlice({
  name: "CategoriesAsync",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setAsyncJobId(state, action: PayloadAction<string>) {
      state.asyncJobId = action.payload;
    },
  },
});

export const { setAsyncJobId, setLoading } = slice.actions;

export const categorizeActionAsync = () => async (dispatch, getState) => {
  const state: ReduxState = getState();
  if (state.Categories.loading) {
    return;
  }
  const { job } = state.JobData;
  if (!job) {
    return;
  }
  const jobId = job.id;
  const { selectedTagRegexesSet } = state.TagRegexes;

  if (!selectedTagRegexesSet) {
    return;
  }
  try {
    dispatch(setLoading(true));
    const { asyncJobId } = await categorizeJobTransactionsAsync(
      jobId,
      selectedTagRegexesSet.name
    );

    dispatch(setAsyncJobId(asyncJobId));

    const onSuccess = async () => {
      dispatch(setLoading(false));
      const { transactions, categories, byCategoryKeyword } = await getCategorizedTransactions(
        jobId,
        state.Reconciler.selectedAccount || ''
      );
      dispatch(setTls({ transactions, resetPages: false }));
      dispatch(
        setCategories(
          categories
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((c, i) => ({
              ...c,
              color: randomColor(i),
            }))
        )
      );
      dispatch(
        setByCategoryKeyword(
          byCategoryKeyword
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((c, i) => ({
              ...c,
              color: randomColor(i),
            }))
        )
      );
      dispatch(setRefreshKey())
      dispatch(
        createAlert({
          message: "Transactions categorized successfully",
          timeout: 0,
          type: "success",
        })
      );
    };
    const onFail = async () => {
      dispatch(setLoading(false));
      dispatch(
        createAlert({
          message: "Error categorizing transactions",
          timeout: 0,
          type: "error",
        })
      );
    };
    pollForCompletion(dispatch, asyncJobId, jobId, onSuccess, onFail);

    //   dispatch(setTls({ transactions, resetPages: false }));
    //   dispatch(
    //     setCategories(
    //       categories
    //         .sort((a, b) => a.name.localeCompare(b.name))
    //         .map((c, i) => ({
    //           ...c,
    //           color: randomColor(i),
    //         }))
    //     )
    //   );
  } catch (err) {
    dispatch(setLoading(false));
  }
};

export default slice.reducer;

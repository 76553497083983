import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import React, { useCallback, useMemo, useRef } from "react";
import { Button, Col, Row } from "react-bootstrap";

type Props = {
  rowData;
  columnDefs;
  height;
  width;
  hasExport;
  reactiveCustomComponents?: any;
};
export const AgTable: React.FC<Props & AgGridReactProps> = ({
  rowData,
  columnDefs,
  height,
  width,
  hasExport,
  ...rest
}) => {
  const ref = useRef();
  const onBtnExport = useCallback(() => {
    if (ref && ref.current) {
      (ref.current as any).api.exportDataAsCsv();
    }
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
    };
  }, []);

  return (
    <>
      {hasExport && (
        <Row>
          <Col>
            <Button variant="link" onClick={onBtnExport}>
              <FontAwesomeIcon icon="download" />
              <span className="ml-1">Export as CSV</span>
            </Button>
          </Col>
        </Row>
      )}
      <Row>
        <div className="ag-theme-alpine" style={{ height, width }}>
          <AgGridReact
            defaultColDef={defaultColDef}
            colResizeDefault="shift"
            singleClickEdit
            ref={ref as any}
            rowData={rowData}
            columnDefs={columnDefs}
            {...rest}
          ></AgGridReact>
        </div>
      </Row>
    </>
  );
};
AgTable.defaultProps = {
  hasExport: false,
};
export default AgTable;

import React, { useState, useMemo, useEffect } from "react";
import AgTable from "../AnalyzeTransactionsTable/AgTable";
import { useTransactions } from "../AnalyzeTransactionsTable/hooks";
import { Button, Col, Row, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { createAlert } from "../../../../redux/reducers/Alerts";
import AlertBanner from "../../../Docuclipper/AlertBanner/AlertBanner";
import { TransactionLine } from "../../../../docuclipper/DocuclipperTypes";
import { createMatchingTransactions } from "../../../../docuclipper/api";
import { fetchMatchedTransactions } from "../../../../redux/reducers/MatchedTransactions";
import { ReduxState } from "../../../../redux";
import ExternalFilter from "src/views/Docuclipper/ExternalFilter";
import {
  clearExternalFilters,
  setExternalFiltersColumnDefs,
} from "src/redux/reducers/ExternalFilter";
import _, { uniq } from "lodash";

export const CreateDoubleMatch = () => {
  const { job } = useSelector((state: ReduxState) => state.JobData);
  const { matches } = useSelector(
    (state: ReduxState) => state.MatchedTransactions
  );
  const m = {};
  for (const x of matches) {
    m[x.match.destinationTransactionId] = true;
    m[x.match.sourceTransactionId] = true;
  }

  const dispatch = useDispatch();
  const transactions = useTransactions();
  const notMatchingTransactions = transactions.filter(
    (t) => !((t as any).id in m)
  );

  const [leftRowData, setLeftRowData] = useState(notMatchingTransactions); // Your left table data
  const [rightRowData, setRightRowData] = useState(notMatchingTransactions); // Your right table data
  const [leftSelected, setLeftSelected] = useState<TransactionLine[] | null>(
    null
  );
  const [rightSelected, setRightSelected] = useState<TransactionLine[] | null>(
    null
  );

  const columnDefs = [
    { field: "account", filter: "agTextColumnFilter", flex: 1 },
    { field: "date", filter: "agDateColumnFilter", flex: 1, sortable: true },
    { field: "description", filter: "agTextColumnFilter", flex: 2 },
    {
      field: "amount",
      filter: "agNumberColumnFilter",
      flex: 1,
      sortable: true,
    },
  ];

  const leftExternalFilterColumns = useMemo(
    () => [
      {
        field: "account",
        label: "Account",
        filter: true,
        filterType: "select",
        options: uniq(transactions.map((t) => t.account)).map((account) => ({
          label: account,
          value: account,
        })),
        defaultFilterType: "equals",
      },
      {
        field: "date",
        label: "Date",
        filter: true,
        filterType: "date",
        defaultFilterType: "equals",
      },
      {
        field: "description",
        label: "Description",
        filter: true,
        filterType: "text",
        defaultFilterType: "contains",
      },
      {
        field: "amount",
        label: "Amount",
        filter: true,
        filterType: "number",
        defaultFilterType: "equals",
      },
    ],
    [transactions]
  );

  const rightExternalFilterColumns = useMemo(
    () => [
      {
        field: "account",
        label: "Account",
        filter: true,
        filterType: "select",
        options: uniq(transactions.map((t) => t.account)).map((account) => ({
          label: account,
          value: account,
        })),
        defaultFilterType: "equals",
      },
      {
        field: "date",
        label: "Date",
        filter: true,
        filterType: "date",
        defaultFilterType: "equals",
      },
      {
        field: "description",
        label: "Description",
        filter: true,
        filterType: "text",
        defaultFilterType: "contains",
      },
      {
        field: "amount",
        label: "Amount",
        filter: true,
        filterType: "number",
        defaultFilterType: "equals",
      },
    ],
    [transactions]
  );

  useEffect(() => {
    dispatch(clearExternalFilters());
    dispatch(
      setExternalFiltersColumnDefs({ externalFilterColumnDefs: columnDefs })
    );
  }, [columnDefs]);

  const leftGridApi = React.useRef<any>(null);
  const rightGridApi = React.useRef<any>(null);

  const onLeftGridReady = (params) => {
    // Fetch and set data for left grid
  };

  const onRightGridReady = (params) => {
    // Fetch and set data for right grid
  };

  const onLeftSelectionChanged = (event) => {
    setLeftSelected(event.api.getSelectedRows());
  };

  const onRightSelectionChanged = (event) => {
    setRightSelected(event.api.getSelectedRows());
  };

  const createMatch = () => {
    // Logic to create a match with transactions from both tables

    if (
      selectedOption === "double" &&
      leftSelected &&
      rightSelected &&
      leftSelected?.length > 0 &&
      rightSelected?.length > 0
    ) {
      if (!leftSelected || !rightSelected) {
        dispatch(
          createAlert({
            message: "Select 2 transactions",
            timeout: 0,
            type: "error",
          })
        );
        return;
      }
      if ((leftSelected[0] as any).id === rightSelected[0].id) {
        dispatch(
          createAlert({
            message: "Select different transactions",
            timeout: 0,
            type: "error",
          })
        );
      }
      if ((leftSelected as any).account === rightSelected[0].account) {
        dispatch(
          createAlert({
            message: "Select different accounts",
            timeout: 0,
            type: "error",
          })
        );
      }
    }

    const matches =
      selectedOption === "single"
        ? (leftSelected || ([] as any)).map((x) => {
            const isDebit = (x as any).amount < 0;
            return {
              sourceTransactionId: isDebit ? x.id : null,
              destinationTransactionId: !isDebit ? x.id : null,
              status: "approved",
            };
          })
        : leftSelected &&
          rightSelected &&
          leftSelected?.length > 0 &&
          rightSelected?.length > 0
        ? [
            {
              sourceTransactionId: (leftSelected[0] as any)?.id,
              destinationTransactionId: rightSelected[0]?.id,
              status: "approved",
            },
          ]
        : [];

    if (job) {
      createMatchingTransactions(job.id, matches)
        .then(() => {
          dispatch(
            createAlert({
              message: "Match created successfully",
              timeout: 0,
              type: "success",
            })
          );
          dispatch(fetchMatchedTransactions());
        })
        .catch((err) => {
          dispatch(
            createAlert({
              message: "Error creating match",
              timeout: 0,
              type: "error",
            })
          );
        });
    }
    //     createMatch() {
    // //
    //     }
  };

  const createSingleMatch = () => {
    // Logic to create a single match with transaction from one table
  };

  const onRowClicked = (event) => {
    if (event.node.selected) {
      event.node.selected = false;
    }
  };
  const [selectedOption, setSelectedOption] = useState("double");

  const handleChange = (e) => setSelectedOption(e.target.value);

  return (
    <div>
      <AlertBanner />
      <Row className="mx-2">
        <Form>
          <Form.Check
            type="radio"
            label="Between 2 Accounts"
            name="radioGroup"
            value="double"
            checked={selectedOption === "double"}
            onChange={handleChange}
            inline
          />

          <Form.Check
            type="radio"
            label="Single Account"
            name="radioGroup"
            value="single"
            checked={selectedOption === "single"}
            onChange={handleChange}
            inline
          />
        </Form>
      </Row>
      <Row className="mb-2">
        <Col md="6">
          Filter Left Table
          <ExternalFilter
            columnDefs={leftExternalFilterColumns}
            gridApi={leftGridApi.current}
          />
        </Col>
        {selectedOption === "double" && (
          <Col md="6">
            Filter Right Table
            <ExternalFilter
              columnDefs={rightExternalFilterColumns}
              gridApi={rightGridApi.current}
            />
          </Col>
        )}
      </Row>
      <Row>
        <Col className="p-2 m-2">
          <AgTable
            hasExport={false}
            height={"400px"}
            width={"100%"}
            columnDefs={columnDefs}
            rowData={leftRowData}
            rowSelection={selectedOption === "single" ? "multiple" : "single"}
            // onRowClicked={onRowClicked}
            rowMultiSelectWithClick={true}
            onGridReady={(params) => {
              leftGridApi.current = params.api;
            }}
            onSelectionChanged={onLeftSelectionChanged}
          />
        </Col>
        {selectedOption === "double" && (
          <Col className="p-2 m-2">
            <AgTable
              hasExport={false}
              height={"400px"}
              width={"100%"}
              columnDefs={columnDefs}
              rowData={rightRowData}
              rowSelection="single"
              // onRowClicked={onRowClicked}
              //   rowMultiSelectWithClick={true}
              onGridReady={(params) => {
                rightGridApi.current = params.api;
              }}
              onSelectionChanged={onRightSelectionChanged}
            />
          </Col>
        )}
      </Row>
      <Row className="justify-content-end">
        <Button
          className="m-2"
          onClick={createMatch}
          disabled={
            selectedOption === "double"
              ? !leftSelected || !rightSelected
              : !leftSelected
          }
        >
          Create Match
        </Button>
      </Row>
    </div>
  );
};

export default CreateDoubleMatch;

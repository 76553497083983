import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, Formik } from "formik";
import React from "react";
import {
  Button,
  Card,
  Form,
  FormGroup,
  Modal,
  Overlay,
  Popover,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { isNullOrUndefined } from "util";
import * as Yup from "yup";
import { createTransactions } from "../../../../docuclipper/api";
import { ReduxState } from "../../../../redux";
import { createAlert } from "../../../../redux/reducers/Alerts";
import { fetchTotals } from "../../../../redux/reducers/Reconciler";
import {
  addAddedManually,
  fetchMfs,
  setTls,
  toggleLoading,
} from "../../../../redux/reducers/TransactionManager";
import {
  formikInput,
  formikReactSelectInput,
  formikSelectInput,
} from "../../../../utils/utils";
import AccountSelector from "./AccountSelector";
import { groupBy, uniq } from "lodash";
import AlertBanner from "../../../Docuclipper/AlertBanner/AlertBanner";
import { getDocumentName } from "../../Analyze/AnalyzeTransactionsTable/utils";
import { useChunkData } from "../../Analyze/AnalyzeTransactionsTable/hooks";

export function Transaction2({ onTransactionAdded }) {
  const dispatch = useDispatch();

  const {
    // selectedDocumentId,
    tls,
    // selectedPage,
    mfs,
    // selectedChunk,
    // selectedAccount,
    // selectedAccountNickname,
  } = useSelector((state: ReduxState) => state.TransactionManager);

  const { documents } = tls;

  const { job } = useSelector((state: ReduxState) => state.JobData);

  const addTransactionHelper = (
    {
      date,
      description,
      amount,
      fieldName,
      // documentId,
      // pageNumber,
      // account,
      chunk,
    },
    { resetForm, setFieldValue, setFieldTouched }
  ) => {
    if (job) {
      dispatch(toggleLoading());

      // console.log({ chunk });
      const { accountName, documentId, chunk: chunk2 } = chunk;
      //TODO extract account+chunk from "chunk" +

      const pageNumber = 1;
      createTransactions(job.id as any, documentId, [
        {
          date,
          description,
          amount,
          fieldName,
          pageNumber,
          account: accountName,
          accountNickname: accountName,
          chunk: chunk2,
        },
      ])
        .then(({ transactions }) => {
          dispatch(toggleLoading());
          dispatch(setTls({ transactions, resetPages: false }));
          dispatch(
            createAlert({
              message: "Transaction created successfully",
              timeout: 0,
              type: "success",
            })
          );
          dispatch(fetchTotals());
          dispatch(addAddedManually());
          dispatch(fetchMfs());
          if (onTransactionAdded) {
            onTransactionAdded();
          }
        })
        .catch((err) => {
          dispatch(toggleLoading());
          dispatch(
            createAlert({
              message: "Error creating transaction",
              timeout: 0,
              type: "error",
            })
          );
        });
    }

    // resetForm();
    setFieldValue("date", "");
    setFieldValue("description", "");
    setFieldValue("amount", "");
    setFieldTouched("date", false);
    setFieldTouched("description", false);
    setFieldTouched("amount", false);
  };
  // const { accounts, selectedAccount } = useSelector(
  //   (state: ReduxState) => state.TransactionManager
  // );

  // TODO get chunks representation  from newsummarytable

  const chunkData = useChunkData();

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        date: "",
        description: "",
        amount: "",
        fieldName: tls.fieldNames.length > 0 ? tls.fieldNames[0] : "",
        // account: accounts.length > 0 ? accounts[0] : null,
        chunk: null,
        // documentId: documents.length > 0 ? documents[0].id : null,
        // pageNumber: 1,
      }}
      validationSchema={Yup.object().shape({
        date: Yup.string()
          .trim()
          .matches(
            /^([0-9]{4})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
            "Date should be in YYYY-MM-DD format with valid month (01-12) and day (01-31)"
          )
          .required(),
        description: Yup.string().required(),
        amount: Yup.number()
          .typeError("Enter a positive or negative number")
          .required(),
        fieldName: Yup.string().required(),
        // account: Yup.string().required(),
        chunk: Yup.object().nullable().required("Account/Period is required"),
        // documentId: Yup.string().required(),
        // pageNumber: Yup.number().required(),
      })}
      onSubmit={addTransactionHelper}
    >
      {({ submitForm, values, setFieldValue }) => {
        return (
          <>
            {tls.fieldNames.length > 1 && (
              <FormGroup className="m-2">
                <strong>
                  <Form.Label>Field Name</Form.Label>
                </strong>
                <Field
                  name="fieldName"
                  as="select"
                  className=""
                  component={formikSelectInput}
                >
                  {tls.fieldNames.map((x) => (
                    <option value={x}>{x}</option>
                  ))}
                </Field>
              </FormGroup>
            )}

            <FormGroup className="m-2">
              <strong>
                <Form.Label>Account/Period</Form.Label>
              </strong>
              <Field
                name="chunk"
                component={formikReactSelectInput}
                options={chunkData.map((chunk, i) => ({
                  value: chunk,
                  label: `${chunk.accountName} from ${chunk.startDate} to ${chunk.endDate} [${chunk.documentName} ]`,
                }))}
              ></Field>
            </FormGroup>
            <FormGroup className="m-2">
              <strong>
                <Form.Label>Date</Form.Label>
              </strong>
              <Field name="date" component={formikInput} placeholder="" />
            </FormGroup>
            <FormGroup className="m-2">
              <strong>
                <Form.Label>Description</Form.Label>
              </strong>
              <Field
                name="description"
                component={formikInput}
                placeholder=""
              />
            </FormGroup>
            <FormGroup className="m-2">
              <strong>
                <Form.Label>Amount</Form.Label>
              </strong>
              <Field name="amount" component={formikInput} placeholder="" />
            </FormGroup>
            <Row className="justify-content-end">
              <div>
                <Button
                  className="m-2"
                  onClick={() => {
                    submitForm();
                  }}
                >
                  <FontAwesomeIcon icon="plus" />
                  <span className="ml-1">Add missing transaction</span>
                </Button>
              </div>
            </Row>
          </>
        );
      }}
    </Formik>
  );
}

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ReduxState } from "../../../../../redux";
import { setDownloadOptionsSelectedDocumentIds } from "../../../../../redux/reducers/TransactionManager";
import { CheckboxOption } from "./utils";
import { getDocumentName } from "src/views/Pages/Analyze/AnalyzeTransactionsTable/utils";

export const DocumentsDropdown: React.FC = () => {
  const dispatch = useDispatch();

  const { tls, downloadOptions } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );
  // const { documents } = tls;
  // console.log({ documents });
  const { selectedDocumentIds } = downloadOptions;

  // const options = (documents || []).map((doc) => ({
  //   value: doc.id.toString(),
  //   label: doc.originalname,
  // }));

  const { data: jobDocs } = useSelector(
    (state: ReduxState) => state.JobDocument
  );

  const uniqueDocumentIds = Array.from(
    new Set(jobDocs.map((item) => item.documentId))
  );
  const options = uniqueDocumentIds.map((docId) => ({
    value: docId,
    label: getDocumentName(jobDocs, docId),
  }));

  const selectedOptions = options.filter((option) =>
    selectedDocumentIds.includes(option.value)
  );
  return (
    <Select
      options={options}
      value={selectedOptions}
      onChange={(value) => {
        dispatch(
          setDownloadOptionsSelectedDocumentIds(
            ((value || []) as any).map((x) => x.value)
          )
        );
      }}
      isMulti={true}
      closeMenuOnSelect={false}
      allowSelectAll={true}
      components={{
        Option: CheckboxOption,
      }}
    />
  );
};
export default DocumentsDropdown;

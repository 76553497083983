import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../../../redux";
import PopoverHelp from "../../../../Docuclipper/PopoverHelp";
import CsvOptions from "./CsvOptions";
import DocumentsDropdown from "./DocumentsDropdown";
import ExportTypeDropdown from "./ExportTypeDropdown";
import FieldNamesDropdown from "./FieldNamesDropdown";
import GenericOptions from "./GenericOptions";
import IifOptions from "./IifOptions";
import Instructions from "./Instructions";
import QboOptions from "./QboOptions";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import { formikInput } from "../../../../../utils/utils";
import { downloadSpreadsheetHelper } from "./utils";
import { automaticModeAsync } from "../../../../../redux/reducers/AutomaticModeAsync";
import ClipLoader from "react-spinners/ClipLoader";

// Validation schema
const validationSchema = Yup.object({
  numCols: Yup.number()
    .required("Number of columns is required")
    .min(1, "Must be at least 1")
    .max(20, "Must be 20 or less"),
  action: Yup.string(),
});

const PreviewForm = () => {
  const dispatch = useDispatch();
  const { job } = useSelector((state: ReduxState) => state.JobData);
  const { tls } = useSelector((state: ReduxState) => state.TransactionManager);
  const { loading: asyncLoading } = useSelector(
    (state: ReduxState) => state.AutomaticModeAsync
  );

  const { documents } = tls;

  const [loading, setLoading] = React.useState(false);

  return (
    <div>
      <Formik
        initialValues={{ numCols: 10, action: "" }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          //
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false); // Reset submitting state
          // }, 400);

          const T = 15;
          const T2 = 50;
          const T3 = values.numCols;
          const T4 = 50;

          setLoading(true);
          if (values.action === "preview") {
            dispatch(automaticModeAsync(T, T2, T3, T4));
            setLoading(false);
            setSubmitting(false);
          } else if (values.action === "download") {
            downloadSpreadsheetHelper(
              job,
              "FieldsToExcel1",
              "xlsx",
              {
                T,
                T2,
                T3,
                T4,
                separateFilesForAccounts: false,
                fileType: "csv",
                splitMultilineDescription: false,
              },
              documents.map((x) => x.id) as any[],
              ["generic"],
              [],
              [],
              "date",
              "asc",
              "CSV"
            )
              .then(() => {
                setLoading(false);
                setSubmitting(false);
              })
              .catch((err) => {
                setLoading(false);
                setSubmitting(false);
              });
          }
        }}
      >
        {({
          isSubmitting,
          errors,
          touched,
          values,
          setFieldValue,
          submitForm,
        }) => (
          <Form>
            {(loading || asyncLoading) && <ClipLoader />}
            {!(loading || asyncLoading) && (
              <Row className="align-items-center">
                <Col xs="auto">
                  <div>
                    <Field
                      type="range"
                      className="ml-2"
                      id="firstName"
                      name="numCols"
                      component={formikInput}
                      min="1"
                      max="20"
                    />
                    <Field type="hidden" name="action" />

                    <label className="ml-2">{`${values.numCols} Columns`}</label>
                    <PopoverHelp
                      header=""
                      placement="right"
                      id="automaticColumnsHelp"
                    >
                      Controls how the data is split into different columns.
                      Choose less columns to pack more data into a single
                      column. Choose more columns to split data into separate
                      columns.
                    </PopoverHelp>
                  </div>
                </Col>
                <Col xs="auto">
                  <div>
                    <Button
                      className="mx-1"
                      variant="light"
                      disabled={isSubmitting}
                      onClick={() => {
                        setFieldValue("action", "preview");
                        submitForm();
                      }}
                    >
                      <FontAwesomeIcon icon="eye" />
                      <span className="ml-1">Adjust Columns</span>
                    </Button>
                    <Button
                      className="ml-1 mr-4"
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                      name="action"
                      value="download"
                      onClick={() => {
                        setFieldValue("action", "download");
                        submitForm();
                      }}
                    >
                      <FontAwesomeIcon icon="download" />
                      <span className="ml-1">Download Data</span>
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

type Props = {};
export const DownloadAutomaticMode: React.SFC<Props> = ({}) => {
  const { options, selectedExportType } = useSelector(
    (state: ReduxState) => state.DownloadOptions
  );
  const { job } = useSelector((state: ReduxState) => state.JobData);

  const [loading, setLoading] = React.useState(false);

  return (
    <Row className="align-items-center justify-content-end">
      <PreviewForm />
    </Row>
  );
};
export default DownloadAutomaticMode;

import { PayloadAction } from "@reduxjs/toolkit";
import { Thumbnail, Document } from "../../../../docuclipper/DocuclipperTypes";
import { TemplateFieldState } from "../../TemplateFieldTypes";

export const _thumbnailsLoad = (state: TemplateFieldState) => {
  state.thumbnails.loading = true;
  state.thumbnails.error = null;
};
export const _thumbnailsDeleteAll = (state: TemplateFieldState) => {
  state.thumbnails.thumbnails = [];
  state.thumbnails.offset = 0;
};
export const _thumbnailsSetData = (
  state: TemplateFieldState,
  action: PayloadAction<{ thumbnails: Thumbnail[]; document: Document }>
) => {
  const { thumbnails, document } = action.payload;

  if (document) {
    const numPages = document.numPages;
    state.thumbnails.loading = false;
    state.thumbnails.thumbnails = action.payload.thumbnails;
    state.thumbnails.offset = action.payload.thumbnails.length;
    state.thumbnails.hasMore = state.thumbnails.offset < numPages;
  }
};
export const _thumbnailsSetError = (state, action: PayloadAction<string>) => {
  state.thumbnails.loading = false;
  state.thumbnails.error = action.payload;
};

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewTag,
  fetchTagRegexes,
  setSelectedTagRegexesSet,
  updateTagRegexesAction,
} from "../../../../redux/reducers/TagRegexes";
import Select from "react-select";
import { ReduxState } from "../../../../redux";
import { flatten, groupBy } from "lodash";
import {
  Button,
  Row,
  Col,
  Modal,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import {
  deleteTagRegexes,
  deleteTagRegexesSet,
} from "../../../../docuclipper/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCustomUi } from "../../../Docuclipper/ConfirmAlert/ConfirmAlert";
import { confirmAlert } from "react-confirm-alert";
import TagCards from "./TagCards";
import { TagCategoryRule } from "src/docuclipper/DocuclipperTypes";
import {
  handleAddNewKeyword,
  extractKeywordsFromJsonRules,
} from "src/utils/categoryManagement";
import AlertBanner from "src/views/Docuclipper/AlertBanner/AlertBanner";

export const TagRegexes: React.FC = () => {
  const [existingTag, setExistingTag] = React.useState("");
  const [newTag, setNewTag] = React.useState("");
  const [show, setShow] = React.useState(false);
  const [filterText, setFilterText] = React.useState("");

  const { tagRegexes, tagRegexesSets, selectedTagRegexesSet } = useSelector(
    (state: ReduxState) => state.TagRegexes
  );

  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(fetchTagRegexes("categorize"));
  }, []);
  const lsKey = "docuclipper.ui.selectedCategoryGroup";
  React.useEffect(() => {
    const savedSelection = localStorage.getItem(lsKey);

    if (savedSelection) {
      dispatch(setSelectedTagRegexesSet(savedSelection));
    } else if (tagRegexesSets.length > 0) {
      dispatch(setSelectedTagRegexesSet(tagRegexesSets[0].name));
    }
  }, [tagRegexesSets]);

  const names = Object.keys(groupBy(tagRegexesSets, "name"));
  const selectedTagIds = !selectedTagRegexesSet
    ? []
    : tagRegexesSets
        .filter((t) => t.name === selectedTagRegexesSet.name)
        .map((x) => x.tagRegexesId);

  const allTagIds = flatten(tagRegexesSets.map((x) => x.tagRegexesId));

  const getTagName = (id) => {
    const matches = tagRegexes.filter((x) => x.id === id);
    if (matches.length > 0) {
      return matches[0].tag;
    }
    return "default";
  };

  const getTagLabel = (id) => {
    const matches = tagRegexes.filter((x) => x.id === id);
    if (matches.length > 0) {
      return matches[0]?.tagLabel ? `(${matches[0]?.tagLabel})` : "";
    }
    return "";
  };

  const existingCategoriesOptions = allTagIds
    .filter((x) => !selectedTagIds.includes(x))
    .map((x) => {
      const setName =
        tagRegexesSets.find((set) => set.tagRegexesId.includes(x))?.name ||
        "unknown";
      return {
        label: `${setName} - ${getTagName(x)} ${getTagLabel(x)}`,
        value: x,
      };
    });

  const hideStr = (x: string) =>
    x === "c89e2ac11db6184500f0933279a407b3" ? "default" : x;
  const toggle = () => setShow(!show);

  const isDefault = selectedTagRegexesSet
    ? selectedTagRegexesSet.name === "c89e2ac11db6184500f0933279a407b3"
    : false;

  const onAddNewKeyword = (
    tagId: string,
    keyword: string,
    isPositive: boolean
  ) => {
    handleAddNewKeyword(dispatch, tagRegexes, tagId, keyword, isPositive);
  };

  const handleAddCategory = () => {
    if (newTag.trim() === "") {
      return;
    }

    if (selectedTagRegexesSet) {
      const rule: TagCategoryRule = {
        keywords: [],
        negativeKeywords: [],
        tag: newTag.trim(),
      };
      dispatch(
        addNewTag(selectedTagRegexesSet.name, newTag.trim(), "", "[]", rule)
      );
      setNewTag("");
    }
  };

  const onRemoveKeyword = (
    tagId: string,
    keyword: string,
    isPositive: boolean
  ) => {
    const tagToUpdate = tagRegexes.find((t) => t.id === tagId);
    if (tagToUpdate) {
      let positiveKeywords = [];
      let negativeKeywords = [];

      if (tagToUpdate.jsonRules) {
        const keywords = extractKeywordsFromJsonRules(tagToUpdate.jsonRules);
        positiveKeywords = keywords.positiveKeywords;
        negativeKeywords = keywords.negativeKeywords;
      } else if (tagToUpdate.regexes) {
        positiveKeywords = JSON.parse(tagToUpdate.regexes);
      }

      const updatedPositiveKeywords = isPositive
        ? positiveKeywords.filter((k) => k !== keyword)
        : positiveKeywords;
      const updatedNegativeKeywords = isPositive
        ? negativeKeywords
        : negativeKeywords.filter((k) => k !== keyword);
      const updatedRule = {
        keywords: updatedPositiveKeywords,
        negativeKeywords: updatedNegativeKeywords,
        tag: tagToUpdate.tag,
      };

      dispatch(
        updateTagRegexesAction(
          tagId,
          tagToUpdate.tag,
          tagToUpdate.tagLabel,
          JSON.stringify(updatedRule),
          updatedRule
        )
      );
    }
  };

  const onDeleteTag = (id) => {
    deleteTagRegexes(id)
      .then(() => {
        dispatch(fetchTagRegexes("categorize"));
      })
      .catch((err) => null);
  };

  const onAddExistingCategoryToGroup = () => {
    if (existingTag && selectedTagRegexesSet) {
      const tag = tagRegexes.find((t) => t.id === existingTag);
      if (tag) {
        const keywords = extractKeywordsFromJsonRules(tag.jsonRules || "");
        dispatch(
          addNewTag(
            selectedTagRegexesSet.name,
            getTagName(existingTag),
            getTagLabel(existingTag).replace("(", "").replace(")", ""),
            tag.regexes,
            {
              keywords: keywords.positiveKeywords,
              negativeKeywords: keywords.negativeKeywords,
              tag: getTagName(existingTag),
            }
          )
        );
      }
    }
  };

  const filteredTags = tagRegexes.filter(
    (t) =>
      selectedTagIds.includes(t.id) &&
      (t.tag.toLowerCase().includes(filterText.toLowerCase()) ||
        t?.tagLabel?.toLowerCase().includes(filterText.toLowerCase()))
  );

  return (
    <div>
      <Row>
        <Col>
          Category Group
          <Select
            className="categorization-tour-select-group"
            data-cy="main-category-select"
            options={names.map((x) => ({
              label: hideStr(x),
              value: x,
            }))}
            value={
              selectedTagRegexesSet
                ? {
                    label: hideStr(selectedTagRegexesSet.name),
                    value: selectedTagRegexesSet.id as string,
                  }
                : null
            }
            onChange={(value) => {
              if (value) {
                dispatch(setSelectedTagRegexesSet(value.value));
                localStorage.setItem(lsKey, value.value);
              }
            }}
          />
        </Col>

        {!isDefault && (
          <Button
            className="mt-3"
            variant="link"
            onClick={() => {
              confirmAlert({
                customUI: getCustomUi({
                  onConfirm: () => {
                    if (selectedTagRegexesSet) {
                      deleteTagRegexesSet(
                        selectedTagRegexesSet.name,
                        selectedTagRegexesSet.type
                      )
                        .then((rsp) => {
                          dispatch(fetchTagRegexes("categorize"));
                          if (tagRegexesSets.length > 0) {
                            dispatch(
                              setSelectedTagRegexesSet(tagRegexesSets[0].name)
                            );
                          } else {
                            dispatch(setSelectedTagRegexesSet(""));
                          }
                        })
                        .catch((err) => null);
                    }
                  },
                  confirmCTA: "Delete",
                  cancelCTA: "Cancel",
                  getMessage: () => (
                    <div
                      className="div--confirm-job-delete"
                      style={{ marginBottom: "20px" }}
                    >
                      <span>{`Are you sure you want to delete this category group?`}</span>
                    </div>
                  ),
                }),
              });
            }}
          >
            <FontAwesomeIcon icon="trash" />
            <span className="ml-1">Delete</span>
          </Button>
        )}

        <Button
          variant="link"
          onClick={toggle}
          className="categorization-tour-edit-keywords mt-3"
        >
          <FontAwesomeIcon icon="cog" />
          <span className="ml-1" data-cy="edit-rules-link">
            Edit Categories/Keywords
          </span>
        </Button>

        <Modal size="xl" show={show} onHide={toggle}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Categories/Keywords</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="my-2">
              <AlertBanner />
            </div>
            <Row>
              <Col md="4">
                <Row>
                  <Col>
                    <Row>
                      <div className="ml-2" style={{ width: "300px" }}>
                        <Select
                          data-cy="existing-category-select"
                          options={existingCategoriesOptions}
                          value={
                            existingTag
                              ? {
                                  label: `${getTagName(
                                    existingTag
                                  )} ${getTagLabel(existingTag)}`,
                                  value: existingTag,
                                }
                              : null
                          }
                          onChange={(value) => {
                            if (value) {
                              setExistingTag(value.value);
                            }
                          }}
                        />
                      </div>
                      <Button
                        variant="link"
                        onClick={onAddExistingCategoryToGroup}
                      >
                        <FontAwesomeIcon icon="plus" />
                        <span className="ml-1">
                          {" "}
                          Add Existing Category to Group
                        </span>
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col md="4">
                <Row>
                  <Col>
                    <Row>
                      <InputGroup className="mb-3">
                        <FormControl
                          type="text"
                          placeholder="Enter New Category"
                          value={newTag}
                          onChange={(e) => setNewTag(e.target.value)}
                          onKeyPress={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              // console.log("Enter key pressed -> New Category: ", newTag);
                              handleAddCategory();
                            }
                          }}
                          autoFocus
                        />
                        <InputGroup.Append>
                          <Button variant="primary" onClick={handleAddCategory}>
                            Add New Category
                          </Button>
                        </InputGroup.Append>
                      </InputGroup>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="h3">Categories</div>
            <Row>
              <Col md="4">
                <InputGroup className="mb-3">
                  <FormControl
                    type="text"
                    placeholder="Filter Categories"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                  <InputGroup.Append>
                    <Button variant="primary">
                      <FontAwesomeIcon icon="search" />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Row>

            <TagCards
              tags={
                filteredTags
                  .reverse()
                  .sort((a, b) => a.tag.localeCompare(b.tag)) as unknown as {
                  id: string;
                  tag: string;
                  tagLabel?: string;
                  regexes: string;
                  jsonRules: TagCategoryRule;
                }[]
              }
              onDeleteTag={(id) => onDeleteTag(id)}
              onAddKeyword={(tagId, keyword, isPositive) =>
                onAddNewKeyword(tagId, keyword, isPositive)
              }
              onRemoveKeyword={onRemoveKeyword}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggle}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>
      <Row className="my-2 mx-1">
        <Col className="text-truncate" style={{ maxWidth: "800px" }}>
          <span className="font-weight-bold">Categories:</span>
          <span className="ml-2">{`${selectedTagIds
            .map((t) => getTagName(t))
            .join(",")}`}</span>
        </Col>
      </Row>
    </div>
  );
};
export default TagRegexes;

import uuidv4 from "uuid/v4";
import { Document, File } from "../../docuclipper/DocuclipperTypes";
import { CLEAR_ALL_PAGES } from "./DocumentEditor";

// import { setDocument, deleteAllFields } from "./TemplateField";
const initialState: {
  files: File[];
} = {
  files: [],
};

export const ADD_FILE = "add-file";
export const UPDATE_FILE = "update-file";
export const REMOVE_FILE = "remove-file";
export const CLEAR_FILES = "clear-files";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CLEAR_FILES:
      return {
        ...state,
        files: [],
      };
    case ADD_FILE:
      return {
        ...state,
        files: [...state.files, action.payload.file],
      };
    case REMOVE_FILE:
      return {
        ...state,
        files: state.files.filter(
          (f) => f.file.uuid !== action.payload.file.file.uuid
        ),
      };
    case UPDATE_FILE:
      const updatedFiles = state.files.filter(
        (f) => f.file.uuid === action.payload.file.file.uuid
      );
      let updatedFile = {};
      if (updatedFiles.length > 0) {
        updatedFile = updatedFiles[0];
      }

      return {
        ...state,
        files: [
          ...state.files.filter(
            (f) => f.file.uuid !== action.payload.file.file.uuid
          ),

          {
            ...updatedFile,
            ...action.payload.file,
          },
        ],
      };

    default:
      return { ...state };
  }
}

export type ToggleSplitFn = ({ file }: { file: File }) => any;
export const toggleSplit = ({ file }: { file: File }) => async (dispatch) => {
  dispatch({
    type: UPDATE_FILE,
    payload: {
      file: {
        ...file,
        split: !file.split,
      },
    },
  });
};

export type SetSplitFn = ({
  file,
  splitInPages,
  splitX,
  splitY,
}: {
  file: File;
  splitInPages: boolean;
  splitX: number | null;
  splitY: number | null;
}) => any;
export const setSplit = ({
  file,
  splitInPages,
  splitX,
  splitY,
}: {
  file: File;
  splitInPages: boolean;
  splitX: number | null;
  splitY: number | null;
}) => async (dispatch) => {
  dispatch({
    type: UPDATE_FILE,
    payload: {
      file: {
        ...file,
        split: splitInPages,
        splitX,
        splitY,
      },
    },
  });
};

export type SetFileFn = ({ file }: { file: File }) => any;
export const setFile = ({ file }: { file: File }) => async (dispatch) => {
  dispatch({
    type: CLEAR_FILES,
    payload: {},
  });
  dispatch({
    type: ADD_FILE,
    payload: {
      file,
      split: false,
    },
  });
};

export type ClearFilesFn = () => any;
export const clearFiles = () => async (dispatch) => {
  dispatch({
    type: CLEAR_FILES,
    payload: {},
  });
  dispatch({
    type: CLEAR_ALL_PAGES,
    payload: {},
  });
};

export type SetDocumentFn = ({ document }: { document: Document }) => any;
export const setDocument = ({ document }: { document: Document }) => async (
  dispatch
) => {
  dispatch({
    type: CLEAR_FILES,
    payload: {},
  });
  const uuid = uuidv4();
  const file: Partial<File> = {
    file: {
      uuid,
    } as any,
    uploading: false,
    status: "ok",
  };

  dispatch({
    type: ADD_FILE,
    payload: {
      file: {
        file,
      },
    },
  });

  dispatch({
    type: UPDATE_FILE,
    payload: {
      file: {
        file,
        document,
      },
    },
  });
};

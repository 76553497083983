import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ReduxState } from "../../../../../redux";
import { setDownloadOptionsSelectedFieldNames } from "../../../../../redux/reducers/TransactionManager";
import { CheckboxOption } from "./utils";

export const FieldNamesDropdown: React.FC
  = () => {
  const dispatch = useDispatch();

  const { tls, downloadOptions } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );
  const { fieldNames } = tls;

  const options = fieldNames.map((x) => ({ value: x, label: x }));

  const { selectedFieldNames } = downloadOptions;

  const selectedOptions = options.filter((option) =>
    selectedFieldNames.includes(option.value)
  );

  return (
    <Select
      options={options}
      value={selectedOptions}
      onChange={(value) => {
        dispatch(
          setDownloadOptionsSelectedFieldNames(
            ((value || []) as any).map((x) => x.value)
          )
        );
      }}
      isMulti={true}
      allowSelectAll={true}
      closeMenuOnSelect={false}
      components={{
        Option: CheckboxOption,
      }}
    />
  );
};
export default FieldNamesDropdown;

import React from "react";
import { getJwt } from "../../../auth";
import { goTo } from "../../../utils/utils";
import quickbooksConnectIcon from "../../../icons/C2QB_green_btn_med_default.svg";

const INTEGRATION_QUICKBOOKS_URL = `${process.env.NODE_ENV === "production" ? "" : "http://localhost:8080"}/api/v1/protected/auth/intuit`;

const INTEGRATIONS = {
  quickbooks: {
    scope: "intuit.quickbooks.accounting",
    url: INTEGRATION_QUICKBOOKS_URL,
  },
};

export const goToQuickbooks = (next: string) =>
  goTo(`${INTEGRATIONS.quickbooks.url}?token=${getJwt()}${next}`);

const navigate = () => {
  const next = `&next=${encodeURIComponent(`/manageIntegrations`)}`;
  goToQuickbooks(next);
};

const QuickBooksAddNewCompany = () => (
  <>
    <div className="m-4">
      <div className="h1">Add QuickBooks Company</div>
      <div
        className="btn--add-quickbooks"
        onClick={navigate}
        style={{ cursor: "pointer" }}
      >
        <img
          style={{ height: "36px" }}
          src={quickbooksConnectIcon}
          alt="QuickBooks logo"
        />
      </div>
    </div>
  </>
);
export default QuickBooksAddNewCompany;

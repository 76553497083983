import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "..";
import { getQboVendors, createQboVendor } from "../../docuclipper/api";
import { QboVendor } from "../../docuclipper/DocuclipperTypes";

const initialState: {
  qboVendors: QboVendor[];
  loading: boolean;
  error: string | null;
} = {
  qboVendors: [],
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "QboVendor",
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
      state.error = null;
    },
    setData(state, action: PayloadAction<QboVendor[]>) {
      state.loading = false;
      state.qboVendors = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    addQboVendor(state, action: PayloadAction<QboVendor>) {
      state.qboVendors.unshift(action.payload);
    },
  },
});

export const { load, setError, setData, addQboVendor } = slice.actions;

export const fetchQboVendors = (integrationId) => async (dispatch, getState) => {
  const state: ReduxState = getState();
  if (state.QboVendor.loading) {
    return;
  }
  dispatch(load());
  try {
    const job = state.JobData.job;
    if (job) {
      const QboVendors = await getQboVendors(integrationId);
      dispatch(
        setData(
          QboVendors.map((c) => ({
            name: c.DisplayName,
            value: c.Id,
          }))
        )
      );
    }
  } catch (err) {
    dispatch(setError((err as any).message || "Error getting QboVendors"));
  }
};

export const createAndAddQboVendor = (integrationId, vendorName) => async (dispatch, getState) => {
  try {
    const vendor = await createQboVendor(integrationId, vendorName);
    dispatch(addQboVendor({
      name: vendor.DisplayName,
      value: vendor.Id,
    }));
  } catch (err) {
    dispatch(setError((err as any).message || "Error creating QboVendor"));
  }
};

export default slice.reducer;

import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { ReduxState } from "../redux";
export function useSeat() {
  const { user } = useSelector((state: ReduxState) => state.User);
  const { subscription } = useSelector(
    (state: ReduxState) => state.Subscription
  );

  const isSeat =
    subscription &&
    subscription.seat &&
    subscription.admin &&
    !subscription.seat.stripePriceId;
  return { admin: subscription?.admin, seat: subscription?.seat, isSeat };
}

import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchOnboardingSteps } from "src/redux/reducers/OnboardingSteps";
export const OnboardingChecker: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchOnboardingSteps())
  }, []);
  return null;
};
export default OnboardingChecker;

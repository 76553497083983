import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateSegment } from "src/docuclipper/api";
import { ReduxState } from "..";

interface TagMetadata {
  [key: string]: string;
}

interface ProjectTag {
  metadata: TagMetadata;
}

interface CustomerTag {
  metadata: TagMetadata;
  tags: {
    [key: string]: ProjectTag;
  };
}

interface TagLevels {
  tags: {
    [key: string]: CustomerTag;
  };
}

const initialState: {
  tagLevels: TagLevels;
  selectedLevel1: string | null;
  selectedLevel2: string | null;
  selectedInvoiceId: string | null;
} = {
  tagLevels: {
    tags: {
      'customer1': {
        metadata: {
          k1: 'v1'
        },
        tags: {
          'project1a': {
            metadata: {
              k1a: 'v1a'
            }
          },
          'project1b': {
            metadata: {
              k1b: 'v1b'
            }
          }
        }
      }, 'customer2': {
        metadata: {
          k2: 'v2'
        },
        tags: {
          'project2a': {
            metadata: {
              k2a: 'v2a'
            }
          },
          'project2b': {
            metadata: {
              k2b: 'v2b'
            }
          }
        }
      }
    }
  },
  selectedLevel1: null,
  selectedLevel2: null,
  selectedInvoiceId: null
};

const slice = createSlice({
  name: "TagLevel",
  initialState,
  reducers: {
    // openSurvey(state, action: PayloadAction<string>) {
    //   state.survey = action.payload;
    //   state.open = true;
    // },
    // closeSurvey(state) {
    //   state.open = false;
    //   state.survey = "";
    // },
    setSelectedLevels(
      state,
      action: PayloadAction<{ level1: string | null; level2: string | null }>
    ) {
      state.selectedLevel1 = action.payload.level1;
      state.selectedLevel2 = action.payload.level2;
    },
    setSelectedInvoiceId(state, action: PayloadAction<string | null>) {
      state.selectedInvoiceId = action.payload;
    },
  },
});

export const { setSelectedLevels, setSelectedInvoiceId } = slice.actions;


export const fetchTagLevels = () => async (dispatch, getState) => {
  // const state: ReduxState = getState();

  // const { files } = getState().Files;



  // // console.log(cleanCsvDocumentId, files);
  // const { loading } = getState().Reconciler;
  // if (loading) {
  //   return;
  // }

  // if (!state.JobData.job) {
  //   return;
  // }
  // const jobId = state.JobData.job.id;

  // dispatch(load());

};

export default slice.reducer;

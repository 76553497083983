import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../../redux";
import { setSelectedDocumentId } from "../../../../redux/reducers/TransactionManager";
import SelectWithBadge from "./SelectWithBadge";
import { getDocumentName } from "../../Analyze/AnalyzeTransactionsTable/utils";
import { fetchJobDocuments } from "src/redux/reducers/JobDocument";

const DocumentSelector: React.FC = () => {
  const dispatch = useDispatch();
  const { selectedDocumentId, reconciled, badDates } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );
  const { data: jobDocs } = useSelector(
    (state: ReduxState) => state.JobDocument
  );

  useEffect(() => {
    if (jobDocs.length === 0) {
      dispatch(fetchJobDocuments());
    }
  }, [jobDocs, dispatch]); // Dependency array for the new useEffect

  const uniqueDocumentIds = Array.from(
    new Set(jobDocs.map((item) => item.documentId))
  );
  const documentOptions = uniqueDocumentIds.map((docId) => ({
    value: docId,
    label: getDocumentName(jobDocs, docId),
    reconciled: reconciled.documentIds[(docId as any) || ""],
    isBadDate: badDates.documentIds[(docId as any) || ""],
  }));

  const selectedDocument = documentOptions.filter(
    (x) => x.value === selectedDocumentId
  );

  useEffect(() => {
    if (!selectedDocumentId && documentOptions.length > 0) {
      dispatch(setSelectedDocumentId(documentOptions[0].value));
    }
  }, [selectedDocumentId, documentOptions, dispatch]);

  return (
    <div className="flex-column">
      <span className="font-weight-bold mr-1">
        Select Document ({uniqueDocumentIds.length} available) ▾
      </span>
      <div style={{ width: "95%" }}>
        <SelectWithBadge
          value={selectedDocument.length > 0 ? selectedDocument[0] : null}
          onChange={(e) => {
            dispatch(setSelectedDocumentId(e.value));
          }}
          options={documentOptions}
          placeholder="Select a document..."
        />
        {!selectedDocumentId && (
          <small>
            <div className="text-danger">Please select a document</div>
          </small>
        )}
      </div>
    </div>
  );
};

export default DocumentSelector;

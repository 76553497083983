import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "..";
import { createQboAccount, getQboAccounts } from "../../docuclipper/api";
import { QboAccountCategory } from "../../docuclipper/DocuclipperTypes";

const initialState: {
  qboAccountCategory: QboAccountCategory[];
  loading: boolean;
  error: string | null;
} = {
  qboAccountCategory: [],
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "QboAccountCategory",
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
      state.error = null;
    },
    setData(state, action: PayloadAction<QboAccountCategory[]>) {
      state.loading = false;
      state.qboAccountCategory = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    addQboAccountCategory(state, action: PayloadAction<QboAccountCategory>) {
      state.qboAccountCategory.unshift(action.payload);
    },
  },
});

export const { load, setError, setData, addQboAccountCategory } = slice.actions;

export const fetchQboAccountCategorys =
  (integrationId) => async (dispatch, getState) => {
    const state: ReduxState = getState();
    if (state.QboAccountCategory.loading) {
      return;
    }
    dispatch(load());
    try {
      const job = state.JobData.job;
      if (job) {
        const QboAccountCategorys = await getQboAccounts(integrationId, false);
        dispatch(
          setData(
            QboAccountCategorys.map((c) => ({
              name: c.Name,
              value: c.Id,
            }))
          )
        );
      }
    } catch (err) {
      dispatch(setError((err as any).message || "Error getting QboAccountCategorys"));
    }
  };


export const createAndAddQboAccountCategory = (integrationId, accountName) => async (dispatch, getState) => {
  try {
    const account = await createQboAccount(integrationId, accountName);
    dispatch(addQboAccountCategory({
      name: account.Name,
      value: account.Id,
    }));
  } catch (err) {
    dispatch(setError((err as any).message || "Error creating QboAccount"));
  }
};

export default slice.reducer;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, Collapse, Row } from "react-bootstrap";
type Props = {
  heading: () => any;
  body: () => any;
  initialExpanded?: boolean;
};

export const FaqItem: React.SFC<Props> = ({
  heading,
  body,
  initialExpanded,
}) => {
  //   const [expanded, setExpanded] = React.useState(false);
  const [expanded, setExpanded] = React.useState(initialExpanded);
  const toggle = () => setExpanded(!expanded);
  return (
    <Card className="rounded px-2">
      <Card.Header
        className="font-weight-bold"
        style={{ cursor: "pointer" }}
        onClick={toggle}
      >
        <Row className="justify-content-between">
          <div>{heading && heading()}</div>
          <FontAwesomeIcon icon={expanded ? "chevron-up" : "chevron-down"} />
        </Row>
      </Card.Header>

      <Collapse in={expanded}>
        <Card.Body>{body && body()}</Card.Body>
      </Collapse>
    </Card>
  );
};

FaqItem.defaultProps = {
  initialExpanded: false,
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Row,
  Modal,
  Form,
  FormGroup,
  Col,
  Card,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";
import {
  DocType,
  QboAccountCategory,
  QboItem,
  TagInvoiceRule,
} from "src/docuclipper/DocuclipperTypes";
import TagRegexesInvoiceViewOnly from "./TagRegexesInvoiceViewOnly";
import {
  addNewInvoiceGroup,
  deleteTagRegexesInvoiceAction,
  fetchTagRegexesInvoice,
  updateTagRegexesInvoiceAction,
} from "src/redux/reducers/TagRegexesInvoice";
import { ReduxState } from "src/redux";
import {
  applyRunInvoiceRules,
  setShowModalInvoiceRules,
} from "src/redux/reducers/InvoiceManager";
import { LoadingButton } from "src/views/Docuclipper/TrackedButton";
import ErrorMessage from "src/views/Docuclipper/ErrorMessage";

type Props = {
  categories?: QboItem[] | QboAccountCategory[];
  docType: DocType;
  integrationId?: number | null;
};

type Rule = {
  ifConditions: {
    field: string;
    operator: string;
    value: { keywords: string[] };
  }[];
  thenSet: { categoryAutomated: string; value: string }[];
  createdAt: string;
};

const defaultErrorMap = {
  keyword: false,
  category: false,
};

export const TagRegexesInvoice: React.FC<Props> = ({
  categories = [],
  docType,
  integrationId = null,
}) => {
  const dispatch = useDispatch();
  const [combinationOperator, setCombinationOperator] = useState("all");
  const [rules, setRules] = useState<Rule[]>([]);
  const [editRuleIndex, setEditRuleIndex] = useState<number | null>(null);
  const [addingNewRule, setAddingNewRule] = useState(false);
  const [errorMap, setErrorMap] = useState<{ [key: string]: boolean }>({
    ...defaultErrorMap,
  });

  const { loading, tagRegexesInvoice } = useSelector(
    (state: ReduxState) => state.TagRegexesInvoice
  );

  const { showModalInvoiceRules, runInvoiceRulesLoading } = useSelector(
    (state: ReduxState) => state.InvoiceManager
  );

  const categoryLabel = ["payables", "expense", "bill"].includes(docType)
    ? "Category"
    : "Product/Service";

  const keys = [categoryLabel];

  const availableFields = [
    { value: "description", label: "Line Item Description" },
    // Add vendor or customer field based on docType
    ...(["payables", "expense", "bill"].includes(docType)
      ? [{ value: "vendor", label: "Vendor" }]
      : [{ value: "customer", label: "Customer" }]),
  ];
  const toggle = () => {
    dispatch(setShowModalInvoiceRules(!showModalInvoiceRules));

    setEditRuleIndex(null);
    setErrorMap({ ...defaultErrorMap });
  };

  const groupName = `DocType: ${docType} | ${
    integrationId ? `Integration ${integrationId}` : "Invoice"
  }`;

  const categoryOptions = ["payables", "receivables"].includes(docType)
    ? []
    : categories.map((option) => ({ value: option.value, label: option.name }));

  const categoryKey = ["payables", "receivables"].includes(docType)
    ? "categoryExcelAutomated"
    : "categoryAutomated";
  const serviceKey = ["payables", "receivables"].includes(docType)
    ? "serviceExcelAutomated"
    : "serviceAutomated";

  const ruleToEdit = editRuleIndex !== null ? rules[editRuleIndex] : null;

  const selectedFields = ruleToEdit
    ? ruleToEdit.ifConditions.map((condition) => condition.field)
    : [];
  const remainingFields = availableFields.filter(
    (field) => !selectedFields.includes(field.value)
  );
  const selectedCategories = ruleToEdit
    ? ruleToEdit.thenSet.map((action) => action.categoryAutomated)
    : [];
  const remainingCategories = keys.filter(
    (key) => !selectedCategories.includes(key)
  );

  const hasError = Object.values(errorMap).some((value) => value);

  const handleRuleChange = (ruleIndex, index, field, value) => {
    const newRules = [...rules];
    if (field === "categoryAutomated") {
      newRules[ruleIndex].thenSet[index][field] = value;
    } else {
      newRules[ruleIndex].thenSet[index].value = value;
    }
    setRules(newRules);
  };

  const handleIfConditionChange = (ruleIndex, index, field, value) => {
    const newRules = [...rules];
    if (field === "operator") {
      newRules[ruleIndex].ifConditions[index][field] = value;
      newRules[ruleIndex].ifConditions[index].value = { keywords: [""] };
    } else if (field === "value") {
      if (typeof value === "string") {
        // For all fields, including vendor and customer, use the keywords array
        newRules[ruleIndex].ifConditions[index].value = {
          keywords: value.split(",").map((keyword) => keyword.trim()),
        };
      } else {
        // If it's already in the correct format, just assign it
        newRules[ruleIndex].ifConditions[index].value = value;
      }
    } else {
      newRules[ruleIndex].ifConditions[index][field] = value;
    }
    setRules(newRules);
  };

  const handleOnCreateRule = () => {
    const lastRule = rules[rules.length - 1];
    const firstCondition = lastRule.ifConditions[0];

    if (firstCondition.value.keywords[0] === "") {
      setErrorMap({ ...errorMap, keyword: true });
      return;
    }

    if (lastRule.thenSet[0].value === "") {
      setErrorMap({ ...errorMap, category: true });
      return;
    }

    setErrorMap({ ...errorMap, keyword: false, category: false });

    const newRule = lastRule;
    const tag: TagInvoiceRule = {
      tag: `${groupName} - Rule ${rules.length}`,
      jsonRules: JSON.stringify({
        conditions: {
          [combinationOperator]: newRule.ifConditions.map((condition) => ({
            fact: condition.field,
            operator: condition.operator,
            value: condition.value,
          })),
        },
        event: {
          type: "invoiceRules",
          params: {
            [categoryKey]:
              newRule.thenSet[0].categoryAutomated === "Category"
                ? newRule.thenSet[0].value
                : "",
            [serviceKey]:
              newRule.thenSet[0].categoryAutomated === "Product/Service"
                ? newRule.thenSet[0].value
                : "",
            docType,
          },
        },
      }),
    };
    dispatch(addNewInvoiceGroup(groupName, tag, integrationId, docType));
    setAddingNewRule(false);
    setEditRuleIndex(null);
  };

  const handleOnBlurUpdateRule = (ruleIndex) => {
    if (addingNewRule) return;

    const ruleToUpdate = rules[ruleIndex];
    const tagRegex = tagRegexesInvoice[ruleIndex];

    const jsonRule = {
      conditions: {
        [combinationOperator]: ruleToUpdate.ifConditions.map((condition) => ({
          fact: condition.field,
          operator: condition.operator,
          value: condition.value,
        })),
      },
      event: {
        type: "invoiceRules",
        params: {
          [categoryKey]:
            ruleToUpdate.thenSet[0].categoryAutomated === "Category"
              ? ruleToUpdate.thenSet[0].value
              : "",
          [serviceKey]:
            ruleToUpdate.thenSet[0].categoryAutomated === "Product/Service"
              ? ruleToUpdate.thenSet[0].value
              : "",
          docType,
        },
      },
    };

    dispatch(
      updateTagRegexesInvoiceAction(
        tagRegex.id,
        jsonRule,
        integrationId,
        docType
      )
    );
    // dispatch(applyRunInvoiceRules(integrationId, docType));
  };

  const handleClearAddRule = () => {
    setAddingNewRule(false);
    setEditRuleIndex(null);
    setErrorMap({ ...defaultErrorMap });
  };

  const handleOnRemoveRule = (ruleIndex) => {
    if (addingNewRule && ruleIndex === rules.length - 1) {
      setRules(rules.filter((_, index) => index !== ruleIndex));
      handleClearAddRule();
    } else {
      const ruleToRemove = tagRegexesInvoice[ruleIndex];
      if (ruleToRemove) {
        dispatch(
          deleteTagRegexesInvoiceAction(ruleToRemove.id, integrationId, docType)
        );
        setRules(rules.filter((_, index) => index !== ruleIndex));
        handleClearAddRule();
        // dispatch(applyRunInvoiceRules(integrationId, docType));
      }
    }
  };

  const handleAddRule = () => {
    const newRule = {
      ifConditions: [
        {
          field: "description",
          operator: "containsAny",
          value: { keywords: [""] },
        },
      ],
      thenSet: [{ categoryAutomated: categoryLabel, value: "" }],
      createdAt: new Date().toISOString(),
    };
    const newRules = [...rules, newRule];
    setRules(newRules);
    setEditRuleIndex(newRules.length - 1);
    setAddingNewRule(true);
  };

  const handleAddThenSet = (ruleIndex) => {
    const newRules = [...rules];
    newRules[ruleIndex].thenSet.push({ categoryAutomated: "", value: "" });
    setRules(newRules);
  };

  const handleAddIfCondition = (ruleIndex) => {
    const newRules = [...rules];
    newRules[ruleIndex].ifConditions.push({
      field: "description",
      operator: "containsAny",
      value: { keywords: [""] },
    });
    setRules(newRules);
  };

  const handleRemoveThenSet = (ruleIndex, index) => {
    if (rules[ruleIndex].thenSet.length > 1) {
      const newRules = [...rules];
      newRules[ruleIndex].thenSet.splice(index, 1);
      setRules(newRules);
    }
  };

  const handleRemoveIfCondition = (ruleIndex, index) => {
    if (rules[ruleIndex].ifConditions.length > 1) {
      const newRules = [...rules];
      newRules[ruleIndex].ifConditions.splice(index, 1);
      setRules(newRules);
    }
  };

  useEffect(() => {
    if (showModalInvoiceRules) {
      dispatch(fetchTagRegexesInvoice("invoiceRules", integrationId, docType));
    }
  }, [showModalInvoiceRules]);

  useEffect(() => {
    const existingRules = tagRegexesInvoice.map((tagRegex) => {
      const baseRule = {
        conditions: {
          all: [
            {
              fact: "description",
              operator: "containsAny",
              value: { keywords: [""] },
            },
          ],
        },
        event: {
          type: "invoiceRules",
          params: {
            [categoryKey]: "",
            [serviceKey]: "",
          },
        },
      };

      const parsedRules = (tagRegex?.jsonRules as any) || baseRule;

      const operator = parsedRules.conditions?.all ? "all" : "any";

      return {
        ifConditions: parsedRules.conditions[operator]?.map((condition) => ({
          field: condition.fact,
          operator: condition.operator,
          value: condition.value.keywords
            ? { keywords: condition.value.keywords }
            : condition.value,
        })),
        thenSet: [
          {
            categoryAutomated: ["payables", "expense", "bill"].includes(docType)
              ? "Category"
              : "Product/Service",
            value:
              parsedRules.event.params[
                ["payables", "expense", "bill"].includes(docType)
                  ? categoryKey
                  : serviceKey
              ],
          },
        ],
        createdAt: tagRegex.createdAt,
      };
    });

    setRules(existingRules);
  }, [tagRegexesInvoice, combinationOperator]);

  return (
    <Modal size="lg" show={showModalInvoiceRules} onHide={toggle}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Rules</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          height: "35em",
          overflowY: rules.length > 1 ? "scroll" : "hidden",
        }}
      >
        {loading && <ClipLoader />}
        {!loading && (
          <>
            <Row>
              {editRuleIndex === null ? (
                <Col>
                  <Button variant="link" onClick={handleAddRule}>
                    <FontAwesomeIcon icon="plus" />
                    <span className="ml-1">Add new rule</span>
                  </Button>
                </Col>
              ) : (
                <Col>
                  <Button
                    variant="link"
                    disabled={runInvoiceRulesLoading || hasError}
                    onClick={() => {
                      if (addingNewRule) {
                        handleOnRemoveRule(rules.length - 1);
                      } else {
                        setEditRuleIndex(null);
                      }
                    }}
                    className="float-right"
                  >
                    View all rules
                  </Button>
                </Col>
              )}
            </Row>
            {editRuleIndex === null ? (
              <>
                <TagRegexesInvoiceViewOnly
                  rules={rules}
                  categories={categories}
                  onEdit={setEditRuleIndex}
                  onRemove={handleOnRemoveRule}
                />
              </>
            ) : (
              <Row className="flex-column">
                {ruleToEdit && (
                  <Col style={{ maxHeight: "auto", marginBottom: "0" }}>
                    <Card className="mb-4">
                      <Card.Body>
                        <Row>
                          <FormGroup as={Col} className="d-flex">
                            <Form.Label>If</Form.Label>
                            <Select
                              options={[{ value: "all", label: "All" }]}
                              value={{
                                value: combinationOperator,
                                label:
                                  combinationOperator === "all" ? "All" : "Any",
                              }}
                              onChange={(option) =>
                                option && setCombinationOperator(option.value)
                              }
                              className="col-4"
                            />
                          </FormGroup>
                          <Col className="text-right">
                            <Button
                              variant="link"
                              onClick={() => handleOnRemoveRule(editRuleIndex)}
                            >
                              <FontAwesomeIcon icon="trash" />
                            </Button>
                          </Col>
                        </Row>
                        {ruleToEdit.ifConditions.map((condition, index) => (
                          <Form key={index}>
                            <Form.Row>
                              <FormGroup as={Col} md="4">
                                <Select
                                  options={availableFields}
                                  value={{
                                    value: condition.field,
                                    label:
                                      condition.field.charAt(0).toUpperCase() +
                                      condition.field.slice(1),
                                  }}
                                  onChange={(option) => {
                                    if (option) {
                                      handleIfConditionChange(
                                        editRuleIndex,
                                        index,
                                        "field",
                                        option.value
                                      );
                                      handleOnBlurUpdateRule(editRuleIndex);
                                    }
                                  }}
                                />
                              </FormGroup>
                              <FormGroup as={Col} md="3">
                                <Select
                                  options={[
                                    { value: "containsAny", label: "Contains" },
                                    {
                                      value: "doesNotContainAny",
                                      label: "Not Contains",
                                    },
                                    ...(condition.field === "vendor" ||
                                    condition.field === "customer"
                                      ? [
                                          { value: "equal", label: "Equals" },
                                          {
                                            value: "notEqual",
                                            label: "Not Equals",
                                          },
                                        ]
                                      : []),
                                  ]}
                                  value={{
                                    value: condition.operator,
                                    label:
                                      condition.operator === "containsAny"
                                        ? "Contains"
                                        : condition.operator ===
                                          "doesNotContainAny"
                                        ? "Not Contains"
                                        : condition.operator === "equal"
                                        ? "Equals"
                                        : "Not Equals",
                                  }}
                                  onChange={(option) => {
                                    if (option) {
                                      handleIfConditionChange(
                                        editRuleIndex,
                                        index,
                                        "operator",
                                        option.value
                                      );
                                      handleOnBlurUpdateRule(editRuleIndex);
                                    }
                                  }}
                                />
                              </FormGroup>
                              <FormGroup as={Col} md="4">
                                <Form.Control
                                  name="keyword"
                                  type="text"
                                  value={
                                    typeof condition.value === "object" &&
                                    "keywords" in condition.value
                                      ? condition.value.keywords.join(", ")
                                      : condition.value
                                  }
                                  onChange={(e) => {
                                    handleIfConditionChange(
                                      editRuleIndex,
                                      index,
                                      "value",
                                      e.target.value
                                    );
                                    setErrorMap({
                                      ...errorMap,
                                      keyword: !!!e.target.value,
                                    });
                                  }}
                                  onBlur={() => {
                                    handleOnBlurUpdateRule(editRuleIndex);
                                  }}
                                />
                                {errorMap.keyword && (
                                  <ErrorMessage>
                                    {condition.field === "vendor" ||
                                    condition.field === "customer"
                                      ? `${
                                          condition.field
                                            .charAt(0)
                                            .toUpperCase() +
                                          condition.field.slice(1)
                                        } is required`
                                      : "Keyword is required"}
                                  </ErrorMessage>
                                )}
                              </FormGroup>
                              <FormGroup as={Col} md="1">
                                {index > 0 && (
                                  <Button
                                    variant="link"
                                    onClick={() =>
                                      handleRemoveIfCondition(
                                        editRuleIndex,
                                        index
                                      )
                                    }
                                  >
                                    <FontAwesomeIcon icon="trash" />
                                  </Button>
                                )}
                              </FormGroup>
                            </Form.Row>
                          </Form>
                        ))}
                        <Row>
                          <Col md="12">
                            <Button
                              variant="link"
                              onClick={() =>
                                handleAddIfCondition(editRuleIndex)
                              }
                              disabled={remainingFields.length === 0}
                            >
                              <FontAwesomeIcon icon="plus" />
                              <span className="ml-1">
                                Add another condition
                              </span>
                            </Button>
                          </Col>
                          <Col md="12">
                            <Form.Label>Then set</Form.Label>
                          </Col>
                        </Row>
                        {ruleToEdit.thenSet.map((thenSet, index) => (
                          <Form key={index}>
                            <Form.Row className="align-items-center">
                              <FormGroup as={Col} md="5">
                                <Select
                                  options={keys.map((option) => ({
                                    value: option,
                                    label: option,
                                  }))}
                                  value={{
                                    value: thenSet.categoryAutomated,
                                    label:
                                      thenSet.categoryAutomated ||
                                      categoryLabel,
                                  }}
                                  onChange={(option) => {
                                    if (option) {
                                      handleRuleChange(
                                        editRuleIndex,
                                        index,
                                        "categoryAutomated",
                                        option.value
                                      );
                                      handleOnBlurUpdateRule(editRuleIndex);
                                    }
                                  }}
                                />
                              </FormGroup>
                              <FormGroup
                                as={Col}
                                md="1"
                                className="text-center"
                              >
                                <Form.Label>to</Form.Label>
                              </FormGroup>
                              <FormGroup as={Col} md="5">
                                {["payables", "receivables"].includes(
                                  docType
                                ) ? (
                                  <Form.Control
                                    type="text"
                                    value={thenSet.value}
                                    onChange={(e) => {
                                      handleRuleChange(
                                        editRuleIndex,
                                        index,
                                        "value",
                                        e.target.value
                                      );
                                      setErrorMap({
                                        ...errorMap,
                                        category: !!!e.target.value,
                                      });
                                    }}
                                    onBlur={() => {
                                      handleOnBlurUpdateRule(editRuleIndex);
                                    }}
                                  />
                                ) : (
                                  <Select
                                    options={categoryOptions}
                                    value={{
                                      value: thenSet.value,
                                      label:
                                        categories.find(
                                          (c) => c.value === thenSet.value
                                        )?.name || thenSet.value,
                                    }}
                                    onChange={(option) => {
                                      if (option) {
                                        handleRuleChange(
                                          editRuleIndex,
                                          index,
                                          "value",
                                          option.value
                                        );
                                        handleOnBlurUpdateRule(editRuleIndex);
                                        setErrorMap({
                                          ...errorMap,
                                          category: !!!option,
                                        });
                                      }
                                    }}
                                  />
                                )}
                                {errorMap.category && (
                                  <ErrorMessage>
                                    {categoryLabel} is required
                                  </ErrorMessage>
                                )}
                              </FormGroup>
                              <FormGroup as={Col} md="1">
                                {index > 0 && (
                                  <Button
                                    variant="link"
                                    onClick={() =>
                                      handleRemoveThenSet(editRuleIndex, index)
                                    }
                                  >
                                    <FontAwesomeIcon icon="trash" />
                                  </Button>
                                )}
                              </FormGroup>
                            </Form.Row>
                          </Form>
                        ))}
                        <Button
                          variant="link"
                          onClick={() => handleAddThenSet(editRuleIndex)}
                          className="mb-2 ml-2"
                          disabled={remainingCategories.length === 0}
                        >
                          <FontAwesomeIcon icon="plus" />
                          <span className="ml-1">Add another action</span>
                        </Button>
                      </Card.Body>
                      {addingNewRule && (
                        <Card.Footer>
                          <LoadingButton
                            isLoading={runInvoiceRulesLoading}
                            variant="primary"
                            onClick={handleOnCreateRule}
                            className="float-right"
                            disabled={hasError}
                          >
                            <FontAwesomeIcon icon="plus" />
                            <span className="ml-1">Add new rule</span>
                          </LoadingButton>
                        </Card.Footer>
                      )}
                    </Card>
                  </Col>
                )}
              </Row>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={toggle}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default TagRegexesInvoice;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { saveAs } from "file-saver";
import queryString from "query-string";
import React, { useRef, useState, useEffect } from "react";
import { Badge, Col, FormGroup, Row, Tab, Tabs } from "react-bootstrap";
import Media from "react-media";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  RouteComponentProps,
  withRouter,
  useLocation,
  useHistory,
} from "react-router-dom";
import {
  downloadSpreadsheet,
  exportJob,
  getJob,
} from "../../../docuclipper/api";
import {
  ExportType,
  Integration,
  Job,
} from "../../../docuclipper/DocuclipperTypes";
import { logErrorToGA } from "../../../docuclipper/Logger";
import { ReduxState } from "../../../redux";
import { createAlert, CreateAlertFn } from "../../../redux/reducers/Alerts";
import { fetchAnnouncement } from "../../../redux/reducers/Announcement";
import { setJobData } from "../../../redux/reducers/JobData";
import {
  setDownloadModalOpen,
  setExportType,
} from "../../../redux/reducers/JobExport";
import {
  closeModal,
  CloseModalFn,
  openModal,
  OpenModalFn,
} from "../../../redux/reducers/ModalManager";
import { enableTour, EnableTourFn } from "../../../redux/reducers/Tours";
import { useIsAdmin } from "../../../utils/auth";
import { breakpoints, useHasQbo } from "../../../utils/utils";
import PageHeader from "../../Docuclipper/PageHeader/PageHeader";
import HelpDrawer from "../../Docuclipper/Ratings/HelpDrawer";
import RatingsModal from "../../Docuclipper/Ratings/RatingsModal";
import { goToGdocs } from "../Integrations/IntegrationsTable";
import JobDataTable2 from "./JobDataTable2";
import { JobFailed } from "./JobFailed";
import { JobInProgress } from "./JobInProgress";
import JobPoller from "./JobPoller";
import JobRealTimeTracker from "./JobRealTimeTracker";
import { goToQuickbooks } from "./QuickBooksAddNewCompany";

import TransactionManager from "./TransactionManager/TransactionManager";
import JobHeader from "./TransactionManager/JobHeader";
import { JobAdminButtons, JobButtons } from "./TransactionManager/JobButtons";
import { JobOutOfPages } from "./JobOutOfPages";
import { setHelpDrawerOpen } from "../../../redux/reducers/HelpDrawer";
import WritePdfDownloadButton from "./TransactionManager/WritePdfDownloadButton";
import { AnalyzeTabGated, CategorizeTabGated } from "../Analyze";
import { useQueryStateSync } from "../../../custom-hooks/useQueryStateSync";
import EditForm from "./EditForm/EditForm";
import InvoicePage from "../Invoices/InvoicePage";
import DownloadAsAdminButton from "../Admin/DownloadAsAdminButton";
import { AdminDocumentDownloader } from "./TransactionManager/AdminDocumentDownloader";
import { FraudData } from "../FraudViewer";
import { fetchJobDocuments } from "src/redux/reducers/JobDocument";

const getHelp = (where: ExportType) => {
  switch (where) {
    case "qbo":
      return [
        <br />,
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.docuclipper.com/docs/ui/user-guide/extracting-data/import-data-quickbooks-online/"
        >
          <FontAwesomeIcon icon="question-circle" />
          <span className="ml-1">
            How to import QBO Web Connect files into QuickBooks Online
          </span>
          <FontAwesomeIcon className="ml-2" icon="external-link-alt" />
        </a>,
        <br />,
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.docuclipper.com/docs/ui/user-guide/extracting-data/import-data-quickbooks-desktop/"
        >
          <FontAwesomeIcon icon="question-circle" />
          <span className="ml-1">
            How to import QBO Web Connect files into QuickBooks Desktop
          </span>
          <FontAwesomeIcon className="ml-2" icon="external-link-alt" />
        </a>,
        <br />,
      ];

    case "iif":
      return [
        <br />,
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.docuclipper.com/docs/ui/user-guide/extracting-data/import-data-quickbooks-desktop-iif/"
        >
          <FontAwesomeIcon icon="question-circle" />
          <span className="ml-1">
            How to import IIF files into QuickBooks Desktop
          </span>
          <FontAwesomeIcon className="ml-2" icon="external-link-alt" />
        </a>,
      ];
    case "sage-csv":
      return [
        <br />,
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.docuclipper.com/docs/ui/user-guide/extracting-data/import-data-sage/"
        >
          <FontAwesomeIcon icon="question-circle" />
          <span className="ml-1">How to import CSV files into Sage</span>
          <FontAwesomeIcon className="ml-2" icon="external-link-alt" />
        </a>,
      ];
    case "xero-csv":
      return [
        <br />,
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.docuclipper.com/docs/ui/user-guide/extracting-data/import-data-xero/"
        >
          <FontAwesomeIcon icon="question-circle" />
          <span className="ml-1">How to import CSV files into Xero</span>
          <FontAwesomeIcon className="ml-2" icon="external-link-alt" />
        </a>,
      ];
    default:
      return [];
  }
};
/* tslint:disable:no-var-requires */
const qs = require("qs");

const MAX_WIDTH = "1024px";

type Props = {};

type ReduxProps = {
  job: Job;
  modalManager: ReduxState["ModalManager"];
  createAlert: CreateAlertFn;
  enableTour: EnableTourFn;
  closeModal: CloseModalFn;
  openModal: OpenModalFn;
};

export const JobDataViewer = () => {
  // console.log("JobDataViewer re-render");
  return (
    <>
      <JobPoller />
      {/* <JobWSUpdater />  */}
      <JobDataViewer2 />
      <AdminDocumentDownloader />
    </>
  );
};

const colWidths = ["3", "5", "4"] as any;

const JobDataViewer2: React.FC<Props> = (
  {
    // history,
    // job,
    // createAlert,
    // enableTour,
    // modalManager,
    // openModal,
    // closeModal,
  }
) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const job = useSelector((state: ReduxState) => state.JobData.job);
  const modalManager = useSelector((state: ReduxState) => state.ModalManager);
  const createAlert = (alert) => dispatch(createAlert(alert));
  const enableTour = (tour) => dispatch(enableTour(tour));
  const openModal = (modalType, modalProps) =>
    dispatch(openModal(modalType, modalProps));
  const closeModal = () => dispatch(closeModal());
  const exploreDataRef = useRef(null);
  const [exportLoading, setExportLoading] = React.useState<{
    googleDocs: boolean;
    quickbooks: boolean;
    csv: boolean;
    xlsx: boolean;
    qbo: boolean;
    iif: boolean;
    qif: boolean;
    "xero-csv": boolean;
    "sage-csv": boolean;
    netsuite: boolean;
    myob: boolean;
    relate: boolean;
  }>({
    googleDocs: false,
    quickbooks: false,
    csv: false,
    xlsx: false,
    qbo: false,
    iif: false,
    qif: false,
    "xero-csv": false,
    "sage-csv": false,
    netsuite: false,
    myob: false,
    relate: false,
  });
  const [jobLoading, setJobLoading] = React.useState(false);
  const [jobError, setJobError] = React.useState(false);
  const [integrationsLoading, setIntegrationsLoading] = React.useState(true);

  const [gdocsIntegrationId, setGdocsIntegrationId] = React.useState<
    string | null
  >(null);
  const [quickbooksIntegrationId, setQuickbooksIntegrationId] = React.useState<
    string | null
  >(null);
  const [integrations, setIntegrations] = React.useState<Integration[]>([]);

  const [noDataHelpTemplateType, setNoDataHelpTemplateType] = React.useState<
    "public" | "private"
  >("public");

  // const noDataHelpToggle = () => setNoDataHelpIsOpen(!noDataHelpIsOpen);

  // const [flattenTables, setFlattenTables] = React.useState(false);
  const [tablesInSeparateSheets] = React.useState(false);

  let query;
  if (location) {
    query = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });
  }

  React.useEffect(() => {
    dispatch(fetchJobDocuments());
  }, []);

  const [documentLoading, setDocumentLoading] = React.useState(false);

  const exportToApplication = (
    where:
      | "QuickBooks"
      | "GoogleDocs"
      | "csv"
      | "xlsx"
      | "qbo"
      | "iif"
      | "qif"
      | "xero-csv"
      | "sage-csv"
      | "myob"
      | "netsuite"
      | "relate",
    documentIds: string[],
    integrationId: number | null = null,
    jobId: number | null = null,
    extraValues: {} = {},
    cb: () => any = () => null
  ) => {
    let integrationId2;
    switch (where) {
      case "GoogleDocs":
        integrationId2 = integrationId || gdocsIntegrationId;
        break;
      case "QuickBooks":
        integrationId2 = integrationId || quickbooksIntegrationId;
        break;
    }
    if (
      job &&
      ![
        "csv",
        "xlsx",
        "qbo",
        "iif",
        "qif",
        "xero-csv",
        "sage-csv",
        "netsuite",
        "myob",
        "relate",
      ].includes(where) &&
      integrationId2 === null
    ) {
      const next = `&next=${encodeURIComponent(
        `/jobData?jobId=${jobId || job.id}&action=export`
      )}`;
      switch (where) {
        case "GoogleDocs":
          goToGdocs(next);
          break;
        case "QuickBooks":
          goToQuickbooks(next);
          break;
      }

      return Promise.resolve();
    }
    let item;
    let jobType;
    switch (where) {
      case "GoogleDocs":
        item = "Google Docs Spreadsheet";
        jobType = "FieldsToExcel1";
        setExportLoading({ ...exportLoading, googleDocs: true });

        break;
      case "QuickBooks":
        item = "QuickBooks invoice";
        jobType = "FieldsToQuickbooks1";
        setExportLoading({ ...exportLoading, quickbooks: true });
        break;
      case "csv":
      case "xlsx":
        item = "Excel Spreadsheet";
        jobType = "FieldsToExcel1";
        setExportLoading({ ...exportLoading, [where]: true });
        break;
      case "qbo":
        item = "QuickBooks (QBO Web Connect)";
        jobType = "FieldsToExcel1";
        // setExportLoading({ ...exportLoading, [where]: true });
        break;
      case "iif":
        item = "IIF";
        jobType = "FieldsToExcel1";
        // setExportLoading({ ...exportLoading, [where]: true });
        break;
      case "qif":
        item = "QIF";
        jobType = "FieldsToExcel1";
        setExportLoading({ ...exportLoading, [where]: true });
        break;
      case "xero-csv":
        item = "Xero";
        jobType = "FieldsToExcel1";
        setExportLoading({ ...exportLoading, [where]: true });
        break;
      case "sage-csv":
        item = "Sage";
        jobType = "FieldsToExcel1";
        setExportLoading({ ...exportLoading, [where]: true });
        break;
      case "netsuite":
        item = "NetSuite";
        jobType = "FieldsToExcel1";
        setExportLoading({ ...exportLoading, [where]: true });
        break;
      case "myob":
        item = "MYOB";
        jobType = "FieldsToExcel1";
        setExportLoading({ ...exportLoading, [where]: true });
        break;
      case "relate":
        item = "Relate";
        jobType = "FieldsToExcel1";
        setExportLoading({ ...exportLoading, [where]: true });
        break;
    }

    if (!job) {
      return;
    }
    switch (where) {
      case "GoogleDocs":
      case "QuickBooks":
        return exportJob(jobId || job.id, {
          jobType,
          integrationId: integrationId2 || where,
          flattenTables: !tablesInSeparateSheets,
          documentIds: [],
        })
          .then((rsp) => {
            try {
              switch (where) {
                case "GoogleDocs":
                case "QuickBooks":
                  const obj = JSON.parse(rsp.output);
                  createAlert({
                    type: "success",
                    message: [
                      <a
                        rel="noopener noreferrer"
                        key={"1"}
                        target="_blank"
                        href={`${obj.output}`}
                      >
                        {item}
                      </a>,
                      " created successfully.",
                    ],
                    timeout: 0,
                  });
                  break;
              }
            } catch (err) {
              logErrorToGA(err);
              createAlert({
                type: "error",
                message: (err as any).message || "Error exporting data.",
                timeout: 0,
              });
            }

            switch (where) {
              case "GoogleDocs":
                setExportLoading({ ...exportLoading, googleDocs: false });

                break;
              case "QuickBooks":
                setExportLoading({ ...exportLoading, quickbooks: false });
                break;
            }
            if (cb) {
              cb();
            }
          })
          .catch((err) => {
            switch (where) {
              case "GoogleDocs":
                setExportLoading({ ...exportLoading, googleDocs: false });
                break;
              case "QuickBooks":
                setExportLoading({ ...exportLoading, quickbooks: false });
            }
            logErrorToGA(err);
            createAlert({
              type: "error",
              message: err.message || "Error exporting data.",
              timeout: 0,
            });
            if (err.errorCode === 1055) {
              openModal("noData", {});
              setNoDataHelpTemplateType(
                err.data ? err.data.templateType || "public" : "public"
              );
            }
          });

      case "csv":
      case "xlsx":
      case "qbo":
      case "iif":
      case "qif":
      case "xero-csv":
      case "sage-csv":
      case "netsuite":
      case "myob":
      case "relate":
        return Promise.resolve();
      // return downloadSpreadsheet(
      //   jobId || job.id,
      //   "FieldsToExcel1",
      //   where,
      //   !tablesInSeparateSheets,
      //   documentIds,
      //   extraValues,
      //   []
      // )
      //   .then((data) => {
      //     dispatch(setExportType(where as any));
      //     dispatch(setDownloadModalOpen(false));
      //     if (
      //       localStorage.getItem("docuclipper.helpDrawer.dontShow") !== "true"
      //     ) {
      //       dispatch(setHelpDrawerOpen(true));
      //     }
      //     let extension: any =
      //       ["xero-csv", "sage-csv", "netsuite", "myob", "relate"].includes(
      //         where
      //       ) || where.startsWith("custom-")
      //         ? "csv"
      //         : where;
      //     if (
      //       ["qbo", "qif", "ofx", "qfx"].includes(where) &&
      //       data.type === "application/zip"
      //     ) {
      //       extension = `${where}.zip`;
      //     }
      //     saveAs(
      //       data,
      //       `${
      //         job.name || (template ? template.name : "file")
      //       }-docuclipper.${extension}`
      //     );
      //     createAlert({
      //       type: "success",
      //       message: [
      //         `Data exported correctly. Check your Downloads folder.`,
      //         ...getHelp(where),
      //       ],
      //       timeout: 0,
      //     });
      //     if (!["qbo", "iif"].includes(where)) {
      //       setExportLoading({ ...exportLoading, [where]: false });
      //     }

      //     if (cb) {
      //       cb();
      //     }
      //   })
      //   .catch((err) => {
      //     createAlert({
      //       type: "error",
      //       message: err.message || "Error exporting data.",
      //       timeout: 0,
      //     });
      //     if (!["qbo", "iif"].includes(where)) {
      //       setExportLoading({ ...exportLoading, [where]: false });
      //     }
      //     if (err.errorCode === 1055) {
      //       openModal("noData", {});
      //       setNoDataHelpTemplateType(
      //         err.data ? err.data.templateType || "public" : "public"
      //       );
      //     }
      //   });
      default:
        return Promise.resolve();
    }
  };

  React.useEffect(() => {
    if (job) {
      // passed by redux, no need to fetch anything
      return;
    }
    if (!job && query.jobId) {
      setJobLoading(true);
      getJob(query.jobId)
        .then((job) => {
          dispatch(setJobData({ job, progress: job.progress || null }));
          setJobLoading(false);
          if (
            query.action === "export" &&
            !query.error &&
            query.success &&
            query.integrationId
          ) {
            switch (query.integration) {
              case "googleSpreadsheets":
                setGdocsIntegrationId(query.integrationId);
                exportToApplication(
                  "GoogleDocs",
                  [],
                  query.integrationId,
                  job.id,
                  {},
                  // () => dispatch(fetchAnnouncement())
                  () => null
                );
                break;
              case "quickbooks":
                setQuickbooksIntegrationId(query.integrationId);
                exportToApplication(
                  "QuickBooks",
                  [],
                  query.integrationId,
                  job.id,
                  {},
                  // () => dispatch(fetchAnnouncement())
                  () => null
                );
                break;
            }
          }
        })
        .catch((err) => {
          setJobLoading(false);
          setJobError(true);
        });
    } else {
      history.push("/dataArchive");
    }
  }, []);

  // React.useEffect(() => {
  //   setIntegrationsLoading(true);
  //   getIntegrations()
  //     .then((integrations) => {
  //       setIntegrations(integrations);
  //       setIntegrationsLoading(false);
  //     })
  //     .catch((err) => {
  //       setIntegrationsLoading(false);
  //     });
  // }, []);

  const { queryParams, querySetters } = useQueryStateSync({
    mode: "reconcile",
    analyzeMode: "categorize",
  });

  const mode = queryParams.mode;

  const [activeTab, setActiveTab] = React.useState(
    mode === "fraud"
      ? "4"
      : mode === "analyze"
      ? "3"
      : mode === "categorize"
      ? "2"
      : "1"
  );

  React.useEffect(() => {
    const { tour } = queryString.parse(location.search);
    if (tour) {
      enableTour(tour as string);
    }
  }, []);

  React.useEffect(() => {
    dispatch(setExportType(null));
  }, []);

  const { isAdmin, isSuperLogin, isSupportRep } = useIsAdmin();
  const hasQbo = useHasQbo();

  if (!job) {
    // console.log("returning null");
    return null;
  }
  const { template, id } = job;

  const { tour } = queryString.parse(location.search);

  if (template) {
    switch (template.type) {
      case "Invoice":
        break;
      default:
        break;
    }
  }

  let extension = "";
  if (job.type === "WritePdf") {
    extension = ".zip";
  }

  // console.log({ job });

  return (
    <>
      <RatingsModal />
      <HelpDrawer />
      <Row>
        <Col xs="12" sm="12" md="12" lg="12" xl="12">
          <div className="p-4">
            {/* <PageHeader title="" description=""> */}
            <JobRealTimeTracker />

            <FormGroup>
              {["Failed"].includes(job.status) && <JobFailed job={job} />}
              {["OutOfCredits"].includes(job.status) && (
                <JobOutOfPages job={job} />
              )}

              {["InProgress"].includes(job.status) && (
                <div>
                  <Row>
                    <Col md={8}>
                      <JobInProgress job={job} />
                    </Col>
                    <Col md={4}>
                      <JobAdminButtons />
                    </Col>
                  </Row>
                </div>
              )}
              {["Succeeded"].includes(job.status) && (
                <>
                  {job.type === "Invoice" &&
                    ["Succeeded", "OutOfCredits"].includes(job.status) && (
                      <>
                        <Row>
                          <Col md={6}>
                            <JobHeader />
                          </Col>
                          <Col md={6}>
                            {/* <JobAdminButtons /> */}
                            <JobButtons
                              downloadButton={
                                () => null /* <InvoiceImportButton />*/
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <InvoicePage key={job?.id} />
                        </Row>
                      </>
                    )}
                  {job.type === "Form" &&
                    ["Succeeded", "OutOfCredits"].includes(job.status) && (
                      <>
                        <Row>
                          <Col md={6}>
                            <JobHeader />
                          </Col>
                          <Col md={6}>
                            <JobButtons downloadButton={() => null} />
                          </Col>
                        </Row>
                        <Row>
                          <EditForm />
                        </Row>
                      </>
                    )}
                  {["ExtractData", "Csv2Qbo"].includes(job.type || "") && (
                    <>
                      <Tabs
                        activeKey={activeTab}
                        id="admin-tabs"
                        onSelect={(activeTab) => {
                          querySetters.set_mode(
                            activeTab === "4"
                              ? "fraud"
                              : activeTab === "3"
                              ? "analyze"
                              : activeTab === "2"
                              ? "categorize"
                              : "reconcile"
                          );
                          if (activeTab === "4") {
                            querySetters.set_analyzeMode("fraud");
                          } else if (activeTab === "3") {
                            if (isAdmin) {
                              querySetters.set_analyzeMode("flow");
                            } else {
                              querySetters.set_analyzeMode("flow");
                            }
                          } else if (activeTab === "2") {
                            querySetters.set_analyzeMode("categorize");
                          } else if (activeTab === "1") {
                            querySetters.set_analyzeMode("reconcile");
                          }
                        }}
                      >
                        <Tab eventKey="1" title="Reconcile / Download">
                          {activeTab === "1" ? (
                            <TransactionManager hasQbo={hasQbo} />
                          ) : null}
                        </Tab>
                        {job.isBankMode && !job.isGeneric && (
                          <Tab
                            eventKey="2"
                            title={
                              <>
                                <span>Categorize</span>
                                {/* <Badge className="ml-2" variant="success">
                              New
                            </Badge> */}
                              </>
                            }
                          >
                            {activeTab === "2" ? (
                              <div className="mt-4">
                                <CategorizeTabGated />
                              </div>
                            ) : null}
                          </Tab>
                        )}
                        {job.isBankMode && !job.isGeneric && (
                          <Tab
                            eventKey="3"
                            title={
                              <>
                                <span>Analyze</span>
                                <Badge className="ml-2" variant="success">
                                  New
                                </Badge>
                              </>
                            }
                          >
                            {activeTab === "3" ? (
                              <div className="mt-4">
                                <AnalyzeTabGated />
                              </div>
                            ) : null}
                          </Tab>
                        )}
                        {job.isBankMode && !job.isGeneric && (
                          <Tab
                            eventKey="4"
                            title={
                              <>
                                <span>Fraud Detection</span>
                                <Badge className="ml-2" variant="success">
                                  New
                                </Badge>
                              </>
                            }
                          >
                            {activeTab === "4" ? (
                              <div className="mt-4">
                                <FraudData />
                              </div>
                            ) : null}
                          </Tab>
                        )}
                      </Tabs>
                    </>
                  )}
                  {(job.type === "WritePdf" || job.type === "EditDocument") &&
                    ["Succeeded", "OutOfCredits"].includes(job.status) && (
                      <>
                        <>
                          <Row>
                            <Col md={8}>
                              <JobHeader />
                            </Col>
                            <Col md={4}>
                              <JobButtons
                                downloadButton={() => (
                                  <WritePdfDownloadButton />
                                )}
                              />
                            </Col>
                          </Row>
                        </>
                      </>
                    )}
                </>
              )}
            </FormGroup>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default JobDataViewer;

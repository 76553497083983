import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ExternalFilter {
  externalFilters: {
    [key: string]: {
      filterType: string;
      type: string; // operator type like 'equals', 'lessThan', etc.
      filterValue?: string | number; // for single value filters
      // filterFrom?: string | number; // for range filters
      filterValueTo?: string | number; // for range filters
    };
  };
  externalFilterColumnDefs: any[];
  externalFilterSortBy: string;
  externalFilterSortDirection: string;
}

const initialState: ExternalFilter = {
  externalFilters: {},
  externalFilterColumnDefs: [],
  externalFilterSortBy: 'date',
  externalFilterSortDirection: 'asc',
};

const externalFilterSlice = createSlice({
  name: 'ExternalFilter',
  initialState,
  reducers: {
    setExternalFilters(state, action: PayloadAction<{ externalFilters: ExternalFilter['externalFilters'] }>) {
      state.externalFilters = action.payload.externalFilters;

    },
    setExternalFiltersColumnDefs(state, action: PayloadAction<{ externalFilterColumnDefs: ExternalFilter['externalFilterColumnDefs'] }>) {
      state.externalFilterColumnDefs = action.payload.externalFilterColumnDefs;
    },
    setExternalFiltersSortBy(state, action: PayloadAction<string>) {
      state.externalFilterSortBy = action.payload;
    },
    setExternalFiltersSortDirection(state, action: PayloadAction<string>) {
      state.externalFilterSortDirection = action.payload;
    },
    clearExternalFilters(state) {
      state = initialState;
    }
  },
});

export const {
  setExternalFilters,
  setExternalFiltersColumnDefs,
  setExternalFiltersSortBy,
  setExternalFiltersSortDirection,
  clearExternalFilters
} = externalFilterSlice.actions;

export default externalFilterSlice.reducer;

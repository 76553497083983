import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  categorizeJobTransactions,
  categorizeJobTransactionsAsync,
  getAllTags,
  getAsyncTask,
  getCategorizedTransactions,
  getTransactionCategories,
  previewAutomaticModeAsync,
} from "../../docuclipper/api";
import { ReduxState } from "..";

import { fetchTls, setTls } from "./TransactionManager";
import { setCategories } from "./Categories";
import { createAlert } from "./Alerts";
import { pollForCompletion } from "./AsyncUtils";

export function randomColor(i) {
  const colors = ["#7982B9", "#A5C1DC", "#E9F6FA"];
  return colors[i % colors.length];
}
const initialState: {
  loading: boolean;
  asyncJobId: string | null;
} = {
  loading: false,
  asyncJobId: null,
};

const slice = createSlice({
  name: "AutomaticModeAsync",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setAsyncJobId(state, action: PayloadAction<string>) {
      state.asyncJobId = action.payload;
    },
  },
});

export const { setAsyncJobId, setLoading } = slice.actions;

export const automaticModeAsync =
  (T, T2, T3, T4) => async (dispatch, getState) => {
    const state: ReduxState = getState();
    if (state.AutomaticModeAsync.loading) {
      return;
    }
    const { job } = state.JobData;
    if (!job) {
      return;
    }
    const jobId = job.id;

    try {
      dispatch(setLoading(true));
      const { asyncJobId } = await previewAutomaticModeAsync(
        jobId,

        T,
        T2,
        T3,
        T4
      );

      dispatch(setAsyncJobId(asyncJobId));

      const onSuccess = async () => {
        dispatch(setLoading(false));
        dispatch(fetchTls(false));
        dispatch(
          createAlert({
            message: `Data extraction completed successfully. Click on "Download Data" to get the file.`,
            timeout: 0,
            type: "success",
          })
        );
      };
      const onFail = async () => {
        dispatch(setLoading(false));
        dispatch(
          createAlert({
            message: "Error extracting data",
            timeout: 0,
            type: "error",
          })
        );
      };
      pollForCompletion(dispatch, asyncJobId, jobId, onSuccess, onFail);

      //   dispatch(setTls({ transactions, resetPages: false }));
      //   dispatch(
      //     setCategories(
      //       categories
      //         .sort((a, b) => a.name.localeCompare(b.name))
      //         .map((c, i) => ({
      //           ...c,
      //           color: randomColor(i),
      //         }))
      //     )
      //   );
    } catch (err) {
      dispatch(setLoading(false));
    }
  };

export default slice.reducer;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import excelIcon from "../../../../../icons/excel.png";
import myobIcon from "../../../../../icons/myob.jpeg";
import qbIcon from "../../../../../icons/qb-logo-horizontal-preferred.svg";
import quickenIcon from "../../../../../icons/quicken.png";
import netsuiteIcon from "../../../../../icons/netsuite.jpeg";

import relateIcon from "../../../../../icons/relate.png";
import sageIcon from "../../../../../icons/sage.png";
import xeroIcon from "../../../../../icons/xero.png";
import ofxIcon from "../../../../../icons/ofx.jpeg";

import { ReduxState } from "../../../../../redux";
import {
  setSelectedExportType,
  setSelectedCustomConfigCSV,
  lsKeySelectedExportType,
} from "../../../../../redux/reducers/DownloadOptions";
import { useLocalStorage } from "../../../../../utils/prefs";
import { IconOption, IconValue } from "./utils";
import ClipLoader from "react-spinners/ClipLoader";
import {
  fetchCustomFormats,
  deleteSelectedCustomFormat,
  setSelectedCustomFormat,
  createCustomFormatRedux,
} from "../../../../../redux/reducers/CustomFormat";
import { useHasQbo } from "../../../../../utils/utils";
import { ExportType } from "src/docuclipper/DocuclipperTypes";
import { toggleModal as toggleColumnConfigModal } from "../../../../../redux/reducers/ColumnConfig";

const invoiceOptions = [
  {
    value: "csv",
    label: "Excel (CSV)",
    icon: excelIcon,
  },
  {
    value: "xlsx",
    label: "Excel (XLSX)",
    icon: excelIcon,
  },
  {
    value: "qbo",
    label: "QuickBooks Online",
    icon: qbIcon,
  },
];

const allOptions = [
  {
    value: "csv",
    label: "Excel",
    icon: excelIcon,
  },
  {
    value: "qbo",
    label: "QBO Web Connect (QuickBooks Online and Desktop)",
    icon: qbIcon,
    iconHeight: "36px",
  },
  {
    value: "ofx",
    label: "OFX",
    icon: ofxIcon,
  },
  {
    value: "qfx",
    label: "QFX",
    icon: quickenIcon,
  },
  {
    value: "xero-csv",
    label: "Xero CSV",
    icon: xeroIcon,
  },
  {
    value: "sage-cloud-csv",
    label: "Sage Cloud CSV",
    icon: sageIcon,
  },
  {
    value: "sage-csv",
    label: "Sage CSV",
    icon: sageIcon,
  },
  {
    value: "myob",
    label: "MYOB CSV",
    icon: myobIcon,
  },
  {
    value: "relate",
    label: "Relate CSV",
    icon: relateIcon,
  },
  {
    value: "iif",
    label: "QuickBooks Desktop IIF",
    icon: qbIcon,
  },
  {
    value: "qif",
    label: "Quicken QIF",
    icon: quickenIcon,
  },
  {
    value: "netsuite",
    label: "NetSuite",
    icon: netsuiteIcon,
  },
];

const defaultSecondSelectOptions = [
  {
    value: "custom-basic",
    label: "Basic (date, description, amount)",
  },
  {
    value: "custom-all",
    label: "All (all columns)",
  },
];

const addNewCustomOption = {
  value: "add-new",
  label: "+ Create New Export Format",
};

export const ExportTypeDropdown: React.FC = () => {
  const dispatch = useDispatch();
  const { job } = useSelector((state: ReduxState) => state.JobData);
  const { customFormats, loading, selected } = useSelector(
    (state: ReduxState) => state.CustomFormat
  );
  const { selectedExportType, selectedCustomConfigCSV } = useSelector(
    (state: ReduxState) => state.DownloadOptions
  );

  const [selectedOptionLS, setSelectedOptionLS] = useLocalStorage(
    lsKeySelectedExportType,
    `{"value": "csv"}`
  );
  const [firstSelectValue, setFirstSelectValue] = useState({
    value: selectedOptionLS.value,
    label: "",
    icon: "",
  });
  const [secondSelectValue, setSecondSelectValue] = useState<{
    value: string;
    label: string;
  }>(selectedCustomConfigCSV);
  const isDeleting = useRef(false);

  const hasQbo = useHasQbo();

  const customOptions = customFormats
    .map((x) => ({
      value: `custom-${x.name}`,
      label: x.name.charAt(0).toUpperCase() + x.name.slice(1),
      onEdit: () => {
        dispatch(setSelectedCustomFormat(`${x.name}`));
        dispatch(setSelectedExportType(`custom-${x.name}` as any));
        dispatch(toggleColumnConfigModal(true));
      },
      onDelete: () => {
        if (
          window.confirm("Are you sure you want to delete this configuration?")
        ) {
          isDeleting.current = true;
          dispatch(setSelectedCustomFormat(`${x.name}`));
          dispatch(setSelectedExportType(`custom-${x.name}` as any));
          dispatch(deleteSelectedCustomFormat());

          if (secondSelectValue.value === `custom-${x.name}`) {
            const nextCustomOption = customFormats.filter((f) => {
              return (
                f.name !== x.name && f.name !== "all" && f.name !== "basic"
              );
            })?.[0];
            if (nextCustomOption) {
              onSelectedOption({
                value: `custom-${nextCustomOption.name}`,
                label:
                  nextCustomOption.name.charAt(0).toUpperCase() +
                  nextCustomOption.name.slice(1),
              });
            } else {
              onSelectedOption(defaultSecondSelectOptions[0]);
            }
          }
          setTimeout(() => {
            isDeleting.current = false;
          }, 0);
        }
      },
    }))
    .filter((x) => x.value !== "custom-all" && x.value !== "custom-basic");

  const secondSelectOptions = [
    ...defaultSecondSelectOptions,
    {
      value: "custom",
      label: "Custom",
      options: [addNewCustomOption].concat(customOptions),
    },
  ];

  const getOptions = () => {
    if (job?.type === "Invoice") {
      return invoiceOptions;
    } else if (job?.isGeneric || !hasQbo) {
      return allOptions.filter((o) => o.value === "xlsx");
    } else {
      return allOptions;
    }
  };

  const optionsByType = getOptions();

  const onSelectedOption = (option) => {
    setSecondSelectValue(option);
    setSelectedOptionLS({ value: option.value });
    const selectedValue = option.value.replace("custom-", "");
    dispatch(setSelectedCustomFormat(selectedValue));
    dispatch(setSelectedExportType(option.value as ExportType));
    dispatch(setSelectedCustomConfigCSV(option));
  };

  useEffect(() => {
    dispatch(
      fetchCustomFormats({
        init: false,
        type: "bankStatement",
      })
    );

    const defaultFirstSelectOption =
      optionsByType.find((option) => option?.value === selectedExportType) ||
      allOptions[0];

    setFirstSelectValue({
      value: defaultFirstSelectOption.value as ExportType,
      label: defaultFirstSelectOption.label,
      icon: defaultFirstSelectOption.icon,
    });

    setSelectedOptionLS({ value: defaultFirstSelectOption.value });

    if (defaultFirstSelectOption.value === "csv") {
      setSecondSelectValue(selectedCustomConfigCSV);
      setSelectedOptionLS({ value: selectedCustomConfigCSV.value });
      dispatch(
        setSelectedCustomFormat(
          selectedCustomConfigCSV.value.replace("custom-", "")
        )
      );
      dispatch(
        setSelectedExportType(selectedCustomConfigCSV.value as ExportType)
      );
      dispatch(setSelectedCustomConfigCSV(selectedCustomConfigCSV));
    } else {
      dispatch(setSelectedCustomFormat(defaultFirstSelectOption.value));
      dispatch(
        setSelectedExportType(defaultFirstSelectOption.value as ExportType)
      );
    }
  }, []);

  useEffect(() => {
    const defaultFirstSelectOption =
      optionsByType.find((option) => option?.value === selectedExportType) ||
      allOptions[0];

    setFirstSelectValue({
      value: defaultFirstSelectOption.value as ExportType,
      label: defaultFirstSelectOption.label,
      icon: defaultFirstSelectOption.icon,
    });
  }, [selectedExportType, optionsByType]);

  useEffect(() => {
    setSecondSelectValue(selectedCustomConfigCSV);
  }, [selectedCustomConfigCSV]);

  if (loading) {
    return <ClipLoader />;
  }

  return (
    <>
      <Select
        maxMenuHeight={600}
        className="select-export-type"
        options={optionsByType}
        value={firstSelectValue}
        onChange={(selectedOption) => {
          if (selectedOption) {
            setFirstSelectValue({
              value: selectedOption.value as ExportType,
              label: selectedOption.label,
              icon: selectedOption.icon,
            });

            if (selectedOption.value === "csv") {
              setSecondSelectValue(selectedCustomConfigCSV);
              setSelectedOptionLS({ value: selectedCustomConfigCSV.value });
              dispatch(
                setSelectedCustomFormat(
                  selectedCustomConfigCSV.value.replace("custom-", "")
                )
              );
              dispatch(
                setSelectedExportType(
                  selectedCustomConfigCSV.value as ExportType
                )
              );
              dispatch(setSelectedCustomConfigCSV(selectedCustomConfigCSV));
            } else {
              dispatch(setSelectedCustomFormat(selectedOption.value));
              dispatch(
                setSelectedExportType(selectedOption.value as ExportType)
              );
            }
          }
        }}
        components={{ Option: IconOption, SingleValue: IconValue }}
      />
      {job?.type !== "Invoice" &&
        job?.type !== "Form" &&
        typeof firstSelectValue.value === "string" &&
        (firstSelectValue.value === "csv" ||
          firstSelectValue.value?.startsWith("custom-")) && (
          <Select
            maxMenuHeight={600}
            className="select-export-type mt-2"
            options={secondSelectOptions}
            value={secondSelectValue}
            onChange={(selectedOption) => {
              if (selectedOption && !isDeleting.current) {
                if (selectedOption.value === "add-new") {
                  dispatch(toggleColumnConfigModal(true));
                  dispatch(
                    createCustomFormatRedux({
                      type: "bankStatement",
                    })
                  );
                  return;
                }
                onSelectedOption(selectedOption);
              }
            }}
            components={{ Option: IconOption, SingleValue: IconValue }}
          />
        )}
    </>
  );
};

export default ExportTypeDropdown;

// src/views/Pages/DataArchive/TransactionManager/useBankStatementPDF.ts
import { getJwt } from "src/auth";

const BASE_URL = process.env.NODE_ENV === "production" ? "https://www.docuclipper.com" : "http://localhost:8080";

const useBankStatementPDF = ({
  selectedDocumentId,
}) => {
  const pdfURL = selectedDocumentId
    ? `${BASE_URL}/api/v1/protected/document/${selectedDocumentId}/file.pdf?token=${getJwt()}#page=${1}&pagemode=none&toolbar=1`
    : "";

  return {
    pdfURL,
  };
};

export default useBankStatementPDF;
import { useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { ReduxState } from "../redux";
export function useJobInfo() {
  const { job } = useSelector((state: ReduxState) => state.JobData);

  const redoneAsGeneric = job?.isGeneric && !job.originalIsGeneric;
  const missingBank = job?.template?.name === "Bank";
  return { job, redoneAsGeneric, missingBank };
}

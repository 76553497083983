import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Overlay, Form, Card, Row } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { formikInput } from "../../../../utils/utils";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../../redux";

const PageForm = ({ lastPage, onSubmit }) => {
  const initialValues = {
    startPage: 1,
    endPage: lastPage + 1,
  };

  const handleSubmit = (values) => {
    // Handle form submission here
    const { startPage, endPage } = values;

    onSubmit({ startPage: startPage - 1, endPage: endPage - 1 });
  };
  const validationSchema = Yup.object().shape({
    startPage: Yup.number()
      .required("Start page is required.")
      .min(1, "Start page must be 1 or greater."),
    endPage: Yup.number()
      .required("End page is required.")
      .min(
        Yup.ref("startPage"),
        "End page must be greater than or equal to start page."
      )
      .max(
        lastPage + 1,
        `End page is greater than the last page (${lastPage + 1})`
      ),
  });
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, errors }) => {
        return (
          <Form>
            <Form.Group className="mx-1" controlId="startPage">
              {/* <Form.Label>Start Date</Form.Label> */}
              <Field
                type="number"
                name="startPage"
                placeholder="Start Page"
                component={formikInput}
              />
            </Form.Group>
            <Form.Group className="mx-1" controlId="endPage">
              {/* <Form.Label>Start Date</Form.Label> */}
              <Field
                type="number"
                name="endPage"
                placeholder="End Page"
                component={formikInput}
              />
            </Form.Group>

            <div>
              <Button className="mx-1" onClick={() => handleSubmit()}>
                <FontAwesomeIcon icon="hand-pointer" />
                <span className="ml-1">Select Pages</span>
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

type Props = {};

export const SelectPages = ({ buttonVariant, onSubmit }) => {
  const [show, setShow] = React.useState(false);
  const toggle = () => setShow(!show);
  const target = React.useRef(null);
  const {
    selectedPage,
    firstPage,
    lastPage,
    selectedRowIds: selectedRowIdsRedux,
  } = useSelector((state: ReduxState) => state.TransactionManager);
  return (
    <>
      <Button variant={buttonVariant} ref={target} onClick={toggle}>
        <FontAwesomeIcon icon="hand-pointer" />
        <span className="ml-1">Select Pages</span>
      </Button>
      <Overlay target={target.current} show={show} placement="right">
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div {...props}>
            <Card style={{ width: "400px" }}>
              <Card.Header>
                <Row className="justify-content-between">
                  <div className="h5 m-2">Select Pages</div>
                  <Button variant="link" onClick={() => toggle()}>
                    <FontAwesomeIcon icon="times" />
                  </Button>
                </Row>
              </Card.Header>
              <Card.Body>
                <PageForm
                  lastPage={lastPage}
                  onSubmit={(val) => {
                    onSubmit(val);
                    toggle();
                  }}
                />
              </Card.Body>
            </Card>
          </div>
        )}
      </Overlay>
    </>
  );
};

export default SelectPages;

import { DocuClipperPlan } from "../../docuclipper/DocuclipperTypes";

const initialState: {
  isOpen: boolean;
  neededPlan: DocuClipperPlan;
  feature: string;
} = {
  isOpen: false,
  neededPlan: "Business",
  feature: ""
};

export const OPEN_UPGRADE_PLAN_MODAL = "open-upgrade-plan-modal";
export const CLOSE_UPGRADE_PLAN_MODAL = "close-upgrade-plan-modal";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_UPGRADE_PLAN_MODAL:
      return {
        ...state,

        ...action.payload,
        isOpen: true
      };
    case CLOSE_UPGRADE_PLAN_MODAL:
      return {
        ...state,
        isOpen: false
      };

    default:
      return state;
  }
}

export type OpenUpgradePlanModalFn = (
  neededPlan: DocuClipperPlan,
  feature: string
) => any;
export const openUpgradePlanModal = (
  neededPlan: DocuClipperPlan,
  feature: string
) => {
  return {
    type: OPEN_UPGRADE_PLAN_MODAL,
    payload: {
      neededPlan,
      feature
    }
  };
};

export type CloaseUpgradePlanModalFn = () => any;
export const closeUpgradePlanModal = () => ({
  type: CLOSE_UPGRADE_PLAN_MODAL,
  payload: {}
});

import React from "react";
import { Row } from "react-bootstrap";
import { components } from "react-select";
import { isNullOrUndefined } from "util";
import { downloadSpreadsheet } from "../../../../../docuclipper/api";
import {
  ExportType,
  Job,
  TableFilterConfig,
} from "../../../../../docuclipper/DocuclipperTypes";
import store from "../../../../../redux";
import { createAlert } from "../../../../../redux/reducers/Alerts";
import { fetchAnnouncement } from "../../../../../redux/reducers/Announcement";
import { setHelpDrawerOpen } from "../../../../../redux/reducers/HelpDrawer";
import {
  setDownloadModalOpen,
  setExportType,
} from "../../../../../redux/reducers/JobExport";
import { openModal } from "../../../../../redux/reducers/ModalManager";
import {
  ReconciledNo,
  ReconciledUnknown,
  ReconciledYes,
} from "../../ReconciledBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function downloadSpreadsheetHelper(
  job: Job | null,
  jobType: Job["jobType"],
  format: ExportType,
  params: { [k: string]: any },
  selectedDocumentIds: string[],
  selectedFieldNames: string[],
  filteredTlIds: string[],
  filters: TableFilterConfig[],
  sortBy: string,
  sortDirection: string,
  fileType?: "CSV" | "XLSX",
  preview?: boolean,
  selectedExportFields?: any
) {
  if (!job || !format) {
    return null as any;
  }
  let extension = format;
  if (
    [
      "xero-csv",
      "sage-csv",
      "sage-cloud-csv",
      "myob",
      "relate",
      "netsuite",
    ].includes(format)
  ) {
    extension = "csv";
  }
  if (["qif"].includes(format)) {
    extension = "qif";
  } else if (["ofx"].includes(format)) {
    extension = "ofx";
  } else if (["xlsx"].includes(format)) {
    extension = "xlsx";
  } else if (fileType?.toLowerCase() === "csv") {
    extension = "csv";
  } else if (fileType?.toLowerCase() === "xlsx") {
    extension = "xlsx";
  }
  return downloadSpreadsheet(
    job.id,
    jobType,
    format,
    true,
    selectedDocumentIds || [],
    params,
    selectedFieldNames,
    filteredTlIds,
    filters,
    sortBy,
    sortDirection,
    fileType,
    preview,
    selectedExportFields
  )
    .then((data) => {
      if (preview) return data;

      // store.dispatch(setExportType(format as any));
      store.dispatch(setDownloadModalOpen(false));
      // if (localStorage.getItem("docuclipper.helpDrawer.dontShow") !== "true") {
      //   store.dispatch(setHelpDrawerOpen(true));
      // }

      if (data.type === "application/zip") {
        extension += ".zip";
      }
      saveAs(
        data,
        `${
          job.name || (job.template ? job.template.name || "" : "")
        }-docuclipper.${extension}`
      );
      store.dispatch(
        createAlert({
          message: "Data exported correctly. Check your Downloads folder.",
          timeout: 0,
          type: "success",
        })
      );
      // store.dispatch(fetchAnnouncement());
    })
    .catch((err) => {
      store.dispatch(
        createAlert({
          message: err.message || "Error downloading file",
          timeout: 0,
          type: "error",
        })
      );
      if (!preview) {
        // don't close modal when preview fails
        store.dispatch(setDownloadModalOpen(false));
      }
      // if (localStorage.getItem("docuclipper.helpDrawer.dontShow") !== "true") {
      //   store.dispatch(setHelpDrawerOpen(true));
      // }

      // if (err.errorCode === 1055) {
      //   store.dispatch(openModal("noData", {}));
      //   // setNoDataHelpTemplateType(
      //   //   err.data ? err.data.templateType || "public" : "public"
      //   // );
      // }
    });
}

const { Option } = components;

export const CheckboxOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label className="ml-1">{props.label}</label>
      </components.Option>
    </div>
  );
};

export const CheckboxReconciledOption = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
        {props.data.totals && (
          <>
            <div className="my-1 text-success">
              <span>{`${props.data.totals.numCredits} credits`}</span>

              {` (${props.data.totals.totalCredits})`}
            </div>
            <div className="my-1 text-danger">
              <span>{`${props.data.totals.numPayments} debits`}</span>

              {` (${props.data.totals.totalPayments})`}
            </div>
          </>
        )}
        <div className="m-2">
          {props.data.totals && props.data.totals.isReconciled === true && (
            <ReconciledYes
              type={props.data.reconciledType}
              disableOverlay={true}
            />
          )}
          {props.data.totals && props.data.totals.isReconciled === false && (
            <ReconciledNo
              type={props.data.reconciledType}
              disableOverlay={true}
            />
          )}
          {(isNullOrUndefined(props.data.totals) ||
            isNullOrUndefined(props.data.totals.isReconciled)) && (
            <ReconciledUnknown
              type={props.data.reconciledType}
              disableOverlay={true}
            />
          )}
        </div>
        {/* </Row> */}
      </components.Option>
    </div>
  );
};

export const ReconciledMultiValueLabel = (props) => {
  return (
    <div>
      <components.MultiValueLabel {...props}>
        <span className="">
          <strong>{props.data.label}</strong>
        </span>
        {props.data.totals && (
          <>
            <span className="mx-2 text-success">
              <span>{`${props.data.totals.numCredits} credits`}</span>

              {` (${props.data.totals.totalCredits})`}
            </span>
            <span className="mx-2 text-danger">
              <span>{`${props.data.totals.numPayments} debits`}</span>

              {` (${props.data.totals.totalPayments})`}
            </span>
          </>
        )}
        <span className="mx-2">
          {props.data.totals && props.data.totals.isReconciled === true && (
            <ReconciledYes
              type={props.data.reconciledType}
              disableOverlay={true}
            />
          )}
          {props.data.totals && props.data.totals.isReconciled === false && (
            <ReconciledNo
              type={props.data.reconciledType}
              disableOverlay={true}
            />
          )}
          {(isNullOrUndefined(props.data.totals) ||
            isNullOrUndefined(props.data.totals.isReconciled)) && (
            <ReconciledUnknown
              type={props.data.reconciledType}
              disableOverlay={true}
            />
          )}
        </span>
        {/* </Row> */}
      </components.MultiValueLabel>
    </div>
  );
};

export const IconOption = (props) => (
  <Option {...props}>
    {props.data.icon && (
      <img
        src={props.data.icon}
        style={{ height: props.data.iconHeight || "24px" }}
        alt={props.data.label}
      />
    )}
    <span className={props.data.icon && "ml-2"}>{props.data.label}</span>
    {props.data?.onEdit && (
      <FontAwesomeIcon
        onClick={props.data.onEdit}
        className="ml-2 icon-hover"
        icon="edit"
        style={{ color: "grey", cursor: "pointer" }}
        onMouseOver={(e) => (e.currentTarget.style.color = "blue")}
        onMouseOut={(e) => (e.currentTarget.style.color = "grey")}
      />
    )}
    {props.data?.onDelete && (
      <FontAwesomeIcon
        onClick={props.data.onDelete}
        className="ml-2 icon-hover"
        icon="trash"
        style={{ color: "grey", cursor: "pointer" }}
        onMouseOver={(e) => (e.currentTarget.style.color = "red")}
        onMouseOut={(e) => (e.currentTarget.style.color = "grey")}
      />
    )}
  </Option>
);

export const ReconciledOption = (props) => (
  <Option {...props}>
    <Row className="justify-content-between px-2">
      <span className="ml-2">{props.data.label}</span>
      {props.data.reconciled === true && (
        <ReconciledYes type={props.data.reconciledType} disableOverlay={true} />
      )}
      {props.data.reconciled === false && (
        <ReconciledNo type={props.data.reconciledType} disableOverlay={true} />
      )}
      {isNullOrUndefined(props.data.reconciled) && (
        <ReconciledUnknown
          type={props.data.reconciledType}
          disableOverlay={true}
        />
      )}
    </Row>
  </Option>
);

export const ReconciledValue = ({
  cx,
  getStyles,
  selectProps,
  data,
  isDisabled,
}) => {
  return (
    <Row className="justify-content-between px-2">
      <span style={{ maxWidth: "70%" }} className="mx-2 text-truncate">
        {selectProps.getOptionLabel(data)}
      </span>
      {data.reconciled === true && (
        <ReconciledYes type={data.reconciledType} disableOverlay={true} />
      )}
      {data.reconciled === false && (
        <ReconciledNo type={data.reconciledType} disableOverlay={true} />
      )}
      {isNullOrUndefined(data.reconciled) && (
        <ReconciledUnknown type={data.reconciledType} disableOverlay={true} />
      )}
    </Row>
  );
};

export const IconValue = ({ cx, getStyles, selectProps, data, isDisabled }) => {
  return (
    <div>
      {data.icon && (
        <img
          src={data.icon}
          style={{ height: data.iconHeight || "24px" }}
          alt={data.label}
        />
      )}
      <span className="ml-2">{selectProps.getOptionLabel(data)}</span>
    </div>
  );
};

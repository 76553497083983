import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Alert, Button, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../../../redux";
import PopoverHelp from "../../../../Docuclipper/PopoverHelp";
import CsvOptions from "./CsvOptions";
import DocumentsDropdown from "./DocumentsDropdown";
import ExportTypeDropdown from "./ExportTypeDropdown";
import FieldNamesDropdown from "./FieldNamesDropdown";
import GenericOptions from "./GenericOptions";
import IifOptions from "./IifOptions";
import Instructions from "./Instructions";
import QboOptions from "./QboOptions";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import { formikInput } from "../../../../../utils/utils";
import { downloadSpreadsheetHelper } from "./utils";
import { automaticModeAsync } from "../../../../../redux/reducers/AutomaticModeAsync";
import ClipLoader from "react-spinners/ClipLoader";

// Validation schema
const validationSchema = Yup.object({
  action: Yup.string(),
});

const PreviewForm = () => {
  const dispatch = useDispatch();
  const { job } = useSelector((state: ReduxState) => state.JobData);
  const { tls } = useSelector((state: ReduxState) => state.TransactionManager);
  const { loading: asyncLoading } = useSelector(
    (state: ReduxState) => state.AutomaticModeAsync
  );

  const { documents } = tls;

  const [loading, setLoading] = React.useState(false);

  return (
    <div>
      <Formik
        initialValues={{ action: "" }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          //
          // setTimeout(() => {
          //   alert(JSON.stringify(values, null, 2));
          //   setSubmitting(false); // Reset submitting state
          // }, 400);

          const T = 15;
          const T2 = 50;
          const T3 = 10;
          const T4 = 50;

          setLoading(true);
          if (values.action === "preview") {
            dispatch(automaticModeAsync(T, T2, T3, T4));
            setLoading(false);
            setSubmitting(false);
          } else if (values.action === "download") {
            downloadSpreadsheetHelper(
              job,
              "FieldsToExcel1",
              "xlsx",
              {
                T,
                T2,
                T3,
                T4,
                separateFilesForAccounts: false,
                fileType: "csv",
                splitMultilineDescription: false,
              },
              documents.map((x) => x.id) as any[],
              [],
              [],
              [],
              "date",
              "asc",
              "CSV"
            )
              .then(() => {
                setLoading(false);
                setSubmitting(false);
              })
              .catch((err) => {
                setLoading(false);
                setSubmitting(false);
              });
          }
        }}
      >
        {({
          isSubmitting,
          errors,
          touched,
          values,
          setFieldValue,
          submitForm,
        }) => (
          <Form>
            {(loading || asyncLoading) && <ClipLoader />}
            {!(loading || asyncLoading) && (
              <Row className="align-items-center">
                <Col xs="auto">
                  <div>
                    <Button
                      className="ml-1 mr-4"
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                      name="action"
                      value="download"
                      onClick={() => {
                        setFieldValue("action", "download");
                        submitForm();
                      }}
                    >
                      <FontAwesomeIcon icon="download" />
                      <span className="ml-1">Download Data</span>
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

type Props = {};
export const DownloadTemplate: React.SFC<Props> = ({}) => {
  const { options, selectedExportType } = useSelector(
    (state: ReduxState) => state.DownloadOptions
  );
  const { job } = useSelector((state: ReduxState) => state.JobData);

  const [loading, setLoading] = React.useState(false);

  return (
    <Row className="align-items-center">
      <PreviewForm />
    </Row>
  );
};
export default DownloadTemplate;

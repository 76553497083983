import React, { useState, useEffect, useRef } from "react";
import Fuse from "fuse.js";
import Select from "react-select";
import { FieldProps } from "formik";

import MenuList from "./MenuList";
import debounce from "debounce-promise";
import { getLastUsedBids } from "../../../../../../docuclipper/api";

const wait = 50;

export const QboSearchField: React.FC<FieldProps<any>> = ({ field, form, meta }) => (
  <>
    <QboSearch field={field} form={form} meta={meta} />
    {form.touched[field.name] && form.errors[field.name] && (
      <div className="text-danger">{form.errors[field.name]}</div>
    )}
  </>
);

export const QboSearch: React.FC<FieldProps<any>> = ({ field, form, meta }) => {
  const [data, setData] = useState<any[]>([]);
  const [fuse, setFuse] = useState<Fuse<any>>(new Fuse([], { keys: ["name"] }));
  const [recentBids, setRecentBids] = useState<any[]>([]);
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const bidsData = await getLastUsedBids();
        if (isMounted.current) {
          setRecentBids(bidsData.map(bid => ({ bid: bid.bid, name: bid.bankName })));
        }
      } catch (error) {
        console.error("Error fetching recent bids:", error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function loadQboData() {
      const response = await import("./qbo.json");
      const newData = response.default.map(d => ({
        ...d,
        label: d.name,
        value: d.bid
      }));

      const newDataWithRecentBids = recentBids
      .map(({ bid, name }) => ({
        bid: bid as any,
        name,
        value: bid,
        label: name,
      }))
      .concat(newData)

      if (isMounted.current) {
        setData(newDataWithRecentBids);
        setFuse(new Fuse(newDataWithRecentBids, { keys: ["name"] }));
      }
    }
    loadQboData();
  }, [recentBids]);

  const searchOptions = (inputValue: string) => new Promise(resolve => {
    if (!inputValue) {
      resolve(data);
    } else {
      resolve(fuse.search(inputValue).map(c => c.item));
    }
  });

  const debouncedLoadOptions = debounce(searchOptions, wait);

  return (
    <>
      {data.length > 0 && (
        <Select
          placeholder="Search banks ..."
          name={field.name}
          components={{ MenuList }}
          options={data}
          isSearchable={true}
          defaultOptions={true}
          onChange={(option) => form.setFieldValue(field.name, option)}
          onBlur={field.onBlur}
          loadOptions={debouncedLoadOptions}
        />
      )}
    </>
  );
};

export default QboSearchField;

import queryString from "query-string";
import React from "react";
import {
  RouteComponentProps,
  useHistory,
  useLocation,
  withRouter,
} from "react-router-dom";
import { FilteredItem, SortedItem } from "../docuclipper/DocuclipperTypes";
import { loadPreference, savePreference } from "../utils/prefs";

export function useTablePreferences(
  tableId: string,
  defaultPageSize: number,
  defaultSorted: SortedItem[],
  defaultFiltered: FilteredItem[],
  defaultPage: number,
  syncUrl: boolean = false
) {
  const history = useHistory();
  const location = useLocation();

  const defaults = {
    pageSize: 20,
    sorted: [],
    filtered: [],
    page: 0,
  };
  const defaultURLState = {
    pageSize: "",
    sorted: "",
    filtered: "",
    page: "",
  };
  let urlState = React.useRef({
    ...defaultURLState,
    ...queryString.parse(location.search),
  });

  const setURLState = (newState) => {
    if (!syncUrl) {
      return;
    }
    const search = `?${queryString.stringify({
      ...urlState.current,
      ...newState,
    })}`;
    history.push({
      search,
    });
  };

  const initialPageSize = parseInt(urlState.current.pageSize.toString(), 10);
  const [pageSize, setPageSize] = React.useState(
    isNaN(initialPageSize) ? defaultPageSize : initialPageSize
  );
  const [sorted, setSorted] = React.useState(defaultSorted);
  const [filtered, setFiltered] = React.useState(defaultFiltered);

  const [page, setPage] = React.useState(defaultPage);

  let pageSizePreferenceKey = React.useRef(`table.pageSize.${tableId}`);
  let pagePreferenceKey = React.useRef(`table.page.${tableId}`);

  let sortedPreferenceKey = React.useRef(`table.sorted.${tableId}`);
  let filteredPreferenceKey = React.useRef(`table.filtered.${tableId}`);

  React.useEffect(() => {
    pageSizePreferenceKey.current = `table.pageSize.${tableId}`;
    sortedPreferenceKey.current = `table.sorted.${tableId}`;
    filteredPreferenceKey.current = `table.filtered.${tableId}`;
    Promise.all([
      urlState.current.pageSize
        ? Promise.resolve(urlState.current.pageSize)
        : loadPreference(
            pageSizePreferenceKey.current,
            defaults.pageSize.toString()
          ),
      urlState.current.sorted
        ? Promise.resolve(urlState.current.sorted)
        : loadPreference(sortedPreferenceKey.current, defaults.sorted),
      urlState.current.filtered
        ? Promise.resolve(urlState.current.filtered)
        : loadPreference(filteredPreferenceKey.current, defaults.filtered),
      urlState.current.page ? Promise.resolve(urlState.current.page) : "0", //loadPreference(pagePreferenceKey.current, defaults.page),
    ])
      .then((value) => {
        const [pageSizeValue, sortedValue, filteredValue, page] = value;
        const newPageSize = value
          ? parseInt(pageSizeValue, 10)
          : defaultPageSize;
        const newPage = value ? parseInt(page, 10) : defaultPage;
        setPageSize(newPageSize);
        setPage(newPage);
        setSorted(JSON.parse(sortedValue));
        setFiltered(JSON.parse(filteredValue));
      })
      .catch((err) => null);
  }, [tableId]);

  const onPageSizeChange = (value) => {
    setPageSize(value);
    savePreference(pageSizePreferenceKey.current, value.toString());
    setURLState({ pageSize: value.toString() });
  };
  const onFilteredChange = (value) => {
    const valueStr = JSON.stringify(value);
    setFiltered(value);
    savePreference(filteredPreferenceKey.current, valueStr);
    setURLState({ filtered: valueStr });
  };
  const onSortedChange = (value) => {
    const valueStr = JSON.stringify(value);
    setSorted(value);
    savePreference(sortedPreferenceKey.current, valueStr);
    setURLState({ sorted: valueStr });
  };
  const onPageChange = (value) => {
    setPage(value);
    // savePreference(pagePreferenceKey.current, value.toString());
    setURLState({ page: value });
  };

  return {
    pageSize,
    onPageSizeChange,
    filtered,
    onFilteredChange,
    sorted,
    onSortedChange,
    page,
    onPageChange,
  };
}

export const TableWithPreferences: React.SFC<any> = ({
  tableId,
  defaultPageSize,
  defaultSorted,
  defaultFiltered,
  defaultPage,
  syncUrl,
  children,
}: {
  tableId: string;
  defaultPageSize: number;
  defaultSorted: SortedItem[];
  defaultFiltered: FilteredItem[];
  defaultPage: number;
  children?: any;
  syncUrl: boolean;
} & RouteComponentProps) => {
  const prefs = useTablePreferences(
    tableId,
    defaultPageSize,
    defaultSorted,
    defaultFiltered,
    defaultPage,
    syncUrl
  );
  return children(prefs);
};

TableWithPreferences.defaultProps = {
  syncUrl: false,
};

export default withRouter(TableWithPreferences);

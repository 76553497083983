import {
  lsRemoveItem,
  lsGetItem,
  lsSetItem,
  localStorageWorks
} from "../../LocalStorageWrapper";
import jwtDecode from "jwt-decode";
import { DocuclipperToken } from "../../docuclipper/DocuclipperTypes";
import * as Sentry from "@sentry/browser";

const tokenStr = lsGetItem("docuclipper.jwt");
let token = null;

if (tokenStr) {
  token = jwtDecode(tokenStr);
}

const initialState: {
  token: DocuclipperToken | null;
  tokenStr: string | null;
} = {
  token,
  tokenStr
};

export const SET_TOKEN = "set-token";
export const DELETE_TOKEN = "delete-token";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TOKEN:
      return {
        ...state,
        token: action.payload.token,
        tokenStr: action.payload.tokenStr
      };
    case DELETE_TOKEN:
      return {
        ...state,
        token: null,
        tokenStr: null
      };

    default:
      return state;
  }
}

export type SetTokenFn = (tokenStr: string) => any;
export const setToken = (tokenStr: string) => {
  let token;
  try {
    token = jwtDecode(tokenStr);
  } catch (err) {
    Sentry.captureException(err, {
      extra: {
        tokenStr
      }
    });
    throw err;
  }

  withLocalStorageCheck(() => lsSetItem("docuclipper.jwt", tokenStr));

  return {
    type: SET_TOKEN,
    payload: {
      token,
      tokenStr
    }
  };
};

export type DeleteTokenFn = () => any;
export const deleteToken = () => {
  withLocalStorageCheck(() => lsRemoveItem("docuclipper.jwt"));

  return {
    type: DELETE_TOKEN,
    payload: {}
  };
};

const withLocalStorageCheck = (fn: () => any) => {
  if (!localStorageWorks()) {
    // store.dispatch({
    //   type: CREATE_ALERT,
    //   payload: {
    //     alert: {
    //       type: "error",
    //       timeout: 0,
    //       message:
    //         "Cookies are not enabled on your browser. Please enable cookies in your browser preferences to continue."
    //     }
    //   }
    // });
    return null;
  } else {
    return fn();
  }
};

import React, { useRef, useState } from "react";
import { Card, Button, Badge, Form, Modal, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CSSProperties } from "styled-components";
import CreatableSelect from "react-select/creatable";
import PopoverHelp from "src/views/Docuclipper/PopoverHelp";

type TagProps = {
  tag: string;
  tagLabel?: string;
  onDeleteTag: () => void;
  onRemoveKeyword: (keyword: string, isPositive: boolean) => void;
  onAddKeyword: (keyword: string, isPositive: boolean) => void;
  onSaveTag: (tag: string, tagLabel: string) => void;
  keywords: { positive: string[]; negative: string[] };
};

const TagCard: React.FC<TagProps> = React.memo(
  ({
    tag,
    tagLabel,
    onDeleteTag,
    onRemoveKeyword,
    onAddKeyword,
    onSaveTag,
    keywords,
  }) => {
    const [hover, setHover] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [editableTag, setEditableTag] = useState<string>(tag);
    const [editableTagLabel, setEditableTagLabel] = useState<string>(
      tagLabel || ""
    );

    const containerRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const iconContainerStyle: CSSProperties = {
      position: "absolute",
      top: 0,
      right: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      padding: "0.5rem",
      gap: "0.25rem",
      visibility: hover ? "visible" : "hidden",
    };

    const cardBodyStyle: CSSProperties = {
      minHeight: "8rem",
      maxHeight: "8rem",
      padding: "0.5rem",
      paddingBottom: "0.5rem",
    };

    const keywordsListStyle: CSSProperties = {
      position: "absolute",
      bottom: "0.5rem",
      left: "0.5rem",
      right: "0.5rem",
      maxHeight: "calc(100% - 50%)",
      overflowY: "auto",
    };

    const handleOnChangeCreatableSelect = (
      newValue: any,
      actionMeta: any,
      isPositive: boolean
    ) => {
      if (actionMeta.action === "create-option" && newValue) {
        onAddKeyword(newValue[newValue.length - 1].value, isPositive);
      } else if (actionMeta.action === "remove-value") {
        onRemoveKeyword(actionMeta.removedValue.value, isPositive);
      }
    };

    const renderKeywords = () => {
      return [...keywords.positive, ...keywords.negative].map(
        (keyword, idx) => (
          <Badge
            key={idx}
            variant={keywords.positive.includes(keyword) ? "success" : "danger"}
            className="mr-1"
          >
            {keyword}
          </Badge>
        )
      );
    };

    const handleSaveTag = () => {
      onSaveTag(editableTag, editableTagLabel);
      setShowModal(false);
    };

    const renderKeywordsForm = () => (
      <Form>
        <Form.Group>
          <Form.Label>
            Include Keywords{" "}
            <PopoverHelp header="" placement="right" id="includeKeywordHelp">
              DocuClipper will assign the category if the description contains
              any of these keywords
            </PopoverHelp>
          </Form.Label>
          <CreatableSelect
            data-cy="creatable-select-positive"
            placeholder="Type to create a new keyword"
            isClearable
            onChange={(newValue, actionMeta) =>
              handleOnChangeCreatableSelect(newValue, actionMeta, true)
            }
            isMulti={true}
            options={keywords.positive.map((keyword) => ({
              label: keyword,
              value: keyword,
            }))}
            value={keywords.positive.map((keyword) => ({
              label: keyword,
              value: keyword,
            }))}
            formatCreateLabel={(val) => `Create keyword "${val}"`}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            Exclude Keywords{" "}
            <PopoverHelp header="" placement="right" id="includeKeywordHelp">
              DocuClipper won't assign the category if the description contains
              any of these keywords, even if the include keywords match.
            </PopoverHelp>
          </Form.Label>
          <CreatableSelect
            data-cy="creatable-select-negative"
            placeholder="Type to create a new keyword"
            isClearable
            onChange={(newValue, actionMeta) =>
              handleOnChangeCreatableSelect(newValue, actionMeta, false)
            }
            isMulti={true}
            options={keywords.negative.map((keyword) => ({
              label: keyword,
              value: keyword,
            }))}
            value={keywords.negative.map((keyword) => ({
              label: keyword,
              value: keyword,
            }))}
            formatCreateLabel={(val) => `Create keyword "${val}"`}
          />
        </Form.Group>
      </Form>
    );

    return (
      <Card
        ref={containerRef}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        className="tag-card"
      >
        <Card.Body style={cardBodyStyle}>
          <Card.Title>
            {tag} {tagLabel ? `- ${tagLabel}` : ""}
          </Card.Title>
          <div
            ref={contentRef}
            style={keywordsListStyle}
            className="keywords-list"
          >
            {renderKeywords()}
          </div>

          <div style={iconContainerStyle}>
            <Button variant="danger" size="sm" onClick={onDeleteTag}>
              <FontAwesomeIcon icon={["fas", "trash"]} />
            </Button>
            <Button
              variant="primary"
              size="sm"
              onClick={() => setShowModal(true)}
            >
              <FontAwesomeIcon icon={["fas", "pencil-alt"]} />
            </Button>
          </div>
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Edit Category and Keywords</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group>
                <Form.Label>Category</Form.Label>
                <FormControl
                  type="text"
                  value={editableTag}
                  onChange={(e) => setEditableTag(e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Category Label (optional)</Form.Label>
                <FormControl
                  type="text"
                  value={editableTagLabel}
                  onChange={(e) => setEditableTagLabel(e.target.value)}
                />
              </Form.Group>
              {renderKeywordsForm()}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSaveTag}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </Card.Body>
      </Card>
    );
  }
);

export default TagCard;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReferredUser } from "../../docuclipper/DocuclipperTypes";
import { getReferredUsers } from "../../docuclipper/api";
import { ReduxState } from "..";

const initialState: {
  referredUsers: ReferredUser[];
  referringUser: ReferredUser | null;
  loading: boolean;
  error: string | null;
} = {
  referredUsers: [],
  referringUser: null,
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "Referrals",
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
      state.error = null;
    },
    setReferredUsers(state, action: PayloadAction<ReferredUser[]>) {
      state.loading = false;
      state.referredUsers = action.payload;
    },
    setReferringUser(state, action: PayloadAction<ReferredUser | null>) {
      state.loading = false;
      state.referringUser = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const { load, setError, setReferredUsers, setReferringUser } = slice.actions;

export const fetchReferredUsers = () => async (dispatch, getState) => {
  const state: ReduxState = getState();
  if (state.Referrals.loading) {
    return;
  }
  dispatch(load());
  try {
    const { referredUsers, referringUser } = await getReferredUsers();
    dispatch(setReferredUsers(referredUsers));
    dispatch(setReferringUser(referringUser));
  } catch (err) {
    dispatch(setError(err.message || "Error getting referred users"));
  }
};

export default slice.reducer;

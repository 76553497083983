import React from "react";
import { Button, ButtonProps } from "react-bootstrap";
import withLoading from "../../../hoc/withLoading";
import { withTooltip } from "../../../hoc/withTooltip";
import { sendGAEvent } from "../../../utils/utils";

type Props = {
  onClick?: (...args: any[]) => any;
  action: string;
  category?: string;
  label?: string;
  variant?: string;
  className?: string;
  style?: any;
  id?: string;
  target?: string;
};

const TrackedButton: React.FC<Props & ButtonProps> = ({
  children,
  onClick,
  action,
  category,
  label,
  ...rest
}) => {
  const trackedOnClick = () => {
    sendGAEvent({ action, category, label });
    if (onClick) {
      onClick();
    }
  };
  return (
    <Button {...rest} onClick={trackedOnClick}>
      {children}
    </Button>
  );
};

TrackedButton.defaultProps = {
  category: "other",
  label: "",
};

export const LoadingButton = withLoading(Button);
export const ButtonWithTooltip = withTooltip(Button);
export const LoadingTrackedButton = withLoading(TrackedButton);

export const TrackedButtonWithTooltip = withTooltip(TrackedButton);

export default TrackedButton;

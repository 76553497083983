import { TagCategoryRule } from "src/docuclipper/DocuclipperTypes";
import { updateTagRegexesAction } from "../redux/reducers/TagRegexes";
import { Dispatch } from "redux";
import { AnyAction } from 'redux';

export const extractKeywordsFromJsonRules = (jsonRules: string) => {
    try {
        const rules = JSON.parse(jsonRules);
        return {
            positiveKeywords:
                rules.conditions.all.find(
                    (condition) => condition.operator === "containsAny"
                )?.value.keywords || [],
            negativeKeywords:
                rules.conditions.all.find(
                    (condition) => condition.operator === "doesNotContainAny"
                )?.value.keywords || [],
        };
    } catch (e) {
        console.error("Error parsing jsonRules", e);
        return { positiveKeywords: [], negativeKeywords: [] };
    }
};

export const handleAddNewKeyword = (
    dispatch: any,
    tagRegexes: any[],
    tagId: string,
    keyword: string,
    isPositive: boolean
) => {
    const tag = tagRegexes.find((t) => t.id === tagId);
    if (tag) {
        let positiveKeywords = [];
        let negativeKeywords = [];

        if (tag.jsonRules) {
            const keywords = extractKeywordsFromJsonRules(tag.jsonRules);
            positiveKeywords = keywords.positiveKeywords;
            negativeKeywords = keywords.negativeKeywords;
        } else if (tag.regexes) {
            // Consider regexes as positive keywords if jsonRules is null
            positiveKeywords = JSON.parse(tag.regexes);
        }

        const updatedPositiveKeywords = isPositive
            ? [...positiveKeywords, keyword]
            : positiveKeywords;
        const updatedNegativeKeywords = isPositive
            ? negativeKeywords
            : [...negativeKeywords, keyword];
        const updatedRule = {
            keywords: updatedPositiveKeywords,
            negativeKeywords: updatedNegativeKeywords,
            tag: tag.tag,
        };

        dispatch(
            updateTagRegexesAction(
                tag.id,
                tag.tag,
                tag.tagLabel,
                JSON.stringify(updatedRule),
                updatedRule
            )
        );
    }
}; 
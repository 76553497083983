import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ReduxState } from "../../../../../redux";
import { setOption } from "../../../../../redux/reducers/DownloadOptions";
import { useLocalStorage } from "../../../../../utils/prefs";

const options = [
  {
    value: "DD/MM/YYYY",
    label: "DD/MM/YYYY",
  },
  {
    value: "MM/DD/YYYY",
    label: "MM/DD/YYYY",
  },
  {
    value: "YYYY-MM-DD",
    label: "YYYY-MM-DD",
  },
];

export const DateFormatOptions: React.FC = () => {
  const dispatch = useDispatch();

  const { options: reduxOptions } = useSelector(
    (state: ReduxState) => state.DownloadOptions
  );

  const [selectedOptionLS, setSelectedOptionLS] = useLocalStorage(
    `docuclipper.prefs.dateFormat`,
    ""
  );

  React.useEffect(() => {
    if (!selectedOptionLS) {
      setSelectedOptionLS(
        reduxOptions[`docuclipper.prefs.dateFormat`] || "YYYY-MM-DD"
      );
    }
  }, []);

  React.useEffect(() => {
    dispatch(
      setOption({
        key: `docuclipper.prefs.dateFormat`,
        value: selectedOptionLS || "YYYY-MM-DD",
      })
    );
  }, [selectedOptionLS]);

  const value = reduxOptions[`docuclipper.prefs.dateFormat`];

  return (
    <Select
      options={options}
      value={{ value, label: value }}
      onChange={(value) => {
        setSelectedOptionLS(value ? value.value || "" : "");
        dispatch(
          setOption({
            key: `docuclipper.prefs.dateFormat`,
            value: value ? value.value || "" : "",
          })
        );
      }}
    />
  );
};
export default DateFormatOptions;

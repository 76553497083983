import React from "react";
import { Alert, Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useTable } from "react-table";
import { getBullMqJobs } from "../../../docuclipper/api";
import { createAlert } from "../../../redux/reducers/Alerts";
import * as moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

type Props = {};
export const BullMqJobs: React.FC<Props> = ({}) => {
  const [loading, setLoading] = React.useState(false);
  const [jobs, setJobs] = React.useState([]);
  const [counts, setCounts] = React.useState({});

  const [jobId, setJobId] = React.useState("");
  const dispatch = useDispatch();
  const fetch = () => {
    setLoading(true);
    getBullMqJobs({ jobId })
      .then(({ jobs, counts }) => {
        setJobs(jobs);
        setCounts(counts);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          createAlert({
            message: err.message || "Error getting bullmq jobs",
            timeout: 0,
            type: "error",
          })
        );
      });
  };
  return (
    <>
      <div style={{ maxWidth: "10vw" }}>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>Job Id</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter jobId"
            value={jobId}
            onChange={(e) => setJobId(e.target.value)}
          />
        </Form.Group>
      </div>
      {!loading && <Button onClick={fetch}>Search</Button>}
      {loading && <ClipLoader />}
      <div>{JSON.stringify(counts)} </div>
      {jobs.length === 0 && <Alert variant="danger">No jobs</Alert>}
      <Table
        columns={[
          {
            Header: "name",
            accessor: "name",
          },
          {
            Header: "processedOn",
            accessor: "processedOn",
            Cell: ({ value }) => moment.unix(value / 1000).fromNow(),
          },
          {
            Header: "finishedOn",
            accessor: "finishedOn",
            Cell: ({ value }) => moment.unix(value / 1000).fromNow(),
          },
          {
            Header: "data",
            accessor: "data",
            Cell: ({ value }) => JSON.stringify(value),
          },
        ]}
        data={jobs}
      />
      {/* <div>
        {jobs.map((j) => (
          <div>{JSON.stringify(j)}</div>
        ))}
      </div> */}
    </>
  );
};
export default BullMqJobs;

import React, { useMemo, useEffect, useRef } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Split from "react-split";
import AgTable from "../../Analyze/AnalyzeTransactionsTable/AgTable";
import { useTransactionsAutomaticMode } from "../../Analyze/AnalyzeTransactionsTable/hooks";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../../redux";
import { fetchTls } from "../../../../redux/reducers/TransactionManager";
import PDFViewer from "../../PdfViewer";
import useBankStatementPDF from "./useBankStatementPDF";
import { getDocumentName } from "../../Analyze/AnalyzeTransactionsTable/utils";
import { useHasQbo } from "src/utils/utils";
import { IDatasource } from "ag-grid-community";
import { TableFilterConfig } from "src/docuclipper/DocuclipperTypes";
import DocumentSelector from "./DocumentSelector";

export const AutomaticModeTable: React.FC = () => {
  const dispatch = useDispatch();

  const { tls, pagination, accountChunkLabels, selectedDocumentId } =
    useSelector((state: ReduxState) => state.TransactionManager);
  const { job } = useSelector((state: ReduxState) => state.JobData);
  const { data: jobDocs } = useSelector(
    (state: ReduxState) => state.JobDocument
  );

  React.useEffect(() => {
    dispatch(fetchTls(true));
  }, []);

  // const transactions = useTransactionsAutomaticMode(
  //   (selectedDocumentId || "").toString()
  // );

  // Filter transactions by selected document

  const { pdfURL } = useBankStatementPDF({
    selectedDocumentId,
  });

  let cols: any[] = [];
  let colNames: string[] = [];

  // Add null check and ensure tls.tls is an array
  if (tls?.tls && Array.isArray(tls.tls)) {
    for (const t of tls.tls) {
      const keys = Object.keys(t);
      if (keys.length > colNames.length) {
        colNames = keys;
      }
    }
    cols = colNames.map((name) => ({
      field: name,
      headerName: `Col ${name}`,
    }));
  }

  // console.log({ tls, cols, selectedDocumentId, pdfURL });

  const { data: jobsDocuments } = useSelector(
    (state: ReduxState) => state.JobDocument
  );
  const hasQbo = useHasQbo();
  const gridApi = useRef<any>(null);

  const onGridReady = (params) => {
    gridApi.current = params.api;

    // You can also store columnApi if needed
    // const columnApi = params.columnApi;
  };

  useEffect(() => {
    if (!gridApi.current) {
      // console.log("exit 0");
      return;
    }

    const dataSource: IDatasource = {
      rowCount: undefined,
      getRows: (params) => {
        const convertFilterModel = (): TableFilterConfig[] => {
          // TODO use params.filterModel
          return Object.entries(params.filterModel).map(
            ([field, filter]: [string, any]) => {
              // Get the actual filter type from the filter object
              const filterType = filter.filterType || "text"; // Default to 'text' if filterType is not specified
              const operationType = filter.type; // This is the operation (equals, contains, lessThan, etc.)

              // Initialize the filter object
              const filterOutput = {
                field,
                operator: operationType, // The operation (equals, contains, lessThan, etc.)
                valueFrom: undefined,
                valueTo: undefined,
                value: undefined,
              };

              if (filterType === "number") {
                // Handle number filters
                if (operationType === "inRange") {
                  filterOutput.valueFrom = filter.filter; // For range filters
                  filterOutput.valueTo = filter.filterTo;
                } else if (
                  operationType === "blank" ||
                  operationType === "notBlank"
                ) {
                  filterOutput.value = undefined; // No specific value for blank/not blank
                } else {
                  filterOutput.value = filter.filter; // Single value filters
                }
              } else if (filterType === "date") {
                // Handle date filters
                if (operationType === "inRange") {
                  filterOutput.valueFrom = filter.dateFrom; // Date filters use dateFrom
                  filterOutput.valueTo = filter.dateTo; // and dateTo
                } else if (
                  operationType === "blank" ||
                  operationType === "notBlank"
                ) {
                  filterOutput.value = undefined; // No specific value for blank/not blank
                } else {
                  filterOutput.value = filter.dateFrom; // Single value date filters use dateFrom
                }
              } else {
                // Handle text filters and other types
                filterOutput.value = filter.filter; // Default case
              }

              return filterOutput;
            }
          );
        };

        const convertSortModel = (sortModel: any[]) => {
          return sortModel.map((sort) => ({
            field: sort.colId,
            direction: sort.sort.toUpperCase(),
          }));
        };

        dispatch(
          fetchTls(false, {
            pagination: {
              page: Math.floor(params.startRow / pagination.pageSize) + 1,
              pageSize: params.endRow - params.startRow,
            },
            filters: [
              // Base filters
              ...(selectedDocumentId
                ? [
                    {
                      field: "documentId",
                      operator: "equals" as const,
                      value: selectedDocumentId,
                    },
                  ]
                : []),
              // ...(selectedAccount
              //   ? [
              //       {
              //         field: "account",
              //         operator: "equals" as const,
              //         value: selectedAccount,
              //       },
              //     ]
              //   : []),
              // ...(selectedChunk
              //   ? [
              //       {
              //         field: "chunk",
              //         operator: "equals" as const,
              //         value: selectedChunk,
              //       },
              //     ]
              //   : []),
              // Dynamic filters from AG Grid
              // {
              //   field: "included",
              //   operator: "equals" as const,
              //   value: true,
              // },
              ...convertFilterModel(),
            ],
            sorting: convertSortModel(params.sortModel),
            successAutomaticModeCallback: params.successCallback,
          })
        );
      },
    };

    if (gridApi.current && dataSource) {
      gridApi.current.setDatasource(dataSource);
    } else {
      console.error("Grid API or dataSource is not defined");
    }
  }, [job, dispatch, pagination.pageSize, selectedDocumentId, gridApi.current]);

  // console.log({ pdfURL });
  return (
    <div>
      <Split
        sizes={[40, 60]}
        direction="horizontal"
        style={{ display: "flex", height: "90vh" }}
      >
        {
          <div style={{ width: "100%" }} className="mr-2">
            <PDFViewer
              key={`${selectedDocumentId}-${job?.id}`}
              url={pdfURL}
              height={"90vh"}
            />
          </div>
        }
        <div style={{ width: "100%" }} className="ml-4">
          <AgTable
            columnDefs={cols}
            hasExport={false}
            height={"80vh"}
            width={"100%"}
            pagination={true}
            paginationPageSize={pagination.pageSize}
            rowModelType={"infinite"}
            cacheBlockSize={pagination.pageSize}
            cacheOverflowSize={2}
            maxConcurrentDatasourceRequests={1}
            infiniteInitialRowCount={1}
            maxBlocksInCache={2}
            rowData={undefined}
            onGridReady={onGridReady}
          />
        </div>
      </Split>
    </div>
  );
};

export default AutomaticModeTable;

import React, { useEffect, useMemo, useState } from "react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ReduxState } from "../../../../redux";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  Row,
  Col,
  Button,
  Modal,
  Pagination,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { useTransactions } from "./hooks";
import { uniq, uniqBy } from "lodash";
import {
  performBulkEdit,
  updateJobTransaction,
} from "../../../../docuclipper/api";
import { fetchPieChart } from "../../../../redux/reducers/TagRegexes";
import AgBulkTable from "./AgBulkTable";
import {
  fetchTls,
  setRefreshKey,
  setTls,
  toggleLoading,
} from "../../../../redux/reducers/TransactionManager";
import { createAlert } from "../../../../redux/reducers/Alerts";
import { FormWithOverlay } from "../../../../utils/form-with-overlay";
import { Field } from "formik";
import * as Yup from "yup";
import ClipLoader from "react-spinners/ClipLoader";
import { useIsAdmin } from "src/utils/auth";
import ExternalFilter from "src/views/Docuclipper/ExternalFilter";
import {
  clearExternalFilters,
  setExternalFiltersColumnDefs,
} from "src/redux/reducers/ExternalFilter";
import CategorizationBadge from "../../Invoices/CategorizationBadge";
import { handleAddNewKeyword } from "src/utils/categoryManagement";
import { CreateRuleForm } from "./CreateRuleForm";
import { IDatasource } from "ag-grid-community";
import { TableFilterConfig } from "src/docuclipper/DocuclipperTypes";
import { useHasQbo } from "src/utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const datefilterParamsYYYYMMDD = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    if (!cellValue) return -1;

    // Normalize both dates to UTC midnight
    const [year, month, day] = cellValue.split("-").map(Number);
    const cellDate = new Date(Date.UTC(year, month - 1, day));
    const filterDate = new Date(
      Date.UTC(
        filterLocalDateAtMidnight.getFullYear(),
        filterLocalDateAtMidnight.getMonth(),
        filterLocalDateAtMidnight.getDate()
      )
    );

    if (cellDate.getTime() === filterDate.getTime()) return 0;
    if (cellDate < filterDate) return -1;
    if (cellDate > filterDate) return 1;
    return 0;
  },
};

const generateOptions = (tagRegexes, selectedTagIds) => {
  const getTagName = (id) => {
    const matches = tagRegexes.filter((x) => x.id === id);
    if (matches.length > 0) {
      return `${matches[0].tag}`;
    }
    return "default";
  };

  const getTagLabel = (id) => {
    const matches = tagRegexes.filter((x) => x.id === id);
    if (matches.length > 0) {
      return matches[0].tagLabel ? ` - ${matches[0].tagLabel}` : "";
    }
    return "";
  };

  return selectedTagIds
    .map((t) => ({
      label: `${getTagName(t)}${getTagLabel(t)}`,
      value: getTagName(t),
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
};

const CategoryCellRenderer = (props) => {
  const [showRuleModal, setShowRuleModal] = useState(false);
  const [showPopover, setShowPopover] = useState(false);
  const values = props.data;

  const { job } = useSelector((state: ReduxState) => state.JobData);
  const { selectedAccount } = useSelector(
    (state: ReduxState) => state.Reconciler
  );
  const { tagRegexes, selectedTagRegexesSet, tagRegexesSets } = useSelector(
    (state: ReduxState) => state.TagRegexes
  );
  const dispatch = useDispatch();
  const { setDashboardReloadTrigger } = props;

  const selectedTagIds = !selectedTagRegexesSet
    ? []
    : tagRegexesSets
        .filter((t) => t.name === selectedTagRegexesSet.name)
        .map((x) => x.tagRegexesId);

  const onSave = (newCategory) => {
    if (!job) {
      return;
    }

    setDashboardReloadTrigger(false);

    updateJobTransaction(job.id, values.id, {
      editedCategory: newCategory,
    })
      .then(() => {
        // dispatch(setRefreshKey());

        dispatch(fetchPieChart(selectedAccount));
        setDashboardReloadTrigger(true);
      })
      .catch(() => null);
  };

  const options = generateOptions(tagRegexes, selectedTagIds);

  const customStyles = {
    container: (provided) => ({
      ...provided,
      width: "100%",
      height: "100%",
      position: "relative",
    }),
    control: (provided) => ({
      ...provided,
      minHeight: "100%",
      height: "100%",
      border: "1px solid #ced4da",
      borderRadius: "0.25rem",
      boxShadow: "none",
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: "100%",
      padding: "0 8px",
      display: "flex",
      alignItems: "center",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0",
      padding: "0",
      display: "flex",
      alignItems: "center",
      height: "100%",
    }),
    singleValue: (provided) => ({
      ...provided,
      margin: "0",
      padding: "0",
      display: "flex",
      alignItems: "center",
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: "100%",
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menu: (provided) => ({
      ...provided,
      marginTop: "0",
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "0",
    }),
  };
  if (!values) {
    return "";
  } //
  const selectedOption =
    options.find((option) => option.value === props.value) || null;
  const category = values?.category !== "Uncategorized" ? values?.category : "";
  const editedCategory = values?.editedCategory || "";

  // console.log({ editedCategory, category, options });

  const handleCreateRuleSuccess = () => {
    setShowPopover(false);
  };

  return (
    <>
      <CategorizationBadge
        primaryCondition={editedCategory}
        secondaryCondition={category}
        styles={{
          position: "absolute",
          marginTop: "-0.7rem",
        }}
      />
      <div
        style={{
          marginTop: "1.1rem",
          display: "flex",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <CreatableSelect
          isClearable
          value={selectedOption}
          onChange={(selectedOption) => {
            onSave(selectedOption ? selectedOption.value : "");
            console.log({ props: props.api });
            if (props.api) {
              props.api.purgeInfiniteCache();
            }
          }}
          options={options}
          placeholder="Uncategorized"
          menuPortalTarget={document.body}
          styles={customStyles}
        />
        {!category && !editedCategory && (
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            show={showPopover}
            onToggle={() => setShowPopover(!showPopover)}
            overlay={
              <Popover id="create-rule-popover" style={{ minWidth: "500px" }}>
                <Popover.Content>
                  <CreateRuleForm
                    initialKeyword={
                      Array.isArray(values.description)
                        ? values.description.join(" ")
                        : values.description
                    }
                    initialCategory={editedCategory}
                    showTransactionSelect={false}
                    onSuccess={handleCreateRuleSuccess}
                  />
                </Popover.Content>
              </Popover>
            }
          >
            <Button variant="link" size="sm" style={{ whiteSpace: "nowrap" }}>
              Create Rule
            </Button>
          </OverlayTrigger>
        )}
      </div>
      <Modal
        show={showRuleModal}
        onHide={() => setShowRuleModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Categorization Rule</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CreateRuleForm
            initialKeyword={
              Array.isArray(values.description)
                ? values.description.join(" ")
                : values.description
            }
            initialCategory={values.editedCategory}
            showTransactionSelect={false}
            onSuccess={() => setShowRuleModal(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export const AnalyzeTransactionsTable = ({ setDashboardReloadTrigger }) => {
  const { isAdmin } = useIsAdmin();

  const transactions = useTransactions();
  const { job } = useSelector((state: ReduxState) => state.JobData);
  const { loading } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );
  const { tagRegexes, selectedTagRegexesSet, tagRegexesSets } = useSelector(
    (state: ReduxState) => state.TagRegexes
  );

  const dispatch = useDispatch();

  const categories = tagRegexesSets
    .filter((x) => x.name === selectedTagRegexesSet?.name)
    .map((x: any) => x.TagRegex?.tag);
  // console.log({
  //   categories,
  //   tagRegexesSets,
  //   selectedTagRegexesSet,
  // });
  const [columnDefs, setColumnDefs] = useState<any[]>([]);

  const gridApi = React.useRef<any>(null);

  const selectedTagIds = !selectedTagRegexesSet
    ? []
    : tagRegexesSets
        .filter((t) => t.name === selectedTagRegexesSet.name)
        .map((x) => x.tagRegexesId);

  const options = generateOptions(tagRegexes, selectedTagIds);

  const bulkEditHelper = (operation, transactionIds, params) => {
    if (!job) {
      return Promise.resolve();
    }
    dispatch(toggleLoading());
    return performBulkEdit({
      jobId: job.id,
      operation,
      params,
      transactionIds,
    })
      .then(({ transactions }) => {
        dispatch(toggleLoading());
        dispatch(setTls({ transactions, resetPages: false }));
        dispatch(
          createAlert({
            message: "Correction made successfully",
            timeout: 0,
            type: "success",
          })
        );
      })
      .catch(() => {
        dispatch(toggleLoading());
        dispatch(
          createAlert({
            message: "Error making correction",
            timeout: 0,
            type: "error",
          })
        );
      });
  };

  useEffect(() => {
    const defs = [
      {
        field: "account",
        filter: "agTextColumnFilter",
        sortable: true,
        flex: 1,
      },
      {
        field: "date",
        filter: "agDateColumnFilter",
        filterParams: datefilterParamsYYYYMMDD,
        sortable: true,
        flex: 1,
      },
      {
        field: "description",
        filter: "agTextColumnFilter",
        flex: 3,
      },
      {
        field: "amount",
        filter: "agNumberColumnFilter",
        sortable: true,
        flex: 1,
      },
      {
        field: "category",
        filter: "agSetColumnFilter",
        flex: 3,
        cellRenderer: CategoryCellRenderer,
        cellRendererParams: {
          setDashboardReloadTrigger: setDashboardReloadTrigger,
        },
        filterParams: {
          values: categories,
          filterValueGetter: (params) => {
            return params.value.toUpperCase();
            // Map your original value to a custom display value
            // For example:
            const valueMap = {
              original_value_1: "Custom Label 1",
              original_value_2: "Custom Label 2",
              // Add mappings for all your categories
            };

            return valueMap[params.value] || params.value;
          },
        },
        cellClass: "categorization-tour-override-category",
      },
      {
        headerName: "Matched Keyword",
        field: "categoryKeyword",
        filter: "agTextColumnFilter",
        flex: 2,
      },
    ];

    setColumnDefs(defs);
    dispatch(clearExternalFilters());
    dispatch(setExternalFiltersColumnDefs({ externalFilterColumnDefs: defs }));
  }, [categories.length]);

  const externalFilterColumns = useMemo(
    () => [
      // {
      //   field: "account",
      //   label: "Account",
      //   filter: true,
      //   filterType: "text",
      //   defaultFilterType: "contains",
      // },
      {
        field: "date",
        label: "Date",
        filter: true,
        filterType: "date",
        defaultFilterType: "equals",
      },
      {
        field: "description",
        label: "Description",
        filter: true,
        filterType: "text",
        defaultFilterType: "contains",
      },
      {
        field: "amount",
        label: "Amount",
        filter: true,
        filterType: "number",
        defaultFilterType: "equals",
      },
      {
        field: "category",
        label: "Category",
        filter: true,
        filterType: "select",
        options: categories.map((category) => ({
          label: category!,
          value: category!,
        })),
        defaultFilterType: "equals",
      },
      {
        field: "categoryKeyword",
        label: "Matched Keyword",
        filter: true,
        filterType: "text",
        defaultFilterType: "contains",
      },
    ],
    [categories]
  );

  const [selectedTransaction, setSelectedTransaction] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedKeyword, setSelectedKeyword] = useState("");

  const uncategorizedTransactions = useMemo(() => {
    const normalizedTransactions = transactions
      .filter((t) => !t.category || t.category === "Uncategorized")
      .map((t) => ({
        ...t,
        description: Array.isArray(t.description)
          ? t.description.join(" ")
          : t.description || "",
      }));

    return uniqBy(normalizedTransactions, "description").sort((a, b) =>
      a.description.localeCompare(b.description)
    );
  }, [transactions]);

  const {
    selectedDocumentId,
    tls,
    selectedChunk,
    accountChunkLabels,
    pagination,
    refreshKey,
  } = useSelector((state: ReduxState) => state.TransactionManager);
  const { selectedAccount } = useSelector(
    (state: ReduxState) => state.Reconciler
  );

  const { documents } = tls;

  // console.log({ job });
  const hasQbo = useHasQbo();
  useEffect(() => {
    if (!gridApi.current) {
      // console.log("exit 0");
      return;
    }

    const dataSource: IDatasource = {
      rowCount: undefined,
      getRows: (params) => {
        const convertFilterModel = (): TableFilterConfig[] => {
          // TODO use params.filterModel
          return Object.entries(params.filterModel).map(
            ([field, filter]: [string, any]) => {
              // Get the actual filter type from the filter object
              const filterType = filter.filterType || "text"; // Default to 'text' if filterType is not specified
              const operationType = filter.type; // This is the operation (equals, contains, lessThan, etc.)

              // Initialize the filter object
              const filterOutput = {
                field,
                operator: operationType, // The operation (equals, contains, lessThan, etc.)
                valueFrom: undefined,
                valueTo: undefined,
                value: undefined,
              };

              if (filterType === "number") {
                // Handle number filters
                if (operationType === "inRange") {
                  filterOutput.valueFrom = filter.filter; // For range filters
                  filterOutput.valueTo = filter.filterTo;
                } else if (
                  operationType === "blank" ||
                  operationType === "notBlank"
                ) {
                  filterOutput.value = undefined; // No specific value for blank/not blank
                } else {
                  filterOutput.value = filter.filter; // Single value filters
                }
              } else if (filterType === "date") {
                // Handle date filters
                if (operationType === "inRange") {
                  filterOutput.valueFrom = filter.dateFrom; // Date filters use dateFrom
                  filterOutput.valueTo = filter.dateTo; // and dateTo
                } else if (
                  operationType === "blank" ||
                  operationType === "notBlank"
                ) {
                  filterOutput.value = undefined; // No specific value for blank/not blank
                } else {
                  filterOutput.value = filter.dateFrom; // Single value date filters use dateFrom
                }
              } else {
                // Handle text filters and other types
                filterOutput.value = filter.filter; // Default case
              }

              return filterOutput;
            }
          );
        };

        const convertSortModel = (sortModel: any[]) => {
          return sortModel.map((sort) => ({
            field: sort.colId,
            direction: sort.sort.toUpperCase(),
          }));
        };

        dispatch(
          fetchTls(false, {
            pagination: {
              page: Math.floor(params.startRow / pagination.pageSize) + 1,
              pageSize: params.endRow - params.startRow,
            },
            filters: [
              // Base filters
              // ...(selectedDocumentId
              //   ? [
              //       {
              //         field: "documentId",
              //         operator: "equals" as const,
              //         value: selectedDocumentId,
              //       },
              //     ]
              //   : []),
              ...(selectedAccount
                ? [
                    {
                      field: "account",
                      operator: "equals" as const,
                      value: selectedAccount,
                    },
                  ]
                : []),
              // ...(selectedChunk
              //   ? [
              //       {
              //         field: "chunk",
              //         operator: "equals" as const,
              //         value: selectedChunk,
              //       },
              //     ]
              //   : []),
              // Dynamic filters from AG Grid
              {
                field: "included",
                operator: "equals" as const,
                value: true,
              },
              ...convertFilterModel(),
            ],
            sorting: convertSortModel(params.sortModel),
            successCallback: params.successCallback,
            accountChunkLabels,
            documents,
            hasQbo,
            job,
          })
        );
      },
    };

    if (gridApi.current && dataSource) {
      gridApi.current.setDatasource(dataSource);
    } else {
      console.error("Grid API or dataSource is not defined");
    }
  }, [
    job,
    dispatch,
    pagination.pageSize,
    selectedDocumentId,
    selectedAccount,
    selectedChunk,
    refreshKey,
    gridApi.current,
  ]);

  // console.log({ uncategorizedTransactions });
  return (
    <>
      {/* <Row className="mb-3">
        <Col md={12}>
          <h5>Add Categorization Rule</h5>
          <CreateRuleForm showTransactionSelect={true} />
        </Col>
      </Row> */}

      {loading && (
        <Row>
          <ClipLoader />
        </Row>
      )}
      <h3>Transactions</h3>
      <Row className="mb-2">
        <Col md="12">
          Filter by
          <ExternalFilter
            columnDefs={externalFilterColumns}
            gridApi={gridApi.current}
          />
        </Col>
      </Row>
      <AgBulkTable
        className="categorization-tour-table"
        onSetApi={(api) => (gridApi.current = api)}
        rowHeight={65}
        renderBulkOps={(data, setSelectedRowsIds) => {
          return (
            <>
              <div className="d-flex align-items-center mb-2">
                <Button
                  variant="light"
                  onClick={() => {
                    if (!gridApi.current) return;
                    gridApi.current.forEachNode((node) => {
                      node.setSelected(true);
                    });
                  }}
                  className="m-1"
                >
                  <FontAwesomeIcon icon="check-square" />
                  <span className="ml-1">Select All</span>
                </Button>

                <Button
                  variant="light"
                  onClick={() => {
                    if (!gridApi.current) return;
                    gridApi.current.deselectAll();
                  }}
                  className="m-1"
                  disabled={data.length === 0}
                >
                  <FontAwesomeIcon icon="times" />
                  <span className="ml-1">Clear Selection</span>
                </Button>

                {data.length > 0 && (
                  <span className="ml-3">{data.length} rows selected</span>
                )}

                <FormWithOverlay
                  icon="edit"
                  initialValues={{ category: "" }}
                  validationSchema={Yup.object().shape({
                    category: Yup.string().required(),
                  })}
                  renderFields={() => (
                    <Field
                      name="category"
                      component={({ field, form }) => (
                        <CreatableSelect
                          isClearable
                          value={
                            field.value
                              ? { label: field.value, value: field.value }
                              : null
                          }
                          onChange={(selectedOption) =>
                            form.setFieldValue(
                              "category",
                              selectedOption ? selectedOption.value : ""
                            )
                          }
                          options={options}
                          placeholder="Uncategorized"
                        />
                      )}
                    />
                  )}
                  cta={"Update Category"}
                  ctaClassName={"categorization-tour-update-bulk"}
                  disabled={data.length === 0}
                  onSave={(values) => {
                    if (!job) {
                      return;
                    }
                    bulkEditHelper(
                      "updateCategory",
                      data.map((x) => x.id),
                      { category: values.editedCategory || values.category }
                    ).then(() => {
                      // dispatch(setRefreshKey());
                      dispatch(fetchPieChart(selectedAccount));
                      if (setSelectedRowsIds) {
                        setSelectedRowsIds([]);
                      }
                      if (gridApi.current) {
                        gridApi.current.purgeInfiniteCache();
                      }
                    });
                  }}
                />
              </div>
            </>
          );
        }}
        hasExport={false}
        // rowData={transactions}
        columnDefs={columnDefs}
        width={"100%"}
        height={800}
        cypressId="analyze-transactions-table"
        pagination={true}
        paginationPageSize={pagination.pageSize}
        rowModelType={"infinite"}
        cacheBlockSize={pagination.pageSize}
        cacheOverflowSize={2}
        maxConcurrentDatasourceRequests={1}
        infiniteInitialRowCount={1}
        maxBlocksInCache={2}
        rowData={undefined}
      />
    </>
  );
};

import React from "react";
import BadRatings from "./views/Pages/Admin/BadRatings";
import BullMqJobs from "./views/Pages/Admin/BullMqJobs";
import { CustomFormat } from "./views/Pages/CustomFormat/CustomFormat";
import JobDataViewer from "./views/Pages/DataArchive/JobDataViewer";
import TransactionManager from "./views/Pages/DataArchive/TransactionManager/TransactionManager";
import { Invoices } from "./views/Pages/TagLevel/Invoices";
import { Transactions } from "./views/Pages/TagLevel/Transactions";

const CustomTemplates = React.lazy(
  () => import("./views/Pages/Templates/CustomTemplates")
);

const RequestPasswordChange = React.lazy(
  () => import("./views/Pages/RequestPasswordChange")
);
const ChangePassword = React.lazy(() => import("./views/Pages/ChangePassword"));
const ExtractSingle = React.lazy(() => import("./views/Pages/ExtractSingle"));
const NewTemplate = React.lazy(() => import("./views/Pages/NewTemplate"));
const NewBankStatementTemplate = React.lazy(
  () => import("./views/Pages/NewBankStatementTemplate")
);
const NewTaxTemplate = React.lazy(() => import("./views/Pages/NewTaxTemplate"));

const UpdateTemplate = React.lazy(
  () => import("./views/Pages/UpdateTemplate/")
);

const ExtractTemplate = React.lazy(
  () => import("./views/Pages/ExtractTemplate")
);

const ExtractBankMode = React.lazy(
  () => import("./views/Pages/ExtractBankMode")
);

const ExtractInvoice = React.lazy(() => import("./views/Pages/ExtractInvoice"));
const ExtractForm = React.lazy(() => import("./views/Pages/ExtractForm"));
const Csv2Qbo = React.lazy(() => import("./views/Pages/Csv2Qbo"));
const VendorsCsv = React.lazy(() => import("./views/Pages/VendorsCsv"));

const ExtractDataJobs = React.lazy(
  () => import("./views/Pages/DataArchive/ExtractDataJobs")
);
const CustomizableTemplates = React.lazy(
  () => import("./views/Pages/CustomizableTemplates")
);
const MyTemplates = React.lazy(() => import("./views/Pages/MyTemplates"));
const Integrations = React.lazy(() => import("./views/Pages/Integrations"));

const Account = React.lazy(() => import("./views/Pages/Account"));
const RestartTrial = React.lazy(
  () => import("./views/Pages/Account/RestartTrial")
);

const Referrals = React.lazy(() => import("./views/Pages/Account/Referrals"));
const ReferralsLinkMink = React.lazy(
  () => import("./views/Pages/Account/Referrals/LinkMink")
);

const SeatManager = React.lazy(
  () => import("./views/Pages/Account/SeatManager/")
);

const SeatManagerOld = React.lazy(
  () => import("./views/Pages/Account/Plans/SeatManager/SeatManagerOld")
);

const AskForReview = React.lazy(
  () => import("./views/Pages/Review/AskForReview")
);
const ReviewIntuit = React.lazy(() => import("./views/Pages/Review/Intuit"));
const ReviewG2 = React.lazy(() => import("./views/Pages/Review/G2"));

const Tools = React.lazy(() => import("./views/Pages/Tools"));
const CustomDocument = React.lazy(
  () => import("./views/Pages/Tools/CustomDocument")
);

const UsersTable = React.lazy(() => import("./views/Pages/Admin/UsersTable"));
const Contracts = React.lazy(() => import("./views/Pages/Admin/Contracts"));

const UserExplorer = React.lazy(
  () => import("./views/Pages/Admin/UserExplorer")
);
const AdminDocumentExplorer = React.lazy(
  () => import("./views/Pages/Admin/AdminDocumentExplorer")
);
const Superlogin = React.lazy(() => import("./views/Pages/Admin/Superlogin2"));

const PgbossJobs = React.lazy(() => import("./views/Pages/Admin/Jobs"));
const AuditLog = React.lazy(() => import("./views/Pages/Admin/AuditLog"));

const QuickBooksAddNewCompany = React.lazy(
  () => import("./views/Pages/DataArchive/QuickBooksAddNewCompany")
);
const BankStatements = React.lazy(
  () => import("./views/Pages/Templates/BankStatements")
);
const TaxForms = React.lazy(() => import("./views/Pages/Templates/TaxForms"));

const Upgrade = React.lazy(
  () =>
    import("./views/Pages/Account/SelectPlanAfterTrial/SelectPlanAfterTrial")
);

const Support = React.lazy(() => import("./views/Pages/Support/"));

const SwitchPlans = React.lazy(
  () => import("./views/Pages/Account/ChangePlan/SwitchPlans")
);

const CancelPlan = React.lazy(
  () => import("./views/Pages/Account/ChangePlan/CancelPlan")
);

const DocumentEditor = React.lazy(() => import("./views/Pages/DocumentEditor"));
const TemplateAddedColumns = React.lazy(
  () => import("./views/Pages/TemplateAddedColumns")
);

const Documents = React.lazy(() => import("./views/Pages/Documents"));

const WritePdf = React.lazy(() => import("./views/Pages/WritePdf"));
const QboApiDriver = React.lazy(() => import("./views/Pages/QboApiDriver"));

const Regions = React.lazy(
  () => import("./views/Pages/ExtractSingle/v2/Regions")
);

const Sandbox = React.lazy(() => import("./views/Pages/Sandbox"));
const Survey1 = React.lazy(() => import("./views/Pages/Surveys/Survey1"));
const ProspectCsv = React.lazy(() => import("./views/Pages/ProspectCsv"));

const GettingStarted = React.lazy(() => import("./views/Pages/GettingStarted"));

const routes = [
  { path: "/", exact: true, name: "Home" },
  {
    path: "/regions",
    exact: true,
    name: "regions",
    component: Regions,
    isProtected: false,
  },

  // DocuClipper
  {
    path: "/requestPasswordChange",
    exact: true,
    name: "Request password change",
    component: RequestPasswordChange,
    isProtected: false,
  },
  {
    path: "/changePassword",
    exact: true,
    name: "Change Password",
    component: ChangePassword,
    isProtected: false,
  },

  {
    path: "/extract/single",
    exact: true,
    name: "Convert document",
    component: ExtractSingle,
  },
  {
    path: "/extract/template",
    exact: true,
    name: "Convert document using template",
    component: ExtractTemplate,
    docsUrl:
      "/docs/ui/user-guide/extracting-data/extracting-data-with-a-template/",
  },
  {
    path: "/extract/bankMode",
    exact: true,
    name: "Convert bank statements",
    component: ExtractBankMode,
    docsUrl: "",
  },
  {
    path: "/extract/invoice",
    exact: true,
    name: "Convert invoices & receipts",
    component: ExtractInvoice,
    docsUrl: "",
  },
  {
    path: "/extract/form",
    exact: true,
    name: "Convert form",
    component: ExtractForm,
    docsUrl: "",
  },
  {
    path: "/extractdata/*/template",
    exact: true,
    name: "",
    component: ExtractTemplate,
    docsUrl:
      "/docs/ui/user-guide/extracting-data/extracting-data-with-a-template/",
  },
  {
    path: "/dataArchive",
    exact: true,
    name: "Data Archive",
    component: ExtractDataJobs,
    docsUrl:
      "/docs/ui/user-guide/extracting-data/exploring-exporting-extracted-data/",
  },
  { path: "/extractdata", exact: true, name: "Convert Documents" },
  {
    path: "/extractdata/custom",
    exact: true,
    name: "Custom Document",
    component: CustomTemplates,
    docsUrl: "/docs/ui/user-guide/extracting-data/from-custom-documents/",
  },
  {
    path: "/extract/csv2qbo",
    exact: true,
    name: "Convert CSV files",
    component: Csv2Qbo,
    docsUrl: "",
  },
  {
    path: "/extract/vendorscsv",
    exact: true,
    name: "Import Vendor Spreadsheet",
    component: VendorsCsv,
    docsUrl: "",
  },
  {
    path: "/surveys/survey1",
    exact: true,
    name: "Survey1",
    component: Survey1,
    docsUrl: "",
  },
  {
    path: "/cf",
    exact: true,
    name: "cf",
    component: CustomFormat,
    docsUrl: "",
  },

  {
    path: "/extractdata/bankstatements",
    exact: true,
    name: "Bank & Credit Card Statements",
    component: BankStatements,
    docsUrl: "/docs/ui/user-guide/extracting-data/from-bank-statements/",
  },
  {
    path: "/extractdata/taxforms",
    exact: true,
    name: "Tax Forms",
    component: TaxForms,
    docsUrl: "/docs/ui/user-guide/extracting-data/from-tax-forms/",
  },
  {
    path: "/customizableTemplates",
    exact: true,
    name: "Customizable Templates",
    component: CustomizableTemplates,
  },
  {
    path: "/mytemplates",
    exact: true,
    name: "My Templates",
    component: MyTemplates,
  },

  {
    path: "/manageIntegrations",
    exact: true,
    name: "Integrations",
    component: Integrations,
    docsUrl: "/docs/ui/user-guide/integrations/",
  },
  {
    path: "/account",
    exact: true,
    name: "Account",
    component: Account,
    docsUrl: "/docs/ui/user-guide/managing-your-account/account/",
  },
  {
    path: "/account/restartTrial",
    exact: true,
    name: "Restart your trial",
    component: RestartTrial,
    docsUrl: "/docs/ui/user-guide/managing-your-account/account/",
  },
  {
    path: "/account/refer",
    exact: true,
    name: "Refer someone",
    component: ReferralsLinkMink,
    docsUrl: "/docs/ui/user-guide/managing-your-account/account/",
  },

  {
    path: "/account/invite",
    exact: true,
    name: "Manage Users / Teams",
    component: SeatManagerOld,
    docsUrl: "",
  },
  {
    path: "/account/teams",
    exact: true,
    name: "Manage Teams",
    component: SeatManager,
    docsUrl: "",
  },

  {
    path: "/review",
    exact: true,
    name: "Review Docuclipper",
    component: AskForReview,
    docsUrl: "",
  },

  {
    path: "/review/intuit",
    exact: true,
    name: "QuickBooks App Store",
    component: ReviewIntuit,
    docsUrl: "",
  },

  {
    path: "/review/g2",
    exact: true,
    name: "G2",
    component: ReviewG2,
    docsUrl: "",
  },

  {
    path: "/jobData",
    exact: true,
    name: "Job Data",
    component: JobDataViewer,
    docsUrl:
      "/docs/ui/user-guide/extracting-data/exploring-exporting-extracted-data/",
  },
  {
    path: "/tl",
    exact: true,
    name: "Transations",
    component: TransactionManager,
    docsUrl: "",
  },
  {
    path: "/sandbox",
    exact: true,
    name: "Sandbox",
    component: Sandbox,
    docsUrl: "",
  },
  {
    path: "/template/new",
    exact: true,
    name: "New Custom Document",
    component: NewTemplate,
    docsUrl: "/docs/ui/user-guide/extracting-data/from-custom-documents/",
  },
  {
    path: "/template/invoice/new",
    exact: true,
    name: "New Custom Invoice",
    component: NewTemplate,
  },
  {
    path: "/template/bankStatement/new",
    exact: true,
    name: "New Custom Bank Statement",
    component: NewBankStatementTemplate,
    docsUrl: "/docs/ui/user-guide/extracting-data/from-custom-documents/",
  },
  {
    path: "/template/tax/new",
    exact: true,
    name: "New Custom Tax Form",
    component: NewTaxTemplate,
    docsUrl: "/docs/ui/user-guide/extracting-data/from-custom-documents/",
  },

  {
    path: "/template/update",
    exact: true,
    name: "Update Template",
    component: UpdateTemplate,
    docsUrl: "/docs/ui/user-guide/extracting-data/from-custom-documents/",
  },
  { path: "/extractdata", exact: true, name: "Extract Data", component: Tools },
  {
    path: "/extract/customDocument",
    exact: true,
    name: "Custom Document",
    component: CustomDocument,
  },

  {
    path: "/admin/users",
    exact: true,
    name: "Users",
    component: UsersTable,
    permission: "READ_DB_TABLES",
  },
  {
    path: "/admin/bull",
    exact: true,
    name: "BullMQ jobs",
    component: BullMqJobs,
    permission: "READ_DB_TABLES",
  },
  {
    path: "/admin/contracts",
    exact: true,
    name: "Manage contracts",
    component: Contracts,
    permission: "READ_DB_TABLES",
  },
  {
    path: "/admin/userexplorer",
    exact: true,
    name: "User Explorer",
    component: UserExplorer,
    permission: "READ_DB_TABLES",
  },
  {
    path: "/admin/documents",
    exact: true,
    name: "Document Explorer",
    component: AdminDocumentExplorer,
    permission: "READ_DB_TABLES",
  },
  {
    path: "/admin/pgboss",
    exact: true,
    name: "pg-boss jobs",
    component: PgbossJobs,
    permission: "READ_DB_TABLES",
  },
  {
    path: "/admin/badRatings",
    exact: true,
    name: "Bad Ratings",
    component: BadRatings,
    permission: "READ_DB_TABLES",
  },

  {
    path: "/admin/auditlog",
    exact: true,
    name: "Admin audit log",
    component: AuditLog,
    permission: "READ_DB_TABLES",
  },
  {
    path: "/admin/superlogin",
    exact: true,
    name: "Superlogin",
    component: Superlogin,
    permission: "SUPPORT_TOOL",
  },
  {
    path: "/quickbooksaddnewcompany",
    exact: true,
    name: "Add QuickBooks company",
    component: QuickBooksAddNewCompany,
    docsUrl: "/docs/ui/user-guide/integrations/integration-quickbooks/",
  },
  {
    path: "/account/upgrade",
    exact: true,
    name: "Upgrade",
    component: Upgrade,
    docsUrl: "/docs/ui/user-guide/managing-your-account/account/",
  },
  {
    path: "/support",
    exact: true,
    name: "Support",
    component: Support,
    docsUrl: "",
  },
  {
    path: "/support/prospectCsv",
    exact: true,
    name: "Upload Prospect CSV",
    component: ProspectCsv,
    docsUrl: "",
  },
  // {
  //   path: "/account/cards",
  //   exact: true,
  //   name: "Credit cards",
  //   component: UpdateCard,
  //   docsUrl: "/docs/ui/user-guide/managing-your-account/account/"
  // },
  {
    path: "/account/changePlans",
    exact: true,
    name: "Change plans",
    component: SwitchPlans,
    docsUrl: "/docs/ui/user-guide/managing-your-account/account/",
  },
  {
    path: "/account/cancelPlan",
    exact: true,
    name: "Cancel plan",
    component: CancelPlan,
    docsUrl: "/docs/ui/user-guide/managing-your-account/account/",
  },
  {
    path: "/documentEditor",
    exact: true,
    name: "Format documents",
    component: DocumentEditor,
    docsUrl: "/docs/ui/user-guide/formatting-documents/formatting-documents/",
  },
  {
    path: "/templateAddedColumns",
    exact: true,
    name: "Added Columns",
    component: TemplateAddedColumns,
    docsUrl: "/docs/ui/user-guide/",
  },
  {
    path: "/documents",
    exact: true,
    name: "Documents",
    component: Documents,
    docsUrl: "",
  },
  {
    path: "/writePdf",
    exact: true,
    name: "Populate documents",
    component: WritePdf,
    docsUrl: "",
  },
  {
    path: "/qboApiDriver",
    exact: true,
    name: "QuickBooks API Driver",
    component: QboApiDriver,
    docsUrl: "",
  },
  {
    path: "/gettingStarted",
    exact: true,
    name: "Gettig Started",
    component: GettingStarted,
    docsUrl: "",
  },
  {
    path: "/invoices",
    exact: true,
    name: "Invoices",
    component: Invoices,
    docsUrl: "",
  },
  {
    path: "/transactions",
    exact: true,
    name: "Transactions",
    component: Transactions,
    docsUrl: "",
  },
];

export default routes;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "..";
import { getPlans } from "../../docuclipper/api";
import { ContractAddonsAttributes, StripeCoupon, StripePlan, SubscriptionItemAttributes } from "../../docuclipper/DocuclipperTypes";



// Update the initial state with the correct types
const initialState: {
  plans: StripePlan[];
  loading: boolean;
  error: string | null;
  coupon: StripeCoupon | null;
  subscriptionItems: SubscriptionItemAttributes[]; // Use the defined type
  contractAddons: ContractAddonsAttributes[]; // Use the defined type
} = {
  plans: [],
  loading: false,
  error: null,
  coupon: null,
  subscriptionItems: [], // Initialize with the correct type
  contractAddons: [], // Initialize with the correct type
};

const slice = createSlice({
  name: "Plans",
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
      state.error = null;
    },
    setCoupon(state, action: PayloadAction<StripeCoupon>) {
      state.coupon = action.payload;
    },
    setData(state, action: PayloadAction<{
      plans: StripePlan[];
      subscriptionItems: SubscriptionItemAttributes[]; // Use the defined type
      contractAddons: ContractAddonsAttributes[]; // Use the defined type
    }>) {
      state.loading = false;
      state.plans = action.payload.plans;
      state.subscriptionItems = action.payload.subscriptionItems; // Update subscriptionItems
      state.contractAddons = action.payload.contractAddons; // Update contractAddons
    },
    setError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const { load, setError, setData, setCoupon } = slice.actions;

export const fetchPlans =
  (couponId = "") =>
    async (dispatch, getState) => {
      const state: ReduxState = getState();
      if (state.Plans.loading) {
        return;
      }
      dispatch(load());
      try {
        const { plans, coupon, subscriptionItems, contractAddons } = await getPlans({ coupon: couponId });
        plans.sort((a, b) => b.amount - a.amount);
        dispatch(setData({
          plans,
          subscriptionItems,
          contractAddons,
        }));
        dispatch(setCoupon(coupon));
      } catch (err) {
        dispatch(setError((err as any).message || "Error getting plans"));
      }
    };

export default slice.reducer;

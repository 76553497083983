import { Row } from "react-bootstrap";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../../redux";
import { setSelectedChunk } from "../../../../redux/reducers/TransactionManager";
import SelectWithBadge from "./SelectWithBadge";
import {
  getIsBadDateBoolean,
  getIsReconciledBoolean,
} from "./SummaryTableAccountViewHorizontal";

type Props = {
  showAccountsHeading?: boolean;
  showEditAccountName?: boolean;
  renderReconciled?: any;
};
export const PeriodSelector: React.FC<Props> = ({ showAccountsHeading }) => {
  const dispatch = useDispatch();
  const { totalsByChunk } = useSelector(
    (state: ReduxState) => state.Reconciler
  );
  const { selectedAccount, selectedDocumentId } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );

  const { mfs, selectedChunk } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );
  const { mfs: fields } = mfs;

  const accountChunks = (totalsByChunk[selectedAccount || ""] || {})[
    selectedDocumentId || ""
  ];
  const accountChunkKeys = Object.keys(accountChunks || {});

  React.useEffect(() => {
    if (accountChunkKeys.length > 0) {
      dispatch(setSelectedChunk(accountChunkKeys[0]));
    }
  }, [accountChunkKeys.length, selectedAccount, selectedDocumentId]);

  const options = accountChunkKeys.map((chunk) => ({
    label: accountChunks[chunk].label || "Unknown date to unknown date",
    value: chunk,
    reconciled: getIsReconciledBoolean(fields, selectedAccount || "", chunk),
    reconciledType: "period",
    isBadDate: getIsBadDateBoolean(fields, selectedAccount || "", chunk),
  }));
  let selectedOption: any = null;
  const matches = options.filter((x) => x.value === selectedChunk);
  if (matches.length > 0) {
    selectedOption = matches[0];
  }
  return (
    <>
      {accountChunkKeys.length > 0 && (
        <Row className="flex-column">
          {showAccountsHeading && (
            <span className="font-weight-bold mr-1">
              Select Period ({accountChunkKeys.length} available) ▾
            </span>
          )}
          <div style={{ width: "100%" }}>
            <SelectWithBadge
              value={selectedOption}
              onChange={(e) => {
                dispatch(setSelectedChunk(e.value));
              }}
              options={options}
              placeholder="Select a period..."
            />
          </div>
        </Row>
      )}
    </>
  );
};
export default PeriodSelector;

PeriodSelector.defaultProps = {
  showAccountsHeading: true,
  showEditAccountName: true,
  renderReconciled: () => null,
};

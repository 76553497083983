import React from "react";
import { Form, Button, Row } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import { formikInput } from "../../../../../utils/utils";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PeriodForm = ({ onSubmit }) => {
  const validationSchema = yup.object().shape({
    startDate: yup
      .string()
      .trim()
      .matches(/\d{4}\-\d{2}\-\d{2}/, "Invalid date, use YYYY-MM-DD format")
      .required(),
    endDate: yup
      .string()
      .trim()
      .matches(/\d{4}\-\d{2}\-\d{2}/, "Invalid date, use YYYY-MM-DD format")
      .required(),
  });

  const initialValues = { startDate: "", endDate: "" };

  const handleSubmit = (values) => {
    // Handle form submission logic here

    onSubmit(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Form.Group className="mx-1" controlId="startDate">
              {/* <Form.Label>Start Date</Form.Label> */}
              <Field
                type="text"
                name="startDate"
                placeholder="Start Date"
                component={formikInput}
              />
            </Form.Group>
            <Form.Group className="mx-1" controlId="endDate">
              {/* <Form.Label>End Date</Form.Label> */}
              <Field
                type="text"
                name="endDate"
                placeholder="End Date"
                component={formikInput}
              />
            </Form.Group>

            <div>
              <Button className="mx-1" variant="primary" type="submit">
                <FontAwesomeIcon icon="plus" />
                <span className="ml-1">Add New Period</span>
              </Button>
            </div>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export const PeriodForm2 = ({ onSubmit }) => {
  const initialValues = {
    startDate: "",
    endDate: "",
    account: "",
    startBalance: "",
    endBalance: "",
  };

  const validationSchema = yup.object().shape({
    account: yup.string().required(),
    startDate: yup
      .string()
      .trim()
      .matches(/\d{4}\-\d{2}\-\d{2}/, "Invalid date, use YYYY-MM-DD format")
      .required(),
    endDate: yup
      .string()
      .trim()
      .matches(/\d{4}\-\d{2}\-\d{2}/, "Invalid date, use YYYY-MM-DD format")
      .required(),
    startBalance: yup.number().required(),
    endBalance: yup.number().required(),
  });

  const handleSubmit = (values) => {
    // Handle form submission logic here

    onSubmit(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Form.Group className="mx-1" controlId="startDate">
              {/* <Form.Label>Start Date</Form.Label> */}
              <Field
                type="text"
                name="account"
                placeholder="Account"
                component={formikInput}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mx-1" controlId="startDate">
              {/* <Form.Label>Start Date</Form.Label> */}
              <Field
                type="text"
                name="startDate"
                placeholder="Start Date"
                component={formikInput}
              />
            </Form.Group>
            <Form.Group className="mx-1" controlId="endDate">
              {/* <Form.Label>End Date</Form.Label> */}
              <Field
                type="text"
                name="endDate"
                placeholder="End Date"
                component={formikInput}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mx-1" controlId="startBalance">
              {/* <Form.Label>Start Date</Form.Label> */}
              <Field
                type="text"
                name="startBalance"
                placeholder="Start Balance"
                component={formikInput}
              />
            </Form.Group>
            <Form.Group className="mx-1" controlId="endBalance">
              {/* <Form.Label>End Date</Form.Label> */}
              <Field
                type="text"
                name="endBalance"
                placeholder="End Balance"
                component={formikInput}
              />
            </Form.Group>
          </Row>
          <Row>
            <div>
              <Button className="mx-1" variant="primary" type="submit">
                <FontAwesomeIcon icon="plus" />
                <span className="ml-1">Add New Period</span>
              </Button>
            </div>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default PeriodForm;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: {
  T: number;
  T2: number;
  T3: number;
  T4: number;
} = {
  T: 15, // TODO adjust
  T2: 50, // TODO adjust
  T3: 10,
  T4: 50, // TODO adjust
};

const slice = createSlice({
  name: "GenericOptions",
  initialState,
  reducers: {
    setT(state, action: PayloadAction<number>) {
      state.T = action.payload;
    },
    setT2(state, action: PayloadAction<number>) {
      state.T2 = action.payload;
    },
    setT3(state, action: PayloadAction<number>) {
      state.T3 = action.payload;
    },
    setT4(state, action: PayloadAction<number>) {
      state.T4 = action.payload;
    },
  },
});

export const { setT, setT2, setT3, setT4 } = slice.actions;

export default slice.reducer;

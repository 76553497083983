import React from "react";
import { Formik, Field } from "formik";
import { Form, Row } from "react-bootstrap";
import * as Yup from "yup";
import { formikReactSelectInput } from "../../../../../utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../../../redux";
import { Button, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setSelectedAccount } from "../../../../../redux/reducers/TransactionManager";
import { AccountForm2 } from "./NewAccount";
import { PeriodForm2 } from "./NewPeriod";
import { createAlert } from "../../../../../redux/reducers/Alerts";
import { performBulkEdit } from "../../../../../docuclipper/api";
import {
  fetchMfs,
  setSelectedRowIds,
  setTls,
  toggleLoading,
} from "../../../../../redux/reducers/TransactionManager";
import { fetchTotals } from "../../../../../redux/reducers/Reconciler";
import { setSelectedChunk } from "../../../../../redux/reducers/TransactionManager";
import uuidv4 from "uuid/v4";
import { useChunkData } from "../../../Analyze/AnalyzeTransactionsTable/hooks";

const validationSchema = Yup.object().shape({
  chunk: Yup.object().nullable(),
});

export const SelectAccount2 = ({
  transactionIds,
  setSelectionError,
  onDone,
}) => {
  const { job } = useSelector((state: ReduxState) => state.JobData);

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const {
      account,
      period,
      isNewAccount,
      isNewPeriod,
      periodId,
      startDate,
      endDate,
      startBalance,
      endBalance,
      chunk,
    } = values;

    let accountName = "";
    let chunk2 = "";
    if (chunk) {
      accountName = chunk.accountName;
      chunk2 = chunk.chunk;
    } else {
      accountName = account;
    }
    if (job) {
      dispatch(toggleLoading());
      performBulkEdit({
        jobId: job.id,
        operation: "assignToAccount",
        transactionIds,
        params: {
          account: {
            account: accountName,
            isNew: isNewAccount,
          },
          period: {
            chunk: chunk2,
            id: periodId,
            isNew: isNewPeriod,
            startDate,
            endDate,
            startBalance,
            endBalance,
          },
        },
      })
        .then(({ transactions }) => {
          dispatch(toggleLoading());
          setSubmitting(false);
          resetForm();
          toggleShowEditAccount();

          dispatch(setTls({ transactions, resetPages: false }));

          dispatch(fetchTotals());
          dispatch(fetchMfs());
          dispatch(setSelectedRowIds({}));

          dispatch(setSelectedAccount(account));
          dispatch(setSelectedChunk(period || periodId));

          dispatch(
            createAlert({
              message: "Correction made successfully",
              timeout: 0,
              type: "success",
            })
          );
          onDone();
        })
        .catch((err) => {
          dispatch(toggleLoading());
          setSubmitting(false);
          dispatch(
            createAlert({
              message: "Error assigning transactions to account",
              timeout: 0,
              type: "error",
            })
          );
        });
    }
  };

  const [showEditAccount, setShowEditAccount] = React.useState(false);
  const toggleShowEditAccount = () => {
    setShowEditAccount(!showEditAccount);
    setView("existing");
  };

  const dispatch = useDispatch();
  const [view, setView] = React.useState<
    "existing" | "newAccount" | "newPeriod"
  >("existing");
  const chunkData = useChunkData();
  return (
    <>
      <Button
        className="assign-to-account-btn"
        variant="light"
        disabled={transactionIds.length === 0}
        onClick={() => {
          if (transactionIds.length === 0) {
            setSelectionError("Select at least 1 row");
            return;
          }
          toggleShowEditAccount();
        }}
      >
        <FontAwesomeIcon icon="cog" />
        <span className="ml-1">Assign To Account/Period</span>
      </Button>

      <Formik
        enableReinitialize={true}
        initialValues={{
          account: "",
          period: "",
          chunk: null,
          isNewAccount: false,
          isNewPeriod: false,
          startDate: "",
          endDate: "",
          startBalance: "",
          endBalance: "",
          periodId: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, errors, submitForm, setFieldValue, values }) => {
          return (
            <Form>
              <Modal
                size="lg"
                show={showEditAccount}
                onHide={toggleShowEditAccount}
              >
                <Modal.Header closeButton>
                  <Modal.Title>Assign To Account/Period</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  {view === "existing" && (
                    <Row className="justify-content-end">
                      <Button
                        className="m-2"
                        variant="link"
                        onClick={() => setView("newAccount")}
                      >
                        <FontAwesomeIcon icon="plus"></FontAwesomeIcon>
                        <span className="ml-1">Add Account/Period</span>
                      </Button>
                    </Row>
                  )}
                  {view === "existing" && (
                    <div>
                      <strong>
                        <Form.Label>Account/Period</Form.Label>
                      </strong>
                      <Field
                        name="chunk"
                        component={formikReactSelectInput}
                        options={chunkData.map((chunk, i) => ({
                          value: chunk,
                          label: `${chunk.accountName} from ${chunk.startDate} to ${chunk.endDate} [${chunk.documentName} ]`,
                        }))}
                        onChange={(e) => {
                          setFieldValue("isNewAccount", false);
                        }}
                      ></Field>
                    </div>
                  )}
                  {view === "newAccount" && (
                    <div className="m-3">
                      <Button
                        className="mb-4"
                        variant="link"
                        onClick={() => setView("existing")}
                      >
                        <FontAwesomeIcon icon="angle-left" />
                        <span className="ml-1">Back</span>
                      </Button>
                      <AccountForm2
                        onSubmit={({
                          account,
                          startDate,
                          endDate,
                          startBalance,
                          endBalance,
                        }) => {
                          setFieldValue("account", account);
                          setFieldValue("isNewAccount", true);

                          setFieldValue("period", "");
                          setFieldValue("isNewPeriod", true);
                          setFieldValue("periodId", uuidv4());
                          setFieldValue("startDate", startDate);
                          setFieldValue("endDate", endDate);
                          setFieldValue("startBalance", startBalance);
                          setFieldValue("endBalance", endBalance);

                          submitForm();
                        }}
                      />
                    </div>
                  )}

                  {view === "newPeriod" && (
                    <div className="m-3">
                      <Button
                        className="mb-4"
                        variant="link"
                        onClick={() => setView("existing")}
                      >
                        <FontAwesomeIcon icon="angle-left" />
                        <span className="ml-1">Back</span>
                      </Button>
                      <PeriodForm2
                        onSubmit={({
                          startDate,
                          endDate,
                          account,
                          startBalance,
                          endBalance,
                        }) => {
                          setFieldValue("account", account);
                          setFieldValue("isNewAccount", false);
                          setFieldValue("period", "");
                          setFieldValue("periodId", uuidv4());
                          setFieldValue("isNewPeriod", true);
                          setFieldValue("startDate", startDate);
                          setFieldValue("endDate", endDate);
                          setFieldValue("startBalance", startBalance);
                          setFieldValue("endBalance", endBalance);

                          submitForm();
                        }}
                      />
                    </div>
                  )}
                </Modal.Body>
                {view === "existing" && (
                  <Modal.Footer>
                    <Button
                      onClick={() => submitForm()}
                      disabled={isSubmitting}
                    >
                      Assign
                    </Button>
                  </Modal.Footer>
                )}
              </Modal>
              <Field type="hidden" name="isNewAccount" />
              <Field type="hidden" name="isNewPeriod" />
              <Field type="hidden" name="periodId" />
              <Field type="hidden" name="startDate" />
              <Field type="hidden" name="endDate" />
              <Field type="hidden" name="startBalance" />
              <Field type="hidden" name="endBalance" />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SelectAccount2;

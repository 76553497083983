import { PayloadAction } from "@reduxjs/toolkit";
import {
  Template,
  TemplateFieldV2,
} from "../../../../docuclipper/DocuclipperTypes";
import { TemplateFieldState } from "../../TemplateFieldTypes";

export const _templateLoad = (state) => {
  state.template.loading = true;
  state.template.error = null;
};
export const _templateSetData = (
  state: TemplateFieldState,
  action: PayloadAction<{ template: Partial<Template> }>
) => {
  state.template.loading = false;
  state.template.template = {
    ...(state.template.template || {}),
    ...action.payload.template,
  };
};
export const _templateSetError = (
  state: TemplateFieldState,
  action: PayloadAction<string>
) => {
  state.template.loading = false;
  state.template.error = action.payload;
};

export const _templateClear = (
  state: TemplateFieldState,
  action: PayloadAction<void>
) => {
  state.template.template = null;
};

export const _setTemplateFields = (
  state: TemplateFieldState,
  action: PayloadAction<TemplateFieldV2[]>
) => {
  state.templateFields = action.payload;
};

import React from "react";
import { Col, Row, Alert } from "react-bootstrap";
import TrackedButton from "../../Docuclipper/TrackedButton";
import withLoading from "../../../hoc/withLoading";
import {
  Integration,
  IntegrationsInfo,
} from "../../../docuclipper/DocuclipperTypes";
import { goTo } from "../../../utils/utils";
import { getJwt } from "../../../auth";
import { AvatarItem } from "@atlaskit/avatar";

const LoadingBtn = withLoading(TrackedButton);

type Props = {
  type: "quickbooks" | "googleSpreadsheets" | "gmail";
  integrations: Integration[];
  integrationsInfo: IntegrationsInfo;
  testFn: (type: string, id: string) => any;
  deleteFn: (integrationId: string) => any;
};

export const IntegrationActions: React.SFC<Props> = ({
  type,
  integrations,
  integrationsInfo,
  testFn,
  deleteFn,
}) => {
  const [loading, setLoading] = React.useState({});

  let testBtnTest;
  switch (type) {
    case "googleSpreadsheets":
      testBtnTest = "Create test spreadsheet";
      break;
    case "quickbooks":
      testBtnTest = "Test QuickBooks service";
      break;
    case "gmail":
      testBtnTest = "Test gmail service";
  }

  let actions: any = null;

  actions = integrations.map((integration, i) => {
    const onClick = (operation: "Test" | "Disconnect") => {
      return () => {
        setLoading({ ...loading, [`${type}${i}${operation}`]: true });
        let fn;
        switch (operation) {
          case "Test":
            fn = () => testFn(type, integration.id);
            break;
          case "Disconnect":
            fn = () => deleteFn(integration.id);
        }
        fn()
          .then(() => {
            setLoading({ ...loading, [`${type}${i}${operation}`]: false });
          })
          .catch(() => {
            setLoading({ ...loading, [`${type}${i}${operation}`]: false });
          });
      };
    };

    const testButton = (
      <LoadingBtn
        isLoading={loading[`${type}${i}Test`]}
        action={`testIntegration`}
        label={type}
        onClick={onClick("Test")}
        variant="link"
      >
        {testBtnTest}
      </LoadingBtn>
    );

    const extraParams = JSON.parse(integration.extraParams || "{}");
    let btn: any = null;

    let disconnectBtn: JSX.Element | null = null;
    if (type === "quickbooks") {
      disconnectBtn = (
        <LoadingBtn
          isLoading={loading[`${type}${i}Disconnect`]}
          action="integrationDisconnectClick"
          label={type}
          variant="link"
          onClick={onClick("Disconnect")}
        >
          Disconnect
        </LoadingBtn>
      );
    }

    const fixIntegrationFn = () =>
      goTo(`${integrationsInfo[type].url}${`&token=${getJwt()}`}`);

    let msg: any = null;
    if (integration.errorCode !== null) {
      msg = (
        <span
          style={{ display: "block", marginBottom: "10px" }}
          className="ml-1"
        >{`There's a problem with the integration`}</span>
      );
      let buttonText = "Fix";
      switch (integration.errorMessage) {
        case "invalid_grant":
          msg = (
            <span
              style={{ display: "block", marginBottom: "10px" }}
              className="ml-1"
            >{`Credentials are invalid or expired`}</span>
          );
          buttonText = "Renew credentials";
      }
      btn = (
        <TrackedButton
          action="fixIntegrationClick"
          label={type}
          variant="warning"
          onClick={fixIntegrationFn}
        >
          {buttonText}
        </TrackedButton>
      );
    }

    let image: any = null;
    if (
      extraParams.photos &&
      extraParams.photos.length > 0 &&
      extraParams.photos[0].value
    ) {
      image = (
        <img
          width={24}
          height={24}
          className="rounded-circle"
          src={
            extraParams.photos && extraParams.photos.length > 0
              ? extraParams.photos[0].value
              : ""
          }
          alt=""
        />
      );
    }

    const quickbooksAccount = <b>{extraParams.companyName || ""}</b>;
    const gdocsAccount = (
      <AvatarItem
        avatar={image}
        primaryText={extraParams.displayName || ""}
        secondaryText={extraParams.email || ""}
      />
    );

    return (
      <>
        <Row
          style={{ marginTop: "15px", display: "flex", alignItems: "center" }}
          key={`${i}1`}
        >
          <Col md="6" className="text-truncate">
            {type === "quickbooks" ? quickbooksAccount : gdocsAccount}
          </Col>
          <Col md="6">
            {msg}
            <div>
              {btn}
              {disconnectBtn}
              {testButton}
            </div>
          </Col>
        </Row>
      </>
    );
  });

  if (actions.length === 0) {
    let msg;
    switch (type) {
      case "quickbooks":
        msg = "You haven't connected any QuickBooks companies";
        break;
      case "googleSpreadsheets":
        msg = "You haven't connected any Google Docs accounts";
        break;
      case "gmail":
        msg = "You haven't connected any Gmail accounts";
        break;
    }
    return <Alert variant="danger">{msg}</Alert>;
  }
  return actions;
};

export default IntegrationActions;

import React, { useCallback, useMemo, useRef, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import {
  approveMatchingTransactions,
  getJobMatchingTransactions,
  matchTransactions,
  rejectMatchingTransactions,
} from "../../../../docuclipper/api";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../../redux";
import AgTable from "../AnalyzeTransactionsTable/AgTable";
import { convertTlsToTable } from "../AnalyzeTransactionsTable/utils";
import { useHasQbo } from "../../../../utils/utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TransactionLine } from "../../../../docuclipper/DocuclipperTypes";
import { fetchMatchedTransactions } from "../../../../redux/reducers/MatchedTransactions";
import { AgGridReact } from "ag-grid-react";
import CreateDoubleMatch from "./CreateDoubleMatch";
type Props = {};
export const MatchingTransactions: React.FC<Props> = ({}) => {
  const dispatch = useDispatch();
  const { job } = useSelector((state: ReduxState) => state.JobData);
  const { loading, matches } = useSelector(
    (state: ReduxState) => state.MatchedTransactions
  );

  React.useEffect(() => {
    dispatch(fetchMatchedTransactions());
  }, []);

  const [columnDefs] = useState([
    {
      field: "account1",
      width: 150,
    },
    {
      field: "date1",
      width: 120,
    },
    {
      field: "description1",
    },
    {
      field: "amount1",
      width: 100,
    },
    {
      field: "account2",
      width: 150,
    },
    {
      field: "date2",
      width: 120,
    },
    {
      field: "description2",
    },
    {
      field: "amount2",
      width: 100,
    },
    {
      field: "matchStatus",
      headerName: "Status",
      width: 100,
    },
    {
      field: "actions",
      width: 400,
      cellRenderer: (params, element) => {
        const { matchStatus } = params.data;
        const approveButton = (
          <Button
            variant="link"
            onClick={() => {
              if (job) {
                approveMatchingTransactions(job.id, params.data.matchId)
                  .then(() => dispatch(fetchMatchedTransactions()))
                  .catch((err) => null);
              }
            }}
          >
            <FontAwesomeIcon icon="check" />
            <span className="ml-1">Approve</span>
          </Button>
        );
        const rejectButton = (
          <Button
            variant="link"
            onClick={() => {
              if (job) {
                rejectMatchingTransactions(job.id, params.data.matchId)
                  .then(() => dispatch(fetchMatchedTransactions()))
                  .catch((err) => null);
              }
            }}
          >
            <FontAwesomeIcon icon="times" />
            <span className="ml-1">Reject</span>
          </Button>
        );
        if (matchStatus === "pending") {
          return (
            <>
              {approveButton}
              {rejectButton}
            </>
          );
        } else if (matchStatus === "approved") {
          return <>{rejectButton}</>;
        } else if (matchStatus === "rejected") {
          return <>{approveButton}</>;
        }
      },
    },
  ]);

  const hasQbo = useHasQbo();

  const { tls, selectedDocumentId, pageIndex, accountChunkLabels } =
    useSelector((state: ReduxState) => state.TransactionManager);
  const {
    tls: transactions,
    loading: tlsLoading,
    error: tlsError,
    documents,
  } = tls;

  const rowData = matches.map((x) => {
    const { match, transactions } = x;
    const [t1, t2] = transactions;
    const tt1 = t1
      ? convertTlsToTable([t1], documents, job, hasQbo, accountChunkLabels)[0]
      : null;
    const tt2 = t2
      ? convertTlsToTable([t2], documents, job, hasQbo, accountChunkLabels)[0]
      : null;
    return {
      matchId: match.id,
      matchStatus: match.status,
      account1: tt1?.account,
      date1: tt1?.date,
      description1: tt1?.description,
      amount1: tt1?.amount,
      account2: tt2?.account,
      date2: tt2?.date,
      description2: tt2?.description,
      amount2: tt2?.amount,
    };
  });

  const ref = useRef();
  const onBtnExport = useCallback(() => {
    if (ref && ref.current) {
      (ref.current as any).api.exportDataAsCsv();
    }
  }, []);

  // React.useEffect(() => {
  //   if (ref && ref.current && (ref.current as any).api) {
  //     (ref.current as any).api.setSortModel({
  //       colId: "matchId",
  //       sort: "asc",
  //     });
  //   }
  // }, [ref, ref.current]);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
    };
  }, []);

  const [showCreateTransfer, setShowCreateTransfer] = React.useState(false);

  return (
    <div>
      <Row className="justify-content-end m-2">
        <Button
          variant="link"
          onClick={() => setShowCreateTransfer(!showCreateTransfer)}
        >
          <FontAwesomeIcon icon={showCreateTransfer ? "minus" : "plus"} />
          <span className="ml-1">
            {showCreateTransfer ? "Close Create Transfer" : "Create Transfer"}
          </span>
        </Button>
        <Button
          onClick={() => {
            if (job) {
              matchTransactions(job.id, 5)
                .then((rsp) => dispatch(fetchMatchedTransactions()))
                .catch((err) => null);
            }
          }}
        >
          <FontAwesomeIcon icon="forward" />
          <span className="ml-1">Detect Transfers</span>
        </Button>
      </Row>

      {showCreateTransfer && (
        <div className="mb-4">
          <CreateDoubleMatch />
        </div>
      )}

      <div className="h3 mt-4">Transfers</div>

      <>
        <Row>
          <Col>
            <Button variant="link" onClick={onBtnExport}>
              <FontAwesomeIcon icon="download" />
              <span className="ml-1">Export as CSV</span>
            </Button>
          </Col>
        </Row>
        <Row>
          <div
            className="ag-theme-alpine"
            style={{ height: 800, width: "100%" }}
          >
            <AgGridReact
              defaultColDef={defaultColDef}
              colResizeDefault="shift"
              singleClickEdit
              ref={ref as any}
              rowData={rowData}
              columnDefs={columnDefs}
            ></AgGridReact>
          </div>
        </Row>
      </>
    </div>
  );
};
export default MatchingTransactions;

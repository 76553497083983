import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../../redux";
import { Button, Col, Row, Form, Modal, Alert } from "react-bootstrap";
import CategoriesPieChart from "./PieChart";
import { TagRegexes } from "./TagRegexes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  addNewCategoryGroup,
  fetchPieChart,
  fetchTagRegexes,
} from "../../../../redux/reducers/TagRegexes";
import { categorizeAction } from "../../../../redux/reducers/Categories";
import { AnalyzeTransactionsTable } from "../AnalyzeTransactionsTable";
import AgTable from "../AnalyzeTransactionsTable/AgTable";
import { useHistory } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { categorizeActionAsync } from "../../../../redux/reducers/CategoriesAsync";
import { useIsAdmin } from "../../../../utils/auth";
// import SupersetDash from "./SupersetPieChart.tsx.disabled";
import { DownloadModal } from "../../DataArchive/TransactionManager/DownloadModal";
import { TagCategoryRule } from "src/docuclipper/DocuclipperTypes";
import CategorizationTour from "../../DataArchive/TransactionManager/CategorizationTour";
import AllAccountsSelector from "../AnalyzeTransactionsTable/AllAccountsSelector";

export const Categorize: React.FC = () => {
  const [groupName, setGroupName] = useState("");
  const [dashboardReloadTrigger, setDashboardReloadTrigger] = useState(true);

  const dispatch = useDispatch();

  const {
    selectedTagRegexesSet,
    tagRegexesSets,
    loading: tagRegexesSetsLoading,
  } = useSelector((state: ReduxState) => state.TagRegexes);

  const { job } = useSelector((state: ReduxState) => state.JobData);
  const { categories, byCategoryKeyword } = useSelector(
    (state: ReduxState) => state.Categories
  );

  const { loading } = useSelector((state: ReduxState) => state.AsyncCategories);
  const { pieLoading } = useSelector((state: ReduxState) => state.TagRegexes);
  const { selectedAccount } = useSelector(
    (state: ReduxState) => state.Reconciler
  );

  const jobId = job ? job.id : "";

  const history = useHistory();

  React.useEffect(() => {
    dispatch(fetchTagRegexes("categorize"));
  }, []);
  React.useEffect(() => {
    if (selectedAccount) {
      dispatch(fetchPieChart(selectedAccount));
    }
  }, [selectedAccount]);

  React.useEffect(() => {
    if (selectedTagRegexesSet && categories.length === 0) {
      dispatch(categorizeAction());
    }
  }, []);

  const { isAdmin } = useIsAdmin();

  const [showNewGroup, setShowNewGroup] = useState(false);
  const toggleAddNewCategoryGroup = () => setShowNewGroup(!showNewGroup);

  const [rowData, setRowData] = useState<{ name; value; color }[]>([]);
  React.useEffect(() => {
    setRowData(categories);
  }, [JSON.stringify(categories)]);

  const [columnDefs] = useState([
    {
      headerName: "Category",
      field: "name",
      filter: "agTextColumnFilter",
      flex: 2,
    },
    {
      headerName: "Amount $",
      field: "value",
      filter: "agNumberColumnFilter",
      flex: 1,
      sortable: true,
      sort: "desc",
    },
  ]);

  const lsKey = "docuclipper.ui.selectedCategoryGroup";

  const handleAddNewCategoryGroup = () => {
    const rule: TagCategoryRule = {
      keywords: [],
      negativeKeywords: [],
      tag: groupName.trim(),
    };
    dispatch(addNewCategoryGroup(groupName, rule));
    localStorage.setItem(lsKey, groupName);
    toggleAddNewCategoryGroup();
  };

  useEffect(() => {
    setDashboardReloadTrigger(!loading);
  }, [loading]);

  useEffect(() => {
    if (showNewGroup) {
      setGroupName("");
    }
  }, [showNewGroup]);

  // console.log({ categories, byCategoryKeyword });
  return (
    <div className="m-2">
      <Row className="justify-content-end m-2 align-items-center">
        <CategorizationTour />
        <a
          href="https://www.youtube.com/watch?v=alrd8zCClOw&authuser=0"
          target="_blnk"
          rel="noopener noreferrer"
        >
          <div className="mx-2 div--help-video">
            <FontAwesomeIcon icon="play" />
            <span className="ml-2">Watch Categorization Demo</span>
          </div>
        </a>
        <Button
          className="categorization-tour-add-group"
          variant="link"
          onClick={toggleAddNewCategoryGroup}
        >
          <FontAwesomeIcon icon="plus" />
          <span className="ml-1" data-cy="add-new-category-group-link">
            Add New Category Group
          </span>
        </Button>
        <Button
          variant="link"
          onClick={() => {
            history.push("/extract/vendorscsv");
          }}
          className="categorization-tour-upload-vendors"
        >
          <FontAwesomeIcon icon="upload" />
          <span className="ml-1" data-cy="upload-vendor-category-link">
            Upload Vendor/Category CSV
          </span>
        </Button>
        <Modal show={showNewGroup} onHide={toggleAddNewCategoryGroup}>
          <Modal.Header closeButton>
            <Modal.Title>Add New Category Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Category Group Name</Form.Label>
                  <Form.Control
                    name="groupName"
                    value={groupName}
                    onChange={(e) => {
                      setGroupName(e.target.value);
                    }}
                    type="text"
                  />
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={toggleAddNewCategoryGroup}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={handleAddNewCategoryGroup}
              className=""
            >
              <FontAwesomeIcon icon="plus" />
              <span
                className="ml-1"
                data-cy="add-new-category-group-modal-button"
              >
                Add New Category Group
              </span>
            </Button>
          </Modal.Footer>
        </Modal>
      </Row>

      {tagRegexesSets.length === 0 && !tagRegexesSetsLoading && (
        <Alert variant="danger">
          You don't have any category groups. Click on "Add New Category Group"
          to create one.
        </Alert>
      )}
      {tagRegexesSets.length > 0 && (
        <>
          <Row className="align-items-center">
            <Col md="6">
              <TagRegexes />
            </Col>
            <Col md="4">
              {!loading && (
                <Button
                  style={{ marginTop: "-1rem" }}
                  className="categorization-tour-categorize "
                  onClick={() => {
                    if (jobId && selectedTagRegexesSet) {
                      dispatch(categorizeActionAsync());
                    }
                  }}
                >
                  Categorize
                </Button>
              )}
              {loading && <ClipLoader />}
            </Col>
            <Col md="2">
              <div style={{ marginTop: "-1rem" }}>
                <DownloadModal />
              </div>
            </Col>
          </Row>
          {/* {false && (
            <div>
              <SupersetDash
                dashboardName={"categorizePieChart"}
                height={"400px"}
                width={"100%"}
                reload={dashboardReloadTrigger}
              />
            </div>
          )} */}
          {
            <Row className="mt-5">
              <AllAccountsSelector
                onAccountChange={(newAccount) => {
                  dispatch(fetchPieChart(newAccount));
                }}
              />
            </Row>
          }
          {true && categories.length > 0 && (
            <Row>
              <Col md={6}>
                <Row>
                  <Col className="mt-4">
                    <h3>Category Breakdown</h3>
                    {categories.length > 0 && (
                      <>
                        <AgTable
                          className="categorization-tour-summary"
                          data-cy="categorization-table"
                          hasExport={true}
                          rowData={rowData}
                          columnDefs={columnDefs}
                          width={"100%"}
                          height={200}
                        />
                      </>
                    )}
                  </Col>
                  <Col style={{ height: "300px" }}>
                    {!pieLoading && <CategoriesPieChart data={categories} />}
                    {pieLoading && <ClipLoader />}
                  </Col>
                </Row>
              </Col>

              <Col md={6}>
                <Row>
                  <Col className="mt-4">
                    <h3>Vendor Breakdown</h3>
                    <AgTable
                      hasExport={true}
                      rowData={byCategoryKeyword}
                      columnDefs={[
                        {
                          headerName: "Keyword",
                          field: "name",
                          flex: 2,
                        },
                        {
                          headerName: "Amount $",
                          field: "value",
                          flex: 1,
                          sortable: true,
                          sort: "desc",
                        },
                      ]}
                      width={"100%"}
                      height={200}
                    />
                  </Col>
                  <Col style={{ height: "300px" }}>
                    {!pieLoading && (
                      <CategoriesPieChart data={byCategoryKeyword} />
                    )}
                    {pieLoading && <ClipLoader />}
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </>
      )}

      <div className="m-2">
        <AnalyzeTransactionsTable
          setDashboardReloadTrigger={setDashboardReloadTrigger}
        />
      </div>
    </div>
  );
};
export default Categorize;

import queryString from "query-string";
import React from "react";
import { Alert } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import ClipLoader from "react-spinners/ClipLoader";
import {
  DocuClipperPlanPricing,
  DocuClipperPricing,
  StripeCustomer,
  StripePlan,
  StripeSubscription,
} from "../../../docuclipper/DocuclipperTypes";
import { ReduxState } from "../../../redux";
import { fetchPlans } from "../../../redux/reducers/Plans";
import { fetchSubscription } from "../../../redux/reducers/Subscription";

export const getDiscountedPricing = (
  originalPricing: DocuClipperPricing,
  subscription: StripeSubscription
) => {
  if (!subscription || !subscription.coupon) {
    return null;
  }
  const percent_off = subscription.coupon.percent_off;
  if (!percent_off) {
    return null;
  }
  const discountedPricing: DocuClipperPricing = {} as any;
  for (const plan in originalPricing) {
    for (const billingCycle of [
      "Monthly",
      "Annually",
      "AnnuallyBilledMonthly",
    ]) {
      if (!(plan in discountedPricing)) {
        discountedPricing[plan] = {};
      }
      if (!(billingCycle in discountedPricing[plan])) {
        discountedPricing[plan][billingCycle] = {};
      }
      const obj: DocuClipperPlanPricing = discountedPricing[plan][billingCycle];
      obj.amount = Math.round(
        (originalPricing[plan][billingCycle].amount * (100 - percent_off)) / 100
      );
      obj.annualSavings =
        billingCycle === "Monthly"
          ? null
          : Math.round(
              (originalPricing[plan][billingCycle].annualSavings *
                percent_off) /
                100
            );
      obj.discountSavings = Math.round(
        (originalPricing[plan][billingCycle].discountSavings *
          (100 - percent_off)) /
          100
      );
      obj.discountPercent = originalPricing[plan][billingCycle].discountPercent;
    }
  }
  return discountedPricing;
};

export const getPricing = (plans: StripePlan[]) => {
  plans = plans || [];
  const starterMonthly = plans.filter(
    (x) =>
      x.docuclipperPlan.name === "Starter" &&
      x.docuclipperPlan.billingCycle === "Monthly"
  );
  const starterAnnually = plans.filter(
    (x) =>
      x.docuclipperPlan.name === "Starter" &&
      x.docuclipperPlan.billingCycle === "Annually"
  );
  const starter100Monthly = plans.filter(
    (x) =>
      x.docuclipperPlan.name === "Starter100" &&
      x.docuclipperPlan.billingCycle === "Monthly"
  );
  const starter100Annually = plans.filter(
    (x) =>
      x.docuclipperPlan.name === "Starter100" &&
      x.docuclipperPlan.billingCycle === "Annually"
  );
  const professionalMonthly = plans.filter(
    (x) =>
      x.docuclipperPlan.name === "Professional" &&
      x.docuclipperPlan.billingCycle === "Monthly"
  );
  const professionalAnnually = plans.filter(
    (x) =>
      x.docuclipperPlan.name === "Professional" &&
      x.docuclipperPlan.billingCycle === "Annually"
  );

  const businessMonthly = plans.filter(
    (x) =>
      x.docuclipperPlan.name === "Business" &&
      x.docuclipperPlan.billingCycle === "Monthly"
  );
  const businessAnnually = plans.filter(
    (x) =>
      x.docuclipperPlan.name === "Business" &&
      x.docuclipperPlan.billingCycle === "Annually"
  );
  const enterpriseMonthly = plans.filter(
    (x) =>
      x.docuclipperPlan.name === "Enterprise" &&
      x.docuclipperPlan.billingCycle === "Monthly"
  );
  const enterpriseAnnually = plans.filter(
    (x) =>
      x.docuclipperPlan.name === "Enterprise" &&
      x.docuclipperPlan.billingCycle === "Annually"
  );

  if (
    starterMonthly.length === 0 ||
    starterAnnually.length === 0 ||
    businessMonthly.length === 0 ||
    businessAnnually.length === 0 ||
    enterpriseMonthly.length === 0 ||
    enterpriseAnnually.length === 0 ||
    professionalMonthly.length === 0 ||
    professionalAnnually.length === 0
  ) {
    return null;
  }

  const starterAnnualSavingsAmount =
    (starterMonthly[0].amount / 100) * 12 - starterAnnually[0].amount / 100;
  const starterAnnualSavingsPercentage =
    (100 * starterAnnually[0].amount) / (starterMonthly[0].amount * 12);

  const starter100AnnualSavingsAmount =
    (starter100Monthly[0].amount / 100) * 12 -
    starter100Annually[0].amount / 100;
  const starter100AnnualSavingsPercentage =
    (100 * starter100Annually[0].amount) / (starter100Monthly[0].amount * 12);

  const businessAnnualSavingsAmount =
    (businessMonthly[0].amount / 100) * 12 - businessAnnually[0].amount / 100;
  const businessAnnualSavingsPercentage =
    (100 * businessAnnually[0].amount) / (businessMonthly[0].amount * 12);

  const professionalAnnualSavingsAmount =
    (professionalMonthly[0].amount / 100) * 12 -
    professionalAnnually[0].amount / 100;
  const professionalAnnualSavingsPercentage =
    (100 * professionalAnnually[0].amount) /
    (professionalMonthly[0].amount * 12);

  const enterpriseAnnualSavingsAmount =
    (enterpriseMonthly[0].amount / 100) * 12 -
    enterpriseAnnually[0].amount / 100;
  const enterpriseAnnualSavingsPercentage =
    (100 * enterpriseAnnually[0].amount) / (enterpriseMonthly[0].amount * 12);

  const starterAnnuallyBilledMonthly = plans.filter(
    (x) =>
      x.docuclipperPlan.name === "Starter" &&
      x.docuclipperPlan.billingCycle === "AnnuallyBilledMonthly"
  );
  const starter100AnnuallyBilledMonthly = plans.filter(
    (x) =>
      x.docuclipperPlan.name === "Starter" &&
      x.docuclipperPlan.billingCycle === "AnnuallyBilledMonthly"
  );

  const professionalAnnuallyBilledMonthly = plans.filter(
    (x) =>
      x.docuclipperPlan.name === "Professional" &&
      x.docuclipperPlan.billingCycle === "AnnuallyBilledMonthly"
  );
  const businessAnnuallyBilledMonthly = plans.filter(
    (x) =>
      x.docuclipperPlan.name === "Business" &&
      x.docuclipperPlan.billingCycle === "AnnuallyBilledMonthly"
  );
  const enterpriseAnnuallyBilledMonthly = plans.filter(
    (x) =>
      x.docuclipperPlan.name === "Enterprise" &&
      x.docuclipperPlan.billingCycle === "AnnuallyBilledMonthly"
  );

  const starterAnnuallyBilledMonthlySavingsAmount =
    ((starterMonthly[0].amount - starterAnnuallyBilledMonthly[0].amount) * 12) /
    100;

  const starterAnnuallyBilledMonthlySavingsPercentage =
    (100 * starterAnnuallyBilledMonthly[0].amount) /
    (starterMonthly[0].amount * 12);

  const starter100AnnuallyBilledMonthlySavingsAmount =
    ((starter100Monthly[0].amount - starter100AnnuallyBilledMonthly[0].amount) *
      12) /
    100;

  const starter100AnnuallyBilledMonthlySavingsPercentage =
    (100 * starter100AnnuallyBilledMonthly[0].amount) /
    (starter100Monthly[0].amount * 12);

  const professionalAnnuallyBilledMonthlySavingsAmount =
    ((professionalMonthly[0].amount -
      professionalAnnuallyBilledMonthly[0].amount) *
      12) /
    100;

  const professionalAnnuallyBilledMonthlySavingsPercentage =
    (100 * professionalAnnuallyBilledMonthly[0].amount) /
    (professionalMonthly[0].amount * 12);

  const businessAnnuallyBilledMonthlySavingsAmount =
    ((businessMonthly[0].amount - businessAnnuallyBilledMonthly[0].amount) *
      12) /
    100;

  const businessAnnuallyBilledMonthlySavingsPercentage =
    (100 * businessAnnuallyBilledMonthly[0].amount) /
    (businessMonthly[0].amount * 12);

  const enterpriseAnnuallyBilledMonthlySavingsAmount =
    ((enterpriseMonthly[0].amount - enterpriseAnnuallyBilledMonthly[0].amount) *
      12) /
    100;

  const enterpriseAnnuallyBilledMonthlySavingsPercentage =
    (100 * enterpriseAnnuallyBilledMonthly[0].amount) /
    (enterpriseMonthly[0].amount * 12);

  const pricing: DocuClipperPricing = {
    Business: {
      Monthly: {
        amount: businessMonthly[0].amount / 100,
        currency: businessMonthly[0].currency,
        annualSavings: null,
        discountSavings: null,
        discountPercent: null,
      },
      Annually: {
        amount: businessAnnually[0].amount / 100,
        currency: businessAnnually[0].currency,
        annualSavings: businessAnnualSavingsAmount,
        discountSavings: null,
        discountPercent: businessAnnualSavingsPercentage,
      },
      AnnuallyBilledMonthly: {
        amount: businessAnnuallyBilledMonthly[0].amount / 100,
        currency: businessAnnuallyBilledMonthly[0].currency,
        annualSavings: businessAnnuallyBilledMonthlySavingsAmount,
        discountSavings: null,
        discountPercent: businessAnnuallyBilledMonthlySavingsPercentage,
      },
    },
    Starter: {
      Monthly: {
        amount: starterMonthly[0].amount / 100,
        currency: starterMonthly[0].currency,
        annualSavings: null,
        discountSavings: null,
        discountPercent: null,
      },
      Annually: {
        amount: starterAnnually[0].amount / 100,
        currency: starterAnnually[0].currency,
        annualSavings: starterAnnualSavingsAmount,
        discountSavings: null,
        discountPercent: starterAnnualSavingsPercentage,
      },
      AnnuallyBilledMonthly: {
        amount: starterAnnuallyBilledMonthly[0].amount / 100,
        currency: starterAnnuallyBilledMonthly[0].currency,
        annualSavings: starterAnnuallyBilledMonthlySavingsAmount,
        discountSavings: null,
        discountPercent: starterAnnuallyBilledMonthlySavingsPercentage,
      },
    },
    Starter100: {
      Monthly: {
        amount: starter100Monthly[0].amount / 100,
        currency: starter100Monthly[0].currency,
        annualSavings: null,
        discountSavings: null,
        discountPercent: null,
      },
      Annually: {
        amount: starter100Annually[0].amount / 100,
        currency: starter100Annually[0].currency,
        annualSavings: starter100AnnualSavingsAmount,
        discountSavings: null,
        discountPercent: starter100AnnualSavingsPercentage,
      },
      AnnuallyBilledMonthly: {
        amount: starter100AnnuallyBilledMonthly[0].amount / 100,
        currency: starter100AnnuallyBilledMonthly[0].currency,
        annualSavings: starter100AnnuallyBilledMonthlySavingsAmount,
        discountSavings: null,
        discountPercent: starter100AnnuallyBilledMonthlySavingsPercentage,
      },
    },
    Enterprise: {
      Monthly: {
        amount: enterpriseMonthly[0].amount / 100,
        currency: enterpriseMonthly[0].currency,

        annualSavings: null,
        discountSavings: null,
        discountPercent: null,
      },
      Annually: {
        amount: enterpriseAnnually[0].amount / 100,
        currency: enterpriseAnnually[0].currency,

        annualSavings: enterpriseAnnualSavingsAmount,
        discountSavings: null,
        discountPercent: enterpriseAnnualSavingsPercentage,
      },
      AnnuallyBilledMonthly: {
        amount: enterpriseAnnuallyBilledMonthly[0].amount / 100,
        currency: enterpriseAnnuallyBilledMonthly[0].currency,
        annualSavings: enterpriseAnnuallyBilledMonthlySavingsAmount,
        discountSavings: null,
        discountPercent: enterpriseAnnuallyBilledMonthlySavingsPercentage,
      },
    },
    Professional: {
      Monthly: {
        amount: professionalMonthly[0].amount / 100,
        currency: professionalMonthly[0].currency,
        annualSavings: null,
        discountSavings: null,
        discountPercent: null,
      },
      Annually: {
        amount: professionalAnnually[0].amount / 100,
        currency: professionalAnnually[0].currency,

        annualSavings: professionalAnnualSavingsAmount,
        discountSavings: null,
        discountPercent: professionalAnnualSavingsPercentage,
      },
      AnnuallyBilledMonthly: {
        amount: professionalAnnuallyBilledMonthly[0].amount / 100,
        currency: professionalAnnuallyBilledMonthly[0].currency,
        annualSavings: professionalAnnuallyBilledMonthlySavingsAmount,
        discountSavings: null,
        discountPercent: professionalAnnuallyBilledMonthlySavingsPercentage,
      },
    },
  };
  return pricing;
};

export function WithAccountAndPlans(WrappedComponent) {
  return function _WithAccountAndPlans({ location, ...props }): JSX.Element {
    const {
      subscription,
      loading: subscriptionLoading,
      error: subscriptionError,
    } = useSelector((state: ReduxState) => state.Subscription);

    const {
      plans,
      loading: plansLoading,
      error: plansError,
    } = useSelector((state: ReduxState) => state.Plans);

    const dispatch = useDispatch();

    const { coupon } = queryString.parse(location.search);

    React.useEffect(() => {
      dispatch(fetchPlans(coupon as string));
      dispatch(fetchSubscription());
    }, [dispatch]);

    if (subscriptionLoading || plansLoading) {
      return (
        <ClipLoader
          // sizeUnit={"px"}
          size={35}
          // color={"#123abc"}
          loading={true}
        />
      );
    }

    if (!subscription || subscriptionError) {
      return (
        <Alert variant="danger">
          {subscriptionError || "Error getting subscription"}
        </Alert>
      );
    }
    if (plans.length === 0 || plansError) {
      return (
        <Alert variant="danger">
          {subscriptionError || "Error getting plans"}
        </Alert>
      );
    }
    return (
      <WrappedComponent
        {...props}
        subscription={subscription}
        plans={plans}
      ></WrappedComponent>
    );
  };
}

export const getDefaultCard = (customer: StripeCustomer) => {
  if (!customer) {
    return null;
  }
  const defaultSource = customer.default_source;
  if (defaultSource) {
    const maybeSource = customer.sources.data.filter(
      (x) => x.id === defaultSource
    );
    if (maybeSource.length > 0) {
      return maybeSource[0];
    }
  }
  return null;
};

import * as Sentry from "@sentry/browser";
import React from "react";
import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/stable";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import "react-table-6/react-table.css";
import App from "./App";
import "./index.css";
import { initIcons } from "./init";
// import 'react-app-polyfill/ie11'; // For IE 11 support
import "./polyfill";
import store from "./redux";
import * as serviceWorker from "./serviceWorker";
// import "./wdyr";

// import "bootstrap/dist/css/bootstrap.min.css";

initIcons();

// setupGA();
try {
  if (process.env.NODE_ENV === "production") {
    Sentry.init({
      dsn: "https://2551d5b5cce1443dae955ed0d2e9676a@o395817.ingest.sentry.io/5248298",
      release: process.env.DOCUCLIPPER_SHA,
      environment: process.env.NODE_ENV,
      integrations: [Sentry.replayIntegration()],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });
  }
} catch (error) {
  // console.log("Sentry error", error);
}

ReactDOM.render(
  <Provider store={store}>
    {/* <div style={{ maxWidth: "1600px" }}> */}
    <App />
    {/* </div> */}
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

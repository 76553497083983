import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExportType } from "../../docuclipper/DocuclipperTypes";
import { lsGetItem, lsSetItem } from "src/LocalStorageWrapper";

export const lsKeySelectedExportType = `docuclipper.prefs.selectedInvoiceExportType`
export const lsKeySelectedExportTypeModal = `docuclipper.prefs.selectedInvoiceExportTypeModal`
export const lsKeySelectedCustomFormatType = `docuclipper.prefs.selectedInvoiceCustomFormatType`

const initialState: {
  selectedExportType: ExportType;
  selectedExportTypeModal: ExportType;
  selectedCustomFormatType: string;
  sortBy: string;
  sortDirection: string;
  reloadExportPreview: boolean;
  options: { [k: string]: string };
} = {
  selectedExportType: lsGetItem(lsKeySelectedExportType) as ExportType || "csv",
  selectedExportTypeModal: lsGetItem(lsKeySelectedExportTypeModal) as ExportType || "csv",
  selectedCustomFormatType: lsGetItem(lsKeySelectedCustomFormatType) || "custom-all",
  sortBy: "Invoice date",
  sortDirection: "asc",
  reloadExportPreview: true,
  options: {
    "docuclipper.prefs.dateFormat": "YYYY-MM-DD",
  },
};

const slice = createSlice({
  name: "DownloadInvoiceOptions",
  initialState,
  reducers: {
    setSelectedExportType(
      state,
      action: PayloadAction<ExportType>
    ) {
      state.selectedExportType = action.payload;
      lsSetItem(lsKeySelectedExportType, action.payload);
    },
    setSelectedExportTypeModal(
      state,
      action: PayloadAction<ExportType>
    ) {
      state.selectedExportTypeModal = action.payload;
      lsSetItem(lsKeySelectedExportTypeModal, action.payload);
    },
    setSelectedCustomFormatType(
      state,
      action: PayloadAction<string>
    ) {
      state.selectedCustomFormatType = action.payload;
      lsSetItem(lsKeySelectedCustomFormatType, action.payload);
    },
    setSortBy(
      state,
      action: PayloadAction<string>
    ) {
      state.sortBy = action.payload;
    },
    setSortDirection(
      state,
      action: PayloadAction<string>
    ) {
      state.sortDirection = action.payload;
    },
    setReloadExportPreview(
      state,
      action: PayloadAction<boolean>
    ) {
      state.reloadExportPreview = action.payload;
    }
  },
});

export const {
  setSelectedExportType,
  setSelectedExportTypeModal,
  setSelectedCustomFormatType,
  setSortBy,
  setSortDirection,
  setReloadExportPreview
} = slice.actions;

export default slice.reducer;

import { Template } from "../../docuclipper/DocuclipperTypes";

const initialState: {
  template: Template | null;
  selectedTemplateFields: { [key: string]: boolean };
} = {
  template: null,
  selectedTemplateFields: {},
};

export const SET_TEMPLATE = "set-template";
export const SET_TEMPLATE_FIELD = "set-template-fields";
export const CLEAR_TEMPLATE_FIELDS = "clear-template-fields";

export const CLEAR_TEMPLATE = "clear-template";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TEMPLATE:
      return {
        ...state,
        template: {
          ...state.template,
          ...action.payload.template,
        },
      };
    case SET_TEMPLATE_FIELD:
      return {
        ...state,
        selectedTemplateFields: {
          ...state.selectedTemplateFields,
          [action.payload.field]: action.payload.value,
        },
      };
    case CLEAR_TEMPLATE_FIELDS:
      return {
        ...state,
        selectedTemplateFields: {},
      };
    case CLEAR_TEMPLATE:
      return {
        ...state,
        template: null,
      };

    default:
      return state;
  }
}

// export type SetTemplateFn = (template: Template) => any;
// export const setTemplate = (template: Template) => ({
//   type: SET_TEMPLATE,
//   payload: {
//     template
//   }
// });

export type SetTemplateFieldFn = (field: string, value: boolean) => any;
export const setTemplateField = (field: string, value: boolean) => ({
  type: SET_TEMPLATE_FIELD,
  payload: {
    field,
    value,
  },
});

export type ClearTemplateFieldsFn = () => any;
export const clearTemplateFields = () => ({
  type: CLEAR_TEMPLATE_FIELDS,
  payload: {},
});

// export type ClearTemplateFn = () => any;
// export const clearTemplate = () => ({
//   type: CLEAR_TEMPLATE,
//   payload: {}
// });

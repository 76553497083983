import React, { useState, useEffect } from "react";
import { Card, Alert, Spinner, Tab, Tabs, Modal } from "react-bootstrap";

import { PotentialMatchList } from "./PotentitalMatchList";
import { ExistingMatchGroups } from "./ExistingMatchGroups";
import { getMatchGroups, getPotentialMatches } from "src/docuclipper/api";
import { MatchGroup } from "src/docuclipper/DocuclipperTypes";

interface Props {
  transactionLineId: number;
  onMatchCreated?: () => void;
}

interface PotentialMatches {
  [key: string]: Array<{
    id: number;
    // add other match properties here
  }>;
}

export const InvoiceMatchManager: React.FC<Props> = ({
  transactionLineId,
  onMatchCreated,
}) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string>("");
  const [potentialMatches, setPotentialMatches] = useState<PotentialMatches>(
    {}
  );
  const [existingGroups, setExistingGroups] = useState<MatchGroup[]>([]);
  const [activeTab, setActiveTab] = useState("potential");
  const [showMatchModal, setShowMatchModal] = useState(false);

  useEffect(() => {
    loadData();
  }, [transactionLineId]);

  const loadData = async () => {
    try {
      setLoading(true);
      const [matchesResponse, groupsResponse] = await Promise.all([
        getPotentialMatches(transactionLineId),
        getMatchGroups(transactionLineId),
      ]);
      setPotentialMatches(matchesResponse.potentialMatches || {});
      setExistingGroups(Array.isArray(groupsResponse) ? groupsResponse : []);
    } catch (err) {
      setError("Failed to load match data");
      setPotentialMatches({});
      setExistingGroups([]);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="text-center p-4">
        <Spinner animation="border" />
      </div>
    );
  }

  return (
    <Card>
      <Card.Header>
        <h5 className="mb-0">Invoice Matching</h5>
      </Card.Header>
      <Card.Body>
        {error && <Alert variant="danger">{error}</Alert>}

        <Tabs
          activeKey={activeTab}
          onSelect={(k) => setActiveTab(k || "potential")}
        >
          <Tab eventKey="potential" title="Potential Matches">
            <PotentialMatchList
              transactionLineId={transactionLineId}
              onMatchCreated={() => {
                loadData();
                onMatchCreated?.();
              }}
            />
          </Tab>
          <Tab eventKey="existing" title="Existing Groups">
            <ExistingMatchGroups
              groups={existingGroups}
              onStatusUpdated={loadData}
            />
          </Tab>
        </Tabs>

        <Modal
          show={showMatchModal}
          onHide={() => setShowMatchModal(false)}
          size="xl"
          fullscreen
          dialogClassName="w-100 m-0"
        >
          <Modal.Header
            closeButton
            onPointerEnterCapture={() => {}}
            onPointerLeaveCapture={() => {}}
            placeholder=""
          >
            <Modal.Title>Invoice Match Details</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-3">
            {/* Add your modal content here */}
          </Modal.Body>
        </Modal>
      </Card.Body>
    </Card>
  );
};

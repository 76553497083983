import { PayloadAction } from "@reduxjs/toolkit";
import {
  KonvaLine,
  EdgeType,
  VariableLocation,
  Percentage,
  HorizontalLine,
  VerticalLine,
  VariableLocationEdges,
  Words,
} from "../../../../docuclipper/DocuclipperTypes";
import { doRectangleInverseMath } from "../../../../rectangle-math";
import uuidv4 from "uuid/v4";
import { computeLine, edge2color } from "../../TemplateFieldUtils";
import { TemplateFieldState } from "../../TemplateFieldTypes";

export const _updateEdgePercentage = (
  state: TemplateFieldState,
  action: PayloadAction<{
    id: string;
    // percentage: number;
    edge: "top" | "bottom" | "left" | "right";
    vlId: string;
  }>
) => {
  const { id, vlId, edge } = action.payload;
  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }

    if (!state.stage) {
      return tf;
    }

    let percentage = 0;
    if (["top", "left"].includes(edge)) {
      percentage = 1;
    } else if (["bottom", "right"].includes(edge)) {
      percentage = 99;
    } else {
      throw new Error(`Unexpected edge ${edge}`);
    }
    let line: KonvaLine = computeLine({
      edge,
      percentage,
      state,
    });

    if (!tf.variableLocation) {
      return tf;
    }
    return {
      ...tf,

      variableLocation: {
        ...tf.variableLocation,
        variableLocations: tf.variableLocation.variableLocations.map((vl) => {
          if (vl.id !== vlId) {
            return vl;
          }
          return {
            ...vl,
            [edge]: {
              ...vl[edge],
              percentage: {
                percentage,
                line,
              },
            },
          };
        }),
      },
    };
  });
};

export const _updateEdgePercentageDisable = (
  state: TemplateFieldState,
  action: PayloadAction<{
    id: string;
    edge: EdgeType;
    vlId: string;
  }>
) => {
  const { id, edge, vlId } = action.payload;
  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }

    if (!state.stage) {
      return tf;
    }

    if (!tf.variableLocation) {
      return tf;
    }
    return {
      ...tf,

      variableLocation: {
        ...tf.variableLocation,
        variableLocations: tf.variableLocation?.variableLocations.map((vl) => {
          if (vl.id !== vlId) {
            return vl;
          }
          return {
            ...vl,
            [edge]: {
              ...vl[edge],
              percentage: null,
            },
          };
        }),
      },
    };
  });
};

export const _setVariableSelectedId = (
  state: TemplateFieldState,
  action: PayloadAction<{
    id: string;
    vlId: string;
  }>
) => {
  const { id, vlId } = action.payload;

  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }
    return {
      ...tf,
      variableLocation: !tf.variableLocation
        ? null
        : {
            ...tf.variableLocation,
            selectedId: vlId,
          },
    };
  });
};

export const _updateEdgePercentageFromLine = (
  state: TemplateFieldState,
  action: PayloadAction<{
    id: string;
    x: number;
    lineX: number;
    y: number;
    lineY: number;
    lineId: string;
  }>
) => {
  // update percentage by dragging a line in the canvas
  // copy updateColumnWidthFromRectangle
  const { id, x, lineX, y, lineY, lineId } = action.payload;

  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }

    if (!tf.variableLocation) {
      return tf;
    }

    if (!state.stage) {
      return tf;
    }
    let percentage = 0;

    const vfSelectedId = tf.variableLocation.selectedId;
    return {
      ...tf,
      variableLocation: {
        ...(tf.variableLocation as VariableLocation),
        variableLocations: tf.variableLocation.variableLocations.map((vl) => {
          if (vl.id !== vfSelectedId) {
            return vl;
          }
          if (!state.stage) {
            return vl;
          }

          let edge;
          for (const maybeEdge of ["top", "bottom", "left", "right"]) {
            if (vl[maybeEdge as EdgeType].percentage?.line.id === lineId) {
              edge = maybeEdge;
            }
          }
          if (!edge) {
            return vl;
          }

          const line: any = {
            ...vl[edge].percentage.line,
          };

          if (["top", "bottom"].includes(edge)) {
            percentage = (100 * y) / state.stage.height;
            line.horizontalLine.y = Math.min(
              Math.max(0, y),
              state.stage.height
            );
          } else if (["left", "right"].includes(edge)) {
            percentage = (100 * x) / state.stage.width;
            line.verticalLine.x = Math.min(Math.max(0, x), state.stage.width);
          }
          // console.log("updateEdgePercentageFromLine", {
          //   x,
          //   lineX,
          //   y,
          //   lineY,
          //   stageWidth: state.stage.width,
          //   stageHeight: state.stage.height,
          // });

          return {
            ...vl,
            [edge]: {
              ...vl[edge],
              percentage: {
                ...vl[edge].percentage,
                percentage,
                line,
              },
            },
          };
        }),
      },
    };
  });
};

export const _updateEdgePercentageFromKey = (
  state: TemplateFieldState,
  action: PayloadAction<{
    id: string;
    deltaX: number;
    deltaY: number;
  }>
) => {
  const { id, deltaX, deltaY } = action.payload;

  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }

    if (!tf.variableLocation) {
      return tf;
    }

    const vfSelectedId = tf.variableLocation.selectedId;
    return {
      ...tf,
      variableLocation: {
        ...(tf.variableLocation as VariableLocation),
        variableLocations: tf.variableLocation.variableLocations.map((vl) => {
          if (vl.id !== vfSelectedId) {
            return vl;
          }

          if (!state.selectedEdge) {
            return vl;
          }
          if (!vl[state.selectedEdge].percentage) {
            return vl;
          }

          const line = {
            ...(vl[state.selectedEdge].percentage as Percentage).line,
          };

          let delta;

          if (["top", "bottom"].includes(state.selectedEdge)) {
            delta = deltaY;
            line.horizontalLine = {
              ...(line.horizontalLine as HorizontalLine),
              y: (line.horizontalLine as HorizontalLine).y + delta,
            };
          } else if (["left", "right"].includes(state.selectedEdge)) {
            delta = deltaX;
            line.verticalLine = {
              ...(line.verticalLine as VerticalLine),
              x: (line.verticalLine as VerticalLine).x + delta,
            };
          }
          const percentage =
            vl[state.selectedEdge].percentage?.percentage + delta;

          return {
            ...vl,
            [state.selectedEdge]: {
              ...vl[state.selectedEdge],
              percentage: {
                ...vl[state.selectedEdge].percentage,
                percentage,
                line,
              },
            },
          };
        }),
      },
    };
  });
};

export const _wordsLoad = (
  state: TemplateFieldState,
  action: PayloadAction<{ id: string; vlId: string }>
) => {
  const { id, vlId } = action.payload;

  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }
    if (!tf.variableLocation) {
      return tf;
    }
    if (!state.page) {
      return tf;
    }
    if (!state.selectedEdge) {
      return tf;
    }
    return {
      ...tf,
      variableLocation: {
        ...tf.variableLocation,
        variableLocations: tf.variableLocation.variableLocations.map((vl) => {
          if (!state.selectedEdge) {
            return vl;
          }
          if (vl.id !== vlId) {
            return vl;
          }
          return {
            ...vl,
            [state.selectedEdge]: {
              ...vl[state.selectedEdge],
              words: {
                ...vl[state.selectedEdge].words,
                loading: true,
                error: null,
              },
            },
          };
        }),
      },
    };
  });
};
export const _wordsSetData = (
  state: TemplateFieldState,
  action: PayloadAction<{
    id: string;
    words: string[];
    edges: number[];
    edge?: EdgeType;
    vlId: string;
  }>
) => {
  const { id, words, edges, edge, vlId } = action.payload;

  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }
    if (!tf.variableLocation) {
      return tf;
    }

    const selectedEdge = edge ? edge : state.selectedEdge;

    if (!selectedEdge) {
      return tf;
    }

    const isHorizontal = ["top", "bottom"].includes(selectedEdge);
    const isVertical = ["left", "right"].includes(selectedEdge);

    const stageEdges = edges.map((e) => {
      if (!state.page.page) {
        return 0;
      }
      if (!state.stage) {
        return 0;
      }
      let xx;
      if (isHorizontal) {
        xx = { y0: e, x0: 0, y1: e, x1: 0 };
      } else if (isVertical) {
        xx = { y0: 0, x0: e, y1: 0, x1: e };
      }

      const { x, y } = doRectangleInverseMath(
        xx,
        {
          stageHeight: state.stage?.height,
          stageWidth: state.stage?.width,
        },
        {
          pageHeight: state.page.page.height,
          pageWidth: state.page.page.width,
        }
      );
      if (isHorizontal) {
        return y;
      } else if (isVertical) {
        return x;
      } else {
        return 0;
      }
    });

    let stroke = edge2color(selectedEdge);
    return {
      ...tf,
      variableLocation: {
        ...tf.variableLocation,
        variableLocations: tf.variableLocation.variableLocations.map((vl) => {
          if (!selectedEdge) {
            return vl;
          }
          if (vl.id !== vlId) {
            return vl;
          }
          return {
            ...vl,
            [selectedEdge]: {
              ...vl[selectedEdge],
              words: {
                ...vl[selectedEdge].words,
                loading: false,
                words,
                lines: stageEdges.map(
                  (e, i): KonvaLine => {
                    if (["top", "bottom"].includes(selectedEdge as EdgeType)) {
                      return {
                        draggable: false,
                        horizontalLine: {
                          y: e,
                          maxY: null,
                          minY: null,
                          scaleX: 1.0,
                          scaleY: 1.0,
                          x0: null,
                          x1: null,
                        },
                        verticalLine: null,
                        id: uuidv4(),
                        stroke,
                        strokeWidth: 5,
                        type: "horizontalFullWidth",
                        text: "",
                        dash: null,
                      };
                    } else if (
                      ["left", "right"].includes(selectedEdge as EdgeType)
                    ) {
                      return {
                        draggable: false,
                        horizontalLine: null,
                        verticalLine: {
                          x: e,
                          maxX: null,
                          minX: null,
                          scaleX: 1.0,
                          scaleY: 1.0,
                          y0: null,
                          y1: null,
                        },

                        id: uuidv4(),
                        stroke,
                        strokeWidth: 5,
                        type: "verticalFullHeight",
                        text: "",
                        dash: null,
                      };
                    } else {
                      return {} as KonvaLine;
                    }
                  }
                ),
              },
            },
          };
        }),
      },
    };
  });
};
export const _wordsSetWords = (
  state: TemplateFieldState,
  action: PayloadAction<{
    id: string;
    words: string[];
    edge: EdgeType;
    vlId: string;
  }>
) => {
  const { id, words, edge, vlId } = action.payload;

  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }
    if (!tf.variableLocation) {
      return tf;
    }

    return {
      ...tf,
      variableLocation: {
        ...tf.variableLocation,
        variableLocations: tf.variableLocation.variableLocations.map((vl) => {
          if (vl.id !== vlId) {
            return vl;
          }
          return {
            ...vl,
            [edge]: {
              ...vl[edge],
              words: {
                ...vl[edge].words,
                words,
              },
            },
          };
        }),
      },
    };
  });
};
export const _wordsSetError = (
  state: TemplateFieldState,
  action: PayloadAction<{ id: string; error: string; vlId: string }>
) => {
  const { id, error, vlId } = action.payload;

  state.templateFields = state.templateFields.map((tf) => {
    if (!tf.variableLocation) {
      return tf;
    }
    if (!state.page) {
      return tf;
    }
    if (!state.selectedEdge) {
      return tf;
    }

    return {
      ...tf,
      variableLocation: {
        ...tf.variableLocation,
        variableLocations: tf.variableLocation.variableLocations.map((vl) => {
          if (vl.id !== vlId) {
            return vl;
          }
          if (!state.selectedEdge) {
            return vl;
          }
          return {
            ...vl,
            [state.selectedEdge]: {
              ...vl[state.selectedEdge],
              words: {
                ...vl[state.selectedEdge].words,
                loading: false,
                error,
              },
            },
          };
        }),
      },
    };
  });
};

export const _updateIncludeWords = (
  state: TemplateFieldState,
  action: PayloadAction<{ edgeType: EdgeType; checked: boolean; vlId: string }>
) => {
  const { checked, edgeType, vlId } = action.payload;

  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== state.selectedFieldId) {
      return tf;
    }
    if (!tf.variableLocation) {
      return tf;
    }

    return {
      ...tf,
      variableLocation: {
        ...tf.variableLocation,
        variableLocations: tf.variableLocation.variableLocations.map((vl) => {
          if (vl.id !== vlId) {
            return vl;
          }

          let xPosition;
          let yPosition;
          switch (edgeType) {
            case "top":
              yPosition = checked ? "top" : "bottom";
              xPosition = vl[edgeType].words?.xPosition;
              break;
            case "bottom":
              yPosition = checked ? "bottom" : "top";
              xPosition = vl[edgeType].words?.xPosition;
              break;
            case "left":
              xPosition = checked ? "left" : "right";
              yPosition = vl[edgeType].words?.yPosition;
              break;
            case "right":
              xPosition = checked ? "right" : "left";
              yPosition = vl[edgeType].words?.yPosition;
              break;
          }
          return {
            ...vl,
            [edgeType]: {
              ...vl[edgeType],
              words: {
                ...vl[edgeType].words,
                xPosition,
                yPosition,
                includeWordsChecked: checked,
              },
            },
          };
        }),
      },
    };
  });
  return state;
};

export const _updateVlOffset = (
  state: TemplateFieldState,
  action: PayloadAction<{
    edgeType: EdgeType;
    id: string;
    offset: number;
    vlId: string;
  }>
) => {
  const { id, offset, edgeType, vlId } = action.payload;

  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }
    if (!tf.variableLocation) {
      return tf;
    }

    return {
      ...tf,
      variableLocation: {
        ...tf.variableLocation,
        variableLocations: tf.variableLocation.variableLocations.map((vl) => {
          if (vl.id !== vlId) {
            return vl;
          }

          return {
            ...vl,
            [edgeType]: {
              ...vl[edgeType],
              words: {
                ...vl[edgeType].words,
                offset,
              },
            },
          };
        }),
      },
    };
  });
  return state;
};

export const _addVariableField = (
  state: TemplateFieldState,
  action: PayloadAction<{
    id: string;
  }>
) => {
  const { id } = action.payload;
  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }
    const edges = ["top", "bottom", "left", "right"].map((edge) => ({
      words: {
        loading: false,
        error: null,
        words: [],
        wordType: ["top", "bottom"].includes(edge)
          ? ("y" as "y")
          : ("x" as "x"),
        xPosition: ["top", "bottom"].includes(edge)
          ? null
          : ("right" as "right"),
        yPosition: ["top", "bottom"].includes(edge) ? ("top" as "top") : null,
        includeWordsChecked: true,
        lines: [],
        offset: 0,
      },
      percentage: null,
    }));
    const newVl: VariableLocationEdges = {
      id: uuidv4(),
      top: edges[0],
      bottom: edges[1],
      left: edges[2],
      right: edges[3],
      forbiddenWords: [],
      forbiddenWordsEnabled: false,
    };
    return {
      ...tf,
      variableLocation: !tf.variableLocation
        ? null
        : {
            ...tf.variableLocation,
            selectedId: newVl.id,
            variableLocations: [
              ...tf.variableLocation.variableLocations,
              newVl,
            ],
          },
    };
  });
};

export const _deleteVariableField = (
  state,
  action: PayloadAction<{
    id: string;
    vlId: string;
  }>
) => {
  const { id, vlId } = action.payload;
  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }
    return {
      ...tf,
      variableLocation: !tf.variableLocation
        ? null
        : {
            ...tf.variableLocation,
            variableLocations: tf.variableLocation.variableLocations.filter(
              (vl) => vl.id !== vlId
            ),
          },
    };
  });
};
export const _duplicateVariableField = (
  state,
  action: PayloadAction<{ id: string; vlId: string }>
) => {
  const { id, vlId } = action.payload;
  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }

    if (!tf.variableLocation) {
      return tf;
    }
    const originalVls = (tf.variableLocation as VariableLocation).variableLocations.filter(
      (vl) => vl.id === vlId
    );
    if (originalVls.length === 0) {
      return tf;
    }
    const originalVl = originalVls[0];
    const newVl: VariableLocationEdges = {
      ...originalVl,
    };
    // replace ids
    newVl.id = uuidv4();
    for (const edge of ["top", "bottom", "left", "right"]) {
      if (newVl[edge as EdgeType].percentage) {
        (newVl[edge as EdgeType].percentage as Percentage).line.id = uuidv4();
      }
      if (newVl[edge as EdgeType].words) {
        (newVl[edge as EdgeType].words as Words).lines = (newVl[
          edge as EdgeType
        ].words as Words).lines.map((l) => ({ ...l, id: uuidv4() }));
        (newVl[edge as EdgeType].words as Words).loading = false;
        (newVl[edge as EdgeType].words as Words).error = null;
      }
    }
    return {
      ...tf,
      variableLocation: !tf.variableLocation
        ? null
        : {
            ...tf.variableLocation,
            selectedId: newVl.id,
            variableLocations: [
              ...tf.variableLocation.variableLocations,
              newVl,
            ],
          },
    };
  });
};

export const _updateForbiddenWordsEnabled = (
  state,
  action: PayloadAction<{ id: string; vlId: string; enabled: boolean }>
) => {
  const { id, vlId, enabled } = action.payload;
  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }

    if (!tf.variableLocation) {
      return tf;
    }

    return {
      ...tf,
      variableLocation: {
        ...tf.variableLocation,
        variableLocations: tf.variableLocation.variableLocations.map((vl) => {
          if (vl.id !== vlId) {
            return vl;
          }
          return {
            ...vl,
            forbiddenWordsEnabled: enabled,
          };
        }),
      },
    };
  });
};

export const _updateForbiddenWords = (
  state,
  action: PayloadAction<{
    id: string;
    vlId: string;
    forbiddenWordsId: string;
    words: string[];
  }>
) => {
  const { id, vlId, forbiddenWordsId, words } = action.payload;
  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }

    if (!tf.variableLocation) {
      return tf;
    }

    return {
      ...tf,
      variableLocation: {
        ...tf.variableLocation,
        variableLocations: tf.variableLocation.variableLocations.map((vl) => {
          if (vl.id !== vlId) {
            return vl;
          }
          return {
            ...vl,
            forbiddenWords: vl.forbiddenWords.map((fw) => {
              if (fw.id !== forbiddenWordsId) {
                return fw;
              }
              return {
                ...fw,
                words,
              };
            }),
          };
        }),
      },
    };
  });
};

export const _addForbiddenWords = (
  state,
  action: PayloadAction<{
    id: string;
    vlId: string;
  }>
) => {
  const { id, vlId } = action.payload;
  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }

    if (!tf.variableLocation) {
      return tf;
    }

    return {
      ...tf,
      variableLocation: {
        ...tf.variableLocation,
        variableLocations: tf.variableLocation.variableLocations.map((vl) => {
          if (vl.id !== vlId) {
            return vl;
          }
          return {
            ...vl,
            forbiddenWords: [...vl.forbiddenWords, { id: uuidv4(), words: [] }],
          };
        }),
      },
    };
  });
};

export const _deleteForbiddenWords = (
  state,
  action: PayloadAction<{
    id: string;
    vlId: string;
    forbiddenWordsId: string;
  }>
) => {
  const { id, vlId, forbiddenWordsId } = action.payload;
  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }

    if (!tf.variableLocation) {
      return tf;
    }

    return {
      ...tf,
      variableLocation: {
        ...tf.variableLocation,
        variableLocations: tf.variableLocation.variableLocations.map((vl) => {
          if (vl.id !== vlId) {
            return vl;
          }
          return {
            ...vl,
            forbiddenWords: vl.forbiddenWords.filter(
              (fw) => fw.id !== forbiddenWordsId
            ),
          };
        }),
      },
    };
  });
};

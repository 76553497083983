import { useSelector } from "react-redux";
import { ReduxState } from "../redux";

export function useIsAdmin() {
  const { token } = useSelector((state: ReduxState) => state.Auth);
  const isAdmin =
    token && token.permissions && token.permissions.includes("READ_DB_TABLES");
  const isSupportRep =
    token && token.permissions && token.permissions.includes("SUPPORT_TOOL");
  const isSuperLogin = token && token.superlogin;

  return { isAdmin, isSuperLogin, isSupportRep };
  // return { isAdmin: false, isSuperLogin: false };
}

export const doRectangleMath = (
  { x, y, width, height, scaleX, scaleY },
  { stageWidth, stageHeight },
  { pageWidth, pageHeight }
) => {
  // scale coordinates
  const stageScaleX = pageWidth / stageWidth;
  const stageScaleY = pageHeight / stageHeight;
  const obj = {
    x0: x * stageScaleX,
    x1: (x + width * scaleX) * stageScaleX,
    y0: y * stageScaleY,
    y1: (y + height * scaleY) * stageScaleY,
  };

  return obj;
};

export const doRectangleInverseMath = (
  { x0, x1, y0, y1 },
  { stageWidth, stageHeight },
  { pageWidth, pageHeight }
) => {
  const stageScaleX = pageWidth / stageWidth;
  const stageScaleY = pageHeight / stageHeight;

  const obj = {
    x: x0 !== undefined ? x0 / stageScaleX : 0,
    y: y0 !== undefined ? y0 / stageScaleY : 0,
    height: y0 !== undefined && y1 !== undefined ? (y1 - y0) / stageScaleY : 0,
    width: x0 !== undefined && x1 !== undefined ? (x1 - x0) / stageScaleX : 0,
    scaleX: 1.0,
    scaleY: 1.0,
  };
  return obj;
};

import React from "react";
import NewTransactionManager from "../DataArchive/TransactionManager/NewTransactionManager";
import { useDispatch } from "react-redux";
import { fetchDocumentsByTagLevels } from "src/redux/reducers/JobDocument";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { fetchTotals } from "src/redux/reducers/Reconciler";

export const Transactions = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  React.useEffect(() => {
    const { level1, level2 } = queryString.parse(location.search);
    dispatch(fetchDocumentsByTagLevels(level1 as string, level2 as string));
  }, [location.search]);

  React.useEffect(() => {
    const { level1, level2 } = queryString.parse(location.search);
    dispatch(fetchTotalsByTagLevels(level1 as string, level2 as string));
  }, [location.search]);

  return (
    <div>
      <NewTransactionManager />
    </div>
  );
};

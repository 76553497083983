import uuidv4 from "uuid/v4";
import { getTour } from "../../docuclipper/api";

const initialState: {
  tours: {
    [key: string]: boolean;
  };
  hashes: {
    [key: string]: string;
  };
} = {
  tours: {},
  hashes: {},
};

export const ENABLE_TOUR = "enable-tour";
export const DISABLE_TOUR = "disable-tour";
export const UPDATE_TOUR = "update-tour";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ENABLE_TOUR:
      return {
        ...state,
        tours: {
          ...state.tours,
          [action.payload.tourId]: true,
        },
      };
    case UPDATE_TOUR:
      const hashes = {};
      for (const tourId in state.tours) {
        hashes[tourId] = uuidv4();
      }
      return {
        ...state,
        hashes,
      };
    case DISABLE_TOUR:
      return {
        ...state,
        tours: {
          ...state.tours,
          [action.payload.tourId]: false,
        },
      };

    default:
      return state;
  }
}

export type EnableTourFn = (tourId: string) => any;
export const enableTour = (tourId: string) => async (dispatch) => {
  dispatch({
    type: ENABLE_TOUR,
    payload: {
      tourId,
    },
  });
};

export type DisableTourFn = (tourId: string) => any;
export const disableTour = (tourId: string) => async (dispatch) => {
  dispatch({
    type: DISABLE_TOUR,
    payload: {
      tourId,
    },
  });
};

export type EnableTourIfNotCompleted = (tourId: string) => any;
export const enableTourIfNotCompleted = (tourId: string) => async (
  dispatch
) => {
  try {
    let tour = await getTour(tourId);
    if (!tour.completed) {
      dispatch({
        type: ENABLE_TOUR,
        payload: {
          tourId,
        },
      });
    }
  } catch (err) {}
};

export type UpdateToursFn = () => any;
export const updateTours = () => ({
  type: UPDATE_TOUR,
  payload: {},
});

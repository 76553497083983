import React, { Component } from "react";
// import GoogleAnalytics from "react-ga";
import { RouteComponentProps } from "react-router";
import { logErrorToGA } from "./Logger";
import { getFields } from "../auth";
import ReactGA from "react-ga4";

window.addEventListener("error", (e) => {
  logErrorToGA(e.error);
});

window.addEventListener("unhandledrejection", (rj) => {
  logErrorToGA(rj.reason);
});

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = (page) => {
    // GoogleAnalytics.set({
    //   ...getFields(),
    //   // sessionId
    //   dimension6:
    //     new Date().getTime() + "." + Math.random().toString(36).substring(5),
    // });
    // GoogleAnalytics.pageview(page);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  };

  // eslint-disable-next-line
  const HOC = class extends Component<RouteComponentProps, {}> {
    componentDidMount() {
      // eslint-disable-next-line
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;

// import React from "react";
import { File, CsvLineStatus } from "../../docuclipper/DocuclipperTypes";

const initialState: {
  file: null | File;
  csvLineStatuses: CsvLineStatus[];
  parsingCsv: boolean;
} = {
  file: null,
  csvLineStatuses: [],
  parsingCsv: false,
};

export const SET_CSV_FILE = "add-csv-file";
export const REMOVE_CSV_FILE = "remove-csv-file";
export const SET_CSV_LINE_STATUSES = "add-csv-line-statuses";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case REMOVE_CSV_FILE:
      return {
        ...state,
        file: null,
        csvLineStatuses: [],
      };
    case SET_CSV_FILE:
      return {
        ...state,
        file: {
          ...state.file,
          ...action.payload,
        },
      };
    case SET_CSV_LINE_STATUSES:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
}

export type RemoveFileFn = () => any;
export const removeFile = () => async (dispatch) => {
  dispatch({
    type: REMOVE_CSV_FILE,
    payload: {},
  });
  dispatch({
    type: SET_CSV_LINE_STATUSES,
    payload: {
      csvLineStatuses: [],
    },
  });
};

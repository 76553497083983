import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { DeleteJobButton } from "../DeleteJobButton";
import { ReduxState } from "../../../../redux";
import { RedoJobButton } from "../RedoJobButton";
import { emailJob, markJobReviewed } from "../../../../docuclipper/api";
import { createAlert } from "../../../../redux/reducers/Alerts";
import { useIsAdmin } from "../../../../utils/auth";
import { DebugJobButton } from "../DebugJobButton";
import InvoiceTour from "./InvoiceTour";
import HelpDrawer from "src/views/Docuclipper/Ratings/HelpDrawer";
import { setHelpDrawerOpen } from "src/redux/reducers/HelpDrawer";
import AddDocumentsPopover from "./AddBankStatementsPdfCsvPopover";

export const JobAdminButtons = () => {
  const dispatch = useDispatch();

  const { job } = useSelector((state: ReduxState) => state.JobData);
  const { isAdmin, isSuperLogin } = useIsAdmin();

  if (!job) {
    return null;
  }
  const onEmail = () => {
    emailJob(job.id)
      .then((x) =>
        dispatch(
          createAlert({
            message: "Email sent successfully",
            timeout: 0,
            type: "success",
          })
        )
      )
      .catch((err) => {
        dispatch(
          createAlert({
            message: "Error emailing job",
            timeout: 0,
            type: "error",
          })
        );
      });
  };

  const onMarkReviewed = () => {
    markJobReviewed(job.id)
      .then((x) =>
        dispatch(
          createAlert({
            message: "Job marked as reviewed successfully",
            timeout: 0,
            type: "success",
          })
        )
      )
      .catch((err) => {
        dispatch(
          createAlert({
            message: "Error marking job as reviewed",
            timeout: 0,
            type: "error",
          })
        );
      });
  };

  return (
    <>
      {(isAdmin || isSuperLogin) && (
        <Row className="justify-content-end">
          <RedoJobButton job={job} />
          <DebugJobButton job={job} />
          <Button className="ml-2" variant="link" onClick={onEmail}>
            <FontAwesomeIcon icon="envelope" />
            <span className="ml-1">Email</span>
          </Button>
          <Button className="ml-2" variant="link" onClick={onMarkReviewed}>
            <FontAwesomeIcon icon="check" />
            <span className="ml-1">Mark reviewed</span>
          </Button>
          <DeleteJobButton job={job} />
        </Row>
      )}
    </>
  );
};

export const JobButtons = ({ downloadButton }) => {
  const { job } = useSelector((state: ReduxState) => state.JobData);
  const dispatch = useDispatch();

  if (!job) {
    return null;
  }

  return (
    <>
      <Row className="justify-content-end mr-2">
        {job.type === "Invoice" && <InvoiceTour />}
        {job?.type === "Invoice" && (
          <>
            <Button
              className="btn--report-bad-job"
              variant="link"
              onClick={() => {
                dispatch(setHelpDrawerOpen(true));
              }}
            >
              <FontAwesomeIcon icon="exclamation-triangle" />
              <span className="m-2">Report Issue</span>
            </Button>
            <>{<HelpDrawer />}</>
          </>
        )}

        <DeleteJobButton job={job} />
        <AddDocumentsPopover />
        {downloadButton && downloadButton()}
      </Row>
    </>
  );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Job } from "../../docuclipper/DocuclipperTypes";

const initialState: {
  exportType: Job["jobType"] | "xlsx" | "csv" | "qbo" | null;
  downloadModalOpen: boolean;
  downloadInvoiceModalOpen: boolean;
} = {
  exportType: null,
  downloadModalOpen: false,
  downloadInvoiceModalOpen: false,
};

const slice = createSlice({
  name: "JobExport",
  initialState,
  reducers: {
    setExportType(state, action: PayloadAction<Job["jobType"] | null>) {
      state.exportType = action.payload;
    },
    setDownloadModalOpen(state, action: PayloadAction<boolean>) {
      state.downloadModalOpen = action.payload;
    },
    setDownloadInvoiceModalOpen(state, action: PayloadAction<boolean>) {
      state.downloadInvoiceModalOpen = action.payload;
    },
  },
});

export const { setExportType, setDownloadModalOpen, setDownloadInvoiceModalOpen } = slice.actions;

export default slice.reducer;

import {
  TemplateFieldV2,
  KonvaRectangle,
  VerticalLine,
  FixedLocation,
} from "../../../../docuclipper/DocuclipperTypes";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  doRectangleInverseMath,
  doRectangleMath,
} from "../../../../rectangle-math";
import { TemplateFieldState } from "../../TemplateFieldTypes";

const updateRectangleHelper = (
  state: TemplateFieldState,
  tf: TemplateFieldV2,
  update: Partial<KonvaRectangle>
): TemplateFieldV2 => {
  if (!state.page) {
    return tf;
  }
  if (!state.stage || !state.page.page) {
    return tf;
  }
  if (!tf.fixedLocation) {
    return tf;
  }
  if (!tf.fixedLocation) {
    return tf;
  }

  const rectangle = {
    ...tf.fixedLocation.rectangle,
    ...update,
  };
  const pageLocation = doRectangleMath(
    { ...rectangle },
    {
      stageHeight: state.stage.height,
      stageWidth: state.stage.width,
    },
    {
      pageHeight: state.page.page.height,
      pageWidth: state.page.page.width,
    }
  );

  // update lines if any
  if (tf.toolType === "ocrtables") {
    tf.tableConfig.columns = tf.tableConfig.columns.map((c, i) => {
      if (!c.line.verticalLine) {
        return c;
      }

      let newX =
        rectangle.x +
        rectangle.width * rectangle.scaleX * (c.columnWidthPercentage / 100);

      if (i > 0) {
        (tf.tableConfig.columns[i - 1].line
          .verticalLine as VerticalLine).maxX = newX;
      }
      if (i < tf.tableConfig.columns.length - 1) {
        (tf.tableConfig.columns[i + 1].line
          .verticalLine as VerticalLine).minX = newX;
      }

      const verticalLine = {
        ...c.line.verticalLine,
        x: newX,

        y0: rectangle.y,
        y1: rectangle.y + rectangle.height * rectangle.scaleY,
        scaleX: 1.0,
        scaleY: 1.0,
      };
      if (i === 0) {
        verticalLine.minX = rectangle.x;
      }
      if (i === tf.tableConfig.columns.length - 1) {
        verticalLine.maxX = rectangle.x + rectangle.width * rectangle.scaleX;
      }
      // console.log({ verticalLine, rectangle });

      return {
        ...c,
        line: {
          ...c.line,
          verticalLine,
        },
      };
    });
  }

  return {
    ...tf,
    fixedLocation: {
      pageLocation,
      rectangle,
    },
  };
};

export const _moveRectangle = (
  state: TemplateFieldState,
  action: PayloadAction<{
    id: string;
    x: number;
    y: number;
  }>
) => {
  const { x, y, id: rectangleId } = action.payload;
  state.templateFields = state.templateFields.map(
    (tf): TemplateFieldV2 => {
      if (!tf.fixedLocation || !tf.fixedLocation.rectangle) {
        return tf;
      }
      if (rectangleId !== tf.fixedLocation.rectangle.id) {
        return tf;
      }

      return updateRectangleHelper(state, tf, {
        x,
        y,
      });
    }
  );
};

export const _moveRectangleFromKey = (
  state: TemplateFieldState,
  action: PayloadAction<{
    id: string;
    deltaX: number;
    deltaY: number;
  }>
) => {
  const { deltaX, deltaY, id: rectangleId } = action.payload;
  state.templateFields = state.templateFields.map(
    (tf): TemplateFieldV2 => {
      if (!tf.fixedLocation || !tf.fixedLocation.rectangle) {
        return tf;
      }
      if (rectangleId !== tf.fixedLocation.rectangle.id) {
        return tf;
      }

      return updateRectangleHelper(state, tf, {
        x: tf.fixedLocation.rectangle.x + deltaX,
        y: tf.fixedLocation.rectangle.y + deltaY,
      });
    }
  );
};

export const _fixNegativeRectangles = (
  state: TemplateFieldState,
  action: PayloadAction<{
    id: string;
  }>
) => {
  const { id: rectangleId } = action.payload;
  state.templateFields = state.templateFields.map(
    (tf): TemplateFieldV2 => {
      if (tf.locationType !== "fixed") {
        return tf;
      }
      if (!tf.fixedLocation) {
        return tf;
      }
      if (rectangleId !== tf.fixedLocation.rectangle.id) {
        return tf;
      }

      let { x, y, width, height } = tf.fixedLocation.rectangle;

      if (tf.fixedLocation.rectangle.height < 0) {
        height = -height;
        y -= height;
      }

      if (tf.fixedLocation.rectangle.width < 0) {
        width = -width;
        x -= width;
      }

      return updateRectangleHelper(state, tf, {
        x,
        y,
        width,
        height,
      });
    }
  );
};

export const _transformRectangle = (
  state: TemplateFieldState,
  action: PayloadAction<{
    id: string;
    x: number;
    y: number;
    width: number;
    height: number;
    scaleX: number;
    scaleY: number;
  }>
) => {
  const {
    id: rectangleId,
    x,
    y,
    width,
    height,
    scaleX,
    scaleY,
  } = action.payload;
  state.templateFields = state.templateFields.map(
    (tf): TemplateFieldV2 => {
      if (tf.locationType !== "fixed") {
        return tf;
      }
      if (!tf.fixedLocation) {
        return tf;
      }
      if (rectangleId !== tf.fixedLocation.rectangle.id) {
        return tf;
      }

      return updateRectangleHelper(state, tf, {
        x,
        y,
        width,
        height,
        scaleX,
        scaleY,
      });
    }
  );
};

export const _updateFixedLocation = (
  state: TemplateFieldState,
  action: PayloadAction<{
    id: string;
    update: Partial<FixedLocation["pageLocation"]>;
  }>
) => {
  const { id, update } = action.payload;

  state.templateFields = state.templateFields.map(
    (tf): TemplateFieldV2 => {
      if (tf.id !== id) {
        return tf;
      }
      if (!tf.fixedLocation) {
        return tf;
      }
      if (!state.page.page) {
        return tf;
      }
      const rectangle = doRectangleInverseMath(
        { ...tf.fixedLocation.pageLocation, ...update },
        {
          stageHeight: state.stage?.height,
          stageWidth: state.stage?.width,
        },
        {
          pageHeight: state.page.page.height,
          pageWidth: state.page.page.width,
        }
      );
      return {
        ...tf,
        fixedLocation: {
          pageLocation: {
            ...tf.fixedLocation.pageLocation,
            ...update,
          },
          rectangle: { ...tf.fixedLocation.rectangle, ...rectangle },
        },
      };
    }
  );
};

export const _updateRectanglesOnPageChange = (
  state: TemplateFieldState,
  action: PayloadAction<void>
) => {
  state.templateFields = state.templateFields.map(
    (tf): TemplateFieldV2 => {
      if (!state.page.page) {
        return tf;
      }
      let rectangle;
      if (tf.fixedLocation) {
        rectangle = doRectangleInverseMath(
          tf.fixedLocation.pageLocation,
          {
            stageHeight: state.stage?.height,
            stageWidth: state.stage?.width,
          },
          {
            pageHeight: state.page.page.height,
            pageWidth: state.page.page.width,
          }
        );
      }

      return {
        ...tf,
        fragments: {
          ...tf.fragments,
          fragments: tf.fragments.fragments.map((f) => {
            if (!state.page.page) {
              return f;
            }
            const updatedRectangle = doRectangleInverseMath(
              f.fragment,
              {
                stageHeight: state.stage?.height,
                stageWidth: state.stage?.width,
              },
              {
                pageHeight: state.page.page.height,
                pageWidth: state.page.page.width,
              }
            );
            return {
              ...f,
              rectangle: {
                ...f.rectangle,
                ...updatedRectangle,
              },
            };
          }),
        },
        fixedLocation: !tf.fixedLocation
          ? null
          : {
              ...tf.fixedLocation,
              rectangle: { ...tf.fixedLocation.rectangle, ...rectangle },
            },
      };
    }
  );
};

export const _expandRectangle = (
  state: TemplateFieldState,
  action: PayloadAction<{
    id: string;
    x: number;
    y: number;
  }>
) => {
  const { x, y, id: rectangleId } = action.payload;
  state.templateFields = state.templateFields.map(
    (tf): TemplateFieldV2 => {
      if (!tf.fixedLocation || !tf.fixedLocation.rectangle) {
        return tf;
      }
      if (rectangleId !== tf.fixedLocation.rectangle.id) {
        return tf;
      }
      return updateRectangleHelper(state, tf, {
        width: x - tf.fixedLocation.rectangle.x,
        height: y - tf.fixedLocation.rectangle.y,
      });
    }
  );
};

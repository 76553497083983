import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, OverlayTrigger, Popover } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setExternalFilters } from "src/redux/reducers/ExternalFilter";
import Select from "react-select";
import { setRefreshKey } from "src/redux/reducers/TransactionManager";
import { options } from "src/views/Pages/ExtractSingle/v2/QboOptions/Options";

interface FilterOption {
  label: string;
  value: string;
}

interface ColumnDef {
  field: string;
  filter: boolean;
  filterType: string; // e.g., 'text', 'number', 'date', 'select'
  defaultFilterType: string;
  label: string;
  options?: { label: string; value: any }[]; // Add options for select type
}

interface FilterState {
  [key: string]: {
    filterType: string;
    type: string;
    filterValue: string | number;
    filterValueTo?: string | number;
  };
}

interface ExternalFilterProps {
  columnDefs: ColumnDef[];
  gridApi: any;
}

export const ExternalFilter: React.FC<ExternalFilterProps> = ({
  columnDefs,
  gridApi,
}) => {
  const dispatch = useDispatch();
  const [showPopover, setShowPopover] = useState<{ [key: string]: boolean }>(
    {}
  );
  const [hovered, setHovered] = useState<string | null>(null);
  const [filters, setFilters] = useState<FilterState>({});

  const renderFilterOptions = (type: string): FilterOption[] => {
    switch (type) {
      case "text":
        return [
          { label: "Contains", value: "contains" },
          { label: "Not contains", value: "notContains" },
          { label: "Equals", value: "equals" },
          { label: "Not equal", value: "notEqual" },
          { label: "Starts with", value: "startsWith" },
          { label: "Ends with", value: "endsWith" },
        ];
      case "number":
        return [
          { label: "Equals", value: "equals" },
          { label: "Not equal", value: "notEqual" },
          { label: "Less than", value: "lessThan" },
          { label: "Less than or equal", value: "lessThanOrEqual" },
          { label: "Greater than", value: "greaterThan" },
          { label: "Greater than or equal", value: "greaterThanOrEqual" },
          { label: "In range", value: "inRange" },
        ];
      case "date":
        return [
          { label: "Equals", value: "equals" },
          { label: "Not equal", value: "notEqual" },
          { label: "Before", value: "lessThan" },
          { label: "After", value: "greaterThan" },
          { label: "In range", value: "inRange" },
        ];
      case "select":
        return [
          { label: "Equals", value: "equals" },
          { label: "Not equal", value: "notEqual" },
        ];
      // multicheckbox
      // boolean
      default:
        return [];
    }
  };

  const clearFilter = (field: string) => {
    if (!gridApi) {
      return;
    }
    const filterInstance = gridApi.getFilterInstance(field);
    filterInstance.setModel(null);
    gridApi.onFilterChanged();

    setFilters((prevFilters) => {
      const newFilters = { ...prevFilters };
      delete newFilters[field];
      return newFilters;
    });

    setShowPopover((prevShowPopover) => {
      const newShowPopover = { ...prevShowPopover };
      newShowPopover[field] = false;
      return newShowPopover;
    });
  };

  const formatDateForFilter = (dateString: string | number | undefined) => {
    if (!dateString) return null;
    // // Convert MM/DD/YYYY to YYYY-MM-DD
    // const [month, day, year] = dateString.toString().split("/");
    // return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    // return new Date(dateString);
    return dateString;
  };

  const onApplyFilter = (field: string) => {
    if (!gridApi) {
      return;
    }
    const columnDef = columnDefs.find((def) => def.field === field);
    if (!columnDef) return;

    const filterInstance = gridApi.getFilterInstance(field);
    const currentFilter = filters[field] || {};

    const updatedFilter = {
      ...currentFilter,
      filterType: currentFilter.filterType || columnDef.defaultFilterType,
    };

    // Add special handling for "Uncategorized" category
    if (
      false &&
      field === "category" &&
      updatedFilter.filterValue === "Uncategorized"
    ) {
      filterInstance.setModel({
        type: "blank",
        filter: true,
      });
    } else if (columnDef.filterType === "date") {
      const dateFrom = formatDateForFilter(updatedFilter.filterValue);
      const dateTo = formatDateForFilter(updatedFilter.filterValueTo);

      // console.log({ dateFrom, dateTo, field, type: updatedFilter.filterType });
      const filterModel = {
        type: updatedFilter.filterType,
        dateFrom,
        dateTo,
        filterType: "date",
        comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
          // console.log("Comparator called:", {
          //   filterLocalDateAtMidnight,
          //   cellValue,
          // });
          if (!cellValue) return 0;

          const cellDate = new Date(cellValue);

          if (cellDate < filterLocalDateAtMidnight) {
            return -1;
          } else if (cellDate > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      };

      // console.log("Filter model:", filterModel);
      filterInstance.setModel(filterModel);
      // console.log("Filter instance model:", filterInstance.getModel());

      // const column = gridApi.getColumnDef(field);
      // column.valueGetter = (params: any) => {
      //   return params.data[field] ? new Date(params.data[field]) : null;
      // };
    } else if (columnDef.filterType === "number") {
      const filterModel = {
        filterType: "number",
        type: updatedFilter.filterType,
        filter:
          field === "pageNumber"
            ? parseInt(updatedFilter.filterValue as string) - 1
            : parseFloat(updatedFilter.filterValue as string),
        filterTo:
          updatedFilter.filterType === "inRange"
            ? field === "pageNumber"
              ? parseInt(updatedFilter.filterValueTo as string) - 1
              : parseFloat(updatedFilter.filterValueTo as string)
            : null,
      };

      filterInstance.setModel(filterModel);
      const actualModel = filterInstance.getModel();
      // console.log("Original model:", filterModel);
      // console.log("Actual model after setting:", actualModel);

      // console.log({ filterModel, x: 1 });
    } else {
      console.log({
        type: updatedFilter.filterType,
        filter: updatedFilter.filterValue,
      });
      filterInstance.setModel({
        type: updatedFilter.filterType,
        filter: updatedFilter.filterValue,
      });
      // console.log({
      //   y: {
      //     type: updatedFilter.filterType,
      //     filter: updatedFilter.filterValue,
      //   },
      //   x: 1,
      // });
    }

    const newFilters = {
      ...filters,
      [field]: updatedFilter,
    };
    setFilters(newFilters);

    gridApi.onFilterChanged();
    setShowPopover((prevShowPopover) => ({
      ...prevShowPopover,
      [field]: false,
    }));

    // dispatch(setRefreshKey());

    // const rows = getFilteredRows();

    dispatch(
      setExternalFilters({
        externalFilters: newFilters,
      })
    );

    if (gridApi.current) {
      gridApi.current.purgeInfiniteCache();
    }
  };

  const handleTrigger = (field: string) => {
    setShowPopover((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleFilterSubmit = (field: string, event: React.FormEvent) => {
    event.preventDefault();
    onApplyFilter(field);
  };

  const getFilteredRows = () => {
    if (!gridApi) {
      return [];
    }
    const filteredRows: any[] = [];
    gridApi.forEachNodeAfterFilter((node) => {
      filteredRows.push(node.data);
    });
    return filteredRows;
  };

  return (
    <div className="d-flex flex-wrap">
      {columnDefs.map((colDef) => {
        if (!colDef.filter) return null;

        const sortedOptions = [...(colDef.options || [])].sort((a, b) =>
          (a?.label || "").localeCompare(b?.label || "")
        );

        if (
          colDef.field === "category" &&
          (colDef.options || []).filter((x) => x.value === "Uncategorized")
            .length === 0
        ) {
          colDef.options?.unshift({
            label: " Uncategorized",
            value: "Uncategorized",
          });
        }

        return (
          <OverlayTrigger
            trigger="click"
            placement="bottom"
            overlay={
              <Popover
                id={`popover-${colDef.field}`}
                show={showPopover[colDef.field]}
              >
                <Popover.Content>
                  <form
                    onSubmit={(event) =>
                      handleFilterSubmit(colDef.field, event)
                    }
                  >
                    <div className="filter-options">
                      {colDef.filterType !== "select" && (
                        <select
                          className="form-control mb-2"
                          value={
                            filters[colDef.field]?.filterType ||
                            colDef.defaultFilterType
                          }
                          onChange={(e) =>
                            setFilters({
                              ...filters,
                              [colDef.field]: {
                                ...filters[colDef.field],
                                filterType: e.target.value,
                              },
                            })
                          }
                        >
                          {renderFilterOptions(colDef.filterType).map(
                            (option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            )
                          )}
                        </select>
                      )}
                      {colDef.filterType !== "date" &&
                        colDef.filterType !== "select" && (
                          <>
                            <input
                              className="form-control mb-2"
                              type={
                                colDef.filterType === "number"
                                  ? "number"
                                  : "text"
                              }
                              placeholder={
                                filters[colDef.field]?.filterType === "inRange"
                                  ? "From"
                                  : "Search"
                              }
                              value={filters[colDef.field]?.filterValue || ""}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  [colDef.field]: {
                                    ...filters[colDef.field],
                                    filterValue: e.target.value,
                                  },
                                })
                              }
                            />
                            {filters[colDef.field]?.filterType ===
                              "inRange" && (
                              <input
                                className="form-control mb-2"
                                type={
                                  colDef.filterType === "number"
                                    ? "number"
                                    : "text"
                                }
                                placeholder="To"
                                value={
                                  filters[colDef.field]?.filterValueTo || ""
                                }
                                onChange={(e) =>
                                  setFilters({
                                    ...filters,
                                    [colDef.field]: {
                                      ...filters[colDef.field],
                                      filterValueTo: e.target.value,
                                    },
                                  })
                                }
                              />
                            )}
                          </>
                        )}
                      {colDef.filterType === "date" && (
                        <>
                          <input
                            className="form-control mb-2"
                            type="date"
                            placeholder="YYYY-MM-DD"
                            value={filters[colDef.field]?.filterValue || ""}
                            onChange={(e) =>
                              setFilters({
                                ...filters,
                                [colDef.field]: {
                                  ...filters[colDef.field],
                                  filterType:
                                    filters[colDef.field]?.filterType ||
                                    "equals",
                                  filterValue: e.target.value,
                                },
                              })
                            }
                          />
                          {filters[colDef.field]?.filterType === "inRange" && (
                            <input
                              className="form-control mb-2"
                              type="date"
                              placeholder="YYYY-MM-DD"
                              value={filters[colDef.field]?.filterValueTo || ""}
                              onChange={(e) =>
                                setFilters({
                                  ...filters,
                                  [colDef.field]: {
                                    ...filters[colDef.field],
                                    filterType: "inRange",
                                    filterValueTo: e.target.value,
                                  },
                                })
                              }
                            />
                          )}
                        </>
                      )}
                      {colDef.filterType === "select" && (
                        <Select
                          className="mb-2"
                          options={sortedOptions}
                          value={
                            colDef.options?.find(
                              (opt) =>
                                opt.value === filters[colDef.field]?.filterValue
                            ) || null
                          }
                          onChange={(selected) =>
                            setFilters({
                              ...filters,
                              [colDef.field]: {
                                ...filters[colDef.field],
                                filterValue: selected?.value || "",
                              },
                            })
                          }
                          isClearable
                        />
                      )}
                      <div className="d-flex justify-content-between">
                        <Button onClick={() => onApplyFilter(colDef.field)}>
                          Apply
                        </Button>
                        <Button
                          variant="secondary"
                          onClick={() =>
                            setShowPopover((prev) => ({
                              ...prev,
                              [colDef.field]: false,
                            }))
                          }
                        >
                          Close
                        </Button>
                      </div>
                    </div>
                  </form>
                </Popover.Content>
              </Popover>
            }
            key={colDef.field}
            show={showPopover[colDef.field]}
            onToggle={() => handleTrigger(colDef.field)}
            rootClose
          >
            <Button
              variant="outline-dark"
              size="sm"
              className="d-flex justify-content-start align-items-center mr-2"
            >
              {filters[colDef.field] && filters[colDef.field].filterValue && (
                <FontAwesomeIcon
                  icon={hovered === colDef.field ? "times-circle" : "times"}
                  onMouseEnter={() => setHovered(colDef.field)}
                  onMouseLeave={() => setHovered(null)}
                  onClick={(e) => {
                    e.stopPropagation();
                    clearFilter(colDef.field);
                  }}
                  className="mr-2"
                  style={{
                    color: hovered === colDef.field ? "white" : "black",
                  }}
                />
              )}
              <span className="flex-grow-1 text-left">
                {colDef.label}
                {filters[colDef.field] && filters[colDef.field].filterValue && (
                  <span>
                    {" "}
                    |{" "}
                    {`${
                      filters[colDef.field].filterType ||
                      colDef.defaultFilterType
                    }: `}{" "}
                    {`"${filters[colDef.field].filterValue}"`}
                    {filters[colDef.field].filterValueTo
                      ? `- "${filters[colDef.field].filterValueTo}"`
                      : ""}
                  </span>
                )}
              </span>
            </Button>
          </OverlayTrigger>
        );
      })}
    </div>
  );
};

export default ExternalFilter;

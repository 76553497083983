import React from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { logUserIn } from "../auth";
import queryString from "query-string";
import { trackSignupGA4 } from "../utils/utils";

/* tslint:disable:no-var-requires */
const qs = require("qs");

const ReceiveJwt: React.SFC<RouteComponentProps> = ({ history, location }) => {
  const handleJwt = (next) => {
    if (!location) {
      return;
    }
    const query = qs.parse(location.hash.substr(1));
    location.hash = "";
    if (query.token) {
      logUserIn(query.token);
      // const next = query.next ? decodeURIComponent(query.next) : "/extractdata";
      const to = next ? decodeURIComponent(next) : "/extractdata";
      // console.log({ to });
      history.push((to || "").replace("/app", ""));
    } else {
      history.push("/");
    }
  };

  const { error, success, value, currency, event, next } = queryString.parse(
    location.search
  );

  React.useEffect(() => {
    handleJwt(next);
  }, []);

  if (event === "sign_up") {
    // trackSignupGA4(undefined);
    if (window && (window as any).lintrk) {
      (window as any).lintrk("track", { conversion_id: 10568564 });
    }
    if (window && (window as any).rdt) {
      (window as any).rdt("track", "SignUp");
    }
  }

  return null;
};

export default withRouter(ReceiveJwt);

import { createSlice, PayloadAction, current } from "@reduxjs/toolkit";
import { ReduxState } from "..";
import {
  createCustomFormat,
  deleteCustomFormat,
  getCustomFormats,
  getFeatureGates,
  getFeatureGatesByPlanEnabled,
  updateCustomFormat,
} from "../../docuclipper/api";
import {
  CustomFormat,
  CustomFormatItem,
  FeatureGate,
} from "../../docuclipper/DocuclipperTypes";
import { createAlert } from "./Alerts";
import { setSelectedExportType } from "./DownloadOptions";

const initialState: {
  featureGates: FeatureGate[];
  featureGatesByPlanEnabled: { [k: string]: boolean };
  loading: boolean;
} = {
  featureGates: [],
  featureGatesByPlanEnabled: {},
  loading: true,
};

const slice = createSlice({
  name: "featureGates",
  initialState,
  reducers: {
    setFeatureGates(state, action: PayloadAction<FeatureGate[]>) {
      state.featureGates = action.payload;
    },
    setFeatureGatesByPlanEnabled(
      state,
      action: PayloadAction<{ featureId: string; enabled: boolean }>
    ) {
      state.featureGatesByPlanEnabled[action.payload.featureId] =
        action.payload.enabled;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
  },
});

export const { setFeatureGates, setFeatureGatesByPlanEnabled, setLoading } =
  slice.actions;

export const fetchFeatureGates = () => async (dispatch, getState) => {
  const state: ReduxState = getState();
  // if (state.CustomFormat.loading) {
  //   return;
  // }

  try {
    // dispatch(setLoading(true));
    dispatch(setLoading(true));
    const rsp = await getFeatureGates();
    dispatch(setLoading(false));
    // dispatch(setLoading(false));
    dispatch(setFeatureGates(rsp.featureGates));
  } catch (err) {
    // dispatch(setLoading(false));
    //
    dispatch(setLoading(false));
  }
};

export const fetchFeatureGateByPlanEnabled =
  (featureId) => async (dispatch, getState) => {
    const state: ReduxState = getState();
    // if (state.CustomFormat.loading) {
    //   return;
    // }

    try {
      // dispatch(setLoading(true));
      dispatch(setLoading(true));
      const isEnabled = await getFeatureGatesByPlanEnabled(featureId);
      dispatch(setLoading(false));
      // dispatch(setLoading(false));
      dispatch(setFeatureGatesByPlanEnabled({ enabled: isEnabled, featureId }));
    } catch (err) {
      // dispatch(setLoading(false));
      //
      dispatch(setLoading(false));
    }
  };

export default slice.reducer;

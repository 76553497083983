import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: { sidebarShow: "responsive" | boolean } = {
  sidebarShow: "responsive",
};

const slice = createSlice({
  name: "StripeCustomer",
  initialState,
  reducers: {
    setSidebarShow(state, action: PayloadAction<"responsive" | boolean>) {
      state.sidebarShow = action.payload;
    },
  },
});

export const { setSidebarShow } = slice.actions;

export default slice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Column {
    field: string;
    headerName: string;
    hide?: boolean;
    width?: number; // Add width property
    configurable?: boolean;
}

interface ColumnConfigState {
    [tableId: string]: Column[]; // Use tableId as the key for different configurations
}

// Function to load initial state from local storage
const loadInitialState = (): ColumnConfigState => {
    const state: ColumnConfigState = {};
    // Assuming you have a way to get all table IDs, for example, from a predefined list or local storage
    const tableIds = ["transactionManager"]; // Replace with actual table IDs or logic to retrieve them

    tableIds.forEach(tableId => {
        const savedColumns = localStorage.getItem(`columnVisibility_${tableId}`);
        if (savedColumns) {
            state[tableId] = JSON.parse(savedColumns);
        }
    });

    return state;
};

const initialState: ColumnConfigState = loadInitialState(); // Load initial state from local storage

// Function to load columns from local storage
const loadColumnsFromLocalStorage = (tableId: string): Column[] => {
    const savedColumns = localStorage.getItem(`columnVisibility_${tableId}`);
    return savedColumns ? JSON.parse(savedColumns) : [];
};

// Function to save columns to local storage
const saveColumnsToLocalStorage = (tableId: string, columns: Column[]) => {
    localStorage.setItem(`columnVisibility_${tableId}`, JSON.stringify(columns));
};

const slice = createSlice({
    name: "AgGridColumnConfig",
    initialState,
    reducers: {
        setColumns(state, action: PayloadAction<{ tableId: string; columns: Column[] }>) {
            const { tableId, columns } = action.payload;
            state[tableId] = [...columns]; // Create a new array for columns
            saveColumnsToLocalStorage(tableId, columns); // Save to local storage
        },
        toggleColumnVisibility(state, action: PayloadAction<{ tableId: string; field: string }>) {
            const { tableId, field } = action.payload;
            const columns = state[tableId];
            if (columns) {
                const updatedColumns = columns.map(col =>
                    col.field === field ? { ...col, hide: !col.hide } : col
                );
                state[tableId] = updatedColumns; // Update the state with the new columns array
                saveColumnsToLocalStorage(tableId, updatedColumns); // Save to local storage
            }
        },
        setColumnWidth(state, action: PayloadAction<{ tableId: string; field: string; width: number }>) {
            const { tableId, field, width } = action.payload;
            const columns = state[tableId];
            if (columns) {
                const updatedColumns = columns.map(col =>
                    col.field === field ? { ...col, width } : col // Create a new array with updated width
                );
                state[tableId] = updatedColumns; // Update the state with the new columns array
                saveColumnsToLocalStorage(tableId, updatedColumns); // Save to local storage
            }
        },
        loadColumns(state, action: PayloadAction<string>) {
            const tableId = action.payload;
            const loadedColumns = loadColumnsFromLocalStorage(tableId);
            state[tableId] = [...loadedColumns]; // Create a new array for loaded columns
        },
    },
});

export const { setColumns, toggleColumnVisibility, setColumnWidth, loadColumns } = slice.actions;
export default slice.reducer;
import moment from "moment";
import React from "react";
import { Card } from "react-bootstrap";
import {
  DocuClipperBillingCycle,
  DocuClipperPlan,
  DocuClipperPricing,
  StripePlan,
  StripeSubscription,
  ContractAddonsAttributes,
  SubscriptionPreview,
  SubscriptionItemAttributes,
} from "../../../../docuclipper/DocuclipperTypes";
import PopoverHelp from "../../../Docuclipper/PopoverHelp";
import { getCurrencySymbol } from "./PlanSelector2";

type Props = {
  subscription: StripeSubscription;
  billingCycle: DocuClipperBillingCycle;
  pricing: DocuClipperPricing;
  discountedPricing: DocuClipperPricing | null;
  plan: DocuClipperPlan;
  renderButton: () => JSX.Element;
  plans: StripePlan[];
  addons: SubscriptionItemAttributes[];
  subscriptionPreview: SubscriptionPreview | null;
};

export const PlanSummary: React.FC<Props> = ({
  subscription,
  pricing,
  discountedPricing,
  billingCycle,
  plan,
  renderButton,
  plans,
  addons,
  subscriptionPreview,
}) => {
  let proration;
  const maybeProration = subscriptionPreview?.lines.filter((x) =>
    x.description.toLowerCase().includes("unused")
  );
  if (maybeProration && maybeProration?.length > 0) {
    proration = maybeProration[0].amount;
  }
  // console.log({ pricing, plan, billingCycle });
  const amount = Math.round(pricing[plan][billingCycle].amount);
  const discountedAmount = discountedPricing
    ? Math.round(discountedPricing[plan][billingCycle].amount)
    : amount;
  // const amountJsx = discountedAmount ? (
  //   <>
  //     <del className="text-muted">{`$${discountedAmount}`}</del>
  //     <span className="ml-2">{`$${amount}`}</span>
  //   </>
  // ) : (
  //   <>
  //     <span>{amount}</span>
  //   </>
  // );
  const currencySymbol = getCurrencySymbol(
    pricing[plan][billingCycle].currency
  );

  const amountJsx = <>{`${currencySymbol}${discountedAmount}`}</>;
  // const amountJsx = (
  //   <>{`${currencySymbol}${subscriptionPreview?.total || 0}`}</>
  // );
  const billingCycleStr = billingCycle === "Annually" ? "year" : "month";
  const x = plans.filter((p) => p.docuclipperPlan.name === plan);
  const pages = x.length > 0 ? x[0].docuclipperPlan.numPages : 0;
  return (
    <Card style={{ maxWidth: "350px" }}>
      <Card.Body className="p-4">
        <h4>{`DocuClipper ${plan} plan`}</h4>
        <div className="text-muted my-2">
          {amountJsx}
          {`${
            plan === "Enterprise"
              ? ` / ${billingCycleStr} / user`
              : ` every ${billingCycleStr}`
          }`}
          <span>{`${
            subscription && subscription.coupon
              ? ` (${subscription.coupon.percent_off}% off regular price)`
              : ""
          }`}</span>
        </div>
        <div>
          <ul>
            {pages && <li>{`${pages} pages / month`}</li>}
            <li>
              {["Business", "Enterprise"].includes(plan) ? (
                <>
                  <span>{`Premium support`}</span>
                  <PopoverHelp
                    placement="right"
                    id="premiumSupportHelp"
                    header=""
                  >
                    <span>Includes dedicated support manager</span>
                  </PopoverHelp>
                </>
              ) : (
                "Basic support"
              )}
            </li>
            {/* {addons.length > 0 && (
              <li>
                <strong>Addons:</strong>
                <ul>
                  {addons.map((addon) => (
                    <li
                      key={addon.id}
                    >{`${addon.name} - ${currencySymbol}${addon.amount}`}</li>
                  ))}
                </ul>
              </li>
            )}
            {proration && (
              <li>
                <strong>Proration:</strong> {`${currencySymbol}${proration}`}
              </li>
            )} */}
          </ul>
        </div>
        {subscription.status === "trialing" && (
          <>
            <div className="my-2 mb-4">
              You will be charged <strong>{amountJsx}</strong> on{" "}
              <strong>
                {moment()
                  // .unix(subscription.trialEnd || 0)
                  .format("MMMM Do, YYYY")}
              </strong>
              .
              {/* {" "}
              when your trial ends. */}
            </div>
            {/* <Row className="justify-content-between  p-3">
              <div>Billed now</div>
              <div>$0</div>
            </Row> */}
          </>
        )}
        {renderButton && renderButton()}
      </Card.Body>
    </Card>
  );
};
export default PlanSummary;

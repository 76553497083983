import { useEffect } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { removeAlert } from "../redux/reducers/Alerts";

type ReduxProps = {
  removeAlert: () => any;
};
const RouteChangeHandler: React.SFC<ReduxProps & RouteComponentProps> = ({
  history,
  removeAlert,
}) => {
  useEffect(() => {
    removeAlert();
  }, [history.location.pathname]);

  return null;
};

export default connect(null, {
  removeAlert,
})(withRouter(RouteChangeHandler));

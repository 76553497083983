import React from "react";
import ClipLoader from "react-spinners/ClipLoader";

function withLoading(Component) {
  return function WihLoadingComponent({ isLoading, ...props }) {
    if (!isLoading) return <Component {...props} />;
    return (
      <div className="sweet-loading">
        <ClipLoader
          // sizeUnit={"px"}
          size={35}
          // color={"#123abc"}
          loading={isLoading}
        />
      </div>
    );
  };
}
export default withLoading;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardElementChangeEvent, Token } from "@stripe/stripe-js";
import { Formik } from "formik";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  previewSubscription,
  trackPurchase,
  updateSubscription,
} from "../../../../docuclipper/api";
import {
  Contract,
  ContractAddonsAttributes,
  DocuClipperBillingCycle,
  DocuClipperPlan,
  DocuClipperPricing,
  StripePlan,
  StripeSubscription,
  SubscriptionItemAttributes,
  SubscriptionPreview,
} from "../../../../docuclipper/DocuclipperTypes";
import { logErrorToGA } from "../../../../docuclipper/Logger";
import { ReduxState } from "../../../../redux";
import { createAlert, CreateAlertFn } from "../../../../redux/reducers/Alerts";
import { fetchSubscription } from "../../../../redux/reducers/Subscription";
import { fetchUser } from "../../../../redux/reducers/User";
import { LoadingTrackedButton } from "../../../Docuclipper/TrackedButton";
import { CreditCardLogos } from "../CreditCardLogos";
import { StripeWrapper } from "../StripeWrapper";
import UpdateCard from "../UpdateCard";
import { getDefaultCard } from "../utils";
import BillingCycleSelector from "./BillingCycleSelector";
import PlanSummary from "./PlanSummary";

// lm.js must be initialized with your API key
type Props = {
  selectedPlan: DocuClipperPlan;
  selectedBillingCycle: DocuClipperBillingCycle;
  btnText: string;
  pricing: DocuClipperPricing;
  discountedPricing: DocuClipperPricing | null;
  subscription: StripeSubscription;
  onBack?: () => any;
  onConfirmed: () => any;
  plans: StripePlan[];
  billingCycleOptions?: DocuClipperBillingCycle[] | null;
};
type ReduxProps = {
  createAlert: CreateAlertFn;
};

export const ConfirmPlan: React.FC<
  Props & ReduxProps & RouteComponentProps
> = ({
  selectedPlan,
  selectedBillingCycle,
  createAlert,
  history,
  btnText,
  pricing,
  discountedPricing,
  subscription,
  onBack,
  onConfirmed,
  plans,
  billingCycleOptions,
}) => {
  // console.log({
  //   selectedPlan,
  //   selectedBillingCycle,
  //   discountedPricing,
  //   subscription,
  //   plans,
  // });
  const [errorInCard, setErrorInCard] = React.useState(false);
  const [cardErrorMessage, setCardErrorMessage] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const lm =
    window &&
    (window as any).LinkMink &&
    (window as any).LinkMink("pub_live_N6ZO2eP96rrXdFJQ9Vrc");

  // const stripe = useStripe();
  // const elements = useElements();
  const dispatch = useDispatch();

  const { coupon } = useSelector((state: ReduxState) => state.Plans);

  const onCardChange = (changeResponse: StripeCardElementChangeEvent) => {
    setCardErrorMessage(
      changeResponse.error ? changeResponse.error.message || "" : ""
    );
    setErrorInCard(changeResponse.error !== undefined);
  };

  const [lmData, setLmData] = React.useState("");
  React.useEffect(() => {
    if (lm) {
      setLmData(lm.getReferralData());
    }
  }, [lm]);

  // const submit = async (values, { setSubmitting, resetForm }) => {
  //   if (!stripe || !elements) {
  //     return;
  //   }

  //   if (selectedPlan === null) {
  //     return;
  //   }

  //   let token: Token | undefined = undefined;

  //   if (!subscription.customer.default_source) {
  //     const cardElement = elements.getElement(CardElement);
  //     if (!cardElement) {
  //       createAlert({
  //         type: "error",
  //         message: `Missing card.`,
  //         timeout: 0,
  //       });
  //       resetForm();
  //       return;
  //     }

  //     const { error, token: stripeToken } = await stripe.createToken(
  //       cardElement
  //     );
  //     if (error) {
  //       logErrorToGA(error.message);
  //       createAlert({
  //         type: "error",
  //         message: error.message || `Error creating billing token.`,
  //         timeout: 0,
  //       });
  //       resetForm();
  //       return;
  //     }
  //     token = stripeToken;
  //   }

  //   setLoading(true);
  //   updateSubscription({
  //     source: token ? token.id : "",
  //     plan: selectedPlan,
  //     billingCycle,
  //     coupon: coupon ? coupon.id : "",
  //     lm_data: lm ? lm.getReferralData() : undefined,
  //   })
  //     .then(() => {
  //       if (window && (window as any).lintrk) {
  //         (window as any).lintrk("track", { conversion_id: 10568572 });
  //       }
  //       const plan = plans.filter(
  //         (x) =>
  //           x.docuclipperPlan.billingCycle === billingCycle &&
  //           x.docuclipperPlan.name === selectedPlan
  //       );
  //       if (plan.length > 0) {
  //         const amount = plan[0].amount / 100.0;
  //         trackPurchase(amount);
  //       }

  //       dispatch(fetchUser());
  //       dispatch(fetchSubscription());
  //       setTimeout(() => {
  //         createAlert({
  //           type: "success",
  //           message: `Successfully switched to the ${selectedPlan} plan.`,
  //           timeout: 0,
  //         });
  //       }, 500);
  //       onConfirmed();
  //       setSubmitting(false);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       logErrorToGA(err);
  //       setSubmitting(false);
  //       setLoading(false);
  //       createAlert({
  //         type: "error",
  //         message:
  //           err.message || `Error switching to the ${selectedPlan} plan.`,
  //         timeout: 0,
  //       });
  //     });
  // };

  const [billingCycle, setBillingCycle] =
    React.useState<DocuClipperBillingCycle>(selectedBillingCycle);

  const defaultCard = getDefaultCard(subscription.customer);
  const { token, tokenStr } = useSelector((state: ReduxState) => state.Auth);

  const { contractAddons, subscriptionItems } = useSelector(
    (state: ReduxState) => state.Plans
  );

  const availableSubscriptionItems = subscriptionItems.filter(
    (item) => item.billingCycle === billingCycle
  );

  const hasItem = (item: SubscriptionItemAttributes) => {
    return contractAddons.some(
      (contractAddon) => contractAddon.subscriptionItemId === item.id
    );
  };

  const [selectedAddons, setSelectedAddons] = React.useState<
    (SubscriptionItemAttributes & { checked: boolean; quantity: number })[]
  >(
    availableSubscriptionItems.map((x) => {
      return {
        ...x,
        checked: hasItem(x),
        quantity: 1,
      };
    })
  );

  const handleAddonChange = (addon: SubscriptionItemAttributes) => {
    setSelectedAddons((prevSelectedAddons) =>
      prevSelectedAddons.map((item) =>
        item.id === addon.id ? { ...item, checked: !item.checked } : item
      )
    );
  };
  const [subscriptionPreview, setSubscriptionPreview] =
    React.useState<SubscriptionPreview>();

  // React.useEffect(() => {
  //   previewSubscription({
  //     plan: selectedPlan,
  //     billingCycle,
  //     itemIds: selectedAddons.map((x) => x.id),
  //   })
  //     .then((preview) => setSubscriptionPreview(preview))
  //     .catch((err) => null);
  // }, [selectedAddons]);

  // console.log({
  //   subscriptionItems,
  //   contractAddons,
  //   availableSubscriptionItems,
  //   selectedAddons,
  //   subscriptionPreview,
  // });
  return (
    <>
      {onBack && (
        <Button variant="link" onClick={onBack}>
          <FontAwesomeIcon icon="angle-left" />
          <span className="ml-1">Back</span>
        </Button>
      )}

      <Formik initialValues={{}} onSubmit={() => undefined /*submit*/}>
        {({ submitForm }) => (
          <>
            <Row>
              <Col sm="12" md="12" lg="6" xl="6">
                {/* <div className="">
                  {!subscription.customer.default_source && (
                    <div className="my-4 p-2">
                      <CardElement
                        options={{
                          hidePostalCode: true,
                        }}
                        onChange={onCardChange}
                        className={`form-control ${
                          errorInCard ? "is-invalid" : ""
                        }`}
                      />
                      <div className={errorInCard ? "invalid-feedback" : ""}>
                        {cardErrorMessage}
                      </div>
                      <CreditCardLogos />
                    </div>
                  )}
                  {subscription.customer.default_source &&
                    defaultCard &&
                    defaultCard.last4 && (
                      <Row className="my-2 align-items-center px-4">
                        <div className="px-2">{`Card ending in ${defaultCard.last4}`}</div>
                        <UpdateCard
                          headerText="Manage payments"
                          size="lg"
                          btnText="Update billing information"
                          btnProps={{ variant: "link" }}
                        />
                      </Row>
                    )}
                </div> */}
                <div className="my-4">
                  <BillingCycleSelector
                    subscription={subscription}
                    pricing={pricing}
                    discountedPricing={discountedPricing}
                    onSelected={(billingCycle) => setBillingCycle(billingCycle)}
                    selectedPlan={selectedPlan}
                    selectedBillingCycle={billingCycle}
                    billingCycleOptions={billingCycleOptions}
                  />
                </div>
                {/* <div className="my-4">
                  <h5>Addons</h5>
                  {selectedAddons.map((item) => (
                    <div key={item.id}>
                      <input
                        type="checkbox"
                        checked={(item as any).checked}
                        onChange={() => handleAddonChange(item)}
                      />
                      {item.name} - {item.amount} {item.currency}
                    </div>
                  ))}
                </div> */}
              </Col>
              <Col sm="12" md="12" lg="6" xl="6">
                <PlanSummary
                  plans={plans}
                  pricing={pricing}
                  discountedPricing={discountedPricing}
                  billingCycle={billingCycle}
                  plan={selectedPlan}
                  subscription={subscription}
                  addons={selectedAddons.filter((x) => (x as any).checked)}
                  subscriptionPreview={subscriptionPreview || null}
                  renderButton={() => (
                    <form
                      action="/api/v1/protected/stripe/checkout"
                      method="post"
                    >
                      <input type="hidden" name="lm_data" value={lmData} />
                      <input type="hidden" name="token" value={tokenStr} />
                      <input
                        type="hidden"
                        name="billingCycle"
                        value={billingCycle}
                      />
                      <input type="hidden" name="plan" value={selectedPlan} />
                      {selectedAddons
                        .filter((x) => (x as any).checked)
                        .map((addon) => (
                          <input
                            key={`id-${addon.id}`}
                            type="hidden"
                            name={`addons[]`}
                            value={addon.id}
                          />
                        ))}
                      {selectedAddons
                        .filter((x) => (x as any).checked)
                        .map((addon) => (
                          <input
                            key={`quantity-${addon.quantity}`}
                            type="hidden"
                            name={`quantity[]`}
                            value={addon.quantity}
                          />
                        ))}
                      <Button type="submit">{btnText}</Button>
                    </form>
                  )}
                />
              </Col>
            </Row>
          </>
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};
const ConfirmPlan2 = connect(mapStateToProps, { createAlert })(
  withRouter(ConfirmPlan)
);

const Wrapped = (props: Props) => (
  // <StripeWrapper>
  <ConfirmPlan2 {...props} />
  // </StripeWrapper>
);

export default Wrapped;

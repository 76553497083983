import { PayloadAction } from "@reduxjs/toolkit";
import {
  Fragment,
  VerticalLine,
} from "../../../../docuclipper/DocuclipperTypes";
import { doRectangleInverseMath } from "../../../../rectangle-math";
import uuidv4 from "uuid/v4";
import { TemplateFieldState } from "../../TemplateFieldTypes";

export const _load = (
  state: TemplateFieldState,
  action: PayloadAction<{ id: string }>
) => {
  const { id } = action.payload;

  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }
    return {
      ...tf,
      fragments: {
        ...tf.fragments,
        loading: true,
        error: null,
      },
    };
  });
};

export const _setFragment = (
  state: TemplateFieldState,
  action: PayloadAction<{ id: string; fragment: Fragment }>
) => {
  const { id, fragment } = action.payload;

  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }
    if (!tf.fixedLocation) {
      return tf;
    }
    if (!state.page) {
      return tf;
    }

    const { height, width, x, y } = doRectangleInverseMath(
      fragment,
      {
        stageHeight: state.stage?.height,
        stageWidth: state.stage?.width,
      },
      {
        pageHeight: (state.page as any).page.height,
        pageWidth: (state.page as any).page.width,
      }
    );

    const rectangleId = uuidv4();
    return {
      ...tf,

      fragments: {
        ...tf.fragments,
        selectedFragmentId: rectangleId,
        loading: false,
        fragments: [
          {
            ...tf.fragments.fragments[0],
            fragment,
            rectangle: {
              draggable: false,
              fill: "yellow",
              height,
              width,
              x,
              y,
              scaleX: 1.0,
              scaleY: 1.0,
              id: rectangleId,
              templateFieldId: "",
            },
          },
        ],
      },
    };
  });
};

export const _clearVFragments = (
  state: TemplateFieldState,
  action: PayloadAction<void>
) => {
  state.templateFields = state.templateFields.map((tf) => {
    // if (tf.id !== id) {
    //   return tf;
    // }
    tf.fragments.fragments = [];
    return tf;
  });
};

export const _setVFragments = (
  state: TemplateFieldState,
  action: PayloadAction<{ id: string; fragments: Fragment[] }>
) => {
  const { id, fragments } = action.payload;

  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }
    tf.fragments.loading = false;

    // if (!state.page.page) {
    //   return tf;
    // }

    if (fragments.length === 0) {
      return tf;
    }
    const fragment = fragments[0];
    const { x, y, width, height } = doRectangleInverseMath(
      fragment,
      {
        stageHeight: state.stage ? state.stage.height : 0,
        stageWidth: state.stage ? state.stage.width : 0,
      },
      {
        pageHeight: state.page.page ? state.page.page.height : 0,
        pageWidth: state.page.page ? state.page.page.width : 0,
      }
    );
    const rectangleId = uuidv4();

    return {
      ...tf,
      tableConfig: {
        ...tf.tableConfig,
        columns: tf.tableConfig.columns.map((c, i) => {
          const newX = x + (width * c.columnWidthPercentage) / 100;
          if (i > 0) {
            // update prev line
            (tf.tableConfig.columns[i - 1].line
              .verticalLine as VerticalLine).maxX = newX;
          }
          if (i < tf.tableConfig.columns.length - 1) {
            // update prev line
            (tf.tableConfig.columns[i + 1].line
              .verticalLine as VerticalLine).minX = newX;
          }
          const verticalLine = {
            ...(c.line.verticalLine as VerticalLine),
            x: newX,
            y0: y,
            y1: y + height,
          };
          if (i === 0) {
            verticalLine.minX = x;
          }
          if (i === tf.tableConfig.columns.length - 1) {
            verticalLine.maxX = x + width;
          }
          return {
            ...c,
            line: {
              ...c.line,
              verticalLine,
            },
          };
        }),
      },

      fragments: {
        ...tf.fragments,
        selectedFragmentId: rectangleId,
        loading: false,
        fragments: fragments.map((f, i) => {
          // if (!state.page.page) {
          //   return f;
          // }
          const { height, width, x, y } = doRectangleInverseMath(
            f,
            {
              stageHeight: state.stage?.height,
              stageWidth: state.stage?.width,
            },
            {
              pageHeight: state.page.page ? state.page.page.height : 0,
              pageWidth: state.page.page ? state.page.page.width : 0,
            }
          );

          return {
            fragment: { ...f },
            rectangle: {
              draggable: false,
              fill: "yellow",
              id: i === 0 ? rectangleId : uuidv4(),
              scaleX: 1.0,
              scaleY: 1.0,
              templateFieldId: tf.id,
              width,
              height,

              x,
              y,
            },
          };
        }),
      },
    };
  });
};
export const _setError = (
  state: TemplateFieldState,
  action: PayloadAction<{ id: string; error: string }>
) => {
  const { id, error } = action.payload;

  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }
    return {
      ...tf,
      fragments: {
        ...tf.fragments,
        loading: false,
        error,
      },
    };
  });
};

export const _updateFragmentRedux = (
  state: TemplateFieldState,
  action: PayloadAction<{ id: string; fragment: Fragment }>
) => {
  const { id, fragment } = action.payload;

  state.templateFields = state.templateFields.map((tf) => {
    if (tf.id !== id) {
      return tf;
    }
    return {
      ...tf,
      fragments: {
        ...tf.fragments,
        loading: false,
        fragments: tf.fragments.fragments.map((f) => {
          if (f.fragment.id !== fragment.id) {
            return f;
          }
          return {
            ...f,
            fragment: {
              ...f.fragment,
              ...fragment,
            },
          };
        }),
      },
    };
  });
};

export const _jobDataLoad = (state, action: PayloadAction<{}>) => {
  state.job.error = null;
  state.job.loading = true;
};

export const _jobDataSetData = (state, action: PayloadAction<void>) => {
  state.job.loading = false;
};

export const _jobDataSetError = (state, action: PayloadAction<string>) => {
  state.job.error = action.payload;
  state.job.loading = false;
};

import React, { useState, useEffect } from "react";
import { Modal, Table, Alert, Spinner } from "react-bootstrap";
import { getApprovalHistory } from "src/docuclipper/api";

interface ApprovalHistoryProps {
  show: boolean;
  onHide: () => void;
  transactionLineId?: number | string;
}

interface HistoryEntry {
  id: number;
  action: "approved" | "rejected" | "pending";
  createdAt: string;
  userEmail: string;
  comment?: string;
  User?: {
    email?: string;
  };
}

export const ApprovalHistory: React.FC<ApprovalHistoryProps> = ({
  show,
  onHide,
  transactionLineId,
}) => {
  const [history, setHistory] = useState<HistoryEntry[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    const loadHistory = async () => {
      if (!show || !transactionLineId) return;

      setLoading(true);
      setError("");

      try {
        const response = await getApprovalHistory(Number(transactionLineId));
        setHistory(response);
      } catch (err) {
        setError("Failed to load approval history");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    loadHistory();
  }, [transactionLineId, show]);

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header
        closeButton
        onPointerEnterCapture={() => {}}
        onPointerLeaveCapture={() => {}}
        placeholder=""
      >
        <Modal.Title>Approval History</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {loading && (
          <div className="text-center p-4">
            <Spinner animation="border" />
          </div>
        )}

        {error && <Alert variant="danger">{error}</Alert>}

        {!loading && !error && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Date</th>
                <th>User</th>
                <th>Action</th>
                <th>Comment</th>
              </tr>
            </thead>
            <tbody>
              {history.map((entry) => (
                <tr key={entry.id}>
                  <td>{new Date(entry.createdAt).toLocaleString()}</td>
                  <td>{entry?.User?.email || ""}</td>
                  <td>
                    <span
                      className={`text-${
                        entry.action === "approved"
                          ? "success"
                          : entry.action === "rejected"
                          ? "danger"
                          : "warning"
                      }`}
                    >
                      {entry.action.charAt(0).toUpperCase() +
                        entry.action.slice(1)}
                    </span>
                  </td>
                  <td>{entry.comment || "-"}</td>
                </tr>
              ))}
              {history.length === 0 && (
                <tr>
                  <td colSpan={4} className="text-center">
                    No approval history found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </Modal.Body>
    </Modal>
  );
};

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faEye,
  faRandom,
  faCog,
  faTable,
  faFont,
  faCopy,
  faQuestionCircle,
  faTrash,
  faCut,
  faUpload,
  faDollarSign,
  faCalendar,
  faChevronUp,
  faChevronDown,
  faChevronRight,
  faAngleLeft,
  faCheck,
  faCheckCircle,
  faDownload,
  faSearch,
  faTimes,
  faTimesCircle,
  faShareAlt,
  faUndo,
  faPlay,
  faShare,
  faCrop,
  faExpand,
  faExclamationTriangle,
  faMousePointer,
  faArrowsAlt,
  faSearchPlus,
  faSearchMinus,
  faPencilAlt,
  faForward,
  faPlus,
  faMinus,
  faExternalLinkAlt,
  faPercentage,
  faUniversity,
  faHashtag,
  faArrowUp,
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faExclamationCircle,
  faFileExcel,
  faFileCsv,
  faEdit,
  faComments,
  faSignOutAlt,
  faUserCircle,
  faRedo,
  faSyncAlt,
  faSync,
  faGift,
  faStar,
  faFileExport,
  faEnvelope,
  faInfoCircle,
  faFrown,
  faHeart,
  faUserPlus,
  faHandshake,
  faHandPointer,
  faLock,
  faToggleOn,
  faToggleOff,
  faPlusCircle,
  faHistory,
  faCheckSquare
} from "@fortawesome/free-solid-svg-icons";

import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faCircle } from "@fortawesome/free-regular-svg-icons";

export const initIcons = () =>
  library.add(
    faEye,
    faRandom,
    faCog,
    faTable,
    faFont,
    faCopy,
    faQuestionCircle,
    faTrash,
    faCut,
    faUpload,
    faDollarSign,
    faCalendar,
    faChevronUp,
    faChevronDown,
    faChevronRight,
    faAngleLeft,
    faCheck,
    faCheckCircle,
    faDownload,
    faSearch,
    faTimes,
    faTimesCircle,
    faShareAlt,
    faUndo,
    faPlay,
    faShare,
    faCrop,
    faExpand,
    faExclamationTriangle,
    faMousePointer,
    faArrowsAlt,
    faSearchPlus,
    faSearchMinus,
    faPencilAlt,
    faForward,
    faUpload,
    faPlus,
    faMinus,
    faExternalLinkAlt,
    faPercentage,
    faUniversity,
    faHashtag,
    faArrowUp,
    faArrowDown,
    faArrowLeft,
    faArrowRight,
    faExclamationCircle,
    faChevronDown,
    faChevronUp,
    faExternalLinkAlt,
    faExclamationTriangle,
    faFileExcel,
    faFileCsv,
    faGoogle,
    faEdit,
    faComments,
    faSignOutAlt,
    faUserCircle,
    faRedo,
    faSyncAlt,
    faSync,
    faGift,
    faStar,
    faFileExport,
    faEnvelope,
    faInfoCircle,
    faFrown,
    faHeart,
    faUserPlus,
    faHandshake,
    faHandPointer,
    faLock,
    faCircle,
    faToggleOn,
    faToggleOff,
    faPlusCircle,
    faHistory,
    faCheckSquare
  );

// export const setupGA = () => {
//   const accountId =
//     process.env.NODE_ENV === "production" ? "UA-132343690-1" : "UA-132343690-2";

//   GoogleAnalytics.initialize(accountId, { debug: false });
//   GoogleAnalytics.plugin.require("linkid");
//   GoogleAnalytics.set(getFields());
// };

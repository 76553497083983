import React, { useState } from "react";
import { Modal, Button, Popover, OverlayTrigger } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { addDocumentsToJob } from "src/docuclipper/api";
import { ReduxState } from "src/redux";
import { createAlert } from "src/redux/reducers/Alerts";
import { clearFiles } from "src/redux/reducers/Files";
import { setJobData } from "src/redux/reducers/JobData";
import { fetchJobDocuments } from "src/redux/reducers/JobDocument";
import Dropzone3 from "src/views/Docuclipper/Dropzone3";
import { useHistory, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddDocumentsPopover = () => {
  const [fileType, setFileType] = useState("pdf"); // State to manage selected file type
  const history = useHistory();
  const { job } = useSelector((state: ReduxState) => state.JobData);

  const handleAddDocuments = () => {
    if (fileType === "pdf") {
      history.push(`/extract/bankMode?moveToJobId=${job?.id}`); // Updated to include moveToJobId
    } else if (fileType === "csv") {
      history.push(`/extract/csv2qbo?moveToJobId=${job?.id}`); // Updated to include moveToJobId
    }
  };

  return (
    <OverlayTrigger
      trigger="click"
      placement="left"
      overlay={
        <Popover id="add-documents-popover">
          <Popover.Title as="h3">Add Documents</Popover.Title>
          <Popover.Content>
            <div>
              <h5>Select Document Type</h5>
              <div style={{ marginBottom: "10px" }}>
                <label style={{ marginRight: "15px" }}>
                  <input
                    type="radio"
                    value="pdf"
                    checked={fileType === "pdf"}
                    onChange={() => setFileType("pdf")}
                  />
                  <span className="ml-1">PDF</span>
                </label>
                <label style={{ marginRight: "15px" }}>
                  <input
                    type="radio"
                    value="csv"
                    checked={fileType === "csv"}
                    onChange={() => setFileType("csv")}
                  />
                  <span className="ml-1">CSV</span>
                </label>
              </div>
              <Button variant="primary" onClick={handleAddDocuments}>
                Add Documents
              </Button>
            </div>
          </Popover.Content>
        </Popover>
      }
    >
      <Button variant="link">
        <FontAwesomeIcon icon="plus" />
        <span className="ml-1">Add Documents</span>
      </Button>
    </OverlayTrigger>
  );
};

export default AddDocumentsPopover;

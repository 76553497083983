import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "..";
import {
  createTagRegexes,
  createTagRegexesSet,
  createTagRule,
  getCategorizedTransactions,
  getTagRegexes,
  getTaxRegexesByType,
  updateTagRegexes,
  updateTagRule,
} from "../../docuclipper/api";
import {
  DocType,
  TagCategoryRule,

  TagRegexes,
  TagRegexesSets,
  TagRegexesType,
  TagRule,
} from "../../docuclipper/DocuclipperTypes";
import { createAlert } from "./Alerts";
import { randomColor, setByCategoryKeyword, setCategories } from "./Categories";
import { setTls } from "./TransactionManager";
import { deleteTagRegexes } from '../../docuclipper/api';

const initialState: {
  loading: boolean;
  pieLoading: boolean;
  tagRegexes: TagRegexes[];
  tagRules: TagRule[];
  tagRegexesSets: TagRegexesSets[];
  selectedTagRegexesSet: TagRegexesSets | null;
  byCategoryKeyword: any[];
} = {
  loading: false,
  pieLoading: false,
  tagRegexes: [],
  tagRules: [],
  tagRegexesSets: [],
  selectedTagRegexesSet: null,
  byCategoryKeyword: [],
};

const slice = createSlice({
  name: "TagRegexes",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setPieLoading(state, action: PayloadAction<boolean>) {
      state.pieLoading = action.payload;
    },
    setTagRegexes(state, action: PayloadAction<TagRegexes[]>) {
      state.tagRegexes = action.payload;
    },
    addTagRegexes(state, action: PayloadAction<TagRegexes>) {
      state.tagRegexes = [...state.tagRegexes, action.payload];
    },
    addTagRule2(state, action: PayloadAction<TagRule>) {
      state.tagRules = [...state.tagRules, action.payload];
    },
    addTagRegexesSet(state, action: PayloadAction<TagRegexesSets>) {
      state.tagRegexesSets = [...state.tagRegexesSets, action.payload];
    },
    updateTagRegexes2(state, action: PayloadAction<{ id; tag; tagLabel; regexes, jsonRules }>) {
      state.tagRegexes = state.tagRegexes.map((x) =>
        x.id === action.payload.id
          ? {
            ...x,
            tag: action.payload.tag,
            tagLabel: action.payload.tagLabel,
            regexes: action.payload.regexes,
            jsonRules: action.payload.jsonRules
          }
          : x
      );
    },
    updateTagRule2(state, action: PayloadAction<TagRule>) {
      state.tagRules = state.tagRules.map((x) =>
        x.id === action.payload.id ? { ...x, ...action.payload } : x
      );
    },
    setTagRegexesSets(state, action: PayloadAction<TagRegexesSets[]>) {
      state.tagRegexesSets = action.payload;
      if (!state.selectedTagRegexesSet && action.payload.length > 0) {
        state.selectedTagRegexesSet = action.payload[0];
      }
    },
    setSelectedTagRegexesSet(state, action: PayloadAction<string>) {
      const matches = state.tagRegexesSets.filter(
        (x) => x.name === action.payload
      );
      if (matches.length > 0) {
        state.selectedTagRegexesSet = matches[0];
      } else {
        state.selectedTagRegexesSet = null;
      }
    },
    setByCategoryKeyword(state, action: PayloadAction<any[]>) {
      state.byCategoryKeyword = action.payload;
    },
  },
});

export const {
  setLoading,
  setPieLoading,
  setTagRegexes,
  setTagRegexesSets,
  setSelectedTagRegexesSet,
  addTagRegexes,
  addTagRegexesSet,
  updateTagRegexes2,
  updateTagRule2,
  addTagRule2,

} = slice.actions;

export const fetchTagRegexes =
  (type: TagRegexesType) => async (dispatch, getState) => {
    const state: ReduxState = getState();
    if (state.TagRegexes.loading) {
      return;
    }
    dispatch(setLoading(true));

    try {
      const { tagRegexes, tagRegexesSets } = await getTagRegexes(type);
      dispatch(setTagRegexes(tagRegexes));
      dispatch(setTagRegexesSets(tagRegexesSets));
      dispatch(setLoading(false));
      // }
    } catch (err) {
      dispatch(setLoading(false));
    }
  };

export const fetchPieChart = (selectedAccount) => async (dispatch, getState) => {
  const state: ReduxState = getState();

  try {
    if (state.TagRegexes.pieLoading) {
      return;
    }
    if (!state.JobData.job) {
      return;
    }
    dispatch(setPieLoading(true));
    const jobId = state.JobData.job.id;
    const { transactions, categories, byCategoryKeyword } = await getCategorizedTransactions(
      jobId,
      selectedAccount
    );
    dispatch(setTls({ transactions, resetPages: false }));
    dispatch(
      setCategories(
        categories
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((c, i) => ({
            ...c,
            color: randomColor(i),
          }))
      )
    );
    dispatch(
      setByCategoryKeyword(
        byCategoryKeyword
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((c, i) => ({
            ...c,
            color: randomColor(i + categories.length),
          }))
      )
    );
    dispatch(setPieLoading(false));
  } catch (err) {
    dispatch(setPieLoading(false));
  }
};

export const updateTagRegexesAction =
  (id, tag, tagLabel, regexes, rule) => async (dispatch, getState) => {
    try {
      updateTagRegexes(id, tag, tagLabel, regexes, rule)
        .then((res) => {
          dispatch(updateTagRegexes2({ id, tag, tagLabel, regexes, jsonRules: res.jsonRules }));
        })
        .catch((err) => null);
    } catch (err) { }
  };

export const deleteTagRegexesAction = (id) => async (dispatch, getState) => {
  try {
    deleteTagRegexes(id)
      .then(() => {
        dispatch(
          createAlert({
            message: "Rule deleted successfully",
            timeout: 3000,
            type: "success",
          })
        );
      })
      .catch((err) => null);
  } catch (err) { }
}

export const updateTagRuleAction =
  (rule: TagRule) => async (dispatch, getState) => {
    try {
      updateTagRule(rule)
        .then(() => {
          dispatch(updateTagRule2(rule));
        })
        .catch((err) => null);
    } catch (err) { }
  };

export const updateTagCategoryRuleAction =
  (rule: TagRule) => async (dispatch, getState) => {
    try {
      updateTagRule(rule)
        .then(() => {
          dispatch(updateTagRule2(rule));
        })
        .catch((err) => null);
    } catch (err) { }
  };

export const addNewTag = (set, tag, tagLabel, regexes, rule: TagCategoryRule) => async (dispatch, getState) => {
  try {
    createTagRegexes(tag, tagLabel, regexes, rule)
      .then((rsp) => {
        // console.log("addNewTag -> createTagRegexes -> rsp", rsp);
        dispatch(
          addTagRegexes({
            id: rsp.id,
            regexes: rsp.regexes,
            tag: rsp.tag,
            tagLabel: rsp.tagLabel,
            jsonRules: rsp.jsonRules
          })
        );
        createTagRegexesSet(set, rsp.id, "categorize")
          .then((rsp2) => {
            dispatch(addTagRegexesSet(rsp2));
          })
          .catch((err) => dispatch(createAlert({
            message: err.message || "Error adding category",
            timeout: 0,
            type: "error",
          })));
      })
      .catch((err) => null);
  } catch (err) { }
};

export const addTagRule = (rule: TagRule) => async (dispatch, getState) => {
  const state: ReduxState = getState();

  try {
    createTagRule(rule)
      .then((rsp) => {
        dispatch(addTagRule2(rsp));
        createTagRegexesSet(
          state.TagRegexes.selectedTagRegexesSet,
          rsp.id,
          "tag"
        )
          .then((rsp2) => {
            dispatch(addTagRegexesSet(rsp2));
          })
          .catch((err) => dispatch(createAlert({
            message: err.message || "Error adding rules",
            timeout: 0,
            type: "error",
          })));
      })
      .catch((err) => null);
  } catch (err) { }
};

export const addNewCategoryGroup = (name, rule: TagCategoryRule) => async (dispatch, getState) => {
  if (!name) {
    dispatch(
      createAlert({
        message: "Need to specify the category group name",
        timeout: 0,
        type: "error",
      })
    );
    return;
  }
  try {
    createTagRegexes(`${name} - default category`, "", "[]", rule)
      .then((rsp) => {
        dispatch(
          addTagRegexes({
            id: rsp.id,
            regexes: rsp.regexes,
            tag: rsp.tag,
            tagLabel: rsp.tagLabel,
            jsonRules: rsp.jsonRules
          })
        );
        createTagRegexesSet(name, rsp.id, "categorize")
          .then((rsp2) => {
            dispatch(addTagRegexesSet(rsp2));
            dispatch(setSelectedTagRegexesSet(rsp2.name));
          })
          .catch((err) => dispatch(createAlert({
            message: err.message || "Error adding category",
            timeout: 0,
            type: "error",
          })));
      })
      .catch((err) => null);
  } catch (err) { }
};

export default slice.reducer;

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../../redux";
import { closeModal } from "../../../../redux/reducers/ModalManager";
import { fetchUser } from "../../../../redux/reducers/User";
import { ReferFriendPromotionModal } from "./ReferFriendPromotion";

export const Promotions: React.SFC = () => {
  const dispatch = useDispatch();

  const { activeModal } = useSelector(
    (state: ReduxState) => state.ModalManager
  );
  const { user, error } = useSelector((state: ReduxState) => state.User);

  React.useEffect(() => {
    if (!user && !error) {
      dispatch(fetchUser());
    }
  }, []);

  return (
    <>
      {user && (
        <ReferFriendPromotionModal
          user={user}
          open={activeModal === "refer"}
          onHide={() => dispatch(closeModal("refer"))}
        />
      )}
    </>
  );
};
export default Promotions;

import React from "react";
import TagCard from "./TagCard";
import { Row, Col } from "react-bootstrap";
import { TagCategoryRule } from "src/docuclipper/DocuclipperTypes";
import { updateTagRegexesAction } from "src/redux/reducers/TagRegexes";
import { useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

type Props = {
  tags: Array<{
    id: string;
    tag: string;
    tagLabel?: string;
    regexes: string;
    jsonRules: TagCategoryRule;
  }>;
  onDeleteTag: (id: string) => void;
  onAddKeyword: (tagId: string, keyword: string, isPositive: boolean) => void;
  onRemoveKeyword: (
    tagId: string,
    keyword: string,
    isPositive: boolean
  ) => void;
};

export const TagCards: React.FC<Props> = ({
  tags,
  onDeleteTag,
  onAddKeyword,
  onRemoveKeyword,
}) => {
  const dispatch = useDispatch();

  const rows = Math.ceil(tags.length / 5);

  const parseKeywords = (jsonRules, regexes) => {
    try {
      if (jsonRules) {
        const rules = JSON.parse(jsonRules);
        const positiveKeywords =
          rules.conditions.all.find(
            (condition) => condition.operator === "containsAny"
          )?.value.keywords || [];
        const negativeKeywords =
          rules.conditions.all.find(
            (condition) => condition.operator === "doesNotContainAny"
          )?.value.keywords || [];
        return { positive: positiveKeywords, negative: negativeKeywords };
      } else {
        const parsedRegexes = regexes ? JSON.parse(regexes) : [];
        return { positive: parsedRegexes, negative: [] };
      }
    } catch (error) {
      console.error("Error parsing jsonRules or regexes:", error);
      return { positive: [], negative: [] };
    }
  };

  const handleSaveTag = (tagId, newTag, newTagLabel, regexes, jsonRules) => {
    //{ id, tag, tagLabel, regexes, jsonRules: res.jsonRules }
    dispatch(
      updateTagRegexesAction(tagId, newTag, newTagLabel, regexes, jsonRules)
    );
  };

  const handleDeleteTag = (tagId: string) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div
            style={{
              zIndex: 1500,
              background: "white",
              padding: "20px",
              borderRadius: "8px",
            }}
          >
            <h1>Confirm Category Deletion</h1>
            <p>Are you sure you want to delete this category?</p>

            <button className="btn btn-secondary" onClick={onClose}>
              Cancel
            </button>
            <button
              className="btn btn-danger me-2 ml-2"
              onClick={() => {
                onDeleteTag(tagId);
                onClose();
              }}
            >
              Delete
            </button>
          </div>
        );
      },
      overlayClassName: "overlay-custom-class-name",
    });
  };

  return (
    <>
      {Array.from({ length: rows }).map((_, rowIndex) => (
        <Row key={rowIndex}>
          {tags.slice(rowIndex * 6, (rowIndex + 1) * 6).map((tag) => (
            <Col key={tag.id} md={2}>
              <TagCard
                tag={tag.tag}
                tagLabel={tag.tagLabel || ""}
                onDeleteTag={() => handleDeleteTag(tag.id)}
                onRemoveKeyword={(keyword, isPositive) =>
                  onRemoveKeyword(tag.id, keyword, isPositive)
                }
                onAddKeyword={(keyword, isPositive) =>
                  onAddKeyword(tag.id, keyword, isPositive)
                }
                onSaveTag={(newTag, newTagLabel) =>
                  handleSaveTag(
                    tag.id,
                    newTag,
                    newTagLabel,
                    tag.regexes,
                    JSON.parse(tag.regexes)
                  )
                }
                keywords={parseKeywords(tag.jsonRules, tag.regexes)}
              />
            </Col>
          ))}
        </Row>
      ))}
    </>
  );
};

export default TagCards;

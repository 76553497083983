import React from "react";
import { RouteComponentProps } from "react-router";
import { withRouter } from "react-router-dom";
import { logUserIn } from "../auth";

const QuickBooksDisconnected: React.SFC<{}> = ({}) => {
  return (
    <div
      className="p-4"
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "4rem",
        maxWidth: "600px",
        backgroundColor: "white",
      }}
    >
      <div className="h1">QuickBooks Disconnected</div>
      <div className="my-4">
        Your QuickBooks integration has been disconnected. You will no longer to
        be able to import data directly to your QuickBooks account from
        DocuClipper.
      </div>
      <div className="my-2">
        If you'd like to re-connect DocuClipper and your QuickBooks account, go
        to <a href="/manageIntegrations">Manage Integrations</a>
      </div>
      <div className="my-2">
        For general DocuClipper support contact us at{" "}
        <a href="mailto:support@docuclipper.com">support@docuclipper.com</a>
      </div>
    </div>
  );
};

export default QuickBooksDisconnected;

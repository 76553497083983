import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import React from "react";
import { ReduxState } from "..";
import { getSubscription } from "../../docuclipper/api";
import { StripeSubscription } from "../../docuclipper/DocuclipperTypes";
import { createAlert } from "./Alerts";

const initialState: {
  subscription: StripeSubscription | null;
  loading: boolean;
  error: string | null;
} = {
  subscription: null,
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "Subscription",
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
      state.error = null;
    },
    setData(state, action: PayloadAction<StripeSubscription>) {
      state.loading = false;
      state.subscription = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const { load, setData, setError } = slice.actions;

export const fetchSubscription = () => async (dispatch, getState) => {
  const state: ReduxState = getState();
  if (state.Subscription.loading) {
    return;
  }
  dispatch(load());
  try {
    const subscription = await getSubscription();
    dispatch(setData(subscription));
    if (
      ["incomplete", "incomplete_expired", "past_due", "unpaid"].includes(
        subscription.status
      )
    ) {
      const message = (
        <>
          Last payment failed
          {subscription.lastInvoice && (
            <a
              className="text-right"
              target="_blank"
              rel="noopener noreferrer"
              href={subscription.lastInvoice.hosted_invoice_url}
            >
              <span className="ml-2">Retry</span>
            </a>
          )}
        </>
      );
      dispatch(
        createAlert({
          message,
          timeout: 0,
          type: "error",
        })
      );
    }
  } catch (err) {
    dispatch(setError(err.message || "Error getting plans"));
  }
};

export default slice.reducer;

import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getBadRatings, markReviewed } from "../../../docuclipper/api";
import {
  ApiTableParams,
  BadRating,
} from "../../../docuclipper/DocuclipperTypes";
import { createAlert } from "../../../redux/reducers/Alerts";
import DocuClipperTable from "../../Docuclipper/DocuClipperTable";

type Props = {};

export const BadRatings: React.SFC<Props> = () => {
  const fetchData = ({ offset, limit, filtered, sorted }: ApiTableParams) => {
    const res = getBadRatings({
      offset,
      limit,
      filtered,
      sorted,
    });
    return res;
  };
  const dispatch = useDispatch();
  const [reviewedLoading, setReviewedLoading] = React.useState(false);
  return (
    <>
      <DocuClipperTable<BadRating>
        syncUrl={false}
        defaultPageSize={20}
        defaultSorted={[]}
        defaultFiltered={[]}
        externalLoading={reviewedLoading}
        columns={[
          {
            Header: "Template",
            accessor: "templateName",
            headerStyle: {
              fontWeight: "bold",
            },
          },
          {
            Header: "Email",
            accessor: "email",
            headerStyle: {
              fontWeight: "bold",
            },
          },
          {
            Header: "Job",
            accessor: "jobId",
            headerStyle: {
              fontWeight: "bold",
            },
            Cell: (row) => {
              return (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`/jobData?jobId=${row.original.jobId}`}
                >
                  {`Go to job ${row.original.jobId}`}
                </a>
              );
            },
          },
          {
            Header: "Rating",
            accessor: "jobId",
            headerStyle: {
              fontWeight: "bold",
            },
            Cell: (row) => {
              return (
                <Button
                  variant="link"
                  onClick={() => {
                    setReviewedLoading(true);
                    markReviewed(row.original.jobRatingId)
                      .then(() => {
                        setReviewedLoading(false);
                        dispatch(
                          createAlert({
                            message: "Reviewed",
                            timeout: 0,
                            type: "success",
                          })
                        );
                      })
                      .catch(() => {
                        setReviewedLoading(false);
                        dispatch(
                          createAlert({
                            message: "Error marking reviewed",
                            timeout: 0,
                            type: "error",
                          })
                        );
                      });
                  }}
                >
                  Mark Reviewed
                </Button>
              );
            },
          },
        ]}
        fetchData={fetchData}
        tableId="badRatings"
      />
    </>
  );
};

export default BadRatings;

import React from "react";
import { useSelector } from "react-redux";
import { ReduxState } from "src/redux";

const DisplayExternalFilters: React.FC = () => {
  const { externalFilters } = useSelector(
    (state: ReduxState) => state.ExternalFilter
  );

  return (
    <div className="external-filters-display">
      <div className="font-weight-bold">Active Filters</div>
      {Object.keys(externalFilters).length > 0 ? (
        <ul>
          {Object.entries(externalFilters).map(
            ([field, { filterType, filterValue, filterValueTo }]) => (
              <li key={field}>
                <strong>{field}</strong>: {filterType} - "{filterValue}"
                {filterValueTo ? ` to ${filterValueTo}` : ""}
              </li>
            )
          )}
        </ul>
      ) : (
        <p>No active filters.</p>
      )}
    </div>
  );
};

export default DisplayExternalFilters;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "..";
import { getQboCustomers, createQboCustomer } from "../../docuclipper/api";
import { QboCustomer } from "../../docuclipper/DocuclipperTypes";

const initialState: {
  qboCustomers: QboCustomer[];
  loading: boolean;
  error: string | null;
} = {
  qboCustomers: [],
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "QboCustomer",
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
      state.error = null;
    },
    setData(state, action: PayloadAction<QboCustomer[]>) {
      state.loading = false;
      state.qboCustomers = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    addQboCustomer(state, action: PayloadAction<QboCustomer>) {
      state.qboCustomers.unshift(action.payload);
    },
  },
});

export const { load, setError, setData, addQboCustomer } = slice.actions;

export const fetchQboCustomers = (integrationId) => async (dispatch, getState) => {
  const state: ReduxState = getState();
  if (state.QboCustomer.loading) {
    return;
  }
  dispatch(load());
  try {
    const job = state.JobData.job;
    if (job) {
      const QboCustomers = await getQboCustomers(integrationId);
      dispatch(
        setData(
          QboCustomers.map((c) => ({
            name: c.DisplayName,
            value: c.Id,
          }))
        )
      );
    }
  } catch (err) {
    dispatch(setError((err as any).message || "Error getting QboCustomers"));
  }
};

export const createAndAddQboCustomer = (integrationId, customerName) => async (dispatch, getState) => {
  try {
    const customer = await createQboCustomer(integrationId, customerName);
    dispatch(addQboCustomer({
      name: customer.DisplayName,
      value: customer.Id,
    }));
    return { name: customer.DisplayName, id: customer.Id };
  } catch (err) {
    dispatch(setError((err as any).message || "Error creating QboCustomer"));
    console.error(err);
  }
};

export default slice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "..";
import { Job } from "../../docuclipper/DocuclipperTypes";
import { hasQboHelper } from "../../utils/utils";
import { setSelectedExportType } from "./DownloadOptions";

const initialState: {
  job: Job | null;
  progress: number | null;
  lastUpdate: Date | null;
} = {
  job: null,
  progress: null,
  lastUpdate: null,
};

const slice = createSlice({
  name: "JobData",
  initialState,
  reducers: {
    clearJobData(state) {
      state.job = null;
    },
    setJob(state, action: PayloadAction<Job>) {
      state.job = action.payload;
    },
    setProgress(state, action: PayloadAction<number | null>) {
      state.progress = action.payload;
    },
    setLastUpdate(state, action: PayloadAction<Date>) {
      state.lastUpdate = action.payload;
    },
    // setJobData(
    //   state,
    //   action: PayloadAction<{
    //     job: Partial<Job>;
    //     progress?: number | null;
    //     onlyUpdateIfJobIdMatches?: boolean;
    //   }>
    // ) {
    //   if (
    //     action.payload.onlyUpdateIfJobIdMatches &&
    //     state.job &&
    //     action.payload.job.id !== state.job.id
    //   ) {
    //     return;
    //   }
    //   state.job = { ...state.job, ...action.payload.job } as any;
    //   if (action.payload.progress !== undefined) {
    //     state.progress = action.payload.progress;
    //   }

    //   state.lastUpdate = new Date();
    // },
  },
});

export const {
  clearJobData,
  setJob,
  setProgress,
  setLastUpdate /*, setJobData*/,
} = slice.actions;

export const setJobData =
  ({
    job,
    progress,
    onlyUpdateIfJobIdMatches,
  }: {
    job: Partial<Job>;
    progress?: number | null;
    onlyUpdateIfJobIdMatches?: boolean;
  }) =>
    async (dispatch, getState) => {
      const state: ReduxState = getState();

      if (
        onlyUpdateIfJobIdMatches &&
        state.JobData.job &&
        job.id?.toString() !== state.JobData.job.id.toString()
      ) {
        // console.log(`setJobData, no match, ${job.id} vs ${state.JobData.job.id}`)
        // console.log({ job, progress, onlyUpdateIfJobIdMatches })
        return;
      }
      // state.JobData.job = { ...state.JobData.job, ...job } as any;
      dispatch(setJob({ ...state.JobData.job, ...job } as any));
      if (progress !== undefined) {
        // state.JobData.progress = progress;
        dispatch(setProgress(progress));
      }

      let hasQbo = false;
      if (state.JobData.job) {
        hasQbo = hasQboHelper(state.JobData.job);
      }

      // state.JobData.lastUpdate = new Date();
      dispatch(setLastUpdate(new Date()));
      if ((job && job.isGeneric) || (state.JobData.job && !hasQbo)) {
        dispatch(setSelectedExportType("xlsx"));
      } else if (
        job &&
        job.isBankMode &&
        state.DownloadOptions.selectedExportType === "xlsx"
      ) {
        dispatch(setSelectedExportType("csv"));
      }
    };

export default slice.reducer;

import React from "react";
import { Alert } from "react-bootstrap";
import { RouteComponentProps, useLocation, withRouter } from "react-router-dom";
import {
  DocuClipperBillingCycle,
  DocuClipperPlan,
  StripePlan,
  StripeSubscription,
} from "../../../../docuclipper/DocuclipperTypes";
import {
  getDiscountedPricing,
  getPricing,
  WithAccountAndPlans,
} from "../utils";
import ConfirmPlan from "./ConfirmPlan";
import PlanSelector from "./PlanSelector";
import PlanSelector2 from "./PlanSelector2";
import { useSeat } from "../../../../custom-hooks/useSeat";
import { useSelector } from "react-redux";
import { ReduxState } from "src/redux";

type Props = {
  plans: StripePlan[];
  subscription: StripeSubscription;
  onConfirmed: () => any;
};

export const SelectPlanAfterTrial3: React.SFC<Props & RouteComponentProps> = ({
  history,
  subscription,
  plans,
  onConfirmed,
}) => {
  // select plan
  // when plan chosen => confirm plan
  // when plan confirmed => history.push('/account')
  // when plan confirmed => click on back to go to select plan
  const [step, setStep] = React.useState<"select" | "confirm">("select");
  const [selectedPlan, setSelectedPlan] =
    React.useState<DocuClipperPlan>("Business");
  const [selectedBillingCycle, setSelectedBillingCycle] =
    React.useState<DocuClipperBillingCycle>("Monthly");

  const location = useLocation();

  React.useEffect(() => {
    if (subscription.status === "active") {
      history.push(`/account/changePlans${location.search}`);
    }
  }, [subscription, history]);

  const pricing = getPricing(plans);
  if (!pricing) {
    return <Alert variant="danger">Error loading plans</Alert>;
  }
  const discountedPricing = getDiscountedPricing(pricing, subscription);
  // console.log({ discountedPricing });
  return (
    <>
      {step === "select" && (
        <>
          <PlanSelector2
            renderHeading={() => (
              <>
                {subscription.status === "trialing" && (
                  <div className="h3 text-center my-4">
                    Pick a plan to use when your free trial ends
                  </div>
                )}
              </>
            )}
            currentPlan={null}
            currentBillingCycle={null}
            pricing={pricing}
            discountedPricing={discountedPricing}
            onSelect={(plan, billingCycle) => {
              setSelectedPlan(plan);
              setSelectedBillingCycle(billingCycle);
              setStep("confirm");
            }}
          />
        </>
      )}
      {step === "confirm" && (
        <ConfirmPlan
          plans={plans}
          btnText="Start plan"
          subscription={subscription}
          pricing={pricing}
          discountedPricing={discountedPricing}
          onConfirmed={onConfirmed}
          onBack={() => setStep("select")}
          selectedPlan={selectedPlan}
          selectedBillingCycle={selectedBillingCycle}
        />
      )}
    </>
  );
};

export const _SelectPlanAfterTrial2: React.FC<
  Props &
    RouteComponentProps & {
      redirectToAccountOnConfirmed: boolean;
      onConfirmed?: () => any;
    }
> = ({ history, redirectToAccountOnConfirmed, onConfirmed, ...rest }) => {
  const { isSeat, admin } = useSeat();
  const isContractAdmin = useSelector(
    (state: ReduxState) => state.User.user?.isContractAdmin
  );
  if (!isContractAdmin) {
    let email = "";
    if (admin && admin.email) {
      email = admin.email;
    }
    return (
      <Alert variant="danger">{`Only admins can make changes to the plan. Contact ${
        email ? email : "your admin"
      } to get help.`}</Alert>
    );
  }
  return (
    <SelectPlanAfterTrial3
      {...rest}
      history={history}
      onConfirmed={() => {
        if (redirectToAccountOnConfirmed) {
          history.push("/account?section=plan");
        }
        onConfirmed();
      }}
    />
  );
};

export const SelectPlanAfterTrial = withRouter(_SelectPlanAfterTrial2);

SelectPlanAfterTrial.defaultProps = {
  redirectToAccountOnConfirmed: true,
  onConfirmed: () => null,
};

const SelectPlanAfterTrial2 = withRouter(
  WithAccountAndPlans(SelectPlanAfterTrial)
);

export default () => <SelectPlanAfterTrial2 />;

import { Field, Formik } from "formik";
import React from "react";
import { Form, FormGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { ReduxState } from "../../../../../redux";
import { formikInput } from "../../../../../utils/utils";
import PopoverHelp from "../../../../Docuclipper/PopoverHelp";
import { LoadingTrackedButton } from "../../../../Docuclipper/TrackedButton";
import { downloadSpreadsheetHelper } from "./utils";

type Props = {};
export const IifOptions: React.SFC<Props> = ({}) => {
  const [loading, setLoading] = React.useState(false);

  const dispatch = useDispatch();
  const { job } = useSelector((state: ReduxState) => state.JobData);

  const { downloadOptions } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );
  const { selectedDocumentIds, selectedFieldNames } = downloadOptions;

  //   const { selectedDocumentId } = useSelector(
  //     (state: ReduxState) => state.TransactionManager
  //   );
  const onExport = ({ accountId, bankId, accountType, fromAccount }) => {
    return downloadSpreadsheetHelper(
      job,
      "FieldsToExcel1",
      "iif",
      {
        accountId,
        bankId,
        accountType,
        fromAccount,
        getTotals: false,
      },
      selectedDocumentIds,
      selectedFieldNames,
      [],
      [],
      "date",
      "asc"
    );
  };
  const onSubmit =
    (format: "iif" | "iif") =>
    ({ accountId, bankId, accountType, fromAccount }, { resetForm }) => {
      setLoading(true);
      onExport({
        accountId,
        bankId,
        fromAccount,
        accountType,
      })
        .then(() => {
          setLoading(false);

          // resetForm();
        })
        .catch((err) => {
          setLoading(false);
          //
        });
    };

  return (
    <Formik
      initialValues={{
        accountId: "",
        bankId: "",
        accountType: "checking",
        fromAccount: "",
      }}
      validationSchema={Yup.object().shape({
        accountId: Yup.string().required("Source account is required"),
        fromAccount: Yup.string().required("Destination account is required"),
      })}
      onSubmit={onSubmit("iif")}
    >
      {({ submitForm, values }) => (
        <>
          <FormGroup>
            <Form.Label>
              <strong>Source Account</strong>
            </Form.Label>
            <PopoverHelp header="" id="iifFromAccountHelp" placement="bottom">
              The name of the account assigned to the transaction
            </PopoverHelp>
            <Field name="accountId" type="text" component={formikInput}></Field>
          </FormGroup>
          <FormGroup>
            <Form.Label>
              <strong>Destination Account</strong>
            </Form.Label>
            <PopoverHelp header="" id="iifFromAccountHelp" placement="bottom">
              The income or expense account to which you assigned the
              transaction
            </PopoverHelp>
            <Field
              name="fromAccount"
              type="text"
              component={formikInput}
            ></Field>
          </FormGroup>

          <Row className="justify-content-end mx-1">
            <LoadingTrackedButton
              isLoading={loading}
              className="btn-save-iif justify-content-center m-2"
              // disabled={anyFileUploading}
              variant="primary"
              onClick={() => {
                submitForm();
              }}
            >
              Download IIF
            </LoadingTrackedButton>
          </Row>

          {/* <FormGroup>
            <Form.Label>
              <strong>Statement Year</strong>
            </Form.Label>
            <Field name="year" type="text" component={formikInput}></Field>
          </FormGroup> */}
        </>
      )}
    </Formik>
  );
};
export default IifOptions;

import { Field, Formik } from "formik";
import React from "react";
import { Modal, Form, Button } from "react-bootstrap";
import {
  CreatableSelectField,
  amountFilterOptions,
  formikInput,
  formikSelectInput,
} from "../../../../utils/utils";
import * as Yup from "yup";
import { on } from "events";
import { TagRule } from "../../../../docuclipper/DocuclipperTypes";

type Props = {
  onSubmit: (rule: TagRule) => any;
  rule: TagRule | null;
};
const validationSchema = Yup.object().shape({
  operator: Yup.string(),
  amountValue: Yup.number(),
  keywords: Yup.array()
    .of(Yup.string().required("Required")) // Each element must be a string
    .min(1, "At least one keyword is required"),
  negativeKeywords: Yup.array().of(Yup.string().required("Required")), // Each element must be a string
  tag: Yup.string().required(),
});

export const TagRuleForm: React.FC<Props> = ({ onSubmit, rule }) => {
  const [showEditAccount, setShowEditAccount] = React.useState(false);
  const toggleShowEditAccount = () => setShowEditAccount(!showEditAccount);
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    const { amountOperator, amountValue, keywords, negativeKeywords, tag } =
      values;
    const rule: TagRule = {
      amount: {
        operator: amountOperator,
        value: amountValue,
      },
      description: {
        keywords,
        negativeKeywords,
      },
      tag,
    };
    onSubmit(rule);
    resetForm();
  };
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        amountOperator: rule ? rule.amount.operator : "",
        amountValue: rule ? rule.amount.value : "",
        keywords: rule ? rule.description.keywords : [],
        negativeKeywords: rule ? rule.description.negativeKeywords : [],
        tag: rule ? rule.tag : "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({
        isSubmitting,
        errors,
        submitForm,
        setFieldValue,
        values,
        touched,
      }) => {
        return (
          <Form>
            <div>
              <strong>
                <Form.Label>Amount</Form.Label>
              </strong>
              <Field
                name="amountOperator"
                type="select"
                component={formikSelectInput}
                onChange={(e) => {
                  setFieldValue("amountOperator", e.target.value);
                }}
              >
                {amountFilterOptions.map(({ label, value }, i) => (
                  <option key={i} value={value}>
                    {label}
                  </option>
                ))}
              </Field>
              <Field
                name="amountValue"
                type="number"
                step="0.01"
                component={formikInput}
              />
              <Field
                name="keywords"
                component={CreatableSelectField}
                initialOptions={[]}
                isMulti
                placeholder="Select or create options"
              />

              <Field
                name="negativeKeywords"
                component={CreatableSelectField}
                initialOptions={[]}
                isMulti
                placeholder="Select or create options"
              />

              <Field
                placeholder="tag"
                className=""
                type="text"
                name="tag"
                component={formikInput}
              />
              <Button
                onClick={() => {
                  submitForm();
                }}
              >
                Add Rule
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default TagRuleForm;

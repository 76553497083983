import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { ExportType } from "../../../../../docuclipper/DocuclipperTypes";
type Props = { selectedExportType: ExportType };
export const Instructions: React.FC<Props> = ({ selectedExportType }) => {
  let links: { text: string; link: string }[] = [];
  switch (selectedExportType) {
    case "csv":
      links.push({
        link: "/help-center/convert-bank-statements-to-excel/",
        text: "Excel instructions",
      });

      break;
    case "qbo":
      links.push({
        link: "/help-center/import-bank-statements-into-quickbooks-online/",
        text: "QuickBooks Online instructions",
      });
      links.push({
        link: "/help-center/import-bank-statements-into-quickbooks-desktop/",
        text: "QuickBooks Desktop instructions",
      });
      break;
    case "xero-csv":
      links.push({
        link: "/help-center/import-bank-statements-into-xero/",
        text: "Xero instructions",
      });
      break;
    case "sage-csv":
    case "sage-cloud-csv":
      links.push({
        link: "/help-center/import-bank-statements-into-sage/",
        text: "Sage instructions",
      });
      break;
    default:
      //   if (selectedExportType?.startsWith("custom-")) {
      links.push({
        link: "/help-center/convert-bank-statements-to-excel/",
        text: "Excel instructions",
      });
      //   }
      break;
  }
  return (
    <>
      {links.map((link, idx) => (
        <div className="text-right" key={idx}>
          <a href={link.link} target="_blnk" rel="noopener noreferrer">
            <div className="ml-2 div--help-video">
              <FontAwesomeIcon icon="question-circle" />
              <span className="ml-2" /*variant="link"*/>{link.text}</span>
            </div>
          </a>
        </div>
      ))}
    </>
  );
};
export default Instructions;

import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../../redux";
import { isNullOrUndefined } from "../../../../utils/utils";
import {
  setAccounts,
  setSelectedAccount,
} from "../../../../redux/reducers/TransactionManager";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { updateAccountName } from "../../../../docuclipper/api";
import { fetchTotals } from "../../../../redux/reducers/Reconciler";
import {
  fetchMfs,
  fetchTls,
} from "../../../../redux/reducers/TransactionManager";
import SelectWithBadge from "./SelectWithBadge";
import ClipLoader from "react-spinners/ClipLoader";
type Props = {
  showAccountsHeading?: boolean;
  showEditAccountName?: boolean;
  renderReconciled?: any;
};
export const AccountSelector: React.FC<Props> = ({
  showAccountsHeading,
  showEditAccountName,
}) => {
  const dispatch = useDispatch();
  const {
    tls,
    selectedDocumentId,
    reconciled,
    accounts,
    selectedAccount,
    badDates,
  } = useSelector((state: ReduxState) => state.TransactionManager);

  // const { tls: transactions } = tls;

  const { totalsByDocumentId } = useSelector(
    (state: ReduxState) => state.Reconciler
  );
  const { job } = useSelector((state: ReduxState) => state.JobData);

  const totals = !isNullOrUndefined(selectedDocumentId)
    ? totalsByDocumentId
      ? totalsByDocumentId[selectedDocumentId as string]
      : {}
    : {};

  React.useEffect(() => {
    const accounts = Object.keys(totals || {});
    dispatch(
      setAccounts({
        accounts,
        resetSelectedAccount: true,
      })
    );
    dispatch(setSelectedAccount(accounts.length > 0 ? accounts[0] : null));
  }, [selectedDocumentId]);

  React.useEffect(() => {
    dispatch(
      setAccounts({
        accounts: Object.keys(totals || {}),
        resetSelectedAccount: false,
      })
    );
  }, [JSON.stringify(totals)]);

  // const nicknames = {};
  // for (const t of transactions) {
  //   nicknames[t.account] = t.accountNickname;
  // }
  const [showEditAccount, setShowEditAccount] = React.useState(false);
  const [newAccountName, setNewAccountName] = React.useState(selectedAccount);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const toggleShowEditAccount = () => setShowEditAccount(!showEditAccount);

  React.useEffect(() => {
    setNewAccountName(selectedAccount);
  }, [selectedAccount]);

  const options = accounts.map((accountName) => ({
    label: accountName,
    value: accountName,
    reconciled: reconciled.accounts[accountName],
    reconciledType: "account",
    isBadDate: badDates.accounts[accountName],
  }));
  let selectedOption: any = null;
  const matches = options.filter((x) => x.value === selectedAccount);
  if (matches.length > 0) {
    selectedOption = matches[0];
  }
  return (
    <>
      {Object.keys(accounts).length > 0 && (
        <>
          <Row className="flex-column">
            <div
              className="d-flex"
              style={{
                justifyContent: "space-between",
                width: "95%",
              }}
            >
              {showAccountsHeading && (
                <span className="font-weight-bold">
                  Select Account ({accounts.length} available) ▾
                </span>
              )}
              {showEditAccountName && (
                <>
                  <Button
                    className="p-0"
                    variant="link"
                    onClick={toggleShowEditAccount}
                  >
                    <FontAwesomeIcon icon="cog" />
                    <span className="ml-1">Rename</span>
                  </Button>
                  <Modal show={showEditAccount} onHide={toggleShowEditAccount}>
                    <Modal.Header closeButton>
                      <Modal.Title>Rename Account</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Row>
                        <Col>
                          <Form.Group>
                            <Form.Label>Account Name</Form.Label>
                            <Form.Control
                              name="accountName"
                              value={newAccountName || ""}
                              onChange={(e) => {
                                setNewAccountName(e.target.value);
                              }}
                              type="text"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button
                        variant="secondary"
                        onClick={toggleShowEditAccount}
                      >
                        Close
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => {
                          if (job) {
                            setIsUpdating(true);
                            updateAccountName({
                              jobId: job.id,
                              documentId: selectedDocumentId,
                              newAccountName,
                              oldAccountName: selectedAccount,
                            })
                              .then(() => {
                                toggleShowEditAccount();
                                dispatch(fetchTotals());
                                dispatch(fetchTls(false));
                                dispatch(fetchMfs());
                              })
                              .catch(() => {
                                toggleShowEditAccount();
                              })
                              .finally(() => {
                                setIsUpdating(false);
                              });
                          }
                        }}
                        disabled={isUpdating}
                        className=""
                      >
                        <span className="">
                          {isUpdating ? (
                            <>
                              <ClipLoader size={14} color="#ffffff" />{" "}
                              Renaming...
                            </>
                          ) : (
                            "Rename Account"
                          )}
                        </span>
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </>
              )}
            </div>

            <div style={{ width: "95%" }}>
              <SelectWithBadge
                value={selectedOption}
                onChange={(e) => {
                  dispatch(setSelectedAccount(e.value));
                }}
                options={options}
                placeholder="Select an account..."
              />
            </div>
          </Row>
        </>
      )}
    </>
  );
};
export default AccountSelector;

AccountSelector.defaultProps = {
  showAccountsHeading: true,
  showEditAccountName: true,
  renderReconciled: () => null,
};

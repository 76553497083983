import React from "react";
import { Modal } from "react-bootstrap";
// import { DocuclipperPlan } from "../../../docuclipper/DocuclipperTypes";
import {
  CloaseUpgradePlanModalFn,
  closeUpgradePlanModal,
} from "../../../redux/reducers/UpgradePlan";

import { connect } from "react-redux";
import { DocuClipperPlan } from "../../../docuclipper/DocuclipperTypes";
// import Upgrade from "./Upgrade";

type Props = {};

type ReduxProps = {
  isOpen: boolean;
  neededPlan: DocuClipperPlan;
  feature: string;

  closeUpgradePlanModal: CloaseUpgradePlanModalFn;
};

export const UpgradeModal: React.SFC<Props & ReduxProps> = ({
  isOpen,
  closeUpgradePlanModal,
  neededPlan,
  feature,
}) => {
  const toggle = () => closeUpgradePlanModal();

  return (
    <Modal size="lg" show={isOpen} onHide={toggle}>
      <Modal.Header closeButton={true}>Upgrade plan</Modal.Header>
      <Modal.Body>{/* <Upgrade renderBanner={() => banner} /> */}</Modal.Body>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  neededPlan: state.UpgradePlan.neededPlan,
  feature: state.UpgradePlan.feature,
  isOpen: state.UpgradePlan.isOpen,
});

const mapDispatchToProps = {
  closeUpgradePlanModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeModal);

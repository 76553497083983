import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../../redux";
import { handleAddNewKeyword } from "src/utils/categoryManagement";
import { createAlert } from "../../../../redux/reducers/Alerts";
import { useTransactions } from "./hooks";

interface KeywordInputProps {
  value: string | string[];
  onChange: (value: string) => void;
}

const KeywordInput = React.memo<KeywordInputProps>(({ value, onChange }) => {
  const [localValue, setLocalValue] = useState(() =>
    Array.isArray(value) ? value.join(" ") : value
  );

  // console.log({ localValue });
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
  };

  const handleBlur = () => {
    if (localValue !== value) {
      onChange(localValue);
    }
  };

  useEffect(() => {
    setLocalValue(Array.isArray(value) ? value.join(" ") : value);
  }, [value]);

  return (
    <div className="form-group">
      <label htmlFor="keywordInput">Keyword</label>
      <input
        id="keywordInput"
        type="text"
        className="form-control"
        placeholder="Edit keyword..."
        value={localValue}
        onChange={handleChange}
        onBlur={handleBlur}
      />
    </div>
  );
});

interface CreateRuleFormProps {
  initialTransaction?: string;
  initialKeyword?: string;
  initialCategory?: string;
  showTransactionSelect?: boolean;
  onSuccess?: () => void;
}

export const CreateRuleForm: React.FC<CreateRuleFormProps> = ({
  initialTransaction = "",
  initialKeyword = "",
  initialCategory = "",
  showTransactionSelect = true,
  onSuccess,
}) => {
  const dispatch = useDispatch();
  const { tagRegexes, selectedTagRegexesSet, tagRegexesSets } = useSelector(
    (state: ReduxState) => state.TagRegexes
  );
  const transactions = useTransactions();

  const [selectedTransaction, setSelectedTransaction] =
    useState(initialTransaction);
  const [selectedKeyword, setSelectedKeyword] = useState(initialKeyword);
  const [selectedCategory, setSelectedCategory] = useState(initialCategory);

  const selectedTagIds = !selectedTagRegexesSet
    ? []
    : tagRegexesSets
        .filter((t) => t.name === selectedTagRegexesSet.name)
        .map((x) => x.tagRegexesId);

  const uncategorizedTransactions = React.useMemo(() => {
    return transactions
      .filter((t) => !t.category || t.category === "Uncategorized")
      .map((t) => ({
        ...t,
        description: Array.isArray(t.description)
          ? t.description.join(" ")
          : t.description || "",
      }));
  }, [transactions]);

  const options = React.useMemo(() => {
    return selectedTagIds
      .map((t) => {
        const tag = tagRegexes.find((x) => x.id === t);
        return {
          label: `${tag?.tag}${tag?.tagLabel ? ` - ${tag.tagLabel}` : ""}`,
          value: tag?.tag || "",
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [tagRegexes, selectedTagIds]);

  const handleCreateRule = () => {
    if (selectedCategory) {
      const matchingTag = tagRegexes.find((t) => t.tag === selectedCategory);
      if (matchingTag) {
        handleAddNewKeyword(
          dispatch,
          tagRegexes,
          matchingTag.id,
          selectedKeyword,
          true
        );
        dispatch(
          createAlert({
            message:
              "Rule created. Please re-run categorization to apply the new rule.",
            timeout: 5000,
            type: "success",
          })
        );
        setSelectedTransaction("");
        setSelectedCategory("");
        setSelectedKeyword("");
        onSuccess?.();
      } else {
        dispatch(
          createAlert({
            message: "Could not find matching tag for selected category",
            timeout: 5000,
            type: "error",
          })
        );
      }
    }
  };

  return (
    <Row>
      {showTransactionSelect && (
        <Col md={4}>
          <label htmlFor="transactionSelect">Transaction</label>
          <CreatableSelect
            id="transactionSelect"
            placeholder="Select uncategorized transaction..."
            options={uncategorizedTransactions.map((t) => ({
              label: t.description,
              value: t.id,
              description: Array.isArray(t.description)
                ? t.description.join(" ")
                : t.description || "",
            }))}
            value={
              selectedTransaction
                ? {
                    label: uncategorizedTransactions.find(
                      (t) => t.id === selectedTransaction
                    )?.description,
                    value: selectedTransaction,
                    description: uncategorizedTransactions.find(
                      (t) => t.id === selectedTransaction
                    )?.description,
                  }
                : null
            }
            onChange={(selected) => {
              setSelectedTransaction(selected?.value || "");
              setSelectedKeyword(
                Array.isArray(selected?.description)
                  ? selected.description.join(" ")
                  : selected?.description || ""
              );
            }}
            isClearable
          />
        </Col>
      )}
      <Col md={showTransactionSelect ? 3 : 4}>
        <KeywordInput value={selectedKeyword} onChange={setSelectedKeyword} />
      </Col>
      <Col md={showTransactionSelect ? 3 : 4}>
        <label htmlFor="categorySelect">Category</label>
        <CreatableSelect
          id="categorySelect"
          placeholder="Select category..."
          options={options}
          value={
            selectedCategory
              ? { label: selectedCategory, value: selectedCategory }
              : null
          }
          onChange={(selected) => setSelectedCategory(selected?.value || "")}
          isClearable
        />
      </Col>
      <Col md={showTransactionSelect ? 2 : 4}>
        <Button
          style={{ marginTop: "2rem" }}
          variant="primary"
          onClick={handleCreateRule}
          disabled={
            showTransactionSelect
              ? !selectedTransaction || !selectedCategory
              : !selectedCategory
          }
        >
          Create Rule
        </Button>
      </Col>
    </Row>
  );
};

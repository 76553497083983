import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { redoJob, redoJobInBankMode } from "../../../docuclipper/api";
import { createAlert } from "../../../redux/reducers/Alerts";
import { setJobData } from "../../../redux/reducers/JobData";
import { useIsAdmin } from "../../../utils/auth";

export function RedoJobButton({ job }) {
  const { isAdmin, isSuperLogin, isSupportRep } = useIsAdmin();
  const dispatch = useDispatch();

  if (!isAdmin && !isSuperLogin && !isSupportRep) {
    return null;
  }

  const onJobRedo = () => {
    if (!job) {
      return;
    }
    redoJob(job.id)
      .then((jobRsp) => {
        dispatch(setJobData({ job: jobRsp, progress: null }));
        dispatch(
          createAlert({
            message: "Job restarted successfully",
            timeout: 0,
            type: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(
          createAlert({
            message: "Error restarting job",
            timeout: 0,
            type: "error",
          })
        );
      });
  };

  const onJobRedoInBankMode = ({
    useV2,
    disableAutoRotation,
  }: {
    useV2: boolean;
    disableAutoRotation: boolean;
  }) => {
    if (!job) {
      return;
    }
    redoJobInBankMode(job.id, useV2, disableAutoRotation)
      .then((jobRsp) => {
        dispatch(setJobData({ job: jobRsp, progress: null }));
        dispatch(
          createAlert({
            message: "Job restarted successfully",
            timeout: 0,
            type: "success",
          })
        );
      })
      .catch((err) => {
        dispatch(
          createAlert({
            message: "Error restarting job",
            timeout: 0,
            type: "error",
          })
        );
      });
  };

  return (
    <>
      <Button variant="link" onClick={onJobRedo}>
        <FontAwesomeIcon icon="undo" />
        <span className="ml-1">Redo job</span>
      </Button>
      <Button
        variant="link"
        onClick={() =>
          onJobRedoInBankMode({ useV2: false, disableAutoRotation: false })
        }
      >
        <FontAwesomeIcon icon="undo" />
        <span className="ml-1">Redo job (bank mode)</span>
      </Button>
      <Button
        variant="link"
        onClick={() =>
          onJobRedoInBankMode({ useV2: true, disableAutoRotation: false })
        }
      >
        <FontAwesomeIcon icon="undo" />
        <span className="ml-1">Redo job (bank mode v2)</span>
      </Button>
      <Button
        variant="link"
        onClick={() =>
          onJobRedoInBankMode({ useV2: false, disableAutoRotation: true })
        }
      >
        <FontAwesomeIcon icon="undo" />
        <span className="ml-1">Redo job (bank mode) (no rotation)</span>
      </Button>
      <Button
        variant="link"
        onClick={() =>
          onJobRedoInBankMode({ useV2: true, disableAutoRotation: true })
        }
      >
        <FontAwesomeIcon icon="undo" />
        <span className="ml-1">Redo job (bank mode v2) (no rotation)</span>
      </Button>
    </>
  );
}

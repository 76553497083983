import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeCardElementChangeEvent, Token } from "@stripe/stripe-js";
import { Formik } from "formik";
import React from "react";
// import { formikInput } from "./utils";
import { Alert, Button, Col, Form, FormGroup, Row } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
// import * as Yup from "yup";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { buyCredits, trackPurchase } from "../../../docuclipper/api";
import { StripeSubscription } from "../../../docuclipper/DocuclipperTypes";
import { logErrorToGA } from "../../../docuclipper/Logger";
import { ReduxState } from "../../../redux";
import { createAlert } from "../../../redux/reducers/Alerts";
import { closeModal } from "../../../redux/reducers/ModalManager";
import { fetchUser } from "../../../redux/reducers/User";
import { emptyFn } from "../../../utils/utils";
import { CreditCardLogos } from "../../Pages/Account/CreditCardLogos";
import UpdateCard from "../../Pages/Account/UpdateCard";
import { getDefaultCard } from "../../Pages/Account/utils";
import AlertBanner from "../AlertBanner/AlertBanner";
import TrackedButton, { LoadingTrackedButton } from "../TrackedButton";
import Pricing from "./Pricing";
import PricingCard from "./PricingCard";

type Props = {
  outOfCredits: boolean;
  subscription: StripeSubscription;
};

export const Upgrade2: React.SFC<Props & RouteComponentProps> = ({
  history,
  outOfCredits,
  subscription,
}) => {
  const dispatch = useDispatch();
  const { token, tokenStr } = useSelector((state: ReduxState) => state.Auth);

  const [selectedPlan, setSelectedPlan] = React.useState<
    "100" | "250" | "500" | "1000"
  >("500");
  const [errorInCard, setErrorInCard] = React.useState(false);
  const [cardErrorMessage, setCardErrorMessage] = React.useState<
    string | undefined
  >(undefined);
  const [cardComplete, setCardComplete] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  // const stripe = useStripe();
  // const elements = useElements();
  const isContractAdmin = useSelector(
    (state: ReduxState) => state.User.user?.isContractAdmin
  );
  const onSelect = (plan) => () => setSelectedPlan(plan);

  // const onCardChange = (changeResponse: StripeCardElementChangeEvent) => {
  //   setCardErrorMessage(
  //     changeResponse.error ? changeResponse.error.message : undefined
  //   );
  //   setErrorInCard(changeResponse.error !== undefined);
  //   setCardComplete(changeResponse.complete);
  // };

  // const submit = async (values, { setSubmitting, resetForm }) => {
  //   if (!stripe || !elements) {
  //     return;
  //   }

  //   if (selectedPlan === null) {
  //     return;
  //   }

  //   let token: Token | undefined = undefined;

  //   if (!subscription.customer.default_source) {
  //     const cardElement = elements.getElement(CardElement);
  //     if (!cardElement) {
  //       return;
  //     }

  //     const { error, token: stripeToken } = await stripe.createToken(
  //       cardElement
  //     );
  //     if (error) {
  //       logErrorToGA(error);
  //       createAlert({
  //         type: "error",
  //         message: error.message || `Error creating billing token.`,
  //         timeout: 0,
  //       });
  //       resetForm();
  //       return;
  //     }
  //     token = stripeToken;
  //   }

  //   setLoading(true);
  //   buyCredits({
  //     source: token ? token.id : "",
  //     plan: selectedPlan,
  //     // name: values.email,
  //     // email: values.email
  //   })
  //     .then(() => {
  //       let amount = 0;
  //       switch (selectedPlan) {
  //         case "100":
  //           amount = 19;
  //           break;
  //         case "250":
  //           amount = 39;
  //           break;
  //         case "500":
  //           amount = 59;
  //           break;
  //         case "1000":
  //           amount = 79;
  //           break;
  //       }
  //       trackPurchase(amount);
  //       setLoading(false);
  //       if (window && (window as any).lintrk) {
  //         (window as any).lintrk("track", { conversion_id: 10568572 });
  //       }
  //       dispatch(closeModal("buyPages"));
  //       dispatch(fetchUser());
  //       setSubmitting(false);
  //       history.push("/empty");
  //       setTimeout(() => {
  //         history.replace("/account?section=plan");
  //       });
  //       setTimeout(() => {
  //         dispatch(
  //           createAlert({
  //             type: "success",
  //             message: `Pages successfully added to your account.`,
  //             timeout: 0,
  //           })
  //         );
  //       }, 500);
  //     })
  //     .catch((err) => {
  //       setLoading(false);
  //       setSubmitting(false);
  //       logErrorToGA(err);
  //       dispatch(
  //         createAlert({
  //           type: "error",
  //           message: `Error adding pages to your account.`,
  //           timeout: 0,
  //         })
  //       );
  //     });
  // };

  if (!subscription || !isContractAdmin) {
    return null;
  }
  // const defaultCard = getDefaultCard(subscription.customer);

  return (
    <>
      {outOfCredits ? (
        <div className="my-2">
          <Alert variant="danger">You don't have any pages left</Alert>
        </div>
      ) : null}

      <div className="my-2">
        <AlertBanner />
      </div>

      <p>
        Purchased pages never expire. If you have any questions reach out to{" "}
        <a href="mailto:support@docuclipper.com">support@docuclipper.com</a>
      </p>

      <Col xs="12" sm="12" md="12" lg="12" xl="12">
        <Formik initialValues={{}} onSubmit={() => undefined /*submit*/}>
          {({ isValid, isSubmitting, submitForm }) => (
            <Form>
              <FormGroup style={{ display: "flex" }}>
                <Pricing>
                  <PricingCard
                    popular={false}
                    selected={selectedPlan === "100"}
                    planName=" 100 pages"
                    onSelected={onSelect("100")}
                    featuresFn={emptyFn}
                    price="$29"
                  />
                  <PricingCard
                    popular={false}
                    selected={selectedPlan === "250"}
                    planName=" 250 pages"
                    onSelected={onSelect("250")}
                    featuresFn={emptyFn}
                    price="$59"
                  />
                  <PricingCard
                    popular={true}
                    selected={selectedPlan === "500"}
                    planName="500 pages"
                    onSelected={onSelect("500")}
                    featuresFn={emptyFn}
                    price="$99"
                  />
                  <PricingCard
                    popular={false}
                    selected={selectedPlan === "1000"}
                    planName="1000 pages"
                    onSelected={onSelect("1000")}
                    featuresFn={emptyFn}
                    price="$159"
                  />
                </Pricing>
              </FormGroup>
              <FormGroup>
                <Alert variant="warning">
                  <FontAwesomeIcon
                    icon="exclamation-circle"
                    className={`text-warning`}
                  />
                  <span className="ml-2">
                    The price per page is more expensive when buying pages
                    directly. Save money by getting a subscription.
                  </span>
                  <TrackedButton
                    onClick={() => {
                      if (subscription.status === "trialing") {
                        history.push("/account/upgrade");
                      } else {
                        history.push("/account/changePlans");
                      }
                      dispatch(closeModal("outOfPages"));
                    }}
                    variant="link"
                    action="saveMoneyWithSubscriptionClick"
                  >
                    Select plan
                  </TrackedButton>
                </Alert>
              </FormGroup>
              {/* {(!subscription.customer.default_source || !defaultCard) && (
                <FormGroup style={{ maxWidth: "400px" }}>
                  <Form.Label>
                    <strong>Card</strong>
                  </Form.Label>

                  <CardElement
                    options={{
                      hidePostalCode: true,
                    }}
                    onChange={onCardChange}
                    className={`form-control ${
                      errorInCard ? "is-invalid" : ""
                    }`}
                  />
                  <div className={errorInCard ? "invalid-feedback" : ""}>
                    {cardErrorMessage}
                  </div>
                  <CreditCardLogos />
                </FormGroup>
              )}
              {subscription.customer.default_source && defaultCard && (
                <Row className="my-2 align-items-center">
                  {defaultCard.last4 && (
                    <div className="px-2">{`Card ending in ${defaultCard.last4}`}</div>
                  )}
                  <UpdateCard
                    headerText="Manage payments"
                    size="lg"
                    btnText="Update billing information"
                    btnProps={{ variant: "link" }}
                  />
                </Row>
              )} */}

              <FormGroup>
                {/* <LoadingTrackedButton
                  isLoading={loading}
                  className="btn--buy-credits"
                  variant="primary"
                  action="buyCreditsFormClick"
                  onClick={submitForm}
                  disabled={
                    // !isValid ||
                    !stripe || isSubmitting
                  }
                >
                  Buy Pages
                </LoadingTrackedButton> */}
                <form action="/api/v1/protected/stripe/checkout" method="post">
                  <input type="hidden" name="token" value={tokenStr} />
                  <input type="hidden" name="plan" value={selectedPlan} />

                  <Button
                    className={`btn--select-plan-${selectedPlan}`}
                    type="submit"
                  >
                    Buy Pages
                  </Button>
                </form>
              </FormGroup>
            </Form>
          )}
        </Formik>
      </Col>
    </>
  );
};
export default connect(null, {
  createAlert,
  closeModal,
})(withRouter(Upgrade2));

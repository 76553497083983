import React from "react";
import { Alert, Button, Col, Row, Tab, Tabs } from "react-bootstrap";
import Categorize from "./Categorize/index";
import DateGaps from "./DateGaps/index";
import FlowOfFunds from "./FlowOfFunds/index";
import { useQueryStateSync } from "../../../custom-hooks/useQueryStateSync";
import ManualMatch from "./MatchingTransactions/ManualMatch";
import { useDispatch, useSelector } from "react-redux";
import { fetchTls } from "../../../redux/reducers/TransactionManager";
import MatchingTransactions from "./MatchingTransactions";
import JobExportData from "../DataArchive/JobExportData";
import { ReduxState } from "../../../redux";
import { DownloadModal } from "../DataArchive/TransactionManager/DownloadModal";

import FeatureGate from "../../Docuclipper/FeatureGate/FeatureGate";
import { sendFeedback } from "../../../docuclipper/api";
import { createAlert } from "../../../redux/reducers/Alerts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FeatureGateByPlan } from "../../Docuclipper/FeatureGate/FeatureGateByPlan";
import { useHistory } from "react-router-dom";
import { useSeat } from "../../../custom-hooks/useSeat";
import Tagging from "./Tagging";
// import SupersetDash from "./Categorize/SupersetPieChart.tsx.disabled";
import { useIsAdmin } from "../../../utils/auth";
import { CashflowChart } from "./Cashflow/Cashflow2";

type Props = {};
export const AnalyzeTab: React.FC<Props> = ({}) => {
  const dispatch = useDispatch();
  // React.useEffect(() => {
  //   dispatch(fetchTls(false));
  // }, []);
  const { queryParams, querySetters } = useQueryStateSync({
    analyzeMode: "cashflow",
  });

  let x = "1";
  if (queryParams.analyzeMode === "cashflow") {
    x = "7";
  } else if (queryParams.analyzeMode === "gaps") {
    x = "2";
  } else if (queryParams.analyzeMode === "match") {
    x = "3";
  } else if (queryParams.analyzeMode === "flow") {
    x = "4";
  } else if (queryParams.analyzeMode === "tagging") {
    x = "5";
  } else if (queryParams.analyzeMode === "flowOfFunds") {
    x = "6";
  }
  const [activeTab, setActiveTab] = React.useState(x);

  // const { isAdmin } = useIsAdmin();
  const isAdmin = false;
  const mode = queryParams.analyzeMode;
  const { job } = useSelector((state: ReduxState) => state.JobData);
  return (
    <>
      <Tabs
        activeKey={activeTab}
        id="admin-tabs"
        onSelect={(activeTab) => {
          switch (activeTab) {
            case "1":
              return querySetters.set_analyzeMode("cashflow");
            case "2":
              return querySetters.set_analyzeMode("gaps");
            case "3":
              return querySetters.set_analyzeMode("match");
            case "4":
              return querySetters.set_analyzeMode("flow");

            case "5":
              return querySetters.set_analyzeMode("tagging");
            case "6":
              return querySetters.set_analyzeMode("flowOfFunds");
            case "7":
              return querySetters.set_analyzeMode("cashflow");

            default:
              return isAdmin
                ? querySetters.set_analyzeMode("flowOfFunds")
                : querySetters.set_analyzeMode("flow");
          }
        }}
      >
        {/* {isAdmin && (
          <Tab eventKey="1" title="Cashflow Analytics">
            {activeTab === "1" ? (
              <div>
                <SupersetDash
                  dashboardName={"cashflow"}
                  height={"800px"}
                  width={"100%"}
                  reload={true} // change dinamically to reload the dashboard
                />
              </div>
            ) : null}
          </Tab>
        )} */}
        <Tab eventKey="2" title="Date Gaps">
          {activeTab === "2" ? <DateGaps /> : null}
        </Tab>

        <Tab eventKey="3" title="Transfers">
          {activeTab === "3" ? <MatchingTransactions /> : null}
        </Tab>
        {!isAdmin && (
          <Tab eventKey="4" title="Flow of Funds">
            {activeTab === "4" ? <FlowOfFunds /> : null}
          </Tab>
        )}
        {isAdmin && (
          <Tab eventKey="5" title="Tagging">
            {activeTab === "5" ? <Tagging /> : null}
          </Tab>
        )}
        {/* {isAdmin && (
          <Tab eventKey="6" title="Flow of Funds">
            {activeTab === "6" ? (
              <div>
                <SupersetDash
                  dashboardName={"flowOfFunds"}
                  height={"800px"}
                  width={"100%"}
                  reload={true} // change dinamically to reload the dashboard
                />
              </div>
            ) : null}
          </Tab>
        )} */}
        <Tab eventKey="7" title="Cashflow Analysis">
          {activeTab === "7" ? <CashflowChart /> : null}
        </Tab>
      </Tabs>
    </>
  );
};

export const CategorizeTab: React.FC<Props> = ({}) => {
  const dispatch = useDispatch();
  // React.useEffect(() => {
  //   dispatch(fetchTls(false));
  // }, []);
  const { queryParams, querySetters } = useQueryStateSync({
    analyzeMode: "categorize",
  });

  let x = "1";
  if (queryParams.analyzeMode === "categorize") {
    x = "1";
  }
  const [activeTab, setActiveTab] = React.useState(x);

  const { isAdmin } = useIsAdmin();
  const mode = queryParams.analyzeMode;
  const { job } = useSelector((state: ReduxState) => state.JobData);
  return (
    <>
      <Categorize />
      {/* <Tabs
        activeKey={activeTab}
        id="admin-tabs"
        onSelect={(activeTab) => {
          switch (activeTab) {
            case "1":
              return querySetters.set_analyzeMode("categorize");

            default:
              return querySetters.set_analyzeMode("categorize");
          }
        }}
      >
        <Tab eventKey="1" title="Categorize">
          {activeTab === "1" ? <Categorize /> : null}
        </Tab>
      </Tabs> */}
    </>
  );
};

const CategorizeNotEnabled: React.FC<Props> = ({}) => {
  const dispatch = useDispatch();
  const [info, setInfo] = React.useState("");
  const history = useHistory();
  const { isSeat, admin } = useSeat();

  return (
    <div>
      <div>
        <div className="h3">Categorization</div>

        <div>
          <Row>
            <Col>
              <span className="font-weight-bold">Categorize</span>
              <span className="ml-2">
                Automatically assign categories to the transactions.
              </span>
            </Col>
          </Row>
        </div>

        <div className="mt-4" style={{ maxWidth: "600px" }}>
          <Alert variant="danger">
            This feature is available on the Professional, Business and
            Enterprise plans
            {!isSeat && (
              <Button
                className="mx-2"
                onClick={() => history.push("/account/upgrade")}
              >
                Upgrade
              </Button>
            )}
            {isSeat && (
              <>
                {admin && <div>{`Email your admin ${admin?.email} or`}</div>}
                <Button
                  className="m-2"
                  onClick={() => history.push("/signup/")}
                >
                  Create Your Own Account
                </Button>
              </>
            )}
          </Alert>
        </div>
      </div>
    </div>
  );
};

const AnalyzeNotEnabled: React.FC<Props> = ({}) => {
  const dispatch = useDispatch();
  const [info, setInfo] = React.useState("");
  const history = useHistory();
  const { isSeat, admin } = useSeat();

  return (
    <div>
      <div>
        <div className="h3">Financial Investigation Features</div>

        <div>
          <Row>
            <Col>
              <span className="font-weight-bold">Date Gaps</span>
              <span className="ml-2">
                Make sure you have all the statements for a given date range.
              </span>
            </Col>
          </Row>

          <Row>
            <Col>
              <span className="font-weight-bold">Transfers</span>
              <span className="ml-2">Detect transfers between accounts.</span>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="font-weight-bold">Flow of Funds</span>
              <span className="ml-2">
                Understand flow of funds between accounts at a glance.
              </span>
            </Col>
          </Row>
        </div>

        <div className="mt-4" style={{ maxWidth: "600px" }}>
          <Alert variant="danger">
            This feature is only available on the Business and Enterprise plans
            {!isSeat && (
              <Button
                className="mx-2"
                onClick={() => history.push("/account/upgrade")}
              >
                Upgrade
              </Button>
            )}
            {isSeat && (
              <>
                {admin && <div>{`Email your admin ${admin?.email} or`}</div>}
                <Button
                  className="m-2"
                  onClick={() => history.push("/signup/")}
                >
                  Create Your Own Account
                </Button>
              </>
            )}
          </Alert>
        </div>
      </div>
    </div>
  );
};
export const CategorizeTabGated: React.FC<Props> = ({}) => {
  // return (
  //   <FeatureGateByPlan
  //     featureId={1}
  //     renderNotEnabled={() => <CategorizeNotEnabled />}
  //     renderEnabled={() => <CategorizeTab />}
  //   />
  // );
  return <CategorizeTab />;
};

export const AnalyzeTabGated: React.FC<Props> = ({}) => {
  // return (
  //   <FeatureGateByPlan
  //     featureId={2}
  //     renderNotEnabled={() => <AnalyzeNotEnabled />}
  //     renderEnabled={() => <AnalyzeTab />}
  //   />
  // );
  return <AnalyzeTab />;
};

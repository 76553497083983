import { createStore, combineReducers, applyMiddleware } from "redux";
import reducers from "./reducers";
import thunk from "redux-thunk";
import throttle from "redux-throttle";
import { composeWithDevTools } from "redux-devtools-extension";

const defaultWait = 300;
const defaultThrottleOption = {
  // https://lodash.com/docs#throttle
  leading: true,
  trailing: true,
};

const middlewares = [throttle(defaultWait, defaultThrottleOption), thunk];
const rootReducer = combineReducers({
  ...reducers,
});

export type RT = typeof reducers;
export type ReduxState = ReturnType<typeof rootReducer>;

function configureStore() {
  // if (process.env.NODE_ENV === "development") {
  //   const { logger } = require("redux-logger");
  //   middlewares.push(logger);
  // }

  return createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middlewares))
  );
}

export default configureStore();

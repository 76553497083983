import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../../redux";
import {
  setSelectedDocumentId,
  updateMetadataField,
} from "../../../../redux/reducers/TransactionManager";
import { isNullOrUndefined } from "../../../../utils/utils";
import { ReconciledNo, ReconciledYes } from "../ReconciledBadge";
import { MetadataField } from "../../../../docuclipper/DocuclipperTypes";
import AccountSelector from "./AccountSelector";
import PeriodSelector from "./PeriodSelector";
import uuidv4 from "uuid/v4";
import SelectWithBadge from "./SelectWithBadge";
import { getDocumentName } from "../../Analyze/AnalyzeTransactionsTable/utils";
import {
  EditableFieldAmount,
  EditableFieldDateYYYYMMDD,
} from "../../../../utils/EditableField";
import DocumentSelector from "./DocumentSelector";

type SummaryInfo = {
  numCredits: number;
  numPayments: number;
  totalCredits: string;
  totalPayments: string;
};
export const TransactionRow = ({
  summaryInfo,
}: {
  summaryInfo: SummaryInfo;
  fieldName: string;
}) => {
  const total =
    Math.abs(parseFloat(summaryInfo.totalCredits)) -
    Math.abs(parseFloat(summaryInfo.totalPayments));

  return (
    <>
      <Row className="justify-content-between align-items-center">
        <div>{`${
          summaryInfo.numCredits + summaryInfo.numPayments
        } transaction(s)`}</div>
        <div>{`${total.toFixed(2)}`}</div>
      </Row>
      <Row>
        <td>
          <div className="mt-2">
            {summaryInfo.numCredits >= 0 && (
              <span className="text-success font-weight-bold">
                <span>{`${summaryInfo.numCredits} credit(s)`}</span>

                {` +${parseFloat(summaryInfo.totalCredits.toString()).toFixed(
                  2
                )}`}
              </span>
            )}

            {summaryInfo.numPayments >= 0 && (
              <span className="text-danger ml-4 font-weight-bold">
                <span>{`${summaryInfo.numPayments} debit(s)`}</span>

                {` ${parseFloat(summaryInfo.totalPayments.toString()).toFixed(
                  2
                )}`}
              </span>
            )}
          </div>
        </td>
      </Row>
    </>
  );
};

export const getIsReconciled = (
  fields: MetadataField[],
  accountName: string,
  chunk: string | null
) => {
  const x = (fields || []).filter(
    (mf) =>
      mf.name === "isReconciled" &&
      (!chunk || mf.chunk === chunk) &&
      (accountName.toString() === "null" || mf.accountName === accountName)
  );
  if (x.length > 0) {
    const hasOne = x.filter((y) => y.value === "1");
    if (hasOne.length === x.length) {
      return <ReconciledYes type={chunk ? "period" : "account"} />;
    } else {
      return <ReconciledNo type={chunk ? "period" : "account"} />;
    }
  } else {
    return null;
  }
};

export const getIsReconciledBoolean = (
  fields: MetadataField[],
  accountName: string,
  chunk: string | null
) => {
  const x = (fields || []).filter(
    (mf) =>
      mf.name === "isReconciled" &&
      (!chunk || mf.chunk === chunk) &&
      (accountName.toString() === "null" || mf.accountName === accountName)
  );
  if (x.length > 0) {
    const hasOne = x.filter((y) => y.value === "1");
    if (hasOne.length === x.length) {
      return true;
    } else {
      return false;
    }
  } else {
    return null;
  }
};

export const getIsBadDateBoolean = (
  fields: MetadataField[],
  accountName: string,
  chunk: string | null
) => {
  const x = (fields || []).filter(
    (mf) =>
      ["startDate", "endDate"].includes(mf.name) &&
      (!chunk || mf.chunk === chunk) &&
      (accountName.toString() === "null" || mf.accountName === accountName)
  );
  if (x.length > 0) {
    const badDates = x.filter((y) => y.value.includes("2001"));
    return badDates.length > 0;
  } else {
    return null;
  }
};

export const SummaryTableAccountViewHorizontal2: React.FC = () => {
  const dispatch = useDispatch();
  let { tls, mfs, selectedDocumentId, selectedChunk, badDates, reconciled } =
    useSelector((state: ReduxState) => state.TransactionManager);

  selectedChunk = selectedChunk || "";
  let { selectedAccount } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );

  selectedAccount = selectedAccount || "";

  const { mfs: fields } = mfs;
  const { totalsByDocumentId, totalsByChunk } = useSelector(
    (state: ReduxState) => state.Reconciler
  );

  const totals = !isNullOrUndefined(selectedDocumentId)
    ? totalsByDocumentId
      ? totalsByDocumentId[selectedDocumentId as string]
      : {}
    : {};

  const chunkTotals =
    totalsByChunk &&
    !isNullOrUndefined(selectedAccount) &&
    !isNullOrUndefined(selectedAccount) &&
    !isNullOrUndefined(selectedChunk) &&
    selectedAccount in totalsByChunk &&
    !isNullOrUndefined(selectedChunk) &&
    selectedDocumentId &&
    selectedDocumentId in totalsByChunk[selectedAccount] &&
    selectedChunk in totalsByChunk[selectedAccount][selectedDocumentId]
      ? totalsByChunk[selectedAccount][selectedDocumentId][
          selectedChunk as string
        ]
      : {};

  const mfHelper = (name, accountName, documentId, chunk) => {
    const x = (fields || []).filter(
      (mf) =>
        mf.name === name &&
        mf.documentId === documentId &&
        (!selectedChunk || mf.chunk === chunk) &&
        (accountName.toString() === "null" || mf.accountName === accountName)
    );

    const getLabel = (name) => {
      switch (name) {
        case "startDate":
          return "Start Date";
        case "endDate":
          return "End Date";
        case "startBalance":
          return "Start Balance";
        case "endBalance":
          return "End Balance";
        default:
          return name;
      }
    };
    let value = "";
    if (x.length > 0) {
      value = x[0].value;
    }

    let formattedValue = value;
    if (["startBalance", "endBalance"].includes(name)) {
      if (!isNaN(parseFloat(value))) {
        formattedValue = parseFloat(value).toFixed(2);
      }
    }

    return (
      <div className="mx-2">
        {["startDate", "endDate"].includes(name) && (
          <EditableFieldDateYYYYMMDD
            key={uuidv4()}
            onSave={(newValue) => {
              dispatch(
                updateMetadataField(
                  selectedDocumentId as any,
                  name,
                  newValue,
                  accountName,
                  chunk
                )
              );
            }}
            value={formattedValue}
          />
        )}
        {["startBalance", "endBalance"].includes(name) && (
          <EditableFieldAmount
            key={uuidv4()}
            onSave={(newValue) => {
              dispatch(
                updateMetadataField(
                  selectedDocumentId as any,
                  name,
                  newValue,
                  accountName,
                  chunk
                )
              );
            }}
            value={formattedValue}
          />
        )}

        {!formattedValue && (
          <small>
            <div className="text-danger">{`Missing ${getLabel(name)}`}</div>
          </small>
        )}
        {formattedValue &&
          ["startDate", "endDate"].includes(name) &&
          formattedValue.includes("2001") && (
            <small>
              <div className="text-danger">{`Bad year`}</div>
            </small>
          )}
      </div>
    );
  };

  const { data: jobDocs } = useSelector(
    (state: ReduxState) => state.JobDocument
  );
  const uniqueDocumentIds = Array.from(
    new Set(jobDocs.map((item) => item.documentId))
  );
  // if (isNullOrUndefined(totals)) {
  //   return null;
  // }

  // console.log({ selectedDocument, jobDocs });
  return (
    <>
      <Row className="mt-4">
        <Col md="4" className="transation-manager-select-document-id">
          <DocumentSelector />
        </Col>
        <Col md="4" className="tx-manager-account-selector">
          <AccountSelector
            showAccountsHeading={true}
            renderReconciled={() => (
              <>
                {selectedAccount &&
                  getIsReconciled(fields, selectedAccount, selectedChunk)}
              </>
            )}
          />
        </Col>
        <Col md="4" className="tx-manager-period-selector">
          {selectedAccount && (
            <>
              <PeriodSelector
                renderReconciled={() => (
                  <>{getIsReconciled(fields, selectedAccount, selectedChunk)}</>
                )}
              />
            </>
          )}
        </Col>
      </Row>
      <Row className="table-responsive summary-table-dates-balances mb-5 mt-2 d-flex">
        <div className="ml-3">
          <Row className="align-items-center">
            <strong className="">From</strong>
            {selectedAccount &&
              selectedChunk &&
              mfHelper(
                "startDate",
                selectedAccount,
                selectedDocumentId,
                selectedChunk
              )}
          </Row>
          <Row className="align-items-center">
            <strong className="">Start Balance</strong>
            {selectedAccount &&
              selectedChunk &&
              mfHelper(
                "startBalance",
                selectedAccount,

                selectedDocumentId,
                selectedChunk
              )}
          </Row>
        </div>
        <div className="mt-1 ml-5 mr-3">
          <div className="">
            {selectedAccount &&
              selectedChunk &&
              Object.keys(chunkTotals || []).map((fieldName, idx) => (
                <>
                  {!["mfs", "label"].includes(fieldName) && (
                    <TransactionRow
                      key={idx}
                      fieldName={fieldName}
                      summaryInfo={chunkTotals[fieldName]}
                    />
                  )}
                </>
              ))}
          </div>
        </div>
        <div className="ml-5">
          <Row className="align-items-center">
            <strong className="">To</strong>
            {selectedAccount &&
              selectedChunk &&
              mfHelper(
                "endDate",
                selectedAccount,

                selectedDocumentId,
                selectedChunk
              )}
          </Row>
          <Row className="align-items-center">
            <strong className="">End Balance</strong>
            {selectedAccount &&
              selectedChunk &&
              mfHelper(
                "endBalance",
                selectedAccount,
                selectedDocumentId,
                selectedChunk
              )}
          </Row>
        </div>
        <div></div>
      </Row>
    </>
  );
};
export default SummaryTableAccountViewHorizontal2;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React from "react";
import {
  Alert,
  Badge,
  Button,
  OverlayTrigger,
  ProgressBar,
  Row,
  Tooltip,
} from "react-bootstrap";
import Media from "react-media";
import { useMediaPredicate } from "react-media-hook";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  emailJob,
  markJobReviewed,
  resumeJob,
  updateJob,
} from "../../../docuclipper/api";
import { ReduxState } from "../../../redux";
import { createAlert } from "../../../redux/reducers/Alerts";
import { setJobData } from "../../../redux/reducers/JobData";
import { openModal } from "../../../redux/reducers/ModalManager";
import { breakpoints } from "../../../utils/utils";
import EditableText from "../../Docuclipper/EditableText";
import { LoadingTrackedButton } from "../../Docuclipper/TrackedButton";
import { DeleteJobButton } from "./DeleteJobButton";
import { ReconciledBadge } from "./ReconciledBadge";
import { RedoJobButton } from "./RedoJobButton";
import { DownloadModal } from "./TransactionManager/DownloadModal";
import ClipLoader from "react-spinners/ClipLoader";
import { JobInProgress } from "./JobInProgress";
import { GenericJobHelp } from "./GenericJobHelp";

type Props = {};

let status2display = {
  OutOfCredits: "Out of pages",
};

export const JobRealTimeTracker: React.SFC<Props> = ({}) => {
  const { job, progress } = useSelector((state: ReduxState) => state.JobData);
  const dispatch = useDispatch();
  const [resumeLoading, setResumeLoading] = React.useState(false);
  const { token } = useSelector((state: ReduxState) => state.Auth);
  const isAdmin =
    token && token.permissions && token.permissions.includes("READ_DB_TABLES");
  const isSuperLogin = token && token.superlogin;

  const hasLargeScreen = useMediaPredicate("(min-width: 576px)");

  const { subscription } = useSelector(
    (state: ReduxState) => state.Subscription
  );
  const history = useHistory();
  if (!job) {
    return null;
  }

  let jobName = "";
  if (job && job.name) {
    jobName = job.name;
  } else if (job && job.template && job.template.name) {
    jobName = job.template.name;
  }

  const onClickResume = () => {
    setResumeLoading(true);
    resumeJob(job.id)
      .then((jobRsp) => {
        dispatch(setJobData({ job: jobRsp }));
        setResumeLoading(false);
      })
      .catch((err) => {
        setResumeLoading(false);
      });
  };

  //console.log("JobRealTimeTracker re-render");

  return (
    <>
      <Media query={{ minWidth: breakpoints.sm }}>
        {(matches) => (
          <>
            {/* <div className="row">
              <div className="col-md-2">Name</div>
              <div className="col-md-2">Status</div>
              {matches && <div className="col-md-2">Date</div>}
              {matches && <div className="col-md-6"></div>}
            </div> */}

            {job &&
              job.isPartial &&
              !["InProgress", "Finishing"].includes(job.status) && (
                <div className="row">
                  <Alert variant="warning">
                    Only some of pages have been converted
                    {subscription && subscription.status === "trialing"
                      ? `. The trial is limited to 20 pages`
                      : ""}
                  </Alert>
                </div>
              )}

            {job &&
              job.isGeneric &&
              !["InProgress", "Finishing"].includes(job.status) && (
                <></>
                // <div className="row">
                //   <Alert variant="warning">
                //     The data has been extracted using{" "}
                //     <a
                //       href="https://www.docuclipper.com/docs/ui/user-guide/extracting-data/generic-conversion/"
                //       target="_blank"
                //       rel="noreferrer noopener"
                //     >
                //       automatic mode
                //     </a>
                //     . To have more control over what data gets extracted,{" "}
                //     <Button
                //       style={{ padding: 0 }}
                //       variant="link"
                //       onClick={() => history.push("/template/new")}
                //     >
                //       create a custom template
                //     </Button>{" "}
                //     or email{" "}
                //     <a href="mailto:support@docuclipper.com">
                //       support@docuclipper.com
                //     </a>{" "}
                //     to get help
                //   </Alert>
                // </div>
              )}
            {["InProgress", "Finishing"].includes(job.status) && (
              <div className="row">
                <div className="col-md-2">
                  {/* <span className="pt-2">{getElement(!matches)}</span> */}
                  {["OutOfCredits"].includes(job.status) && (
                    <OverlayTrigger
                      popperConfig={{
                        modifiers: {
                          preventOverflow: {
                            enabled: false,
                          },
                        } as any,
                      }}
                      placement="top"
                      overlay={
                        <Tooltip id={`data-archive-resume`}>Resume job</Tooltip>
                      }
                    >
                      <LoadingTrackedButton
                        isLoading={resumeLoading}
                        id="btn--resume-job"
                        variant="link"
                        action="resumeJob"
                        className="btn--resume-job"
                        onClick={onClickResume}
                      >
                        <FontAwesomeIcon /*color="blue"*/ icon="play" />
                        <span className="ml-1">Resume job</span>
                      </LoadingTrackedButton>
                    </OverlayTrigger>
                  )}
                </div>
              </div>
            )}
            {/* <Button
              className="ml-2"
              variant="link"
              onClick={() => {
                if (exploreDataRef && exploreDataRef.current) {
                  exploreDataRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }
              }}
            >
              <FontAwesomeIcon icon="search" />
              <span className="m-1">View details</span>
            </Button> */}
          </>
        )}
      </Media>
      {/* TODO fix getTotals by document */}
      {/* {job.status === "Succeeded" && (
        <div>
          <Reconciler />
        </div>
      )} */}
    </>
  );
};
export default JobRealTimeTracker;

import React from "react";
import { isMobile, isTablet } from "react-device-detect";
import { Alert } from "react-bootstrap";

const MobileBanner: React.FC = () => {
  const [show, setShow] = React.useState(true);

  if (!show || (!isMobile && !isTablet)) return null;

  return (
    <Alert variant="danger" onClose={() => setShow(false)} dismissible>
      This app works best on desktop. Please switch to a desktop browser for the
      best experience.
    </Alert>
  );
};

export default MobileBanner;

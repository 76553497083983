import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import uuidv4 from "uuid/v4";
import { TemplateMetadataField } from "../../docuclipper/DocuclipperTypes";

const initialFields = [
  {
    id: uuidv4(),
    field: {
      name: "startDate",
      documents: [
        {
          regexes: [
            {
              regexes: [""],
            },
          ],
        },
      ],
    },
  },
  {
    id: uuidv4(),
    field: {
      name: "endDate",
      documents: [
        {
          regexes: [
            {
              regexes: [""],
            },
          ],
        },
      ],
    },
  },
  {
    id: uuidv4(),
    field: {
      name: "startBalance",
      documents: [
        {
          regexes: [
            {
              regexes: [""],
            },
          ],
        },
      ],
    },
  },
  {
    id: uuidv4(),
    field: {
      name: "endBalance",
      documents: [
        {
          regexes: [
            {
              regexes: [""],
            },
          ],
        },
      ],
    },
  },
];
const initialState: {
  fields: {
    id: string;
    field: TemplateMetadataField;
  }[];
  logoURL: string;
  intuitNumber: string;
  visibility: "public" | "private";
} = {
  logoURL: "",
  intuitNumber: "",
  visibility: "private",
  fields: initialFields,
};

const slice = createSlice({
  name: "MetadataFields",
  initialState,
  reducers: {
    setLogoUrl(state, action: PayloadAction<string>) {
      state.logoURL = action.payload;
    },
    setIntuitNumber(state, action: PayloadAction<string>) {
      state.intuitNumber = action.payload;
    },
    setTemplateVisibility(state, action: PayloadAction<"public" | "private">) {
      state.visibility = action.payload;
    },
    addMetadataField(state, action: PayloadAction<TemplateMetadataField>) {
      state.fields = [...state.fields, { id: uuidv4(), field: action.payload }];
    },
    deleteMetadataField(state, action: PayloadAction<string>) {
      state.fields = [...state.fields.filter((x) => x.id !== action.payload)];
    },
    clearMetadataFields(state) {
      state.fields = [...initialFields];
      state.intuitNumber = "";
      state.logoURL = "";
      state.visibility = "private";
    },
    editMetadataField(
      state,
      action: PayloadAction<{ field: TemplateMetadataField; id: string }>
    ) {
      state.fields = [
        ...state.fields.map((x) => {
          if (x.id === action.payload.id) {
            return {
              id: x.id,
              field: {
                ...x.field,
                ...action.payload.field,
              },
            };
          } else {
            return x;
          }
        }),
      ];
    },
  },
});

export const {
  addMetadataField,
  deleteMetadataField,
  editMetadataField,
  setIntuitNumber,
  setLogoUrl,
  setTemplateVisibility,
  clearMetadataFields,
} = slice.actions;

export default slice.reducer;

import React from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../redux";
import TrackedButton from "../TrackedButton";

type PricingCardProps = {
  planName: string;
  selected: boolean;
  onSelected: () => any;
  featuresFn: () => any;
  price: string;
  popular: boolean;
};

const PricingCard: React.FunctionComponent<PricingCardProps> = ({
  planName,
  selected,
  onSelected,
  featuresFn,
  price,
  popular,
}: PricingCardProps) => {
  const style = {
    opacity: selected ? 1.0 : 0.5,
    borderColor: "lightgray",
    borderStyle: selected ? "solid" : "none",
  };

  return (
    <div
      className="col-lg-6 mt-3"
      onClick={onSelected}
      style={{ maxWidth: "250px", width: "250px", cursor: "pointer" }}
    >
      <div style={style} className="pricing-box bg-white text-center p-4">
        {popular ? (
          <div className="ribbon">
            <span>Popular</span>
          </div>
        ) : null}
        <div className="plan-title mt-3 mb-4">
          <h3 style={{ color: "#3f6ad8" }} className="mb-0">
            {planName}
          </h3>
        </div>
        <hr />
        <div className="plan-price">
          {/* <span>{price === "Free" ? "" : "$"}</span> */}
          <span
            className="ml-1"
            style={{ fontWeight: "bold", fontSize: "2em" }}
          >
            {price}
          </span>
        </div>
        <hr />

        {featuresFn()}
        <div className="mb-4">
          <TrackedButton
            className="btn--select-plan"
            onClick={onSelected}
            action={`selectPlan`}
            label={planName}
            variant="primary"
          >
            Select
          </TrackedButton>
        </div>
      </div>
    </div>
  );
};

export default PricingCard;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Tour from "reactour";
import { setHelpDrawerOpen } from "src/redux/reducers/HelpDrawer";

const steps = [
  {
    selector: ".transation-manager-select-document-id",
    content:
      "Select a document. DocuClipper displays the transactions and summary information of one document at a time.",
  },
  {
    selector: ".tx-manager-account-selector",
    content:
      "Select the account e.g. 'checking'. A blue badge indicates the account is reconciled.",
  },
  {
    selector: ".tx-manager-period-selector",
    content:
      "Select the period, e.g. '2023-01-01 to 2023-01-31'. A blue badge indicates the period is reconciled.",
  },
  {
    selector: ".summary-table-dates-balances",
    content:
      "Summary information including totals, number of credits and debits, etc. Edit start/end dates and start/end balances for a given period.",
  },
  {
    selector: ".transaction-manager",
    content:
      "Browse the transactions. Click on the filters to select only certain transactions.",
  },
  {
    selector: ".ag-grid-cell-included",
    content: "Include or exclude each transaction using the toggle.",
  },
  {
    selector: ".ag-grid-cell-date",
    content:
      "Correct the values of the transactions by clicking on the cell and entering a new value.",
  },
  {
    selector: ".add-transaction-btn",
    content: 'Click on "Add Transaction" to add any missing transactions.',
  },

  {
    selector: ".ag-grid-cell-selection",
    content: "Select one or more transactions",
  },
  {
    selector: ".bulk-edit-buttons",
    content: "Make changes to the selected transations in bulk.",
  },
  {
    selector: ".assign-to-account-btn",
    content: "Assign selected transactions to a different account or period.",
  },
  {
    selector: ".btn--report-bad-job",
    content: "Get help and report issues to the support team",
  },

  {
    selector: ".btn--download-data",
    content:
      "Click here to download the data. DocuClipper supported formats include Excel, QuickBooks, Xero and other third party applications.",
  },
];

export const TransactionManagerTourAndReportIssue: React.FC = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(() => {
    // Check if user has seen the tour before
    const hasSeenTour = localStorage.getItem("hasSeenTransactionManagerTour");
    return hasSeenTour !== "true";
  });

  // Update localStorage when tour is closed
  const handleCloseTour = () => {
    localStorage.setItem("hasSeenTransactionManagerTour", "true");
    setOpen(false);
  };

  return (
    <>
      <Button
        className="btn--report-bad-job"
        variant="link"
        onClick={() => {
          dispatch(setHelpDrawerOpen(true));
        }}
      >
        <FontAwesomeIcon icon="exclamation-triangle" />
        <span className="m-2">Report Issue</span>
      </Button>
      <Button onClick={() => setOpen(true)} variant="link">
        <FontAwesomeIcon icon="play" />
        <span className="ml-1">Tutorial</span>
      </Button>
      <Tour steps={steps} isOpen={open} onRequestClose={handleCloseTour} />
    </>
  );
};
export default TransactionManagerTourAndReportIssue;

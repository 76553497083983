import React from "react";
import Select from "react-select";
import { ReconciledOption, ReconciledValue } from "./DownloadOptions/utils";
import { Badge } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = { onChange; options; value };
export const SelectWithBadge: React.FC<Props> = ({
  onChange,
  options,
  value,
}) => {
  return (
    <Select
      maxMenuHeight={600}
      options={options}
      value={value}
      onChange={onChange}
      components={{
        Option: ReconciledOption,
        SingleValue: (props) => {
          const { data, ...rest } = props;
          return (
            <>
              <ReconciledValue data={data} {...rest}>
                {props.children}
              </ReconciledValue>
              {data.isBadDate && (
                <Badge
                  variant="warning"
                  className="mt-1 ml-3 p-1 pr-2 text-white"
                >
                  <FontAwesomeIcon color="white" icon="times"></FontAwesomeIcon>
                  <span className="ml-1">Bad date</span>
                </Badge>
              )}
            </>
          );
        },
      }}
    />
  );
};
export default SelectWithBadge;

const initialState = {
  total: 0,
  completed: 0,
  loading: false
};

const SET_TOTAL = "set-total";
const MARK_UNIT_COMPLETED = "mark-unit-completed";
const SET_LOADING = "SET_LOADING";

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_TOTAL:
      return {
        ...state,
        total: action.payload.total,
        completed: 0
      };
    case MARK_UNIT_COMPLETED:
      return {
        ...state,
        completed: state.completed + action.payload.amount
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading
      };
    default:
      return state;
  }
}

export const setTotal = (total: number) => ({
  type: SET_TOTAL,
  payload: {
    total
  }
});

export const markUnitCompleted = (amount: number) => ({
  type: MARK_UNIT_COMPLETED,
  payload: {
    amount
  }
});

export const setLoading = (loading: boolean) => ({
  type: SET_LOADING,
  payload: {
    loading
  }
});

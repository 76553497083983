import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  Alert,
  Card,
  Row,
  Col,
  FormGroup,
  Form,
} from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import {
  getInvoiceApprovalRules,
  fetchTeams,
  getSeats,
  deleteInvoiceApprovalRule,
  updateInvoiceApprovalRule,
  createInvoiceApprovalRule,
  getContractAdmins,
} from "src/docuclipper/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "src/redux";
import { createAlert } from "src/redux/reducers/Alerts";
import { InvoiceApprovalRule } from "src/docuclipper/DocuclipperTypes";
import { ActionMeta, ValueType } from "react-select";
import { InvoiceMatchManager } from "../Invoices/InvoiceMatching/InvoiceMatchManager";
import CreatableSelect from "react-select/creatable";

interface Rule {
  id?: number;
  name: string;
  description?: string;
  isActive: boolean;
  ifConditions: {
    field: string;
    operator: string;
    value: { keywords?: string[] } | { amount?: number };
  }[];
  thenApprovers: {
    type: "team" | "user";
    id: string;
  }[];
  combinationOperator: "all" | "any";
  approverOperator: "all" | "any";
  requireNWayMatch: boolean;
  requiredMatches?: number;
}

interface Team {
  id: string;
  name: string;
  TeamMemberships: {
    User: {
      id: string;
      email: string;
    };
    role: "member" | "admin";
  }[];
}

interface User {
  id: string;
  email: string;
  name: string;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string(),
  isActive: Yup.boolean(),
});

const availableFields = [
  { value: "vendor", label: "Vendor" },
  { value: "amount", label: "Amount" },
  { value: "description", label: "Description" },
  { value: "category", label: "Category" },
];

const getOperatorsByFieldType = (fieldType: string) => {
  switch (fieldType) {
    case "amount":
      return [
        { value: "greaterThan", label: "Greater than" },
        { value: "lessThan", label: "Less than" },
        { value: "equals", label: "Equals" },
        { value: "notEquals", label: "Not equals" },
      ];
    case "vendor":
    case "description":
    case "category":
      return [
        { value: "containsAny", label: "Contains" },
        { value: "doesNotContainAny", label: "Does not contain" },
        { value: "equals", label: "Equals" },
        { value: "notEquals", label: "Not equals" },
      ];
    default:
      return [];
  }
};

// Update handler types for react-select
type SelectOption = { value: string; label: string };

const InvoiceApprovalRules: React.FC<{}> = ({}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: ReduxState) => state.User);

  const [rules, setRules] = useState<InvoiceApprovalRule[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [editingRule, setEditingRule] = useState<InvoiceApprovalRule | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);
  const [teams, setTeams] = useState<Team[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(false);
  const [showMatchModal, setShowMatchModal] = useState(false);

  const loadRules = async () => {
    try {
      const response = await getInvoiceApprovalRules();
      setRules(response as InvoiceApprovalRule[]);
    } catch (err) {
      setError("Failed to load approval rules");
    }
  };

  const loadData = async () => {
    if (!user?.contract?.id) return;

    setLoading(true);
    setError(null);

    try {
      // Load teams
      const fetchedTeams = await fetchTeams(user.contract.id);
      setTeams(fetchedTeams);

      // Load contract admins
      const contractAdmins = await getContractAdmins(user.contract.id as any);

      // Load users (seats)
      const { rows: seats } = await getSeats({
        offset: 0,
        limit: 1000,
        filtered: [],
        sorted: [],
      });

      // Convert seats to users and include contract admins
      const users = [
        // Include contract admins
        ...contractAdmins.map((admin) => ({
          id: admin.id,
          email: admin.email,
          name: admin.email,
        })),
        // Include confirmed and enabled seats
        ...seats
          .filter((seat) => seat.enabled && seat.status === "confirmed")
          .map((seat) => ({
            id: seat.userId,
            email: seat.email,
            name: seat.name || seat.email,
          })),
      ];

      // Remove duplicates based on user ID
      const uniqueUsers = Array.from(
        new Map(users.map((user) => [user.id, user])).values()
      );

      setUsers(uniqueUsers);
    } catch (err) {
      console.error("Error loading teams and users:", err);
      setError("Failed to load teams and users");
      dispatch(
        createAlert({
          type: "error",
          message: "Failed to load teams and users",
          timeout: 0,
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadRules();
    loadData();
  }, [dispatch]);

  const handleClose = () => {
    setShowModal(false);
    setEditingRule(null);
  };

  const handleShow = (rule?: InvoiceApprovalRule) => {
    if (rule) {
      // Extract conditions from the json-rules-engine format
      const conditions = rule.rules?.conditions?.all?.[0]?.all
        ? rule.rules.conditions.all.map((condition) => ({
            field: condition.all[0].fact,
            operator: condition.all[0].operator,
            value:
              condition.all[0].fact === "amount"
                ? { amount: condition.all[0].value }
                : { keywords: condition.all[0].value },
          }))
        : [];

      // Extract approvers from the event params
      const approvers = rule.rules?.event?.params?.approvers || [];

      setEditingRule({
        id: rule.id,
        name: rule.name,
        description: rule.description || "",
        isActive: rule.isActive,
        ifConditions: conditions,
        thenApprovers: approvers,
        approverOperator: rule.rules?.event?.params?.approverOperator || "all",
        combinationOperator: rule.rules?.conditions?.all ? "all" : "any",
        rules: rule.rules,
        requireNWayMatch: rule.requireNWayMatch,
        requiredMatches: rule.requiredMatches,
      });
    } else {
      // Create new rule with default values
      setEditingRule({
        id: 0,
        name: "",
        description: "",
        isActive: true,
        ifConditions: [],
        thenApprovers: [],
        approverOperator: "all",
        combinationOperator: "all",
        rules: {
          conditions: {
            all: [],
          },
          event: {
            type: "invoice-approval-required",
            params: {
              ruleId: 0,
              ruleName: "",
              approvers: [],
              approverOperator: "all",
            },
          },
        },
        requireNWayMatch: false,
        requiredMatches: 2,
      });
    }
    setShowModal(true);
  };

  const convertToJsonRulesEngine = (rule: InvoiceApprovalRule) => {
    // Convert conditions to the nested format
    const conditions = {
      [rule.combinationOperator]: rule.ifConditions.map((condition) => {
        let conditionObj;

        // Handle different field types and operators
        if (condition.field === "amount") {
          conditionObj = {
            fact: condition.field,
            operator: condition.operator,
            value: (condition.value as { amount?: number }).amount || 0,
          };
        } else {
          // For text-based fields (vendor, description, category)
          conditionObj = {
            fact: condition.field,
            operator: condition.operator,
            value: (condition.value as { keywords?: string[] }).keywords || [],
          };
        }

        // Wrap single condition in 'all' array to match the required format
        return {
          all: [conditionObj],
        };
      }),
    };

    return {
      conditions,
      event: {
        type: "invoice-approval-required",
        params: {
          ruleId: rule.id,
          ruleName: rule.name,
          approvers: rule.thenApprovers.map((approver) => ({
            ...approver,
            type: approver.type || "team",
          })),
          approverOperator: rule.approverOperator,
        },
      },
    };
  };

  const handleSubmit = async (
    values: { name: string; description: string },
    { setSubmitting }
  ) => {
    setError(null);
    try {
      if (!editingRule) return;

      // Convert to json-rules-engine format
      const jsonRule = convertToJsonRulesEngine(editingRule);

      //   const rulesObj = {
      //     conditions: editingRule.ifConditions.map((condition) => ({
      //       field: condition.field,
      //       operator: condition.operator,
      //       value: condition.value,
      //     })),
      //     conditionOperator: editingRule.combinationOperator || "all",
      //     approvers: editingRule.thenApprovers.map((approver) => ({
      //       id: approver.id,
      //       type: approver.type,
      //     })),
      //     approverOperator: editingRule.approverOperator || "all",
      //   };

      const payload: InvoiceApprovalRule = {
        id: editingRule.id || 0,
        name: values.name,
        description: values.description || "",
        rules: jsonRule,
        isActive: editingRule.isActive ?? true,
        ifConditions: editingRule.ifConditions || [],
        thenApprovers: editingRule.thenApprovers || [],
        approverOperator: editingRule.approverOperator || "all",
        combinationOperator: editingRule.combinationOperator || "all",
        requireNWayMatch: editingRule.requireNWayMatch,
        requiredMatches: editingRule.requiredMatches,
      };

      if (editingRule.id) {
        await updateInvoiceApprovalRule(editingRule.id, payload);
        dispatch(
          createAlert({
            type: "success",
            message: "Rule updated successfully",
            timeout: 3000,
          })
        );
      } else {
        await createInvoiceApprovalRule(payload);
        dispatch(
          createAlert({
            type: "success",
            message: "Rule created successfully",
            timeout: 3000,
          })
        );
      }

      handleClose();
      loadRules();
    } catch (err) {
      console.error("Error saving rule:", err);
      setError("Failed to save approval rule");
      dispatch(
        createAlert({
          type: "error",
          message: "Failed to save approval rule",
          timeout: 0,
        })
      );
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async (ruleId: number) => {
    if (!window.confirm("Are you sure you want to delete this rule?")) {
      return;
    }

    // console.log({ ruleId });
    try {
      await deleteInvoiceApprovalRule(ruleId);
      loadRules();
    } catch (err) {
      setError("Failed to delete approval rule");
    }
  };

  const handleIfConditionChange = (
    index: number,
    field: string,
    value: any
  ) => {
    const newRule = { ...editingRule! };
    const condition = newRule.ifConditions[index];

    if (field === "field") {
      // Reset operator and value when field type changes
      condition.field = value;
      condition.operator = getOperatorsByFieldType(value)[0].value;
      condition.value = value === "amount" ? { amount: 0 } : { keywords: [] };
    } else if (field === "operator") {
      condition.operator = value;
    } else if (field === "value") {
      if (condition.field === "amount") {
        condition.value = { amount: parseFloat(value) || 0 };
      } else {
        // For vendor/description/category, value is now an array of selected options
        condition.value = {
          keywords: Array.isArray(value) ? value.map((v) => v.value) : [],
        };
      }
    }

    setEditingRule(newRule);
  };

  const handleApproverChange = (
    index: number,
    field: string,
    value: string | undefined,
    option?: any
  ) => {
    if (!value) return;
    const newRule = { ...editingRule! };
    newRule.thenApprovers[index] = {
      id: value,
      type: option?.type || "user",
    };
    setEditingRule(newRule);
  };

  const getApproverOptions = () => {
    const teamOptions = teams.map((team) => ({
      value: `${team.id}`,
      label: `Team: ${team.name}`,
      type: "team",
    }));

    const userOptions = users.map((user) => ({
      value: `${user.id}`,
      label: `User: ${user.email}`,
      type: "user",
    }));

    return [
      { label: "Teams", options: teamOptions },
      { label: "Users", options: userOptions },
    ];
  };

  // Add new condition
  const handleAddCondition = () => {
    const newRule = { ...editingRule! };
    newRule.ifConditions.push({
      field: "vendor",
      operator: "containsAny",
      value: { keywords: [] },
    });
    setEditingRule(newRule);
  };

  // Add new approver
  const handleAddApprover = () => {
    const newRule = { ...editingRule! };
    newRule.thenApprovers.push({ id: "", type: "user" });
    setEditingRule(newRule);
  };

  // Add toggle active status handler
  const handleToggleActive = async (rule: InvoiceApprovalRule) => {
    try {
      if (!rule.id) return;
      await updateInvoiceApprovalRule(rule.id, {
        ...rule,
        isActive: !rule.isActive,
      } as InvoiceApprovalRule);
      loadRules();
      dispatch(
        createAlert({
          type: "success",
          message: `Rule ${
            rule.isActive ? "disabled" : "enabled"
          } successfully`,
          timeout: 3000,
        })
      );
    } catch (err) {
      setError("Failed to update rule status");
    }
  };

  // Add handlers for operator changes
  const handleConditionOperatorChange = (
    value: ValueType<SelectOption, false>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    if (!editingRule || !value) return;
    setEditingRule({
      ...editingRule,
      combinationOperator: (value as SelectOption).value as "all" | "any",
    });
  };

  const handleApproverOperatorChange = (
    value: ValueType<SelectOption, false>,
    actionMeta: ActionMeta<SelectOption>
  ) => {
    if (!editingRule || !value) return;
    setEditingRule({
      ...editingRule,
      approverOperator: (value as SelectOption).value as "all" | "any",
    });
  };

  // Add this helper function to format the conditions
  const formatConditionSummary = (rule: InvoiceApprovalRule) => {
    if (!rule.rules?.conditions) return "";

    const formatCondition = (condition: any): string => {
      if (!condition) return "";

      // Handle nested all/any conditions
      if (condition.all || condition.any) {
        const conditions = condition.all || condition.any;
        const operator = condition.all ? "and" : "or";

        return conditions
          .map((c: any) => formatCondition(c))
          .filter((s: string) => s)
          .join(` ${operator} `);
      }

      // Handle leaf condition
      if (condition.fact && condition.operator) {
        const field = condition.fact;
        const operator = condition.operator;
        const value = condition.value;

        if (field === "amount") {
          return `amount ${
            operator === "greaterThan"
              ? ">="
              : operator === "lessThan"
              ? "<="
              : operator === "equals"
              ? "="
              : operator === "notEquals"
              ? "!="
              : operator
          } ${value}`;
        } else {
          const keywords = Array.isArray(value) ? value : [];
          const operatorText =
            operator === "containsAny"
              ? "contains"
              : operator === "doesNotContainAny"
              ? "does not contain"
              : operator === "equals"
              ? "equals"
              : operator === "notEquals"
              ? "not equals"
              : operator;

          return `${field} ${operatorText} "${keywords.join(", ")}"`;
        }
      }

      return "";
    };

    // Start with the top level conditions
    const topLevel = rule.rules.conditions;
    const operator = "all" in topLevel ? "and" : "or";
    const conditions = topLevel.all || topLevel.any || [];

    return conditions
      .map((condition: any) => formatCondition(condition))
      .filter((s: string) => s)
      .join(` ${operator} `);
  };

  // Add this helper function to format the approvers
  const formatApproverSummary = (
    rule: InvoiceApprovalRule,
    teams: Team[],
    users: User[]
  ) => {
    // console.log({ teams, users });
    const approvers = rule.rules?.event?.params?.approvers || [];
    return approvers
      .map((approver) => {
        if (approver.type === "team") {
          const team = teams.find((t) => t.id === approver.id);
          return `Team "${team?.name || "Unknown"}"`;
        } else {
          const user = users.find((u) => u.id === approver.id);
          return `User "${user?.email || "Unknown"}"`;
        }
      })
      .join(
        ` ${
          rule.rules?.event?.params?.approverOperator === "all" ? "and" : "or"
        } `
      );
  };

  return (
    <div className="p-3">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2></h2>
        <Button variant="link" onClick={() => handleShow()}>
          <FontAwesomeIcon icon="plus" className="mr-1" />
          Add New Rule
        </Button>
      </div>

      {error && <Alert variant="danger">{error}</Alert>}

      {loading ? (
        <Card className="text-center p-5">
          <Card.Body>
            <h4>Loading...</h4>
          </Card.Body>
        </Card>
      ) : !rules || rules.length === 0 ? (
        <Card className="text-center p-5">
          <Card.Body>
            <h4>No Rules Defined</h4>
            <p className="text-muted">
              Create your first invoice approval rule by clicking the "Add New
              Rule" button.
            </p>
          </Card.Body>
        </Card>
      ) : (
        <div className="row g-3">
          {rules.map((rule) => (
            <div key={rule.id} className="col-12">
              <Card>
                <Card.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center flex-grow-1">
                      <div style={{ minWidth: "200px" }}>
                        <h6 className="mb-0">{rule.name}</h6>
                        {rule.description && (
                          <small className="text-muted">
                            {rule.description}
                          </small>
                        )}
                      </div>
                      <div className="mx-4 flex-grow-1">
                        <span className="text-muted">
                          When {formatConditionSummary(rule)} → Approved by{" "}
                          {formatApproverSummary(rule, teams, users)}
                          {rule.requireNWayMatch && (
                            <span>
                              {" "}
                              • Requires {rule.requiredMatches || 2}-way match
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex gap-2">
                      <Button
                        variant="outline-primary"
                        size="sm"
                        className="mr-1"
                        onClick={() => handleShow(rule)}
                      >
                        <FontAwesomeIcon icon="edit" className="me-1" />
                        Edit
                      </Button>
                      <Button
                        variant="outline-danger"
                        size="sm"
                        onClick={() => rule.id && handleDelete(rule.id)}
                      >
                        <FontAwesomeIcon icon="trash" className="me-1" />
                        Delete
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
      )}

      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header
          closeButton
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          placeholder=""
        >
          <Modal.Title>{editingRule ? "Edit Rule" : "New Rule"}</Modal.Title>
        </Modal.Header>
        {editingRule && (
          <Formik
            initialValues={{
              name: editingRule?.name || "",
              description: editingRule?.description || "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting, handleSubmit }) => (
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  handleSubmit(e);
                }}
              >
                <Modal.Body>
                  <div className="mb-3">
                    <label htmlFor="name" className="form-label">
                      Name
                    </label>
                    <Field
                      name="name"
                      className={`form-control ${
                        errors.name && touched.name ? "is-invalid" : ""
                      }`}
                    />
                    {errors.name && touched.name && (
                      <div className="invalid-feedback">{errors.name}</div>
                    )}
                  </div>

                  <div className="mb-3">
                    <label htmlFor="description" className="form-label">
                      Description
                    </label>
                    <Field
                      name="description"
                      as="textarea"
                      rows={2}
                      className="form-control"
                    />
                  </div>

                  <Card className="mb-3">
                    <Card.Header>
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Conditions</h5>
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={handleAddCondition}
                        >
                          Add Condition
                        </Button>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      {(editingRule?.ifConditions || []).length === 0 ? (
                        <div className="text-center text-muted p-3">
                          <p>No conditions defined yet.</p>
                          <p className="mb-0">
                            Add conditions to specify when this rule should
                            apply.
                          </p>
                        </div>
                      ) : (
                        <>
                          <div className="mb-3">
                            <Form.Label>Match</Form.Label>
                            <Select
                              options={[
                                { value: "all", label: "All conditions (AND)" },
                                { value: "any", label: "Any condition (OR)" },
                              ]}
                              value={{
                                value:
                                  editingRule?.combinationOperator || "all",
                                label:
                                  editingRule?.combinationOperator === "all"
                                    ? "All conditions (AND)"
                                    : "Any condition (OR)",
                              }}
                              onChange={handleConditionOperatorChange}
                            />
                          </div>

                          {editingRule?.ifConditions.map((condition, index) => (
                            <Row
                              key={index}
                              className="mb-3 align-items-center"
                            >
                              <FormGroup as={Col} md={2}>
                                <Select
                                  options={availableFields}
                                  value={availableFields.find(
                                    (f) => f.value === condition.field
                                  )}
                                  onChange={(option) =>
                                    handleIfConditionChange(
                                      index,
                                      "field",
                                      option?.value
                                    )
                                  }
                                />
                              </FormGroup>
                              <FormGroup as={Col} md={3}>
                                <Select
                                  options={getOperatorsByFieldType(
                                    condition.field
                                  )}
                                  value={{
                                    value: condition.operator,
                                    label:
                                      getOperatorsByFieldType(
                                        condition.field
                                      ).find(
                                        (op) => op.value === condition.operator
                                      )?.label || condition.operator,
                                  }}
                                  onChange={(option) =>
                                    handleIfConditionChange(
                                      index,
                                      "operator",
                                      option?.value
                                    )
                                  }
                                />
                              </FormGroup>
                              <FormGroup as={Col} md={6}>
                                {condition.field === "vendor" ? (
                                  <CreatableSelect
                                    isMulti
                                    isClearable
                                    options={[]}
                                    value={
                                      ("keywords" in condition.value &&
                                        condition.value.keywords?.map(
                                          (keyword) => ({
                                            value: keyword,
                                            label: keyword,
                                          })
                                        )) ||
                                      []
                                    }
                                    onChange={(newValue) =>
                                      handleIfConditionChange(
                                        index,
                                        "value",
                                        newValue
                                      )
                                    }
                                    placeholder="Type and press enter to add vendors..."
                                  />
                                ) : condition.field === "amount" ? (
                                  <Form.Control
                                    type="number"
                                    value={
                                      "amount" in condition.value
                                        ? condition.value.amount
                                        : 0
                                    }
                                    onChange={(e) =>
                                      handleIfConditionChange(
                                        index,
                                        "value",
                                        e.target.value
                                      )
                                    }
                                  />
                                ) : (
                                  <Select
                                    isMulti
                                    isClearable
                                    isCreatable
                                    createOptionPosition="first"
                                    formatCreateLabel={(inputValue) =>
                                      `Add "${inputValue}"`
                                    }
                                    options={[]} // We don't need predefined options
                                    value={
                                      ("keywords" in condition.value &&
                                        condition.value.keywords?.map(
                                          (keyword) => ({
                                            value: keyword,
                                            label: keyword,
                                          })
                                        )) ||
                                      []
                                    }
                                    onChange={(newValue) =>
                                      handleIfConditionChange(
                                        index,
                                        "value",
                                        newValue
                                      )
                                    }
                                    onCreateOption={(inputValue) => {
                                      const newKeywords =
                                        "keywords" in condition.value
                                          ? [
                                              ...(condition.value.keywords ||
                                                []),
                                              inputValue,
                                            ]
                                          : [inputValue];

                                      handleIfConditionChange(
                                        index,
                                        "value",
                                        newKeywords.map((k) => ({
                                          value: k,
                                          label: k,
                                        }))
                                      );
                                    }}
                                    placeholder="Type and press enter to add values"
                                    components={{
                                      DropdownIndicator: null,
                                    }}
                                  />
                                )}
                              </FormGroup>
                              <Col md={1}>
                                <Button
                                  variant="link"
                                  className="text-danger"
                                  onClick={() => {
                                    const newRule = { ...editingRule! };
                                    newRule.ifConditions.splice(index, 1);
                                    setEditingRule(newRule);
                                  }}
                                >
                                  <FontAwesomeIcon icon="trash" />
                                </Button>
                              </Col>
                            </Row>
                          ))}
                        </>
                      )}
                    </Card.Body>
                  </Card>

                  <Card className="mb-3">
                    <Card.Header>
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Approvers</h5>
                        <Button
                          variant="outline-primary"
                          size="sm"
                          onClick={handleAddApprover}
                        >
                          Add Approver
                        </Button>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      {(editingRule?.thenApprovers || []).length === 0 ? (
                        <div className="text-center text-muted p-3">
                          <p>No approvers defined yet.</p>
                          <p className="mb-0">
                            At least one approver is required to save the rule.
                          </p>
                        </div>
                      ) : (
                        <>
                          <Row className="mb-3">
                            <FormGroup
                              as={Col}
                              className="d-flex align-items-center"
                            >
                              <Form.Label className="mr-1">
                                Require approval from
                              </Form.Label>
                              <div style={{ minWidth: "150px" }}>
                                <Select
                                  options={[
                                    { value: "all", label: "All" },
                                    { value: "any", label: "Any" },
                                  ]}
                                  value={{
                                    value:
                                      editingRule?.approverOperator || "all",
                                    label:
                                      editingRule?.approverOperator === "all"
                                        ? "All"
                                        : "Any",
                                  }}
                                  onChange={handleApproverOperatorChange}
                                />
                              </div>
                              <span className="ms-2">
                                of the following approvers
                              </span>
                            </FormGroup>
                          </Row>

                          {editingRule?.thenApprovers.map((approver, index) => (
                            <Row key={index} className="mb-3">
                              <FormGroup as={Col} md={10}>
                                <Select
                                  options={getApproverOptions()}
                                  value={getApproverOptions()
                                    .flatMap((group) => group.options)
                                    .find(
                                      (option) => option.value === approver.id
                                    )}
                                  onChange={(option) =>
                                    handleApproverChange(
                                      index,
                                      "id",
                                      option?.value,
                                      option
                                    )
                                  }
                                />
                              </FormGroup>
                              <Col md={2}>
                                <Button
                                  variant="link"
                                  className="text-danger"
                                  onClick={() => {
                                    const newRule = { ...editingRule! };
                                    newRule.thenApprovers.splice(index, 1);
                                    setEditingRule(newRule);
                                  }}
                                >
                                  <FontAwesomeIcon icon="trash" />
                                </Button>
                              </Col>
                            </Row>
                          ))}
                        </>
                      )}
                    </Card.Body>
                  </Card>

                  {/* <Card className="mb-3">
                    <Card.Header>Invoice Matching Requirements</Card.Header>
                    <Card.Body>
                      <Form.Group className="mb-3">
                        <Form.Check
                          type="checkbox"
                          id="requireNWayMatch"
                          label="Require N-Way Match"
                          checked={editingRule?.requireNWayMatch}
                          onChange={(e) =>
                            setEditingRule({
                              ...editingRule!,
                              requireNWayMatch: e.target.checked,
                              requiredMatches: e.target.checked
                                ? editingRule?.requiredMatches || 2
                                : 2,
                            })
                          }
                        />
                      </Form.Group>

                      {editingRule?.requireNWayMatch && (
                        <Form.Group>
                          <Form.Label>Required Number of Matches</Form.Label>
                          <Form.Control
                            type="number"
                            min={2}
                            value={editingRule?.requiredMatches || 2}
                            onChange={(e) =>
                              setEditingRule({
                                ...editingRule!,
                                requiredMatches: parseInt(e.target.value),
                              })
                            }
                          />
                        </Form.Group>
                      )}
                    </Card.Body>
                  </Card> */}

                  {/* <Card className="mb-3">
                    <Card.Header>Generated Rules (Debug)</Card.Header>
                    <Card.Body>
                      <pre className="mb-0">
                        {JSON.stringify(
                          {
                            conditions: editingRule?.ifConditions || [],
                            conditionOperator:
                              editingRule?.combinationOperator || "all",
                            approvers: editingRule?.thenApprovers || [],
                            approverOperator:
                              editingRule?.approverOperator || "all",
                          },
                          null,
                          2
                        )}
                      </pre>
                    </Card.Body>
                  </Card> */}
                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-between align-items-center">
                  <div className="text-danger">
                    {!editingRule?.thenApprovers?.length &&
                      "At least one approver is required"}
                  </div>
                  <div>
                    <Button
                      variant="secondary"
                      onClick={handleClose}
                      className="mr-1"
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => handleSubmit()}
                      disabled={
                        isSubmitting ||
                        !(editingRule?.thenApprovers || []).length
                      }
                    >
                      Save
                    </Button>
                  </div>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        )}
      </Modal>

      {/* <Button variant="link" onClick={() => setShowMatchModal(true)}>
        Match Rules
      </Button>

      <Modal
        show={showMatchModal}
        onHide={() => setShowMatchModal(false)}
        size="lg"
      >
        <Modal.Header
          closeButton
          onPointerEnterCapture={() => {}}
          onPointerLeaveCapture={() => {}}
          placeholder=""
        >
          <Modal.Title>Match Rules</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InvoiceMatchManager
            transactionLineId={123}
            onMatchCreated={() => {
              setShowMatchModal(false);
              // Refresh data if needed
            }}
          />
        </Modal.Body>
      </Modal> */}
    </div>
  );
};

export default InvoiceApprovalRules;

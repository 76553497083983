import React from "react";
import { Alert, Badge, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { updateJob } from "../../../../docuclipper/api";
import { ReduxState } from "../../../../redux";
import EditableText from "../../../Docuclipper/EditableText";
import { EditText } from "react-edit-text";
import "react-edit-text/dist/index.css";
import EditJobTags from "../../../Docuclipper/Tags/EditJobTags";
import { ReconciledNo, ReconciledYes } from "../ReconciledBadge";
import ClipLoader from "react-spinners/ClipLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  EditableFieldRequiredText,
  EditableFieldText,
} from "../../../../utils/EditableField";
import { useIsAdmin } from "src/utils/auth";
import { fetchJobRatings } from "src/redux/reducers/JobRatings";

type Props = {};
export const JobBadRatings: React.FC<Props> = ({}) => {
  const dispatch = useDispatch();
  const { job, progress } = useSelector((state: ReduxState) => state.JobData);
  const { jobRatings } = useSelector((state: ReduxState) => state.JobRatings);

  React.useEffect(() => {
    if (job?.id) {
      dispatch(fetchJobRatings(job.id));
    }
  }, []);
  if (jobRatings.length === 0) {
    return null;
  }
  return (
    <Alert variant="info" className="mt-2">
      The team is investigating the issue you reported, thank you for your
      patience.
    </Alert>
  );
};
export default JobBadRatings;

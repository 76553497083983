import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Form, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  CustomFormatItem,
  JobType,
} from "../../../docuclipper/DocuclipperTypes";
import {
  updateCustomFormatItemRedux,
  deleteCustomFormatItemRedux,
} from "../../../redux/reducers/CustomFormat";

type Props = {
  cfi: CustomFormatItem;
  jobType: JobType;
};

export const CustomFormatItemComp: React.FC<Props> = ({ cfi, jobType }) => {
  const dispatch = useDispatch();
  const getOptions = () => {
    const commonOptions = [
      { value: "autoIncrement", label: "Auto Increment" },
      { value: "transactionType", label: "Transaction Type" },
      { value: "hardcodedValue", label: "Hardcoded Value" },
    ];

    if (jobType === "Invoice") {
      return [
        { value: "id", label: "Invoice Number" },
        { value: "date", label: "Invoice Date" },
        { value: "dueDate", label: "Invoice Due Date" },
        { value: "tax", label: "Tax Amount" },
        { value: "total", label: "Invoice Total" },
        { value: "item", label: "Line Item Description" },
        { value: "quantity", label: "Line Item Quantity" },
        { value: "unitPrice", label: "Unit Price per Item" },
        { value: "price", label: "Line Item Total" },
        { value: "service", label: "Service Description" },
        { value: "category", label: "Item/Service Category" },
        { value: "customer", label: "Customer/Bill To" },
        { value: "vendor", label: "Vendor/From" },
        { value: "poNumber", label: "Purchase Order Number" },
      ];
    }

    return [
      { value: "date", label: "Date" },
      { value: "date2", label: "Date2" },
      { value: "payee", label: "Payee" },
      { value: "name", label: "Description" },
      { value: "amount", label: "Amount" },
      { value: "credit", label: "Credit" },
      { value: "debit", label: "Debit" },
      { value: "balance", label: "Balance" },
      { value: "checkNumber", label: "Check Number" },
      { value: "refNumber", label: "Reference Number" },
      { value: "account", label: "Account" },
      { value: "category", label: "Category" },
      { value: "categoryKeyword", label: "Category Keyword" },
      { value: "fileName", label: "File Name" },
      { value: "pageNumber", label: "Page Number" },
      { value: "metadata", label: "Metadata" },
      ...commonOptions,
    ];
  };

  const findLabelForSource = (source: string | undefined) => {
    if (!source) return undefined;
    const options = getOptions();
    const option = options.find((opt) => opt.value === source);
    return option?.label;
  };

  const [localHeading, setLocalHeading] = useState(
    cfi.heading?.text ||
      findLabelForSource(cfi.source) ||
      `Column ${cfi.index + 1}`
  );
  const [localCreditLabel, setLocalCreditLabel] = useState(
    cfi.creditLabel || "Credit"
  );
  const [localDebitLabel, setLocalDebitLabel] = useState(
    cfi.debitLabel || "Debit"
  );
  const [localHardcodedValue, setLocalHardcodedValue] = useState(
    cfi.hardcodedValue || ""
  );

  return (
    <div>
      {/* <h3>Column {cfi.index + 1}</h3> */}
      <Row className="align-items-center mx-2">
        {/* <Form.Label className="mr-2 mb-0">Heading:</Form.Label> */}
        <h3>
          <span>{`Column ${cfi.index + 1}`}</span>
        </h3>
        <span className="mx-2">Heading:</span>
        <Form.Control
          type="text"
          value={localHeading}
          onChange={(e) => setLocalHeading(e.target.value)}
          onBlur={() =>
            dispatch(
              updateCustomFormatItemRedux({
                ...cfi,
                heading: { text: localHeading },
              })
            )
          }
          className="mr-2"
          style={{ width: "auto" }}
        />
        <Button
          variant="link"
          onClick={() => dispatch(deleteCustomFormatItemRedux(cfi))}
        >
          <FontAwesomeIcon icon="trash" />
          <span className="ml-2">Delete Column</span>
        </Button>
      </Row>
      <Form.Group controlId="exampleForm.ControlSelect1">
        <Row className="align-items-center mx-2">
          <span className="mx-2">Field:</span>
          <Form.Control
            className="w-50"
            as="select"
            value={cfi.source}
            onChange={(e) =>
              dispatch(
                updateCustomFormatItemRedux({
                  ...cfi,
                  source: e.target.value as any,
                })
              )
            }
          >
            {getOptions().map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Control>
        </Row>
      </Form.Group>

      {cfi.source === "transactionType" && (
        <>
          <Form.Group>
            <Form.Label>Credit Label</Form.Label>
            <Form.Control
              type="text"
              value={localCreditLabel}
              onChange={(e) => setLocalCreditLabel(e.target.value)}
              onBlur={() =>
                dispatch(
                  updateCustomFormatItemRedux({
                    ...cfi,
                    creditLabel: localCreditLabel,
                  })
                )
              }
              placeholder="E.g. Deposit"
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Debit Label</Form.Label>
            <Form.Control
              type="text"
              value={localDebitLabel}
              onChange={(e) => setLocalDebitLabel(e.target.value)}
              onBlur={() =>
                dispatch(
                  updateCustomFormatItemRedux({
                    ...cfi,
                    debitLabel: localDebitLabel,
                  })
                )
              }
              placeholder="E.g. Payment"
            />
          </Form.Group>
        </>
      )}

      {cfi.source === "hardcodedValue" && (
        <Form.Group>
          <Form.Label>Value</Form.Label>
          <Form.Control
            type="text"
            value={localHardcodedValue}
            onChange={(e) => setLocalHardcodedValue(e.target.value)}
            onBlur={() =>
              dispatch(
                updateCustomFormatItemRedux({
                  ...cfi,
                  hardcodedValue: localHardcodedValue,
                })
              )
            }
            placeholder="Enter hardcoded value"
          />
        </Form.Group>
      )}
    </div>
  );
};

export default CustomFormatItemComp;

import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../../redux";
import { closeModal } from "../../../redux/reducers/ModalManager";
import AlertBanner from "../AlertBanner/AlertBanner";
import { JobHelpMenu } from "./HelpMenu";

type Props = {};
export const RatingsModal: React.SFC<Props> = ({}) => {
  const dispatch = useDispatch();
  const { activeModal } = useSelector(
    (state: ReduxState) => state.ModalManager
  );

  return (
    <Modal
      size="lg"
      show={activeModal === "ratings"}
      onHide={() => dispatch(closeModal("ratings"))}
    >
      <Modal.Header closeButton={true}>
        <div className="h3">Report an issue</div>
        <div style={{ marginLeft: "12em" }}>
          <AlertBanner />
        </div>
      </Modal.Header>
      <Modal.Body>
        {/* <RatingsComp
          job={job}
          onFeedback={(feedback) => {
            if (job && job.id) {
              rateJob({
                jobId: job.id,
                ...feedback,
              }).catch((err) => null);
              dispatch(
                createAlert({
                  message: "Thank you for your feedback",
                  timeout: 0,
                  type: "success",
                })
              );
            }

            dispatch(closeModal("ratings"));
          }}
        /> */}
        <JobHelpMenu />
      </Modal.Body>
    </Modal>
  );
};
export default RatingsModal;

import React from "react";
import Select from "react-select";
import { ReconciledOption, ReconciledValue } from "./DownloadOptions/utils";
import { Badge, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { components } from "react-select";

type Props = { onChange; options; value };
export const SelectWithBadge: React.FC<Props> = ({
  onChange,
  options,
  value,
}) => {
  return (
    <Select
      maxMenuHeight={600}
      options={options}
      value={value}
      onChange={onChange}
      components={{
        Option: (props) => (
          <components.Option {...props}>
            <Row className="justify-content-between px-2">
              <span className="ml-2">{props.data.label}</span>
              {props.data.hasError && (
                <FontAwesomeIcon
                  className="ml-2"
                  color="red"
                  icon="exclamation-triangle"
                />
              )}
              {props.data.hasWarning && (
                // <Badge className="ml-2" variant="warning">
                <FontAwesomeIcon
                  className="ml-2"
                  color="orange"
                  icon="exclamation-triangle"
                />
                // </Badge>
              )}
            </Row>
          </components.Option>
        ),
        SingleValue: (props) => {
          const { data, ...rest } = props;
          return (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <span>{data.label}</span>
                {data.hasError && (
                  <FontAwesomeIcon
                    className="ml-2"
                    color="red"
                    icon="exclamation-triangle"
                  />
                )}
                {data.hasWarning && (
                  // <Badge className="ml-2" variant="warning">
                  <FontAwesomeIcon
                    className="ml-2"
                    color="orange"
                    icon="exclamation-triangle"
                  />
                  // </Badge>
                )}
              </div>
            </>
          );
        },
      }}
    />
  );
};
export default SelectWithBadge;

import React, { useEffect, useState } from "react";
import {
  Row,
  Form,
  FormGroup,
  Button,
  Alert,
  Col,
  Container,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Field, Formik } from "formik";
import { groupBy, uniq } from "lodash";
import { updateJobTransaction } from "../../../docuclipper/api";
import { FormDoc } from "../../../docuclipper/DocuclipperTypes";
import { ReduxState } from "../../../redux";
import {
  fetchTls,
  setSelectedDocumentId,
  setSelectedRowNumber,
} from "../../../redux/reducers/TransactionManager";
import { createAlert } from "../../../redux/reducers/Alerts";
import { fetchIntegrations } from "../../../redux/reducers/Integration";
import {
  formikInput,
  isNullOrUndefined,
  updateObjKeyValue,
} from "../../../utils/utils";

import { ExportTypeDropdown } from "../DataArchive/TransactionManager/DownloadOptions/ExportTypeDropdown";
import { downloadSpreadsheetHelper } from "../DataArchive/TransactionManager/DownloadOptions/utils";

import PDFViewer from "../PdfViewer";
import { getJwt } from "../../../auth";

export const ExportForm: React.FC = () => {
  const dispatch = useDispatch();
  const { job } = useSelector((state: ReduxState) => state.JobData);
  const { tls, selectedDocumentId, selectedRowNumber } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );
  const exportTo = useSelector(
    (state: ReduxState) => state.DownloadOptions.selectedExportType
  );

  const [pdfURL, setPdfURL] = useState("");
  const [selectedPageNumber, setSelectedPageNumber] = useState(1);

  const getPdfURL = () => {
    const domain =
      process.env.NODE_ENV === "production"
        ? "https://www.docuclipper.com"
        : "http://localhost:8080";

    const documentId = selectedDocumentId || tls.tls[0].documentId;

    const url = `
      ${domain}/api/v1/protected/document/${documentId}/file.pdf?token=${getJwt()}#page=${selectedPageNumber}&toolbar=0
    `;

    return url;
  };

  useEffect(() => {
    dispatch(fetchTls(true));
    dispatch(fetchIntegrations());
  }, []);

  useEffect(() => {
    if (tls.tls.length === 0) return;

    setPdfURL(getPdfURL());
  }, [selectedPageNumber, tls.tls.length]);

  const selectedTl =
    !isNullOrUndefined(selectedDocumentId) &&
    !isNullOrUndefined(selectedRowNumber)
      ? tls.tls.filter((x) => x.documentId === selectedDocumentId)[
          selectedRowNumber as number
        ]
      : null;

  // const formDoc: FormDoc = selectedTl
  //   ? JSON.parse(selectedTl.row as any)
  //   : null;

  const formDoc = selectedTl?.row;

  // Memoize the entries of formDoc to prevent unnecessary re-renders
  const formDocEntries = React.useMemo(() => {
    return Object.entries(formDoc || {});
  }, [selectedTl]);

  if (tls.tls.length === 0) {
    return null;
  }

  // console.log({
  //   formDoc,
  //   selectedTl,
  //   selectedDocumentId,
  //   selectedRowNumber,
  //   tls,
  // });
  if (!formDoc) {
    // console.log("no formdoc");
    return null;
  }

  const { documents } = tls;

  const getName = (documentId) => {
    const x = documents.filter((x) => x.id === documentId);
    if (x && x.length > 0) {
      return x[0].originalname;
    }

    return documentId;
  };

  const tlsByDocId = groupBy(tls.tls, "documentId");

  const handleDocumentChange = (docId, selectedIndex, pageNumber) => {
    dispatch(setSelectedDocumentId(docId));
    dispatch(setSelectedRowNumber(selectedIndex));
    setSelectedPageNumber(pageNumber);
  };

  const handleOnSubmit = (values) => {
    let formDocData: FormDoc = {};

    let alertValidation = false;

    if (!alertValidation && job && selectedTl && selectedDocumentId) {
      formDocData = selectedTl ? JSON.parse(selectedTl.row as any) : null;

      formDocData = updateObjKeyValue(formDocData, values);

      updateJobTransaction(job?.id, selectedTl.id as any, {
        newValue: JSON.stringify(formDocData),
      })
        .then((data) => {
          // const currentDocumentId = selectedDocumentId;
          // const currentRowNumber = selectedRowNumber;
          // const currentPageNumber = selectedPageNumber;
          // dispatch(
          //   fetchTls(true, () => {
          //     dispatch(setSelectedDocumentId(currentDocumentId));
          //     if (currentRowNumber !== null) {
          //       dispatch(setSelectedRowNumber(currentRowNumber));
          //     }
          //     setSelectedPageNumber(currentPageNumber);
          //   })
          // );
        })
        .catch(() =>
          dispatch(
            createAlert({
              message: "Error updating form",
              timeout: 0,
              type: "error",
            })
          )
        );
    }
  };

  const handleDownload = () => {
    downloadSpreadsheetHelper(
      job,
      "Form",
      exportTo,
      {
        dateFormat: "YYYY-MM-DD",
        fileType: exportTo.toUpperCase(),
      },
      uniq((tls.tls || []).map((x) => x.documentId)),
      [],
      [],
      [],
      "date",
      "asc",
      "csv" as any
    );
  };

  let cta = "Download";
  if (["custom-basic", "xlsx"].includes(exportTo)) {
    // cta = `Download Excel (${exportTo === "xlsx" ? exportTo.toUpperCase() : "CSV"
    //   }) - ${tls.tls.length} items`;
    cta = `Download Excel`;
  }

  return (
    <Container fluid>
      {/* <div className="mx-2">
        <Alert variant="info">
          We just added support for converting form. Email{" "}
          <a href="mailto:support@docuclipper.com">support@docuclipper.com</a>{" "}
          if you run into any issues or have feedback.
        </Alert>
      </div> */}
      <Row>
        <Col md="6">
          {(Object.keys(tlsByDocId) || []).map((docId, i) => (
            <React.Fragment key={`${docId}-${i}`}>
              <div className="font-weight-bold mb-2">
                {getName(tlsByDocId[docId][0].documentId)}
                <Form.Control
                  as="select"
                  value={
                    selectedDocumentId === docId && selectedRowNumber !== null
                      ? selectedRowNumber.toString()
                      : ""
                  }
                  onChange={(e) => {
                    const selectedIndex = parseInt(e.target.value, 10);
                    const selectedTransaction =
                      tlsByDocId[docId][selectedIndex];
                    // console.log("handleDocumentChange");
                    handleDocumentChange(
                      docId,
                      selectedIndex,
                      selectedTransaction.pageNumber + 1
                    );
                  }}
                  className="ml-2"
                  style={{
                    display: "inline-block",
                    width: "auto",
                    marginLeft: "20px",
                  }}
                >
                  {tlsByDocId[docId].map((tl, index) => (
                    <option key={`document-${index}`} value={index}>
                      Document {index + 1}
                    </option>
                  ))}
                </Form.Control>
              </div>
            </React.Fragment>
          ))}
          <PDFViewer key={selectedPageNumber} url={pdfURL} />
        </Col>
        <Col md="6">
          <Row>
            <Col>
              <FormGroup>
                <strong>
                  <Form.Label>Export To</Form.Label>
                </strong>
                {/* <ExportTypeDropdown /> */}
              </FormGroup>
            </Col>
          </Row>
          <Row className="justify-content-end mb-4">
            <Button
              className="mx-4  btn--still-want-cancel"
              variant="primary"
              onClick={handleDownload}
            >
              {cta}
            </Button>
          </Row>
          <Formik
            key={selectedDocumentId}
            enableReinitialize={true}
            initialValues={formDocEntries.reduce((acc, [key, value]) => {
              acc[`key-${key}`] = key;
              acc[`value-${key}`] = value;
              return acc;
            }, {})}
            onSubmit={handleOnSubmit}
          >
            {({ values }) => {
              return (
                <>
                  <div style={{ height: "95vh", overflow: "scroll" }}>
                    <Row>
                      <Col md={6}>
                        <strong>
                          <Form.Label>Key</Form.Label>
                        </strong>
                      </Col>
                      <Col md={6}>
                        <strong>
                          <Form.Label>Value</Form.Label>
                        </strong>
                      </Col>
                    </Row>

                    {formDocEntries.map(([key, value]) => (
                      <Row key={key}>
                        <Col md={6}>
                          <FormGroup>
                            <Field
                              placeholder=""
                              name={`key-${key}`}
                              type="text"
                              component={formikInput}
                              defaultValue={key}
                              value={values[`key-${key}`] || ""}
                              onBlur={() => handleOnSubmit(values)}
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Field
                              placeholder=""
                              name={`value-${key}`}
                              type="text"
                              component={formikInput}
                              defaultValue={value}
                              value={values[`value-${key}`] || ""}
                              onBlur={() => handleOnSubmit(values)}
                              readOnly
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    ))}
                  </div>
                </>
              );
            }}
          </Formik>
        </Col>
      </Row>
    </Container>
  );
};
export default ExportForm;

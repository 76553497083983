import { TableFilterConfig } from "src/docuclipper/DocuclipperTypes";
import { ExternalFilter } from "src/redux/reducers/ExternalFilter";

export const convertExternalFiltersToTableFilterConfig = (
  externalFilters: ExternalFilter["externalFilters"]
): TableFilterConfig[] => {
  // console.log({ externalFilters });

  const tableFilterConfigs: TableFilterConfig[] = Object.entries(
    externalFilters
  ).map(([key, { filterType, type, filterValue, filterValueTo }]) => ({
    field: key,
    type,
    value: filterValue,
    operator: filterType as any,
    valueFrom: filterValue,
    valueTo: filterValueTo,
  }));

  return tableFilterConfigs;
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "..";
import { getColumnConfigs } from "../../docuclipper/api";
import { ColumnConfig } from "../../docuclipper/DocuclipperTypes";

type ColumnConfigs = { [k: string]: ColumnConfig[] };
const initialState: {
  columnConfigs: ColumnConfigs;
  selectedField: null | string;
  loading: boolean;
  isModalOpen: boolean;
} = {
  columnConfigs: {},
  selectedField: null,
  loading: false,
  isModalOpen: false,
};

const slice = createSlice({
  name: "ColumnConfig",
  initialState,
  reducers: {
    setColumnConfigs(state, action: PayloadAction<ColumnConfigs>) {
      state.columnConfigs = action.payload;
    },
    setSelectedField(state, action: PayloadAction<string>) {
      state.selectedField = action.payload;
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    updateColumnConfig(
      state,
      action: PayloadAction<{ i: number; cc: Partial<ColumnConfig> }>
    ) {
      const { i } = action.payload;
      if (state.selectedField) {
        state.columnConfigs[state.selectedField][i] = {
          ...state.columnConfigs[state.selectedField][i],
          ...action.payload.cc,
        };
      }
    },
    toggleModal(state, action: PayloadAction<boolean>) {
      state.isModalOpen = action.payload;
    }
  },
});

export const {
  setColumnConfigs,
  setSelectedField,
  setLoading,
  updateColumnConfig,
  toggleModal
} = slice.actions;

export const fetchColumnConfigs = () => async (dispatch, getState) => {
  const state: ReduxState = getState();
  if (state.ColumnConfig.loading) {
    return;
  }
  if (!state.JobData.job || !state.JobData.job.template) {
    return;
  }
  try {
    dispatch(setLoading(true));
    const columnConfigs = await getColumnConfigs(state.JobData.job.template.id);
    dispatch(setLoading(false));
    dispatch(setColumnConfigs(columnConfigs));
  } catch (err) {
    dispatch(setLoading(false));
    //
  }
};

export default slice.reducer;

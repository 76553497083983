import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { RedoJobButton } from "./RedoJobButton";
export const JobFailed = ({ job }) => {
  const history = useHistory();
  return (
    <div style={{ maxWidth: "50vw" }}>
      <Row className="text-center">
        <Col>
          <RedoJobButton job={job} />
          <div style={{ maxWidth: "20vw" }}>
            <div className="h2 m-4">
              <FontAwesomeIcon /*size="8x"*/ icon="times" color="red" />{" "}
              <span className="ml-2">Job Failed</span>
            </div>
            <div className="">
              We've added the credits back to your account and the support team
              is looking for a solution. Feel free to reach out to{" "}
              <a href="mailto:support@docuclipper.com">
                support@docuclipper.com
              </a>{" "}
              if you have any questions.
            </div>
            <div className="m-4">
              <Button
                variant="link"
                onClick={() => history.push("/extractdata")}
              >
                Go Back To Homepage
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import excelIcon from "../../../../../icons/excel.png";
import qbIcon from "../../../../../icons/qb-logo-horizontal-preferred.svg";
import { ReduxState } from "../../../../../redux";
import {
  setSelectedExportType,
} from "../../../../../redux/reducers/DownloadInvoiceOptions";
import { IconOption, IconValue } from "./utils";

const invoiceOptions = [
  {
    value: "csv",
    label: "Excel",
    icon: excelIcon,
  },
  {
    value: "qbo",
    label: "QuickBooks Online",
    icon: qbIcon,
  },
];

export const ExportTypeInvoiceDropdown: React.FC = () => {
  const dispatch = useDispatch();
  const { selectedExportType } = useSelector(
    (state: ReduxState) => state.DownloadInvoiceOptions
  );
  const selectedExportTypeOption = invoiceOptions.find(
    (option) => option.value === selectedExportType
  ) || invoiceOptions[0];

  const handleExportTypeSelectChange = (option) => {
    dispatch(setSelectedExportType(option.value as any));
  };

  return (
    <>
      <Select
        maxMenuHeight={600}
        className="select-export-type"
        options={invoiceOptions}
        value={selectedExportTypeOption}
        onChange={handleExportTypeSelectChange}
        components={{ Option: IconOption, SingleValue: IconValue }}
      />
    </>
  );
};

export default ExportTypeInvoiceDropdown;

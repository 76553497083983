import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getJobDocuments, getJobDocumentsByTagLevels } from "../../docuclipper/api";
import { JobDocument } from "../../docuclipper/DocuclipperTypes";
import { setSelectedDocumentId } from "./TransactionManager";

const initialState: {
  data: JobDocument[];
  loading: boolean;
  error: string | null;
} = {
  data: [],
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "JobDocument",
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
      state.error = null;
    },
    setData(state, action: PayloadAction<JobDocument[]>) {
      state.loading = false;
      state.data = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { load, setError, setData } = slice.actions;

export const fetchJobDocuments = () => async (dispatch, getState) => {
  const state = getState().JobData;

  const { loading } = getState().JobDocument;
  if (loading) {
    return;
  }

  if (!state.job) {
    return;
  }
  const jobId = state.job.id;

  dispatch(load());
  try {
    const data = await getJobDocuments(jobId);
    dispatch(setData(data));
    if (data.length > 0) {
      dispatch(setSelectedDocumentId(data[0].documentId))
    }
  } catch (err) {
    dispatch(setError(err.message || "Error getting job documents"));
  }
};

export const fetchDocumentsByTagLevels = (l1: string, l2: string) => async (dispatch, getState) => {
  const state = getState().JobData;

  const { loading } = getState().JobDocument;
  if (loading) {
    return;
  }

  dispatch(load());
  try {
    const data = await getJobDocumentsByTagLevels(l1, l2);
    dispatch(setData(data as any[]));
    if (data.length > 0) {
      dispatch(setSelectedDocumentId(data[0].documentId))
    }
  } catch (err) {
    dispatch(setError(err.message || "Error getting job documents"));
  }
};

export default slice.reducer;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isNull, valuesIn } from "lodash";
import React from "react";
import {
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  updateAccountNameByChunk,
  updateJob,
} from "../../../../docuclipper/api";
import { ReduxState } from "../../../../redux";
import {
  fetchMfs,
  fetchTls,
  setSelectedChunk,
  updateMetadataField,
} from "../../../../redux/reducers/TransactionManager";
import { isNullOrUndefined } from "../../../../utils/utils";
import EditableText from "../../../Docuclipper/EditableText";
import {
  ReconciledBadge,
  ReconciledNo,
  ReconciledYes,
} from "../ReconciledBadge";
import groupBy from "lodash/groupBy";
import { MetadataField } from "../../../../docuclipper/DocuclipperTypes";
import AccountSelector from "./AccountSelector";
import { createAlert } from "../../../../redux/reducers/Alerts";
import {
  setAccountNicknames,
  setAccounts,
} from "../../../../redux/reducers/TransactionManager";
import { fetchTotals } from "../../../../redux/reducers/Reconciler";
import ReassignChunkToAccount from "./ReassignChunkToAccount";
import PeriodSelector from "./PeriodSelector";
import uuidv4 from "uuid/v4";

type SummaryInfo = {
  numCredits: number;
  numPayments: number;
  totalCredits: string;
  totalPayments: string;
};
export const TransactionRow = ({
  summaryInfo,
  fieldName,
}: {
  summaryInfo: SummaryInfo;
  fieldName: string;
}) => {
  const total =
    Math.abs(parseFloat(summaryInfo.totalCredits)) -
    Math.abs(parseFloat(summaryInfo.totalPayments));
  let totalClassName = "";
  if (total > 0) {
    totalClassName = "text-success";
  } else if (total < 0) {
    totalClassName = "text-danger";
  }
  return (
    <>
      <td>
        {fieldName} ({summaryInfo.numCredits + summaryInfo.numPayments})
        {summaryInfo.numCredits > 0 && summaryInfo.numPayments > 0 && (
          <div className="">
            {summaryInfo.numCredits > 0 && (
              <tr>
                <span className="text-success">
                  <span>{`credits (${summaryInfo.numCredits})`}</span>

                  {` ${parseFloat(summaryInfo.totalCredits.toString()).toFixed(
                    2
                  )}`}
                </span>
              </tr>
            )}

            {summaryInfo.numPayments > 0 && (
              <tr>
                <span className="text-danger">
                  <span>{`debits (${summaryInfo.numPayments})`}</span>

                  {` ${parseFloat(summaryInfo.totalPayments.toString()).toFixed(
                    2
                  )}`}
                </span>
              </tr>
            )}
          </div>
        )}
      </td>
      <td>
        <div className="">
          <div className={totalClassName}>{total.toFixed(2)}</div>
        </div>
      </td>
    </>
  );
};

export const getIsReconciled = (
  fields: MetadataField[],
  accountName: string,
  chunk: string | null
) => {
  const x = (fields || []).filter(
    (mf) =>
      mf.name === "isReconciled" &&
      (!chunk || mf.chunk === chunk) &&
      (accountName.toString() === "null" || mf.accountName === accountName)
  );
  if (x.length > 0) {
    const hasOne = x.filter((y) => y.value === "1");
    if (hasOne.length === x.length) {
      return <ReconciledYes type={chunk ? "period" : "account"} />;
    } else {
      return <ReconciledNo type={chunk ? "period" : "account"} />;
    }
  } else {
    return null;
  }
};

export const getIsReconciledBoolean = (
  fields: MetadataField[],
  accountName: string,
  chunk: string | null
) => {
  const x = (fields || []).filter(
    (mf) =>
      mf.name === "isReconciled" &&
      (!chunk || mf.chunk === chunk) &&
      (accountName.toString() === "null" || mf.accountName === accountName)
  );
  if (x.length > 0) {
    const hasOne = x.filter((y) => y.value === "1");
    if (hasOne.length === x.length) {
      return true;
    } else {
      return false;
    }
  } else {
    return null;
  }
};

export const getIsBadDateBoolean = (
  fields: MetadataField[],
  accountName: string,
  chunk: string | null
) => {
  const x = (fields || []).filter(
    (mf) =>
      ["startDate", "endDate"].includes(mf.name) &&
      (!chunk || mf.chunk === chunk) &&
      (accountName.toString() === "null" || mf.accountName === accountName)
  );
  if (x.length > 0) {
    const badDates = x.filter((y) => y.value.includes("2001"));
    return badDates.length > 0;
  } else {
    return null;
  }
};

type Props = {};
export const SummaryTableAccountViewHorizontal: React.SFC<Props> = ({}) => {
  const dispatch = useDispatch();
  const {
    tls,
    mfs,
    selectedDocumentId,
    selectedFieldNames,
    selectedTransactionStatus,
    selectedChunk,
    badDates,
  } = useSelector((state: ReduxState) => state.TransactionManager);

  const { selectedAccount } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );

  const {
    tls: transactions,
    loading: tlsLoading,
    error: tlsError,
    documents,
  } = tls;
  const { mfs: fields, loading: mfsLoading, error: mfsError } = mfs;
  const { totalsByDocumentId, totalsByChunk } = useSelector(
    (state: ReduxState) => state.Reconciler
  );

  const totals = !isNullOrUndefined(selectedDocumentId)
    ? totalsByDocumentId
      ? totalsByDocumentId[selectedDocumentId as string]
      : {}
    : {};

  const chunkTotals =
    totalsByChunk &&
    selectedAccount &&
    !isNullOrUndefined(selectedAccount) &&
    !isNullOrUndefined(selectedChunk) &&
    selectedAccount in totalsByChunk &&
    selectedChunk &&
    selectedDocumentId &&
    selectedDocumentId in totalsByChunk[selectedAccount] &&
    selectedChunk in totalsByChunk[selectedAccount][selectedDocumentId]
      ? totalsByChunk[selectedAccount][selectedDocumentId][
          selectedChunk as string
        ]
      : {};

  const { job, progress } = useSelector((state: ReduxState) => state.JobData);

  // console.log({ totals, selectedAccount, selectedDocumentId });

  // const accounts = groupBy(
  //   [...fields]
  //     .sort((a, b) => (a as any).id - (b as any).id)
  //     .filter((x) => x.documentId === selectedDocumentId),
  //   "accountName"
  // );

  const mfHelper = (name, accountName, documentId, chunk) => {
    const x = (fields || []).filter(
      (mf) =>
        mf.name === name &&
        mf.documentId === documentId &&
        (!selectedChunk || mf.chunk === chunk) &&
        (accountName.toString() === "null" || mf.accountName === accountName)
    );
    const getLabel = (name) => {
      switch (name) {
        case "startDate":
          return "Start Date";
        case "endDate":
          return "End Date";
        case "startBalance":
          return "Start Balance";
        case "endBalance":
          return "End Balance";
        default:
          return name;
      }
    };
    let value = "";
    if (x.length > 0) {
      value = x[0].value;
    }

    let formattedValue = value;
    if (["startBalance", "endBalance"].includes(name)) {
      if (!isNaN(parseFloat(value))) {
        formattedValue = parseFloat(value).toFixed(2);
      }
    }
    const dateRegex = "^\\d{4}-\\d{2}-\\d{2}$";
    const dateErrorMsg =
      "Invalid date. Use YYYY-MM-DD format. Example: 2022-01-31";
    const amountRegex = "^-*\\d+(\\.\\d+)*$";
    const amountErrorMsg = `Invalid amount. Examples: 100.23 or -200.54 or 300`;
    return (
      <div className="mx-2">
        {/* <div className="font-weight-bold mt-2">{getLabel(name)}</div> */}
        <EditableText
          key={uuidv4()}
          initialValue={formattedValue}
          onConfirm={(newValue) => {
            if (["startDate", "endDate"].includes(name)) {
              if (
                new RegExp("^\\d{4}-\\d{2}-\\d{2}$").exec(newValue.trim()) ===
                null
              ) {
                dispatch(
                  createAlert({
                    message: `Invalid date. Use YYYY-MM-DD format. Example: 2022-01-31`,
                    timeout: 0,
                    type: "error",
                  })
                );
                return;
              }
            } else if (["startBalance", "endBalance"].includes(name)) {
              if (
                new RegExp("^-*\\d+(\\.\\d+)*$").exec(newValue.trim()) === null
              ) {
                dispatch(
                  createAlert({
                    message: `Invalid amount. Use a valid amount. Examples: 100.23 or -200.54 or 300`,
                    timeout: 0,
                    type: "error",
                  })
                );
                return;
              }
            }
            dispatch(
              updateMetadataField(
                selectedDocumentId as any,
                name,
                newValue,
                accountName,
                chunk
              )
            );
            // dispatch(fetchJobDocuments());
            dispatch(
              createAlert({
                message: `Field updated successfully`,
                timeout: 0,
                type: "success",
              })
            );
          }}
          regex={
            ["startBalance", "endBalance"].includes(name)
              ? amountRegex
              : dateRegex
          }
          errorMsg={
            ["startBalance", "endBalance"].includes(name)
              ? amountErrorMsg
              : dateErrorMsg
          }
        />

        {!formattedValue && (
          <small>
            <div className="text-danger">{`Missing ${getLabel(name)}`}</div>
          </small>
        )}
        {formattedValue &&
          ["startDate", "endDate"].includes(name) &&
          formattedValue.includes("2001") && (
            <small>
              <div className="text-danger">{`Bad year`}</div>
            </small>
          )}
      </div>
    );
  };

  let jobName = "";
  if (job && job.name) {
    jobName = job.name;
  } else if (job && job.template && job.template.name) {
    jobName = job.template.name;
  }

  let docName = "";
  const matches = documents.filter(
    (d) => d.id.toString() === selectedDocumentId?.toString()
  );
  if (matches.length > 0) {
    docName = matches[0].originalname;
  }

  const aggregateChunkTotals = (selectedAccount) => {
    // account => chunk => fieldName
    const totalsByAccount = {};
    for (const account in totalsByChunk) {
      if (!(account in totalsByAccount)) {
        totalsByAccount[account] = {};
      }
      for (const documentId in totalsByChunk[account]) {
        for (const chunk in totalsByChunk[account][documentId]) {
          for (const name in totalsByChunk[account][documentId][chunk]) {
            if (!(name in totalsByAccount[account])) {
              totalsByAccount[account][name] = {
                numCredits: 0,
                numPayments: 0,
                totalCredits: 0,
                totalPayments: 0,
              };
            }
            totalsByAccount[account][name] = {
              numCredits:
                totalsByAccount[account][name].numCredits +
                totalsByChunk[account][documentId][chunk][name].numCredits,
              numPayments:
                totalsByAccount[account][name].numPayments +
                totalsByChunk[account][documentId][chunk][name].numPayments,

              totalCredits:
                totalsByAccount[account][name].totalCredits +
                parseFloat(
                  totalsByChunk[account][documentId][chunk][name].totalCredits
                ),
              totalPayments:
                totalsByAccount[account][name].totalPayments +
                parseFloat(
                  totalsByChunk[account][documentId][chunk][name].totalPayments
                ),
            };
          }
        }
      }
    }
    return totalsByAccount;
  };

  const totalsByAccount = aggregateChunkTotals(selectedAccount);
  const [showEditAccount, setShowEditAccount] = React.useState(false);
  const [newAccountName, setNewAccountName] = React.useState(selectedAccount);
  const toggleShowEditAccount = () => setShowEditAccount(!showEditAccount);
  if (isNullOrUndefined(totals)) {
    return null;
  }
  // const numReconciledPeriods = (mfs.mfs || []).filter(x => x.name === 'isReconciled' && x.value === '1').length

  return (
    <>
      {/* <Row className="justify-content-end mb-2">
       
      </Row> */}
      <>
        {/* <AccountSelector /> */}

        <Row>
          <Col md="6" className="tx-manager-account-selector">
            <AccountSelector
              showAccountsHeading={true}
              renderReconciled={() => (
                <>
                  {selectedAccount &&
                    getIsReconciled(fields, selectedAccount, selectedChunk)}
                </>
              )}
            />
          </Col>
          <Col md="6" className="tx-manager-period-selector">
            {selectedAccount && (
              <>
                <PeriodSelector
                  renderReconciled={() => (
                    <>
                      {getIsReconciled(fields, selectedAccount, selectedChunk)}
                    </>
                  )}
                />
              </>
            )}
          </Col>
        </Row>
        <div className="table-responsive summary-table-dates-balances">
          <Row style={{ float: "left" }} className="mx-4">
            <div className="p-4">
              <Row className="align-items-center">
                <strong className="mt-2">From</strong>
                {selectedAccount &&
                  selectedChunk &&
                  mfHelper(
                    "startDate",
                    selectedAccount,
                    selectedDocumentId,
                    selectedChunk
                  )}

                <strong className="mt-2">Start Balance</strong>
                {selectedAccount &&
                  selectedChunk &&
                  mfHelper(
                    "startBalance",
                    selectedAccount,

                    selectedDocumentId,
                    selectedChunk
                  )}
              </Row>
            </div>
            <div className="mt-2 p-4">
              <div className="mt-2">
                {selectedAccount &&
                  selectedChunk &&
                  Object.keys(chunkTotals || []).map((fieldName) => (
                    <TransactionRow
                      fieldName={fieldName}
                      summaryInfo={chunkTotals[fieldName]}
                    />
                  ))}
              </div>
            </div>
            <div className="p-4">
              <Row className="align-items-center">
                <strong className="mt-2">To</strong>
                {selectedAccount &&
                  selectedChunk &&
                  mfHelper(
                    "endDate",
                    selectedAccount,

                    selectedDocumentId,
                    selectedChunk
                  )}
                <strong className="mt-2">End Balance</strong>
                {selectedAccount &&
                  selectedChunk &&
                  mfHelper(
                    "endBalance",
                    selectedAccount,
                    selectedDocumentId,
                    selectedChunk
                  )}
              </Row>
            </div>
            <div></div>
          </Row>
        </div>
      </>
    </>
  );
};
export default SummaryTableAccountViewHorizontal;

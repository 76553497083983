import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ExportType } from "../../docuclipper/DocuclipperTypes";
import { lsGetItem } from "src/LocalStorageWrapper";

export const lsKeySelectedExportType = `docuclipper.prefs.selectedExportType`
export const lsKeySelectedCustomConfigCSV = `docuclipper.prefs.selectedCustomConfigCSV`

const initialState: {
  selectedExportType: ExportType;
  selectedCustomConfigCSV: { value: string; label: string };
  options: { [k: string]: string };

} = {
  selectedExportType:
    JSON.parse(
      lsGetItem(lsKeySelectedExportType) ||
      '{"value": "xlsx"}'
    ).value || "xlsx2",
  selectedCustomConfigCSV:
    JSON.parse(
      lsGetItem(lsKeySelectedCustomConfigCSV) ||
      '{"value": "custom-basic", "label": "Basic (date, description, amount)"}'
    ) || { "value": "custom-basic", "label": "Basic (date, description, amount)" },
  options: {
    "docuclipper.prefs.dateFormat": "YYYY-MM-DD",
  }
};

const slice = createSlice({
  name: "DownloadOptions",
  initialState,
  reducers: {
    setSelectedExportType(
      state,
      action: PayloadAction<ExportType>
    ) {
      state.selectedExportType = action.payload;
    },
    setOption(state, action: PayloadAction<{ key: string; value: string }>) {
      const { payload } = action;
      const { key, value } = payload;
      state.options = { ...state.options, [key]: value };
    },
    setSelectedCustomConfigCSV(
      state,
      action: PayloadAction<{ value: string; label: string }>
    ) {
      state.selectedCustomConfigCSV = action.payload;
      localStorage.setItem(lsKeySelectedCustomConfigCSV, JSON.stringify(action.payload))
    },

  },
});

export const { setSelectedExportType, setOption, setSelectedCustomConfigCSV } = slice.actions;

export default slice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  categorizeJobTransactions,
  getAllTags,
  getTransactionCategories,
} from "../../docuclipper/api";
import { ReduxState } from "..";

import { setTls } from "./TransactionManager";

export function randomColor(i) {
  const colors = [
    "#FF5733", // Vibrant Red
    "#33A1FF", // Bright Blue
    "#FF33A1", // Hot Pink
    "#FF8C00", // Dark Orange
    "#8A2BE2", // Blue Violet
    "#FF4500", // Orange Red
    "#2E8B57", // Sea Green
    "#00CED1", // Dark Turquoise
    "#800080", // Purple
    "#B22222"  // Firebrick
  ];
  return colors[i % colors.length];
}
const initialState: {
  loading: boolean;
  categories: { name; value; color }[];
  byCategoryKeyword: { name; value; color }[];
} = {
  loading: false,
  categories: [], byCategoryKeyword: []
};


const slice = createSlice({
  name: "Categories",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setCategories(state, action: PayloadAction<{ name; value; color }[]>) {
      state.categories = action.payload;
    },
    setByCategoryKeyword(state, action: PayloadAction<{ name; value; color }[]>) {
      state.byCategoryKeyword = action.payload;
    },
  },
});

export const { setCategories, setLoading, setByCategoryKeyword } = slice.actions;

export const getCategoriesAction = () => async (dispatch, getState) => {
  const state: ReduxState = getState();
  if (state.Categories.loading) {
    return;
  }
  const { job } = state.JobData;
  if (!job) {
    return;
  }
  const jobId = job.id;
  const { selectedTagRegexesSet } = state.TagRegexes;

  if (!selectedTagRegexesSet) {
    return;
  }


  try {
    dispatch(setLoading(true));
    const { categories } = await getTransactionCategories(jobId);
    dispatch(setLoading(false));
    dispatch(
      setCategories(
        categories
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((c, i) => ({
            ...c,
            color: randomColor(i),
          }))
      )
    );
  } catch (err) {
    dispatch(setLoading(false));
  }
};

export const categorizeAction = () => async (dispatch, getState) => {
  const state: ReduxState = getState();
  if (state.Categories.loading) {
    return;
  }
  const { job } = state.JobData;
  if (!job) {
    return;
  }
  const jobId = job.id;
  const { selectedTagRegexesSet } = state.TagRegexes;

  if (!selectedTagRegexesSet) {
    return;
  }
  try {
    dispatch(setLoading(true));
    const { transactions, categories } = await categorizeJobTransactions(
      jobId,
      selectedTagRegexesSet.name
    );
    dispatch(setLoading(false));
    dispatch(setTls({ transactions, resetPages: false }));
    dispatch(
      setCategories(
        categories
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((c, i) => ({
            ...c,
            color: randomColor(i)
          }))
      )
    );
  } catch (err) {
    dispatch(setLoading(false));
  }
};

export default slice.reducer;

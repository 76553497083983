import React from "react";

import { isMobile } from "react-device-detect";
import * as Popper from "popper.js";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type Props = {
  header: string;
  id: string;
  placement: Popper.Placement;
};

export const PopoverHelp: React.SFC<Props> = ({
  header,
  id,
  children,
  placement,
}) => {
  return isMobile ? null : (
    <>
      <span className="ml-2 input-group-btn ">
        <OverlayTrigger
          popperConfig={{
            modifiers: {
              preventOverflow: {
                enabled: false,
              },
            } as any,
          }}
          placement={placement}
          overlay={
            <Popover id={id}>
              <Popover.Title>{header}</Popover.Title>
              <Popover.Content>{children}</Popover.Content>
            </Popover>
          }
        >
          <FontAwesomeIcon icon="question-circle" />
        </OverlayTrigger>
      </span>
    </>
  );
};

export default PopoverHelp;

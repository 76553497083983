import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "..";
import { getQboItems } from "../../docuclipper/api";
import { QboItem } from "../../docuclipper/DocuclipperTypes";

const initialState: {
  qboItems: QboItem[];
  loading: boolean;
  error: string | null;
} = {
  qboItems: [],
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "QboItem",
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
      state.error = null;
    },
    setData(state, action: PayloadAction<QboItem[]>) {
      state.loading = false;
      state.qboItems = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    addQboItem(state, action: PayloadAction<QboItem>) {
      state.qboItems.unshift(action.payload);
    },
  },
});

export const { load, setError, setData, addQboItem } = slice.actions;

export const fetchQboItems =
  (integrationId) => async (dispatch, getState) => {
    const state: ReduxState = getState();
    if (state.QboItem.loading) {
      return;
    }
    dispatch(load());
    try {
      const job = state.JobData.job;
      if (job) {
        const QboItems = await getQboItems(integrationId);
        dispatch(
          setData(
            QboItems.map((c) => ({
              name: c.Name,
              value: c.Id,
              type: c.Type
            }))
          )
        );
      }
    } catch (err) {
      dispatch(setError((err as any).message || "Error getting QboItems"));
    }
  };

export default slice.reducer;

import React from "react";
import { Form, Button, Row, Col, Card, Overlay, Modal } from "react-bootstrap";
import { Formik, Field, ErrorMessage } from "formik";
import { formikInput } from "../../../../../utils/utils";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const validationSchema = yup.object().shape({
  account: yup.string().required("Account field is required."),
  startDate: yup
    .string()
    .trim()
    .matches(/\d{4}\-\d{2}\-\d{2}/, "Invalid date, use YYYY-MM-DD format")
    .required(),
  endDate: yup
    .string()
    .trim()
    .matches(/\d{4}\-\d{2}\-\d{2}/, "Invalid date, use YYYY-MM-DD format")
    .required(),
  startBalance: yup.number().required(),
  endBalance: yup.number().required(),
});
export const AccountForm2 = ({ onSubmit }) => {
  const initialValues = {
    account: "",
    startDate: "",
    endDate: "",
    startBalance: "",
    endBalance: "",
  };

  const handleSubmit = (values) => {
    // Handle form submission logic here

    onSubmit(values);
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ handleSubmit }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Row>
            <Form.Group className="mx-1" controlId="account">
              <strong>
                <Form.Label>Account Name</Form.Label>
              </strong>
              <Field
                type="text"
                name="account"
                placeholder="Account Name"
                component={formikInput}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mx-1" controlId="startDate">
              <strong>
                <Form.Label>Start Date</Form.Label>
              </strong>
              <Field
                type="text"
                name="startDate"
                placeholder="Start Date"
                component={formikInput}
              />
            </Form.Group>
            <Form.Group className="mx-1" controlId="endDate">
              <strong>
                <Form.Label>End Date</Form.Label>
              </strong>
              <Field
                type="text"
                name="endDate"
                placeholder="End Date"
                component={formikInput}
              />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group className="mx-1" controlId="startBalance">
              <strong>
                <Form.Label>Start Balance</Form.Label>
              </strong>
              <Field
                type="text"
                name="startBalance"
                placeholder="Start Balance"
                component={formikInput}
              />
            </Form.Group>
            <Form.Group className="mx-1" controlId="endBalance">
              <strong>
                <Form.Label>End Balance</Form.Label>
              </strong>
              <Field
                type="text"
                name="endBalance"
                placeholder="End Balance"
                component={formikInput}
              />
            </Form.Group>
          </Row>
          <Row className="justify-content-end">
            <div>
              <Button className="mx-1" variant="primary" type="submit">
                <FontAwesomeIcon icon="plus" />
                <span className="ml-1">Add New Account</span>
              </Button>
            </div>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

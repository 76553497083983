import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "..";
import { getQboAccounts, createQboAccount } from "../../docuclipper/api";
import { QboAccount } from "../../docuclipper/DocuclipperTypes";

const initialState: {
  qboAccounts: QboAccount[];
  loading: boolean;
  error: string | null;
} = {
  qboAccounts: [],
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "QboAccount",
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
      state.error = null;
    },
    setData(state, action: PayloadAction<QboAccount[]>) {
      state.loading = false;
      state.qboAccounts = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
    addQboAccount(state, action: PayloadAction<QboAccount>) {
      state.qboAccounts.unshift(action.payload);
    },
  },
});

export const { load, setError, setData, addQboAccount } = slice.actions;

export const fetchQboAccounts = (integrationId) => async (dispatch, getState) => {
  const state: ReduxState = getState();
  if (state.QboAccount.loading) {
    return;
  }
  dispatch(load());
  try {
    const job = state.JobData.job;
    if (job) {
      const QboAccounts = await getQboAccounts(integrationId, true);
      dispatch(
        setData(
          QboAccounts.map((c) => ({
            name: c.Name,
            value: c.Id,
          }))
        )
      );
    }
  } catch (err) {
    dispatch(setError((err as any).message || "Error getting QboAccounts"));
  }
};

export const createAndAddQboAccount = (integrationId, accountName) => async (dispatch, getState) => {
  try {
    const account = await createQboAccount(integrationId, accountName);
    dispatch(addQboAccount({
      name: account.Name,
      value: account.Id,
    }));
  } catch (err) {
    dispatch(setError((err as any).message || "Error creating QboAccount"));
  }
};

export default slice.reducer;

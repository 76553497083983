import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { User } from "../../../../docuclipper/DocuclipperTypes";
import { closeModal } from "../../../../redux/reducers/ModalManager";

type Props = {};
export const ReferFriendPromotion2: React.SFC<Props & RouteComponentProps> = ({
  history,
}) => {
  const dispatch = useDispatch();
  return (
    <div className="text-center">
      <h3>Make a referral and get one month free</h3>
      <div className="my-4">
        Do you know someone that could benefit from DocuClipper? Refer them and
        you'll get a free month when they subscribe to a paid plan.
      </div>
      <div>
        <Button
          onClick={() => {
            dispatch(closeModal("refer"));
            history.push("/account/refer");
          }}
        >
          Learn More
        </Button>
      </div>
    </div>
  );
};

export const ReferFriendPromotion = withRouter(ReferFriendPromotion2);

type Props2 = {
  open: boolean;
  onHide: () => any;
  user: User;
};
export const ReferFriendPromotionModal: React.FC<Props2> = ({
  open,
  user,
  onHide,
}) => {
  return (
    <>
      <Modal show={open} onHide={onHide}>
        <Modal.Header closeButton={true} />

        <Modal.Body>
          <ReferFriendPromotion />
        </Modal.Body>
      </Modal>
    </>
  );
};

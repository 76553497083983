import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { updateSegment } from "src/docuclipper/api";

const initialState: {
  open: boolean;
  survey: string;
} = {
  open: false,
  survey: "",
};

const slice = createSlice({
  name: "Survey",
  initialState,
  reducers: {
    openSurvey(state, action: PayloadAction<string>) {
      state.survey = action.payload;
      state.open = true;
    },
    closeSurvey(state) {
      state.open = false;
      state.survey = "";
    },
  },
});

export const { openSurvey, closeSurvey } = slice.actions;

export const updateSegmentAction = ({ segment }) =>
  async (dispatch, getState) => {
    try {
      const segmentUpdated = await updateSegment({ segment });
      // console.log({ segmentUpdated });
    } catch (err) {
      console.error(err);
    }
  };

export default slice.reducer;

export type ModalType =
  | "ratings"
  | "upgrade"
  | "refer"
  | "outOfPages"
  | "buyPages"
  | "awsOnboarding"
  | "password"
  | "noData";

export type ModalData = {
  name: ModalType;
  data: {};
};

const initialState: {
  activeModal: ModalType | null;
  modals: ModalData[];
} = {
  activeModal: null,
  modals: [],
};

export const OPEN_MODAL = "open-modal";
export const CLOSE_MODAL = "close-modal";

export default function reducer(state = initialState, action) {
  if (!action.payload) {
    return state;
  }
  const { modalType, data } = action.payload;
  const newModals = state.modals.filter((m) => m.name !== modalType);
  switch (action.type) {
    case OPEN_MODAL:
      return {
        ...state,

        activeModal: modalType,
        modals: [...newModals, { name: modalType, data }],
      };
    case CLOSE_MODAL:
      return {
        ...state,
        activeModal:
          newModals.length > 0 ? newModals[newModals.length - 1].name : null,
        modals: newModals,
      };

    default:
      return state;
  }
}

export type OpenModalFn = (modalType: ModalType, data: {}) => any;
export const openModal = (modalType: ModalType, data: {}) => {
  return {
    type: OPEN_MODAL,
    payload: { modalType, data },
  };
};

export type CloseModalFn = (modalType: ModalType) => any;
export const closeModal = (modalType: ModalType) => ({
  type: CLOSE_MODAL,
  payload: { modalType },
});

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useJobInfo } from "../../../custom-hooks/useJobInfo";
import { usePayingInfo } from "../../../custom-hooks/usePayingInfo";
import { ReduxState } from "../../../redux";
import { FaqItem } from "../../Docuclipper/Ratings/FaqItem";

// function FaqItem({ heading, body }) {
//   return (
//     <div className="m-2">
//       <div className="font-weight-bold">{heading && heading()}</div>
//       <div className="mx-2">{body && body()}</div>
//     </div>
//   );
// }
export const GenericJobHelp = ({ job }) => {
  const history = useHistory();
  const { redoneAsGeneric, missingBank } = useJobInfo();
  const { hasActiveSubscription, hasBoughtPages } = usePayingInfo();
  const reasons: string[] = [];
  if (missingBank) {
    reasons.push("The bank you selected is not supported yet");
  } else {
    reasons.push(`The statement doesn't have any transactions`);
    reasons.push(`The statement isn't supported by DocuClipper`);
    // reasons.push(
    //   `The wrong bank was selected. Double check the bank logo (top right of the table). Some banks have similar names, make sure you select the right one.`
    // );
    reasons.push(
      `The statement is low quality. When the statement is too blurry, sometimes DocuClipper can't extract the data properly.`
    );
    // reasons.push(
    //   `The statement is missing the headers (date, description, amount, etc.). DocuClipper needs the headers (at least on the first page) for data extraction to work.`
    // );
  }

  return (
    <div /*className="rounded" style={{ maxWidth: "50vw" }}*/>
      <Row className="">
        <Col>
          <div>
            <div className="h3 mb-4">
              <FontAwesomeIcon icon="question-circle" />
              <span className="m-2">
                The data has been extracted using "automatic mode"
              </span>
            </div>
            <FaqItem
              initialExpanded={true}
              heading={() => "What is automatic mode?"}
              body={() => (
                <div>
                  <div className="my-2">
                    When DocuClipper can't extract the individual transactions,
                    it falls back to "automatic mode" and converts every single
                    word in the document(s) to Excel.
                  </div>
                  <div className="my-2">
                    When using "automatic mode" DocuClipper can only export the
                    data to Excel. QuickBooks and other third party apps aren't
                    supported.
                  </div>
                </div>
              )}
            />
            <FaqItem
              initialExpanded={true}
              heading={() => 'Why did DocuClipper use "Automatic mode"?'}
              body={() => (
                <div>
                  <div className="my-2">Some of the reasons include:</div>
                  {reasons.map((r) => (
                    <div className="ml-2">{r}</div>
                  ))}
                </div>
              )}
            />
            <FaqItem
              initialExpanded={true}
              heading={() =>
                "Automatic mode doesn't work for me, how can I get the actual transactions?"
              }
              body={() => (
                <div>
                  {(hasBoughtPages || hasActiveSubscription) && (
                    <div className="">
                      Email a link to this job or copies of the statements to{" "}
                      <a href="mailto:support@docuclipper.com">
                        support@docuclipper.com
                      </a>{" "}
                      and the support team will help you get the data you need
                    </div>
                  )}
                  {!hasBoughtPages && !hasActiveSubscription && (
                    <div>
                      <div>
                        The support team can add support for any bank statement,
                        usually in the same day. At the moment, due to high
                        demand, we're only offering this service to paying
                        customers.
                      </div>
                      <Button onClick={() => history.push("/account/upgrade")}>
                        Upgrade
                      </Button>
                    </div>
                  )}
                </div>
              )}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

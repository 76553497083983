import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { Component } from "react";
import DocumentTitle from "react-document-title";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
// import { renderRoutes } from 'react-router-config';
import "./App.scss";
import ExternalRedirect from "./containers.old/DefaultLayout/ExternalRedirect";
import { DocuclipperToken } from "./docuclipper/DocuclipperTypes";
import { ErrorBoundary } from "./docuclipper/ErrorBoundary";
import PrivateRoute from "./docuclipper/PrivateRoute";
import QuickBooksDisconnected from "./docuclipper/QuickBooksDisconnected";
import ReceiveJwt from "./docuclipper/ReceiveJwt";
import RouteChangeHandler from "./docuclipper/RouteChangeHandler";
import withTracker from "./docuclipper/withTracker";
import routes from "./routes";
import { CreditsModal } from "./views/Docuclipper/CreditsModal";
import { SubscriptionModal } from "./views/Docuclipper/CreditsModal/SubscriptionModal";
import Promotions from "./views/Pages/Account/Promotions";
import UpgradeModal from "./views/Pages/Account/UpgradeModal";
import JobWSUpdater from "./views/Pages/DataArchive/JobWSUpdater";
import { AWSOnboardingModal } from "./views/Pages/OnboardAWS/AWSOnboardingModal";
import QueryParamBanner from "./views/Pages/QueryParamBanner";
import { TrialLimitModal } from "./views/Pages/TrialLimitModal";
import ProductTour from "./views/Docuclipper/ProductTour/ProductTour";
import OnboardingChecker from "./views/Docuclipper/GettingStartedSteps/OnboardingChecker";
import FeedbackButton from "./views/Docuclipper/FeedbackButton";
import MobileBanner from "./MobileBanner";
import TwoFactorAuth from "./views/Pages/Signup/TwoFactor";

const stripePromise = loadStripe(
  process.env.NODE_ENV === "production"
    ? "pk_live_vISijIuYcPBVdPIzoBjf5cUy00kEHEk2P1"
    : "pk_test_0RzF7XHTNdggpveaP0puymok00hYV71JbY"
);

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// Containers
const DefaultLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login/LoginBoxed"));
const LoginSso = React.lazy(() => import("./views/Pages/Login/LoginSso"));

const Signup = React.lazy(() => import("./views/Pages/Signup/SignupB2C"));
const Guest = React.lazy(() => import("./views/Pages/Guest/index"));

const RequestReset = React.lazy(
  () => import("./views/Pages/Signup/RequestReset")
);
const EnterpriseSignup = React.lazy(
  () => import("./views/Pages/Signup/EnterpriseSignup")
);
const ResetPassword = React.lazy(
  () => import("./views/Pages/Signup/ResetPassword")
);

// const Page404 = React.lazy(() => import("./views/Pages/Page404"));
// const Page500 = React.lazy(() => import("./views/Pages/Page500"));

type Props = {};
type ReduxProps = {
  token: DocuclipperToken;
};
type State = {};

const unprotectedRoutes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/loginsso",
    name: "Login",
    component: LoginSso,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/guest",
    name: "Guest",
    component: Guest,
  },
  {
    path: "/2fa",
    name: "Two Factor Authentication",
    component: TwoFactorAuth,
  },
  {
    path: "/requestReset",
    name: "Request Reset",
    component: RequestReset,
  },
  {
    path: "/enterpriseSignup",
    name: "EnterpriseSignup",
    component: EnterpriseSignup,
  },
  {
    path: "/receiveJwt",
    name: "Receive JWT",
    component: ReceiveJwt,
  },
  {
    path: "/quickbooksdisconnected",
    name: "QuickBooks Disconnected",
    component: QuickBooksDisconnected,
  },
  {
    path: "/resetPassword",
    name: "Reset Password",
    component: ResetPassword,
  },
  // {
  //   path: "/404",
  //   name: "404",
  //   component: Page404,
  // },
  // {
  //   path: "/500",
  //   name: "500",
  //   component: Page500,
  // },
];

// const Fail = () => {
//   throw new Error("Fail!");
// };

const FloatElementsManager = React.lazy(
  () => import("./views/Docuclipper/FloatElementsManager")
);

const Routes: React.SFC<Props & ReduxProps> = ({ token }) => {
  return (
    <>
      <RouteChangeHandler />

      <Switch>
        {unprotectedRoutes.map((route, i) => (
          <Route
            key={i}
            exact
            path={route.path}
            render={(props) => (
              <ErrorBoundary>
                <DocumentTitle title={`${route.name}`}>
                  <route.component {...props} />
                </DocumentTitle>
              </ErrorBoundary>
            )}
          />
        ))}
        {routes.map((route, idx) => {
          return route.component ? (
            <PrivateRoute
              isProtected={route.isProtected}
              key={idx}
              path={route.path}
              permission={route.permission}
              exact={route.exact}
              component={(props2) => (
                <>
                  <ErrorBoundary>
                    <DocumentTitle title={`${route.name}`}>
                      <DefaultLayout {...props2} maxWidth={route.maxWidth}>
                        {/* <AlertBanner /> */}

                        <MobileBanner />

                        <OnboardingChecker />

                        <FeedbackButton />
                        <React.Suspense fallback={null}>
                          <FloatElementsManager />
                        </React.Suspense>

                        <Elements stripe={stripePromise}>
                          <CreditsModal />
                        </Elements>
                        <SubscriptionModal />

                        <TrialLimitModal />
                        <AWSOnboardingModal />
                        <QueryParamBanner />
                        <Elements stripe={stripePromise}>
                          <UpgradeModal />
                        </Elements>
                        <Promotions />
                        <ProductTour />
                        <route.component {...props2} />
                      </DefaultLayout>
                    </DocumentTitle>
                  </ErrorBoundary>
                </>
              )}
            />
          ) : null;
        })}

        <Route exact={true} path="/empty" render={() => null} />

        <Route
          exact={true}
          path="/"
          render={() =>
            token && token.user ? (
              <Redirect to="/extractdata" />
            ) : (
              <ExternalRedirect path="/docuclipper/" />
            )
          }
        />

        <Route render={() => <ExternalRedirect path="/404/" />} />
      </Switch>
    </>
  );
};

const mapStateToProps = (state) => ({
  token: state.Auth.token,
});

const mapDispatchToProps = {};

const TrackedRoutes = withRouter(
  withTracker(connect(mapStateToProps, mapDispatchToProps)(Routes))
);

class App extends Component<Props, State> {
  render() {
    return (
      <>
        <JobWSUpdater />
        <Router basename="/app">
          <React.Suspense fallback={loading()}>
            <TrackedRoutes />
          </React.Suspense>
        </Router>
      </>
    );
  }
}

export default App;

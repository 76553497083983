import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Field, Formik } from "formik";
import React from "react";
import {
  Button,
  Card,
  Form,
  FormGroup,
  Modal,
  Overlay,
  Popover,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { isNullOrUndefined } from "util";
import * as Yup from "yup";
import { createTransactions } from "../../../../docuclipper/api";
import { ReduxState } from "../../../../redux";
import { createAlert } from "../../../../redux/reducers/Alerts";
import { fetchTotals } from "../../../../redux/reducers/Reconciler";
import {
  addAddedManually,
  fetchMfs,
  setTls,
  toggleLoading,
} from "../../../../redux/reducers/TransactionManager";
import { formikInput, formikSelectInput } from "../../../../utils/utils";
import AccountSelector from "./AccountSelector";
import { uniq } from "lodash";
import AlertBanner from "../../../Docuclipper/AlertBanner/AlertBanner";
import { Transaction2 } from "./AddTransaction2";

function Transaction() {
  const dispatch = useDispatch();

  const {
    selectedDocumentId,
    tls,
    selectedPage,
    mfs,
    selectedChunk,
    selectedAccount,
    selectedAccountNickname,
  } = useSelector((state: ReduxState) => state.TransactionManager);

  const { job } = useSelector((state: ReduxState) => state.JobData);

  const addTransactionHelper = (
    { date, description, amount, fieldName },
    { resetForm }
  ) => {
    if (job && !isNullOrUndefined(selectedDocumentId)) {
      dispatch(toggleLoading());
      createTransactions(job.id as any, selectedDocumentId, [
        {
          date,
          description,
          amount,
          fieldName,
          pageNumber: selectedPage,
          account: selectedAccount,
          accountNickname: selectedAccountNickname,
          chunk: selectedChunk,
        },
      ])
        .then(({ transactions }) => {
          dispatch(toggleLoading());
          dispatch(setTls({ transactions, resetPages: false }));
          dispatch(
            createAlert({
              message: "Transaction created successfully",
              timeout: 0,
              type: "success",
            })
          );
          dispatch(fetchTotals());
          dispatch(addAddedManually());
          dispatch(fetchMfs());
        })
        .catch((err) => {
          dispatch(toggleLoading());
          dispatch(
            createAlert({
              message: "Error creating transaction",
              timeout: 0,
              type: "error",
            })
          );
        });
    }

    resetForm();
  };

  // TODO add formik select dropdown (field, field2, new field)
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        date: "",
        description: "",
        amount: "",
        fieldName: tls.fieldNames.length > 0 ? tls.fieldNames[0] : "",
      }}
      validationSchema={Yup.object().shape({
        date: Yup.string()
          .trim()
          .matches(
            /^([0-9]{4})-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/,
            "Date should be in YYYY-MM-DD format with valid month (01-12) and day (01-31)"
          )
          .required(),
        description: Yup.string().required(),
        amount: Yup.number()
          .typeError("Enter a positive or negative number")
          .required(),
        fieldName: Yup.string().required(),
      })}
      onSubmit={addTransactionHelper}
    >
      {({ submitForm }) => (
        <>
          {tls.fieldNames.length > 1 && (
            <FormGroup className="m-2">
              <strong>
                <Form.Label>Field Name</Form.Label>
              </strong>
              <Field
                name="fieldName"
                as="select"
                className=""
                component={formikSelectInput}
              >
                {tls.fieldNames.map((x) => (
                  <option value={x}>{x}</option>
                ))}
              </Field>
            </FormGroup>
          )}
          <FormGroup className="">
            <FormGroup className="m-2">
              <strong>
                <Form.Label>Date</Form.Label>
              </strong>
              <Field name="date" component={formikInput} placeholder="" />
            </FormGroup>
            <FormGroup className="m-2">
              <strong>
                <Form.Label>Description</Form.Label>
              </strong>
              <Field
                name="description"
                component={formikInput}
                placeholder=""
              />
            </FormGroup>
            <FormGroup className="m-2">
              <strong>
                <Form.Label>Amount</Form.Label>
              </strong>
              <Field name="amount" component={formikInput} placeholder="" />
            </FormGroup>
            <Row className="justify-content-end">
              <div>
                <Button
                  className="m-2"
                  onClick={() => {
                    submitForm();
                  }}
                >
                  <FontAwesomeIcon icon="plus" />
                  <span className="ml-1">Add missing transaction</span>
                </Button>
              </div>
            </Row>
          </FormGroup>
        </>
      )}
    </Formik>
  );
}

export const AddTransactionPopover = ({ buttonVariant }) => {
  const [show, setShow] = React.useState(false);
  const toggle = () => setShow(!show);
  const target = React.useRef(null);
  return (
    <>
      <Button
        className="add-transaction-btn"
        variant={buttonVariant}
        ref={target}
        onClick={toggle}
      >
        <FontAwesomeIcon icon="plus" />
        <span className="ml-1">Add Transaction</span>
      </Button>
      <Overlay target={target.current} show={show} placement="right">
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div {...props}>
            <Card style={{ width: "400px" }}>
              <Card.Header>
                <Row className="justify-content-between">
                  <div className="h5 m-2">Add a transaction</div>
                  <Button variant="link" onClick={() => toggle()}>
                    <FontAwesomeIcon icon="times" />
                  </Button>
                </Row>
              </Card.Header>
              <Card.Body>
                <Transaction />
              </Card.Body>
            </Card>
          </div>
        )}
      </Overlay>
    </>
  );
};

AddTransactionPopover.defaultProps = {
  buttonVariant: String,
};

export const AddTransactionModal = ({
  buttonVariant,
  extended,
  onTransactionAdded,
}) => {
  const [show, setShow] = React.useState(false);
  const toggle = () => setShow(!show);
  const target = React.useRef(null);
  return (
    <>
      <Button
        className="add-transaction-btn"
        variant={buttonVariant}
        ref={target}
        onClick={toggle}
      >
        <FontAwesomeIcon icon="plus" />
        <span className="ml-1">Add Transaction</span>
      </Button>
      <Modal show={show} onHide={toggle}>
        <Modal.Header closeButton={true}>
          <strong>Add Transaction</strong>
        </Modal.Header>

        <Modal.Body>
          <AlertBanner />
          {!extended && <Transaction />}
          {extended && <Transaction2 onTransactionAdded={onTransactionAdded} />}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={toggle}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { uniq } from "lodash";
import { ReduxState } from "..";
import {
  getJobMetadataFields,
  getJobTransactions,
  getJobTransactions2,
  runInvoiceRules,
  updateJobMetadataField,
  updateJobTransaction,
  updatePayeeCleanupConfig,
} from "../../docuclipper/api";
import {
  DocType,
  Document,
  Invoice2,
  MetadataField,
  TransactionLine,
  TableQueryParams,
} from "../../docuclipper/DocuclipperTypes";
import { isNullOrUndefined } from "../../utils/utils";
import { fetchTotals } from "./Reconciler";
import * as _ from "lodash";
import { createAlert } from "./Alerts";
import { convertTlsToTable } from "src/views/Pages/Analyze/AnalyzeTransactionsTable/utils";

function updateChunks(state) {
  // const newChunks = uniq(
  //   (state.tls.tls || [])
  //     .filter((x) => x.account === state.selectedAccount && x.documentId === state.selectedDocumentId)
  //     .map((x) => x.chunk || "")
  // );
  // state.accountChunks = newChunks;

  // //
  // const allChunks = Object.keys(_.groupBy(state.tls.tls || [], "chunk"));
  for (const chunk of (state.accountChunks || [])) {
    state.accountChunkLabels[chunk] = getChunkDateRange(
      state.mfs,
      state.selectedDocumentId,
      chunk
    );
  }
  for (const tl of (state.tls.tls || [])) {
    tl.chunkLabel = state.accountChunkLabels[tl.chunk];
  }
}
const getChunkDateRange = (mfs, documentId, chunk: string) => {
  const s = (mfs.mfs || []).filter(
    (mf) =>
      mf.chunk === chunk &&
      mf.name === "startDate" &&
      mf.documentId === documentId
  );
  const e = (mfs.mfs || []).filter(
    (mf) =>
      mf.chunk === chunk &&
      mf.name === "endDate" &&
      mf.documentId === documentId
  );
  if (s.length > 0 && e.length > 0) {
    return `${s[0].value} to ${e[0].value} `;
  }

  return "";
};

const initialState: {
  reconciled: { accounts: {}; documentIds: {} };
  badDates: { accounts: {}; documentIds: {} };
  loading: boolean;
  tls: {
    tls: TransactionLine[];
    documents: Document[];
    fieldNames: string[];
    loading: boolean;
    error: string | null;
    categories: string[];
  };
  mfs: {
    mfs: MetadataField[];
    loading: boolean;
    error: string | null;
  };
  selectedDocumentId: string | null;
  selectedChunk: string | null;
  selectedRowNumber: number | null;
  selectedTransactionStatus: string[] | undefined;
  selectedFieldNames: string[] | undefined;
  downloadOptions: {
    selectedDocumentIds: string[];
    selectedFieldNames: string[];
  };
  pages: number[];
  pageIndex: number;
  selectedPage: number | null;
  selectedRowIds: {};
  firstPage: number | null;
  lastPage: number | null;
  accounts: string[];
  selectedAccount: string | null;
  selectedAccountNickname: string | null;
  accountNicknames: { [k: string]: string };
  accountChunks: string[];
  accountChunkLabels: {};
  isBulkEdit: boolean;
  selectedTl: TransactionLine | null;
  invoice: Invoice2 | null;
  lastCategorizationTime: number;
  showModalInvoiceRules: boolean;
  payeeCleanupConfig: any;
  pagination: {
    page: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
  };
  refreshKey: number;
} = {
  reconciled: { accounts: {}, documentIds: {} },
  badDates: { accounts: {}, documentIds: {} },
  loading: false,
  tls: {
    tls: [],
    documents: [],
    fieldNames: [],
    loading: false,
    error: null,
    categories: [],
  },
  mfs: { mfs: [], loading: false, error: null },
  selectedDocumentId: null,
  selectedChunk: null,
  selectedRowNumber: 0,
  selectedFieldNames: undefined,
  selectedTransactionStatus: undefined,
  downloadOptions: {
    selectedDocumentIds: [],
    selectedFieldNames: [],
  },
  pages: [],
  pageIndex: 0,
  selectedPage: null,
  firstPage: null,
  lastPage: null,
  selectedRowIds: {},
  accounts: [],
  selectedAccount: null,
  selectedAccountNickname: null,
  accountNicknames: {},
  accountChunks: [],
  accountChunkLabels: {},
  isBulkEdit: false,
  selectedTl: null,
  invoice: null,
  lastCategorizationTime: 0,
  showModalInvoiceRules: false,
  payeeCleanupConfig: null,
  pagination: {
    page: 1,
    pageSize: 20,
    totalCount: 0,
    totalPages: 0
  },
  refreshKey: 0,
};

function setPagesHelper(state) {
  state.pages = uniq(
    (state.tls.tls || [])
      .filter((x) => x.documentId === state.selectedDocumentId)
      .map((x) => x.pageNumber)
  ).sort((a: any, b: any) => a - b);
  state.firstPage = state.pages.length > 0 ? state.pages[0] : null;
  state.selectedPage = state.firstPage;
  state.lastPage =
    state.pages.length > 0 ? state.pages[state.pages.length - 1] : null;
  state.pageIndex = 0;
}

const slice = createSlice({
  name: "TransactionManager",
  initialState,
  reducers: {
    loadTls(state) {
      state.tls.loading = true;
      state.tls.error = null;
    },
    toggleBulkEdit(state) {
      state.isBulkEdit = !state.isBulkEdit;
    },
    disableBulkEdit(state) {
      state.isBulkEdit = false;
    },
    toggleLoading(state) {
      state.loading = !state.loading;
    },
    setTls(
      state,
      action: PayloadAction<{
        transactions: TransactionLine[];
        resetPages: boolean;
      }>
    ) {
      state.tls.loading = false;
      state.tls.tls = action.payload.transactions;
      if (action.payload.resetPages) {
        setPagesHelper(state);
      }

      // updateChunks(state);
    },
    setInvoiceTls(
      state,
      action: PayloadAction<{
        transactions: TransactionLine[];
      }>
    ) {
      state.tls.tls = action.payload.transactions;
    },
    updateRowInvoice(state, action: PayloadAction<{ invoice: Invoice2 }>) {
      const { invoice } = action.payload;

      const index = (state.tls.tls || []).findIndex(tl => tl.documentId === state.selectedDocumentId && tl.rowNumber === state.selectedRowNumber);

      if (index !== -1) {
        state.tls.tls[index].row = JSON.stringify(invoice) as any;
      }
    },
    setTlsCategories(state, action: PayloadAction<string[]>) {
      state.tls.categories = action.payload;
    },
    setSelectedRowIds(state, action: PayloadAction<{}>) {
      state.selectedRowIds = action.payload;
    },
    toggleSelectedRows(state, action: PayloadAction<string[]>) {
      for (const rowId of action.payload) {
        state.selectedRowIds[rowId] = !state.selectedRowIds[rowId];
      }
    },
    setAccountChunks(state, action: PayloadAction<any>) {
      state.accountChunks = action.payload;
      updateChunks(state)
    },
    selectAllRows(state, action: PayloadAction<string[]>) {
      for (const rowId of action.payload) {
        state.selectedRowIds[rowId] = true;
      }
    },
    deselectAllRows(state, action: PayloadAction<string[]>) {
      for (const rowId of action.payload) {
        state.selectedRowIds[rowId] = false;
      }
    },
    goToNextPage(state) {
      if (state.pageIndex === null) {
        return;
      }
      if (state.lastPage === null) {
        return;
      }
      if (state.pageIndex < state.pages.length - 1) {
        state.pageIndex += 1;
        state.selectedPage = state.pages[state.pageIndex];
      }
    },
    goToPreviousPage(state) {
      if (state.pageIndex === null) {
        return;
      }
      if (state.lastPage === null) {
        return;
      }
      if (state.pageIndex > 0) {
        state.pageIndex -= 1;
        state.selectedPage = state.pages[state.pageIndex];
      }
    },
    goToFirstPage(state) {
      if (state.pages.length > 0) {
        state.pageIndex = 0;
        state.selectedPage = state.pages[state.pageIndex];
      }
    },
    goToLastPage(state) {
      if (state.pages.length > 0) {
        state.pageIndex = state.pages.length - 1;
        state.selectedPage = state.pages[state.pageIndex];
      }
    },
    setTl(
      state,
      action: PayloadAction<{
        transactionId: number;
        params: {
          included?: boolean;
          col?: number;
          newValue?: string;
        };
      }>
    ) {
      const { transactionId, params } = action.payload;
      const { included } = params;
      const newObj: any = {};
      if (!isNullOrUndefined(included)) {
        newObj.included = included;
      }

      state.tls.tls = (state.tls.tls || []).map((tl) =>
        tl.id === transactionId.toString() ? { ...tl, ...newObj } : tl
      );
    },
    setDocuments(state, action: PayloadAction<Document[]>) {
      state.tls.loading = false;
      state.tls.documents = action.payload;
    },
    setFieldNames(state, action: PayloadAction<TransactionLine[]>) {
      state.tls.fieldNames = uniq(action.payload.map((x) => x.fieldName));
    },
    setErrorTls(state, action: PayloadAction<string>) {
      state.tls.loading = false;
      state.tls.error = action.payload;
    },
    loadMfs(state) {
      state.mfs.loading = true;
      state.mfs.error = null;
    },
    setMfs(state, action: PayloadAction<MetadataField[]>) {
      state.mfs.loading = false;
      state.mfs.mfs = action.payload;

      {
        const reconciledMfs = (state.mfs.mfs || []).filter(
          (x) => x.name === "isReconciled"
        );

        const byAccount = _.groupBy(reconciledMfs, "accountName");
        const byDocumentId = _.groupBy(reconciledMfs, "documentId");
        state.reconciled = {
          accounts: {},
          documentIds: {},
        };
        for (const account in byAccount) {
          const isReconciled = byAccount[account].reduce(
            (acc, x) => x.value === "1" && acc,
            true
          );
          state.reconciled.accounts[account] = isReconciled;
        }
        for (const documentId in byDocumentId) {
          const isReconciled = byDocumentId[documentId].reduce(
            (acc, x) => x.value === "1" && acc,
            true
          );
          state.reconciled.documentIds[documentId] = isReconciled;
        }
      }

      {
        const dateMfs = (state.mfs.mfs || []).filter((x) =>
          ["startDate", "endDate"].includes(x.name)
        );

        const byAccount = _.groupBy(dateMfs, "accountName");
        const byDocumentId = _.groupBy(dateMfs, "documentId");
        state.badDates = {
          accounts: {},
          documentIds: {},
        };
        for (const account in byAccount) {
          const isBadDate = byAccount[account].reduce(
            (acc, x) => x.value.includes("2001") || acc,
            false
          );
          state.badDates.accounts[account] = isBadDate;
        }
        for (const documentId in byDocumentId) {
          const isBadDate = byDocumentId[documentId].reduce(
            (acc, x) => x.value.includes("2001") || acc,
            false
          );
          state.badDates.documentIds[documentId] = isBadDate;
        }
      }

      // updateChunks(state);
    },
    setErrorMfs(state, action: PayloadAction<string>) {
      state.mfs.loading = false;
      state.mfs.error = action.payload;
    },
    setSelectedDocumentId(state, action: PayloadAction<string | null>) {
      state.selectedDocumentId = action.payload;
      state.selectedRowNumber = 0;
      setPagesHelper(state);
    },
    setSelectedChunk(state, action: PayloadAction<string | null>) {
      state.selectedChunk = action.payload;
    },

    setSelectedRowNumber(state, action: PayloadAction<number>) {
      state.selectedRowNumber = action.payload;
    },
    setSelectedFieldNames(state, action: PayloadAction<string[] | undefined>) {
      state.selectedFieldNames = action.payload;
    },
    setSelectedTransactionStatus(
      state,
      action: PayloadAction<string[] | undefined>
    ) {
      state.selectedTransactionStatus = action.payload;
    },
    setDownloadOptionsSelectedDocumentIds(
      state,
      action: PayloadAction<string[]>
    ) {
      state.downloadOptions.selectedDocumentIds = action.payload;
    },
    setDownloadOptionsSelectedFieldNames(
      state,
      action: PayloadAction<string[]>
    ) {
      state.downloadOptions.selectedFieldNames = action.payload;
    },
    toggleErrors(state) {
      const hasError = (state.selectedTransactionStatus || []).includes(
        "error"
      );
      if (hasError) {
        state.selectedTransactionStatus = (
          state.selectedTransactionStatus || []
        ).filter((x) => x !== "error");
      } else {
        state.selectedTransactionStatus = [
          ...(state.selectedTransactionStatus || []),
          "error",
        ];
      }
    },
    addAddedManually(state) {
      const has = (state.selectedTransactionStatus || []).includes(
        "added manually"
      );
      if (!has) {
        state.selectedTransactionStatus = [
          ...(state.selectedTransactionStatus || []),
          "added manually",
        ];
      }
    },
    setAccounts(
      state,
      action: PayloadAction<{
        accounts: string[];
        resetSelectedAccount: boolean;
      }>
    ) {
      state.accounts = action.payload.accounts;
      if (state.accounts) {
        if (
          state.accounts.length > 0 &&
          (action.payload.resetSelectedAccount ||
            !state.accounts.includes(state.selectedAccount || "null"))
        ) {
          state.selectedAccount = state.accounts[0];
          // updateChunks(state);
          // if (state.accountChunks.length > 0) {
          //   state.selectedChunk = state.accountChunks[0];
          // }
        }

        state.accountNicknames = {};
        for (const t of state.tls.tls || []) {
          state.accountNicknames[t.account] = t.accountNickname;
        }

        state.selectedAccountNickname =
          state.accountNicknames[state.selectedAccount || ""];
      }
    },
    setSelectedAccount(state, action: PayloadAction<string | null>) {
      state.selectedAccount = action.payload;
      if (state.selectedAccount) {
        state.selectedAccountNickname =
          state.accountNicknames[state.selectedAccount];
        // updateChunks(state);



        // if (state.accountChunks.length > 0) {
        //   state.selectedChunk = state.accountChunks[0];
        // }
      }
    },
    setAccountNicknames(state, action: PayloadAction<{ [k: string]: string }>) {
      state.accountNicknames = { ...action.payload };
    },
    setSelectedTl(state, action: PayloadAction<{ selectedTl }>) {
      state.selectedTl = action.payload.selectedTl;
    },
    setInvoice(state, action: PayloadAction<{ invoice: Invoice2 | null }>) {
      state.invoice = action.payload.invoice;
    },
    setLastCategorizationTime(state, action: PayloadAction<number>) {
      state.lastCategorizationTime = action.payload;
    },
    setShowModalInvoiceRules(state, action: PayloadAction<boolean>) {
      state.showModalInvoiceRules = action.payload;
    },
    setPagination(state, action: PayloadAction<{
      page: number;
      pageSize: number;
      totalCount: number;
      totalPages: number;
    }>) {
      state.pagination = action.payload;
    },
    setRefreshKey(state) {
      state.refreshKey += 1;
    },
  },
});

export const {
  loadTls,
  setErrorTls,
  setTls,
  setInvoiceTls,
  toggleLoading,
  toggleBulkEdit,
  disableBulkEdit,
  setDocuments,
  setFieldNames,
  loadMfs,
  setErrorMfs,
  setMfs,
  setSelectedDocumentId,

  setSelectedChunk,
  setSelectedRowNumber,
  setDownloadOptionsSelectedDocumentIds,
  setDownloadOptionsSelectedFieldNames,
  setSelectedFieldNames,
  setTl,
  toggleErrors,
  setSelectedTransactionStatus,
  addAddedManually,
  goToFirstPage,
  goToLastPage,
  goToNextPage,
  goToPreviousPage,
  setTlsCategories,
  updateRowInvoice,
  setSelectedRowIds,
  toggleSelectedRows,
  selectAllRows,
  deselectAllRows,
  setAccountChunks,
  setAccountNicknames,
  setAccounts,
  setSelectedAccount,
  setSelectedTl,
  setInvoice,
  setLastCategorizationTime,
  setShowModalInvoiceRules,
  setPagination,
  setRefreshKey
} = slice.actions;

export const fetchTls = (
  resetFields: boolean,
  options?: TableQueryParams & {
    successCallback?: (rowsThisPage: any[], lastRow: number) => void;
    successAutomaticModeCallback?: (rowsThisPage: any[], lastRow: number) => void;
    documents?: any;
    job?: any;
    hasQbo?: any;
    accountChunkLabels?: any;
  }
) => async (dispatch, getState) => {

  const state: ReduxState = getState();
  if (state.TransactionManager.tls.loading) return;

  try {
    const job = state.JobData.job;
    if (job) {
      dispatch(loadTls());
      const cleanOptions = { ...options }
      delete cleanOptions.successCallback;
      delete cleanOptions.documents;
      delete cleanOptions.job;
      delete cleanOptions.hasQbo;
      delete cleanOptions.accountChunkLabels;

      const response = await getJobTransactions2(job.id, cleanOptions as any);

      const formattedData = response.data.map((t) => {
        try {

          if (Array.isArray(t.row)) {
            const obj = {};
            t.row.forEach((value, index) => {
              obj[index] = value;
            });
            return obj;
          } else {
            return t;
          }

        } catch (err) {
          return {};
        }
      }
      )

      dispatch(setTls({
        transactions: formattedData,
        resetPages: false
      }));
      dispatch(setPagination(response.pagination));

      // Call AG Grid's successCallback directly
      if (options?.successCallback) {
        options.successCallback(convertTlsToTable(response.data,
          options.documents, options.job, options.hasQbo, options.accountChunkLabels), response.pagination.totalCount);
      }

      if (options?.successAutomaticModeCallback) {
        options.successAutomaticModeCallback(formattedData, response.pagination.totalCount);
      }

      dispatch(setTlsCategories(uniq(response.data.map((x) => x.category))));
      dispatch(setFieldNames(response.data));

      if (resetFields) {
        dispatch(
          setDownloadOptionsSelectedDocumentIds(
            uniq(response.data.map((x) => x.documentId))
          )
        );
        const allFields = uniq<string>(response.data.map((x) => x.fieldName));
        dispatch(setDownloadOptionsSelectedFieldNames(allFields));
        if (response.data.length > 0) {
          dispatch(setSelectedDocumentId(response.data[0].documentId));
        }
        dispatch(setSelectedFieldNames(allFields));
        dispatch(setSelectedRowIds({}));
        dispatch(disableBulkEdit());
      }
    }
  } catch (err: any) {
    dispatch(setErrorTls(err.message || "Error getting job transactions"));
  }
};

export const fetchMfs = () => async (dispatch, getState) => {
  const state: ReduxState = getState();
  if (state.TransactionManager.mfs.loading) {
    return;
  }
  dispatch(loadMfs());
  try {
    const job = state.JobData.job;
    if (job) {
      const { metadataFields: mfs } = await getJobMetadataFields(job.id);
      dispatch(setMfs(mfs));
    }
  } catch (err: any) {
    dispatch(setErrorMfs(err.message || "Error getting job transactions"));
  }
};

export const updatePayeeCleanupConfigRedux =
  (
    config: any
  ) =>
    async (dispatch, getState) => {
      const state: ReduxState = getState();

      try {
        const job = state.JobData.job;
        if (job) {
          dispatch(toggleLoading());
          const { transactions } = await updatePayeeCleanupConfig(
            job.id,
            config
          );
          dispatch(setTls({ transactions, resetPages: false }));
        }
      } catch (err) {

      }
    };
export const updateMetadataField =
  (
    documentId: number,
    name: string,
    newValue: string,
    accountName: string,
    chunk: string
  ) =>
    async (dispatch, getState) => {
      const state: ReduxState = getState();

      try {
        const job = state.JobData.job;
        if (job) {
          dispatch(toggleLoading());
          const { transactions } = await updateJobMetadataField(
            job.id,
            documentId,
            name,
            newValue,
            accountName,
            chunk
          );
          dispatch(fetchMfs());
          dispatch(fetchTotals());
          dispatch(setTls({ transactions, resetPages: false }));
          dispatch(toggleLoading());
          dispatch(
            createAlert({
              message: "Field updated successfully",
              timeout: 0,
              type: "success",
            })
          );
        }
      } catch (err) {
        dispatch(toggleLoading());
        dispatch(
          createAlert({
            message: "Error updating field",
            timeout: 0,
            type: "success",
          })
        );
      }
    };

export const updateTransactionLine =
  (
    transactionId: number,
    params: {
      included?: boolean;
      col?: number;
      newValue?: string;
    }
  ) =>
    async (dispatch, getState) => {
      const state: ReduxState = getState();

      try {
        const job = state.JobData.job;
        if (job) {
          await updateJobTransaction(job.id, transactionId, params);
          dispatch(setTl({ transactionId, params }));
          dispatch(fetchTotals());
        }
      } catch (err) { }
    };


// export const applyRunInvoiceRules = () => async (dispatch, getState) => {
//   try {
//     const state: ReduxState = getState();
//     const job = state.JobData.job;

//     if (!job) return;

//     const { newTls } = await runInvoiceRules(job.id)

//     const selectedDocumentId = state.TransactionManager.selectedDocumentId;
//     const selectedIndex = state.TransactionManager.selectedRowNumber || 0;

//     const newSelectedTl = newTls.filter((x) => x.documentId === selectedDocumentId).find((x) => x.rowNumber === selectedIndex);
//     const newInvoice = JSON.parse(newSelectedTl.row as any) as Invoice2;

//     dispatch(setSelectedTl({ selectedTl: newSelectedTl }));
//     dispatch(setInvoice({ invoice: newInvoice }));
//     dispatch(setLastCategorizationTime(Date.now()));
//   } catch (err) {
//     // console.log(err);
//   }
// };

export default slice.reducer;

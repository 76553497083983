import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";

import { Form, Row, Col, Alert } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Split from "react-split";
import { PieChart, Pie, Cell } from "recharts";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useSelector } from "react-redux";
import { ReduxState } from "src/redux";
import {
  getDocumentFraudScore,
  getDocumentFraudSignals,
} from "src/docuclipper/api";
import {
  fetchTls,
  setSelectedDocumentId,
} from "src/redux/reducers/TransactionManager";
import PDFViewer from "./PdfViewer";
import useBankStatementPDF from "./DataArchive/TransactionManager/useBankStatementPDF";
import DocumentSelector from "./DataArchive/TransactionManager/DocumentSelector";

interface FraudSignal {
  id: number;
  type: string;
  confidence: number;
  createdAt: string;
  updatedAt: string;
  documentId: string;
  Document: {
    id: string;
    fileName: string;
  };
}

interface FraudScore {
  score: number;
  comment: string;
}

const getColor = (score: number) => {
  if (score <= 33) return "#FF4842"; // green
  if (score <= 66) return "#FFB020"; // amber
  return "#00C49F"; // red
};

const GaugeChart: React.FC<{ value: number }> = ({ value }) => {
  const normalizedValue = value > 1 ? value : value * 100;
  const data = [
    { name: "score", value: normalizedValue },
    { name: "remainder", value: 100 - normalizedValue },
  ];

  return (
    <PieChart width={120} height={120}>
      <Pie
        data={data}
        cx={60}
        cy={60}
        startAngle={180}
        endAngle={0}
        innerRadius={40}
        outerRadius={55}
        paddingAngle={0}
        dataKey="value"
      >
        <Cell fill={getColor(normalizedValue)} />
        <Cell fill="#f5f5f5" /> {/* gray background */}
      </Pie>
    </PieChart>
  );
};

export const FraudData: React.FC<{}> = () => {
  const [fraudSignals, setFraudSignals] = useState<FraudSignal[]>([]);
  const [fraudScore, setFraudScore] = useState<FraudScore | null>(null);
  const [loading, setLoading] = useState(false);

  const { selectedDocumentId } = useSelector(
    (state: ReduxState) => state.TransactionManager
  );
  const { pdfURL } = useBankStatementPDF({
    selectedDocumentId,
  });
  const dispatch = useDispatch();

  // useEffect(() => {
  //   // If tls is empty, fetch transactions and select first document
  //   if (!tls.tls.length) {
  //     dispatch(fetchTls(true));
  //   }
  // }, [dispatch, tls.tls.length]);

  const columnDefs: ColDef[] = [
    { field: "type", headerName: "Signal Type", sortable: true, filter: true },
    {
      field: "confidence",
      headerName: "Confidence",
      sortable: true,
      valueFormatter: (params) => `${(params.value * 100).toFixed(1)}%`,
      flex: 0.5,
    },
    {
      field: "text",
      headerName: "Text",
      sortable: true,
      filter: true,
      flex: 0.8,
    },
    {
      field: "details",
      headerName: "Details",
      sortable: true,
      filter: true,
      flex: 2,
      tooltipField: "details",
    },
  ];

  useEffect(() => {
    const fetchFraudData = async () => {
      if (!selectedDocumentId) return;

      setLoading(true);
      try {
        const [signalsResponse, scoreResponse] = await Promise.all([
          getDocumentFraudSignals(selectedDocumentId),
          getDocumentFraudScore(selectedDocumentId),
        ]);

        setFraudSignals(signalsResponse.signals);
        setFraudScore(scoreResponse);
      } catch (error) {
        console.error("Error fetching fraud data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFraudData();
  }, [selectedDocumentId]);

  return (
    <Split
      sizes={[40, 60]}
      direction="horizontal"
      style={{ display: "flex", height: "90vh" }}
    >
      <div style={{ width: "100%" }} className="mr-2">
        <PDFViewer
          key={`${selectedDocumentId}`}
          url={pdfURL} // You'll need to add this state/prop
          height={"90vh"}
        />
      </div>
      <div style={{ width: "100%" }} className="ml-4">
        <div className="card mb-4">
          <div className="card-header">
            <h4>Document Fraud Analysis</h4>
          </div>
          <div className="card-body">
            <div className="row mb-4 align-items-center">
              <div className="col-md-6">
                <DocumentSelector />
              </div>
              {!loading && fraudScore && (
                <div className="col-md-6">
                  <Alert variant="light" className="mb-0">
                    <h5 className="mb-2">Fraud Risk Score</h5>
                    <div className="d-flex align-items-center">
                      <GaugeChart value={fraudScore.score} />
                      <div className="ml-3">
                        <h2
                          className="mb-0"
                          style={{
                            color: getColor(fraudScore.score),
                          }}
                        >
                          {fraudScore.score.toFixed(1)}%
                        </h2>
                        <p className="mb-0 text-muted">{fraudScore.comment}</p>
                      </div>
                    </div>
                  </Alert>
                </div>
              )}
            </div>

            {loading && (
              <div className="text-center">Loading fraud data...</div>
            )}

            {!loading && fraudSignals.length > 0 && (
              <div className="ag-theme-alpine" style={{ height: 400 }}>
                <AgGridReact
                  rowData={fraudSignals}
                  columnDefs={columnDefs}
                  defaultColDef={{
                    flex: 1,
                    minWidth: 100,
                    resizable: true,
                    tooltipComponent: true,
                  }}
                  pagination={true}
                  paginationPageSize={10}
                />
              </div>
            )}

            {!loading && selectedDocumentId && fraudSignals.length === 0 && (
              <Alert variant="info">
                No fraud signals detected for this document.
              </Alert>
            )}
          </div>
        </div>
      </div>
    </Split>
  );
};

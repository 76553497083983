import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "src/redux";
import { openSurvey, closeSurvey } from "src/redux/reducers/Survey";

const FeedbackButton: React.FC = () => {
  const dispatch = useDispatch();
  const [isVisible, setIsVisible] = useState(true);

  const { open, survey } = useSelector((state: ReduxState) => state.Survey);
  const { user } = useSelector((state: ReduxState) => state.User);

  useEffect(() => {
    const lastOpenDate = localStorage.getItem("lastFeedbackSurveyOpen");
    const oneMonthsInMs = 30 * 24 * 60 * 60 * 1000;
    const minimumWaitTime = 24 * 60 * 60 * 1000; // 24 hours in milliseconds

    // Check if user has been registered for at least 24 hours
    const userRegistrationTime = user?.createdAt
      ? new Date(user.createdAt).getTime()
      : null;
    const hasMinimumTimeElapsed =
      userRegistrationTime &&
      Date.now() - userRegistrationTime >= minimumWaitTime;

    if (
      hasMinimumTimeElapsed &&
      (!lastOpenDate || Date.now() - Number(lastOpenDate) >= oneMonthsInMs)
    ) {
      dispatch(openSurvey("feedback"));
      localStorage.setItem("lastFeedbackSurveyOpen", Date.now().toString());
    }
  }, [dispatch, user]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (open && survey === "feedback") {
        const surveyElement = document.querySelector(".survey-container"); // Adjust selector as needed
        if (surveyElement && !surveyElement.contains(event.target as Node)) {
          dispatch(closeSurvey());
        }
      }
    };

    const handleEscapeKey = (event: KeyboardEvent) => {
      if (open && survey === "feedback" && event.key === "Escape") {
        dispatch(closeSurvey());
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscapeKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [open, survey, dispatch]);

  const handleOpenSurvey = () => {
    if (open && survey === "feedback") {
      dispatch(closeSurvey());
    } else {
      dispatch(openSurvey("feedback"));
    }
  };

  const handleClose = () => {
    setIsVisible(false);
    // Hide elements with specified classes
    const elementsToHide = document.querySelectorAll(
      ".betterdocs-cross-domain-code, .recaptcha, iframe#launcher"
    );
    elementsToHide.forEach((el) => {
      (el as any).style.display = "none";
    });
  };

  if (!isVisible) return null;

  return (
    <div
      style={{
        position: "fixed",
        right: "5rem",
        bottom: "20px",
        zIndex: 1050,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Button
        variant="primary"
        onClick={handleOpenSurvey}
        style={{ marginRight: "5px" }}
      >
        Feedback
      </Button>
      <Button
        variant="outline-primary"
        size="sm"
        onClick={handleClose}
        style={{ padding: "0.25rem 0.5rem", lineHeight: 1 }}
      >
        ×
      </Button>
    </div>
  );
};

export default FeedbackButton;

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  StripePlan,
  StripeSubscription,
} from "../../../docuclipper/DocuclipperTypes";
import { ReduxState } from "../../../redux";
import { createAlert, CreateAlertFn } from "../../../redux/reducers/Alerts";
import { closeModal } from "../../../redux/reducers/ModalManager";
import { WithAccountAndPlans } from "../../Pages/Account/utils";
import BuyCredits from "./BuyCredits";

const stripePromise = loadStripe(
  process.env.NODE_ENV === "production"
    ? "pk_live_vISijIuYcPBVdPIzoBjf5cUy00kEHEk2P1"
    : "pk_test_0RzF7XHTNdggpveaP0puymok00hYV71JbY"
);

type Props = {
  open: boolean;
  outOfCredits: boolean;
  closeCreditsModal: () => any;
  plans: StripePlan[];
  subscription: StripeSubscription;
  createAlert: CreateAlertFn;
};

type State = {};

export const CreditsModalUnconnected: React.FC<Props> = ({
  open,
  outOfCredits,
  closeCreditsModal,
  createAlert,
  plans,
  subscription,
}) => {
  const toggle = () => closeCreditsModal();

  const onKeyPress = (e) => {
    if (e.key === "Escape") {
      e.preventDefault();
      toggle();
    }
  };

  return (
    <>
      <Modal
        size="xl"
        keyboard={true}
        onKeyPress={onKeyPress}
        show={open}
        onHide={toggle}
      >
        <Modal.Header closeButton={true}>
          <div className="page-title-icon">
            <i className="pe-7s-piggy" />
            <span className="ml-2">Buy Pages</span>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Elements stripe={stripePromise}>
            <BuyCredits
              subscription={subscription}
              outOfCredits={outOfCredits}
            />
          </Elements>
        </Modal.Body>
      </Modal>
    </>
  );
};

const CreditsModalWithData = withRouter(
  WithAccountAndPlans(CreditsModalUnconnected)
) as any;
export const CreditsModal: React.FC = () => {
  const dispatch = useDispatch();

  const { activeModal, modals } = useSelector(
    (state: ReduxState) => state.ModalManager
  );
  if (activeModal !== "buyPages") {
    return null;
  }
  const data = modals.filter((x) => x.name === "buyPages");

  return (
    <CreditsModalWithData
      open={activeModal === "buyPages"}
      outOfCredits={data.length > 0 ? data[0].data.outOfPages : true}
      closeCreditsModal={() => dispatch(closeModal("buyPages"))}
      createAlert={(x) => dispatch(createAlert(x))}
    />
  );
};

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Tour from "reactour";
import { setHelpDrawerOpen } from "src/redux/reducers/HelpDrawer";

const steps = [
  {
    selector: ".invoice-tour-select-doc",
    content: "Select an invoice/receipt",
  },
  {
    selector: ".invoice-tour-export-to",
    content:
      "Select where to export the data. Options including Excel and accounting softwares.",
  },
  {
    selector: ".invoice-tour-document-type",
    content:
      "Select whether the document is a payable or a receivable. Payables have a vendor field and the line items can be assigned a category. Receivables have a customer field and the line items can be assigned a product or service.",
  },
  {
    selector: ".invoice-tour-fields",
    content: "Edit the invoice fields.",
  },
  {
    selector: ".invoice-tour-edit-rules",
    content: "Create rules to automatically set field values.",
  },
  {
    selector: ".invoice-tour-download-data",
    content: "Export the data to Excel or your accounting software.",
  },
];

export const InvoiceTour: React.FC = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(() => {
    // Check if user has seen the tour before
    return localStorage.getItem("hasSeenInvoiceTour") !== "true";
  });

  const closeTour = () => {
    setOpen(false);
    // Save to localStorage that user has seen the tour
    localStorage.setItem("hasSeenInvoiceTour", "true");
  };

  return (
    <>
      <Button onClick={() => setOpen(true)} variant="link">
        <FontAwesomeIcon icon="play" />
        <span className="ml-1">Tutorial</span>
      </Button>
      <Tour steps={steps} isOpen={open} onRequestClose={closeTour} />
    </>
  );
};
export default InvoiceTour;

import React, {
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { GridReadyEvent } from "ag-grid-community";
import { useHistory } from "react-router-dom";
import { setSelectedInvoiceId } from "../../../redux/reducers/TagLevel";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

interface InvoiceRow {
  id: string;
  vendor: string;
  amount: number;
  date: string;
}

// Helper function to generate synthetic data
const generateFakeData = (startRow: number, endRow: number): InvoiceRow[] => {
  const data: InvoiceRow[] = [];
  for (let i = startRow; i < endRow; i++) {
    data.push({
      id: `INV-${i + 1}`,
      vendor: `Vendor ${String.fromCharCode(65 + (i % 26))}`, // Cycles through A-Z
      amount: Math.floor(Math.random() * 10000),
      date: new Date(2024, 0, 1 + i).toISOString().split("T")[0],
    });
  }
  return data;
};

export const Invoices = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const gridApi = useRef<any>(null);
  const [rowData, setRowData] = useState([]);

  const getRows = useCallback((params) => {
    try {
      // Simulate server delay
      setTimeout(() => {
        const totalRows = 1000; // Total number of rows in our synthetic dataset

        // Calculate the rows to return based on the request
        const startRow = params.startRow;
        const endRow = Math.min(params.endRow, totalRows);

        // Generate synthetic data for this page
        const rowsThisPage = generateFakeData(startRow, endRow);

        // Apply sorting if needed
        if (params.sortModel && params.sortModel.length > 0) {
          const { colId, sort } = params.sortModel[0];
          rowsThisPage.sort((a: any, b: any) => {
            const multiplier = sort === "asc" ? 1 : -1;
            return a[colId] > b[colId] ? multiplier : -multiplier;
          });
        }

        // Apply filtering if needed
        let filteredRows = rowsThisPage;
        if (params.filterModel) {
          Object.entries(params.filterModel).forEach(
            ([field, filter]: [string, any]) => {
              if (filter.filter) {
                filteredRows = filteredRows.filter((row: any) => {
                  const value = row[field];
                  const filterValue = filter.filter;

                  switch (filter.type) {
                    case "equals":
                      return value === filterValue;
                    case "contains":
                      return value.toString().includes(filterValue);
                    case "greaterThan":
                      return value > filterValue;
                    case "lessThan":
                      return value < filterValue;
                    default:
                      return true;
                  }
                });
              }
            }
          );
        }

        // Return the data to AG-Grid
        params.successCallback(filteredRows, totalRows);
      }, 500); // 500ms delay to simulate network latency
    } catch (error) {
      console.error("Error in getRows:", error);
      params.failCallback();
    }
  }, []);

  const dataSource = useMemo(
    () => ({
      rowCount: undefined,
      getRows,
    }),
    [getRows]
  );

  const onGridReady = useCallback(
    (params: GridReadyEvent) => {
      gridApi.current = params.api;
      gridApi.current.setDatasource(dataSource);
    },
    [dataSource]
  );

  const SelectInvoiceRenderer = (props: any) => {
    const handleClick = () => {
      dispatch(setSelectedInvoiceId(props.data.id));

      // Create URL object from current URL to handle existing query params
      const url = new URL(window.location.href);
      // Set or update the invoiceId parameter
      url.searchParams.set("invoiceId", props.data.id);
      // Use history.push instead of navigate
      history.push({
        pathname: window.location.pathname,
        search: url.search,
      });
    };

    return (
      <Button variant="primary" size="sm" onClick={handleClick}>
        Select Invoice
      </Button>
    );
  };

  const columnDefs = useMemo(
    () => [
      {
        field: "id",
        headerName: "Invoice ID",
        sortable: true,
        filter: true,
      },
      {
        field: "vendor",
        headerName: "Vendor",
        sortable: true,
        filter: true,
      },
      {
        field: "amount",
        headerName: "Amount",
        sortable: true,
        filter: "agNumberColumnFilter",
        valueFormatter: (params: any) =>
          new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(params.value),
      },
      {
        field: "date",
        headerName: "Date",
        sortable: true,
        filter: "agDateColumnFilter",
      },
      {
        headerName: "Actions",
        cellRenderer: SelectInvoiceRenderer,
        sortable: false,
        filter: false,
        width: 150,
      },
    ],
    []
  );

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      flex: 1,
      filter: true,
      sortable: true,
    }),
    []
  );

  return (
    <div className="ag-theme-alpine" style={{ height: "500px", width: "100%" }}>
      <AgGridReact
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        rowModelType="infinite"
        cacheBlockSize={10}
        cacheOverflowSize={2}
        maxConcurrentDatasourceRequests={1}
        infiniteInitialRowCount={1}
        maxBlocksInCache={10}
        pagination={true}
        paginationPageSize={10}
        suppressCellFocus={true}
      />
    </div>
  );
};

export default Invoices;

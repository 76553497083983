import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

type Props = {
  placement: any;
  id: string;
  renderTooltip: () => JSX.Element;
};

export const withTooltip = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P & Props> => {
  return function WihTooltipComponent({
    placement,
    id,
    renderTooltip,
    ...props
  }: Props) {
    return (
      <OverlayTrigger
        popperConfig={{
          modifiers: {
            preventOverflow: {
              enabled: false,
            },
          } as any,
        }}
        placement={placement || "top"}
        overlay={<Tooltip id={id}>{renderTooltip()}</Tooltip>}
      >
        {<Component {...(props as P)} />}
      </OverlayTrigger>
    );
  };
};

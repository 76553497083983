import { SplitConfig } from "../../views/Pages/DocumentEditor/SplitModal";

import { RotateConfig, CropConfig } from "../../docuclipper/DocuclipperTypes";

type PageId = {
  documentId: number;
  pageNumber: number;
};

export type Page = {
  documentId: number;
  pageNumber: number;
  isSelected: boolean;
  isDeleted: boolean;
  isSkewFixed: boolean;
  rotateConfig: RotateConfig;
  splitConfig: SplitConfig;
  imageData: string;
  imageDataLoading: boolean;
  imageDataError: boolean;
  cropConfig: CropConfig;
};

type State = {
  pages: Page[];
};

const initialState: State = {
  pages: [],
};

export const SELECT_ALL_PAGES = "select-all-pages";
export const SELECT_NONE_PAGES = "select-none-pages";
export const ADD_PAGES = "add-pages";
export const UPDATE_PAGES = "update-pages";
export const DELETE_PAGES = "delete-pages";
export const CLEAR_ALL_PAGES = "clear-all-pages";

export default function reducer(state: State = initialState, action) {
  switch (action.type) {
    case SELECT_ALL_PAGES:
      return {
        ...state,
        pages: state.pages.map((page) => ({ ...page, isSelected: true })),
      };
    case SELECT_NONE_PAGES:
      return {
        ...state,
        pages: state.pages.map((page) => ({ ...page, isSelected: false })),
      };

    case ADD_PAGES:
      return {
        ...state,
        pages: [
          ...state.pages,
          ...action.payload.pages.map(({ documentId, pageNumber }) => ({
            documentId,
            pageNumber,
            imageData: "",
            imageDataLoading: false,
            ImageDataError: false,
            isSelected: false,
            isDeleted: false,
            rotateConfig: { orientation: "north", angle: null },
            splitConfig: {
              splitX: 1,
              splitY: 1,
            },
            cropConfig: {
              bottomPercentage: 0,
              topPercentage: 0,
              leftPercentage: 0,
              rightPercentage: 0,
            },
          })),
        ],
      };

    case UPDATE_PAGES: {
      const updatedPages: { [k: string]: Page } = (
        action.payload.pages || []
      ).reduce((acc, val) => {
        acc[`${val.documentId}-${val.pageNumber}`] = val;
        return acc;
      }, {});

      const pages = state.pages.map((page) => {
        const key = `${page.documentId}-${page.pageNumber}`;
        if (key in updatedPages) {
          return { ...page, ...updatedPages[key] };
        } else {
          return { ...page };
        }
      });
      return {
        ...state,
        pages,
      };
    }

    case DELETE_PAGES: {
      return {
        ...state,
        pages: state.pages.filter(
          (page) => page.documentId !== action.payload.documentId
        ),
      };
    }

    case CLEAR_ALL_PAGES: {
      return {
        ...state,
        pages: [],
      };
    }

    default:
      return state;
  }
}

export type SelectAllPagesFn = () => any;
export const selectAllPages = () => ({
  type: SELECT_ALL_PAGES,
  payload: {},
});

export type SelectNonePagesFn = () => any;
export const selectNonePages = () => ({
  type: SELECT_NONE_PAGES,
  payload: {},
});

export type AddPagesFn = () => any;
export const addPages = (pages: PageId[]) => ({
  type: ADD_PAGES,
  payload: {
    pages,
  },
});

export type UpdatePagesFn = (
  pages: {
    documentId: number;
    pageNumber: number;
    rotateConfig?: RotateConfig;
    splitConfig?: SplitConfig;
    imageData?: string;
    imageDataLoading?: boolean;
    imageDataError?: boolean;
    isDeleted?: boolean;
    isSelected?: boolean;
    isSkewFixed?: boolean;
    cropConfig?: CropConfig;
  }[]
) => any;
export const updatePages = (
  pages: {
    documentId: number;
    pageNumber: number;
    rotateConfig?: RotateConfig;
    splitConfig?: SplitConfig;
    imageData?: string;
    imageDataLoading?: boolean;
    imageDataError?: boolean;
    isDeleted?: boolean;
    isSelected?: boolean;
    isSkewFixed?: boolean;
    cropConfig?: CropConfig;
  }[]
) => {
  const processedPages = pages.map((p) => {
    const {
      documentId,
      pageNumber,
      rotateConfig,
      splitConfig,
      imageData,
      imageDataLoading,
      imageDataError,
      isDeleted,
      isSelected,
      isSkewFixed,
      cropConfig,
    } = p;
    const page: any = {
      documentId,
      pageNumber,
    };
    if (rotateConfig !== undefined) {
      page.rotateConfig = rotateConfig;
    }
    if (splitConfig !== undefined) {
      page.splitConfig = splitConfig;
    }
    if (imageData !== undefined) {
      page.imageData = imageData;
    }
    if (imageDataLoading !== undefined) {
      page.imageDataLoading = imageDataLoading;
    }
    if (imageDataError !== undefined) {
      page.imageDataError = imageDataError;
    }
    if (isDeleted !== undefined) {
      page.isDeleted = isDeleted;
    }
    if (isSelected !== undefined) {
      page.isSelected = isSelected;
    }
    if (isSkewFixed !== undefined) {
      page.isSkewFixed = isSkewFixed;
    }
    if (cropConfig !== undefined) {
      page.cropConfig = cropConfig;
    }
    return page;
  });

  return {
    type: UPDATE_PAGES,
    payload: {
      pages: processedPages,
    },
  };
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxState } from "..";
import { getPromotions } from "../../docuclipper/api";
import { DocuClipperPromotions } from "../../docuclipper/DocuclipperTypes";
import { openModal } from "./ModalManager";

const initialState: {
  promotion: DocuClipperPromotions | null;
  loading: boolean;
  error: string | null;
} = {
  promotion: null,
  loading: false,
  error: null,
};

const slice = createSlice({
  name: "Promotions",
  initialState,
  reducers: {
    load(state) {
      state.loading = true;
      state.error = null;
    },
    setPromotion(state, action: PayloadAction<DocuClipperPromotions | null>) {
      state.loading = false;
      state.promotion = action.payload;
    },
    setError(state, action: PayloadAction<string>) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

const { load, setError, setPromotion } = slice.actions;

export const fetchPromotions = () => async (dispatch, getState) => {
  const state: ReduxState = getState();
  if (state.Promotions.loading) {
    return;
  }
  dispatch(load());
  try {
    const { promotion } = await getPromotions();
    dispatch(setPromotion(promotion));
    if (promotion === "referFriend") {
      dispatch(openModal("refer", {}));
    }
  } catch (err) {
    dispatch(setError(err.message || "Error getting promotions"));
  }
};

export default slice.reducer;
